/* eslint-disable no-throw-literal */
import React, { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  PAS_STATUS,
  POLL_RETRIES,
  POLL_RETRY_DELAY,
  POLL_RETRY_DELAY_AFTER_15_RETRIES,
  POLL_RETRY_DELAY_AFTER_30_RETRIES,
  PAS_FAILURE_ERRORS,
} from 'constants/paymentsSpa';
import { ddLogWithContext } from 'utils/errorLoggerUtil';
import useTranslation from 'utils/hooks/useTranslation';
import notify, { notifyErrorWithWorkflowId } from 'utils/notificationUtils';
import paymentsSpa from '../api/paymentsSpa';
import useOnboardingProgressBar from 'components/Shared/OnboardingProgressBar/useOnboardingProgressBar';
import { getMaximumPaymentAccounts } from 'constants.js';
import { CORPORATE_CLIENT_RESTRICTION_TYPES } from 'constants/corporateClients';

const usePollPayments = ({ id, workflowId }) => {
  const { translateText } = useTranslation();
  const { onGetOnboardingProgress } = useOnboardingProgressBar();
  const [orderInitiated, setOrderInitiated] = React.useState('');
  const [scaChallenge, setScaChallenge] = React.useState('');

  React.useEffect(() => {
    if (orderInitiated) {
      ddLogWithContext({
        type: 'info',
        message: `Payment creation status changed (${PAS_STATUS.InitiateAuthentication}). Attaching hidden iframe.`,
        context: {
          id,
          workflowId,
        },
      });
    }
  }, [id, workflowId, orderInitiated]);

  React.useEffect(() => {
    if (scaChallenge) {
      ddLogWithContext({
        type: 'info',
        message: `Payment creation status changed (${PAS_STATUS.Challenged}). Attaching challenge iframe.`,
        context: {
          id,
          workflowId,
        },
      });
    }
  }, [id, workflowId, scaChallenge]);

  const pollPayments = React.useCallback(
    async ({ queryKey }) => {
      const [, paymentId, traceId] = queryKey;
      const { data } = await paymentsSpa.readOne(paymentId, traceId);
      const { creationStatus } = data;
      if (creationStatus.status === PAS_STATUS.Pending) {
        throw {
          status: PAS_STATUS.Pending,
          paymentId,
          workflowId: traceId,
        };
      }

      const { cardValidations } = creationStatus;
      if (creationStatus.status === PAS_STATUS.InitiateAuthentication) {
        const orderInitiated = cardValidations[0].initiateAuthentication.gatewayHtml;
        setOrderInitiated(atob(orderInitiated));
        throw {
          status: PAS_STATUS.InitiateAuthentication,
          paymentId,
          workflowId: traceId,
        };
      }

      if (creationStatus.status === PAS_STATUS.Challenged) {
        const challenge = cardValidations[0].challengeDetails.challengeQuestion;
        setScaChallenge(atob(challenge));
        throw {
          status: PAS_STATUS.Challenged,
          paymentId,
          workflowId: traceId,
        };
      }

      if (creationStatus.status === PAS_STATUS.Failed) {
        const reason =
          PAS_FAILURE_ERRORS[creationStatus.failureReason] || PAS_FAILURE_ERRORS.UnhandledError;
        ddLogWithContext({
          type: 'warn',
          message: `Payment creation failed with reason ${reason}. Exiting polling process.`,
          context: {
            id: paymentId,
            workflowId: traceId,
          },
        });
        throw {
          status: PAS_STATUS.Failed,
          reason,
          paymentId,
          workflowId: traceId,
        };
      }

      if (creationStatus.status === PAS_STATUS.Created) {
        ddLogWithContext({
          type: 'info',
          message: `Payment creation successful. Exiting polling process.`,
          context: {
            id: paymentId,
            workflowId: traceId,
          },
        });
        return {
          status: PAS_STATUS.Created,
          paymentId,
          workflowId: traceId,
        };
      }

      ddLogWithContext({
        type: 'warn',
        message: 'Payment polling process could not continue due to an unhandled error',
        context: {
          id: paymentId,
          workflowId: traceId,
        },
      });
      throw {
        status: PAS_STATUS.Failed,
        reason: PAS_FAILURE_ERRORS.UnhandledError,
        paymentId,
        workflowId: traceId,
      };
    },
    [setOrderInitiated, setScaChallenge],
  );

  const query = useQuery({
    queryFn: pollPayments,
    queryKey: ['pollPayments', id, workflowId],
    retry: (retryCount, error) => {
      if (retryCount > POLL_RETRIES) {
        ddLogWithContext({
          type: 'info',
          message: 'Payment polling process expired.',
          context: {
            id: error.paymentId,
            workflowId: error.workflowId,
          },
        });
        return false;
      }

      const status = error?.status;
      if (
        status === PAS_STATUS.Pending ||
        status === PAS_STATUS.InitiateAuthentication ||
        status === PAS_STATUS.Challenged
      ) {
        return true;
      }

      return false;
    },
    retryDelay: retryCount => {
      if (process.env.NODE_ENV === 'test') return 10;
      if (retryCount > 30) return POLL_RETRY_DELAY_AFTER_30_RETRIES;
      if (retryCount > 15) return POLL_RETRY_DELAY_AFTER_15_RETRIES;
      return POLL_RETRY_DELAY;
    },
    enabled: false,
    onSuccess: () => {
      notify({
        type: 'success',
        message: translateText('corporateAccounts.payments.create.success'),
      });
      onGetOnboardingProgress();
    },
    onError: error => {
      const mappedError = error.reason || PAS_FAILURE_ERRORS.UnhandledError;

      notifyErrorWithWorkflowId({
        errorCodeTitle: translateText('corporateAccounts.common.errorCode'),
        message: `${translateText(
          `corporateAccounts.payments.create.error.${mappedError}`,
        )} ${translateText('corporateAccounts.common.errorCode.copy')}`,
        workflowId: error.workflowId,
      });
    },
  });

  const pollReset = React.useCallback(() => {
    query.remove();
    setScaChallenge('');
    setOrderInitiated('');
  }, [setOrderInitiated, setScaChallenge, query]);

  return {
    startPolling: query.refetch,
    isPollSuccess: query.isSuccess,
    isPollError: query.isError,
    isPolling: query.isLoading,
    pollError: query.error,
    scaChallenge,
    orderInitiated,
    pollReset,
  };
};

export const useCreateSharedPayment = ({ onReset }) => {
  const { translateText } = useTranslation();
  const [pollingDetails, setPollingDetails] = React.useState({ id: null, workflowId: null });
  const {
    startPolling,
    isPolling,
    isPollSuccess,
    isPollError,
    pollError,
    scaChallenge,
    orderInitiated,
    pollReset,
  } = usePollPayments(pollingDetails);
  const createPayment = async ({ card, restrictionType }) => {
    if (restrictionType?.toLowerCase() === CORPORATE_CLIENT_RESTRICTION_TYPES.RESTRICTED) {
      const {
        response: { data: availablePaymentMethods },
      } = await paymentsSpa.read();
      if (availablePaymentMethods.length >= getMaximumPaymentAccounts())
        throw {
          status: PAS_STATUS.Failed,
          reason: PAS_FAILURE_ERRORS.RestrictedCorporateClientLimitExceeded,
        };
    }

    const { response, workflowId } = await paymentsSpa.create(card);
    return {
      data: response?.data,
      workflowId,
    };
  };

  useEffect(() => {
    if (pollingDetails.id && pollingDetails.workflowId) {
      ddLogWithContext({
        type: 'info',
        message: `Payment creation initiated. Starting polling process for payment ID ${pollingDetails.id}.`,
        context: {
          ...pollingDetails,
        },
      });
      startPolling();
    }
  }, [pollingDetails, startPolling]);

  useEffect(() => {
    return () => {
      setPollingDetails({ id: null, workflowId: null });
    };
  }, [setPollingDetails]);

  const mutation = useMutation({
    mutationFn: createPayment,
    mutationKey: 'createPayment',
    onSuccess: ({ data, workflowId }) => {
      setPollingDetails({
        id: data.creationStatus.paymentAccountId,
        workflowId,
      });
    },
    onError: error => {
      const mappedError = error.reason || PAS_FAILURE_ERRORS.UnhandledError;

      notifyErrorWithWorkflowId({
        errorCodeTitle: translateText('corporateAccounts.common.errorCode'),
        message: `${translateText(
          `corporateAccounts.payments.create.error.${mappedError}`,
        )} ${translateText('corporateAccounts.common.errorCode.copy')}`,
        workflowId: error.workflowId,
      });
    },
  });
  return {
    ...mutation,
    reset: () => {
      mutation.reset();
      pollReset();
      onReset();
    },
    isLoading: mutation.isLoading || isPolling,
    isSuccess: isPollSuccess,
    isError: isPollError,
    pollError,
    scaChallenge,
    orderInitiated,
  };
};
