module.exports = {
  'Corporate Accounts Key': '',
  TEST: '',
  'TEST.INTERPOLATIONS': '',
  'corporateAccounts.actions': 'Actions',
  'corporateAccounts.actions.addNewAccount': 'Ajouter un nouveau client',
  'corporateAccounts.actions.back': 'Retour',
  'corporateAccounts.actions.browse': 'Parcourir',
  'corporateAccounts.actions.cancel': 'Annuler',
  'corporateAccounts.actions.chooseCsv': 'Choisir un fichier CSV',
  'corporateAccounts.actions.continue': 'Continuer',
  'corporateAccounts.actions.deactivate': 'Désactiver',
  'corporateAccounts.actions.deactivateAccount': 'Désactiver le compte',
  'corporateAccounts.actions.delete': 'Supprimer',
  'corporateAccounts.actions.done': 'Effectué',
  'corporateAccounts.actions.download': 'Télécharger',
  'corporateAccounts.actions.downloadLogFile': 'Télécharger le fichier journal',
  'corporateAccounts.actions.edit': 'Modifier',
  'corporateAccounts.actions.export': 'Exporter',
  'corporateAccounts.actions.exportData': 'Exporter les données',
  'corporateAccounts.actions.goToPage': 'Aller à la page {pageName}',
  'corporateAccounts.actions.importing': 'Importation...',
  'corporateAccounts.actions.migrate': '',
  'corporateAccounts.actions.next': 'Suivant',
  'corporateAccounts.actions.reactivate': 'Réactiver',
  'corporateAccounts.actions.reactivateAccount': 'Réactiver le compte client',
  'corporateAccounts.actions.register': "S'inscrire",
  'corporateAccounts.actions.replaceFile': 'Remplacer le fichier',
  'corporateAccounts.actions.resendInvite': "Renvoyer l'invitation",
  'corporateAccounts.actions.resendInvite.error': "Echec. L'invitation n'a pas été renvoyée.",
  'corporateAccounts.actions.resendInvite.success':
    'Invitation envoyée avec succès au collaborateur',
  'corporateAccounts.actions.save': 'Sauvegarder',
  'corporateAccounts.actions.sendInvite': 'Envoyer une invitation',
  'corporateAccounts.admin.accounts': 'Comptes',
  'corporateAccounts.admin.action.enterAccount': 'Afficher',
  'corporateAccounts.admin.action.more': 'Plus',
  'corporateAccounts.admin.action.moreActions': 'Autres actions',
  'corporateAccounts.admin.add.success': 'Utilisateur ajouté',
  'corporateAccounts.admin.corporation': 'Entreprise',
  'corporateAccounts.admin.delete.success': 'Utilisateur supprimé',
  'corporateAccounts.admin.invite.success': 'Invitations envoyées !',
  'corporateAccounts.admin.newCorporations': 'Nouvelles entreprises',
  'corporateAccounts.admin.payment': 'Paiement',
  'corporateAccounts.admin.paymentInfo': 'Information de paiement',
  'corporateAccounts.admin.search': "Recherche par nom d'entreprise, nom abrégé ou ID",
  'corporateAccounts.admin.update.success': 'Utilisateur mis à jour',
  'corporateAccounts.admins.actions.addUser': 'Ajouter un administrateur',
  'corporateAccounts.admins.actions.deleteUser': 'Supprimer cet utilisateur?',
  'corporateAccounts.admins.actions.sendInviteMessage':
    "L'invitation expirera dans {hours}h. Envoyer une invitation aux administrateurs sélectionnés?",
  'corporateAccounts.admins.activate.error.message':
    "Veuillez vous connecter pour terminer l'activation",
  'corporateAccounts.admins.activate.error.title': "Échec de l'activation",
  'corporateAccounts.admins.corporateClientId': 'ID',
  'corporateAccounts.admins.corporationName': 'Entreprise',
  'corporateAccounts.admins.deactivate.banner.message':
    'Ce compte client est désactivé. Les collaborateurs ne pourront pas accéder aux informations associées à cette société.',
  'corporateAccounts.admins.deactivate.error.body':
    'La désactivation du compte {corporateClientName} a échoué. Veuillez réessayer.',
  'corporateAccounts.admins.deactivate.error.title': 'Erreur de désactivation',
  'corporateAccounts.admins.deactivate.modal.confirm.body':
    "<p>Êtes-vous de vouloir désactiver le compte <strong>{corporateClientName}</strong> ?</p><p>Jusqu'à ce que ce compte soit réactiver, les accès administrateurs seront bloqués et tous les collaborateurs seront dissociés de cette entreprise.</p>",
  'corporateAccounts.admins.deactivate.modal.confirm.title': 'Désactivation du compte',
  'corporateAccounts.admins.deactivate.success': 'Compte: {corporateClientName} a été désactivé',
  'corporateAccounts.admins.delete.error.body':
    'Échec de la suppression du compte {corporateClientName}.',
  'corporateAccounts.admins.delete.error.title': 'Erreur de suppression.',
  'corporateAccounts.admins.delete.modal.confirm.body':
    '<p>Voulez-vous vraiment supprimer définitivement le compte <strong>{corporateClientName}</strong> ?</p><p>Cette action ne peut pas être annulée.</p>',
  'corporateAccounts.admins.delete.modal.confirm.title': 'Suppression du compte',
  'corporateAccounts.admins.delete.success': 'Le compte a été supprimé',
  'corporateAccounts.admins.editUser': "Modifier l'utilisateur admin",
  'corporateAccounts.admins.email': 'Courriel',
  'corporateAccounts.admins.firstName': 'Prénom',
  'corporateAccounts.admins.form.emailPlaceholder': 'Ajouter un courriel',
  'corporateAccounts.admins.form.emailRequired': 'Veuillez entrer une adresse e-mail',
  'corporateAccounts.admins.form.firstNamePlaceholder': 'Ajouter un prénom',
  'corporateAccounts.admins.form.firstNameRequired': 'Veuillez entrer un prénom',
  'corporateAccounts.admins.form.lastNamePlaceholder': 'Ajouter le nom',
  'corporateAccounts.admins.form.lastNameRequired': 'Veuillez entrer un nom',
  'corporateAccounts.admins.form.phoneRequired': 'Veuillez entrer un numéro de téléphone',
  'corporateAccounts.admins.form.roleRequired': 'Veuillez sélectionner un rôle',
  'corporateAccounts.admins.form.title.edit': "Modifier l'utilisateur admin",
  'corporateAccounts.admins.form.title.new': 'Ajouter un utilisateur admin',
  'corporateAccounts.admins.lastName': 'Nom',
  'corporateAccounts.admins.main.title': 'Administrateurs',
  'corporateAccounts.admins.modal.downgradeAccountOwner.content':
    "Seul le passage d'un utilisateur admin actif au statut de Titulaire de compte est autorisé.",
  'corporateAccounts.admins.modal.downgradeAccountOwner.title':
    'Déclassement du Titulaire du compte',
  'corporateAccounts.admins.modal.upgradeBusinessManager.content':
    'Le Titulaire du compte actuel sera rétrogradé au rang de Gestionnaire de flotte principal, souhaitez-vous continuer ?',
  'corporateAccounts.admins.modal.upgradeBusinessManager.title':
    'Mettre à niveau le nouveau Business Manager',
  'corporateAccounts.admins.phone': 'Téléphone',
  'corporateAccounts.admins.phoneNumber': 'Numéro de téléphone de contact',
  'corporateAccounts.admins.reactivate.error.body':
    'La réactivation du compte client {corporateClientName} a échoué. Veuillez réessayer.',
  'corporateAccounts.admins.reactivate.error.title': 'Erreur de réactivation',
  'corporateAccounts.admins.reactivate.modal.confirm.body':
    '<p>Êtes-vous sûr de vouloir réactiver le compte client <strong>{corporateClientName}</strong>?</p><p>Une fois terminée, tous les collaborateurs seront automatiquement réactivés.</p>',
  'corporateAccounts.admins.reactivate.modal.confirm.title': 'Réactivation du compte client',
  'corporateAccounts.admins.reactivate.success':
    'Compte client : {corporateClientName} a été réactivé',
  'corporateAccounts.admins.role': 'Rôle',
  'corporateAccounts.admins.role.faq': 'Que signifient ces autorisations?',
  'corporateAccounts.admins.role.faq.title': 'Détails de profil',
  'corporateAccounts.admins.role.fleet_manager': 'Gestionnaire de flotte principal',
  'corporateAccounts.admins.role.fleet_manager.description':
    "Accès à toutes les fonctionnalités, sauf mettre à jour les informations d'abonnement.",
  'corporateAccounts.admins.role.primary_fleet_manager': 'Titulaire du compte',
  'corporateAccounts.admins.role.primary_fleet_manager.description':
    'Accès à toutes les fonctionnalités.',
  'corporateAccounts.admins.role.reporting': 'Comptable',
  'corporateAccounts.admins.role.reporting.description':
    'Accès aux rapports de stationnement seulement.',
  'corporateAccounts.admins.role.service_admin': 'Gestionnaire de flotte secondaire',
  'corporateAccounts.admins.role.service_admin.description':
    'Accès à toutes les fonctionnalités, sauf mettre à jour les moyens de paiement entreprise.',
  'corporateAccounts.admins.status.activating': 'Activation...',
  'corporateAccounts.admins.status.completeActivation':
    "Veuillez vous connecter pour terminer l'activation",
  'corporateAccounts.admins.status.reinviteRequired': 'Nouvelle invitation requise',
  'corporateAccounts.auditLogs.auditDetails.description':
    '{auditLogUserAction} le {auditLogDate} par {user}',
  'corporateAccounts.auditLogs.auditDetails.id': "ID de l'entité",
  'corporateAccounts.auditLogs.auditDetails.title': "Détails de l'audit",
  'corporateAccounts.auditLogs.auditDetails.workflowId': 'Workflow ID',
  'corporateAccounts.auditLogs.emptyState.description': "Ce compte n'a pas de logs",
  'corporateAccounts.auditLogs.searchInput.placeholder':
    "Recherche par collaborateur, véhicule, nom d'utilisateur",
  'corporateAccounts.auditLogs.table.clickForDetails': "Cliquez pour plus d'informations",
  'corporateAccounts.auditLogs.tableColumn.userAction': "Action de l'utilisateur",
  'corporateAccounts.auditLogs.tableColumn.userRole': "Rôle de l'utilisateur",
  'corporateAccounts.auth.redirectMessage':
    'Vous allez être redirigé vers la page de connexion pour vous identifier à nouveau. Merci.',
  'corporateAccounts.auth.sessionExpired': 'Votre session a expiré.',
  'corporateAccounts.billing.billingAddress': 'Adresse de facturation',
  'corporateAccounts.billing.subscription': 'Abonnement',
  'corporateAccounts.breadcrumbs.adminUsers': 'Accès administrateurs',
  'corporateAccounts.breadcrumbs.create': 'Ajouter',
  'corporateAccounts.breadcrumbs.drivers': 'Collaborateurs',
  'corporateAccounts.breadcrumbs.edit': 'Modifier',
  'corporateAccounts.breadcrumbs.monthlyStatements': 'Relevés mensuels',
  'corporateAccounts.breadcrumbs.payments': 'Moyens de paiement',
  'corporateAccounts.breadcrumbs.reports': 'Rapports',
  'corporateAccounts.breadcrumbs.sessions': 'Stationnements',
  'corporateAccounts.breadcrumbs.upload': 'Importer',
  'corporateAccounts.breadcrumbs.vehicles': 'Véhicules',
  'corporateAccounts.button.addSingleDriver': 'Ajouter un collaborateur',
  'corporateAccounts.button.addSingleVehicle': 'Ajouter un seul véhicule',
  'corporateAccounts.button.bulkUpload': 'Import groupé',
  'corporateAccounts.common.all': 'Tous',
  'corporateAccounts.common.contact': 'Contacter',
  'corporateAccounts.common.contactSales': 'Contacter le service commercial',
  'corporateAccounts.common.costCenterInfo':
    "Les centres de coûts peuvent être utilisés pour affecter les dépenses effectuées par les collaborateurs à un département ou à un projet spécifique. Vous pouvez également l'utiliser pour des références internes telles que le numéro associé au collaborateur.",
  'corporateAccounts.common.date': 'Date',
  'corporateAccounts.common.details': 'Détails',
  'corporateAccounts.common.directDebit': 'Prélèvement automatique',
  'corporateAccounts.common.download': 'Télécharger',
  'corporateAccounts.common.driver': 'Collaborateur',
  'corporateAccounts.common.empty': 'vide',
  'corporateAccounts.common.error': 'Erreur',
  'corporateAccounts.common.error.characterCountExceeded':
    'Le champ ne doit pas dépasser 75 caractères',
  'corporateAccounts.common.error.somethingWentWrong':
    'Une erreur s’est produite. Veuillez réessayer.',
  'corporateAccounts.common.errorCode': "Code d'erreur",
  'corporateAccounts.common.errorCode.copy':
    "Veuillez copier le code d'erreur ci-dessus et le communiquer à l'équipe support pour obtenir de l'aide.",
  'corporateAccounts.common.frequency.weekly': 'Hebdomadaire',
  'corporateAccounts.common.help': 'Chat en ligne',
  'corporateAccounts.common.invitationCode': "Code d'invitation",
  'corporateAccounts.common.invitationStatus': "Statut de l'invitation",
  'corporateAccounts.common.month': 'Mois',
  'corporateAccounts.common.new': 'Nouveau',
  'corporateAccounts.common.no': 'Non',
  'corporateAccounts.common.password': 'Mot de passe',
  'corporateAccounts.common.password.length': 'de 8 à 51 caractères',
  'corporateAccounts.common.password.lowercase': 'Une minuscule',
  'corporateAccounts.common.password.number': 'Un chiffre',
  'corporateAccounts.common.password.specialChar': 'Un caractère spécial',
  'corporateAccounts.common.password.uppercase': 'Une majuscule',
  'corporateAccounts.common.passwordRequired': 'Veuillez saisir un mot de passe',
  'corporateAccounts.common.pleaseTryAgain': 'Veuillez réessayer.',
  'corporateAccounts.common.privacyPolicy': 'Politique de confidentialité',
  'corporateAccounts.common.questions': 'Questions',
  'corporateAccounts.common.remove': 'Retirer',
  'corporateAccounts.common.rowNumber': 'Numéro de ligne',
  'corporateAccounts.common.saveSuccessful': 'Enregistrement effectué',
  'corporateAccounts.common.search': 'Rechercher',
  'corporateAccounts.common.status': 'Statut',
  'corporateAccounts.common.status.active': 'Actif',
  'corporateAccounts.common.status.added': 'Ajouté',
  'corporateAccounts.common.status.bulkAdded': 'Import groupé ajouté',
  'corporateAccounts.common.status.bulkDeleted': 'Import groupé supprimé',
  'corporateAccounts.common.status.close': '',
  'corporateAccounts.common.status.closed': 'Fermé',
  'corporateAccounts.common.status.deactivated': 'Désactivé',
  'corporateAccounts.common.status.deleted': 'Supprimé',
  'corporateAccounts.common.status.expired': 'Expiré',
  'corporateAccounts.common.status.inactive': 'Inactif',
  'corporateAccounts.common.status.inviteExpired': "L'invitation a expiré",
  'corporateAccounts.common.status.inviteFailed': "L'invitation a échoué",
  'corporateAccounts.common.status.inviteSent': 'Invitation envoyée',
  'corporateAccounts.common.status.invited': 'Invité',
  'corporateAccounts.common.status.modified': 'Modifié',
  'corporateAccounts.common.status.new': 'Nouveau',
  'corporateAccounts.common.status.notCompleted': 'Non terminé',
  'corporateAccounts.common.status.notInvited': 'Non invité',
  'corporateAccounts.common.status.onHold': 'En attente',
  'corporateAccounts.common.status.onboarding': "Processus d'intégration",
  'corporateAccounts.common.status.passive': 'Passif',
  'corporateAccounts.common.status.pending': 'En attente',
  'corporateAccounts.common.status.previous': 'Précédent',
  'corporateAccounts.common.status.prospect': 'Prospect',
  'corporateAccounts.common.status.rejected': 'Rejeté',
  'corporateAccounts.common.subscriptionAgreement':
    "Conditions générales d'abonnement à PayByPhone Business",
  'corporateAccounts.common.switchToDesktop': "Passer à l'affichage Bureau",
  'corporateAccounts.common.switchToDesktop.description':
    "Vous essayez d'afficher le portail à partir d'un navigateur mobile. Connectez-vous à votre compte PayByPhone Business depuis un ordinateur de bureau ou un ordinateur portable pour bénéficier d'une meilleure expérience.",
  'corporateAccounts.common.termsAndConditions': 'Conditions générales',
  'corporateAccounts.common.termsAndPrivacyAgreementRequired':
    "Veuillez accepter les conditions et l'accord de confidentialité",
  'corporateAccounts.common.type': 'Type',
  'corporateAccounts.common.unexpectedError': "Une erreur inattendue s'est produite",
  'corporateAccounts.common.update': 'Mettre à jour',
  'corporateAccounts.common.upload': 'Importer le fichier CSV',
  'corporateAccounts.common.user': 'Utilisateur',
  'corporateAccounts.common.vehicle': 'Véhicule',
  'corporateAccounts.common.year': 'Année',
  'corporateAccounts.common.yes': 'Oui',
  'corporateAccounts.copyright': '2019 PayByPhone Technologies. Tous droits réservés.',
  'corporateAccounts.copyright.allRightsReserved': 'Tous droits réservés',
  'corporateAccounts.corporateClient.SpaCards': 'Cartes SPA',
  'corporateAccounts.corporateClient.accountOwnerStatus': 'Statut du propriétaire du compte',
  'corporateAccounts.corporateClient.accountType': 'Type de compte',
  'corporateAccounts.corporateClient.accountType.makeTestAccount': "S'agit-il d'un compte test ?",
  'corporateAccounts.corporateClient.accountType.standard': 'Standard',
  'corporateAccounts.corporateClient.accountType.test': 'Test',
  'corporateAccounts.corporateClient.activate.error.message':
    "Nous avons rencontré un problème lors de l'activation de votre compte. Veuillez réessayer, et si le problème persiste, veuillez contacter notre équipe Support.",
  'corporateAccounts.corporateClient.address.error.presence': 'Veuillez saisir une adresse',
  'corporateAccounts.corporateClient.billing.Info':
    'A noter : PayByPhone va émettre une facture. Veuillez vous assurer que vos informations de facturation sont à jour.',
  'corporateAccounts.corporateClient.billing.edit.error.message':
    "Une erreur s'est produite lors de la mise à jour de l'adresse de facturation. Veuillez réessayer.",
  'corporateAccounts.corporateClient.billing.edit.error.title':
    "Erreur : Modifier l'adresse de facturation",
  'corporateAccounts.corporateClient.billing.edit.success': 'Adresse de facturation mise à jour.',
  'corporateAccounts.corporateClient.city': 'Ville',
  'corporateAccounts.corporateClient.city.error.presence': 'Veuillez saisir une ville',
  'corporateAccounts.corporateClient.clientStatus': 'Statut client',
  'corporateAccounts.corporateClient.companyAddress1': "Adresse de l'entreprise, ligne 1",
  'corporateAccounts.corporateClient.companyAddress2': "Adresse de l'entreprise, ligne 2",
  'corporateAccounts.corporateClient.corporationNumber': "Numéro de l'entreprise",
  'corporateAccounts.corporateClient.corporationNumber.error.presence':
    'Veuillez saisir le numéro de société',
  'corporateAccounts.corporateClient.country': 'Pays',
  'corporateAccounts.corporateClient.country.error.presence': 'Veuillez sélectionner un pays',
  'corporateAccounts.corporateClient.country.placeholder': 'Sélectionnez un pays',
  'corporateAccounts.corporateClient.create.error':
    "Nous n'avons pas pu créer le client d'entreprise.",
  'corporateAccounts.corporateClient.create.success': 'Entreprise créée.',
  'corporateAccounts.corporateClient.createAccount': 'Créer un compte',
  'corporateAccounts.corporateClient.crnNo': 'Numero SIRET',
  'corporateAccounts.corporateClient.dateCreated': 'Date de création',
  'corporateAccounts.corporateClient.edit.error':
    "Nous n'avons pas pu mettre à jour le client d'entreprise.",
  'corporateAccounts.corporateClient.edit.success': 'Entreprise mise à jour.',
  'corporateAccounts.corporateClient.email': 'Adresse e-mail (Titulaire du compte)',
  'corporateAccounts.corporateClient.fleetSize': 'Nombre de conducteurs',
  'corporateAccounts.corporateClient.fleetSize.error.presence':
    'Veuillez sélectionner le nombre de conducteurs',
  'corporateAccounts.corporateClient.fleetSize.tooltip':
    "Vous pouvez fournir un chiffre approximatif. Cela ne vous empêchera pas d'ajouter d'autres conducteurs par la suite.",
  'corporateAccounts.corporateClient.industry': "Secteur d'activité de l'entreprise",
  'corporateAccounts.corporateClient.industry.placeholder': "Sélectionner un secteur d'activité",
  'corporateAccounts.corporateClient.industryOption.carRental': 'Location de voitures',
  'corporateAccounts.corporateClient.industryOption.cleaning': 'Service de nettoyage',
  'corporateAccounts.corporateClient.industryOption.construction': 'Construction',
  'corporateAccounts.corporateClient.industryOption.delivery': 'Service de livraison',
  'corporateAccounts.corporateClient.industryOption.electricVehicles': 'Véhicules électriques',
  'corporateAccounts.corporateClient.industryOption.electrical':
    'Entretien des équipements, appareils et composants électriques',
  'corporateAccounts.corporateClient.industryOption.engineering': 'Ingénierie',
  'corporateAccounts.corporateClient.industryOption.foodService': 'Alimentation et services',
  'corporateAccounts.corporateClient.industryOption.furniture':
    'Meubles et fabrication de produits connexes',
  'corporateAccounts.corporateClient.industryOption.government': 'Gouvernement',
  'corporateAccounts.corporateClient.industryOption.healthcare': 'Santé et médecine',
  'corporateAccounts.corporateClient.industryOption.higherEducation': 'Enseignement supérieur',
  'corporateAccounts.corporateClient.industryOption.hospitals': 'Hôpitaux',
  'corporateAccounts.corporateClient.industryOption.hotelChain': 'Hôtel / Chaîne',
  'corporateAccounts.corporateClient.industryOption.insurance': 'Assurance',
  'corporateAccounts.corporateClient.industryOption.landscape':
    'Aménagement paysager et architecture',
  'corporateAccounts.corporateClient.industryOption.manufacturing':
    'Industrie manufacturière, transport et logistique',
  'corporateAccounts.corporateClient.industryOption.mobileHealth': 'Soins à domicile',
  'corporateAccounts.corporateClient.industryOption.mobileSalesTeam':
    'Equipe de vente sur le terrain',
  'corporateAccounts.corporateClient.industryOption.municipal': 'Collectivité',
  'corporateAccounts.corporateClient.industryOption.officeParking': 'Parking de bureaux',
  'corporateAccounts.corporateClient.industryOption.other': 'Autres',
  'corporateAccounts.corporateClient.industryOption.passengerTerminal': 'Terminal passagers',
  'corporateAccounts.corporateClient.industryOption.printing': 'Imprimerie et activités annexes',
  'corporateAccounts.corporateClient.industryOption.realEstate': 'Immobilier',
  'corporateAccounts.corporateClient.industryOption.retail': 'Vente au détail',
  'corporateAccounts.corporateClient.industryOption.specialtyTrade': 'Entrepreneurs spécialisés',
  'corporateAccounts.corporateClient.industryOption.sport': 'Sport et animation',
  'corporateAccounts.corporateClient.industryOption.telecommunications': 'Télécommunications',
  'corporateAccounts.corporateClient.industryOption.tourism': 'Tourisme',
  'corporateAccounts.corporateClient.industryOption.utilities': 'Services',
  'corporateAccounts.corporateClient.name': "Nom de l'entreprise",
  'corporateAccounts.corporateClient.name.error.presence': "Veuillez saisir le nom de l'entreprise",
  'corporateAccounts.corporateClient.officeNumber': 'information complémentaire',
  'corporateAccounts.corporateClient.owner.firstName.error.presence': 'Veuillez saisir un prénom',
  'corporateAccounts.corporateClient.parkingPaymentMethod': 'Moyen de paiement du stationnement',
  'corporateAccounts.corporateClient.payment.accountOwnerEmail': 'E-mail du Titulaire du compte',
  'corporateAccounts.corporateClient.payment.automaticPayment': 'Paiement récurrent automatique',
  'corporateAccounts.corporateClient.payment.cardExpiry': "Date d'expiration de la CB",
  'corporateAccounts.corporateClient.payment.error.valueType': 'Cette valeur doit être un nombre.',
  'corporateAccounts.corporateClient.payment.expiryMonth.error.invalid':
    'Doit être une valeur de mois valide.',
  'corporateAccounts.corporateClient.payment.expiryMonth.error.presence':
    'Veuillez saisir un mois (MM).',
  'corporateAccounts.corporateClient.payment.expiryYear.error':
    "Veuillez saisir une date d'expiration valide.",
  'corporateAccounts.corporateClient.payment.expiryYear.error.presence':
    "Veuillez entrer l'année d'expiration (AAAA).",
  'corporateAccounts.corporateClient.payment.firstSixdigits': '6 premiers chiffres',
  'corporateAccounts.corporateClient.payment.firstSixdigits.error.length':
    'Le champ doit comporter exactement 6 chiffres.',
  'corporateAccounts.corporateClient.payment.firstSixdigits.error.presence':
    'Veuillez saisir les 6 premiers chiffres de la carte bancaire.',
  'corporateAccounts.corporateClient.payment.invoiceSent':
    "Les factures seront envoyées à l'adresse ou aux adresses électroniques suivantes :",
  'corporateAccounts.corporateClient.payment.invoicedManually': 'Facturé manuellement',
  'corporateAccounts.corporateClient.payment.lastFourDigits': '4 derniers chiffres',
  'corporateAccounts.corporateClient.payment.lastFourDigits.error.length':
    'Le champ doit comporter exactement 4 chiffres.',
  'corporateAccounts.corporateClient.payment.lastFourDigits.error.presence':
    'Veuillez saisir les 4 derniers chiffres de la carte bancaire.',
  'corporateAccounts.corporateClient.payment.noInviteSent':
    "Aucune invitation ne sera envoyée une fois que l'organisation aura été créée",
  'corporateAccounts.corporateClient.payment.paymentInfo':
    'Informations sur le paiement du stationnement :',
  'corporateAccounts.corporateClient.payment.subscriptionCommitted':
    "L'entreprise cliente s'est-elle engagée à s'abonner ? (O/N)",
  'corporateAccounts.corporateClient.postalCode': 'Code postal',
  'corporateAccounts.corporateClient.postalCode.error.presence': 'Veuillez saisir un code postal',
  'corporateAccounts.corporateClient.postalCode.error.validity': "Le code postal n'est pas valide.",
  'corporateAccounts.corporateClient.preferredLanguage': 'Préférence de langue des employés',
  'corporateAccounts.corporateClient.preferredLanguage.error.presence':
    'Veuillez sélectionner une langue',
  'corporateAccounts.corporateClient.preferredLanguage.placeholder': 'Sélectionnez une langue',
  'corporateAccounts.corporateClient.region': 'Région',
  'corporateAccounts.corporateClient.region.error.presence': 'Veuillez saisir une région',
  'corporateAccounts.corporateClient.region.placeholder': 'Sélectionnez une région',
  'corporateAccounts.corporateClient.register.addressTitle':
    'Adresse enregistrée pour la facturation',
  'corporateAccounts.corporateClient.register.duplicateNameError.message':
    'Veuillez réessayer avec un nom différent.',
  'corporateAccounts.corporateClient.register.duplicateNameError.title':
    "Erreur : Ce nom de société n'est pas valide",
  'corporateAccounts.corporateClient.register.internalError.message':
    "Une erreur s'est produite lors de l'enregistrement de votre société. Veuillez réessayer.",
  'corporateAccounts.corporateClient.register.internalError.title':
    'Erreur : Enregistrement de la société',
  'corporateAccounts.corporateClient.register.registrationInfo':
    'Votre adresse enregistrée sera affichée sur les factures. Vous pouvez mettre à jour ces informations sur la page Facturation et Abonnement à tout moment.',
  'corporateAccounts.corporateClient.registrationType': 'Inscription',
  'corporateAccounts.corporateClient.registrationType.enterprise': 'Entreprise',
  'corporateAccounts.corporateClient.registrationType.selfRegistered': 'Inscription finalisée',
  'corporateAccounts.corporateClient.restrictionType': 'Type de restriction',
  'corporateAccounts.corporateClient.restrictionType.restricted': 'Restreinte',
  'corporateAccounts.corporateClient.restrictionType.unrestricted': 'Illimitée',
  'corporateAccounts.corporateClient.salesforceId': 'ID de facture',
  'corporateAccounts.corporateClient.salesforceId.error.alphanumeric':
    "L'identifiant Salesforce ne doit comporter que des lettres et des chiffres",
  'corporateAccounts.corporateClient.salesforceId.error.invalid':
    'Identifiant Salesforce non valide',
  'corporateAccounts.corporateClient.salesforceId.error.length':
    "L'identifiant Salesforce doit comporter 15 ou 18 caractères",
  'corporateAccounts.corporateClient.salesforceId.error.presence':
    'Veuillez saisir le Salesforce ID',
  'corporateAccounts.corporateClient.salesforceId.infoMessage':
    'Les identifiants Salesforce sont utilisés pour la facturation. Les clients dont l\'identifiant est incorrect ne seront pas facturés. Cet identifiant se trouve dans Safe Case ID sur la page "Account" dans Salesforce et doit être copié-collé dans ce champ.',
  'corporateAccounts.corporateClient.siretNo': 'N° SIRET',
  'corporateAccounts.corporateClient.stateProvince': 'Région / Province',
  'corporateAccounts.corporateClient.streetAddress': 'Adresse (rue)',
  'corporateAccounts.corporateClient.taxNumber': 'Numéro SIRET',
  'corporateAccounts.corporateClient.taxNumber.error.characterCountExceeded':
    'Veuillez saisir un numéro valide à 14 chiffres ou supprimer tout espace.',
  'corporateAccounts.corporateClient.taxNumber.error.notAlphanumeric':
    'Veuillez saisir uniquement des lettres et des chiffres.',
  'corporateAccounts.corporateClient.taxNumber.error.presence': 'Veuillez saisir un numéro SIRET',
  'corporateAccounts.corporateClient.taxNumber.error_presence': 'Veuillez saisir {taxNumber}',
  'corporateAccounts.corporateClient.version': 'Version',
  'corporateAccounts.corporations.register.subtitle':
    'Nous avons besoin de quelques informations sur votre entreprise',
  'corporateAccounts.corporations.register.title': 'Bienvenue sur PayByPhone Business',
  'corporateAccounts.corporations.title': 'Mes comptes',
  'corporateAccounts.countries.ad': 'Andorre (AD)',
  'corporateAccounts.countries.al': 'Albanie (AL)',
  'corporateAccounts.countries.am': 'Arménie (AM)',
  'corporateAccounts.countries.at': 'Autriche (AT)',
  'corporateAccounts.countries.au': 'Australie (AU)',
  'corporateAccounts.countries.ba': 'Bosnie-Herzégovine (BA)',
  'corporateAccounts.countries.be': 'Belgique (BE)',
  'corporateAccounts.countries.bg': 'Bulgarie (BG)',
  'corporateAccounts.countries.by': 'Biélorussie (BY)',
  'corporateAccounts.countries.ca': 'Canada (CA)',
  'corporateAccounts.countries.ch': 'Suisse (CH)',
  'corporateAccounts.countries.cy': 'Chypre (CY)',
  'corporateAccounts.countries.cz': 'République tchèque (CZ)',
  'corporateAccounts.countries.de': 'Allemagne (DE)',
  'corporateAccounts.countries.dk': 'Danemark (DK)',
  'corporateAccounts.countries.ee': 'Estonie (EE)',
  'corporateAccounts.countries.es': 'Espagne (ES)',
  'corporateAccounts.countries.fi': 'Finlande (FI)',
  'corporateAccounts.countries.fr': 'France (FR)',
  'corporateAccounts.countries.gb': 'Royaume-Uni (GB)',
  'corporateAccounts.countries.gr': 'Grèce (GR)',
  'corporateAccounts.countries.hr': 'Croatie (HR)',
  'corporateAccounts.countries.hu': 'Hongrie (HU)',
  'corporateAccounts.countries.i': 'Slovénie (I)',
  'corporateAccounts.countries.ie': 'Irlande (IE)',
  'corporateAccounts.countries.is': 'Islande (IS)',
  'corporateAccounts.countries.it': 'Italie (IT)',
  'corporateAccounts.countries.li': 'Liechtenstein',
  'corporateAccounts.countries.lt': 'Lituanie (LT)',
  'corporateAccounts.countries.lu': 'Luxembourg (LU)',
  'corporateAccounts.countries.lv': 'Lettonie (LV)',
  'corporateAccounts.countries.mc': 'Monaco (MC)',
  'corporateAccounts.countries.md': 'Moldavie (MD)',
  'corporateAccounts.countries.me': 'Monténégro (ME)',
  'corporateAccounts.countries.mk': 'Macédoine du Nord (MK)',
  'corporateAccounts.countries.mt': 'Malte (MT)',
  'corporateAccounts.countries.nl': 'Pays-Bas (NL)',
  'corporateAccounts.countries.no': 'Norvège (NON)',
  'corporateAccounts.countries.pl': 'Pologne (PL)',
  'corporateAccounts.countries.pt': 'Portugal (PT)',
  'corporateAccounts.countries.ro': 'Roumanie (RO)',
  'corporateAccounts.countries.rs': 'Serbie (RS)',
  'corporateAccounts.countries.ru': 'Russie (RU)',
  'corporateAccounts.countries.se': 'Suède (SE)',
  'corporateAccounts.countries.sk': 'Slovaquie (SK)',
  'corporateAccounts.countries.sm': 'Saint-Marin (SM)',
  'corporateAccounts.countries.tr': 'Turquie (TR)',
  'corporateAccounts.countries.ua': 'Ukraine (UA)',
  'corporateAccounts.countries.us': 'États-Unis (US)',
  'corporateAccounts.countries.va': 'Vatican (VA)',
  'corporateAccounts.creditAccount.authorizedDriversBlurb':
    'Les conducteurs autorisés auront automatiquement un moyen de paiement professionnel lié à leur application PayByPhone.',
  'corporateAccounts.creditAccount.details.accountNumber': 'Numéro de compte',
  'corporateAccounts.creditAccount.details.approvedCreditLimit': 'Limite de crédit approuvée',
  'corporateAccounts.creditAccount.details.availableCredit': 'Crédit disponible',
  'corporateAccounts.creditAccount.details.creditAccountDetails': 'Détails du compte de crédit',
  'corporateAccounts.creditAccount.details.creditAccountId': '',
  'corporateAccounts.creditAccount.details.currentSpend': 'Dépenses actuelles',
  'corporateAccounts.creditAccount.details.expiryDate':
    'Carte se terminant par {cardNumber} expirant le {expiryDate}',
  'corporateAccounts.creditAccount.details.invoicingFrequency': 'Fréquence de facturation',
  'corporateAccounts.creditAccount.details.lastUpdated': 'Dernière mise à jour',
  'corporateAccounts.creditAccount.details.nextPaymentDate': 'Prochaine date de paiement',
  'corporateAccounts.creditAccount.details.overLimitPaymentMethod':
    'Moyen de paiement pour les transactions supérieures à la limite de crédit',
  'corporateAccounts.creditAccount.details.paymentAmountDue': 'Montant du paiement dû',
  'corporateAccounts.creditAccount.details.paymentDetails': 'Détails de paiement',
  'corporateAccounts.creditAccount.details.paymentMethod': 'Moyen de Paiement',
  'corporateAccounts.creditAccount.details.toolTip':
    "Total des frais de stationnement et d'abonnement pour ce cycle de facturation",
  'corporateAccounts.creditAccount.internalError': '',
  'corporateAccounts.creditAccount.migrationStatus': '',
  'corporateAccounts.creditAccount.migrationStatus.new': 'Nouveau',
  'corporateAccounts.creditAccount.migrationStatus.notStarted': '',
  'corporateAccounts.creditAccount.migrationStatus.started': '',
  'corporateAccounts.creditAccount.pageTitle': 'Compte de crédit',
  'corporateAccounts.creditAccount.pendingBanner.description': '',
  'corporateAccounts.creditAccount.rejectedBanner.cta': '',
  'corporateAccounts.creditAccount.rejectedBanner.description': '',
  'corporateAccounts.creditAccount.rejectedBanner.title': '',
  'corporateAccounts.creditAccount.seeFaq': 'Voir la FAQ',
  'corporateAccounts.creditAccount.setUpButton': 'Configurer le compte de crédit',
  'corporateAccounts.creditAccount.status': '',
  'corporateAccounts.creditAccount.stepCard1.subtitle':
    "Nous vous demanderons de vérifier l'adresse de l'entreprise et son numéro SIRET.",
  'corporateAccounts.creditAccount.stepCard1.title': "1. Confirmer les coordonnées de l'entreprise",
  'corporateAccounts.creditAccount.stepCard2.subtitle':
    'Estimez vos dépenses mensuelles de stationnement afin que nous puissions calculer votre limite de crédit.',
  'corporateAccounts.creditAccount.stepCard2.title': '2. Obtenir une pré-approbation',
  'corporateAccounts.creditAccount.stepCard3.subtitle':
    'Autorisez un prélèvement automatique pour payer vos factures en toute tranquillité.',
  'corporateAccounts.creditAccount.stepCard3.title': '3. Configurer le prélèvement automatique',
  'corporateAccounts.creditAccount.subtitle':
    'Une ligne de crédit dédiée à tous vos frais de stationnement.',
  'corporateAccounts.creditAccount.title':
    'Stationnez maintenant, payez plus tard avec PayByPhone Business.',
  'corporateAccounts.creditAccount.type': '',
  'corporateAccounts.creditAccount.type.creditAccount': '',
  'corporateAccounts.creditAccount.type.creditBuilder': '',
  'corporateAccounts.creditAccount.type.hybrid': '',
  'corporateAccounts.creditAccountMigration.error': '',
  'corporateAccounts.creditAccountMigration.error.countryNotSupported': '',
  'corporateAccounts.creditAccountMigration.error.notFound': '',
  'corporateAccounts.creditAccountMigration.success': '',
  'corporateAccounts.creditAccounts.modal.cta': '',
  'corporateAccounts.creditAccounts.modal.description1': '',
  'corporateAccounts.creditAccounts.modal.description2': '',
  'corporateAccounts.creditAccounts.modal.description3': '',
  'corporateAccounts.creditAccounts.modal.description4': '',
  'corporateAccounts.creditAccounts.modal.title': '',
  'corporateAccounts.daysOfWeek.0': 'Dimanche',
  'corporateAccounts.daysOfWeek.1': 'Lundi',
  'corporateAccounts.daysOfWeek.2': 'Mardi',
  'corporateAccounts.daysOfWeek.3': 'Mercredi',
  'corporateAccounts.daysOfWeek.4': 'Jeudi',
  'corporateAccounts.daysOfWeek.5': 'Vendredi',
  'corporateAccounts.daysOfWeek.6': 'Samedi',
  'corporateAccounts.driver.activate.success': 'Activations envoyées',
  'corporateAccounts.driver.activate.warning':
    "Certains collaborateurs ne disposant pas de compte PayByPhone n'ont pas été activés. Veuillez réessayer une fois que ces comptes ont été configurés.",
  'corporateAccounts.driver.add.error': "Nous n'avons pas pu ajouter le conducteur.",
  'corporateAccounts.driver.bulkDelete.success':
    '{numOfDeletedDrivers} collaborateurs ont été supprimés',
  'corporateAccounts.driver.delete.error':
    "Nous n'avons pas pu finaliser la demande de suppression.",
  'corporateAccounts.driver.delete.success': 'Collaborateur supprimé',
  'corporateAccounts.driver.generic.error':
    "Il y a eu un problème de mise à jour de l'employé sélectionné.",
  'corporateAccounts.driver.get.error':
    "Une erreur s'est produite lors de la récupération des informations relatives aux conducteurs.",
  'corporateAccounts.driver.invite.error':
    'Une ou plusieurs invitations ont échoué, veuillez réessayer.',
  'corporateAccounts.driver.invite.success': 'Invitations envoyées',
  'corporateAccounts.driver.limitExceeded.error':
    "Vous avez dépassé le nombre maximum de collaborateurs. Pour l'augmenter, veuillez contacter le service commercial.",
  'corporateAccounts.driver.profileExists.error':
    "Le numéro de téléphone que vous souhaitez ajouter appartient à un autre conducteur. Saisissez le numéro de téléphone dans la barre de recherche sous le format +[code pays]XXXXXXXXX pour localiser l'utilisateur auquel il appartient. Mettez ensuite à jour ses coordonnées ou supprimez-les et ajoutez-les à nouveau.",
  'corporateAccounts.driver.update.error': 'Impossible de modifier le conducteur.',
  'corporateAccounts.driver.update.profileExistsError':
    'Échec de la mise à jour - Un profil de conducteur avec ce numéro de téléphone existe déjà.',
  'corporateAccounts.driver.update.success': 'Employé mis à jour',
  'corporateAccounts.drivers.actions': 'Actions',
  'corporateAccounts.drivers.actions.activate': 'Activer',
  'corporateAccounts.drivers.actions.addParker': 'Ajouter un collaborateur',
  'corporateAccounts.drivers.actions.deleteParkerMessage': 'Supprimer ce collaborateur ?',
  'corporateAccounts.drivers.actions.reinvite': 'Inviter de nouveau.',
  'corporateAccounts.drivers.activationModal.body1':
    "Les collaborateurs ne peuvent pas être activés tant qu'ils n'ont pas configuré de compte PayByPhone.",
  'corporateAccounts.drivers.activationModal.body2':
    "Pour ce faire, téléchargez l'application PayByPhone et enregistrez un compte à l'aide du numéro de téléphone affiché dans votre liste de collaborateurs.",
  'corporateAccounts.drivers.bulkDelete.modal.content':
    "Êtes-vous sûr de vouloir supprimer les collaborateurs sélectionnés et désactiver l'accès au moyen de paiement de l'entreprise?",
  'corporateAccounts.drivers.bulkDelete.modal.title': 'Supprimez des collaborateurs',
  'corporateAccounts.drivers.bulkResendInvite.sucess': 'Invitation(s) envoyée(s)',
  'corporateAccounts.drivers.bulkResendInvite.warning':
    "L'invitation a été envoyée exclusivement aux collaborateurs dont le compte n'est pas encore actif.",
  'corporateAccounts.drivers.bulkResendInviteModal.description':
    "Le lien d'invitation expirera dans une semaine. Les collaborateurs déjà actifs ne recevront pas cette invitation.",
  'corporateAccounts.drivers.bulkResendInviteModal.title': "Renvoyer l'invitation ?",
  'corporateAccounts.drivers.costCenter': 'Centre de coûts',
  'corporateAccounts.drivers.costCenterInfo':
    'Les centres de coûts peuvent être utilisés pour allouer les dépenses effectuées par les conducteurs à un département ou à un projet qui peut être filtré. Ils peuvent aussi être utilisés pour obtenir des références internes comme les identifiants des conducteurs employés.',
  'corporateAccounts.drivers.csvDownload.costCenter': 'Centre de coûts',
  'corporateAccounts.drivers.csvDownload.email': 'Adresse email (Obligatoire)',
  'corporateAccounts.drivers.csvDownload.phoneCountryCode':
    'Code pays [1 / 33 / 44 / etc] (obligatoire)',
  'corporateAccounts.drivers.csvDownload.phoneNumber': 'Numéro de téléphone (obligatoire)',
  'corporateAccounts.drivers.deleteModal.content':
    "Etes-vous sûr(e) de vouloir supprimer ce collaborateur? Une fois supprimé, le collaborateur n'aura plus accès à votre moyen de paiement.",
  'corporateAccounts.drivers.deleteModal.title': 'Supprimer le collaborateur ?',
  'corporateAccounts.drivers.department': 'Département',
  'corporateAccounts.drivers.email': 'Email',
  'corporateAccounts.drivers.employeeId': "Identification de l'employé",
  'corporateAccounts.drivers.emptyState.description':
    "Seuls les collaborateurs autorisés pourront payer leur stationnement avec le moyen de paiement de l'entreprise. Vous pouvez choisir d'inviter les collaborateurs à installer l'application mobile PayByPhone par SMS ou par email. Les collaborateurs peuvent utiliser leur compte existant.",
  'corporateAccounts.drivers.emptyState.importDriversBtn': 'Importer des collaborateurs',
  'corporateAccounts.drivers.emptyState.videoLink': 'Comment inviter vos collaborateurs',
  'corporateAccounts.drivers.export.error.modal.description':
    "Impossible d'exporter la liste des pilotes, veuillez réessayer.",
  'corporateAccounts.drivers.export.error.modal.title':
    "Erreur d'exportation de la liste des pilotes",
  'corporateAccounts.drivers.export.fileName': 'ListeConducteurs',
  'corporateAccounts.drivers.export.modal.description':
    'Les données exportées seront générées en fonction des filtres actuellement appliqués à votre liste de pilotes.',
  'corporateAccounts.drivers.export.modal.title': 'Exporter la liste des pilotes',
  'corporateAccounts.drivers.export.success.notification.description':
    'Votre liste de pilotes a été exportée.',
  'corporateAccounts.drivers.export.success.notification.title': 'Liste de pilotes exportée',
  'corporateAccounts.drivers.firstName': 'Prénom',
  'corporateAccounts.drivers.form.add': 'Ajouter un collaborateur',
  'corporateAccounts.drivers.form.costCenterMaxLength':
    'Il ne faut pas dépasser 15 caractères pour le centre de coûts.',
  'corporateAccounts.drivers.form.emailRequired': 'Veuillez entrer une adresse e-mail',
  'corporateAccounts.drivers.form.emailValidation': 'Veuillez entrer une adresse e-mail valide',
  'corporateAccounts.drivers.form.employeeIdValidation':
    "L'identifiant d'employé ne doit pas dépasser 50 caractères",
  'corporateAccounts.drivers.form.firstNameValidation':
    'Votre prénom ne doit pas dépasser 50 caractères',
  'corporateAccounts.drivers.form.invitationOptions': 'Inviter un collaborateur via',
  'corporateAccounts.drivers.form.inviteOptionsError': 'Veuillez sélectionner au moins une option.',
  'corporateAccounts.drivers.form.jobTitleValidation':
    'Le titre du poste ne doit pas dépasser 50 caractères',
  'corporateAccounts.drivers.form.lastNameValidation':
    'Votre nom ne doit pas dépasser 50 caractères',
  'corporateAccounts.drivers.form.phoneRequired': 'Veuillez entrer un numéro de téléphone',
  'corporateAccounts.drivers.form.phoneValidation': 'Veuillez entrer un numéro de téléphone valide',
  'corporateAccounts.drivers.form.selectInvitationMethod':
    "Sélectionnez le mode d'invitation souhaité",
  'corporateAccounts.drivers.form.sms': 'SMS',
  'corporateAccounts.drivers.form.title.edit': 'Modifier le collaborateur',
  'corporateAccounts.drivers.form.title.new': 'Ajouter un collaborateur',
  'corporateAccounts.drivers.form.update': 'Mettre à jour le chauffeur',
  'corporateAccounts.drivers.groupId': 'Identifiant de groupe',
  'corporateAccounts.drivers.import.error':
    'Certaines ou la totalité des entrées de conducteurs ne sont pas valides. Téléchargez le fichier journal pour plus de détails. Une fois la correction effectuée, réessayez.',
  'corporateAccounts.drivers.import.error.EmailRequired': 'Email manquant',
  'corporateAccounts.drivers.import.error.UnknownParsingError':
    "Une erreur s'est produite lors de l'analyse du fichier",
  'corporateAccounts.drivers.import.error.costCenterMaxLengthExceeded':
    'Longueur maximale atteinte (centre de coûts)',
  'corporateAccounts.drivers.import.error.csvNoRecord': 'Aucun collaborateur valide à importer',
  'corporateAccounts.drivers.import.error.duplicateEntries': 'Conducteurs en double',
  'corporateAccounts.drivers.import.error.failed': "Échec de l'import des collaborateurs",
  'corporateAccounts.drivers.import.error.fileName': 'Fichier journal',
  'corporateAccounts.drivers.import.error.firstNameMaxLengthExceeded':
    'Longueur maximale atteinte (prénom)',
  'corporateAccounts.drivers.import.error.firstNameRequired': 'Prénom manquant',
  'corporateAccounts.drivers.import.error.invalidCostCenter': 'Format incorrect (centre de coûts)',
  'corporateAccounts.drivers.import.error.invalidEmail': 'Format incorrect (e-mail)',
  'corporateAccounts.drivers.import.error.invalidEntries': 'Entrées de conducteurs non valides',
  'corporateAccounts.drivers.import.error.invalidNumbers': 'Format incorrect (numéro de téléphone)',
  'corporateAccounts.drivers.import.error.lastNameMaxLengthExceeded':
    'Longueur maximale atteinte (nom de famille)',
  'corporateAccounts.drivers.import.error.lastNameRequired': 'Nom de famille manquant',
  'corporateAccounts.drivers.import.error.phoneNumberRequired': 'Numéro de téléphone manquant',
  'corporateAccounts.drivers.import.error.uploadFile':
    "Une erreur s'est produite pendant le téléchargement de votre fichier. Assurez-vous de télécharger un fichier CSV valide, puis réessayez.",
  'corporateAccounts.drivers.import.failed': "Échec de l'importation",
  'corporateAccounts.drivers.import.fileName':
    'Modèle du fichier pour importer les collaborateurs.',
  'corporateAccounts.drivers.import.header.costCenter': 'Centre de coûts (optionnel)',
  'corporateAccounts.drivers.import.header.countryCode': 'Indicatif téléphonique du pays',
  'corporateAccounts.drivers.import.header.email': 'Adresse e-mail',
  'corporateAccounts.drivers.import.header.phoneNumber': 'Numéro de téléphone',
  'corporateAccounts.drivers.import.inProgress':
    'Importation en cours de {total} conducteurs (dont {driversAdded} nouveaux et {driversEdited} modifiés)',
  'corporateAccounts.drivers.import.spaModal.title': 'Important, veuillez lire !',
  'corporateAccounts.drivers.import.success':
    '{total} conducteurs ont bien été importés ! ({driversAdded} nouveaux, {driversEdited} modifiés)',
  'corporateAccounts.drivers.import.warning':
    '{total} collaborateur(s) importé(s) avec succès dont {driversAdded} nouveau(x) et {driversEdited} mis à jour. {driversFailed} collaborateur(s) non importé(s).',
  'corporateAccounts.drivers.importModal.editDrivers': 'Pour modifier des conducteurs existants',
  'corporateAccounts.drivers.importModal.editDrivers.1':
    'Téléchargez ce fichier CSV contenant toutes les informations sur les conducteurs.',
  'corporateAccounts.drivers.importModal.editDrivers.2':
    'Modifiez les informations existantes relatives au conducteur.',
  'corporateAccounts.drivers.importModal.editDrivers.3': 'Importer le fichier CSV mis à jour.',
  'corporateAccounts.drivers.importModal.newDrivers': 'Pour importer de nouveaux conducteurs',
  'corporateAccounts.drivers.importModal.newDrivers.1':
    'Ajoutez les informations sur les nouveaux conducteur ligne par ligne.',
  'corporateAccounts.drivers.importModal.title': 'Importer des conducteurs avec un fichier CSV',
  'corporateAccounts.drivers.infograph.card.description':
    "Demander aux collaborateurs de télécharger l'application et créer un compte. Les collaborateurs inactifs devront être désactivés avant de voir votre méthode de paiement. ",
  'corporateAccounts.drivers.infograph.card.title': 'Vos collaborateurs seront listés ici',
  'corporateAccounts.drivers.infograph.cards.0.body':
    "Demandez à vos collaborateurs de télécharger l'application PayByPhone et de créer un compte.",
  'corporateAccounts.drivers.infograph.cards.0.title': 'Informez vos collaborateurs',
  'corporateAccounts.drivers.infograph.cards.1.title': 'Importer une liste de collaborateurs',
  'corporateAccounts.drivers.infograph.cards.2.body':
    'Les collaborateurs inactifs devront être activés avant de pouvoir utiliser le moyen de paiement entreprise.',
  'corporateAccounts.drivers.infograph.cards.2.title': 'Activer les collaborateurs',
  'corporateAccounts.drivers.infograph.subcontent':
    'Les collaborateurs avec le statut "Inactif" peuvent être activés grâce au bouton "Activer". Les numéros de téléphone des comptes PayByPhone doivent correspondre à ceux renseignés dans le portail.',
  'corporateAccounts.drivers.inviteDriverToolTip':
    'Un lien d\'invitation va être envoyé à votre collaborateur. Cette invitation sera valable pendant 7 jours. Une fois acceptée, le statut du collaborateur passera à Actif dans le portail. Si l\'invitation est expirée, vous pouvez en renvoyer une en utilisant le bouton "Plus" situé sur la ligne du collaborateur concerné.',
  'corporateAccounts.drivers.jobTitle': 'Titre du poste',
  'corporateAccounts.drivers.lastName': 'Nom',
  'corporateAccounts.drivers.main.title': 'Collaborateurs',
  'corporateAccounts.drivers.mobilePhoneNumber': 'Numéro de mobile',
  'corporateAccounts.drivers.resendInviteModal.description': 'Le lien expirera dans une semaine.',
  'corporateAccounts.drivers.restrictedAction.add.content':
    'Cette action est temporairement limitée, mais votre gestionnaire de compte PaybyPhone pourra vous aider dans ce processus, afin que vos employés puissent commencer à se garer tout de suite !',
  'corporateAccounts.drivers.restrictedAction.add.title':
    "Besoin d'ajouter de nouveaux collaborateurs ?",
  'corporateAccounts.drivers.restrictedAction.edit.content':
    'Cette action est temporairement limitée, mais votre gestionnaire de compte PaybyPhone pourra vous aider dans ce processus.',
  'corporateAccounts.drivers.restrictedAction.edit.title': 'Besoin de modifier un collaborateur ?',
  'corporateAccounts.drivers.restrictedAction.upload.title':
    "Besoin d'importer des collaborateurs ?",
  'corporateAccounts.drivers.search.placeholder': 'Collaborateurs',
  'corporateAccounts.drivers.workPhoneNumber': 'Numéro de téléphone professionnel',
  'corporateAccounts.errors.fileUpload':
    "Une erreur s'est produite pendant le téléchargement. Veuillez réessayer.",
  'corporateAccounts.errors.unauthorized.content':
    "Vous n'êtes affecté à aucune société, veuillez contacter le service d'assistance.",
  'corporateAccounts.errors.unauthorized.title': 'Accès non autorisé',
  'corporateAccounts.feedbackModal.button': 'Envoyer vos commentaires',
  'corporateAccounts.feedbackModal.feedback.description':
    'Vous avez des commentaires ou souhaitez nous soumettre une demande de fonctionnalité ?',
  'corporateAccounts.feedbackModal.feedback.placeholder': 'Saisissez vos commentaires ici',
  'corporateAccounts.feedbackModal.feedback.subDescription':
    'Nous serions ravis de connaître votre avis et vos commentaires seront étudiés par notre équipe de développement produit.',
  'corporateAccounts.feedbackModal.feedback.success': 'Merci de vos commentaires.',
  'corporateAccounts.feedbackModal.support.button': "Soumettre une demande d'assistance",
  'corporateAccounts.feedbackModal.support.description':
    "Vous avez besoin d'une assistance technique ou souhaitez signaler un bug ?",
  'corporateAccounts.feedbackModal.title': "Retour d'expérience",
  'corporateAccounts.fileUpload.loading': 'Cette opération peut prendre quelques secondes…',
  'corporateAccounts.globalNotification.maintenanceMessage':
    "Une opération de maintenance est prévue le {weekday}, {monthDay}, entre {startTime} et {endTime}. L'accès à PayByPhone Business ne sera pas disponible pendant cette période.",
  'corporateAccounts.globalNotification.maintenancePage.restoreTime':
    'Le site Web sera rétabli à {maintenanceEndHour}. Merci pour votre patience.',
  'corporateAccounts.globalNotification.maintenancePage.underGoingScheduledMaintenance':
    'Une maintenance planifiée est en cours sur notre site Web.',
  'corporateAccounts.globalNotification.maintenancePage.underScheduleMaintenance':
    'Site Web en cours de maintenance planifiée...',
  'corporateAccounts.homePage': "Retourner à la page d'accueil",
  'corporateAccounts.import.downloadCsv': 'Télécharger ce modèle CSV',
  'corporateAccounts.import.downloadCsvTemplate': 'Télécharger ce modèle de fichier CSV',
  'corporateAccounts.import.driver.success':
    "L'importation des données employés peut prendre plusieurs heures.",
  'corporateAccounts.import.emailNotify.success':
    'Une notification par email vous sera envoyée une fois le processus terminé.',
  'corporateAccounts.import.importData': 'Importer des données',
  'corporateAccounts.import.maxFileSize.error':
    'Seuls les fichiers inférieurs ou égaux à 1 Mo sont acceptés pour le moment',
  'corporateAccounts.import.singleFile.error':
    'Un seul fichier par import est autorisé pour le moment.',
  'corporateAccounts.import.step.1.title': 'Étape 1:',
  'corporateAccounts.import.step.2.title': 'Étape 2:',
  'corporateAccounts.import.step.3.title': 'Étape 3:',
  'corporateAccounts.import.step.5.content': 'Cliquez sur Importer les données ci-dessous.',
  'corporateAccounts.import.title.success': 'Fichier importé',
  'corporateAccounts.import.vehicle.success': 'Les données des véhicules sont importés avec succès',
  'corporateAccounts.infograph.drivers.cards.0.body':
    "Une fois que vous avez un moyen de paiement et des véhicules ajoutés, il est temps d'entrer les détails de vos collaborateurs sur le portail.",
  'corporateAccounts.infograph.drivers.cards.0.title': 'AJOUTER UN COLLABORATEUR',
  'corporateAccounts.infograph.drivers.cards.1.body':
    "Choisissez d'envoyer une invitation automatique à vos collaborateurs. Afficher le processus d'invitation",
  'corporateAccounts.infograph.drivers.cards.1.title': 'ENVOYER UNE INVITATION',
  'corporateAccounts.infograph.drivers.cards.2.body':
    'Une fois que vos comptes collaborateurs seront liés, ils seront en mesure de payer leurs stationnements professionnels avec votre moyen de paiement entreprise.',
  'corporateAccounts.infograph.drivers.cards.2.title': 'CONFIRMER MAINTENANT',
  'corporateAccounts.infograph.drivers.note':
    'Pour une meilleure expérience, nul besoin de créer un compte professionnel distinct, le compte est automatiquement associé au compte existant de votre collaborateur.',
  'corporateAccounts.infograph.payments.cards.0.body':
    "Facilitez la gestion des paiements de stationnement en partageant un moyen de paiement d'entreprise crypté avec vos collaborateurs.",
  'corporateAccounts.infograph.payments.cards.0.title': 'AJOUTER UN MOYEN DE PAIEMENT',
  'corporateAccounts.infograph.payments.cards.1.body':
    "Donnez un nom à votre moyen de paiement pour que vos employés puissent la reconnaître facilement dans l'application.",
  'corporateAccounts.infograph.payments.cards.1.title': 'DÉFINIR LE NOM DE LA CB',
  'corporateAccounts.infograph.payments.cards.2.body':
    "Une fois que vous avez enregistré votre carte, elle sera accessible sur l'appareil de vos collaborateurs.",
  'corporateAccounts.infograph.payments.cards.2.title': 'CONFIRMER MAINTENANT',
  'corporateAccounts.infograph.payments.note':
    "Vos collaborateurs ne pourront voir que le nom de votre carte. Ils n'auront plus besoin des détails de la carte et du CVV pour régler leur stationnement.",
  'corporateAccounts.infograph.vehicles.cards.0.body':
    "Entrez un véhicule dans le portail afin que vos collaborateurs puissent l'utiliser à travers l'application PayByPhone.",
  'corporateAccounts.infograph.vehicles.cards.0.title': 'AJOUTER UN VÉHICULE',
  'corporateAccounts.infograph.vehicles.cards.1.body':
    "Donnez un nom à votre véhicule. Cela ne sera pas affiché dans l'application, mais vous aidera lors du filtrage de votre liste.",
  'corporateAccounts.infograph.vehicles.cards.1.title': 'NOM DU VÉHICULE',
  'corporateAccounts.infograph.vehicles.cards.2.body':
    "Une fois que vous avez sauvegardé le véhicule, vos collaborateurs pourront y accéder et l'ajouter à leurs comptes.",
  'corporateAccounts.infograph.vehicles.cards.2.title': 'CONFIRMER MAINTENANT',
  'corporateAccounts.infograph.vehicles.note':
    "Vos collaborateurs ne pourront ajouter que les véhicules de l'entreprise inscrits dans cette liste. Les véhicules ne peuvent pas être liés à un collaborateur spécifique avec le portail.",
  'corporateAccounts.invoiceIdValidation.invalidError': 'Salesforce ID non valide',
  'corporateAccounts.languages.de': 'Allemand',
  'corporateAccounts.languages.fr': 'Français',
  'corporateAccounts.languages.gb': 'Anglais (GB)',
  'corporateAccounts.languages.glb': 'International',
  'corporateAccounts.languages.it': 'Italien',
  'corporateAccounts.languages.nl': 'Néerlandais',
  'corporateAccounts.languages.us': 'Anglais',
  'corporateAccounts.menuLeft.account': 'Compte',
  'corporateAccounts.menuLeft.accounts': 'Comptes',
  'corporateAccounts.menuLeft.adminUsers': 'Accès Administrateurs',
  'corporateAccounts.menuLeft.admins': 'Administrateurs',
  'corporateAccounts.menuLeft.auditLogs': 'Audit des logs',
  'corporateAccounts.menuLeft.billing': 'Facturation et abonnement',
  'corporateAccounts.menuLeft.creditAccount': 'Compte de crédit',
  'corporateAccounts.menuLeft.drivers': 'Collaborateurs',
  'corporateAccounts.menuLeft.monthlyStatements': 'Relevés mensuels',
  'corporateAccounts.menuLeft.payments': 'Moyens de paiement',
  'corporateAccounts.menuLeft.reports': 'Rapports',
  'corporateAccounts.menuLeft.sessions': 'Stationnements récents',
  'corporateAccounts.menuLeft.staffUsers': 'Utilisateurs professionnels de PBP',
  'corporateAccounts.menuLeft.superAdminHome': 'Accueil du Tituaire du compte',
  'corporateAccounts.menuLeft.trigger': 'Réduire la barre latérale',
  'corporateAccounts.menuLeft.vehicles': 'Véhicules',
  'corporateAccounts.month.1': 'Janvier',
  'corporateAccounts.month.10': 'Octobre',
  'corporateAccounts.month.11': 'Novembre',
  'corporateAccounts.month.12': 'Décembre',
  'corporateAccounts.month.2': 'Février',
  'corporateAccounts.month.3': 'Mars',
  'corporateAccounts.month.4': 'Avril',
  'corporateAccounts.month.5': 'Mai',
  'corporateAccounts.month.6': 'Juin',
  'corporateAccounts.month.7': 'Juillet',
  'corporateAccounts.month.8': 'Août',
  'corporateAccounts.month.9': 'Septembre',
  'corporateAccounts.monthlyStatements.downloadStatement': 'Télécharger le relevé de stationnement',
  'corporateAccounts.monthlyStatements.generatedOn': 'Date de génération',
  'corporateAccounts.monthlyStatements.title': 'Relevés mensuels',
  'corporateAccounts.notFound': 'Page non trouvée',
  'corporateAccounts.notification.reportReady.description':
    'Les rapports mensuels exportés seront disponibles sous « Rapports mensuels » pendant 72 heures.',
  'corporateAccounts.notification.reportReady.title': 'Votre rapport est prêt',
  'corporateAccounts.notify.error.auditLogs':
    "Une erreur s'est produite lors de l'obtention des audit logs",
  'corporateAccounts.notify.error.downloadMonthlyStatement':
    "Une erreur s'est produite lors du téléchargement du relevé de stationnement mensuel.",
  'corporateAccounts.notify.error.getMonthlyStatements':
    "Une erreur s'est produite lors du chargement des relevés de stationnement mensuels.",
  'corporateAccounts.onboarding.actions.openPayByPhone': 'Ouvrir PayByPhone',
  'corporateAccounts.onboarding.activationFailed': "Impossible d'associer votre compte",
  'corporateAccounts.onboarding.activationFailed.message':
    "Le jumelage de votre compte a échoué suite à un problème technique. Merci de réessayer plus tard. Si l'erreur persiste, veuillez contacter votre administrateur.",
  'corporateAccounts.onboarding.addDriverStep.description':
    'Ajouter des collaborateurs sur votre compte entreprise.',
  'corporateAccounts.onboarding.addDriverStep.title': 'Inviter des collaborateurs',
  'corporateAccounts.onboarding.addVehicleStep.description':
    'Établir une liste des véhicules professionnels autorisés.',
  'corporateAccounts.onboarding.addVehicleStep.title': 'Ajouter des véhicules',
  'corporateAccounts.onboarding.corporationNotFound': "La société n'existe pas",
  'corporateAccounts.onboarding.corporationNotFound.message':
    "Compte entreprise introuvable. Merci de réessayer plus tard. Si l'erreur persiste, veuillez contacter votre administrateur.",
  'corporateAccounts.onboarding.driverAlreadyActive': 'Compte déjà associé',
  'corporateAccounts.onboarding.driverAlreadyActive.message':
    'Votre compte a déjà été associé à une société',
  'corporateAccounts.onboarding.driverNotFound': 'Impossible de trouver le collaborateur',
  'corporateAccounts.onboarding.driverNotFound.message':
    "Vous devez avoir un compte PayByPhone pour accepter l'invitation. Veuillez vous inscrire à PayByPhone et demander à votre entreprise de vous inviter à nouveau.",
  'corporateAccounts.onboarding.internalError': 'Une erreur est survenue',
  'corporateAccounts.onboarding.internalError.message':
    "Votre requête n'a pas pu aboutir. Merci de réessayer plus tard. Si l'erreur persiste, veuillez contacter votre administrateur.",
  'corporateAccounts.onboarding.invitationExpired': "Le lien d'invitation a expiré",
  'corporateAccounts.onboarding.invitationExpired.message':
    "Veuillez demander à votre administrateur de renvoyer l'invitation.",
  'corporateAccounts.onboarding.inviteCodeNotFound': "Le code d'invitation n'a pas été trouvé",
  'corporateAccounts.onboarding.inviteCodeNotFound.message':
    "Veuillez contacter votre administrateur pour obtenir un nouveau lien afin de compléter le processus d'activation.",
  'corporateAccounts.onboarding.inviteCodeNotValid': "Le code d'invitation n'est pas valide",
  'corporateAccounts.onboarding.inviteCodeNotValid.message':
    "Veuillez contacter votre administrateur pour obtenir un nouveau lien afin de compléter le processus d'activation.",
  'corporateAccounts.onboarding.logInToStartParking':
    'Connectez-vous à PayByPhone et commencez à stationner !',
  'corporateAccounts.onboarding.progressBar.description':
    "Nous avons mis en place une liste d'actions à effectuer pour vous aider à démarrer.",
  'corporateAccounts.onboarding.progressBar.title': "C'est parti !",
  'corporateAccounts.onboarding.registration.formDescription':
    "{companyName} vous a invité à utiliser l'application PayByPhone pour le stationnement.",
  'corporateAccounts.onboarding.registration.formTitle': 'Compléter votre inscription',
  'corporateAccounts.onboarding.registrationRequired': "Le compte n'a pas été trouvé",
  'corporateAccounts.onboarding.registrationRequired.message':
    "Vous devez avoir un compte PayByPhone pour accepter l'invitation. Veuillez vous inscrire à PayByPhone et demander à votre entreprise de vous inviter à nouveau. Si l'erreur persiste, contactez votre administrateur.",
  'corporateAccounts.onboarding.setupAccountStep.description':
    "Nom et adresse de l'entreprise pour la facturation",
  'corporateAccounts.onboarding.setupAccountStep.title': 'Configurer le compte',
  'corporateAccounts.onboarding.setupPaymentStep.description':
    'Vos collaborateurs utiliseront ce mode de paiement pour régler leurs frais de stationnement.',
  'corporateAccounts.onboarding.setupPaymentStep.title': 'Ajouter une carte bancaire',
  'corporateAccounts.onboarding.socialSignIn.instruction':
    "Si vous disposez déjà d'un compte via un login d'un réseau social et que vous souhaitez utiliser le même compte, connectez-vous avec",
  'corporateAccounts.onboarding.success': 'Compte associé avec succès',
  'corporateAccounts.onboarding.success.carousel1.text':
    'Vos véhicules d\'entreprise seront marqués comme "Véhicules professionnels agréés"',
  'corporateAccounts.onboarding.success.carousel1.title': 'Ajoutez vos véhicules professionnels',
  'corporateAccounts.onboarding.success.carousel2.text':
    "Le moyen de paiement est déjà configuré, vous n'avez pas besoin de l'ajouter manuellement.",
  'corporateAccounts.onboarding.success.carousel2.title':
    "Paiement de l'entreprise pour le stationnement",
  'corporateAccounts.onboarding.success.carousel3.subText':
    "*Des frais SMS peuvent s'appliquer selon votre région.",
  'corporateAccounts.onboarding.success.carousel3.text':
    'Laissez-le actif pour éviter les amendes de stationnement.',
  'corporateAccounts.onboarding.success.carousel3.title': 'SMS de rappel',
  'corporateAccounts.onboarding.success.message':
    'Votre compte a été jumelé à {companyName}. Connectez-vous à PayByPhone pour stationner.',
  'corporateAccounts.onboarding.success.notification':
    'Compte créé avec succès ! Votre compte est maintenant lié à {companyName} !',
  'corporateAccounts.onboarding.watchProductDemoVideo': 'Regardez notre vidéo de présentation.',
  'corporateAccounts.onboarding.welcomeModal.actions.getStarted': "C'est parti !",
  'corporateAccounts.onboarding.welcomeModal.description':
    'PayByPhone Business est facile à configurer et à utiliser. Regardez la vidéo pour vous familiariser avec le portail.',
  'corporateAccounts.onboarding.welcomeModal.frequentlyAskedQuestions': 'Consultez nos FAQ',
  'corporateAccounts.onboarding.welcomeModal.title': 'Démarrez avec PayByPhone Business',
  'corporateAccounts.pageDeprecated': "La page est obsolète, supprimée ou n'existe pas du tout.",
  'corporateAccounts.passwordValidation.defaultError':
    'Le mot de passe que vous avez saisi ne correspond pas aux exigences. Veuillez réessayer',
  'corporateAccounts.passwordValidation.lengthError':
    'Le mot de passe doit comporter entre 8 et 51 caractères.',
  'corporateAccounts.passwordValidation.lowerCaseError':
    'Le mot de passe doit contenir au moins une lettre minuscule.',
  'corporateAccounts.passwordValidation.missingNumberError':
    'Le mot de passe doit contenir au moins un chiffre.',
  'corporateAccounts.passwordValidation.missingSpecialCharacterError':
    'Le mot de passe doit contenir au moins un caractère spécial.',
  'corporateAccounts.passwordValidation.upperCaseError':
    'Le mot de passe doit contenir au moins une lettre majuscule.',
  'corporateAccounts.payByPhone': 'PayByPhone Technologies',
  'corporateAccounts.payment.actions.restrictedEdit.content':
    'Cette action est temporairement limitée, mais votre gestionnaire de compte PaybyPhone pourra vous aider dans cette démarche afin que vos collaborateurs puissent immédiatement commencer à utiliser un nouveau moyen de paiement !',
  'corporateAccounts.payment.actions.restrictedEdit.title': 'Mises à jour des moyens de paiement',
  'corporateAccounts.paymentMethod.add.success': 'Moyen de paiement ajouté',
  'corporateAccounts.paymentMethod.delete.success': 'Moyen de paiement supprimé',
  'corporateAccounts.paymentMethod.infograph.cards.0.title': 'Ajouter un nouveau moyen de paiement',
  'corporateAccounts.paymentMethod.infograph.cards.1.body':
    'Optionnel: Actualiser le nom du moyen de paiement en modifiant le moyen de paiement.',
  'corporateAccounts.paymentMethod.infograph.cards.1.title':
    'Actualiser le nom du moyen de paiement',
  'corporateAccounts.paymentMethod.infograph.cards.2.body':
    'Les collaborateurs activés verront automatiquement le nouveau moyen de paiement dans leurs applications',
  'corporateAccounts.paymentMethod.infograph.cards.2.title': 'Partage automatique',
  'corporateAccounts.paymentMethod.infograph.subcontent':
    'Le moyen de paiement professionnel ne pourra être utilisé que par les véhicules approuvés. Ce sont les véhicules ajoutés dans la liste des véhicules approuvés.',
  'corporateAccounts.paymentMethod.update.success': 'Moyen de paiement mis à jour',
  'corporateAccounts.payments.actions.addAndAgree': 'Ajouter et accepter',
  'corporateAccounts.payments.actions.addPayment': 'Ajouter un moyen de paiement',
  'corporateAccounts.payments.actions.editPayment': 'Modifier le moyen de paiement',
  'corporateAccounts.payments.cardCountryCode': 'Code pays de la carte',
  'corporateAccounts.payments.cardCvc': 'Cryptogramme',
  'corporateAccounts.payments.cardName': 'Nom de la CB',
  'corporateAccounts.payments.cardNumber': 'Numéro de carte',
  'corporateAccounts.payments.cardType': 'Type de carte',
  'corporateAccounts.payments.create.error.cardAlreadyExpired': 'La carte a expiré.',
  'corporateAccounts.payments.create.error.cardNumberInvalid':
    "Le numéro de carte n'est pas valide.",
  'corporateAccounts.payments.create.error.cardNumberInvalidFormat':
    "Le format de numéro de carte n'est pas valide.",
  'corporateAccounts.payments.create.error.cardNumberRequired':
    'Le numéro de carte est obligatoire.',
  'corporateAccounts.payments.create.error.cardTypeNotSupported':
    "Le type de carte n'est pas pris en charge.",
  'corporateAccounts.payments.create.error.cardValidationFailed':
    'Échec de la vérification de la carte.',
  'corporateAccounts.payments.create.error.countryCodeNotSupported':
    "Le code pays n'est pas pris en charge.",
  'corporateAccounts.payments.create.error.countryCodeRequired': 'Le code pays est obligatoire.',
  'corporateAccounts.payments.create.error.cvvInvalidFormat': 'Format de CVV non valide.',
  'corporateAccounts.payments.create.error.cvvRequired': 'Le CVV est obligatoire.',
  'corporateAccounts.payments.create.error.expiryMonthInvalid':
    "Le mois d'expiration n'est pas valide.",
  'corporateAccounts.payments.create.error.expiryMonthRequired':
    "Le mois d'expiration est obligatoire.",
  'corporateAccounts.payments.create.error.expiryYearRequired':
    "L'année d'expiration est obligatoire.",
  'corporateAccounts.payments.create.error.expiryYearTooFarInTheFuture':
    "L'année d'expiration est trop éloignée dans le futur.",
  'corporateAccounts.payments.create.error.limitExceeded':
    "Vous avez atteint la limite de votre compte de paiement. Pour l'augmenter, veuillez contacter le service commercial.",
  'corporateAccounts.payments.create.error.nameOnCardInvalidFormat':
    "Le nom figurant sur la carte n'est pas valide.",
  'corporateAccounts.payments.create.error.nameOnCardRequired':
    'Le nom figurant sur la carte est obligatoire.',
  'corporateAccounts.payments.create.error.paymentAccountAlreadyExists':
    'Le compte de paiement existe déjà.',
  'corporateAccounts.payments.create.error.paymentAccountNotFound':
    'Le compte de paiement est introuvable.',
  'corporateAccounts.payments.create.error.unhandledError':
    'Nous n’avons pas pu ajouter votre carte de paiement.',
  'corporateAccounts.payments.create.error.zipCodeInvalidFormat':
    'Format de code postal non valide.',
  'corporateAccounts.payments.create.success':
    "Mode de paiement ajouté. L'affichage des nouveaux modes de paiement dans votre liste peut prendre jusqu'à une minute.",
  'corporateAccounts.payments.creditAccountBanner.button.cta': '',
  'corporateAccounts.payments.creditAccountBanner.description': '',
  'corporateAccounts.payments.creditAccountBanner.description.cta': '',
  'corporateAccounts.payments.creditAccountBanner.title': '',
  'corporateAccounts.payments.cvc': 'CVV/CVC',
  'corporateAccounts.payments.description':
    "Les conducteurs activés verront automatiquement ces moyens de paiement dans leur application, ils n'ont pas besoin de saisir manuellement les détails de la carte. Ces moyens de paiement ne peuvent être utilisés que pour payer le stationnement des",
  'corporateAccounts.payments.description.link': "véhicules agréés par l'entreprise.",
  'corporateAccounts.payments.emptyState.addCreditCardBtn': 'Ajouter une carte bancaire',
  'corporateAccounts.payments.emptyState.description':
    "La carte bancaire ne sera utilisée que pour payer le stationnement. Une facture séparée avec les frais d'abonnement vous sera envoyée par email au début de chaque mois. La carte ne sera débitée que lorsque vos collaborateurs commenceront à stationner. Ils n’auront pas accès aux coordonnées de la carte bancaire.",
  'corporateAccounts.payments.emptyState.title': 'Ajouter un moyen de paiement',
  'corporateAccounts.payments.emptyState.videoLink': 'Comment ajouter une carte de crédit',
  'corporateAccounts.payments.error.delete.notFound':
    'Moyen de paiement introuvable, veuillez rafraîchir votre navigateur.',
  'corporateAccounts.payments.expiry': 'Expiration',
  'corporateAccounts.payments.expiryMonth': 'Mois d’expiration',
  'corporateAccounts.payments.expiryYear': 'Année d’expiration',
  'corporateAccounts.payments.form.cardCountryCodePlaceholder': 'Sélectionnez un code de pays',
  'corporateAccounts.payments.form.cardCountryCodeRequired':
    'Veuillez sélectionner un code de pays',
  'corporateAccounts.payments.form.cardCvcRequired': 'Cryptogramme requis',
  'corporateAccounts.payments.form.cardCvcValidation': 'Cryptogramme invalide',
  'corporateAccounts.payments.form.cardExpiryRequired': "La date d'expiration est obligatoire",
  'corporateAccounts.payments.form.cardExpiryValidation': "La date d'expiration est invalide",
  'corporateAccounts.payments.form.cardNameLength':
    'Le nom de la carte ne peut pas comporter plus de {limit} caractères',
  'corporateAccounts.payments.form.cardNamePlaceholder': 'Ajouter un nom de carte',
  'corporateAccounts.payments.form.cardNameRequired': 'Veuillez ajouter un nom de carte',
  'corporateAccounts.payments.form.cardNumberPlaceholder': 'Numéro de carte',
  'corporateAccounts.payments.form.cardNumberRequired':
    'Le numéro de carte de crédit est obligatoire',
  'corporateAccounts.payments.form.cardNumberValidation': 'La carte de crédit est invalide',
  'corporateAccounts.payments.form.cardTypePlaceholder': 'Type de carte',
  'corporateAccounts.payments.form.cardTypeRequired': 'Veuillez choisir un type de carte',
  'corporateAccounts.payments.form.creditCardTypeNotSupported':
    "{creditCardType} n'est pas pris en charge",
  'corporateAccounts.payments.form.disclaimer':
    'Espace réservé pour la mise à jour de la clause de non-responsabilité relative à la carte',
  'corporateAccounts.payments.form.expiryMonthPlaceholder': 'Sélectionner le mois d’expiration',
  'corporateAccounts.payments.form.expiryYearPlaceholder': 'AAAA',
  'corporateAccounts.payments.form.nameOnCardFormat':
    'Le nom de la carte ne peut pas comporter de caractères non valides < > \' " ou &',
  'corporateAccounts.payments.form.nameOnCardLength':
    'Le nom de la carte ne peut pas comporter moins de 2 caractères ou plus de 70 caractères',
  'corporateAccounts.payments.form.nameOnCardPlaceholder': 'Nom de la carte',
  'corporateAccounts.payments.form.nameOnCardRequired': 'Veuillez entrer le nom de la CB',
  'corporateAccounts.payments.form.primaryCountryOfUse': "Pays principal d'utilisation",
  'corporateAccounts.payments.form.storedCredentialAgreement':
    'Accords de stockage des identifiants',
  'corporateAccounts.payments.form.termsAgreement':
    'En ajoutant ce moyen de paiement, vous acceptez nos',
  'corporateAccounts.payments.form.update': 'Mettre à jour les informations de carte de crédit',
  'corporateAccounts.payments.form.zipCodePlaceholder': 'Ajouter un code postal',
  'corporateAccounts.payments.form.zipCodeRequired': 'Veuillez ajouter un code postal',
  'corporateAccounts.payments.generic.error':
    'Un problème est survenu lors de la mise à jour de votre moyen de paiement',
  'corporateAccounts.payments.main.description': 'Moyens de paiement pour PayByPhone Business',
  'corporateAccounts.payments.modal.delete.areYouSure':
    'Êtes-vous sûr(e) de vouloir supprimer le moyen de paiement suivant ?',
  'corporateAccounts.payments.modal.delete.lastCard':
    'Vos collaborateurs ne pourront plus facturer les transactions de stationnement à votre entreprise!',
  'corporateAccounts.payments.nameOnCard': 'Nom sur la carte',
  'corporateAccounts.payments.paymentAccountId': 'ID du compte de paiement',
  'corporateAccounts.payments.spa.challenge.title': 'Flux SCA Challenge',
  'corporateAccounts.payments.spa.title': 'Ajouter un nouveau moyen de paiement',
  'corporateAccounts.payments.spa.title.edit': 'Modifier le moyen de paiement',
  'corporateAccounts.payments.spa.verification.content':
    "Pour plus de sécurité, vous serez redirigé vers votre banque avant d'ajouter cette carte de crédit dans votre dossier.",
  'corporateAccounts.payments.spa.verification.title': 'Vérification nécessaire',
  'corporateAccounts.payments.title': 'Moyens de paiement',
  'corporateAccounts.payments.toast.paymentMethodDeleted': 'Moyen de paiement supprimé',
  'corporateAccounts.payments.update.success': 'Nom de la carte mis à jour',
  'corporateAccounts.payments.zipCode': 'Code postal',
  'corporateAccounts.reports.actions.clear': 'Effacer',
  'corporateAccounts.reports.actions.load': 'Charger le rapport',
  'corporateAccounts.reports.cardName': 'Nom de la CB',
  'corporateAccounts.reports.cost': 'Montant total',
  'corporateAccounts.reports.costCenter': 'Centre de coûts',
  'corporateAccounts.reports.costInfo':
    "Le coût du stationnement n'est pas définitif tant que le paiement n'a pas été réalisé.",
  'corporateAccounts.reports.dateRange': 'Plage de dates',
  'corporateAccounts.reports.dateRange.endDate': 'Date de fin',
  'corporateAccounts.reports.dateRange.startDate': 'Date de début',
  'corporateAccounts.reports.day': 'Jour',
  'corporateAccounts.reports.dayOfWeek': 'Jour',
  'corporateAccounts.reports.email': 'Email',
  'corporateAccounts.reports.firstName': 'Prénom',
  'corporateAccounts.reports.lastName': 'Nom',
  'corporateAccounts.reports.licensePlate': "Plaque d'immatriculation",
  'corporateAccounts.reports.localEndDateTime': "Heure d'expiration",
  'corporateAccounts.reports.localStartDateTime': 'Heure de début',
  'corporateAccounts.reports.lotId': 'ID Code tarif',
  'corporateAccounts.reports.main.title': 'Rapports',
  'corporateAccounts.reports.maskedCardNumber': 'Détails du moyen de paiement masqués',
  'corporateAccounts.reports.month': 'Type de rapport',
  'corporateAccounts.reports.month.placeholder': 'Veuillez sélectionner',
  'corporateAccounts.reports.monthly': 'Tous les mois',
  'corporateAccounts.reports.monthly.generateReport': 'Générer un rapport',
  'corporateAccounts.reports.monthly.infoNotice':
    'Les rapports mensuels exportés seront disponibles ici pendant 72 heures.',
  'corporateAccounts.reports.monthly.last30days': '30 derniers jours',
  'corporateAccounts.reports.monthly.last7days': '7 derniers jours',
  'corporateAccounts.reports.monthly.last90days': '90 derniers jours',
  'corporateAccounts.reports.monthly.reportJob.status.completed': 'Terminé',
  'corporateAccounts.reports.monthly.reportJob.status.failed': 'Échec',
  'corporateAccounts.reports.monthly.reportJob.status.pending': 'En attente',
  'corporateAccounts.reports.monthly.reportType': 'Type de rapport',
  'corporateAccounts.reports.monthly.timeExported': "Heure de l'export",
  'corporateAccounts.reports.monthly.toast.generatingReport.content':
    "Le rapport est en cours de génération et sera disponible dans vos exportations lorsqu'il sera prêt.",
  'corporateAccounts.reports.monthly.toast.generatingReport.title': 'Préparation de votre rapport',
  'corporateAccounts.reports.monthlyReports': 'Rapports mensuels',
  'corporateAccounts.reports.netTotal': 'Montant total (net)',
  'corporateAccounts.reports.parkingLotName': 'Zones de stationnement',
  'corporateAccounts.reports.parkingMinutes': 'Durée (en minutes)',
  'corporateAccounts.reports.parkingRegionName': 'Nom de la région de stationnement',
  'corporateAccounts.reports.parkingStatus': 'État du stationnement',
  'corporateAccounts.reports.parkingVendorName': 'Ville',
  'corporateAccounts.reports.paymentDate': 'Date de paiement',
  'corporateAccounts.reports.paymentStatus': 'Statut du paiement',
  'corporateAccounts.reports.phone': 'Téléphone',
  'corporateAccounts.reports.phoneNumber': 'Numéro de téléphone',
  'corporateAccounts.reports.searchInput.placeholder':
    "Recherche par plaque d'immatriculation, nom et prénom du collaborateur, nom du client",
  'corporateAccounts.reports.tax': 'TVA',
  'corporateAccounts.reports.transactionId': 'ID transaction',
  'corporateAccounts.reports.transactions': 'Rapport de transaction',
  'corporateAccounts.reports.transactions.chooseDateRange': 'Choisir une plage de dates',
  'corporateAccounts.reports.transactions.noResults':
    'Aucun résultat à afficher pour la plage de dates sélectionnée',
  'corporateAccounts.reports.transactions.timeout.modal.content':
    'Essayez de raccourcir la plage de dates ou exécuter un rapport mensuel à la place.',
  'corporateAccounts.reports.transactions.timeout.modal.title': 'Le temps est écoulé',
  'corporateAccounts.reports.vehicleCountry': 'Pays du véhicule',
  'corporateAccounts.reports.vehicleVin': 'Numéro VIN (n° de série du véhicule)',
  'corporateAccounts.search.validation.minimum': 'La recherche nécessite 3 caractères',
  'corporateAccounts.selfRegistration.actions.signIn': 'Se connecter',
  'corporateAccounts.selfRegistration.activation.description':
    "Vos collaborateurs peuvent désormais stationner avec PayByPhone Business, et votre facturation commence aujourd'hui. Si vous avez des questions, n'hésitez pas à contacter le support client via le chat en bas à droite.",
  'corporateAccounts.selfRegistration.activation.feedback':
    'La configuration de votre compte a-t-elle été facile ?',
  'corporateAccounts.selfRegistration.activation.feedback.difficult': 'Difficile',
  'corporateAccounts.selfRegistration.activation.feedback.easy': 'Facile',
  'corporateAccounts.selfRegistration.activation.feedback.expected': 'Comme attendu',
  'corporateAccounts.selfRegistration.activation.supportLink':
    'Cliquez ici pour contacter le service Support',
  'corporateAccounts.selfRegistration.activation.title':
    'Compte activé ! Vous êtes prêt à stationner',
  'corporateAccounts.selfRegistration.emailVerificationInstruction':
    "Vérifiez votre email et cliquez sur le lien pour activer votre compte. Si vous ne l'avez pas reçu d'ici quelques minutes, vérifiez vos courriers indésirables.",
  'corporateAccounts.selfRegistration.form.agreement':
    "J'accepte les {subscriptionAgreement} et que le prix mentionné ci-dessus me soit facturé par mois et par collaborateur, et je consens à la {privacyPolicy}",
  'corporateAccounts.selfRegistration.form.creditCardNote':
    "Aucune carte de crédit n'est requise jusqu'à ce que vous soyez prêt à stationner",
  'corporateAccounts.selfRegistration.form.title': 'Créez votre compte PayByPhone Business',
  'corporateAccounts.selfRegistration.marketing.businessBenefits.one':
    'Finies les notes de frais fastidieuses',
  'corporateAccounts.selfRegistration.marketing.businessBenefits.three':
    'Vos collaborateurs paient leur stationnement en seulement 30 secondes',
  'corporateAccounts.selfRegistration.marketing.businessBenefits.two':
    'Dépenses contrôlées avec une vue globale de votre activité et des déplacements en temps réel.',
  'corporateAccounts.selfRegistration.marketing.businessesTrust':
    'Une solution de confiance pour les petites et grandes entreprises',
  'corporateAccounts.selfRegistration.marketing.title':
    'Laissez PayByPhone Business simplifier la gestion de votre flotte de véhicules.',
  'corporateAccounts.selfRegistration.resendEmail.button': "Renvoyer l'email",
  'corporateAccounts.selfRegistration.resendEmail.failed':
    "Échec de l'envoi de l'email de vérification",
  'corporateAccounts.selfRegistration.resendEmail.success': 'Un email de vérification a été envoyé',
  'corporateAccounts.selfRegistration.success.supportContact': 'Contacter le support',
  'corporateAccounts.selfRegistration.success.supportMessage':
    'Vous avez un problème ? Nous sommes là pour vous aider !',
  'corporateAccounts.selfRegistration.success.title': 'Vérifier votre adresse email',
  'corporateAccounts.selfRegistration.verification.completeCaptcha':
    'Veuillez compléter le captcha ci-dessous',
  'corporateAccounts.selfRegistration.verification.failed': 'Erreur lors de la vérification',
  'corporateAccounts.selfRegistration.verification.failed.message':
    "La demande de vérification n'a pas pu être effectuée pour le moment. Veuillez réessayer plus tard.",
  'corporateAccounts.selfRegistration.verification.linkExpired': 'Lien de vérification expiré',
  'corporateAccounts.selfRegistration.verification.linkExpired.message':
    "Le lien de vérification n'est plus valide. Veuillez renvoyer un email de vérification pour finaliser l'inscription.",
  'corporateAccounts.selfRegistration.verification.resendVerification':
    "Renvoyer l'email de vérification",
  'corporateAccounts.selfRegistration.verification.success': 'Vérifié',
  'corporateAccounts.selfRegistration.verification.success.message':
    'Votre compte a bien été vérifié',
  'corporateAccounts.selfRegistration.verification.success.portalLink':
    'Se connecter à PayByPhone Business',
  'corporateAccounts.server.domainErrors.RestrictedCorporateClient':
    "L'accès au client d'entreprise est restreint en raison de son statut actuel.",
  'corporateAccounts.sessions.main.description':
    'Voir l’historique de stationnement des dernières 72 heures.',
  'corporateAccounts.sessions.title': 'Stationnements',
  'corporateAccounts.staff.actions.add': 'Ajouter des collaborateurs',
  'corporateAccounts.staff.search': 'Rechercher par nom, email ou identifiant',
  'corporateAccounts.staff.title': 'Gestion des collaborateurs',
  'corporateAccounts.staff.title.add': 'Ajouter des collaborateurs',
  'corporateAccounts.subscription.cancelAccount': 'Besoin de clôturer votre compte ?',
  'corporateAccounts.subscription.contactCustomerSuccess':
    '{contact} notre équipe si vous devez clôturer votre compte.',
  'corporateAccounts.subscription.contactForPricing':
    'Veuillez contacter le service client pour obtenir des informations sur les prix.',
  'corporateAccounts.subscription.currentPlanTitle': 'Plan actuel',
  'corporateAccounts.subscription.editPlan': 'Modifier votre abonnement actuel',
  'corporateAccounts.subscription.error.contactSupport':
    'Impossible de charger les informations sur les prix. Contactez le service client.',
  'corporateAccounts.subscription.error.tryAgain':
    'Impossible de charger les informations sur les prix. Veuillez patienter un moment et réessayer.',
  'corporateAccounts.subscription.memberSince': 'Client depuis',
  'corporateAccounts.subscription.monthlyPlan': 'Forfait mensuel',
  'corporateAccounts.subscription.nextBillingCycle': 'Prochaine facturation',
  'corporateAccounts.subscription.perDriver': 'par conducteur',
  'corporateAccounts.subscription.terms':
    'Abonnement PayByPhone Business {termsAndConditions} et {privacyPolicy}',
  'corporateAccounts.topBar.helpMenu.title': "Besoin d'aide ?",
  'corporateAccounts.topBar.profileMenu.account': 'Compte',
  'corporateAccounts.topBar.profileMenu.changeCorporations': 'Changer de compte',
  'corporateAccounts.topBar.profileMenu.contact': 'Contactez-nous',
  'corporateAccounts.topBar.profileMenu.editProfile': 'Éditer le profil',
  'corporateAccounts.topBar.profileMenu.email': 'Courriel',
  'corporateAccounts.topBar.profileMenu.hello': 'Bonjour',
  'corporateAccounts.topBar.profileMenu.help': 'Centre d’aide',
  'corporateAccounts.topBar.profileMenu.home': 'Accueil',
  'corporateAccounts.topBar.profileMenu.phone': 'Téléphone',
  'corporateAccounts.topBar.profileMenu.role': 'Rôle',
  'corporateAccounts.topBar.profileMenu.signOut': 'Se déconnecter',
  'corporateAccounts.topBar.profileMenu.support': 'Contactez-nous',
  'corporateAccounts.user.activation.error':
    "Nous rencontrons une erreur suite à l'activation de votre compte. Veuillez contacter votre administrateur.",
  'corporateAccounts.user.activation.success': 'Vous avez activé votre compte avec succès.',
  'corporateAccounts.user.limitExceeded.error':
    "Vous avez atteint le nombre maximum d'utilisateurs. Pour l'augmenter, veuillez contacter le service commercial.",
  'corporateAccounts.user.login.success': 'Connexion réussie.',
  'corporateAccounts.user.registration.duplicateEmail':
    "Le profil d'utilisateur avec cet email existe déjà.",
  'corporateAccounts.user.registration.internalError':
    "Nous avons rencontré un problème lors du traitement de votre demande. Veuillez réessayer plus tard. Si le problème persiste, veuillez contacter le service commercial de PayByPhone pour obtenir de l'aide.",
  'corporateAccounts.user.registration.userEmailDomainNotValid':
    'La souscription depuis ce domaine est bloquée. Veuillez réessayer avec une autre adresse email.',
  'corporateAccounts.users.role.customer_success': 'Chargé de relations clients',
  'corporateAccounts.users.role.customer_support_tier_2': 'Support Client Niveau 2',
  'corporateAccounts.users.role.super_admin': 'Titulaire du compte',
  'corporateAccounts.vehicle.add.duplicate.error':
    "Un véhicule avec cette plaque d'immatriculation existe déjà.",
  'corporateAccounts.vehicle.add.error': "Nous n'avons pas pu ajouter votre véhicule.",
  'corporateAccounts.vehicle.add.invalidCharacters':
    'La plaque d’immatriculation contient des caractères non valides',
  'corporateAccounts.vehicle.add.success': 'Véhicule ajouté',
  'corporateAccounts.vehicle.delete.error': "Nous n'avons pas pu supprimer votre véhicule.",
  'corporateAccounts.vehicle.delete.success': 'Véhicule supprimé',
  'corporateAccounts.vehicle.limitExceeded.error':
    "Vous avez dépassé le nombre maximum de véhicules. Pour l'augmenter, veuillez contacter le service commercial.",
  'corporateAccounts.vehicle.update.error': "Nous n'avons pas pu mettre à jour votre véhicule.",
  'corporateAccounts.vehicle.update.success': 'Véhicule mis à jour',
  'corporateAccounts.vehicles.actions.addVehicle': 'Ajouter un véhicule',
  'corporateAccounts.vehicles.actions.deleteVehicleMessage': 'Supprimer ce véhicule?',
  'corporateAccounts.vehicles.actions.deleteVehicles': 'Supprimer les véhicules',
  'corporateAccounts.vehicles.bulkDelete.success':
    '{numOfDeletedVehicles} véhicules ont été supprimés',
  'corporateAccounts.vehicles.country': 'Pays',
  'corporateAccounts.vehicles.csvDownload.country': 'Pays (obligatoire)',
  'corporateAccounts.vehicles.csvDownload.jurisdiction': 'Etat / Région (obligatoire)',
  'corporateAccounts.vehicles.csvDownload.licensePlate': "Plaques d'Immatriculation",
  'corporateAccounts.vehicles.csvDownload.vin': 'VIN [Numéro de châssis] (optional)',
  'corporateAccounts.vehicles.emptyState.ImportVehiclesBtn': 'Importer des véhicules',
  'corporateAccounts.vehicles.emptyState.description':
    "Créez une liste de véhicules approuvés par l'entreprise qui peuvent stationner avec le moyen de paiement professionnel.",
  'corporateAccounts.vehicles.emptyState.videoLink': 'Comment ajouter des véhicules',
  'corporateAccounts.vehicles.export.error.modal.description':
    "Impossible d'exporter la liste des véhicules, veuillez réessayer.",
  'corporateAccounts.vehicles.export.error.modal.title':
    "Erreur d'export de la liste des véhicules",
  'corporateAccounts.vehicles.export.fileName': 'ListeVéhicules',
  'corporateAccounts.vehicles.export.modal.description':
    'Les données exportées seront générées sur la base des filtres actuellement appliqués à votre liste de véhicules.',
  'corporateAccounts.vehicles.export.modal.title': 'Export de la liste de véhicules',
  'corporateAccounts.vehicles.export.success.notification.description':
    'Votre liste de véhicules a été exportée.',
  'corporateAccounts.vehicles.export.success.notification.title': 'Liste de véhicules exportée',
  'corporateAccounts.vehicles.form.add': 'Ajouter un véhicule',
  'corporateAccounts.vehicles.form.countryRequired': 'Veuillez sélectionner un pays',
  'corporateAccounts.vehicles.form.edit': 'Modifier le véhicule',
  'corporateAccounts.vehicles.form.licensePlatePlaceholder': "Ajouter une plaque d'immatriculation",
  'corporateAccounts.vehicles.form.licensePlateRequired':
    'Veuillez entrer une immatriculation valide.',
  'corporateAccounts.vehicles.form.licensePlateValidation':
    "La plaque d'immatriculation ne semble pas valide",
  'corporateAccounts.vehicles.form.new': 'Ajouter un véhicule',
  'corporateAccounts.vehicles.form.regionRequired': 'Veuillez sélectionner une région',
  'corporateAccounts.vehicles.form.update': 'Mettre à jour votre véhicule',
  'corporateAccounts.vehicles.form.vinValidation': 'Numéro VIN non valide',
  'corporateAccounts.vehicles.import.description':
    'Suivez les étapes ci-dessous pour télécharger vos véhicules sur votre compte',
  'corporateAccounts.vehicles.import.fileName': 'Importation de véhicules',
  'corporateAccounts.vehicles.import.header.countryISO': 'Pays ISO-3166 alpha-2 (obligatoire)',
  'corporateAccounts.vehicles.import.header.licensePlate':
    "Numéro de plaque d'immatriculation (obligatoire)",
  'corporateAccounts.vehicles.import.header.numberPlate': "Plaque d'immatriculation (obligatoire)",
  'corporateAccounts.vehicles.import.header.stateISO':
    "Code ISO de l'État : 3166-2:US ; Code ISO de la province : 3166-2:CA (obligatoire)",
  'corporateAccounts.vehicles.import.header.vin': "Code VIN [numéro d'identification du véhicule]",
  'corporateAccounts.vehicles.import.step.2.content':
    'Remplissez le fichier modèle et enregistrez-le.',
  'corporateAccounts.vehicles.import.title': 'Véhicules - Import groupé',
  'corporateAccounts.vehicles.infograph.cards.0.title': 'Ajouter des véhicules',
  'corporateAccounts.vehicles.infograph.cards.1.body':
    "Demandez à vos collaborateurs d'ajouter les véhicules correspondants dans leur application PayByPhone.",
  'corporateAccounts.vehicles.infograph.cards.1.title': 'Prévenez vos collaborateurs',
  'corporateAccounts.vehicles.infograph.cards.2.body':
    'Les collaborateurs actifs peuvent utiliser votre moyen de paiement pour payer le stationnement avec ce véhicule.',
  'corporateAccounts.vehicles.infograph.cards.2.title': 'Début du stationnement',
  'corporateAccounts.vehicles.infograph.subcontent':
    'Un collaborateur ne peut pas utiliser votre moyen de paiement pour payer un stationnement avec un véhicule non déclaré pas dans cette liste. Les véhicules ne sont pas être liés à un conducteur spécifique.',
  'corporateAccounts.vehicles.licensePlate': 'Immatriculation',
  'corporateAccounts.vehicles.modal.content.bulkDelete':
    'Voulez-vous vraiment supprimer les véhicules sélectionnés ?',
  'corporateAccounts.vehicles.modal.title.bulkDelete': 'Supprimer les véhicules ?',
  'corporateAccounts.vehicles.title': 'Véhicules',
  'corporateAccounts.vehicles.vin': 'Numéro VIN (n° de série du véhicule)',
  'corporateAcctounts.vehicles.description':
    "Les conducteurs doivent ajouter ces véhicules à leur application PayByPhone, ils n'apparaîtront pas automatiquement.",
};
