/**
 *
 * @param {string} corporateClientName
 */
export const convertCorporateClientNameToShortName = corporateClientName => {
  if (!corporateClientName) return '';

  const corporateClientNameWithoutSpaces = corporateClientName.replace(/\s/g, '');
  const shortNameLength = Math.min(corporateClientNameWithoutSpaces.length, 10);

  return corporateClientNameWithoutSpaces.substring(0, shortNameLength).toUpperCase();
};
