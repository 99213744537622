import 'moment/min/locales.min';
import moment from 'moment';
import store from 'store';
import locales from 'locales';

export const getLocalizedMoment = () => {
  const currentLocale = store.get('app.settings.locale') || 'en-US';
  const { momentLocale } = locales[currentLocale];

  moment.locale(momentLocale);
  return moment;
};

export const compareDates = (a, b) => moment(a).unix() - moment(b).unix();

export default {
  getLocalizedMoment,
  compareDates,
};
