import actions from './actions';
import corporationActions from '../corporations/actions';
import userActions from '../user/actions';
import mappings from './mappings';

export const INITIAL_STATE = {
  data: [],
  formErrors: [],
  importSuccess: false,
  loading: false,
  refetch: false,
  showNoVehicleUI: true,
  submitting: false,
  totalCount: 0,
  isExporting: false,
  limitExceeded: false,
  isPlateValid: true,
  isSuccess: false,
};

export default function vehiclesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actions.GET:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        refetch: false,
        formErrors: [],
        ...mappings.mapGetVehiclesResponse(state, action.payload),
      };
    case actions.GET_ERROR:
      return {
        ...state,
        loading: false,
        refetch: false,
        formErrors: [],
      };
    case actions.POST:
      return {
        ...state,
        submitting: true,
      };
    case actions.POST_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        submitting: false,
        isPlateValid: true,
        ...mappings.mapCreatedVehicle(state, action.payload),
      };
    case actions.RESET_SUCCESS:
      return {
        ...state,
        isSuccess: false,
      };
    case actions.POST_ERROR:
      return {
        ...state,
        submitting: false,
        formErrors: action.payload || state.formErrors,
      };
    case actions.POST_LIMIT_ERROR:
      return {
        ...state,
        limitExceeded: true,
        submitting: false,
      };
    case actions.POST_INVALID_PLATE_ERROR:
      return {
        ...state,
        isSuccess: false,
        isPlateValid: false,
        submitting: false,
      };
    case actions.RESET_ISPLATEVALID:
      return {
        ...state,
        isSuccess: true,
        isPlateValid: true,
      };
    case actions.PUT:
      return {
        ...state,
        submitting: true,
      };
    case actions.PUT_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        submitting: false,
        isPlateValid: true,
        data: mappings.mapEditedVehicle(state, action.payload),
      };
    case actions.PUT_ERROR:
      return {
        ...state,
        submitting: false,
        formErrors: action.payload || state.formErrors,
      };
    case actions.BATCH_DELETE:
      return {
        ...state,
        loading: true,
      };
    case actions.BATCH_DELETE_SUCCESS:
      return {
        ...state,
        ...mappings.mapBatchDeletedVehicles(state, action.payload),
      };
    case actions.BATCH_DELETE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.DELETE:
      return {
        ...state,
        submitting: true,
      };
    case actions.DELETE_SUCCESS:
      return {
        ...state,
        submitting: false,
        ...mappings.mapDeletedVehicle(state, action.payload),
      };
    case actions.DELETE_ERROR:
      return {
        ...state,
        submitting: false,
      };
    case actions.IMPORT:
      return {
        ...state,
        submitting: true,
        importSuccess: false,
      };
    case actions.IMPORT_CANCEL:
      return {
        ...state,
        submitting: false,
        importSuccess: false,
      };
    case actions.IMPORT_SUCCESS:
      return {
        ...state,
        submitting: false,
        importSuccess: true,
      };
    case actions.IMPORT_ERROR:
      return {
        ...state,
        submitting: false,
        importSuccess: false,
        formErrors: action.payload,
      };
    case actions.IMPORT_LIMIT_ERROR:
      return {
        ...state,
        limitExceeded: true,
        submitting: false,
        importSuccess: false,
      };
    case actions.EXPORT_VEHICLES:
      return {
        ...state,
        isExporting: true,
      };
    case actions.EXPORT_VEHICLES_SUCCESS:
    case actions.EXPORT_VEHICLES_ERROR:
      return {
        ...state,
        isExporting: false,
      };
    case actions.RESET_ERRORS:
      return {
        ...state,
        formErrors: [],
      };
    case actions.RESET_LIST:
      return {
        ...state,
        data: [],
        showNoVehicleUI: true,
        totalCount: 0,
      };
    case corporationActions.GET:
    case userActions.LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
