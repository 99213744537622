import moment from 'moment';
import {
  fieldsToConvertToCamelCase,
  fieldsToConvertDateFormat,
  fieldsToConvertToTwoDecimalPlaces,
  currencyCodeMapper,
} from './creditAccountTypeConfigurations';

export const toCamelCase = string => string?.charAt(0).toLowerCase() + string?.slice(1);

export const flattenCreditAccountDetails = details => {
  if (!details || !Array.isArray(details) || details.length === 0) return null;

  const { creditAccountDetail, ...otherDetails } = details[0];
  const { paymentMethod, ...otherCreditAccountDetails } = creditAccountDetail || {};

  return {
    ...otherCreditAccountDetails,
    ...paymentMethod,
    ...otherDetails,
  };
};

export const formatCreditAccountDetails = details => {
  if (!details) return null;

  const transformed = { ...details };

  for (const key in transformed) {
    if (fieldsToConvertToCamelCase.includes(key)) {
      transformed[key] = toCamelCase(transformed[key]);
    }
    if (fieldsToConvertDateFormat.includes(key)) {
      transformed[key] = moment.utc(transformed[key]).format('YYYY-MM-DD');
    }
    if (fieldsToConvertToTwoDecimalPlaces.includes(key)) {
      transformed[key] =
        currencyCodeMapper[transformed.currencyCode] + Number(transformed[key]).toFixed(2);
    }
  }

  return transformed;
};
