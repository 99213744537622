const InfoIcon = ({ color = '#0887c9' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2993_52830)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99999 15.3333C12.0501 15.3333 15.3333 12.05 15.3333 7.99996C15.3333 3.94987 12.0501 0.666626 7.99999 0.666626C3.9499 0.666626 0.666656 3.94987 0.666656 7.99996C0.666656 12.05 3.9499 15.3333 7.99999 15.3333ZM7.99999 5.33329C8.36818 5.33329 8.66666 5.03482 8.66666 4.66663C8.66666 4.29844 8.36818 3.99996 7.99999 3.99996C7.6318 3.99996 7.33332 4.29844 7.33332 4.66663C7.33332 5.03482 7.6318 5.33329 7.99999 5.33329ZM5.99999 6.66663C5.99999 6.29844 6.29847 5.99996 6.66666 5.99996H7.99999C8.36818 5.99996 8.66666 6.29844 8.66666 6.66663V10.6666H9.33332C9.70151 10.6666 9.99999 10.9651 9.99999 11.3333C9.99999 11.7015 9.70151 12 9.33332 12H6.66666C6.29847 12 5.99999 11.7015 5.99999 11.3333C5.99999 10.9651 6.29847 10.6666 6.66666 10.6666H7.33332V7.33329H6.66666C6.29847 7.33329 5.99999 7.03482 5.99999 6.66663Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_2993_52830">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default InfoIcon;
