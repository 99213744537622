import React from 'react';
import SystemModal from 'components/Shared/Modal';
import ScaIframe from '../PASFormView/ScaIframe';
import useTranslation from 'utils/hooks/useTranslation';

const ScaOrderChallengeModal = ({ onCancel, challengeHtml, visible }) => {
  const { translateText } = useTranslation();
  const MODAL_WIDTH = 697;

  return (
    <SystemModal
      data-testid="scaChallengeModal"
      footer={null}
      onCancel={onCancel}
      visible={visible}
      width={MODAL_WIDTH}
      title={translateText('corporateAccounts.payments.spa.challenge.title')}
    >
      <ScaIframe iframeHtml={challengeHtml} />
    </SystemModal>
  );
};

export default ScaOrderChallengeModal;
