import { OPERATING_SYSTEM } from 'constants/operatingSystem';

export const getOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return OPERATING_SYSTEM.Android;
  }

  if (/iPad|iPhone/i.test(userAgent) && !window.MSStream) {
    return OPERATING_SYSTEM.IOS;
  }

  if (/Macintosh|Mac OS X/i.test(userAgent)) {
    return OPERATING_SYSTEM.MacOS;
  }

  if (/Windows NT|Win64|Win32/i.test(userAgent)) {
    return OPERATING_SYSTEM.Windows;
  }

  return 'Other';
};
