const accountNumber = {
  name: 'accountNumber',
  defaultValue: '-',
  cta: 'copyToClipboard',
};

const approvedCreditLimit = {
  name: 'approvedCreditLimit',
  defaultValue: '-',
};

const availableCredit = {
  name: 'availableCredit',
  defaultValue: '-',
};

const paymentAmountDue = {
  name: 'paymentAmountDue',
  defaultValue: '-',
};

const nextPaymentDate = {
  name: 'nextPaymentDate',
  defaultValue: '-',
};

const paymentMethod = {
  name: 'paymentMethod',
  defaultValue: '-',
  cta: 'update',
};

const invoicingFrequency = {
  name: 'invoicingFrequency',
  defaultValue: '-',
};

const overLimitPaymentMethod = {
  name: 'overLimitPaymentMethod',
  defaultValue: '-',
  cta: 'update',
};

export const creditAccountTypeConfigurations = {
  creditAccount: {
    creditAccountDetails: {
      accountNumber,
      approvedCreditLimit,
      availableCredit,
    },
    paymentDetails: {
      paymentAmountDue,
      nextPaymentDate,
      paymentMethod,
      invoicingFrequency,
    },
  },
  creditBuilder: {
    creditAccountDetails: {
      accountNumber,
    },
    paymentDetails: {
      paymentMethod,
      invoicingFrequency,
    },
  },
  hybrid: {
    creditAccountDetails: {
      accountNumber,
      approvedCreditLimit,
      availableCredit,
    },
    paymentDetails: {
      paymentAmountDue,
      nextPaymentDate,
      paymentMethod,
      overLimitPaymentMethod,
      invoicingFrequency,
    },
  },
};

export const creditAccountValueMapper = {
  accountNumber: 'creditAccountId',
  approvedCreditLimit: 'approvedCreditLimit',
  availableCredit: 'availableCredit',
  paymentAmountDue: 'paymentAmountDue',
  nextPaymentDate: 'nextPaymentDate',
  paymentMethod: 'paymentMethodType',
  invoicingFrequency: 'invoiceFrequency',
  overLimitPaymentMethod: 'overLimitPaymentMethod',
};

export const currencyCodeMapper = {
  GBP: '£',
  USD: '$',
  EUR: '€',
};

export const fieldsToConvertToCamelCase = [
  'creditAccountStatus',
  'creditAccountType',
  'invoiceFrequency',
  'paymentMethodType',
  'status',
];

export const fieldsToConvertDateFormat = [
  'nextPaymentDate',
  'lastUpdated',
  'overLimitPaymentMethodExpiryDate',
];

export const fieldsToConvertToTwoDecimalPlaces = [
  'approvedCreditLimit',
  'availableCredit',
  'paymentAmountDue',
  'currentSpend',
];
