import { compareDates } from 'utils/momentUtil';

const mapMonthlyReports = monthlyReports => {
  if (monthlyReports.length === 0) return [];

  const formattedReports = monthlyReports.map(report => {
    return {
      ...report,
      createdAt: report.createdAt.split(' ')[0] + report.createdAt.split(' ')[1],
    };
  });

  return formattedReports.sort((a, b) => compareDates(b.createdAt, a.createdAt));
};

const mapGeneratedReport = generatedReport => {
  return {
    id: generatedReport.id,
    reportName: generatedReport.reportName,
    status: 0,
    createdAt: generatedReport.creationDateTime,
    expiringAt: generatedReport.expireTime,
  };
};

export default {
  mapMonthlyReports,
  mapGeneratedReport,
};
