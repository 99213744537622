import { REHYDRATE } from 'redux-persist/lib/constants';
import { all, takeEvery, put, call, take } from 'redux-saga/effects';
import { sessions } from 'services/api';
import actions from './actions';

export function* GET() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });

  const { response } = yield call(sessions.read);
  if (response && (response.status === 200 || response.status === 204)) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        data: response.data || [],
      },
    });
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    });
  }
}

export default function* rootSaga() {
  // Wait for redux-persist to rehydrate to prevent sagas running against empty store
  yield take(REHYDRATE);
  yield all([takeEvery(actions.GET, GET)]);
}
