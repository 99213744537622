import http, { httpWrapper } from 'services/http';
import { v4 as uuidv4 } from 'uuid';
import userService from 'services/user';
import { store } from 'initStore';
import { API_URL_PAS2, API_KEY_PAS2, ENDPOINT_PAYMENTS } from 'constants.js';
import { endpoint } from '.';

const read = async () => {
  const authHeaders = await userService.getAuthorizationHeaders();

  const requestOptions = {
    method: 'GET',
    url: `${endpoint(ENDPOINT_PAYMENTS)}`,
    headers: {
      ...authHeaders,
    },
  };

  const response = http(requestOptions);
  return response;
};

const update = async payload => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'PUT',
    url: `${endpoint(ENDPOINT_PAYMENTS)}/${payload.id}`,
    headers: {
      ...authHeaders,
    },
    data: payload,
  };

  const response = http(requestOptions);
  return response;
};

const destroy = async id => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'DELETE',
    url: `${endpoint(ENDPOINT_PAYMENTS)}/${id}`,
    headers: {
      ...authHeaders,
    },
  };

  const response = http(requestOptions);
  return response;
};

// PAS 2.0 endpoints
const pasHeaders = {
  'X-Pbp-ClientType': 'businessapp',
  'X-Api-Key': API_KEY_PAS2,
};

const readOne = async (paymentId, workflowId) => {
  const authHeaders = await userService.getAuthorizationHeaders();

  const requestOptions = {
    method: 'GET',
    url: `${API_URL_PAS2}/${paymentId}`,
    headers: {
      ...authHeaders,
      ...pasHeaders,
      'X-Pbp-WorkflowId': workflowId,
    },
  };

  const response = httpWrapper(requestOptions);
  return response;
};

const create = async card => {
  const { recaptchaToken, ...cardData } = card;
  const currentLanguage = store && store.getState().user.currentCorporation.preferredLanguage;
  const authHeaders = await userService.getAuthorizationHeaders();
  const createPaymentHeaders = {
    'Accept-Language': currentLanguage,
    'X-Pbp-ClientColorDepth': window.screen.colorDepth >= 30 ? 32 : window.screen.colorDepth,
    'X-Pbp-ClientJavaEnabled': window.navigator.javaEnabled(),
    'X-Pbp-ClientScreenHeight': 288,
    'X-Pbp-ClientScreenWidth': 672,
    'X-Pbp-ClientTimeZoneOffsetMinutes': new Date().getTimezoneOffset(),
    'X-Pbp-VerificationToken': recaptchaToken,
    'X-Pbp-ClientType': 'businessapp',
  };

  const workflowId = uuidv4();
  const requestOptions = {
    method: 'POST',
    url: API_URL_PAS2,
    headers: {
      ...authHeaders,
      ...createPaymentHeaders,
      ...pasHeaders,
      'X-Pbp-WorkflowId': workflowId,
    },
    data: {
      ...cardData,
      scope: 'Shared',
    },
  };

  try {
    const response = await httpWrapper(requestOptions);
    return {
      response,
      workflowId,
    };
  } catch (e) {
    e.workflowId = workflowId;
    throw e;
  }
};

export default {
  read,
  readOne,
  create,
  update,
  destroy,
};
