import { useMemo, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useRegisterUser } from 'services/reactQueryHooks/adminUsers.js';
import settingsActions from 'redux/settings/actions';
import { ROUTE_VERIFY_EMAIL } from 'constants.js';
import { getUserRegistrationError } from './helper';

const useSelfRegistrationPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [pricePlan, setPricePlan] = useState(null);

  const {
    mutate: registerUser,
    isLoading: isRegistrationLoading,
    isSuccess: isRegistrationSuccessful,
    isError: isRegistrationFailed,
    error: registrationServerError,
  } = useRegisterUser();

  const registrationError = useMemo(() => {
    if (!isRegistrationFailed) return null;

    return getUserRegistrationError(registrationServerError?.errors);
  }, [isRegistrationFailed, registrationServerError]);

  const handleRegistration = useCallback(
    userInformation => {
      registerUser({ ...userInformation, pricePlan });
    },
    [pricePlan, registerUser],
  );

  const handleInitialLoading = useCallback(({ queryParams }) => {
    const pricePlanCodeFromUrl = queryParams?.p;

    if (pricePlanCodeFromUrl) {
      setPricePlan(pricePlanCodeFromUrl);
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: settingsActions.CHANGE_SETTING,
      payload: {
        setting: 'showSignInButton',
        value: !isRegistrationSuccessful,
      },
    });
    dispatch({
      type: settingsActions.CHANGE_SETTING,
      payload: {
        setting: 'showLanguageSelector',
        value: !isRegistrationSuccessful,
      },
    });

    if (isRegistrationSuccessful) {
      history.push(ROUTE_VERIFY_EMAIL);
    }
  }, [dispatch, history, isRegistrationSuccessful]);

  return {
    isRegistrationLoading,
    isRegistrationSuccessful,
    isRegistrationFailed,
    registrationError,
    onRegister: handleRegistration,
    onRegistrationPageLoad: handleInitialLoading,
  };
};

export default useSelfRegistrationPage;
