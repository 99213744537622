import { CORPORATE_CLIENT_COUNTRY_LOCALE } from 'constants/corporateClients';
import { DRIVER_IMPORT_CSV_HEADER_KEYS } from 'constants/drivers';
import {
  VEHICLES_IMPORT_CSV_HEADER_KEYS,
  VEHICLES_IMPORT_CSV_HEADER_REGION_KEYS,
} from 'constants/vehicles';

export const getDriverImportHeaders = (country, translateText) => {
  const localeOptions = {
    locale: CORPORATE_CLIENT_COUNTRY_LOCALE[country] || CORPORATE_CLIENT_COUNTRY_LOCALE.default,
  };
  return DRIVER_IMPORT_CSV_HEADER_KEYS.map((key, i) => {
    if (i < 2) {
      return {
        key,
        label: translateText(`corporateAccounts.admins.${key}`, localeOptions),
      };
    } else {
      return {
        key,
        label: translateText(`corporateAccounts.drivers.import.header.${key}`, localeOptions),
      };
    }
  });
};

export const getVehiclesImportHeaders = (country, translateText) => {
  const localeOptions = {
    locale: CORPORATE_CLIENT_COUNTRY_LOCALE[country] || CORPORATE_CLIENT_COUNTRY_LOCALE.default,
  };

  let vehicleHeaders = [];
  if (['CA', 'US'].includes(country)) {
    vehicleHeaders = VEHICLES_IMPORT_CSV_HEADER_REGION_KEYS.map(key => ({
      key,
      label: translateText(`corporateAccounts.vehicles.import.header.${key}`, localeOptions),
    }));
  } else {
    vehicleHeaders = VEHICLES_IMPORT_CSV_HEADER_KEYS.map(key => ({
      key,
      label: translateText(`corporateAccounts.vehicles.import.header.${key}`, localeOptions),
    }));
  }

  return vehicleHeaders;
};

export const getImportFileTranslatedByCountryCorp = (fileName, country, translateText) => {
  const localeOptions = {
    locale: CORPORATE_CLIENT_COUNTRY_LOCALE[country] || CORPORATE_CLIENT_COUNTRY_LOCALE.default,
  };

  return translateText(fileName, localeOptions);
};
