import store from 'store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { buildLocalStoreKey } from 'services/reactQueryHooks/localeStore';

/**
 * @param {antd.FormInstance} form
 */

const useValidateFormOnLocaleChange = form => {
  const locale = useSelector(state => state.settings.locale);
  const localStorageAsset = store.get(buildLocalStoreKey(locale));

  useEffect(() => {
    const fieldsWithErrors = form
      .getFieldsError()
      .filter(fieldError => !!fieldError.errors.length)
      .map(fieldError => fieldError.name);

    setTimeout(() => {
      form.validateFields(fieldsWithErrors);
    }, 0); // Ensure that the form validation is only triggered after the locale is updated.
  }, [locale, form, localStorageAsset]);
};

export default useValidateFormOnLocaleChange;
