import { useState, useEffect, useCallback } from 'react';
import { APPLE_SIGN_IN_CLIENT_ID, SOCIAL_SIGN_IN_REDIRECT_URL } from 'constants.js';

const API_URL_APPLE_SIGN_IN =
  'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/fr_FR/appleid.auth.js';

const useAppleSignIn = () => {
  const [isAppleSignInLoading, setIsAppleSignInLoading] = useState(false);
  const [appleSignInError, setAppleSignInError] = useState(null);
  const [appleSubjectToken, setAppleSubjectToken] = useState(null);

  const initializeAppleFramework = () => {
    if (window.AppleID) {
      window.AppleID.auth.init({
        clientId: APPLE_SIGN_IN_CLIENT_ID,
        scope: 'email',
        redirectURI: SOCIAL_SIGN_IN_REDIRECT_URL,
        state: 'apple-sing-in',
        usePopup: true,
      });
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = API_URL_APPLE_SIGN_IN;
    script.async = true;
    script.addEventListener('load', initializeAppleFramework);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      script.removeEventListener('load', initializeAppleFramework);
    };
  }, []);

  const signInWithApple = async () => {
    setIsAppleSignInLoading(true);
    setAppleSubjectToken(null);
    setAppleSignInError(null);

    document.addEventListener('AppleIDSignInOnSuccess', event => {
      setIsAppleSignInLoading(false);
      setAppleSubjectToken(event.detail.authorization.id_token);
    });

    document.addEventListener('AppleIDSignInOnFailure', event => {
      setIsAppleSignInLoading(false);
      setAppleSignInError(event.detail.error);
    });
  };

  const renderAppleSignInButton = useCallback(() => {
    if (window.AppleID) {
      window.AppleID.auth.renderButton();
      const appleButton = document.getElementById('appleid-signin');
      if (appleButton) {
        appleButton.addEventListener('click', signInWithApple);
      }
    }
  }, []);

  return {
    isAppleSignInLoading,
    appleSubjectToken,
    appleSignInError,
    renderAppleSignInButton,
  };
};

export default useAppleSignIn;
