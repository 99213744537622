import React from 'react';
import { Col, Row, Typography } from 'antd';
import notify from 'utils/notificationUtils';
import { MAX_UPLOAD_SIZE_BYTES } from 'constants.js';

export const uploadProps = (fileList, setFileList, translateText) => ({
  onRemove: file => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();

    newFileList.splice(index, 1);
    setFileList(newFileList);
  },
  beforeUpload: file => {
    if (fileList.length === 1) {
      notify({
        type: 'error',
        message: translateText('corporateAccounts.import.singleFile.error'),
      });
      return false;
    }

    if (file.size > MAX_UPLOAD_SIZE_BYTES) {
      notify({
        type: 'error',
        message: translateText('corporateAccounts.import.maxFileSize.error'),
      });
      return false;
    }

    setFileList([...fileList, file]);
    return false;
  },
  accept: '.csv',
  fileList,
});

export const handleUpload = (fileList, importFunc, corporateClientId, importEndpoint) => {
  const formData = new FormData();
  const file = fileList[0];

  formData.append('importFiles', file);
  importFunc(formData, corporateClientId, importEndpoint);
};

export const buildSteps = stepsContent => {
  const stepsArr = [];
  stepsContent.forEach(step => {
    const stepBody = (
      <Row gutter={[16, 16]} key={step.key} style={{ padding: '8px 0' }}>
        <Col span={6} style={{ textAlign: 'right' }}>
          <Typography.Paragraph strong>{step.title}</Typography.Paragraph>
        </Col>
        <Col span={3} />
        <Col span={15}>
          <Typography.Paragraph>{step.content}</Typography.Paragraph>
        </Col>
      </Row>
    );
    stepsArr.push(stepBody);
  });

  return stepsArr;
};
