import React from 'react';
import utilityModal from 'components/Shared/Modal/info';
import { localizedMessage } from 'services/localize';
import { DRIVERS_IMPORT, VEHICLES_IMPORT } from 'constants.js';
import { getDriverImportHeaders, getVehiclesImportHeaders } from 'utils/importUtil';

const setSuccessModal = type => {
  const successMessage =
    type === 'vehicle'
      ? 'Vehicles have been successfully imported'
      : 'Importing data can take a few hours to complete';

  utilityModal.successModal({
    content: (
      <div>
        <p>
          {localizedMessage(`corporateAccounts.import.${type}.success`, successMessage, {
            injectLocale: true,
          })}
        </p>
        {type !== 'vehicle' && (
          <p>{localizedMessage(`corporateAccounts.import.emailNotify.success`)}</p>
        )}
      </div>
    ),
    title: localizedMessage(`corporateAccounts.import.title.success`),
  });
};

const setErrorModal = ({
  title = localizedMessage('corporateAccounts.common.error'),
  content = localizedMessage('corporateAccounts.errors.fileUpload'),
} = {}) => {
  utilityModal.warningModal({
    title,
    content,
  });
};

export const getCSVHeadersFromImportType = (importType, country, translateText) => {
  let headers = [];

  if (importType === DRIVERS_IMPORT) {
    headers = getDriverImportHeaders(country, translateText);
  }
  if (importType === VEHICLES_IMPORT) {
    headers = getVehiclesImportHeaders(country, translateText);
  }

  return headers;
};

const uploadHelper = {
  setSuccessModal,
  setErrorModal,
  getCSVHeadersFromImportType,
};

export default uploadHelper;
