import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { onboardingVideoUrls } from 'constants/onboarding';

const useGetOnboardingVideoUrl = ({ topic }) => {
  const locale = useSelector(state => {
    return state.settings?.locale;
  });

  const url = useMemo(
    () => onboardingVideoUrls[topic][locale] || onboardingVideoUrls[topic]['en-US'],
    [locale, topic],
  );

  return url;
};

export default useGetOnboardingVideoUrl;
