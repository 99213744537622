export const trimValue = value => {
  if (!value) return '';
  return value.replace(/\s+/g, ' ').trim();
};

const searchUtil = {
  trimValue,
};

export default searchUtil;
