export const SCA_EVENTS = {
  OrderInitiateAuthentication: 'OrderInitiateAuthentication',
  OrderChallengeRequired: 'OrderChallengeRequired',
  PaymentAccountAdded: 'PaymentAccountAdded',
};

export const POLL_RETRIES = 45;

export const POLL_RETRY_DELAY = 2000;
export const POLL_RETRY_DELAY_AFTER_15_RETRIES = 4000;
export const POLL_RETRY_DELAY_AFTER_30_RETRIES = 6000;

export const PAS_STATUS = {
  Pending: 'Pending',
  InitiateAuthentication: 'InitiateAuthentication',
  Challenged: 'Challenged',
  Created: 'Created',
  Failed: 'Failed',
};

export const PAS_FAILURE_ERRORS = {
  CardValidationFailed: 'cardValidationFailed', // This error is most common
  // PAS 2.0 Errors - Most of these errors we don't expect to receive or are handled by the form validations. We support translations for these anyway to be safe.
  PaymentAccountNotFound: 'paymentAccountNotFound',
  PaymentAccountAlreadyExists: 'paymentAccountAlreadyExists',
  CardNumberRequired: 'cardNumberRequired',
  CardNumberInvalidFormat: 'cardNumberInvalidFormat',
  CardNumberInvalid: 'cardNumberInvalid',
  CardTypeNotSupported: 'cardTypeNotSupported',
  CountryCodeRequired: 'countryCodeRequired',
  CountryCodeNotSupported: 'countryCodeNotSupported',
  CardAlreadyExpired: 'cardAlreadyExpired',
  ExpiryYearTooFarInTheFuture: 'expiryYearTooFarInTheFuture',
  ExpiryYearRequired: 'expiryYearRequired',
  ExpiryMonthInvalid: 'expiryMonthInvalid',
  ExpiryMonthRequired: 'expiryMonthRequired',
  NameOnCardRequired: 'nameOnCardRequired',
  NameOnCardInvalidFormat: 'nameOnCardInvalidFormat',
  CvvRequired: 'cvvRequired',
  CvvInvalidFormat: 'cvvInvalidFormat',
  ZipCodeInvalidFormat: 'zipCodeInvalidFormat',
  // Polling error
  PollingExpired: 'pollingExpired',
  UnhandledError: 'unhandledError',
  RestrictedCorporateClientLimitExceeded: 'limitExceeded',
};

export const PAYMENT_STATUS = {
  ACTIVE: 0,
  EXPIRED: 2,
};

export const PAYMENT_STATUS_TAGS = {
  [PAYMENT_STATUS.ACTIVE]: {
    key: 'active',
    value: PAYMENT_STATUS.ACTIVE,
  },
  [PAYMENT_STATUS.EXPIRED]: {
    key: 'expired',
    value: PAYMENT_STATUS.EXPIRED,
  },
};

export const UNSUPPORTED_CREDIT_CARDS = [{ type: 'American Express', prefix: 34 }];

export default {
  SCA_EVENTS,
  PAS_STATUS,
  POLL_RETRY_DELAY,
  POLL_RETRIES,
  PAYMENT_STATUS,
  PAYMENT_STATUS_TAGS,
};
