import moment from 'moment';

export const formatDateToMonthAndYear = value => {
  return moment(value).format('MMM, YYYY');
};

export const getNextBillingCycle = now => {
  const nextBillingCycle = new Date(now);
  const currentMonth = nextBillingCycle.getMonth();
  nextBillingCycle.setMonth(currentMonth + 1);
  return nextBillingCycle;
};
