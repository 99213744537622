import React from 'react';
import SystemModal from 'components/Shared/Modal';
import useTranslation from 'utils/hooks/useTranslation';

const ScaVerificationModal = ({ onOk, visible, onCancel }) => {
  const { translateText } = useTranslation();
  const MODAL_WIDTH = 397;

  return (
    <SystemModal
      onOk={onOk}
      onCancel={onCancel}
      okText={translateText('corporateAccounts.actions.continue')}
      visible={visible}
      width={MODAL_WIDTH}
      title={translateText('corporateAccounts.payments.spa.verification.title')}
    >
      <div>{translateText('corporateAccounts.payments.spa.verification.content')}</div>
    </SystemModal>
  );
};

export default ScaVerificationModal;
