import { httpWrapper } from 'services/http';
import userService from 'services/user';
import {
  ENDPOINT_DRIVERS,
  ENDPOINT_DRIVERSV2,
  API_URL_CA_V2,
  API_URL_DRIVERS_V1,
  ENDPOINT_ACTIVATE,
  ENDPOINT_REGISTER,
  API_URL_CORE,
} from 'constants.js';
import { endpoint, endpointCorporateClient } from '.';

const read = async query => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'GET',
    url: endpoint(ENDPOINT_DRIVERS, true),
    headers: {
      ...authHeaders,
    },
    params: query,
  };

  const response = httpWrapper(requestOptions);
  return response;
};

const create = async data => {
  const { recaptchaToken, ...requestData } = data;
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'POST',
    url: endpoint(ENDPOINT_DRIVERS),
    headers: {
      ...authHeaders,
      'X-Pbp-VerificationToken': recaptchaToken,
      'X-Pbp-ClientType': 'businessapp',
    },
    data: requestData,
  };

  const response = httpWrapper(requestOptions);
  return response;
};

const destroy = async id => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'DELETE',
    url: `${endpoint(ENDPOINT_DRIVERS)}/${id}`,
    headers: {
      ...authHeaders,
    },
  };

  const response = httpWrapper(requestOptions);
  return response;
};

const batchDelete = async data => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'POST',
    url: `${endpoint(ENDPOINT_DRIVERS, true, API_URL_CA_V2)}:batchDelete`,
    headers: {
      ...authHeaders,
    },
    data,
  };

  const response = httpWrapper(requestOptions);
  return response;
};

const update = async data => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'PUT',
    url: `${endpoint(ENDPOINT_DRIVERS)}/${data.id}`,
    headers: {
      ...authHeaders,
    },
    data,
  };

  const response = httpWrapper(requestOptions);
  return response;
};

const invitation = async driverData => {
  const { recaptchaToken, ...requestData } = driverData;
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'POST',
    url: `${endpoint(ENDPOINT_DRIVERS, true)}:sendInvitation`,
    headers: {
      ...authHeaders,
      'X-Pbp-VerificationToken': recaptchaToken,
      'X-Pbp-ClientType': 'businessapp',
    },
    data: requestData,
  };

  const response = httpWrapper(requestOptions);
  return response;
};

const upload = async data => {
  const { recaptchaToken, formData } = data;
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'POST',
    url: `${endpointCorporateClient(ENDPOINT_DRIVERSV2, true, API_URL_DRIVERS_V1)}:upload`,
    headers: {
      ...authHeaders,
      'X-Pbp-VerificationToken': recaptchaToken,
      'X-Pbp-ClientType': 'businessapp',
    },
    data: formData,
  };

  const response = httpWrapper(requestOptions);
  return response;
};

const activateV2 = async data => {
  const requestOptions = {
    method: 'POST',
    url: `${endpoint(ENDPOINT_DRIVERSV2, false, API_URL_CORE)}${ENDPOINT_ACTIVATE}`,
    headers: {},
    data,
  };

  const response = httpWrapper(requestOptions);
  return response;
};

const find = async query => {
  const requestOptions = {
    method: 'GET',
    url: `${endpoint(ENDPOINT_DRIVERSV2, false, API_URL_CORE)}/invitations?invitationCode=${query}`,
    headers: {},
  };

  const response = httpWrapper(requestOptions);
  return response;
};

const register = async data => {
  const { recaptchaToken, ...requestData } = data;
  const requestOptions = {
    method: 'POST',
    url: `${endpoint(ENDPOINT_DRIVERSV2, false, API_URL_CORE)}${ENDPOINT_REGISTER}`,
    headers: {
      'X-Pbp-VerificationToken': recaptchaToken,
      'X-Pbp-ClientType': 'businessapp',
    },
    data: requestData,
  };

  const response = httpWrapper(requestOptions);
  return response;
};

export default {
  read,
  create,
  update,
  destroy,
  activateV2,
  batchDelete,
  invitation,
  upload,
  find,
  register,
};
