import enUsAntData from 'antd/lib/locale-provider/en_US';
import enGbAntData from 'antd/lib/locale-provider/en_GB';
import frFrAntData from 'antd/lib/locale-provider/fr_FR';
import deDeAntData from 'antd/lib/locale-provider/de_DE';

import enUsMessages from './en-US';
import enGbMessages from './en-GB';
import frFrMessages from './fr-FR';
import deDeMessages from './de-DE';
import globalMessages from './glb-glb';

const glbAntData = enUsAntData;
glbAntData.locale = 'glb-glb';

export default {
  'en-US': {
    locale: 'en-US',
    antdData: enUsAntData,
    messages: enUsMessages,
    momentLocale: 'en',
  },
  'en-GB': {
    locale: 'en-GB',
    antdData: enGbAntData,
    messages: enGbMessages,
    momentLocale: 'en-gb',
  },
  'fr-FR': {
    locale: 'fr-FR',
    antdData: frFrAntData,
    messages: frFrMessages,
    momentLocale: 'fr',
  },
  'de-DE': {
    locale: 'de-DE',
    antdData: deDeAntData,
    messages: deDeMessages,
    momentLocale: 'de',
  },
  'glb-glb': {
    locale: 'glb-glb',
    antdData: glbAntData,
    messages: globalMessages,
    momentLocale: 'en',
  },
};
