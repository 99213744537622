import React from 'react';

const MenuLeftBillingIcon = () => (
  <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon /Bank">
      <path
        id="Vector"
        d="M14.8211 7.10625C15.3729 7.10625 15.6032 6.39732 15.155 6.07054L8.33536 1.10982C8.23797 1.03847 8.12038 1 7.99965 1C7.87891 1 7.76132 1.03847 7.66393 1.10982L0.844289 6.07054C0.396074 6.39554 0.626431 7.10625 1.18 7.10625H2.28536V13.7848H0.999646C0.921074 13.7848 0.856788 13.8491 0.856788 13.9277V14.8563C0.856788 14.9348 0.921074 14.9991 0.999646 14.9991H14.9996C15.0782 14.9991 15.1425 14.9348 15.1425 14.8563V13.9277C15.1425 13.8491 15.0782 13.7848 14.9996 13.7848H13.7139V7.10625H14.8211ZM7.99965 2.36875L12.8407 5.89018H3.15857L7.99965 2.36875ZM3.57107 7.10625H5.66036V13.7848H3.57107V7.10625ZM6.94607 7.10625H9.03536V13.7848H6.94607V7.10625ZM12.4282 13.7848H10.3211V7.10625H12.4282V13.7848Z"
        fill="white"
      />
    </g>
  </svg>
);

export default MenuLeftBillingIcon;
