import React from 'react';
import { Select, Typography } from 'antd';
import useTranslation from 'utils/hooks/useTranslation';
import styles from './styles.module.scss';

const MonthlyStatementActionRow = ({ filterYear, filterYearOptions, onFilterYearChange }) => {
  const { translateText } = useTranslation();

  return (
    <div className="card-body card-body-padding-bottom-0">
      <span>
        <Typography.Title level={5}>
          {translateText('corporateAccounts.common.year')}
        </Typography.Title>
        <Select
          className={styles.yearInput}
          value={filterYear}
          onChange={onFilterYearChange}
          showSearch
          optionFilterProp="children"
        >
          <Select.Option data-testid="option-default" value={'All'}>
            {translateText('corporateAccounts.common.all')}
          </Select.Option>
          {filterYearOptions.map(year => (
            <Select.Option data-testid={`option-${year}`} key={year} value={year}>
              {year}
            </Select.Option>
          ))}
        </Select>
      </span>
    </div>
  );
};

export default React.memo(MonthlyStatementActionRow);
