import rg4js from 'raygun4js';
import { RAYGUN_KEY } from '../constants';

export const initializeRaygun = () => {
  if (RAYGUN_KEY) {
    rg4js('apiKey', RAYGUN_KEY);
    rg4js('enableCrashReporting', true);
    rg4js('options', {
      ignore3rdPartyErrors: true,
    });
    rg4js('onBeforeSend', payload => {
      if (payload.Details.Error.Message.includes('ResizeObserver')) return null;
      return payload;
    });
  } else {
    console.error('[ERROR] Incorrect or missing Raygun key');
  }
};

export const setRaygunUserId = userId => {
  if (RAYGUN_KEY) {
    rg4js('setUser', {
      identifier: userId,
      isAnonymous: false,
    });
  }
};
