export const STAFF_USER_ROLES = {
  pbp_customer_success: {
    label: 'Customer Success',
    key: 'pbp_customer_success',
  },
  pbp_tier_2_customer_support_corpaccts: {
    label: 'Customer Support Tier 2',
    key: 'pbp_tier_2_customer_support_corpaccts',
  },
  pbp_super_admin: {
    label: 'Super Admin',
    key: 'pbp_super_admin',
  },
};

export const STAFF_USER_STATUS = {
  Active: {
    label: 'Active',
    value: 'Active',
    key: 'active',
  },
  Inactive: {
    label: 'Inactive',
    value: 'Inactive',
    key: 'inactive',
  },
};
