import http from 'services/http';
import userService from 'services/user';
import { ENDPOINT_ADMINS_INVITE } from 'constants.js';
import notify from 'utils/notificationUtils';
import { endpoint } from '.';
import localizedMessage from 'services/localize';

const admin = async () => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'PUT',
    url: endpoint(ENDPOINT_ADMINS_INVITE, false),
    headers: {
      ...authHeaders,
    },
  };

  const response = await http(requestOptions);

  if (response && response.status === 400) {
    notify({
      type: 'error',
      message: localizedMessage('corporateAccounts.user.activation.error'),
    });
  }
  return response;
};

export default { admin };
