import actions from './actions';

export const INITIAL_STATE = {
  loading: false,
  submitting: false,
  data: [],
  corporateClient: null,
  editableCorporateClient: null,
  formErrors: [],
};

export default function corporationsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actions.GET_BY_ID:
      return {
        ...state,
        loading: true,
        editableCorporateClient: null,
      };

    case actions.GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        editableCorporateClient: action.payload,
      };
    case actions.GET_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
        editableCorporateClient: null,
      };
    case actions.POST:
      return {
        ...state,
        submitting: true,
        success: false,
      };
    case actions.POST_SUCCESS:
      return {
        ...state,
        submitting: false,
        success: true,
        corporateClient: action.payload,
        formErrors: [],
      };
    case actions.POST_ERROR:
      return {
        ...state,
        submitting: false,
        success: false,
      };
    case actions.PUT:
      return {
        ...state,
        loading: true,
        submitting: true,
      };
    case actions.PUT_SUCCESS:
      return {
        ...state,
        loading: false,
        submitting: false,
        editableCorporateClient: action.payload,
      };
    case actions.PUT_ERROR:
      return {
        ...state,
        loading: false,
        submitting: false,
        formErrors: action.payload,
      };

    case actions.RESET:
      return {
        ...state,
        corporateClient: null,
        formErrors: [],
        submitting: false,
      };
    case actions.RESET_EDIT_CORPORATION:
      return {
        ...state,
        editableCorporateClient: null,
      };
    case actions.RESET_ERRORS:
      return {
        ...state,
        formErrors: [],
      };
    default:
      return state;
  }
}
