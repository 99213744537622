const mapGetVehiclesResponse = (state, paginated) => {
  const vehicles = paginated ? paginated.items : [];
  const showNoVehicleUI = state.showNoVehicleUI
    ? paginated.totalCount === 0
    : state.showNoVehicleUI;
  const totalCount = paginated.offset !== 0 ? state.totalCount : paginated.totalCount;

  return {
    data: vehicles,
    showNoVehicleUI,
    totalCount,
  };
};

const mapCreatedVehicle = (state, createdVehicle) => {
  return {
    data: [createdVehicle, ...state.data],
    showNoVehicleUI: false,
    totalCount: state.totalCount + 1,
  };
};

const mapEditedVehicle = (state, editedVehicle) => {
  return [...state.data.map(row => (row.id === editedVehicle.id ? editedVehicle : row))];
};

const mapDeletedVehicle = (state, deletedVehicle) => {
  return {
    data: [...state.data.filter(row => row.id !== deletedVehicle.id)],
    showNoVehicleUI: state.totalCount - 1 === 0,
    totalCount: state.totalCount - 1,
  };
};

const mapBatchDeletedVehicles = (state, deletedVehicles) => {
  return {
    showNoVehicleUI: state.totalCount - deletedVehicles.vehicleIds.length === 0,
    totalCount: state.totalCount - deletedVehicles.vehicleIds.length,
    data: state.data.filter(row => !deletedVehicles.vehicleIds.includes(row.id)),
    loading: false,
    refetch: true,
  };
};

const mapExportedVehicles = exportedVehicleResponse => {
  return exportedVehicleResponse.map(vehicle => {
    return {
      licensePlate: vehicle.licensePlate,
      country: vehicle.country,
      jurisdiction: vehicle.jurisdiction,
      vin: vehicle.vin,
    };
  });
};

export default {
  mapCreatedVehicle,
  mapGetVehiclesResponse,
  mapEditedVehicle,
  mapDeletedVehicle,
  mapBatchDeletedVehicles,
  mapExportedVehicles,
};
