import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import PageWrapper from 'components/Shared/Page/Wrapper';
import useCookiesConsent from 'utils/hooks/useCookiesConsent';
import { ONBOARDING_STATUS } from 'constants/onboarding';
import onboardingHelper from './helper';
import useOnboardingPage from './useOnboardingPage';
import DriverActivation from './DriverActivation';
import DriverRegistration from './DriverRegistration';
import OnboardingPageHeader from './OnboardingPageHeader';
import DriverAlreadyActive from './DriverAlreadyActive';

import styles from './style.module.scss';

const Onboarding = ({ location }) => {
  const {
    driverInfo,
    activationError,
    registrationError,
    isRegistrationSuccessful,
    isPageLoading,
    onBoardingStatus,
    driverLoadingErrors,
    driverAlreadyActive,
    enableFacebookSignIn,
    registrationCode,
    renderAppleSignInButton,
    onRegister,
    onPageLoad,
  } = useOnboardingPage();

  useEffect(() => {
    onPageLoad({
      queryParams: onboardingHelper.getQueryParameters(location.search),
      hashParams: onboardingHelper.getHashParameters(location.hash),
    });
  }, [onPageLoad, location.search, location]);

  useCookiesConsent();

  return (
    <PageWrapper loading={isPageLoading}>
      <div className={styles.onboardingPage}>
        <OnboardingPageHeader />
        {!isPageLoading &&
          (driverAlreadyActive ? (
            <DriverAlreadyActive />
          ) : onBoardingStatus === ONBOARDING_STATUS.activation ? (
            <DriverActivation error={activationError} />
          ) : onBoardingStatus === ONBOARDING_STATUS.registration ? (
            <DriverRegistration
              driverInfo={driverInfo}
              driverLoadingErrors={driverLoadingErrors}
              registrationError={registrationError}
              isRegistrationSuccessful={isRegistrationSuccessful}
              onRegister={onRegister}
              registrationCode={registrationCode}
              renderAppleSignInButton={renderAppleSignInButton}
              enableFacebookSignIn={enableFacebookSignIn}
            />
          ) : null)}
      </div>
    </PageWrapper>
  );
};

export default withRouter(injectIntl(Onboarding));
