import { SESSION_EXPIRY_TIME } from 'constants.js';

const decodeToken = encodedToken => {
  if (!encodedToken) return {};

  const parts = encodedToken.split('.');
  if (parts.length !== 3) return {};

  return JSON.parse(jwt.decodeBase64(parts[1]));
};

const isTokenExpired = encodedToken => {
  const decoded = jwt.decodeToken(encodedToken);
  if (!decoded.exp) return false;
  const tokenExpires = new Date(0).setUTCSeconds(decoded.exp);

  return tokenExpires.valueOf() <= new Date().valueOf();
};

const isSessionExpired = encodedToken => {
  const decoded = jwt.decodeToken(encodedToken);
  if (!decoded.exp) return false;
  const expiryTime = new Date(0).setUTCSeconds(decoded.exp);
  const oneDay = 60 * 60 * SESSION_EXPIRY_TIME * 1000; // milliseconds
  const currentTime = new Date().valueOf();

  return currentTime - expiryTime > oneDay;
};

const decodeBase64 = input => {
  return atob(input);
};

const jwt = {
  decodeBase64,
  decodeToken,
  isSessionExpired,
  isTokenExpired,
};

export default jwt;
