import { useQuery } from 'react-query';
import useTranslation from 'utils/hooks/useTranslation';
import notify from 'utils/notificationUtils';
import { auditLogsQueryKeys } from '../queryKeys';
import auditLogsApi from 'services/api/auditLogs';

const { getAuditLogsQuery } = auditLogsQueryKeys;
const AUDITLOGS_DATA_STALETIME = 30000;

export const useGetAuditLogs = ({
  corporateClientId,
  pageSize,
  offset,
  query,
  orderBy,
  filters,
  startDate,
  endDate,
}) => {
  const { translateText } = useTranslation();

  const loadAuditLogs = async ({ queryKey }) => {
    const [, { offset, pageSize, query, orderBy, filters, startDate, endDate }] = queryKey;
    const result = await auditLogsApi.read({
      pageSize,
      offset,
      query,
      orderBy,
      startDate,
      endDate,
      ...filters,
    });

    return result?.data;
  };

  return useQuery({
    queryKey: [
      getAuditLogsQuery,
      { corporateClientId, offset, pageSize, query, orderBy, filters, startDate, endDate },
    ],
    queryFn: loadAuditLogs,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    staleTime: AUDITLOGS_DATA_STALETIME,
    onError: () => {
      notify({
        type: 'error',
        message: translateText('corporateAccounts.notify.error.auditLogs'),
      });
    },
  });
};

export const useGetAuditLogChanges = ({ corporateClientId, auditLogId }) => {
  const { translateText } = useTranslation();

  const getAuditLogChanges = async ({ queryKey }) => {
    const [, { auditLogId }] = queryKey;
    const result = await auditLogsApi.getAuditLogChanges(auditLogId);

    return result?.data;
  };

  return useQuery({
    queryKey: [getAuditLogsQuery, { corporateClientId, auditLogId }],
    queryFn: getAuditLogChanges,
    enabled: auditLogId != null,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    staleTime: AUDITLOGS_DATA_STALETIME,
    onError: () => {
      notify({
        type: 'error',
        message: translateText('corporateAccounts.notify.error.auditLogs'),
      });
    },
  });
};
