import { REHYDRATE } from 'redux-persist/lib/constants';
import { all, takeEvery, take, put, call } from 'redux-saga/effects';
import { isSuccess } from 'utils/sagasUtil';
import { configuration } from 'services/api';
import actions from './actions';

export function* GET() {
  const { response } = yield call(configuration.read);

  if (isSuccess(response)) {
    yield put({
      type: actions.GET_SUCCESS,
      payload: response.data,
    });
  } else {
    yield put({
      type: actions.GET_ERROR,
    });
  }
}

export default function* rootSaga() {
  // Wait for redux-persist to rehydrate to prevent sagas running against empty store
  yield take(REHYDRATE);
  yield all([takeEvery(actions.GET, GET)]);
}
