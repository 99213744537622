import { Drawer } from 'antd';
import HeaderMenu from './HeaderMenu';
import styles from './style.module.scss';

const HeaderDrawer = ({ isOpen }) => (
  <Drawer
    visible={isOpen}
    placement="top"
    closable={false}
    drawerStyle={{ backgroundColor: '#9b9b9b' }}
  >
    <div id={styles.drawerTopSpacing}></div>
    <HeaderMenu />
  </Drawer>
);

export default HeaderDrawer;
