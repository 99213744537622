import { useQuery } from 'react-query';
import notify from 'utils/notificationUtils';
import monthlyStatementsApi from 'services/api/monthlyStatements';
import useTranslation from 'utils/hooks/useTranslation';
import { monthlyStatementQueryKeys } from './queryKeys';

const { getMonthlyStatements, downloadMonthlyStatement } = monthlyStatementQueryKeys;

export const useGetMonthlyStatements = () => {
  const { translateText } = useTranslation();
  const loadMonthlyStatements = async () => {
    const result = await monthlyStatementsApi.readAll();
    return result?.data;
  };

  return useQuery({
    queryKey: [getMonthlyStatements],
    queryFn: loadMonthlyStatements,
    keepPreviousData: false,
    onError: () => {
      notify({
        type: 'error',
        message: translateText('corporateAccounts.notify.error.getMonthlyStatements'),
      });
    },
    staleTime: 5000,
  });
};

export const useDownloadMonthlyStatement = id => {
  const { translateText } = useTranslation();
  const loadMonthlyStatements = async ({ queryKey }) => {
    const [, statementId] = queryKey;
    const result = await monthlyStatementsApi.download(statementId);
    return result?.data;
  };

  return useQuery({
    queryKey: [downloadMonthlyStatement, id],
    queryFn: loadMonthlyStatements,
    keepPreviousData: false,
    enabled: false,
    onError: () => {
      notify({
        type: 'error',
        message: translateText('corporateAccounts.notify.error.downloadMonthlyStatement'),
      });
    },
  });
};

export default {
  useGetMonthlyStatements,
};
