const actions = {
  GET_MONTHLY_REPORTS: 'reports/GET_MONTHLY_REPORTS',
  GET_MONTHLY_REPORTS_SUCCESS: 'reports/GET_MONTHLY_REPORTS_SUCCESS',
  GET_MONTHLY_REPORTS_ERROR: 'reports/GET_MONTHLY_REPORTS_ERROR',
  GENERATE_REPORT: 'reports/GENERATE_REPORT',
  GENERATE_REPORT_SUCCESS: 'reports/GENERATE_REPORT_SUCCESS',
  GENERATE_REPORT_ERROR: 'reports/GENERATE_REPORT_ERROR',
  QUERY: 'reports/GET',
  QUERY_SUCCESS: 'reports/GET_SUCCESS',
  QUERY_ERROR: 'reports/GET_ERROR',
  RESET_ERRORS: 'reports/RESET_ERRORS',
  RESET_REPORTS: 'reports/RESET_REPORTS',
};

export default actions;
