import React from 'react';

const MenuLeftStaffUsersIcon = () => (
  <svg width="20" height="20" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon /Security">
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9446 2.91664L7.87679 0.848779C7.80357 0.823779 7.70536 0.811279 7.60714 0.811279C7.50893 0.811279 7.41071 0.823779 7.3375 0.848779L1.26964 2.91664C1.12143 2.96664 1 3.13807 1 3.29521V11.9095C1 12.0666 1.10179 12.2738 1.225 12.372L7.38036 17.1684C7.44286 17.2166 7.52321 17.2416 7.60536 17.2416C7.6875 17.2416 7.76964 17.2166 7.83036 17.1684L13.9857 12.372C14.1089 12.2756 14.2107 12.0684 14.2107 11.9095V3.29521C14.2143 3.13807 14.0929 2.96842 13.9446 2.91664ZM11.131 12.9673L7.60714 15.7131L3.92405 12.8432C4.54997 11.4945 5.91645 10.5599 7.50002 10.5599C9.13184 10.5599 10.533 11.5524 11.131 12.9673ZM12.0384 12.2602C11.4034 10.9759 10.2395 9.99971 8.83081 9.61674C9.53327 9.17417 10 8.39159 10 7.5C10 6.11929 8.88071 5 7.5 5C6.11929 5 5 6.11929 5 7.5C5 8.3916 5.46674 9.17418 6.16921 9.61675C4.80445 9.9878 3.66947 10.9157 3.02263 12.1408L2.28571 11.5666V3.93092L7.60714 2.11664L12.9286 3.93092V11.5666L12.0384 12.2602ZM7.5 8.88C8.26215 8.88 8.88 8.26215 8.88 7.5C8.88 6.73785 8.26215 6.12 7.5 6.12C6.73785 6.12 6.12 6.73785 6.12 7.5C6.12 8.26215 6.73785 8.88 7.5 8.88Z"
        fill="white"
      />
    </g>
  </svg>
);

export default MenuLeftStaffUsersIcon;
