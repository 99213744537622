export const OPERATING_SYSTEM = {
  IOS: 'iOS',
  Android: 'Android',
  Windows: 'Windows',
  MacOS: 'MacOS',
};

export default {
  OPERATING_SYSTEM,
};
