import React from 'react';
import AmexSvg from 'assets/paymentMethods/AmericanExpress.svg';
import DiscoverSvg from 'assets/paymentMethods/Discover.svg';
import MaestroSvg from 'assets/paymentMethods/Maestro.svg';
import MastercardSvg from 'assets/paymentMethods/Mastercard.svg';
import VisaSvg from 'assets/paymentMethods/Visa.svg';

const CARD_TYPE = {
  AMEX: 'American Express',
  DISCOVER: 'Discover',
  MAESTRO: 'Maestro',
  MASTERCARD: 'Mastercard',
  VISA: 'Visa',
};

const CreditCardImage = ({ paymentCardType }) => {
  let cardSvgSrc;

  switch (paymentCardType) {
    case CARD_TYPE.AMEX:
      cardSvgSrc = AmexSvg;
      break;
    case CARD_TYPE.DISCOVER:
      cardSvgSrc = DiscoverSvg;
      break;
    case CARD_TYPE.MAESTRO:
      cardSvgSrc = MaestroSvg;
      break;
    case CARD_TYPE.MASTERCARD:
      cardSvgSrc = MastercardSvg;
      break;
    case CARD_TYPE.VISA:
      cardSvgSrc = VisaSvg;
      break;
    default:
      cardSvgSrc = null;
  }

  return cardSvgSrc ? <img src={cardSvgSrc} alt={paymentCardType} /> : paymentCardType;
};

export default CreditCardImage;
