import React from 'react';

const MenuLeftMonthlyStatementsIcon = ({ color = '#6C6C6C' }) => (
  <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon /Statement">
      <path
        id="Vector"
        d="M3.625 15C3.06597 15 2.63455 14.8511 2.33073 14.5534C2.02691 14.2556 1.875 13.8212 1.875 13.25V10.625H3.625V1L4.675 2.05729L5.725 1L6.775 2.05729L7.825 1L8.875 2.05729L9.925 1L10.975 2.05729L12.025 1L13.075 2.05729L14.125 1V13.0312C14.125 13.6024 13.9366 14.0733 13.5599 14.444C13.1832 14.8147 12.7153 15 12.1562 15H3.625ZM12.1562 13.6875C12.3507 13.6875 12.5087 13.6298 12.6302 13.5143C12.7517 13.3989 12.8125 13.2378 12.8125 13.0312V3.1875H4.9375V10.625H11.5V13.0312C11.5 13.2378 11.5608 13.3989 11.6823 13.5143C11.8038 13.6298 11.9618 13.6875 12.1562 13.6875ZM5.8125 5.8125V4.5H9.75V5.8125H5.8125ZM5.8125 8V6.6875H9.75V8H5.8125ZM11.2812 5.8125C11.1111 5.8125 10.9592 5.74998 10.8255 5.62494C10.6918 5.49989 10.625 5.34494 10.625 5.1601C10.625 4.97524 10.6918 4.81901 10.8255 4.69141C10.9592 4.5638 11.1141 4.5 11.2904 4.5C11.4666 4.5 11.6185 4.56252 11.7461 4.68756C11.8737 4.81261 11.9375 4.96756 11.9375 5.1524C11.9375 5.33726 11.8746 5.49349 11.7488 5.62109C11.623 5.7487 11.4672 5.8125 11.2812 5.8125ZM11.2812 8C11.1111 8 10.9592 7.93748 10.8255 7.81244C10.6918 7.68739 10.625 7.53244 10.625 7.3476C10.625 7.16274 10.6918 7.00651 10.8255 6.87891C10.9592 6.7513 11.1141 6.6875 11.2904 6.6875C11.4666 6.6875 11.6185 6.75002 11.7461 6.87506C11.8737 7.00011 11.9375 7.15506 11.9375 7.3399C11.9375 7.52476 11.8746 7.68099 11.7488 7.80859C11.623 7.9362 11.4672 8 11.2812 8ZM3.625 13.6875H10.1875V11.9375H3.1875V13.25C3.1875 13.4566 3.20877 13.5812 3.2513 13.6237C3.29384 13.6662 3.4184 13.6875 3.625 13.6875Z"
        fill="white"
      />
    </g>
  </svg>
);

export default MenuLeftMonthlyStatementsIcon;
