import { parsePhoneNumber } from 'libphonenumber-js';

const formatPhoneNumber = phoneNumber => {
  try {
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
    return parsedPhoneNumber.formatInternational();
  } catch (e) {
    return <p style={{ color: 'red', marginBottom: '0px' }}>{e.message}</p>;
  }
};

export default formatPhoneNumber;
