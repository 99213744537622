const mapCreatedSpaPayment = (state, createdPayment) => {
  const CHALLENGE_REQUIRED = 'ChallengeRequired';
  const decodedChallenge =
    createdPayment.cardValidation.challengeQuestion &&
    atob(createdPayment.cardValidation.challengeQuestion);

  return {
    paymentCreateSuccess: true,
    scaOrderChallengeRequired:
      createdPayment.cardValidation.validationStatus === CHALLENGE_REQUIRED,
    scaOrderChallengeHtml: decodedChallenge,
    newPaymentAccountId: createdPayment.accountId,
  };
};

const mapEdittedSpaPayment = (state, edittedSpaPayment) => {
  return {
    data: [
      ...state.data.map(row =>
        row.id === edittedSpaPayment.id ? { ...row, note: edittedSpaPayment.note } : row,
      ),
    ],
  };
};

const mapFailedSpaPayment = (state, formErrors) => {
  return {
    paymentCreateFailed: true,
    formErrors,
  };
};

const mapDeletedPayment = (state, deletedPayment) => {
  return [...state.data.filter(payment => payment.id !== deletedPayment.id)];
};

export default {
  mapCreatedSpaPayment,
  mapEdittedSpaPayment,
  mapFailedSpaPayment,
  mapDeletedPayment,
};
