import { CREDIT_ACCOUNT_MIGRATION_STATUS, CREDIT_ACCOUNT_STATUS } from 'constants/creditAccount';
const toCamelCase = string => string?.charAt(0).toLowerCase() + string?.slice(1);

const creditAccountMigrationConfigManager = ({ corporation, override = null }) => {
  if (!corporation) return null;

  const isCreditAccountMigrationStarted =
    toCamelCase(corporation.creditAccountMigrationStatus) ===
    CREDIT_ACCOUNT_MIGRATION_STATUS.STARTED;

  const isNewBadgeDisplayed =
    isCreditAccountMigrationStarted &&
    toCamelCase(corporation.creditAccountStatus) !== CREDIT_ACCOUNT_STATUS.ACTIVE;

  const isCreditAccountPageVisible = isCreditAccountMigrationStarted;

  const isCreditAccountPendingBannerVisible =
    isCreditAccountMigrationStarted &&
    toCamelCase(corporation.creditAccountStatus) === CREDIT_ACCOUNT_STATUS.PENDING;

  const isCreditAccountRejectedBannerVisible =
    isCreditAccountMigrationStarted &&
    toCamelCase(corporation.creditAccountStatus) === CREDIT_ACCOUNT_STATUS.REJECTED;

  const isCreditAccountBannerVisible = isCreditAccountMigrationStarted;

  const isCreditAccountInfoModalVisible =
    override?.isCreditAccountInfoModalVisible != null
      ? override.isCreditAccountInfoModalVisible
      : isCreditAccountMigrationStarted && !corporation.creditAccountStatus;

  const isPaymentMethodsPageBlocked =
    toCamelCase(corporation.creditAccountMigrationStatus) ===
      CREDIT_ACCOUNT_MIGRATION_STATUS.STARTED &&
    toCamelCase(corporation.creditAccountStatus) === CREDIT_ACCOUNT_STATUS.ACTIVE;

  return {
    isCreditAccountInfoModalVisible,
    paymentMethods: {
      isPageBlocked: isPaymentMethodsPageBlocked,
      isCreditAccountBannerVisible,
    },
    creditAccount: {
      isNewBadgeDisplayed,
      isPageVisible: isCreditAccountPageVisible, // ToDo - this check to be added to the credit account page and left menu item
      isPendingBannerVisible: isCreditAccountPendingBannerVisible,
      isRejectedBannerVisible: isCreditAccountRejectedBannerVisible,
    },
  };
};

export default creditAccountMigrationConfigManager;
