import React, { Fragment } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import NProgress from 'nprogress';
import { Helmet } from 'react-helmet';
import Loader from 'components/Shared/Loader';
import { ROUTES } from 'constants.js';
import PublicLayout from './Public';
import AuthRedirectLayout from './AuthRedirect';
import OnboardingLayout from './Onboarding';
import MainLayout from './Main';
import RegistrationLayout from './RegistrationLayout';

const isOnboarding = pathname => {
  return pathname.includes(ROUTES.ONBOARDING) ? true : false;
};

const Layouts = {
  activationRedirect: AuthRedirectLayout,
  public: PublicLayout,
  authRedirect: AuthRedirectLayout,
  onboarding: OnboardingLayout,
  main: MainLayout,
  registration: RegistrationLayout,
};
class IndexLayout extends React.PureComponent {
  previousPath = '';

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { prevLocation } = prevProps;

    if (location !== prevLocation) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const {
      children,
      location: { pathname, search },
      user,
      showNav,
      showFooter,
      showMenu,
      padContent,
    } = this.props;

    // NProgress Management
    const currentPath = pathname + search;
    if (currentPath !== this.previousPath) {
      NProgress.start();
    }

    setTimeout(() => {
      NProgress.done();
      this.previousPath = currentPath;
    }, 300);

    const isUserAuthorized = user.authorized;
    const isUserLoading = user.loading;
    const isEnteredCorp = user.currentCorporation !== null;

    // Layout Rendering
    const getCurrentLayout = () => {
      if (pathname === '/') {
        return Layouts.public;
      }

      if (isOnboarding(pathname)) {
        return Layouts.onboarding;
      }

      const isRegistration = pathname === ROUTES.REGISTRATION || pathname === ROUTES.VERIFY_EMAIL;
      if (isRegistration) {
        return Layouts.registration;
      }

      if (
        !isUserAuthorized ||
        pathname === '/auth/login_redirect' ||
        pathname === '/auth/logout_redirect' ||
        pathname === ROUTES.ACTIVATE_REDIRECT
      ) {
        return Layouts.authRedirect;
      }

      return Layouts.main;
    };

    const Container = getCurrentLayout();
    const isAuthRedirect = Container === Layouts.authRedirect;

    const BootstrappedLayout = () => {
      // show loader when user in check authorization process, not authorized yet and not on login pages
      if (isUserLoading && !isUserAuthorized) {
        return <Loader fullscreen spinning />;
      }

      if (isAuthRedirect && pathname === ROUTES.ACTIVATE_REDIRECT) {
        return <Container>{children}</Container>;
      }

      // redirect to main dashboard when user on login page and authorized
      if (isAuthRedirect && isUserAuthorized) {
        if (user.adminRole) {
          return <Redirect to={ROUTES.ADMIN_DEFAULT} />;
        }

        if (user.currentCorporation) {
          return <Redirect to={ROUTES.DEFAULT} />;
        }

        return <Redirect to={ROUTES.CORPORATIONS} />;
      }

      // in other case render previously set layout
      return (
        <Container
          showNav={showNav}
          showFooter={showFooter}
          showMenu={showMenu}
          padContent={padContent}
        >
          {children}
        </Container>
      );
    };

    return (
      <Fragment>
        <Helmet titleTemplate="PayByPhone Business | %s" title="PayByPhone Business" />
        {isEnteredCorp && <div id="spa-client" style={{ display: 'none' }} />}
        {BootstrappedLayout()}
      </Fragment>
    );
  }
}

export default connect(({ user }) => ({ user }))(withRouter(IndexLayout));
