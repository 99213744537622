import RegistrationForm from './RegistrationForm';
import RegistrationMarketing from './RegistrationMarketing';

import styles from './style.module.scss';

const RegistrationPage = ({ isMobile, onRegister, isRegistrationLoading, registrationError }) => {
  return (
    <>
      <RegistrationMarketing />
      <section id={styles.main} className={styles.content} data-testid="main">
        <RegistrationForm
          isMobile={isMobile}
          onRegister={onRegister}
          isRegistrationLoading={isRegistrationLoading}
          registrationError={registrationError}
        />
      </section>
    </>
  );
};

export default RegistrationPage;
