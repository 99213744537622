import React from 'react';
import { STAFF_USER_ROLES } from 'constants/staffUsers';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { FeatureContext, FEATURE_STATUS } from './FeatureFlagProvider';

const elevatedRoles = [
  STAFF_USER_ROLES.pbp_super_admin.key,
  STAFF_USER_ROLES.pbp_customer_success.key,
  STAFF_USER_ROLES.pbp_tier_2_customer_support_corpaccts.key,
];

const useFeatureFlag = () => {
  const { adminRole, currentCorporation } = useSelector(state => state.user);
  const flags = useContext(FeatureContext);

  const validateRules = React.useCallback(
    rules => {
      if (!rules) {
        return true;
      }

      if (rules.excludeCountries?.length && currentCorporation?.country) {
        return !rules.excludeCountries.includes(currentCorporation.country);
      }

      return true;
    },
    [currentCorporation],
  );

  const isFeatureEnabled = flagName => {
    const flag = flags[flagName] || {};
    if (flag.override) {
      return true;
    }

    // only visible on override if status is development
    if (flag.status === FEATURE_STATUS.Development) {
      return false;
    }

    if (flag.status === FEATURE_STATUS.Internal) {
      return elevatedRoles.includes(adminRole);
    }

    const rules = flag.rules;
    if (flag.status === FEATURE_STATUS.All) {
      return elevatedRoles.includes(adminRole) || validateRules(rules);
    }

    console.warn(
      `[Warning] Feature flag with ${flagName} is not configured, but used in the code-base`,
    );
    return false;
  };

  return {
    flags,
    showFeature: isFeatureEnabled,
  };
};

export default useFeatureFlag;
