import React from 'react';

const CopyToClipboardIcon = ({ color = '#6CC24A' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    data-testid="copyToClipboardIcon"
  >
    <path
      d="M13.7139 0H4.14244C4.06387 0 3.99958 0.0642857 3.99958 0.142857V1.14286C3.99958 1.22143 4.06387 1.28571 4.14244 1.28571H12.9996V13.5714C12.9996 13.65 13.0639 13.7143 13.1424 13.7143H14.1424C14.221 13.7143 14.2853 13.65 14.2853 13.5714V0.571429C14.2853 0.255357 14.0299 0 13.7139 0ZM11.4282 2.28571H2.2853C1.96922 2.28571 1.71387 2.54107 1.71387 2.85714V12.3339C1.71387 12.4857 1.77458 12.6304 1.88172 12.7375L4.97637 15.8321C5.01565 15.8714 5.0603 15.9036 5.10851 15.9304V15.9643H5.18351C5.24601 15.9875 5.31208 16 5.37994 16H11.4282C11.7442 16 11.9996 15.7446 11.9996 15.4286V2.85714C11.9996 2.54107 11.7442 2.28571 11.4282 2.28571ZM5.10672 14.1464L3.56922 12.6071H5.10672V14.1464ZM10.7139 14.7143H6.24958V12.1786C6.24958 11.7839 5.92994 11.4643 5.5353 11.4643H2.99958V3.57143H10.7139V14.7143Z"
      fill={color}
    />
  </svg>
);

export default CopyToClipboardIcon;
