import React from 'react';
import RegistrationForm from './RegistrationForm';
import OnboardingFailure from './OnboardingFailure';
import OnboardingSuccess from './OnboardingSuccess';

import styles from './style.module.scss';

const DriverRegistration = ({
  driverInfo,
  driverLoadingErrors,
  registrationError,
  isRegistrationSuccessful,
  onRegister,
  renderAppleSignInButton,
  enableFacebookSignIn,
  registrationCode,
}) => {
  const error = driverLoadingErrors || registrationError;

  return (
    <div className={styles.activation} data-testid="driverRegistration">
      {isRegistrationSuccessful && <OnboardingSuccess />}

      {error && <OnboardingFailure error={error} />}

      {driverInfo && !error && !isRegistrationSuccessful && (
        <RegistrationForm
          driverInfo={driverInfo}
          onRegister={onRegister}
          renderAppleSignInButton={renderAppleSignInButton}
          enableFacebookSignIn={enableFacebookSignIn}
          registrationCode={registrationCode}
        />
      )}
    </div>
  );
};

export default DriverRegistration;
