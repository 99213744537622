import { useState, useMemo, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import userActions from 'redux/user/actions';
import { useVerifyUser } from 'services/reactQueryHooks/adminUsers.js';
import { getUserVerificationError } from '../helper';
import { USER_VERIFICATION_ERRORS, USER_VERIFICATION_INFO } from 'constants/users';

const useVerificationStatus = () => {
  const dispatch = useDispatch();
  const [verificationCode, setVerificationCode] = useState('');

  const {
    mutate: verifyUser,
    isSuccess: isVerificationSuccessful,
    isError: isVerificationFailed,
    error: verificationServerError,
  } = useVerifyUser();

  const handleInitialLoading = useCallback(
    ({ queryParams }) => {
      const verificationCodeFromUrl = queryParams?.v;

      if (verificationCodeFromUrl) {
        setVerificationCode(verificationCodeFromUrl);
        verifyUser({ [USER_VERIFICATION_INFO.VerificationCode]: verificationCodeFromUrl });
      }
    },
    [verifyUser],
  );

  const verificationError = useMemo(() => {
    if (!isVerificationFailed) return null;

    return getUserVerificationError(verificationServerError?.errors);
  }, [isVerificationFailed, verificationServerError]);

  useEffect(() => {
    if (verificationCode) {
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'showSignInButton',
          value: false,
        },
      });
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'showLanguageSelector',
          value: false,
        },
      });
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isMobileView',
          value: false,
        },
      });
    }
  }, [dispatch, verificationCode]);

  useEffect(() => {
    if (verificationError === USER_VERIFICATION_ERRORS.VerificationCodeExpired) {
      const userId = verificationServerError.errors.errors.UserId[0];
      if (userId) {
        dispatch({
          type: userActions.SET_STATE,
          payload: {
            userId,
          },
        });
      }
    }
  }, [dispatch, verificationError, verificationServerError]);

  return {
    verificationCode,
    verificationError,
    isVerificationSuccessful,
    onVerificationPageLoad: handleInitialLoading,
  };
};

export default useVerificationStatus;
