import React, { useEffect } from 'react';
import { Button } from 'antd';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import useAmplitude from 'utils/hooks/useAmplitude';
import useTranslation from 'utils/hooks/useTranslation';
import { useDownloadMonthlyStatement } from 'services/reactQueryHooks/monthlyStatements';

const MonthlyStatementDownloadButton = ({ statementId, statementDate }) => {
  const { translateText } = useTranslation();
  const { data: signedUrl, isLoading, refetch, remove } = useDownloadMonthlyStatement(statementId);
  const { sendAmplitudeEvent } = useAmplitude();

  useEffect(() => {
    if (signedUrl) {
      window.open(signedUrl);
      remove();
    }
  }, [remove, signedUrl]);

  return (
    <Button
      type="link"
      data-testid={`downloadMonthlyStatement-${statementId}`}
      onClick={e => {
        e.preventDefault();
        refetch();
        sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.monthlyStatementDownload, { statementDate });
      }}
      disabled={isLoading}
    >
      {translateText('corporateAccounts.monthlyStatements.downloadStatement')}
    </Button>
  );
};

export default React.memo(MonthlyStatementDownloadButton);
