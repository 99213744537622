import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import useAmplitude from 'utils/hooks/useAmplitude';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import CorporateClientForm from 'components/Shared/CorporateClient/CorporateClientForm';

import styles from './style.module.scss';

const BillingAddress = ({ isMobile, isLoading, billingInformation, onSubmit }) => {
  const { sendAmplitudeEvent } = useAmplitude();

  useEffect(() => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.billingAddressTab);
  }, [sendAmplitudeEvent]);

  return (
    <div className={styles.form}>
      {billingInformation && (
        <CorporateClientForm
          isMobile={isMobile}
          isLoading={isLoading}
          isRegister={false}
          billingInformation={billingInformation}
          onSubmit={onSubmit}
        />
      )}
    </div>
  );
};

export default injectIntl(BillingAddress);
