import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer } from 'redux-oidc';
import admins from './admins/reducers';
import corporations from './corporations/reducers';
import user from './user/reducers';
import settings from './settings/reducers';
import vehicles from './vehicles/reducers';
import paymentsSpa from './paymentsSpa/reducers';
import sessions from './sessions/reducers';
import reports from './reports/reducers';
import configuration from './configuration/reducers';

export default history =>
  combineReducers({
    oidc: reducer,
    router: connectRouter(history),
    admins,
    corporations,
    user,
    settings,
    vehicles,
    paymentsSpa,
    sessions,
    reports,
    configuration,
  });
