import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';
import { driverQueryKeys } from 'services/reactQueryHooks/queryKeys.js';
import { ROUTE_REPORTS, ROUTE_DRIVERS } from 'constants.js';
import reportsActions from 'redux/reports/actions';
import notify from 'utils/notificationUtils';
import useTranslation from 'utils/hooks/useTranslation';
import useOnboardingProgressBar from 'components/Shared/OnboardingProgressBar/useOnboardingProgressBar';
import { getPusher } from './pusher';
import useDriverImportCompletionNotification from './useDriverImportCompletionNotification';

const { getDriversQuery } = driverQueryKeys;
export const EVENT_NAMES = {
  REPORT_READY: 'Notification.MonthlyReportReady.V1',
  DRIVER_IMPORT_COMPLETE: 'Notification.DriverImportResult.V1',
};

function withPusher(WrappedComponent) {
  return ({ history, ...props }) => {
    const dispatch = useDispatch();
    const { translateText } = useTranslation();
    const queryClient = useQueryClient();
    const { onDriverImportPushNotification } = useDriverImportCompletionNotification();
    const { onGetOnboardingProgress } = useOnboardingProgressBar();
    const userId = useSelector(state => state.user.id);
    const corporateClientId = useSelector(state => state.user.corporateClientId);
    const authorized = useSelector(state => state.user.authorized);

    const sendReportReadyNotification = useCallback(() => {
      notify({
        type: 'success',
        title: translateText('corporateAccounts.notification.reportReady.title'),
        message: translateText('corporateAccounts.notification.reportReady.description'),
      });

      if (history.location.pathname === ROUTE_REPORTS) {
        dispatch({ type: reportsActions.GET_MONTHLY_REPORTS });
      }
    }, [dispatch, history, translateText]);

    const sendDriverImportNotification = useCallback(
      data => {
        onGetOnboardingProgress();
        if (history.location.pathname === ROUTE_DRIVERS) {
          queryClient.invalidateQueries({ queryKey: getDriversQuery }, { exact: false });
        }

        const responseMessage = JSON.parse(data?.message);
        responseMessage && onDriverImportPushNotification(responseMessage);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [queryClient],
    );

    useEffect(() => {
      let channelName = null;
      let channel = null;

      if (authorized && corporateClientId && userId) {
        channelName = `pbpb.${corporateClientId}.${userId}`;
        channel = getPusher().subscribe(channelName);

        channel.bind(EVENT_NAMES.REPORT_READY, sendReportReadyNotification);
        channel.bind(EVENT_NAMES.DRIVER_IMPORT_COMPLETE, sendDriverImportNotification);
      }

      return () => {
        if (channel) {
          channel.unbind(EVENT_NAMES.REPORT_READY, sendReportReadyNotification);
          channel.unbind(EVENT_NAMES.DRIVER_IMPORT_COMPLETE, sendDriverImportNotification);
          getPusher().unsubscribe(channelName);
        }
      };
    }, [
      authorized,
      corporateClientId,
      userId,
      sendReportReadyNotification,
      sendDriverImportNotification,
    ]);

    return <WrappedComponent {...props} history={history} />;
  };
}

export default withPusher;
