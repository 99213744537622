import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ability from 'config/ability';
import { Helmet } from 'react-helmet';
import Authorize from 'components/Providers/Authorize';
import PrimaryCard from 'components/Layout/PrimaryCard';
import withPusher from 'components/Providers/Pusher/withPusher';
import { ROUTE_PAYMENTS } from 'constants.js';
import actions from 'redux/paymentsSpa/actions';
import PaymentSpaForm from '../PaymentSpaForm';
import { injectIntl } from 'react-intl';
import { ScaVerificationModal, ScaOrderChallengeModal } from '../modals';
import ScaOrderInitiatedIframe from './ScaOrderIntiatedIframe';
import { useCreateSharedPayment } from 'services/reactQueryHooks/payments';
import useTranslation from 'utils/hooks/useTranslation';
import PageWrapper from 'components/Shared/Page/Wrapper';

const PASFormView = ({ history }) => {
  const [showVerification, setShowVerification] = useState(false);
  const [showChallenge, setShowChallenge] = useState(false);
  const dispatch = useDispatch();
  const {
    mutate: createPayment,
    isLoading,
    isError,
    isSuccess,
    scaChallenge,
    orderInitiated,
    reset,
  } = useCreateSharedPayment({
    onReset: () => {
      setShowChallenge(false);
      setShowVerification(false);
    },
  });

  const { translateText } = useTranslation();
  const { formData, formErrors } = useSelector(state => state.paymentsSpa);
  const { corporateClientId, currentCorporation } = useSelector(state => state.user);

  const handleSubmit = payload => {
    if (payload.id) {
      return dispatch({ type: actions.PUT, payload });
    }
    createPayment({ card: payload, restrictionType: currentCorporation.restrictionType });
  };

  const handleCancel = useCallback(() => {
    // This check is implemented to prevent redirection during unit tests and to handle certain side effects that cannot be mocked during unit testing.
    if (process.env.NODE_ENV !== 'test') {
      dispatch({ type: actions.SET_FORM_DATA, payload: null });
      reset();
      history.push(ROUTE_PAYMENTS);
    }
  }, [dispatch, history, reset]);

  const renderTitle = () => {
    if (formData) {
      return translateText('corporateAccounts.payments.spa.title.edit');
    }

    return translateText('corporateAccounts.payments.spa.title');
  };

  useEffect(() => {
    if (ability.cannot('view_form', 'PaymentSpa')) {
      history.push(ROUTE_PAYMENTS);
    }
  }, [history]);

  useEffect(() => {
    if (scaChallenge) {
      setShowVerification(true);
    }
  }, [orderInitiated, scaChallenge]);

  useEffect(() => {
    if (isSuccess) {
      handleCancel();
    }
  }, [isSuccess, handleCancel]);

  useEffect(() => {
    if (isError) {
      reset();
    }
  }, [isError, reset]);

  return (
    <PageWrapper isLoading={false}>
      <Authorize>
        <Helmet title="Payment Method" />
        <ScaVerificationModal
          onOk={() => setShowChallenge(true)}
          onCancel={reset}
          visible={showVerification}
        />
        {showChallenge && (
          <ScaOrderChallengeModal
            visible={showChallenge}
            challengeHtml={scaChallenge}
            onCancel={reset}
          />
        )}
        {orderInitiated && <ScaOrderInitiatedIframe gatewayHtml={orderInitiated} />}
        <PrimaryCard title={renderTitle()}>
          <PaymentSpaForm
            corporateClientId={corporateClientId}
            formData={formData}
            formErrors={formErrors}
            onCancel={handleCancel}
            onSubmit={handleSubmit}
            submitting={isLoading}
            language={currentCorporation.preferredLanguage}
          />
        </PrimaryCard>
      </Authorize>
    </PageWrapper>
  );
};

export default withPusher(injectIntl(PASFormView));
