import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import useTranslation from 'utils/hooks/useTranslation';
import style from './style.module.scss';

const SearchInput = forwardRef(
  (
    {
      placeholder = 'default',
      onSearchFunc,
      searchValue,
      showLengthRequired,
      className = '',
      searchClassName = '',
      onChange,
    },
    ref,
  ) => {
    const { translateText } = useTranslation();

    const [inputFocus, setInputFocus] = useState(false);
    const [form] = Form.useForm();
    const { getFieldValue, setFieldsValue, validateFields, resetFields } = form;

    const MIN_LENGTH = 3;

    const handleSubmit = () => {
      const currentVal = getFieldValue('search')?.trim();

      validateFields()
        .then(() => {
          onSearchFunc(currentVal);
        })
        .catch(() => {
          if (!currentVal) {
            resetFields();
            onSearchFunc(currentVal);
          }
        });
    };

    useEffect(() => {
      if (searchValue) {
        setFieldsValue({
          search: searchValue,
        });
      }
    }, [searchValue, setFieldsValue]);

    useImperativeHandle(ref, () => ({
      clear: resetFields,
    }));

    return (
      <Form form={form} className={`${style.searchFormContainer} ${className}`}>
        <Form.Item
          name="search"
          validateFirst
          validateTrigger="onSubmit"
          rules={[
            {
              message: translateText('corporateAccounts.search.validation.minimum'),
              validator: (_, value) => {
                if (!showLengthRequired) return Promise.resolve();

                const trimmedValue = value.trim();
                if (!value || trimmedValue.length >= MIN_LENGTH) {
                  return Promise.resolve();
                }
                return Promise.reject();
              },
            },
          ]}
        >
          <Input
            ref={ref}
            placeholder={placeholder}
            allowClear
            className={
              (inputFocus ? `${style.searchInput} ${style.searchInputActive}` : style.searchInput) +
              ` ${searchClassName}`
            }
            onFocus={() => {
              setInputFocus(true);
            }}
            onBlur={() => {
              setInputFocus(false);
            }}
            onChange={e => {
              if (onChange) onChange(e.target.value);
            }}
            prefix={<SearchOutlined onClick={handleSubmit} data-testid="searchIcon" />}
            onPressEnter={handleSubmit}
          />
        </Form.Item>
      </Form>
    );
  },
);

export default SearchInput;
