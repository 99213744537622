import { hotjar } from 'react-hotjar';
import { HOTJAR_ID, HOTJAR_SNIPPET_VERSION } from '../constants';

let hotjarInitialized = false;

export const initializeHotjar = () => {
  if (!hotjarInitialized && HOTJAR_ID) {
    hotjar.initialize(HOTJAR_ID, HOTJAR_SNIPPET_VERSION);
    hotjarInitialized = true;
  } else {
    console.log('[ERROR] Incorrect or missing Hotjar ID');
  }
};

export const deinitializeHotjar = () => {
  hotjarInitialized = false;
};

export const setHotjarUserAttributes = ({ userId, language, country, role, isTestCorporation }) => {
  if (hotjarInitialized && typeof window !== 'undefined' && typeof window.hj === 'function') {
    hotjar.identify(userId, {
      language,
      country,
      role,
      isTestCorporation,
    });
  }
};

export const emitEvent = eventName => {
  if (hotjarInitialized) {
    try {
      hotjar.event(eventName);
    } catch (e) {
      console.error('Error sending Hotjar event', e);
    }
  }
};
