import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useGetOnboardingProgress } from 'services/reactQueryHooks/corporations';
import { CORPORATE_CLIENT_PROSPECT_STATUS } from 'constants.js';

const useOnboardingProgressBar = () => {
  const corporationStatus = useSelector(state => state.user.currentCorporation?.statusName);
  const restrictedCorporateClientProspect = useSelector(
    state => state.user.restrictedCorporateClientProspect,
  );
  const allowQuery =
    !restrictedCorporateClientProspect && corporationStatus === CORPORATE_CLIENT_PROSPECT_STATUS;
  const { refetch } = useGetOnboardingProgress();

  const handleOnboardingProgress = useCallback(
    override => {
      if (allowQuery || override) {
        refetch();
      }
    },
    [refetch, allowQuery],
  );

  return {
    onGetOnboardingProgress: handleOnboardingProgress,
  };
};

export default useOnboardingProgressBar;
