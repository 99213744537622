import { httpWrapper } from 'services/http';
import userService from 'services/user';
import { ENDPOINT_AUDIT_LOGS, ENDPOINT_COMPARE } from 'constants.js';
import { endpointCorporateClient } from '.';

export const read = async query => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'GET',
    url: endpointCorporateClient(`${ENDPOINT_AUDIT_LOGS}`, true),
    headers: {
      ...authHeaders,
    },
    params: query,
  };

  const response = httpWrapper(requestOptions);
  return response;
};

export const getAuditLogChanges = async auditLogId => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'GET',
    url: `${endpointCorporateClient(
      `${ENDPOINT_AUDIT_LOGS}`,
      true,
    )}/${auditLogId}${ENDPOINT_COMPARE}`,
    headers: {
      ...authHeaders,
    },
  };

  const response = httpWrapper(requestOptions);
  return response;
};

export default {
  read,
  getAuditLogChanges,
};
