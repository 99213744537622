export const REGION_CODES = {
  CA: [
    {
      label: 'Alberta',
      value: 'AB',
    },
    {
      label: 'British Columbia',
      value: 'BC',
    },
    {
      label: 'Manitoba',
      value: 'MB',
    },
    {
      label: 'New Brunswick',
      value: 'NB',
    },
    {
      label: 'Newfoundland and Labrador',
      value: 'NL',
    },
    {
      label: 'Northwest Territories',
      value: 'NT',
    },
    {
      label: 'Nova Scotia',
      value: 'NS',
    },
    {
      label: 'Nunavut',
      value: 'NU',
    },
    {
      label: 'Ontario',
      value: 'ON',
    },
    {
      label: 'Prince Edward Island',
      value: 'PE',
    },
    {
      label: 'Quebec',
      value: 'QC',
    },
    {
      label: 'Saskatchewan',
      value: 'SK',
    },
    {
      label: 'Yukon',
      value: 'YT',
    },
  ],
  GB: [
    {
      label: 'Avon',
      value: 'AVN',
    },
    {
      label: 'Bedfordshire',
      value: 'BDF',
    },
    {
      label: 'Berkshire',
      value: 'BRK',
    },
    {
      label: 'Bristol, City of',
      value: 'COB',
    },
    {
      label: 'Buckinghamshire',
      value: 'BKM',
    },
    {
      label: 'Cambridgeshire',
      value: 'CAM',
    },
    {
      label: 'Cheshire',
      value: 'CHS',
    },
    {
      label: 'Cleveland',
      value: 'CLV',
    },
    {
      label: 'Cornwall',
      value: 'CON',
    },
    {
      label: 'Cumbria',
      value: 'CMA',
    },
    {
      label: 'Derbyshire',
      value: 'DBY',
    },
    {
      label: 'Devon',
      value: 'DEV',
    },
    {
      label: 'Dorset',
      value: 'DOR',
    },
    {
      label: 'Durham',
      value: 'DUR',
    },
    {
      label: 'East Sussex',
      value: 'SXE',
    },
    {
      label: 'Essex',
      value: 'ESS',
    },
    {
      label: 'Gloucestershire',
      value: 'GLS',
    },
    {
      label: 'Greater London',
      value: 'LND',
    },
    {
      label: 'Greater Manchester',
      value: 'GTM',
    },
    {
      label: 'Hampshire',
      value: 'HAM',
    },
    {
      label: 'Hereford and Worcester',
      value: 'HWR',
    },
    {
      label: 'Herefordshire',
      value: 'HEF',
    },
    {
      label: 'Hertfordshire',
      value: 'HRT',
    },
    {
      label: 'Isle of Wight',
      value: 'IOW',
    },
    {
      label: 'Kent',
      value: 'KEN',
    },
    {
      label: 'Lancashire',
      value: 'LAN',
    },
    {
      label: 'Leicestershire',
      value: 'LEI',
    },
    {
      label: 'Lincolnshire',
      value: 'LIN',
    },
    {
      label: 'London',
      value: 'LDN',
    },
    {
      label: 'Merseyside',
      value: 'MSY',
    },
    {
      label: 'Middlesex',
      value: 'MDX',
    },
    {
      label: 'Norfolk',
      value: 'NFK',
    },
    {
      label: 'Northamptonshire',
      value: 'NTH',
    },
    {
      label: 'Northumberland',
      value: 'NBL',
    },
    {
      label: 'North Humberside',
      value: 'NHM',
    },
    {
      label: 'North Yorkshire',
      value: 'NYK',
    },
    {
      label: 'Nottinghamshire',
      value: 'NTT',
    },
    {
      label: 'Oxfordshire',
      value: 'OXF',
    },
    {
      label: 'Rutland',
      value: 'RUT',
    },
    {
      label: 'Shropshire',
      value: 'SAL',
    },
    {
      label: 'Somerset',
      value: 'SOM',
    },
    {
      label: 'South Humberside',
      value: 'SHM',
    },
    {
      label: 'South Yorkshire',
      value: 'SYK',
    },
    {
      label: 'Staffordshire',
      value: 'STS',
    },
    {
      label: 'Suffolk',
      value: 'SFK',
    },
    {
      label: 'Surrey',
      value: 'SRY',
    },
    {
      label: 'Tyne and Wear',
      value: 'TWR',
    },
    {
      label: 'Warwickshire',
      value: 'WAR',
    },
    {
      label: 'West Midlands',
      value: 'WMD',
    },
    {
      label: 'West Sussex',
      value: 'SXW',
    },
    {
      label: 'West Yorkshire',
      value: 'WYK',
    },
    {
      label: 'Wiltshire',
      value: 'WIL',
    },
    {
      label: 'Worcestershire',
      value: 'WOR',
    },
    {
      label: 'Antrim',
      value: 'ANT',
    },
    {
      label: 'Armagh',
      value: 'ARM',
    },
    {
      label: 'Belfast, City of',
      value: 'BLF',
    },
    {
      label: 'Down',
      value: 'DOW',
    },
    {
      label: 'Fermanagh',
      value: 'FER',
    },
    {
      label: 'Londonderry',
      value: 'LDY',
    },
    {
      label: 'Derry, City of',
      value: 'DRY',
    },
    {
      label: 'Tyrone',
      value: 'TYR',
    },
    {
      label: 'Aberdeen, City of',
      value: 'AN',
    },
    {
      label: 'Aberdeenshire',
      value: 'ABD',
    },
    {
      label: 'Angus (Forfarshire)',
      value: 'ANS',
    },
    {
      label: 'Argyll',
      value: 'AGB',
    },
    {
      label: 'Ayrshire',
      value: 'ARG',
    },
    {
      label: 'Banffshire',
      value: 'BAN',
    },
    {
      label: 'Berwickshire',
      value: 'BEW',
    },
    {
      label: 'Bute',
      value: 'BUT',
    },
    {
      label: 'Caithness',
      value: 'CAI',
    },
    {
      label: 'Clackmannanshire',
      value: 'CLK',
    },
    {
      label: 'Cromartyshire',
      value: 'COC',
    },
    {
      label: 'Dumfriesshire',
      value: 'DFS',
    },
    {
      label: 'Dunbartonshire (Dumbarton)',
      value: 'DNB',
    },
    {
      label: 'Dundee, City of',
      value: 'DD',
    },
    {
      label: 'East Lothian (Haddingtonshire)',
      value: 'ELN',
    },
    {
      label: 'Edinburgh, City of',
      value: 'EB',
    },
    {
      label: 'Fife',
      value: 'FIF',
    },
    {
      label: 'Glasgow, City of',
      value: 'GLA',
    },
    {
      label: 'Inverness-shire',
      value: 'INV',
    },
    {
      label: 'Kincardineshire',
      value: 'KCD',
    },
    {
      label: 'Kinross-shire',
      value: 'KRS',
    },
    {
      label: 'Kirkcudbrightshire',
      value: 'KKD',
    },
    {
      label: 'Lanarkshire',
      value: 'LKS',
    },
    {
      label: 'Midlothian (County of Edinburgh)',
      value: 'MLN',
    },
    {
      label: 'Moray (Elginshire)',
      value: 'MOR',
    },
    {
      label: 'Nairnshire',
      value: 'NAI',
    },
    {
      label: 'Orkney',
      value: 'OKI',
    },
    {
      label: 'Peeblesshire',
      value: 'PEE',
    },
    {
      label: 'Perthshire',
      value: 'PER',
    },
    {
      label: 'Renfrewshire',
      value: 'RFW',
    },
    {
      label: 'Ross and Cromarty',
      value: 'ROC',
    },
    {
      label: 'Ross-shire',
      value: 'ROS',
    },
    {
      label: 'Roxburghshire',
      value: 'ROX',
    },
    {
      label: 'Selkirkshire',
      value: 'SEL',
    },
    {
      label: 'Shetland (Zetland)',
      value: 'SHI',
    },
    {
      label: 'Stirlingshire',
      value: 'STI',
    },
    {
      label: 'Sutherland',
      value: 'SUT',
    },
    {
      label: 'West Lothian (Linlithgowshire)',
      value: 'WLN',
    },
    {
      label: 'Wigtownshire',
      value: 'WIG',
    },
    {
      label: 'Clwyd',
      value: 'CWD',
    },
    {
      label: 'Dyfed',
      value: 'DFD',
    },
    {
      label: 'Gwent',
      value: 'GNT',
    },
    {
      label: 'Gwynedd',
      value: 'GWN',
    },
    {
      label: 'Mid Glamorgan',
      value: 'MGM',
    },
    {
      label: 'Powys',
      value: 'POW',
    },
    {
      label: 'South Glamorgan',
      value: 'SGM',
    },
    {
      label: 'West Glamorgan',
      value: 'WGM',
    },
  ],
  FR: [
    {
      label: 'Auvergne-Rhône-Alpes',
      value: 'ARA',
    },
    {
      label: 'Bourgogne-Franche-Comté',
      value: 'BFC',
    },
    {
      label: 'Bretagne',
      value: 'BRE',
    },
    {
      label: 'Centre-Val de Loire',
      value: 'CVL',
    },
    {
      label: 'Corse',
      value: '20R',
    },
    {
      label: 'Grand-Est',
      value: 'GES',
    },
    {
      label: 'Guadeloupe',
      value: 'GP',
    },
    {
      label: 'Guyane',
      value: 'GF',
    },
    {
      label: 'Hauts-de-France',
      value: 'HDF',
    },
    {
      label: 'Île-de-France',
      value: 'IDF',
    },
    {
      label: 'La Réunion',
      value: 'RE',
    },
    {
      label: 'Mayotte',
      value: 'MAY',
    },
    {
      label: 'Martinique',
      value: 'MQ',
    },
    {
      label: 'Normandie',
      value: 'NOR',
    },
    {
      label: 'Nouvelle-Aquitaine',
      value: 'NAQ',
    },
    {
      label: 'Occitanie',
      value: 'OCC',
    },
    {
      label: 'Pays-de-la-Loire',
      value: 'PDL',
    },
    {
      label: 'Provence-Alpes-Côte-d’Azur',
      value: 'PAC',
    },
  ],
  US: [
    {
      label: 'Alabama',
      value: 'AL',
    },
    {
      label: 'Alaska',
      value: 'AK',
    },
    {
      label: 'Arizona',
      value: 'AZ',
    },
    {
      label: 'Arkansas',
      value: 'AR',
    },
    {
      label: 'California',
      value: 'CA',
    },
    {
      label: 'Colorado',
      value: 'CO',
    },
    {
      label: 'Connecticut',
      value: 'CT',
    },
    {
      label: 'Delaware',
      value: 'DE',
    },
    {
      label: 'District Of Columbia',
      value: 'DC',
    },
    {
      label: 'Florida',
      value: 'FL',
    },
    {
      label: 'Georgia',
      value: 'GA',
    },
    {
      label: 'Hawaii',
      value: 'HI',
    },
    {
      label: 'Idaho',
      value: 'ID',
    },
    {
      label: 'Illinois',
      value: 'IL',
    },
    {
      label: 'Indiana',
      value: 'IN',
    },
    {
      label: 'Iowa',
      value: 'IA',
    },
    {
      label: 'Kansas',
      value: 'KS',
    },
    {
      label: 'Kentucky',
      value: 'KY',
    },
    {
      label: 'Louisiana',
      value: 'LA',
    },
    {
      label: 'Maine',
      value: 'ME',
    },
    {
      label: 'Maryland',
      value: 'MD',
    },
    {
      label: 'Massachusetts',
      value: 'MA',
    },
    {
      label: 'Michigan',
      value: 'MI',
    },
    {
      label: 'Minnesota',
      value: 'MN',
    },
    {
      label: 'Mississippi',
      value: 'MS',
    },
    {
      label: 'Missouri',
      value: 'MO',
    },
    {
      label: 'Montana',
      value: 'MT',
    },
    {
      label: 'Nebraska',
      value: 'NE',
    },
    {
      label: 'Nevada',
      value: 'NV',
    },
    {
      label: 'New Hampshire',
      value: 'NH',
    },
    {
      label: 'New Jersey',
      value: 'NJ',
    },
    {
      label: 'New Mexico',
      value: 'NM',
    },
    {
      label: 'New York',
      value: 'NY',
    },
    {
      label: 'North Carolina',
      value: 'NC',
    },
    {
      label: 'North Dakota',
      value: 'ND',
    },
    {
      label: 'Ohio',
      value: 'OH',
    },
    {
      label: 'Oklahoma',
      value: 'OK',
    },
    {
      label: 'Oregon',
      value: 'OR',
    },
    {
      label: 'Pennsylvania',
      value: 'PA',
    },
    {
      label: 'Rhode Island',
      value: 'RI',
    },
    {
      label: 'South Carolina',
      value: 'SC',
    },
    {
      label: 'South Dakota',
      value: 'SD',
    },
    {
      label: 'Tennessee',
      value: 'TN',
    },
    {
      label: 'Texas',
      value: 'TX',
    },
    {
      label: 'Utah',
      value: 'UT',
    },
    {
      label: 'Vermont',
      value: 'VT',
    },
    {
      label: 'Virginia',
      value: 'VA',
    },
    {
      label: 'Washington',
      value: 'WA',
    },
    {
      label: 'West Virginia',
      value: 'WV',
    },
    {
      label: 'Wisconsin',
      value: 'WI',
    },
    {
      label: 'Wyoming',
      value: 'WY',
    },
  ],
};

export const REGION_LABEL = {
  CA: 'stateProvince',
  US: 'stateProvince',
};

export const TAX_NUMBER_LABEL = {
  FR: 'siretNo',
  GB: 'crnNo',
};

export const JURISDICTION_REQUIRED_COUNTRIES = ['CA', 'US'];

export const TAX_NUMBER_REQUIRED_COUNTRIES = ['FR', 'GB'];
