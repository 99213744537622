import { REHYDRATE } from 'redux-persist/lib/constants';
import { all, takeEvery, take, put, call } from 'redux-saga/effects';
import { isSuccess } from 'utils/sagasUtil';
import notify from 'utils/notificationUtils';
import { corporateClients } from 'services/api';
import { localizedMessage } from 'services/localize';
import actions from './actions';

export function* GET_BY_ID({ payload }) {
  const { response } = yield call(corporateClients.readById, payload.id);

  if (isSuccess(response)) {
    yield put({
      type: actions.GET_BY_ID_SUCCESS,
      payload: response.data,
    });
  } else {
    yield put({ type: actions.GET_BY_ID_ERROR });
  }
}

export function* POST({ payload }) {
  const { response } = yield call(corporateClients.create, payload);

  if (isSuccess(response)) {
    yield put({
      type: actions.POST_SUCCESS,
      payload: response.data,
    });
    notify({
      type: 'success',
      message: localizedMessage('corporateAccounts.corporateClient.create.success'),
    });
  } else {
    notify({
      type: 'error',
      message: localizedMessage('corporateAccounts.corporateClient.create.error'),
    });
    yield put({
      type: actions.POST_ERROR,
    });
  }
}

export function* PUT({ payload }) {
  const { response } = yield call(corporateClients.update, payload);

  if (isSuccess(response)) {
    yield put({
      type: actions.PUT_SUCCESS,
      payload: response.data,
    });
    notify({
      type: 'success',
      message: localizedMessage('corporateAccounts.corporateClient.edit.success'),
    });
  } else {
    notify({
      type: 'error',
      message: localizedMessage('corporateAccounts.corporateClient.edit.error'),
    });
    yield put({ type: actions.PUT_ERROR });
  }
}

export default function* rootSaga() {
  // Wait for redux-persist to rehydrate to prevent sagas running against empty store
  yield take(REHYDRATE);
  yield all([
    takeEvery(actions.GET_BY_ID, GET_BY_ID),
    takeEvery(actions.POST, POST),
    takeEvery(actions.PUT, PUT),
  ]);
}
