import React from 'react';

const MenuLeftSessionsIcon = () => (
  <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon /Parking" clipPath="url(#clip0_3626_1081)">
      <path
        id="Vertical container"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8C0 3.58214 3.58214 0 8 0C12.4179 0 16 3.58214 16 8C16 12.4179 12.4179 16 8 16C3.58214 16 0 12.4179 0 8ZM1.35714 8C1.35714 11.6679 4.33214 14.6429 8 14.6429C11.6679 14.6429 14.6429 11.6679 14.6429 8C14.6429 4.33214 11.6679 1.35714 8 1.35714C4.33214 1.35714 1.35714 4.33214 1.35714 8ZM8.85156 4H5.69531V12H7.38672V9.40625H8.80859C9.42318 9.40625 9.94662 9.29297 10.3789 9.06641C10.8138 8.83984 11.1458 8.52344 11.375 8.11719C11.6042 7.71094 11.7188 7.24219 11.7188 6.71094C11.7188 6.17969 11.6055 5.71094 11.3789 5.30469C11.1549 4.89583 10.8294 4.57682 10.4023 4.34766C9.97526 4.11589 9.45833 4 8.85156 4ZM7.38672 8.05078H8.53516C8.86068 8.05078 9.12891 7.99479 9.33984 7.88281C9.55339 7.76823 9.71224 7.61068 9.81641 7.41016C9.92318 7.20703 9.97656 6.97396 9.97656 6.71094C9.97656 6.44531 9.92318 6.21354 9.81641 6.01562C9.71224 5.8151 9.55339 5.66016 9.33984 5.55078C9.1263 5.4388 8.85547 5.38281 8.52734 5.38281H7.38672V8.05078Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3626_1081">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default MenuLeftSessionsIcon;
