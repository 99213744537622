import { BROWSER_LANGUAGES_MAPPING, LOCALE_TO_COUNTRY } from 'constants.js';

const portalDefaultLanguage = 'en-US';

export const getBrowserDefaultLanguage = () => {
  const browserDefaultLanguages = navigator.languages;

  for (let i = 0; i < browserDefaultLanguages.length; i++) {
    const language = browserDefaultLanguages[i];
    if (BROWSER_LANGUAGES_MAPPING[language]) {
      return BROWSER_LANGUAGES_MAPPING[language];
    }
  }

  return portalDefaultLanguage;
};

export const getBrowserCountryBasedOnLanguage = language => {
  return LOCALE_TO_COUNTRY[language]?.countryCode;
};
