import { alphanumericRegex, whitespaceRegex } from './regex';

export const validateTaxNumber = async (taxNumber, translateText) => {
  const isTooLong = taxNumber.length > 14;
  const hasWhitespace = whitespaceRegex.test(taxNumber);

  if (isTooLong || hasWhitespace) {
    return Promise.reject(
      translateText('corporateAccounts.corporateClient.taxNumber.error.characterCountExceeded'),
    );
  }

  const isAlphanumeric = alphanumericRegex.test(taxNumber);
  if (!isAlphanumeric) {
    return Promise.reject(
      translateText('corporateAccounts.corporateClient.taxNumber.error.notAlphanumeric'),
    );
  }

  return Promise.resolve();
};
