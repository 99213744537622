import React, { useCallback, useEffect, useState } from 'react';
import { Form, Input, Col, Row, Select, Switch, Button } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import corporationActions from 'redux/corporations/actions';
import { MAXIMUM_CHAR_COUNT, LANGUAGES, SUPPORTED_COUNTRIES } from 'constants.js';
import { CORP_EDIT_FIELDS } from 'constants/corporateClients';
import { REGION_CODES } from 'regions';
import useTranslation from 'utils/hooks/useTranslation';
import updateRegionLabel, { getTaxNumberLabel } from 'utils/region';
import { isValidSalesforceId } from 'utils/salesforceIdUtil';
import Can from 'config/Can';
import styles from './style.module.scss';
import SalesforceIdInfoLabel from 'components/Layout/Forms/CorporationInfo/SalesforceIdInfoLabel';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 20 },
};

const CorporationInfoEditForm = ({ corporation, formItemStyle, onSave, onCancel }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(corporation);
  const [country, setCountry] = useState(null);
  const [regionLabel, setRegionLabel] = useState('');
  const [isTestCorp, setIsTestCorp] = useState(formData.isTestCorporation);
  const [form] = Form.useForm();
  const { getFieldValue, setFieldsValue, validateFields } = form;
  const { Option } = Select;
  const { translateText } = useTranslation();

  const taxNumberLabel = getTaxNumberLabel(country, translateText);

  const handleFormSubmit = useCallback(() => {
    validateFields(CORP_EDIT_FIELDS.corporation)
      .then(values => {
        const updatedFields = { ...formData, ...values };
        dispatch({ type: corporationActions.PUT, payload: updatedFields });
        onSave();
      })
      .catch(() => {});
  }, [formData, dispatch, validateFields, onSave]);

  const handleFormCancel = useCallback(() => {
    dispatch({ type: corporationActions.RESET_EDIT_CORPORATION });
    onCancel();
  }, [dispatch, onCancel]);

  const handleTestCorp = value => {
    setIsTestCorp(value);
    setFieldsValue({ isTestCorporation: value });
  };

  useEffect(() => {
    if (formData.id) setFieldsValue(formData);
    setCountry(formData.country);
    updateRegionLabel(formData.country, setRegionLabel, translateText);
  }, [formData, setCountry, setFieldsValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFormData(corporation);
    updateRegionLabel(corporation.country, setRegionLabel, translateText);
  }, [corporation, setFormData]); // eslint-disable-line react-hooks/exhaustive-deps

  const isTestCorporation = () => getFieldValue('isTestCorporation');

  return (
    <Form form={form} layout="horizontal" {...layout}>
      <Row gutter={[16, 16]}>
        <Col>
          <Can I="set_test" on="Corporation" passThrough>
            {can => (
              <Form.Item
                name="isTestCorporation"
                label={translateText(
                  'corporateAccounts.corporateClient.accountType.makeTestAccount',
                )}
                labelAlign="left"
              >
                <Switch
                  data-testid="makeTestCorpSwitch"
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked={isTestCorp}
                  onClick={handleTestCorp}
                  disabled={!can}
                />
              </Form.Item>
            )}
          </Can>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col xs={24} sm={16}>
          <Form.Item
            name="corporateClientName"
            style={formItemStyle}
            label={translateText('corporateAccounts.corporateClient.name')}
            labelAlign="left"
          >
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={8}>
          <Form.Item
            name="corporationNumber"
            style={formItemStyle}
            label={translateText('corporateAccounts.corporateClient.corporationNumber')}
            labelAlign="left"
            rules={[MAXIMUM_CHAR_COUNT]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            name="taxNumber"
            style={formItemStyle}
            label={taxNumberLabel}
            labelAlign="left"
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            name="invoiceId"
            style={formItemStyle}
            label={translateText('corporateAccounts.corporateClient.salesforceId')}
            labelAlign="left"
            extra={<SalesforceIdInfoLabel translateText={translateText} />}
            rules={[
              {
                required: true,
                message: translateText(
                  'corporateAccounts.corporateClient.salesforceId.error.presence',
                ),
              },
              {
                validator: (rule, value) =>
                  isTestCorporation() || !value
                    ? Promise.resolve()
                    : isValidSalesforceId(getFieldValue('invoiceId'), translateText),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12}>
          <Form.Item
            name={['address', 'companyAddress1']}
            style={formItemStyle}
            label={translateText('corporateAccounts.corporateClient.companyAddress1')}
            labelAlign="left"
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name={['address', 'companyAddress2']}
            style={formItemStyle}
            label={translateText('corporateAccounts.corporateClient.companyAddress2')}
            labelAlign="left"
          >
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={8}>
          <Form.Item
            name={['address', 'city']}
            style={formItemStyle}
            label={translateText('corporateAccounts.corporateClient.city')}
            labelAlign="left"
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            name="region"
            style={formItemStyle}
            label={regionLabel}
            labelAlign="left"
            className={!REGION_CODES[country] && styles.regionDisabled}
          >
            <Select
              placeholder={translateText('corporateAccounts.corporateClient.region.placeholder')}
              data-testid="regionDropdown"
              disabled={true}
            >
              {REGION_CODES[country] &&
                Object.keys(REGION_CODES[country]).map((_region, index) => (
                  <Option
                    key={REGION_CODES[country][index].value}
                    value={REGION_CODES[country][index].value}
                  >
                    {REGION_CODES[country][index].label}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            name={['address', 'postalCode']}
            style={formItemStyle}
            label={translateText('corporateAccounts.corporateClient.postalCode')}
            labelAlign="left"
          >
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="country"
            validateTrigger="onBlur"
            style={formItemStyle}
            label={translateText('corporateAccounts.corporateClient.country')}
            labelAlign="left"
          >
            <Select
              disabled={true}
              placeholder={translateText('corporateAccounts.corporateClient.country.placeholder')}
              data-testid="countryDropdown"
            >
              {SUPPORTED_COUNTRIES.map(ctry => (
                <Option key={ctry.countryCode} value={ctry.countryCode}>
                  {translateText(`corporateAccounts.countries.${ctry.countryCode.toLowerCase()}`)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="preferredLanguage"
            validateTrigger="onBlur"
            label={translateText('corporateAccounts.corporateClient.preferredLanguage')}
            labelAlign="left"
            rules={[
              {
                required: true,
                message: translateText(
                  'corporateAccounts.corporateClient.preferredLanguage.error.presence',
                ),
              },
            ]}
          >
            <Select
              placeholder={translateText(
                'corporateAccounts.corporateClient.preferredLanguage.placeholder',
              )}
            >
              {LANGUAGES &&
                Object.keys(LANGUAGES).map(language => (
                  <Option key={LANGUAGES[language].value} value={LANGUAGES[language].value}>
                    {LANGUAGES[language].label}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ float: 'right' }}>
        <Col xs={24}>
          <Button
            data-testid="cancelButton"
            onClick={handleFormCancel}
            type="default"
            loading={false}
            style={{
              width: '130px',
              marginRight: 10,
            }}
          >
            {translateText('corporateAccounts.actions.cancel')}
          </Button>
          <Button
            data-testid="corporatePageSaveButton"
            onClick={handleFormSubmit}
            type="primary"
            loading={false}
            style={{
              width: '130px',
              marginRight: 10,
            }}
          >
            {translateText('corporateAccounts.actions.save')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default CorporationInfoEditForm;
