import React, { useEffect, useCallback } from 'react';
import { Button, Form, Input, Radio, Modal, Typography } from 'antd';
import isEmpty from 'lodash.isempty';
import { USER_ROLES } from 'constants.js';
import PhoneInput from 'components/Shared/PhoneInput';
import isValidPhoneNumber from 'utils/phoneUtils';
import { injectIntl } from 'react-intl';
import useTranslation from 'utils/hooks/useTranslation';
import useAmplitude from 'utils/hooks/useAmplitude';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import adminUtil from 'utils/adminUtil';
import notify from 'utils/notificationUtils';
import ability from 'config/ability';

const PRIMARY_FLEET_MANAGER = 'primary_fleet_manager';

const AdminUserForm = ({
  currentCorporation,
  formData,
  formErrors,
  initialValues = {},
  intl,
  isEditing,
  isSubmitting,
  onCancel,
  onSubmit,
  tableData,
  onOpenSecondaryDrawer,
}) => {
  const { sendAmplitudeEvent } = useAmplitude();
  const { translateText } = useTranslation();
  const [form] = Form.useForm();
  const { getFieldValue, setFieldsValue, validateFields, setFields } = form;
  const resetForm = useCallback(() => form.resetFields(), [form]);

  const onInputChange = (fieldName, value) => {
    setFieldsValue({ [fieldName]: value });
  };

  const handleCancel = () => {
    resetForm();
    onCancel();
  };

  const handleSubmit = async () => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.userSave);
    const primaryDowngrade = primaryDowngradeCheck();
    if (primaryDowngrade) return;
    const primaryUpgrade = primaryUpgradeCheck();

    if (!primaryUpgrade && !primaryDowngrade) validateAndSubmit();
  };

  const validateAndSubmit = (currentAccountOwner = null) => {
    validateFields()
      .then(values => {
        onSubmit(values, currentAccountOwner);
        resetForm();
        onCancel();
      })
      .catch(() => {});
  };

  const primaryUpgradeCheck = () => {
    const selectedPrimary = currentRole() === PRIMARY_FLEET_MANAGER;
    const rolesChanged = currentRole() !== existingRole();
    const currentAccount = adminUtil.downgradeCurrentAccount(
      selectedPrimary,
      formData,
      currentRole(),
      existingRole(),
      tableData,
    );

    if (selectedPrimary && rolesChanged) {
      Modal.confirm({
        title: translateText('corporateAccounts.admins.modal.upgradeBusinessManager.title', {
          injectLocale: true,
        }),
        content: translateText('corporateAccounts.admins.modal.upgradeBusinessManager.content', {
          injectLocale: true,
        }),
        onOk: () => validateAndSubmit(currentAccount),
      });
    }

    return selectedPrimary && rolesChanged;
  };

  const primaryDowngradeCheck = () => {
    const existingPrimary = existingRole() === PRIMARY_FLEET_MANAGER;
    const roleDowngraded = existingRole() !== currentRole();

    if (existingPrimary && roleDowngraded) {
      Modal.error({
        title: translateText('corporateAccounts.admins.modal.downgradeAccountOwner.title', {
          injectLocale: true,
        }),
        content: translateText(
          'corporateAccounts.admins.modal.downgradeAccountOwner.content',

          { injectLocale: true },
        ),
      });
    }

    return existingPrimary && roleDowngraded;
  };

  const currentRole = () => {
    return getFieldValue('role');
  };

  const existingRole = () => {
    return formData ? formData.role : '';
  };

  const disabledRole = role => {
    return (
      formData?.role === PRIMARY_FLEET_MANAGER ||
      (role === PRIMARY_FLEET_MANAGER && ability.cannot('view', PRIMARY_FLEET_MANAGER))
    );
  };

  const renderSecondaryDrawerLink = () => {
    return (
      <Typography.Link
        data-testid="addUserFaq"
        onClick={e => {
          e.preventDefault();
          onOpenSecondaryDrawer(true);
        }}
      >
        {translateText('corporateAccounts.admins.role.faq', {
          injectLocale: true,
        })}
      </Typography.Link>
    );
  };

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  useEffect(() => {
    if (!isEditing || isEmpty(formData)) {
      resetForm();
    } else if (formData.id) {
      setFieldsValue(formData);
    }
  }, [formData, isEditing, resetForm, setFieldsValue]);

  useEffect(() => {
    if (!isEmpty(formErrors) && !formErrors.message) {
      Object.keys(formErrors).forEach(key => {
        const fieldValue = getFieldValue(key);
        if (key) {
          formErrors[key].value = fieldValue;
        } else {
          // Field might not exist, show notification with error
          notify({
            type: 'error',
            title: translateText('corporateAccounts.common.error'),
            message: formErrors[key].errors.join(', '),
          });
          delete formErrors[key];
        }
      });
      setFields(formErrors);
    }
  }, [formErrors, getFieldValue, setFields, translateText]);

  return (
    <div>
      <Form form={form} layout="vertical">
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="corporateClientId" hidden initialValue={initialValues.corporateClientId}>
          <Input />
        </Form.Item>
        <Form.Item
          name="firstName"
          label={translateText('corporateAccounts.admins.firstName')}
          rules={[
            {
              required: true,
              message: translateText('corporateAccounts.admins.form.firstNameRequired'),
            },
            {
              max: 50,
              message: translateText('corporateAccounts.drivers.form.firstNameValidation'),
            },
          ]}
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'corporateAccounts.admins.form.firstNamePlaceholder',
              defaultMessage: 'Add first name',
            })}
          />
        </Form.Item>
        <Form.Item
          name="lastName"
          label={translateText('corporateAccounts.admins.lastName')}
          rules={[
            {
              required: true,
              message: translateText('corporateAccounts.admins.form.lastNameRequired'),
            },
            {
              max: 50,
              message: translateText('corporateAccounts.drivers.form.lastNameValidation'),
            },
          ]}
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'corporateAccounts.admins.form.lastNamePlaceholder',
              defaultMessage: 'Add last name',
            })}
          />
        </Form.Item>
        <Form.Item
          name="role"
          label={translateText('corporateAccounts.admins.role')}
          extra={renderSecondaryDrawerLink()}
          rules={[
            {
              required: true,
              message: translateText('corporateAccounts.admins.form.roleRequired'),
            },
          ]}
        >
          <Radio.Group>
            {USER_ROLES.map(role => (
              <Radio
                key={role.value}
                value={role.value}
                disabled={isEditing && disabledRole(role.value)}
                style={radioStyle}
              >
                {translateText(`corporateAccounts.admins.role.${role.value}`)}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="email"
          label={translateText('corporateAccounts.admins.email')}
          rules={[
            {
              required: !isEditing,
              message: translateText('corporateAccounts.admins.form.emailRequired'),
            },
            {
              type: 'email',
              message: translateText('corporateAccounts.drivers.form.emailValidation'),
            },
          ]}
        >
          <Input
            disabled={isEditing}
            placeholder={intl.formatMessage({
              id: 'corporateAccounts.admins.form.emailPlaceholder',
              defaultMessage: 'Add email',
            })}
          />
        </Form.Item>
        <Form.Item
          name="phone"
          label={translateText('corporateAccounts.admins.phone')}
          validateFirst
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: translateText('corporateAccounts.admins.form.phoneRequired'),
            },
            () => ({
              validator(rule, value) {
                if (!value || isValidPhoneNumber(getFieldValue('phone'))) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    translateText(
                      'corporateAccounts.drivers.form.phoneValidation',

                      { injectLocale: true },
                    ),
                  ),
                );
              },
            }),
          ]}
        >
          <PhoneInput
            code={currentCorporation?.country}
            fieldValue="phone"
            onInputChange={onInputChange}
          />
        </Form.Item>
      </Form>
      <div
        style={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
        }}
      >
        <Button onClick={handleCancel} style={{ marginRight: 8 }} disabled={isSubmitting}>
          {translateText('corporateAccounts.actions.cancel')}
        </Button>
        <Button
          data-testid="addUserSubmitBtn"
          onClick={handleSubmit}
          type="primary"
          loading={isSubmitting}
        >
          {isEditing
            ? translateText('corporateAccounts.admins.editUser')
            : translateText('corporateAccounts.admins.actions.addUser')}
        </Button>
      </div>
    </div>
  );
};

export default injectIntl(AdminUserForm);
