import React from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import usePlausible from 'utils/hooks/usePlausible';

const OnboardingLayout = ({ children }) => {
  usePlausible();

  return (
    <div>
      <Layout.Content>
        <div className={'utils__content'}>{children}</div>
      </Layout.Content>
    </div>
  );
};

const mapStateToProps = ({ settings }) => ({
  isBorderless: settings.isBorderless,
  isSquaredBorders: settings.isSquaredBorders,
  isFixedWidth: settings.isFixedWidth,
  isMenuShadow: settings.isMenuShadow,
  isMenuTop: settings.isMenuTop,
});

OnboardingLayout.defaultProps = {
  showNav: false,
  showFooter: false,
  showMenu: false,
  padContent: false,
};

export default connect(mapStateToProps)(withRouter(OnboardingLayout));
