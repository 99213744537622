import { useCallback } from 'react';
import { JURISDICTION_REQUIRED_COUNTRIES } from 'regions';
import {
  useGetBillingInformation,
  useEditBillingInformation,
} from 'services/reactQueryHooks/corporations';

const useBillingPage = () => {
  const { data, isLoading: isGetBillingiformationLoading } = useGetBillingInformation();

  if (
    data?.address?.jurisdiction &&
    !JURISDICTION_REQUIRED_COUNTRIES.includes(data.address.country)
  ) {
    data.address.jurisdiction = null;
  }

  const {
    mutate: editBillingInformation,
    isLoading: isEditBillingInformationLoading,
  } = useEditBillingInformation();

  const handleEditBillingInformation = useCallback(
    ({ formData }) => {
      editBillingInformation(formData);
    },
    [editBillingInformation],
  );

  return {
    billingInformation: data,
    onSubmit: handleEditBillingInformation,
    isGetBillingiformationLoading,
    isEditBillingInformationLoading,
  };
};

export default useBillingPage;
