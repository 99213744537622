import { Modal } from 'antd';

const successModal = ({ content, title }) => {
  Modal.success({
    content,
    title,
    onOk() {},
  });
};

const warningModal = ({
  content,
  title,
  func,
  formData,
  updatedfieldValues,
  okText = null,
  okButtonProps = null,
  cancelText = null,
  cancelFunc = null,
}) => {
  Modal.confirm({
    content,
    title,
    okText,
    okButtonProps,
    cancelText,
    onOk() {
      if (func) func({ ...formData, ...updatedfieldValues });
    },
    onCancel() {
      if (cancelFunc) cancelFunc({ ...formData, ...updatedfieldValues });
    },
  });
};

const infoModal = ({ content, title, okText = null }) => {
  Modal.info({
    content,
    title,
    okText,
    onOk() {},
    onCancel() {},
  });
};

const errorModal = ({ content, title, okText = null, onOk = () => {} }) => {
  Modal.error({
    content,
    title,
    okText,
    onOk,
  });
};

const utilityModal = {
  errorModal,
  successModal,
  warningModal,
  infoModal,
};

export default utilityModal;
