import actions from './actions';
import mappings from './mappings';

export const INITIAL_STATE = {
  loading: false,
  submitting: false,
  spaEnabledCountries: [],
};

export default function configurationReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actions.GET:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        spaEnabledCountries: mappings.mapSpaEnabledCountries(action.payload),
      };
    default:
      return state;
  }
}
