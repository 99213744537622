import React from 'react';

const CloseIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5063 13.7437C12.848 14.0854 13.402 14.0854 13.7437 13.7437C14.0854 13.402 14.0854 12.848 13.7437 12.5063L8.23744 7L13.7437 1.49372C14.0854 1.15201 14.0854 0.597991 13.7437 0.256283C13.402 -0.0854263 12.848 -0.085426 12.5063 0.256282L7 5.76257L1.49372 0.256281C1.15201 -0.085427 0.59799 -0.0854273 0.256281 0.256281C-0.0854273 0.59799 -0.085427 1.15201 0.256281 1.49372L5.76256 7L0.256283 12.5063C-0.0854259 12.848 -0.0854259 13.402 0.256283 13.7437C0.597991 14.0854 1.15201 14.0854 1.49372 13.7437L7 8.23744L12.5063 13.7437Z"
        fill="#4D4D4D"
      />
    </svg>
  );
};

export default CloseIcon;
