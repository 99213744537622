import { Ability } from '@casl/ability';
import { defineRulesFor } from './users/permissions';

// Defines how to detect object's type
/* eslint-disable no-underscore-dangle */
const subjectName = item => {
  if (!item || typeof item === 'string') {
    return item;
  }
  return item.__type;
};

export const ability = new Ability([], { subjectName });

export const updateAbilities = (role, corporations, corporateClientId, adminRole) => {
  ability.update(defineRulesFor(role, corporations, corporateClientId, adminRole));
};

export default ability;
