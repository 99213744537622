import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import Authorize from 'components/Providers/Authorize';
import ButtonSearchRow from 'components/Shared/Page/ButtonSearchRow';
import PageTitle from 'components/Shared/Page/Title';
import PageTable from 'components/Shared/Page/Table';
import PageWrapper from 'components/Shared/Page/Wrapper';
import useTranslation from 'utils/hooks/useTranslation';
import StaffUserForm from './StaffUserForm';
import useStaffUserPage from './useStaffUserPage';
import useStaffUserForm from './useStaffUserForm';

const StaffManagement = () => {
  const { translateText } = useTranslation();

  const staffUserFormProps = useStaffUserForm();
  const { onShowCreate, onShowEdit } = staffUserFormProps;

  const { staffUsers, columns, isLoading, onSearch, onTableChange } = useStaffUserPage({
    onShowEdit,
  });

  const handleTableChange = (paging, filters) => {
    onTableChange(filters);
  };

  return (
    <Authorize>
      <Helmet title="Staff Management" />
      <PageWrapper>
        <PageTitle
          title={translateText('corporateAccounts.staff.title')}
          numRecords={staffUsers.length}
        />
        <ButtonSearchRow
          visible
          showLengthRequired={false}
          searchOptions={{
            handleSearch: onSearch,
            placeholder: translateText('corporateAccounts.staff.search'),
          }}
          primaryBtn={
            <Button type="primary" size="large" onClick={onShowCreate} data-testid="createStaff">
              <PlusOutlined />
              {translateText('corporateAccounts.staff.actions.add')}
            </Button>
          }
        />
        <PageTable
          visible
          table={
            <Table
              className="utils__scrollTable"
              columns={columns}
              dataSource={staffUsers}
              loading={isLoading}
              onChange={handleTableChange}
              rowKey="id"
              scroll={{ x: true }}
            />
          }
        />
        <StaffUserForm {...staffUserFormProps} />
      </PageWrapper>
    </Authorize>
  );
};

export default withRouter(StaffManagement);
