import React from 'react';
import { Modal, Typography } from 'antd';
import styles from './styles.module.scss';
import { CloseIcon } from '../Icons';

const ExtendedModal = ({
  children,
  className,
  wrapClassName,
  closable = true,
  footer,
  visible,
  onCancel,
  onOk,
  okText,
  cancelText,
  title,
  width = 720,
  ...props
}) => {
  return (
    <Modal
      className={className}
      wrapClassName={wrapClassName}
      title={
        title && (
          <Typography.Title level={3} className={styles.modalTitle}>
            {title}
          </Typography.Title>
        )
      }
      width={width}
      visible={visible}
      onOk={onOk}
      okText={okText}
      onCancel={onCancel}
      cancelText={cancelText}
      centered
      closable={closable}
      closeIcon={<CloseIcon />}
      footer={footer}
      {...props}
    >
      {children}
    </Modal>
  );
};

export default ExtendedModal;
