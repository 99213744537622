import React from 'react';

const ContactIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.50012 9.58345C2.49725 10.6833 2.75423 11.7684 3.25012 12.7501C3.83809 13.9266 4.742 14.9161 5.86058 15.6078C6.97917 16.2996 8.26825 16.6663 9.58346 16.6668C10.6833 16.6696 11.7684 16.4127 12.7501 15.9168L17.5001 17.5001L15.9168 12.7501C16.4127 11.7684 16.6697 10.6833 16.6668 9.58345C16.6663 8.26825 16.2996 6.97916 15.6079 5.86057C14.9161 4.74199 13.9266 3.83809 12.7501 3.25012C11.7684 2.75422 10.6833 2.49725 9.58346 2.50012H9.16679C7.42983 2.59594 5.78925 3.32908 4.55917 4.55916C3.32909 5.78925 2.59595 7.42983 2.50012 9.16678V9.58345Z"
      stroke="#4D4D4D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="6.75"
      y1="8.25"
      x2="12.25"
      y2="8.25"
      stroke="#4D4D4D"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <line
      x1="6.75"
      y1="11.25"
      x2="11.25"
      y2="11.25"
      stroke="#4D4D4D"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default ContactIcon;
