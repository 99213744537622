import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import helpUrls from 'constants/helpCentre';

const useGetSupportLink = () => {
  const [supportLink, setSupportLink] = useState(null);

  const currentCorporation = useSelector(state => state.user.currentCorporation);

  useEffect(() => {
    const helpUrl = helpUrls[currentCorporation?.country];
    setSupportLink(helpUrl?.SUPPORT_URL || helpUrls.EN.SUPPORT_URL);
  }, [currentCorporation?.country]);

  return supportLink;
};

export default useGetSupportLink;
