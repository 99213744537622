import actions from './actions';
import corporationActions from '../corporations/actions';
import userActions from '../user/actions';
import mappings from './mappings';

export const INITIAL_STATE = {
  loading: false,
  monthlyLoading: false,
  data: [],
  monthlyReports: [],
  error: null,
};

export default function reportsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actions.QUERY:
      return {
        ...state,
        loading: true,
      };
    case actions.QUERY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.transactions || [],
      };
    case actions.QUERY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload?.error,
      };
    case actions.GET_MONTHLY_REPORTS:
      return {
        ...state,
        monthlyLoading: true,
      };
    case actions.GET_MONTHLY_REPORTS_SUCCESS:
      return {
        ...state,
        monthlyReports: mappings.mapMonthlyReports(action.payload),
        monthlyLoading: false,
      };
    case actions.GET_MONTHLY_REPORTS_ERROR:
      return {
        ...state,
        monthlyLoading: false,
      };
    case actions.GENERATE_REPORT:
      return {
        ...state,
        monthlyLoading: true,
      };
    case actions.GENERATE_REPORT_SUCCESS:
      return {
        ...state,
        monthlyLoading: false,
        monthlyReports: [mappings.mapGeneratedReport(action.payload), ...state.monthlyReports],
      };
    case actions.GENERATE_REPORT_ERROR:
      return {
        ...state,
        monthlyLoading: false,
      };
    case actions.RESET_ERRORS:
      return {
        ...state,
        error: null,
      };
    case actions.RESET_REPORTS:
      return {
        ...state,
        loading: false,
        data: [],
      };
    case corporationActions.GET:
    case userActions.LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
