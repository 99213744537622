export const isCardActive = (currentUrl, cardUrl) => {
  return currentUrl?.includes(cardUrl);
};

export const calculatePercentage = onboardingStatus => {
  if (!onboardingStatus) return 0;
  const totalSteps = Object.keys(onboardingStatus).length;
  const completedSteps = Object.values(onboardingStatus).filter(status => status === true).length;

  return (completedSteps / totalSteps) * 100;
};

export const getLinkText = ({ translateText, pageUrl }) => {
  return `${translateText('corporateAccounts.actions.goToPage', {
    pageName: `${translateText(
      `corporateAccounts.breadcrumbs.${pageUrl.replace(/^\//, '').toLowerCase()}`,
    ).toLowerCase()}`,
  })} ➔`;
};
