import React from 'react';
import { Row, Col } from 'antd';
import PageTitle from 'components/Shared/Page/Title';

const PrimaryCard = ({ children, description, loading, primaryButton, title }) => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="card">
          <PageTitle title={title} description={description} />
          <div className="card-body text-right">
            {primaryButton && (
              <Row gutter={[6, 6]}>
                <Col xs={24}>{!loading && primaryButton()}</Col>
              </Row>
            )}
          </div>
          <div className="card-body">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default PrimaryCard;
