import parsePhoneNumberFromString from 'libphonenumber-js';

const mapExportedDrivers = exportedDriverResponse => {
  return exportedDriverResponse.map(driver => {
    const parsedPhoneNumber = driver.mobilePhoneNumber
      ? parsePhoneNumberFromString(driver.mobilePhoneNumber)
      : null;

    return {
      firstName: driver.firstName,
      lastName: driver.lastName,
      email: driver.email,
      phoneNumber: parsedPhoneNumber ? parsedPhoneNumber.nationalNumber : '',
      phoneCountryCode: parsedPhoneNumber ? parsedPhoneNumber.countryCallingCode : '',
      costCenter: driver.costCenter,
    };
  });
};

export default {
  mapExportedDrivers,
};
