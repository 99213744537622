import { useState, useEffect, useCallback } from 'react';
import { Modal } from 'antd';
import isEmpty from 'lodash.isempty';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'redux/vehicles/actions';
import useTranslation from 'utils/hooks/useTranslation';
import searchUtil from 'utils/searchUtil';
import { calculateOffset, getOrderByDirection } from 'utils/paginationUtil';
import useAmplitude from 'utils/hooks/useAmplitude';
import { DEFAULT_PAGE_SIZE } from 'constants/tables';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import bulkDeleteModal from './modals/bulkDeleteModal';
import tableColumns from './tableColumns';

const useVehiclePaginationTable = ({ onShowEditForm, onShowAddForm }) => {
  const dispatch = useDispatch();
  const { sendAmplitudeEvent } = useAmplitude();
  const { translateText } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(DEFAULT_PAGE_SIZE);
  const [searchQuery, setSearchQuery] = useState('');
  const [filters, setFilters] = useState({});
  const [orderBy, setOrderBy] = useState('');
  const [selectedTableRows, setSelectedTableRows] = useState([]);
  const [isPageEmpty, setIsPageEmpty] = useState(false);
  const { currentCorporation } = useSelector(state => state.user);
  const { data: dataSource, loading, refetch, totalCount, showNoVehicleUI } = useSelector(
    state => state.vehicles,
  );

  const resetSelectedRows = () => setSelectedTableRows([]);

  const handleSearch = val => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.vehicleSearch);
    const queryString = searchUtil.trimValue(val) || null;
    setSearchQuery(queryString);
    setCurrentPage(1);
  };

  const handleQueryChange = (page, filter, sort) => {
    if (page.current !== currentPage) {
      return;
    }

    let sortString = null;
    if (!isEmpty(sort)) {
      sortString = `${sort.field} ${getOrderByDirection(sort.order)}`;
    }

    setOrderBy(sortString);
    setFilters(filter || '');
    setCurrentPage(1);
  };

  const handlePageSizeChange = (i, pageSize) => {
    setLimit(pageSize);
    setCurrentPage(1);
  };

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
    resetSelectedRows();
  };

  const exportData = useCallback(() => {
    const { country } = filters;
    dispatch({
      type: actions.EXPORT_VEHICLES,
      payload: {
        countryCodes: country?.join(',') || '',
        licensePlate: searchQuery,
        orderBy,
      },
    });
  }, [searchQuery, filters, orderBy, dispatch]);

  const handleExport = useCallback(() => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.vehicleExport);
    Modal.confirm({
      title: translateText('corporateAccounts.vehicles.export.modal.title'),
      content: translateText('corporateAccounts.vehicles.export.modal.description'),
      icon: null,
      okText: translateText('corporateAccounts.actions.exportData'),
      cancelText: translateText('corporateAccounts.actions.cancel'),
      onOk: exportData,
    });
  }, [exportData, sendAmplitudeEvent, translateText]);

  const deleteButtonClick = () => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.vehicleDelete);
  };

  const handleDeleteItem = id => {
    setIsPageEmpty(dataSource.length === 1);
    dispatch({ type: actions.DELETE, payload: { id } });
    resetSelectedRows();
  };

  const handleBulkDeleteConfirm = useCallback(() => {
    const rowsToDelete = selectedTableRows.map(row => row.id);
    setIsPageEmpty(dataSource.length === rowsToDelete.length);
    dispatch({ type: actions.BATCH_DELETE, payload: { vehicleIds: rowsToDelete } });
  }, [selectedTableRows, dataSource, dispatch]);

  const handleBulkDelete = () => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.vehicleBulkDelete);
    bulkDeleteModal({ onDeleteConfirm: handleBulkDeleteConfirm }, translateText);
  };

  const loadPage = useCallback(
    page => {
      const { country } = filters;
      dispatch({
        type: actions.GET,
        payload: {
          countryCodes: country?.join(',') || '',
          offset: calculateOffset(page, limit),
          limit,
          licensePlate: searchQuery,
          orderBy,
        },
      });
    },
    [limit, searchQuery, filters, orderBy, dispatch],
  );

  const refetchPage = useCallback(() => {
    if (!loading && dataSource.length === 0) {
      currentPage > 1 ? setCurrentPage(prev => prev - 1) : loadPage(currentPage);
      setIsPageEmpty(false);
    }
  }, [currentPage, dataSource, loadPage, loading]);

  useEffect(() => {
    loadPage(currentPage);
  }, [currentPage, loadPage]);

  useEffect(() => {
    if (refetch && isPageEmpty) {
      refetchPage();
    }
  }, [isPageEmpty, refetch, refetchPage]);

  useEffect(() => {
    dispatch({ type: actions.RESET_LIST });
    resetSelectedRows();
    return () => dispatch({ type: actions.RESET_LIST });
  }, [dispatch]);

  return {
    dataSource,
    columns: tableColumns(
      onShowEditForm,
      deleteButtonClick,
      handleDeleteItem,
      currentCorporation?.country,
      translateText,
    ),
    currentPage,
    loading,
    searchQuery,
    selectedTableRows,
    totalCount,
    visible: searchQuery || !showNoVehicleUI,
    onBulkDelete: handleBulkDelete,
    onExport: handleExport,
    onPageChange: handlePageChange,
    onPageSizeChange: handlePageSizeChange,
    onRowSelectChange: (_selectedRowKeys, selectedRows) => {
      setSelectedTableRows(selectedRows);
    },
    onSearch: handleSearch,
    onShowAddForm,
    onTableChange: handleQueryChange,
  };
};

export default useVehiclePaginationTable;
