import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import { message, notification } from 'antd';
import { DEFAULT_PAGE_SIZE } from 'constants/tables';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import { RESTRICTED_CORPORATE_CLIENT_PROSPECT } from 'constants.js';
import useAmplitude from 'utils/hooks/useAmplitude';
import searchUtil from 'utils/searchUtil';
import { calculateOffset } from 'utils/paginationUtil';
import { driverQueryKeys } from 'services/reactQueryHooks/queryKeys';
import {
  useGetDrivers,
  useAddDriver,
  useEditDriver,
  useDeleteDriver,
  useInviteDriver,
  useBulkDeleteDrivers,
  useExportDrivers,
} from 'services/reactQueryHooks/drivers';

const createOrderString = (orderBy, sortOrder) => {
  const SORT_OPTIONS = {
    ascend: 'asc',
    descend: 'desc',
  };
  return orderBy && sortOrder ? `${orderBy.toLowerCase()} ${SORT_OPTIONS[sortOrder]}` : null;
};

const useDriversPage = () => {
  const { currentCorporation, corporateClientId } = useSelector(state => state.user);
  const [drivers, setDrivers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(DEFAULT_PAGE_SIZE);
  const [totalSize, setTotalSize] = useState(0);
  const [selectedTableRows, setSelectedTableRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState(null);
  const [statusFilters, setStatusFilters] = useState(null);
  const [orderBy, setOrderBy] = useState(null);
  const [hasDrivers, setHasDrivers] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);

  const queryClient = useQueryClient();
  const { sendAmplitudeEvent } = useAmplitude();

  const { mutate: addDriver, isLoading: isAddLoading } = useAddDriver();
  const { mutate: editDriver, isLoading: isEditLoading } = useEditDriver();
  const { mutate: deleteDriver, isLoading: isDeleteLoading } = useDeleteDriver();
  const { mutate: inviteDriver, isLoading: isInviteLoading } = useInviteDriver();
  const { mutate: bulkDeleteDrivers } = useBulkDeleteDrivers();
  const {
    mutate: exportDriversList,
    isLoading: isExportLoading,
    isSuccess: isExportSuccess,
  } = useExportDrivers(currentCorporation);
  const { data, isLoading: isDriverLoading } = useGetDrivers({
    query: searchQuery,
    offset: calculateOffset(currentPage, limit || DEFAULT_PAGE_SIZE),
    limit,
    orderBy,
    status: statusFilters,
    enabled: corporateClientId !== RESTRICTED_CORPORATE_CLIENT_PROSPECT,
  });

  const handleAddDriver = driver => {
    addDriver(driver);
  };

  const handleEditDriver = driver => {
    editDriver(driver);
  };

  const handleDeleteDriver = id => {
    deleteDriver(id);
  };

  const handleInviteDriver = inviteData => {
    inviteDriver(inviteData);
  };

  const handleBulkDeleteDrivers = ids => {
    bulkDeleteDrivers(ids);
  };

  const handleExportDrivers = () => {
    exportDriversList({ query: searchQuery, status: statusFilters, orderBy });
  };

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
    setSelectedTableRows([]);
  };

  const handlePageSizeChange = (i, pageSize) => {
    setLimit(pageSize);
  };

  const handleSearch = value => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.driverSearch);
    setSearchQuery(searchUtil.trimValue(value));
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setStatusFilters(filters.status);
    setOrderBy(createOrderString(sorter.field, sorter.order));
  };

  const handleSelectionChange = (_selectedRowKeys, selectedRows) => {
    setSelectedTableRows(selectedRows);
  };

  useEffect(() => {
    if (data?.items && !pageLoaded) {
      setPageLoaded(true);
    }
  }, [data, pageLoaded, setPageLoaded]);

  useEffect(() => {
    if (!isDriverLoading) {
      const offset = calculateOffset(currentPage, limit || DEFAULT_PAGE_SIZE);
      if (offset !== 0 && data?.items.length === 0) setCurrentPage(currentPage - 1);
      else {
        setDrivers(data?.items);
        setTotalSize(data?.totalCount);
      }
    }
  }, [data, isDriverLoading, currentPage, limit]);

  useEffect(() => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.driverPage);
    return () => {
      queryClient.removeQueries(driverQueryKeys.getDriversQuery);
      notification.destroy();
      message.destroy();
    };
  }, [sendAmplitudeEvent, queryClient]);

  useEffect(() => {
    if (totalSize) {
      return setHasDrivers(true);
    }

    if (searchQuery || statusFilters) {
      return setHasDrivers(true);
    }

    if (!isDriverLoading && !totalSize) {
      setHasDrivers(false);
    }
  }, [totalSize, searchQuery, statusFilters, pageLoaded, isDriverLoading]);

  return {
    drivers,
    hasDrivers,
    isPageLoading: !pageLoaded,
    isDriverLoading,
    isAddLoading,
    isEditLoading,
    isDeleteLoading,
    isInviteLoading,
    isExportLoading,
    isExportSuccess,
    currentPage,
    statusFilters,
    totalSize,
    selectedTableRows,
    searchQuery,
    onAddDriver: handleAddDriver,
    onEditDriver: handleEditDriver,
    onDeleteDriver: handleDeleteDriver,
    onInviteDriver: handleInviteDriver,
    onBulkDeleteDrivers: handleBulkDeleteDrivers,
    onExportDrivers: handleExportDrivers,
    onPageChange: handlePageChange,
    onPageSizeChange: handlePageSizeChange,
    onSearch: handleSearch,
    onTableChange: handleTableChange,
    onRowSelectChange: handleSelectionChange,
  };
};

export default useDriversPage;
