import React from 'react';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import useTranslation from 'utils/hooks/useTranslation';
import useAmplitude from 'utils/hooks/useAmplitude';
import { OPERATING_SYSTEM } from 'constants/operatingSystem';
import { LOGIN_URL } from 'constants.js';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';

const OpenAppButton = ({ styles, isOnboardingSuccess }) => {
  const { translateText } = useTranslation();
  const { sendAmplitudeEvent } = useAmplitude();

  const { operatingSystem } = useSelector(state => state.settings);

  const openApp = () => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.OpenApp);
    window.location.href =
      operatingSystem === OPERATING_SYSTEM.Android || operatingSystem === OPERATING_SYSTEM.IOS
        ? LOGIN_URL.Deep_Link
        : LOGIN_URL.Web;
  };

  return (
    <Button
      type="primary"
      block
      onClick={openApp}
      data-testid="openAppButton"
      className={isOnboardingSuccess ? styles.actionBtn : styles.activationButton}
    >
      {translateText('corporateAccounts.onboarding.actions.openPayByPhone')}
    </Button>
  );
};

export default OpenAppButton;
