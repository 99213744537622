import { httpWrapper } from 'services/http';
import userService from 'services/user';
import { endpoint } from '.';
import { API_URL_CAS, API_KEY_CAS } from 'constants.js';

const getCreditAccount = async corporateClientId => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'GET',
    url: `${endpoint('/accounts', false, API_URL_CAS)}?corporateClientId=${corporateClientId}`,
    headers: {
      ...authHeaders,
      'X-Pbp-ClientType': 'businessapp',
      'x-api-key': API_KEY_CAS,
    },
  };

  const response = httpWrapper(requestOptions);
  return response;
};

const creditAccounts = {
  getCreditAccount,
};

export default creditAccounts;
