import React from 'react';
import { Form, Input } from 'antd';
import { injectIntl } from 'react-intl';
import PhoneInput from 'components/Shared/PhoneInput';
import isValidPhoneNumber from 'utils/phoneUtils';
import useTranslation from 'utils/hooks/useTranslation';
import { MAXIMUM_CHAR_COUNT } from 'constants.js';

const ProfileStep = ({ form }) => {
  const { translateText } = useTranslation();

  const { getFieldValue, setFieldsValue } = form;

  const onInputChange = (fieldName, value) => {
    if (fieldName === 'primaryFleetManager.phoneNumber')
      setFieldsValue({ primaryFleetManager: { phoneNumber: value } });
    else {
      setFieldsValue({ [fieldName]: value });
    }
  };

  return (
    <>
      <Form.Item
        name={['primaryFleetManager', 'firstName']}
        label={translateText('corporateAccounts.admins.firstName')}
        rules={[
          {
            required: true,
            message: translateText(
              'corporateAccounts.corporateClient.owner.firstName.error.presence',
            ),
          },
          {
            max: 50,
            message: translateText('corporateAccounts.drivers.form.firstNameValidation'),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['primaryFleetManager', 'lastName']}
        label={translateText('corporateAccounts.admins.lastName')}
        rules={[
          {
            required: true,
            message: translateText('corporateAccounts.admins.form.lastNameRequired'),
          },
          {
            max: 50,
            message: translateText('corporateAccounts.drivers.form.lastNameValidation'),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['primaryFleetManager', 'phoneNumber']}
        validateFirst
        validateTrigger="onBlur"
        label={translateText('corporateAccounts.admins.phoneNumber')}
        rules={[
          {
            required: true,
            message: translateText('corporateAccounts.drivers.form.phoneRequired'),
          },
          {
            validator: (rule, value) =>
              isValidPhoneNumber(getFieldValue(['primaryFleetManager', 'phoneNumber'])),
            message: translateText('corporateAccounts.drivers.form.phoneValidation'),
          },
        ]}
      >
        <PhoneInput fieldValue="primaryFleetManager.phoneNumber" onInputChange={onInputChange} />
      </Form.Item>
      <Form.Item
        name={['primaryFleetManager', 'email']}
        label={translateText('corporateAccounts.corporateClient.email')}
        rules={[
          {
            required: true,
            message: translateText('corporateAccounts.drivers.form.emailValidation'),
          },
          {
            type: 'email',
            message: translateText('corporateAccounts.drivers.form.emailValidation'),
          },
          MAXIMUM_CHAR_COUNT,
        ]}
      >
        <Input disabled={getFieldValue('id') !== undefined} />
      </Form.Item>
    </>
  );
};

export default injectIntl(ProfileStep);
