/* eslint-disable */
import React from 'react';
import { Typography } from 'antd';
import { DoubleRightOutlined, DoubleLeftOutlined } from '@ant-design/icons';
import useTranslation from 'utils/hooks/useTranslation';
import styles from './style.module.scss';

const MenuTrigger = ({ isMenuCollapsed, isLightTheme }) => {
  const { translateText } = useTranslation();
  return (
    <div
      className={
        isLightTheme ? styles.menuTrigger : `${styles.menuTrigger} ${styles.menuTriggerDark}`
      }
    >
      {isMenuCollapsed ? (
        <div>
          <DoubleRightOutlined />
        </div>
      ) : (
        <>
          <DoubleLeftOutlined />
          <Typography>
            <p data-testid="menuTriggerTitle">
              {translateText('corporateAccounts.menuLeft.trigger')}
            </p>
          </Typography>
        </>
      )}
    </div>
  );
};

export default MenuTrigger;
