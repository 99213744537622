import { useActivateCorporation } from 'services/reactQueryHooks/corporations';

const useProspectAccountActivation = () => {
  const {
    mutate: activateCorporation,
    isSuccess: isActivateCorporationSuccess,
    isError: isActivateCorporationError,
    isLoading: isActivateCorporationLoading,
  } = useActivateCorporation();

  const handleAccountActivation = () => {
    activateCorporation();
  };

  return {
    isActivateCorporationSuccess,
    isActivateCorporationError,
    isActivateCorporationLoading,
    onAccountActivation: handleAccountActivation,
  };
};

export default useProspectAccountActivation;
