import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import userManager from 'utils/userManager';
import notify from 'utils/notificationUtils';
import userService from 'services/user';
import Loader from 'components/Shared/Loader';
import { ROUTE_ACTIVATE_REDIRECT, ROUTE_404, EMAIL_NOT_VERIFIED } from 'constants.js';
import authorizeRedirect from './helpers';
import useTranslation from 'utils/hooks/useTranslation';

const Authorize = ({
  user: {
    activationId,
    activationSuccess,
    authorized,
    corporations,
    currentCorporation,
    adminRole,
    loading,
    logout,
  },
  location: { pathname },
  children,
  to = ROUTE_404,
  loadCurrentAccount,
  setActivationSuccess,
  setUserActivationId,
}) => {
  const { translateText } = useTranslation();
  const [currentActivationId, setCurrentActivationId] = useState(null);

  const compareUserActivations = useCallback(async () => {
    const currentActivation = await userService.checkActivation();
    setCurrentActivationId(currentActivation);

    if (!currentActivation || currentActivation !== activationId) {
      setUserActivationId(currentActivation);
    }

    if (currentActivation === activationId && activationSuccess) {
      loadCurrentAccount();
      setActivationSuccess(false);
    }
  }, [
    activationId,
    activationSuccess,
    loadCurrentAccount,
    setActivationSuccess,
    setUserActivationId,
  ]);

  useEffect(() => {
    compareUserActivations().then(() => {
      userService.updateCurrentAbilities();
    });
  }, [compareUserActivations]);

  // Default authorization handling
  if (logout) return null;

  if (loading) {
    return <Loader fullscreen />;
  }

  if (!authorized) {
    userManager.signinRedirect();
    return null;
  }

  const isActivationFailure = () => {
    return (
      currentActivationId &&
      currentCorporation &&
      activationId !== currentActivationId &&
      !activationSuccess
    );
  };
  if (isActivationFailure()) {
    return <Redirect to={ROUTE_ACTIVATE_REDIRECT} />;
  }

  const hasNoCorporations = corporations.length === 0;
  const isEmailVerified = currentCorporation?.id !== EMAIL_NOT_VERIFIED;
  if (hasNoCorporations && isEmailVerified) {
    notify({
      type: 'error',
      title: translateText('corporateAccounts.errors.unauthorized.title'),
      message: translateText('corporateAccounts.errors.unauthorized.content'),
    });
    return <Redirect to={to} />;
  }

  // Bulk of custom redirect logic based on authorization and roles should occur in `authorizeRedirect`
  const redirectPath = authorizeRedirect({
    corporation: currentCorporation,
    corporationList: corporations,
    path: pathname,
    adminRole,
  });

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }

  return children;
};

export default connect(
  ({ user, corporations }) => ({ user, corporations }),
  dispatch => ({
    loadCurrentAccount: () => {
      dispatch({ type: 'user/LOAD_CURRENT_ACCOUNT' });
      return Promise.resolve();
    },
    loadCurrentProfile: () => {
      dispatch({ type: 'user/LOAD_CURRENT_PROFILE' });
      return Promise.resolve();
    },
    setCurrentUser: currentUser => {
      dispatch({ type: 'user/LOGIN', payload: { currentUser, force: true } });
      return Promise.resolve();
    },
    setUserActivationId: activationId => {
      return dispatch({
        type: 'user/SET_STATE',
        payload: { activationId },
      });
    },
    setActivationSuccess: activationSuccess => {
      return dispatch({
        type: 'user/SET_STATE',
        payload: { activationSuccess },
      });
    },
  }),
)(withRouter(Authorize));
