const Circle = ({ color, progressPercentage }) => {
  const radius = 26;
  const circumference = 2 * Math.PI * radius;
  const strokePercentage = progressPercentage
    ? ((100 - progressPercentage) * circumference) / 100
    : 0;

  return (
    <circle
      r={radius}
      cx={30}
      cy={30}
      fill="transparent"
      stroke={color}
      strokeWidth={'6px'}
      strokeDasharray={circumference}
      strokeDashoffset={strokePercentage}
    ></circle>
  );
};

const PercentageValue = ({ progressPercentage }) => {
  return (
    <text x="50%" y="50%" dominantBaseline="central" textAnchor="middle" fontSize={'16px'}>
      {progressPercentage.toFixed(0)}%
    </text>
  );
};

const ProgressCircle = ({ progressPercentage }) => (
  <svg width={60} height={60}>
    <g transform={`rotate(-90 ${'30 30'})`}>
      <Circle color="#f5f5f5" />
      <Circle color="#6CC24A" progressPercentage={progressPercentage} />
    </g>
    <PercentageValue progressPercentage={progressPercentage} />
  </svg>
);

export default ProgressCircle;
