import React from 'react';
import { useSelector } from 'react-redux';
import Icon, { PlusOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import ButtonSearchRow from 'components/Shared/Page/ButtonSearchRow';
import { DownloadOutlined } from '@ant-design/icons';
import { UploadIcon } from 'components/Shared/Icons';
import useAmplitude from 'utils/hooks/useAmplitude';
import useTranslation from 'utils/hooks/useTranslation';
import { ROUTE_VEHICLES_UPLOAD } from 'constants.js';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import Can from 'config/Can';
import styles from './style.module.scss';

const VehiclesTableActions = ({
  visible,
  history,
  selectedRows,
  searchQuery,
  onExport,
  onSearch,
  onShowAddForm,
  onBulkDelete,
}) => {
  const { isExporting, totalCount } = useSelector(state => state.vehicles);
  const { sendAmplitudeEvent } = useAmplitude();
  const { translateText } = useTranslation();

  return (
    <ButtonSearchRow
      visible={visible}
      searchOptions={{
        handleSearch: onSearch,
        searchQuery,
        placeholder: translateText('corporateAccounts.vehicles.licensePlate'),
      }}
      primaryBtn={
        <Can I="create" a="Vehicle" passThrough>
          {can => (
            <Button
              data-testid="addVehicleBtn"
              disabled={!can}
              type="primary"
              size="large"
              onClick={onShowAddForm}
            >
              <PlusOutlined />
              {translateText('corporateAccounts.vehicles.actions.addVehicle')}
            </Button>
          )}
        </Can>
      }
      secondaryBtn={
        <Space size={10}>
          <Can I="export" a="Vehicle" passThrough>
            {can => (
              <Button
                className={styles.buttonIcon}
                type="secondary"
                size="large"
                data-testid="exportBtn"
                icon={
                  <span>
                    <DownloadOutlined />
                  </span>
                }
                onClick={onExport}
                disabled={!can || totalCount === 0}
                loading={isExporting}
              >
                {translateText('corporateAccounts.actions.export')}
              </Button>
            )}
          </Can>
          <Can I="upload" a="Vehicle" passThrough>
            {can => (
              <Button
                className={styles.buttonIcon}
                disabled={!can}
                type="secondary"
                size="large"
                icon={
                  <span>
                    <Icon component={UploadIcon} />
                  </span>
                }
                onClick={() => {
                  sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.vehicleInitialImport);
                  history.push(ROUTE_VEHICLES_UPLOAD);
                }}
              >
                {translateText('corporateAccounts.common.upload')}
              </Button>
            )}
          </Can>
        </Space>
      }
      bulkActionBtns={
        <Can I="destroy" a="Vehicle" passThrough>
          {can => (
            <Button
              data-testid="batchDeleteBtn"
              disabled={!can || !selectedRows.length > 0}
              type="secondary"
              size="middle"
              onClick={onBulkDelete}
            >
              {translateText('corporateAccounts.actions.delete')}
            </Button>
          )}
        </Can>
      }
    />
  );
};

export default VehiclesTableActions;
