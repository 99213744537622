// This is a temporary hack to support total count for the admin table.
// https://github.com/ant-design/ant-design/issues/24022

const filterTableData = (records = [], filters = {}) => {
  let result = [...records];
  const filterKeys = Object.keys(filters);

  filterKeys.forEach(key => {
    const filterBy = filters[key];
    if (filterBy) {
      result = result.filter(row => filterBy.includes(row[key]));
    }
  });

  return result;
};

const antdUtils = {
  filterTableData,
};

export default antdUtils;
