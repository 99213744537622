import React from 'react';

const MenuLeftStaffAccountsIcon = () => (
  <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon /Appstore">
      <path
        id="Vector"
        d="M7.143 1.42969H1.71443C1.55728 1.42969 1.42871 1.55826 1.42871 1.7154V7.14397C1.42871 7.30112 1.55728 7.42969 1.71443 7.42969H7.143C7.30014 7.42969 7.42871 7.30112 7.42871 7.14397V1.7154C7.42871 1.55826 7.30014 1.42969 7.143 1.42969ZM6.21443 6.2154H2.643V2.64397H6.21443V6.2154ZM14.2859 1.42969H8.85728C8.70014 1.42969 8.57157 1.55826 8.57157 1.7154V7.14397C8.57157 7.30112 8.70014 7.42969 8.85728 7.42969H14.2859C14.443 7.42969 14.5716 7.30112 14.5716 7.14397V1.7154C14.5716 1.55826 14.443 1.42969 14.2859 1.42969ZM13.3573 6.2154H9.78586V2.64397H13.3573V6.2154ZM7.143 8.57255H1.71443C1.55728 8.57255 1.42871 8.70112 1.42871 8.85826V14.2868C1.42871 14.444 1.55728 14.5725 1.71443 14.5725H7.143C7.30014 14.5725 7.42871 14.444 7.42871 14.2868V8.85826C7.42871 8.70112 7.30014 8.57255 7.143 8.57255ZM6.21443 13.3583H2.643V9.78683H6.21443V13.3583ZM14.2859 8.57255H8.85728C8.70014 8.57255 8.57157 8.70112 8.57157 8.85826V14.2868C8.57157 14.444 8.70014 14.5725 8.85728 14.5725H14.2859C14.443 14.5725 14.5716 14.444 14.5716 14.2868V8.85826C14.5716 8.70112 14.443 8.57255 14.2859 8.57255ZM13.3573 13.3583H9.78586V9.78683H13.3573V13.3583Z"
        fill="white"
      />
    </g>
  </svg>
);

export default MenuLeftStaffAccountsIcon;
