import moment from 'moment';
import momentTz from 'moment-timezone';

export const getTimezone = () => {
  const tzName = momentTz.tz.guess();
  return momentTz.tz(tzName).format('z');
};

export const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm';

// We make an assumption that maintenance window will always be on the same day
export const parseMaintenanceWindowEnv = maintenanceWindow => {
  if (!maintenanceWindow) {
    return null;
  }

  const splitDates = maintenanceWindow.split(',').map(x => x.trim());
  if (splitDates.length !== 2) {
    return null;
  }
  const [start, end] = splitDates;
  if (
    !moment(start, DATE_TIME_FORMAT, true).isValid() ||
    !moment(end, DATE_TIME_FORMAT, true).isValid()
  ) {
    return null;
  }

  const startDate = moment.utc(start).toDate();
  const endDate = moment.utc(end).toDate();
  if (endDate < startDate) {
    return null;
  }

  return [startDate, endDate];
};

export const showMaintenanceNotification = start => {
  return moment(start).isAfter(new Date());
};

export const showMaintenancePage = (start, end) => {
  return moment(new Date()).isBetween(start, end);
};

export const formatRestoreTimeMessage = (end, translateText) => {
  const zone = getTimezone();
  const endTime = moment(end).format('h:mm A');

  return translateText('corporateAccounts.globalNotification.maintenancePage.restoreTime', {
    maintenanceEndHour: `${endTime}${zone ? ` ${zone}` : ''}`,
  });
};

export default {
  showMaintenanceNotification,
  showMaintenancePage,
  formatRestoreTimeMessage,
};
