const reporting = {
  adminUsers: 'read',
  driver: {
    cannot: [
      'activate',
      'create',
      'deactivate',
      'destroy',
      'edit',
      'read_all_columns',
      'upload',
      'export',
    ],
  },
  vehicle: {
    cannot: ['export'],
  },
  vehicleForm: ['read_vehicle_location', 'read_vehicle_name', 'read_vehicle_type'],
  paymentSpa: {
    cannot: ['create', 'edit', 'destroy'],
  },
  billing: {
    cannot: ['edit'],
  },
  RestrictedDriverUpload: {
    cannot: ['bulkDriverUpload'],
  },
};

export default reporting;
