import pbpSuperAdmin from './pbpSuperAdmin';
import pbpCustomerSuccess from './pbpCustomerSuccess';
import pbpCustomerSuccessTierTwo from './pbpCustomerSuccessTierTwo';
import primaryFleetManager from './primaryFleetManager';
import fleetManager from './fleetManager';
import serviceAdmin from './serviceAdmin';
import reporting from './reporting';

const actions = {
  pbpSuperAdmin,
  pbpCustomerSuccess,
  pbpCustomerSuccessTierTwo,
  primaryFleetManager,
  fleetManager,
  serviceAdmin,
  reporting,
};

export default actions;
