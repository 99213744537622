import React from 'react';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en-US';
import '@formatjs/intl-relativetimeformat/locale-data/en-GB';
import '@formatjs/intl-relativetimeformat/locale-data/fr';
import '@formatjs/intl-relativetimeformat/locale-data/de';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import locales from 'locales';
import { useFetchLocale } from 'services/reactQueryHooks/localeStore';
import glbGlb from 'locales/glb-glb';

export const errorHandler = err => {
  if (err.code === 'MISSING_TRANSLATION') {
    // this warning became too noisy, enable it back when needed
    // console.warn('Missing translation', err.message);
    return;
  }
  throw err;
};

const LocalizationProvider = ({ children, settings, excludeAntdConfigProvider }) => {
  const { locale } = settings;
  const { data: localeMessages } = useFetchLocale(locale || 'en-US');

  const currentLocale = locales[locale];
  const isGlobal = locale === 'glb-glb';
  if (excludeAntdConfigProvider) {
    return (
      <IntlProvider
        locale={isGlobal ? 'en' : currentLocale.locale}
        messages={isGlobal ? glbGlb : localeMessages}
        onError={errorHandler}
      >
        {children}
      </IntlProvider>
    );
  }

  return (
    <ConfigProvider locale={currentLocale.antdData}>
      <IntlProvider
        locale={isGlobal ? 'en' : currentLocale.locale}
        messages={isGlobal ? glbGlb : localeMessages}
        onError={errorHandler}
      >
        {children}
      </IntlProvider>
    </ConfigProvider>
  );
};

export default connect(({ settings }) => ({ settings }))(LocalizationProvider);
