import React from 'react';
import { Typography } from 'antd';
import useTranslation from 'utils/hooks/useTranslation';
import AuditLogsEmptyStateIcon from 'components/Shared/Icons/AuditLogsEmptyStateIcon';

import styles from './style.module.scss';

const AuditLogsEmptyState = ({ visible }) => {
  const { translateText } = useTranslation();
  if (!visible) return null;

  return (
    <div className={styles.emptyStatePage}>
      <AuditLogsEmptyStateIcon />
      <Typography className={styles.emptyStateDescription}>
        {translateText('corporateAccounts.auditLogs.emptyState.description')}
      </Typography>
    </div>
  );
};

export default AuditLogsEmptyState;
