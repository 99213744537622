import React from 'react';
import { Space } from 'antd';
import { Typography } from 'antd';
import styles from '../style.module.scss';

const PageTitle = ({ title, numRecords = 0, description = null, status = null }) => {
  return (
    <div className="card-header">
      <Space size={5}>
        <Typography.Title level={3}>
          {title}
          {numRecords > 0 && (
            <span>
              &nbsp;
              <span className={styles.total} data-testid="pageTitleTotalRecords">
                ({numRecords})
              </span>
            </span>
          )}
        </Typography.Title>
        {status && (
          <Typography.Text className={`${styles.statusDafault} ${styles[status]}`}>
            {status}
          </Typography.Text>
        )}
      </Space>

      {description && <Typography.Paragraph>{description}</Typography.Paragraph>}
    </div>
  );
};

export default PageTitle;
