import React from 'react';
import { Tag } from 'antd';
import style from './style.module.scss';

const UtilTag = ({ content, type = 'success' }) => {
  const className = `tag-${type}`;

  return <Tag className={`${style.tag} ${style[className]}`}>{content}</Tag>;
};

export default UtilTag;
