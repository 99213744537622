import React from 'react';
import { Typography } from 'antd';
import Spinner from 'components/Shared/Spinner';
import useTranslation from 'utils/hooks/useTranslation';
import styles from './style.module.scss';

const DriverImportModalLoading = () => {
  const { translateText } = useTranslation();

  return (
    <div data-testid="driverImportLoading" className={styles.driverLoading}>
      <Typography.Paragraph>
        {translateText('corporateAccounts.fileUpload.loading')}
      </Typography.Paragraph>
      <Spinner />
    </div>
  );
};

export default DriverImportModalLoading;
