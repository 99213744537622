import React from 'react';
import { Typography } from 'antd';
import { MenuLeftAccountIcon } from 'components/Shared/Icons';
import { convertCorporateClientNameToShortName } from 'utils/corporateClientUtil';

import styles from './style.module.scss';

const CurrentCorporationMenu = ({ currentCorporation, isMenuCollapsed, isLightTheme }) => {
  return (
    <div
      data-testid="currentCorporation"
      className={
        isLightTheme ? styles.corporation : `${styles.corporation} ${styles.corporationDark}`
      }
    >
      <MenuLeftAccountIcon />
      <Typography className={styles.corporationName}>
        {isMenuCollapsed
          ? null
          : convertCorporateClientNameToShortName(currentCorporation.corporateClientName)}
      </Typography>
    </div>
  );
};

export default CurrentCorporationMenu;
