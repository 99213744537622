export const tableRows = {
  driver: {
    firstName: 'corporateAccounts.admins.firstName',
    lastName: 'corporateAccounts.admins.lastName',
    email: 'corporateAccounts.admins.email',
    mobilePhoneNumber: 'corporateAccounts.drivers.import.header.phoneNumber',
    costCenter: 'corporateAccounts.drivers.costCenter',
    status: 'corporateAccounts.common.status',
    invitationStatus: 'corporateAccounts.common.invitationStatus',
    invitationCode: 'corporateAccounts.common.invitationCode',
  },
  vehicle: {
    licensePlate: 'corporateAccounts.vehicles.licensePlate',
    country: 'corporateAccounts.corporateClient.country',
    jurisdiction: 'corporateAccounts.corporateClient.region',
    vin: 'corporateAccounts.vehicles.vin',
    status: 'corporateAccounts.common.status',
  },
  paymentMethod: {
    note: 'corporateAccounts.payments.cardName',
    status: 'corporateAccounts.common.status',
    paymentAccountId: 'corporateAccounts.payments.paymentAccountId',
  },
  user: {
    firstName: 'corporateAccounts.admins.firstName',
    lastName: 'corporateAccounts.admins.lastName',
    role: 'corporateAccounts.admins.role',
    mobilePhoneNumber: 'corporateAccounts.drivers.import.header.phoneNumber',
    email: 'corporateAccounts.admins.email',
    status: 'corporateAccounts.common.status',
  },
  creditAccount: {
    creditAccountId: 'corporateAccounts.creditAccount.details.creditAccountId',
    paymentAccountId: 'corporateAccounts.payments.paymentAccountId',
    status: 'corporateAccounts.common.status',
    PaymentCardType: 'corporateAccounts.payments.cardType',
  },
};

export const columnHeader = {
  driver: 'corporateAccounts.common.driver',
  vehicle: 'corporateAccounts.common.vehicle',
  paymentMethod: 'corporateAccounts.admin.payment',
  User: 'corporateAccounts.common.user',
  creditAccount: 'corporateAccounts.creditAccount.pageTitle',
};
