import React from 'react';

const UploadIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3638 8.68003C12.3638 9.14027 11.9907 9.51337 11.5305 9.51337L7.3219 9.51337C7.17454 9.51337 7.0361 9.47511 6.91599 9.408L6.90711 9.40296C6.87707 9.38569 6.84822 9.3666 6.82069 9.34585C6.76756 9.30579 6.71938 9.25952 6.67723 9.20812C6.62818 9.1484 6.58869 9.08346 6.55877 9.01532C6.52039 8.92809 6.49653 8.83304 6.49024 8.73321C6.48892 8.71241 6.48837 8.69158 6.4886 8.67075L6.4886 4.47153C6.4886 4.01129 6.86169 3.63819 7.32193 3.63819C7.78217 3.63819 8.15526 4.01129 8.15526 4.47153V6.66805L14.2457 0.577574C14.5712 0.252137 15.0988 0.252137 15.4243 0.577574C15.7497 0.903011 15.7497 1.43065 15.4243 1.75609L9.33364 7.8467L11.5305 7.8467C11.9907 7.8467 12.3638 8.2198 12.3638 8.68003ZM3.16602 2.02179C2.70578 2.02179 2.33268 2.39488 2.33268 2.85512V12.8359C2.33268 13.2962 2.70578 13.6693 3.16602 13.6693H13.1468C13.6071 13.6693 13.9802 13.2962 13.9802 12.8359V6.67074C13.9802 6.21051 14.3532 5.83741 14.8135 5.83741C15.2737 5.83741 15.6468 6.21051 15.6468 6.67074V12.8359C15.6468 14.2166 14.5275 15.3359 13.1468 15.3359H3.16602C1.7853 15.3359 0.666016 14.2166 0.666016 12.8359V2.85512C0.666016 1.47441 1.7853 0.355119 3.16602 0.355119H8.98855C9.44879 0.355119 9.82188 0.728215 9.82188 1.18845C9.82188 1.64869 9.44879 2.02179 8.98855 2.02179H3.16602Z"
      fill="#2D2D2D"
    />
  </svg>
);

export default UploadIcon;
