import React from 'react';

const CheckMarkIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="checkMarkIcon"
  >
    <g id="check">
      <path
        id="Vector"
        d="M13.3333 4L6.00001 11.3333L2.66667 8"
        stroke="#6CC24A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default CheckMarkIcon;
