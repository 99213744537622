import React, { useEffect, useState } from 'react';
import useAmplitude from 'utils/hooks/useAmplitude';
import ExtendedModal from 'components/Shared/Modal/index.js';
import WelcomeModal from 'components/Shared/WelcomeModal';
import CorporateClientForm from 'components/Shared/CorporateClient/CorporateClientForm';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import useRegisterCorporationModal from './useRegisterCorporationModal';
import RegisterCorporationFormHeader from './RegisterCorporationFormHeader';

const RegisterCorporationModal = () => {
  const {
    isMobile,
    isRegisterCorporationLoading,
    isRegisterCorporationSuccess,
    prospectUser,
    onRegister,
  } = useRegisterCorporationModal();

  const [isWelcomeModalVisible, setIsWelcomeModalVisible] = useState(true);

  const { sendAmplitudeEvent } = useAmplitude();

  useEffect(() => {
    prospectUser &&
      sendAmplitudeEvent(
        AMPLITUDE_EVENT_TYPES.selfRegistrationCorporateClientRegistrationPageViewed,
      );
  }, [prospectUser, sendAmplitudeEvent]);

  const RegisterCorporationFormModal = () => (
    <ExtendedModal
      visible={prospectUser}
      closable={false}
      footer={null}
      wrapClassName={'modalWrapper'}
      width={630}
    >
      <RegisterCorporationFormHeader />
      <CorporateClientForm
        isMobile={isMobile}
        isLoading={isRegisterCorporationLoading}
        isRegister={true}
        onSubmit={onRegister}
      />
    </ExtendedModal>
  );

  return (
    <>
      {RegisterCorporationFormModal()}
      {isRegisterCorporationSuccess && !isMobile && (
        <WelcomeModal isVisible={isWelcomeModalVisible} setIsVisible={setIsWelcomeModalVisible} />
      )}
    </>
  );
};

export default RegisterCorporationModal;
