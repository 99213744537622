import React, { useEffect, useState } from 'react';
import userManager from 'utils/userManager';
import authUtil from 'utils/authUtil';
import useTranslation from 'utils/hooks/useTranslation';

const Activate = ({ history }) => {
  const { translateText } = useTranslation();

  const activating = translateText('corporateAccounts.admins.status.activating');
  const [redirectMessage, setRedirectMessage] = useState(activating);

  useEffect(() => {
    const setActivationMessage = async () => {
      try {
        await userManager.querySessionStatus();
      } catch {
        const activationMessage = translateText(
          'corporateAccounts.admins.status.completeActivation',
        );
        setRedirectMessage(activationMessage);
      }
    };

    setActivationMessage();
    authUtil.triggerActivationRedirect(history);
  }, [history]); // eslint-disable-line react-hooks/exhaustive-deps

  return <h2 style={{ textAlign: 'center', marginTop: 30 }}>{redirectMessage}</h2>;
};

export default Activate;
