import { CORP_CREATE_STEPS, CORP_CREATE_FIELDS } from 'constants/corporateClients';

const isHiddenField = (currentStep, step) => {
  return currentStep !== step ? 'hidden' : null;
};

const handleNext = async ({
  onFormDataChange,
  setAreAllStepsComplete,
  onSave,
  form,
  activeKey,
  setActivePage,
}) => {
  const newActiveKey =
    activeKey === CORP_CREATE_STEPS.PROFILE.TABKEY
      ? CORP_CREATE_STEPS.INFO.TABKEY
      : CORP_CREATE_STEPS.PROFILE.TABKEY;

  try {
    await form.validateFields(CORP_CREATE_FIELDS[activeKey]);
    const formFieldValues = await form.getFieldsValue();

    onFormDataChange(formFieldValues);

    if (activeKey === CORP_CREATE_STEPS.INFO.TABKEY) {
      onSave(formFieldValues);
      setAreAllStepsComplete(true);
    } else {
      setActivePage(newActiveKey);
    }
  } catch (errors) {
    /* form handles validation errors */
  }
};

const util = {
  handleNext,
  isHiddenField,
};

export default util;
