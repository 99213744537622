import notify from 'utils/notificationUtils';
import { DOMAIN_ERRORS } from 'constants/serverErrors';
import localizedMessage from '../services/localize';

export const getDomainErrorCode = errorResponse => {
  const domainErrorCode = errorResponse?.data?.domainErrorCode;
  return DOMAIN_ERRORS[domainErrorCode];
};

export const setDomainErrorNotification = ({ type, code }) => {
  const errorType = type || 'warn';
  notify({
    type: errorType,
    title: localizedMessage(`corporateAccounts.common.${type}`),
    message: localizedMessage(`corporateAccounts.server.domainErrors.${code}`),
  });
};

export default {
  getDomainErrorCode,
  setDomainErrorNotification,
};
