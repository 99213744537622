import { useEffect } from 'react';
import { URL_BUSINESS_PORTAL, PORTAL_ENV, ENABLE_PLAUSIBLE } from 'constants.js';

function usePlausible() {
  useEffect(() => {
    if (ENABLE_PLAUSIBLE?.toLowerCase() !== 'true') return;
    const script = document.createElement('script');

    const portalEnvironment = PORTAL_ENV ?? 'development';

    if (portalEnvironment === 'development') {
      script.textContent = customScriptForTestingInDev;
    } else {
      script.src = 'https://plausible.io/js/plausible.js';
      script.setAttribute('data-domain', URL_BUSINESS_PORTAL.replace(/^https?:\/\//, ''));
      script.async = true;
      script.defer = true;
    }

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);
}

const customScriptForTestingInDev = `
  (function() {
    window.plausible = function(eventName, options) {
      console.info(\`Plausible event: \${eventName}\`, options);
    };
    
    function trackPageview() {
      window.plausible('pageview', { u: window.location.href });
    }
    
    // Track initial pageview
    trackPageview();
    
    // Track subsequent pageviews
    const pushState = history.pushState;
    history.pushState = function() {
      pushState.apply(history, arguments);
      trackPageview();
    };
    window.addEventListener('popstate', trackPageview);
  })();
`;

export default usePlausible;
