import React from 'react';
import classNames from 'classnames';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import styles from './style.module.scss';

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

const Loader = ({ spinning = true, fullScreen, children }) => {
  const loader = (
    <div className={styles.overlay}>
      <Spin
        data-testid="loader"
        indicator={antIcon}
        className={classNames(styles.loader, {
          [styles.fullScreen]: fullScreen,
        })}
      />
    </div>
  );

  if (spinning) return loader;
  return children;
};

export default Loader;
