import { httpWrapper } from 'services/http';
import userService from 'services/user';
import { endpoint } from '.';

const BASE_ENDPOINT = '/adminusers';

const read = async () => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'GET',
    url: `${endpoint(BASE_ENDPOINT, false)}`,
    headers: {
      ...authHeaders,
    },
  };

  return httpWrapper(requestOptions);
};

const create = async payload => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'POST',
    url: `${endpoint(BASE_ENDPOINT, false)}`,
    headers: {
      ...authHeaders,
    },
    data: payload,
  };

  return httpWrapper(requestOptions);
};

const update = async payload => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'PUT',
    url: `${endpoint(BASE_ENDPOINT, false)}/${payload.id}`,
    headers: {
      ...authHeaders,
    },
    data: payload,
  };

  return httpWrapper(requestOptions);
};

const destroy = async userId => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'DELETE',
    url: `${endpoint(`${BASE_ENDPOINT}/${userId}`, false)}`,
    headers: {
      ...authHeaders,
    },
  };

  return httpWrapper(requestOptions);
};

const sendInvite = async userId => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'POST',
    url: `${endpoint(`${BASE_ENDPOINT}/${userId}:activate`, false)}`,
    headers: {
      ...authHeaders,
    },
  };

  return httpWrapper(requestOptions);
};

export default {
  create,
  read,
  update,
  destroy,
  sendInvite,
};
