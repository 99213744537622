import { useCallback } from 'react';
import { useMigrateCorporationToCreditAccount } from 'services/reactQueryHooks/corporations';
import { CREDIT_ACCOUNT_MIGRATION_STATUS } from 'constants/creditAccount';

const useCreditAccountMigration = () => {
  const {
    mutate: creditAccountMigration,
    isLoading: isCreditAccountMigrationLoading,
  } = useMigrateCorporationToCreditAccount();

  const handleCreditAccountMigration = useCallback(
    corporateClientIds => {
      const payload = {
        creditAccountMigrationRequests: corporateClientIds?.map(id => ({
          corporateClientId: id,
          migrationStatus: CREDIT_ACCOUNT_MIGRATION_STATUS.STARTED,
        })),
      };

      creditAccountMigration(payload);
    },
    [creditAccountMigration],
  );

  return {
    handleCreditAccountMigration,
    isCreditAccountMigrationLoading,
  };
};

export default useCreditAccountMigration;
