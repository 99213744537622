import React from 'react';

const useUpdateEffect = (effectCallback, dependencies = []) => {
  const isInitialMount = React.useRef(true);

  React.useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      effectCallback();
    }
    /* eslint-disable */
  }, dependencies);
};

export default useUpdateEffect;
