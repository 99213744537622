import { REHYDRATE } from 'redux-persist/lib/constants';
import { all, take, takeEvery, put, call } from 'redux-saga/effects';
import { adminUsers } from 'services/api';
import { localizedMessage } from 'services/localize';
import notify from 'utils/notificationUtils';
import actions from './actions';
import { USER_DOMAIN_ERROR_CODES } from 'constants/users';

export function* GET() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });

  const { response } = yield call(adminUsers.read);

  if (response && (response.status === 200 || response.status === 204)) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        data: response.data || [],
        formErrors: [],
      },
    });
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    });
  }
}

export function* POST({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      submitting: true,
    },
  });

  const { response, error } = yield call(adminUsers.create, payload);

  if (response && response.status === 201) {
    yield put({
      type: actions.POST_SUCCESS,
      payload: response.data,
      formErrors: [],
    });

    notify({
      type: 'success',
      message: localizedMessage('corporateAccounts.admin.add.success'),
    });
  } else if (error && error.status === 400) {
    if (
      error.errors.domainErrorCode ===
      USER_DOMAIN_ERROR_CODES.RestrictedCorporateClientLimitExceeded
    ) {
      notify({
        type: 'error',
        message: localizedMessage('corporateAccounts.user.limitExceeded.error'),
      });
    } else if (
      error?.errors?.message?.includes("User can't have multiple admin role in same corporate.")
    ) {
      notify({
        type: 'error',
        message: localizedMessage('corporateAccounts.user.registration.duplicateEmail'),
      });
    } else {
      yield put({
        type: actions.POST_ERROR,
        payload: {
          submitting: false,
          formErrors: error.errors,
        },
      });
    }
  } else {
    yield put({
      type: actions.POST_ERROR,
      payload: {
        submitting: false,
        error: error.message,
      },
    });
  }
}

export function* PUT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      submitting: true,
    },
  });

  const { response, error } = yield call(adminUsers.update, payload);

  if (response && response.status === 200) {
    yield put({
      type: actions.PUT_SUCCESS,
      payload: response.data,
      formErrors: [],
    });

    notify({
      type: 'success',
      message: localizedMessage('corporateAccounts.admin.update.success'),
    });
  } else if (error && error.status === 400) {
    yield put({
      type: actions.PUT_ERROR,
      payload: {
        submitting: false,
        formErrors: error.errors,
      },
    });
  } else {
    yield put({
      type: actions.PUT_ERROR,
      payload: {
        submitting: false,
      },
    });
  }
}

export function* DELETE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      submitting: true,
    },
  });

  const { response } = yield call(adminUsers.destroy, payload.id);

  if (response && response.status === 200) {
    yield put({
      type: actions.DELETE_SUCCESS,
      payload,
    });

    notify({
      type: 'success',
      message: localizedMessage('corporateAccounts.admin.delete.success'),
    });
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        submitting: false,
      },
    });
  }
}

export function* INVITE({ payload }) {
  const { response } = yield call(adminUsers.invite, payload);

  if (response && response.status === 200) {
    yield put({
      type: actions.INVITE_SUCCESS,
      payload: response.data,
    });

    notify({
      type: 'success',
      message: localizedMessage('corporateAccounts.admin.invite.success'),
    });
  }
}

export function* DOWNGRADE_ACCOUNT_OWNER({ payload }) {
  yield put({
    type: actions.PUT_SUCCESS,
    payload,
  });
}

export default function* rootSaga() {
  // Wait for redux-persist to rehydrate to prevent sagas running against empty store
  yield take(REHYDRATE);
  yield all([
    takeEvery(actions.GET, GET),
    takeEvery(actions.POST, POST),
    takeEvery(actions.PUT, PUT),
    takeEvery(actions.INVITE, INVITE),
    takeEvery(actions.DELETE, DELETE),
  ]);
}
