const orderByMapping = {
  ascend: 'asc',
  descend: 'desc',
};

export const calculateOffset = (page, limit) => (page <= 1 ? 0 : page * limit - limit);

export const getOrderByDirection = direction => {
  return orderByMapping[direction] || orderByMapping.ascend;
};

export default {
  calculateOffset,
  getOrderByDirection,
};
