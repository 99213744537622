module.exports = {
  'Corporate Accounts Key': '',
  TEST: 'Key for testing',
  'TEST.INTERPOLATIONS': 'Interpolate {interpolationTest}',
  'corporateAccounts.actions': 'Actions',
  'corporateAccounts.actions.addNewAccount': 'Add New Account',
  'corporateAccounts.actions.back': 'Back',
  'corporateAccounts.actions.browse': 'Browse',
  'corporateAccounts.actions.cancel': 'Cancel',
  'corporateAccounts.actions.chooseCsv': 'Choose CSV file',
  'corporateAccounts.actions.continue': 'Continue',
  'corporateAccounts.actions.deactivate': 'Deactivate',
  'corporateAccounts.actions.deactivateAccount': 'Deactivate account',
  'corporateAccounts.actions.delete': 'Delete',
  'corporateAccounts.actions.done': 'Done',
  'corporateAccounts.actions.download': 'Download',
  'corporateAccounts.actions.downloadLogFile': 'Download Log File',
  'corporateAccounts.actions.edit': 'Edit',
  'corporateAccounts.actions.export': 'Export',
  'corporateAccounts.actions.exportData': 'Export data',
  'corporateAccounts.actions.goToPage': 'Go to {pageName} page',
  'corporateAccounts.actions.importing': 'Importing...',
  'corporateAccounts.actions.migrate': 'Migrate',
  'corporateAccounts.actions.next': 'Next',
  'corporateAccounts.actions.reactivate': 'Reactivate',
  'corporateAccounts.actions.reactivateAccount': 'Reactivate account',
  'corporateAccounts.actions.register': 'Register',
  'corporateAccounts.actions.replaceFile': 'Replace file',
  'corporateAccounts.actions.resendInvite': 'Resend invite',
  'corporateAccounts.actions.resendInvite.error': 'Failed to resend the invitation',
  'corporateAccounts.actions.resendInvite.success': 'Invitation successfully sent to a driver',
  'corporateAccounts.actions.save': 'Save',
  'corporateAccounts.actions.sendInvite': 'Send Invite',
  'corporateAccounts.admin.accounts': 'Accounts',
  'corporateAccounts.admin.action.enterAccount': 'Enter Account',
  'corporateAccounts.admin.action.more': 'More',
  'corporateAccounts.admin.action.moreActions': 'More Actions',
  'corporateAccounts.admin.add.success': 'User added',
  'corporateAccounts.admin.corporation': 'Corporation',
  'corporateAccounts.admin.delete.success': 'User removed',
  'corporateAccounts.admin.invite.success': 'Invitations sent!',
  'corporateAccounts.admin.newCorporations': 'New Corporations',
  'corporateAccounts.admin.payment': 'Payment',
  'corporateAccounts.admin.paymentInfo': 'Payment Info',
  'corporateAccounts.admin.search': 'Search by business name',
  'corporateAccounts.admin.update.success': 'User updated',
  'corporateAccounts.admins.actions.addUser': 'Add user',
  'corporateAccounts.admins.actions.deleteUser': 'Delete this user?',
  'corporateAccounts.admins.actions.sendInviteMessage':
    'Invitations expire in {hours} hours. Invite selected users?',
  'corporateAccounts.admins.activate.error.message': 'Please login to complete activation',
  'corporateAccounts.admins.activate.error.title': 'Activation Failed',
  'corporateAccounts.admins.corporateClientId': 'ID',
  'corporateAccounts.admins.corporationName': 'Business name',
  'corporateAccounts.admins.deactivate.banner.message':
    "This account is deactivated. Drivers won't be able to access any assets associated to this corporation.",
  'corporateAccounts.admins.deactivate.error.body':
    '{corporateClientName} account deactivation failed. Please try again.',
  'corporateAccounts.admins.deactivate.error.title': 'Deactivation Error',
  'corporateAccounts.admins.deactivate.modal.confirm.body':
    '<p>Are you sure you want to deactivate the <strong>{corporateClientName}</strong> account?</p><p>Until this account is reactivated, user access will be blocked and all driver profiles will be unlinked from this business.</p>',
  'corporateAccounts.admins.deactivate.modal.confirm.title': 'Account deactivation',
  'corporateAccounts.admins.deactivate.success':
    'Account: {corporateClientName} has been deactivated',
  'corporateAccounts.admins.delete.error.body': '{corporateClientName} account deletion failed.',
  'corporateAccounts.admins.delete.error.title': 'Deletion Error.',
  'corporateAccounts.admins.delete.modal.confirm.body':
    '<p>Are you sure you want to permanently delete the <strong>{corporateClientName}</strong> account?</p><p>This action cannot be undone.</p>',
  'corporateAccounts.admins.delete.modal.confirm.title': 'Account deletion',
  'corporateAccounts.admins.delete.success': 'Account has been deleted',
  'corporateAccounts.admins.editUser': 'Edit User',
  'corporateAccounts.admins.email': 'Email',
  'corporateAccounts.admins.firstName': 'First Name',
  'corporateAccounts.admins.form.emailPlaceholder': 'Add email',
  'corporateAccounts.admins.form.emailRequired': 'Please enter an email address',
  'corporateAccounts.admins.form.firstNamePlaceholder': 'Add first name',
  'corporateAccounts.admins.form.firstNameRequired': 'Please enter a first name',
  'corporateAccounts.admins.form.lastNamePlaceholder': 'Add last name',
  'corporateAccounts.admins.form.lastNameRequired': 'Please enter a last name',
  'corporateAccounts.admins.form.phoneRequired': 'Please enter a phone number',
  'corporateAccounts.admins.form.roleRequired': 'Please select a role',
  'corporateAccounts.admins.form.title.edit': 'Edit User',
  'corporateAccounts.admins.form.title.new': 'Add user',
  'corporateAccounts.admins.lastName': 'Last Name',
  'corporateAccounts.admins.main.title': 'Users',
  'corporateAccounts.admins.modal.downgradeAccountOwner.content':
    'Only an upgrade of an active user to Account Owner is permitted.',
  'corporateAccounts.admins.modal.downgradeAccountOwner.title': 'Downgrade Account Owner',
  'corporateAccounts.admins.modal.upgradeBusinessManager.content':
    'The existing Account Owner will be downgraded to a Business Manager, would you like to continue?',
  'corporateAccounts.admins.modal.upgradeBusinessManager.title': 'Upgrade new Business Manager',
  'corporateAccounts.admins.phone': 'Phone',
  'corporateAccounts.admins.phoneNumber': 'Contact Phone Number',
  'corporateAccounts.admins.reactivate.error.body':
    '{corporateClientName} account reactivation failed. Please try again.',
  'corporateAccounts.admins.reactivate.error.title': 'Reactivation Error',
  'corporateAccounts.admins.reactivate.modal.confirm.body':
    '<p>Are you sure you want to reactivate the <strong>{corporateClientName}</strong> account?</p><p>Once complete, all drivers will be automatically reactivated.</p>',
  'corporateAccounts.admins.reactivate.modal.confirm.title': 'Account reactivation',
  'corporateAccounts.admins.reactivate.success':
    'Account: {corporateClientName} has been reactivated',
  'corporateAccounts.admins.role': 'Role',
  'corporateAccounts.admins.role.faq': 'What do these permissions mean?',
  'corporateAccounts.admins.role.faq.title': 'Roles & Permissions',
  'corporateAccounts.admins.role.fleet_manager': 'Business Manager',
  'corporateAccounts.admins.role.fleet_manager.description':
    'Access to all features, but cannot update subscription information.',
  'corporateAccounts.admins.role.primary_fleet_manager': 'Account Owner',
  'corporateAccounts.admins.role.primary_fleet_manager.description':
    'Access to all features. Primary contact for PayByPhone.',
  'corporateAccounts.admins.role.reporting': 'Analyst',
  'corporateAccounts.admins.role.reporting.description': 'Read only for reports.',
  'corporateAccounts.admins.role.service_admin': 'Business Admin',
  'corporateAccounts.admins.role.service_admin.description':
    'Access to all features, but cannot update subscription information or invite users to access the Dashboard.',
  'corporateAccounts.admins.status.activating': 'Activating...',
  'corporateAccounts.admins.status.completeActivation': 'Please login to complete activation',
  'corporateAccounts.admins.status.reinviteRequired': 'Re-invite Required',
  'corporateAccounts.auditLogs.auditDetails.description':
    '{auditLogUserAction} on {auditLogDate} by {user}',
  'corporateAccounts.auditLogs.auditDetails.id': 'Entity ID',
  'corporateAccounts.auditLogs.auditDetails.title': 'Audit details',
  'corporateAccounts.auditLogs.auditDetails.workflowId': 'Workflow ID',
  'corporateAccounts.auditLogs.emptyState.description': "This account doesn't have any logs",
  'corporateAccounts.auditLogs.searchInput.placeholder': 'Search by drivers, vehicles, user name',
  'corporateAccounts.auditLogs.table.clickForDetails': 'Click for details',
  'corporateAccounts.auditLogs.tableColumn.userAction': 'User action',
  'corporateAccounts.auditLogs.tableColumn.userRole': 'User role',
  'corporateAccounts.auth.redirectMessage':
    'You will now be redirected to the login page to reauthenticate. Thank you.',
  'corporateAccounts.auth.sessionExpired': 'Your session has expired.',
  'corporateAccounts.billing.billingAddress': 'Billing Address',
  'corporateAccounts.billing.subscription': 'Subscription',
  'corporateAccounts.breadcrumbs.adminUsers': 'Users',
  'corporateAccounts.breadcrumbs.create': 'Add',
  'corporateAccounts.breadcrumbs.drivers': 'Drivers',
  'corporateAccounts.breadcrumbs.edit': 'Edit',
  'corporateAccounts.breadcrumbs.monthlyStatements': 'Monthly Statements',
  'corporateAccounts.breadcrumbs.payments': 'Payment Methods',
  'corporateAccounts.breadcrumbs.reports': 'Reports',
  'corporateAccounts.breadcrumbs.sessions': 'Parking Sessions',
  'corporateAccounts.breadcrumbs.upload': 'Upload',
  'corporateAccounts.breadcrumbs.vehicles': 'Vehicles',
  'corporateAccounts.button.addSingleDriver': 'Add single driver',
  'corporateAccounts.button.addSingleVehicle': 'Add single vehicle',
  'corporateAccounts.button.bulkUpload': 'Bulk import',
  'corporateAccounts.common.all': 'All',
  'corporateAccounts.common.contact': 'Contact',
  'corporateAccounts.common.contactSales': 'Contact Sales',
  'corporateAccounts.common.costCenterInfo':
    "Cost centers can be used to assign expenses made by drivers to a department or project which can be filtered. You can also use it for internal references such as the driver's employee ID.",
  'corporateAccounts.common.date': 'Date',
  'corporateAccounts.common.details': 'Details',
  'corporateAccounts.common.directDebit': 'Direct debit',
  'corporateAccounts.common.download': 'Download',
  'corporateAccounts.common.driver': 'Driver',
  'corporateAccounts.common.empty': 'empty',
  'corporateAccounts.common.error': 'Error',
  'corporateAccounts.common.error.characterCountExceeded':
    'Field has exceeded maximum length of 75 characters',
  'corporateAccounts.common.error.somethingWentWrong': 'Something went wrong. Please try again.',
  'corporateAccounts.common.errorCode': 'Error Code',
  'corporateAccounts.common.errorCode.copy':
    'Please copy the error code above and share it with the support team for further assistance.',
  'corporateAccounts.common.frequency.weekly': 'Weekly',
  'corporateAccounts.common.help': 'Help',
  'corporateAccounts.common.invitationCode': 'Invitation Code',
  'corporateAccounts.common.invitationStatus': 'Invitation Status',
  'corporateAccounts.common.month': 'Month',
  'corporateAccounts.common.new': 'New',
  'corporateAccounts.common.no': 'No',
  'corporateAccounts.common.password': 'Password',
  'corporateAccounts.common.password.length': '8 to 51 characters long',
  'corporateAccounts.common.password.lowercase': 'One lowercase',
  'corporateAccounts.common.password.number': 'One number',
  'corporateAccounts.common.password.specialChar': 'One special character',
  'corporateAccounts.common.password.uppercase': 'One uppercase',
  'corporateAccounts.common.passwordRequired': 'Please enter a password',
  'corporateAccounts.common.pleaseTryAgain': 'Please try again.',
  'corporateAccounts.common.privacyPolicy': 'Privacy Policy',
  'corporateAccounts.common.questions': 'Questions',
  'corporateAccounts.common.remove': 'Remove',
  'corporateAccounts.common.rowNumber': 'Row number',
  'corporateAccounts.common.saveSuccessful': 'Save successful',
  'corporateAccounts.common.search': 'Search',
  'corporateAccounts.common.status': 'Status',
  'corporateAccounts.common.status.active': 'Active',
  'corporateAccounts.common.status.added': 'Added',
  'corporateAccounts.common.status.bulkAdded': 'Bulk Added',
  'corporateAccounts.common.status.bulkDeleted': 'Bulk Deleted',
  'corporateAccounts.common.status.close': 'Close',
  'corporateAccounts.common.status.closed': 'Closed',
  'corporateAccounts.common.status.deactivated': 'Deactivated',
  'corporateAccounts.common.status.deleted': 'Deleted',
  'corporateAccounts.common.status.expired': 'Expired',
  'corporateAccounts.common.status.inactive': 'Inactive',
  'corporateAccounts.common.status.inviteExpired': 'Invite Expired',
  'corporateAccounts.common.status.inviteFailed': 'Invite Failed',
  'corporateAccounts.common.status.inviteSent': 'Invite Sent',
  'corporateAccounts.common.status.invited': 'Invited',
  'corporateAccounts.common.status.modified': 'Modified',
  'corporateAccounts.common.status.new': 'New',
  'corporateAccounts.common.status.notCompleted': 'Not completed',
  'corporateAccounts.common.status.notInvited': 'Not Invited',
  'corporateAccounts.common.status.onHold': 'On hold',
  'corporateAccounts.common.status.onboarding': 'Onboarding',
  'corporateAccounts.common.status.passive': 'Passive',
  'corporateAccounts.common.status.pending': 'Pending',
  'corporateAccounts.common.status.previous': 'Previous',
  'corporateAccounts.common.status.prospect': 'Prospect',
  'corporateAccounts.common.status.rejected': 'Rejected',
  'corporateAccounts.common.subscriptionAgreement':
    'PayByPhone Business Subscription Terms & Conditions',
  'corporateAccounts.common.switchToDesktop': 'Switch to Desktop view',
  'corporateAccounts.common.switchToDesktop.description':
    'You are trying to view the portal from mobile browser. Log in to your PayByPhone Business account from desktop or laptop to get a better experience.',
  'corporateAccounts.common.termsAndConditions': 'Terms & Conditions',
  'corporateAccounts.common.termsAndPrivacyAgreementRequired':
    'Please accept the terms and privacy agreement',
  'corporateAccounts.common.type': 'Type',
  'corporateAccounts.common.unexpectedError': 'An unexpected error occurred',
  'corporateAccounts.common.update': 'Update',
  'corporateAccounts.common.upload': 'Import CSV',
  'corporateAccounts.common.user': 'User',
  'corporateAccounts.common.vehicle': 'Vehicle',
  'corporateAccounts.common.year': 'Year',
  'corporateAccounts.common.yes': 'Yes',
  'corporateAccounts.copyright': '2019 PayByPhone Technologies. All rights reserved.',
  'corporateAccounts.copyright.allRightsReserved': 'All rights reserved',
  'corporateAccounts.corporateClient.SpaCards': 'SPA Cards',
  'corporateAccounts.corporateClient.accountOwnerStatus': 'Account Owner Status',
  'corporateAccounts.corporateClient.accountType': 'Account Type',
  'corporateAccounts.corporateClient.accountType.makeTestAccount': 'Is this a test Account?',
  'corporateAccounts.corporateClient.accountType.standard': 'Standard',
  'corporateAccounts.corporateClient.accountType.test': 'Test',
  'corporateAccounts.corporateClient.activate.error.message':
    'Your account activation was not successful. Please try again, and if the issue persists, please contact our support team.',
  'corporateAccounts.corporateClient.address.error.presence': 'Please enter a address',
  'corporateAccounts.corporateClient.billing.Info':
    'Note: PayByPhone will issue an invoice. Please ensure your billing information is up-to-date.',
  'corporateAccounts.corporateClient.billing.edit.error.message':
    'An error occurred while updating the billing address. Please try again.',
  'corporateAccounts.corporateClient.billing.edit.error.title': 'Error: Edit Billing Address',
  'corporateAccounts.corporateClient.billing.edit.success': 'Billing address updated.',
  'corporateAccounts.corporateClient.city': 'City',
  'corporateAccounts.corporateClient.city.error.presence': 'Please enter a city',
  'corporateAccounts.corporateClient.clientStatus': 'Client Status',
  'corporateAccounts.corporateClient.companyAddress1': 'Corporation Address Line 1',
  'corporateAccounts.corporateClient.companyAddress2': 'Corporation Address Line 2',
  'corporateAccounts.corporateClient.corporationNumber': 'Corporation Number',
  'corporateAccounts.corporateClient.corporationNumber.error.presence':
    'Please enter corporation number',
  'corporateAccounts.corporateClient.country': 'Country',
  'corporateAccounts.corporateClient.country.error.presence': 'Please select a country',
  'corporateAccounts.corporateClient.country.placeholder': 'Select Country',
  'corporateAccounts.corporateClient.create.error':
    'We were unable to create the corporate client.',
  'corporateAccounts.corporateClient.create.success': 'Corporation created.',
  'corporateAccounts.corporateClient.createAccount': 'Create Account',
  'corporateAccounts.corporateClient.crnNo': 'CRN (Company Registration Number)',
  'corporateAccounts.corporateClient.dateCreated': 'Date Created',
  'corporateAccounts.corporateClient.edit.error': 'We were unable to update the corporate client.',
  'corporateAccounts.corporateClient.edit.success': 'Corporation updated.',
  'corporateAccounts.corporateClient.email': 'Email Address (Account Owner)',
  'corporateAccounts.corporateClient.fleetSize': 'No. of drivers',
  'corporateAccounts.corporateClient.fleetSize.error.presence': 'Please select number of drivers',
  'corporateAccounts.corporateClient.fleetSize.tooltip':
    "You can provide an approximate number. It won't limit you from adding more drivers later on.",
  'corporateAccounts.corporateClient.industry': 'Company Industry',
  'corporateAccounts.corporateClient.industry.placeholder': 'Select Industry',
  'corporateAccounts.corporateClient.industryOption.carRental': 'Car Rental',
  'corporateAccounts.corporateClient.industryOption.cleaning': 'Cleaning',
  'corporateAccounts.corporateClient.industryOption.construction': 'Construction',
  'corporateAccounts.corporateClient.industryOption.delivery': 'Delivery',
  'corporateAccounts.corporateClient.industryOption.electricVehicles': 'Electric Vehicles',
  'corporateAccounts.corporateClient.industryOption.electrical':
    'Electrical Equipment, Appliance, and Component Servicing',
  'corporateAccounts.corporateClient.industryOption.engineering': 'Engineering',
  'corporateAccounts.corporateClient.industryOption.foodService': 'Food & Service',
  'corporateAccounts.corporateClient.industryOption.furniture':
    'Furniture and Related Product Manufacturing',
  'corporateAccounts.corporateClient.industryOption.government': 'Government',
  'corporateAccounts.corporateClient.industryOption.healthcare': 'Healthcare & Medical',
  'corporateAccounts.corporateClient.industryOption.higherEducation': 'Higher Education',
  'corporateAccounts.corporateClient.industryOption.hospitals': 'Hospitals',
  'corporateAccounts.corporateClient.industryOption.hotelChain': 'Hotel / Chain',
  'corporateAccounts.corporateClient.industryOption.insurance': 'Insurance',
  'corporateAccounts.corporateClient.industryOption.landscape': 'Landscape Design & Architecture',
  'corporateAccounts.corporateClient.industryOption.manufacturing':
    'Manufacturing, Transport & Logistics',
  'corporateAccounts.corporateClient.industryOption.mobileHealth': 'Mobile health',
  'corporateAccounts.corporateClient.industryOption.mobileSalesTeam': 'Mobile Sales Team',
  'corporateAccounts.corporateClient.industryOption.municipal': 'Municipal',
  'corporateAccounts.corporateClient.industryOption.officeParking': 'Office parking',
  'corporateAccounts.corporateClient.industryOption.other': 'Other',
  'corporateAccounts.corporateClient.industryOption.passengerTerminal': 'Passenger Terminal',
  'corporateAccounts.corporateClient.industryOption.printing':
    'Printing and Related Support Activities',
  'corporateAccounts.corporateClient.industryOption.realEstate': 'Real Estate',
  'corporateAccounts.corporateClient.industryOption.retail': 'Retail',
  'corporateAccounts.corporateClient.industryOption.specialtyTrade': 'Specialty Trade Contractors',
  'corporateAccounts.corporateClient.industryOption.sport': 'Sport & Recreation',
  'corporateAccounts.corporateClient.industryOption.telecommunications': 'Telecommunications',
  'corporateAccounts.corporateClient.industryOption.tourism': 'Tourism',
  'corporateAccounts.corporateClient.industryOption.utilities': 'Utilities',
  'corporateAccounts.corporateClient.name': 'Company Legal Name',
  'corporateAccounts.corporateClient.name.error.presence': 'Please enter company name',
  'corporateAccounts.corporateClient.officeNumber': 'Office/Suite #',
  'corporateAccounts.corporateClient.owner.firstName.error.presence': 'Please enter a first name',
  'corporateAccounts.corporateClient.parkingPaymentMethod': 'Parking Payment Method',
  'corporateAccounts.corporateClient.payment.accountOwnerEmail': 'Account Owner Email',
  'corporateAccounts.corporateClient.payment.automaticPayment': 'Automatic Recurring Payment',
  'corporateAccounts.corporateClient.payment.cardExpiry': 'Card expiry',
  'corporateAccounts.corporateClient.payment.error.valueType': 'This value must be a number.',
  'corporateAccounts.corporateClient.payment.expiryMonth.error.invalid':
    'Must be a valid month value.',
  'corporateAccounts.corporateClient.payment.expiryMonth.error.presence':
    'Please enter a month (MM).',
  'corporateAccounts.corporateClient.payment.expiryYear.error': 'Please enter a valid expiry date.',
  'corporateAccounts.corporateClient.payment.expiryYear.error.presence':
    'Please enter a expiry year (YYYY).',
  'corporateAccounts.corporateClient.payment.firstSixdigits': 'First six digits',
  'corporateAccounts.corporateClient.payment.firstSixdigits.error.length':
    'Field must be exactly 6 digits.',
  'corporateAccounts.corporateClient.payment.firstSixdigits.error.presence':
    'Please enter first six digits of credit card.',
  'corporateAccounts.corporateClient.payment.invoiceSent':
    'Invoices will be sent to the following email address(s):',
  'corporateAccounts.corporateClient.payment.invoicedManually': 'Invoiced manually',
  'corporateAccounts.corporateClient.payment.lastFourDigits': 'Last four digits',
  'corporateAccounts.corporateClient.payment.lastFourDigits.error.length':
    'Field must be exactly 4 digits.',
  'corporateAccounts.corporateClient.payment.lastFourDigits.error.presence':
    'Please enter last four digits of credit card.',
  'corporateAccounts.corporateClient.payment.noInviteSent':
    'No invitation will be sent once corporation has been created',
  'corporateAccounts.corporateClient.payment.paymentInfo': 'Parking Payment Info:',
  'corporateAccounts.corporateClient.payment.subscriptionCommitted':
    'Business Client has committed to subscription? (Y/N)',
  'corporateAccounts.corporateClient.postalCode': 'Postal/Zip Code',
  'corporateAccounts.corporateClient.postalCode.error.presence': 'Please enter a postal code',
  'corporateAccounts.corporateClient.postalCode.error.validity': 'Postal code is invalid.',
  'corporateAccounts.corporateClient.preferredLanguage': 'Employee language preference',
  'corporateAccounts.corporateClient.preferredLanguage.error.presence': 'Please select a language',
  'corporateAccounts.corporateClient.preferredLanguage.placeholder': 'Select language',
  'corporateAccounts.corporateClient.region': 'Region',
  'corporateAccounts.corporateClient.region.error.presence': 'Please enter a region',
  'corporateAccounts.corporateClient.region.placeholder': 'Select Region',
  'corporateAccounts.corporateClient.register.addressTitle': 'Registered address for invoicing',
  'corporateAccounts.corporateClient.register.duplicateNameError.message':
    'Try changing it to a different name.',
  'corporateAccounts.corporateClient.register.duplicateNameError.title':
    'Error: This corporation name is invalid',
  'corporateAccounts.corporateClient.register.internalError.message':
    'An error occurred while registering your corporation. Please try again.',
  'corporateAccounts.corporateClient.register.internalError.title':
    'Error: Registering Corporation',
  'corporateAccounts.corporateClient.register.registrationInfo':
    'Your registered address will be displayed on invoices. You can update this information on the Billing and Subscription page at any time.',
  'corporateAccounts.corporateClient.registrationType': 'Registration Type',
  'corporateAccounts.corporateClient.registrationType.enterprise': 'Enterprise',
  'corporateAccounts.corporateClient.registrationType.selfRegistered': 'Self-Registered',
  'corporateAccounts.corporateClient.restrictionType': 'Restriction Type',
  'corporateAccounts.corporateClient.restrictionType.restricted': 'Restricted',
  'corporateAccounts.corporateClient.restrictionType.unrestricted': 'Unrestricted',
  'corporateAccounts.corporateClient.salesforceId': 'Salesforce ID',
  'corporateAccounts.corporateClient.salesforceId.error.alphanumeric':
    'Salesforce ID must only have letters and numbers',
  'corporateAccounts.corporateClient.salesforceId.error.invalid': 'Not a valid Salesforce ID',
  'corporateAccounts.corporateClient.salesforceId.error.length':
    'Salesforce ID must be 15 or 18 characters long',
  'corporateAccounts.corporateClient.salesforceId.error.presence': 'Please enter Salesforce ID',
  'corporateAccounts.corporateClient.salesforceId.infoMessage':
    'Salesforce IDs are used for billing, clients with incorrect IDs won’t be invoiced. This ID can be found in Safe Case ID on the Account page in Salesforce and should be copied and pasted into this field.',
  'corporateAccounts.corporateClient.siretNo': 'SIRET No.',
  'corporateAccounts.corporateClient.stateProvince': 'State/Province',
  'corporateAccounts.corporateClient.streetAddress': 'Street Address',
  'corporateAccounts.corporateClient.taxNumber': 'Tax Number',
  'corporateAccounts.corporateClient.taxNumber.error.characterCountExceeded':
    'Must be 14 characters or less (letters and numbers only).',
  'corporateAccounts.corporateClient.taxNumber.error.notAlphanumeric':
    'Please enter only letters and numbers.',
  'corporateAccounts.corporateClient.taxNumber.error.presence': 'Please enter tax number',
  'corporateAccounts.corporateClient.taxNumber.error_presence': 'Please enter {taxNumber}',
  'corporateAccounts.corporateClient.version': 'Version',
  'corporateAccounts.corporations.register.subtitle':
    'We just need a few details about your business',
  'corporateAccounts.corporations.register.title': 'Welcome to PayByPhone Business!',
  'corporateAccounts.corporations.title': 'My Accounts',
  'corporateAccounts.countries.ad': 'Andorra (AD)',
  'corporateAccounts.countries.al': 'Albania (AL)',
  'corporateAccounts.countries.am': 'Armenia (AM)',
  'corporateAccounts.countries.at': 'Austria',
  'corporateAccounts.countries.au': 'Australia (AU)',
  'corporateAccounts.countries.ba': 'Bosnia and Herzegovina (BA)',
  'corporateAccounts.countries.be': 'Belgium (BE)',
  'corporateAccounts.countries.bg': 'Bulgaria (BG)',
  'corporateAccounts.countries.by': 'Belarus (BY)',
  'corporateAccounts.countries.ca': 'Canada (CA)',
  'corporateAccounts.countries.ch': 'Switzerland (CH)',
  'corporateAccounts.countries.cy': 'Cyprus (CY)',
  'corporateAccounts.countries.cz': 'Czech Republic',
  'corporateAccounts.countries.de': 'Germany (DE)',
  'corporateAccounts.countries.dk': 'Denmark (DK)',
  'corporateAccounts.countries.ee': 'Estonia (EE)',
  'corporateAccounts.countries.es': 'Spain',
  'corporateAccounts.countries.fi': 'Finland (FI)',
  'corporateAccounts.countries.fr': 'France (FR)',
  'corporateAccounts.countries.gb': 'United Kingdom (GB)',
  'corporateAccounts.countries.gr': 'Greece (GR)',
  'corporateAccounts.countries.hr': 'Croatia (HR)',
  'corporateAccounts.countries.hu': 'Hungary (HU)',
  'corporateAccounts.countries.i': 'Slovenia (I)',
  'corporateAccounts.countries.ie': 'Ireland (IE)',
  'corporateAccounts.countries.is': 'Iceland (IS)',
  'corporateAccounts.countries.it': 'Italy',
  'corporateAccounts.countries.li': 'Liechtenstein',
  'corporateAccounts.countries.lt': 'Lithuania (LT)',
  'corporateAccounts.countries.lu': 'Luxembourg (LU)',
  'corporateAccounts.countries.lv': 'Latvia (LV)',
  'corporateAccounts.countries.mc': 'Monaco (MC) ',
  'corporateAccounts.countries.md': 'Moldova (MD)',
  'corporateAccounts.countries.me': 'Montenegro (ME)',
  'corporateAccounts.countries.mk': 'North Macedonia (MK)',
  'corporateAccounts.countries.mt': 'Malta (MT)',
  'corporateAccounts.countries.nl': 'Netherlands (NL)',
  'corporateAccounts.countries.no': 'Norway (NO)',
  'corporateAccounts.countries.pl': 'Poland (PL)',
  'corporateAccounts.countries.pt': 'Portugal (PT)',
  'corporateAccounts.countries.ro': 'Romania (RO)',
  'corporateAccounts.countries.rs': 'Serbia (RS)',
  'corporateAccounts.countries.ru': 'Russia (RU)',
  'corporateAccounts.countries.se': 'Sweden (SE)',
  'corporateAccounts.countries.sk': 'Slovakia (SK)',
  'corporateAccounts.countries.sm': 'San Marino (SM)',
  'corporateAccounts.countries.tr': 'Turkey (TR)',
  'corporateAccounts.countries.ua': 'Ukraine (UA)',
  'corporateAccounts.countries.us': 'United States (US)',
  'corporateAccounts.countries.va': 'Vatican (VA)',
  'corporateAccounts.creditAccount.authorizedDriversBlurb':
    'Authorized drivers automatically will have a business payment method linked to their PayByPhone apps.',
  'corporateAccounts.creditAccount.details.accountNumber': 'Account Number',
  'corporateAccounts.creditAccount.details.approvedCreditLimit': 'Approved credit limit',
  'corporateAccounts.creditAccount.details.availableCredit': 'Available credit',
  'corporateAccounts.creditAccount.details.creditAccountDetails': 'Credit account details',
  'corporateAccounts.creditAccount.details.creditAccountId': 'Credit Account Id',
  'corporateAccounts.creditAccount.details.currentSpend': 'Current spend',
  'corporateAccounts.creditAccount.details.expiryDate':
    'Card ending with {cardNumber} expiring on {expiryDate}',
  'corporateAccounts.creditAccount.details.invoicingFrequency': 'Invoicing frequency',
  'corporateAccounts.creditAccount.details.lastUpdated': 'Last updated',
  'corporateAccounts.creditAccount.details.nextPaymentDate': 'Next payment date',
  'corporateAccounts.creditAccount.details.overLimitPaymentMethod':
    'Payment method for transaction above the credit limit',
  'corporateAccounts.creditAccount.details.paymentAmountDue': 'Payment amount due',
  'corporateAccounts.creditAccount.details.paymentDetails': 'Payment details',
  'corporateAccounts.creditAccount.details.paymentMethod': 'Payment method',
  'corporateAccounts.creditAccount.details.toolTip':
    'Total parking and subscription fees for this billing cycle',
  'corporateAccounts.creditAccount.internalError':
    'We are having issues retrieving your details. Please reload the page. If the issue persists, contact customer support.',
  'corporateAccounts.creditAccount.migrationStatus': 'Credit Account Migration Status',
  'corporateAccounts.creditAccount.migrationStatus.new': 'New',
  'corporateAccounts.creditAccount.migrationStatus.notStarted': 'Not Started',
  'corporateAccounts.creditAccount.migrationStatus.started': 'Started',
  'corporateAccounts.creditAccount.pageTitle': 'Credit account',
  'corporateAccounts.creditAccount.pendingBanner.description':
    'Your information will be updated here within ~48 hours and you’ll get an email notification.  If your information hasn’t been updated after 48 hours, please contact us at 02045258010.',
  'corporateAccounts.creditAccount.rejectedBanner.cta': 'Inquire about pre-payment',
  'corporateAccounts.creditAccount.rejectedBanner.description':
    'However, you can still pre-pay and park with PayByPhone Business. If you believe there was an error with your credit account application, please contact us at 02045258010.',
  'corporateAccounts.creditAccount.rejectedBanner.title':
    'Unfortunately, we are unable to offer you a credit account at this time.',
  'corporateAccounts.creditAccount.seeFaq': 'See FAQ',
  'corporateAccounts.creditAccount.setUpButton': 'Set up credit account',
  'corporateAccounts.creditAccount.status': 'Credit Account Status',
  'corporateAccounts.creditAccount.stepCard1.subtitle':
    "We'll ask you to verify company address and registration number.",
  'corporateAccounts.creditAccount.stepCard1.title': '1. Confirm company details',
  'corporateAccounts.creditAccount.stepCard2.subtitle':
    'Estimate your monthly parking expenses so we can calculate your credit limit.',
  'corporateAccounts.creditAccount.stepCard2.title': '2. Get pre-approved',
  'corporateAccounts.creditAccount.stepCard3.subtitle':
    'Authorize a direct debit for hassle-free bill payments.',
  'corporateAccounts.creditAccount.stepCard3.title': '3. Set up direct debit',
  'corporateAccounts.creditAccount.subtitle':
    'A dedicated credit line for all your parking expenses.',
  'corporateAccounts.creditAccount.title': 'Park now, pay later with PayByPhone Business.',
  'corporateAccounts.creditAccount.type': 'Credit Account Type',
  'corporateAccounts.creditAccount.type.creditAccount': 'Credit Account',
  'corporateAccounts.creditAccount.type.creditBuilder': 'Credit Builder',
  'corporateAccounts.creditAccount.type.hybrid': 'Hybrid',
  'corporateAccounts.creditAccountMigration.error':
    'An error occurred while migrating the selected corporate clients to credit accounts.',
  'corporateAccounts.creditAccountMigration.error.countryNotSupported':
    'One or more of the selected corporate clients are not in Great Britain and cannot be migrated to a credit account.',
  'corporateAccounts.creditAccountMigration.error.notFound':
    'One or more of the selected corporate clients cannot be found.',
  'corporateAccounts.creditAccountMigration.success':
    'The selected corporate clients have successfully started their migration to credit accounts.',
  'corporateAccounts.creditAccounts.modal.cta': 'Set up credit account',
  'corporateAccounts.creditAccounts.modal.description1':
    "We're excited to announce the launch of credit accounts provided by Allstar Business Solutions Limited, our sister company and a part of Corpay group. We are transitioning away from current card payments to providing you with a parking credit account.",
  'corporateAccounts.creditAccounts.modal.description2': 'Also with,',
  'corporateAccounts.creditAccounts.modal.description3':
    'Detailed parking statements to support your VAT claims',
  'corporateAccounts.creditAccounts.modal.description4': 'A single payment for easy bookkeeping.',
  'corporateAccounts.creditAccounts.modal.title': 'Introducing credit accounts',
  'corporateAccounts.daysOfWeek.0': 'Sunday',
  'corporateAccounts.daysOfWeek.1': 'Monday',
  'corporateAccounts.daysOfWeek.2': 'Tuesday',
  'corporateAccounts.daysOfWeek.3': 'Wednesday',
  'corporateAccounts.daysOfWeek.4': 'Thursday',
  'corporateAccounts.daysOfWeek.5': 'Friday',
  'corporateAccounts.daysOfWeek.6': 'Saturday',
  'corporateAccounts.driver.activate.success': 'Activations sent',
  'corporateAccounts.driver.activate.warning':
    'Some drivers were not activated due to not having a PayByPhone account. Please try again once accounts have been set up.',
  'corporateAccounts.driver.add.error': 'We were unable to add your driver.',
  'corporateAccounts.driver.bulkDelete.success': '{numOfDeletedDrivers} Drivers have been deleted',
  'corporateAccounts.driver.delete.error': 'We were unable to complete the delete request.',
  'corporateAccounts.driver.delete.success': 'Driver removed',
  'corporateAccounts.driver.generic.error': 'There was an issue updating the driver selected.',
  'corporateAccounts.driver.get.error': 'We encountered an error retrieving your drivers.',
  'corporateAccounts.driver.invite.error': 'One or more invitations have failed, please try again.',
  'corporateAccounts.driver.invite.success': 'Invitations sent',
  'corporateAccounts.driver.limitExceeded.error':
    'You have reached your driver limit. To learn more about how to increase your limit, please contact sales.',
  'corporateAccounts.driver.profileExists.error':
    'The phone number you want to add belongs to another driver. Enter the phone in the search bar in the format +[country code]XXXXXXXXX to locate the user it belongs to. Then, update their details or delete and re-add them.',
  'corporateAccounts.driver.update.error': 'We were unable to edit your driver.',
  'corporateAccounts.driver.update.profileExistsError':
    'Update Failed - Driver Profile with this phone number already exists.',
  'corporateAccounts.driver.update.success': 'Driver updated',
  'corporateAccounts.drivers.actions': 'Actions',
  'corporateAccounts.drivers.actions.activate': 'Activate',
  'corporateAccounts.drivers.actions.addParker': 'Add driver',
  'corporateAccounts.drivers.actions.deleteParkerMessage': 'Delete this Driver?',
  'corporateAccounts.drivers.actions.reinvite': 'Reinvite',
  'corporateAccounts.drivers.activationModal.body1':
    'Drivers cannot be activated until they set up a PayByPhone account.',
  'corporateAccounts.drivers.activationModal.body2':
    'This can be done by downloading the PayByPhone app and registering an account using the phone number displayed in your driver list.',
  'corporateAccounts.drivers.bulkDelete.modal.content':
    'Are you sure you would like to delete the selected drivers and remove their business payment method access?',
  'corporateAccounts.drivers.bulkDelete.modal.title': 'Deleting drivers',
  'corporateAccounts.drivers.bulkResendInvite.sucess': 'Invites sent',
  'corporateAccounts.drivers.bulkResendInvite.warning':
    'Some driver invites were not sent due to them already having an active account',
  'corporateAccounts.drivers.bulkResendInviteModal.description':
    'The invitation link will expire after 1 week. Any active drivers will not get the invitation.',
  'corporateAccounts.drivers.bulkResendInviteModal.title': 'Resend invite?',
  'corporateAccounts.drivers.costCenter': 'Cost Center',
  'corporateAccounts.drivers.costCenterInfo':
    "Cost centers can be used to assign expenses made by drivers to a department or project which can be filtered. You can also use it for internal references such as the driver's employee ID.",
  'corporateAccounts.drivers.csvDownload.costCenter': 'Cost Center',
  'corporateAccounts.drivers.csvDownload.email': 'Email (required)',
  'corporateAccounts.drivers.csvDownload.phoneCountryCode':
    'Country Code [1 / 33 / 44 / etc] (required)',
  'corporateAccounts.drivers.csvDownload.phoneNumber': 'Phone Number (required)',
  'corporateAccounts.drivers.deleteModal.content':
    'Are you sure you want to delete this driver? Driver will not have access to business payment methods upon deletion.',
  'corporateAccounts.drivers.deleteModal.title': 'Delete driver?',
  'corporateAccounts.drivers.department': 'Department',
  'corporateAccounts.drivers.email': 'Email',
  'corporateAccounts.drivers.employeeId': 'Employee ID',
  'corporateAccounts.drivers.emptyState.description':
    'Only authorized drivers will be able to pay for parking with your business payment methods. You can choose to notify drivers to install the PayByPhone mobile app via SMS or email. Drivers can use their existing account.',
  'corporateAccounts.drivers.emptyState.importDriversBtn': 'Import drivers',
  'corporateAccounts.drivers.emptyState.videoLink': 'How to invite drivers',
  'corporateAccounts.drivers.export.error.modal.description':
    'Could not export drivers list, please try again.',
  'corporateAccounts.drivers.export.error.modal.title': 'Drivers list export error',
  'corporateAccounts.drivers.export.fileName': 'DriverList',
  'corporateAccounts.drivers.export.modal.description':
    'Exported data will be generated based on the filters currently applied to your drivers list.',
  'corporateAccounts.drivers.export.modal.title': 'Drivers list export',
  'corporateAccounts.drivers.export.success.notification.description':
    'Your drivers list has been exported.',
  'corporateAccounts.drivers.export.success.notification.title': 'Drivers list exported',
  'corporateAccounts.drivers.firstName': 'First Name',
  'corporateAccounts.drivers.form.add': 'Add driver',
  'corporateAccounts.drivers.form.costCenterMaxLength':
    'Cost Center must not exceed a length of 15 characters.',
  'corporateAccounts.drivers.form.emailRequired': 'Please enter an email address',
  'corporateAccounts.drivers.form.emailValidation': 'Please enter a valid email address',
  'corporateAccounts.drivers.form.employeeIdValidation':
    'Employee ID must not exceed a length of 50 characters',
  'corporateAccounts.drivers.form.firstNameValidation':
    'Your first name must not exceed a length of 50 characters',
  'corporateAccounts.drivers.form.invitationOptions': 'Invite Driver via',
  'corporateAccounts.drivers.form.inviteOptionsError': 'Please select at least one option',
  'corporateAccounts.drivers.form.jobTitleValidation':
    'Job Title must not exceed a length of 50 characters',
  'corporateAccounts.drivers.form.lastNameValidation':
    'Your last name must not exceed a length of 50 characters',
  'corporateAccounts.drivers.form.phoneRequired': 'Please enter a phone number',
  'corporateAccounts.drivers.form.phoneValidation': 'Please enter a valid phone number',
  'corporateAccounts.drivers.form.selectInvitationMethod': 'Please select an invitation method',
  'corporateAccounts.drivers.form.sms': 'SMS',
  'corporateAccounts.drivers.form.title.edit': 'Edit Driver',
  'corporateAccounts.drivers.form.title.new': 'Add new driver',
  'corporateAccounts.drivers.form.update': 'Update Driver',
  'corporateAccounts.drivers.groupId': 'Group ID',
  'corporateAccounts.drivers.import.error':
    'Some or all driver entries are invalid. Download the log file to get more details. Once corrected, try again.',
  'corporateAccounts.drivers.import.error.EmailRequired': 'Missing entry (Email)',
  'corporateAccounts.drivers.import.error.UnknownParsingError':
    'An error occurred while parsing the file',
  'corporateAccounts.drivers.import.error.costCenterMaxLengthExceeded':
    'Exceed maximum length (cost center)',
  'corporateAccounts.drivers.import.error.csvNoRecord':
    'There are no valid drivers to be imported.',
  'corporateAccounts.drivers.import.error.duplicateEntries': 'Duplicate drivers',
  'corporateAccounts.drivers.import.error.failed': 'Drivers failed to import.',
  'corporateAccounts.drivers.import.error.fileName': 'LogFile',
  'corporateAccounts.drivers.import.error.firstNameMaxLengthExceeded':
    'Exceed maximum length (first name)',
  'corporateAccounts.drivers.import.error.firstNameRequired': 'Missing entry (first name)',
  'corporateAccounts.drivers.import.error.invalidCostCenter': 'Incorrect format (cost center)',
  'corporateAccounts.drivers.import.error.invalidEmail': 'Incorrect format (email)',
  'corporateAccounts.drivers.import.error.invalidEntries': 'Invalid driver entries',
  'corporateAccounts.drivers.import.error.invalidNumbers': 'Incorrect format (phone number)',
  'corporateAccounts.drivers.import.error.lastNameMaxLengthExceeded':
    'Exceed maximum length (last name)',
  'corporateAccounts.drivers.import.error.lastNameRequired': 'Missing entry (last name)',
  'corporateAccounts.drivers.import.error.phoneNumberRequired': 'Missing entry (phone number)',
  'corporateAccounts.drivers.import.error.uploadFile':
    'There was an issue uploading your file. Ensure you are uploading a valid CSV file and try again.',
  'corporateAccounts.drivers.import.failed': 'Import Failed',
  'corporateAccounts.drivers.import.fileName': 'PayByPhone Business Driver Import Template',
  'corporateAccounts.drivers.import.header.costCenter': 'Cost Center (optional)',
  'corporateAccounts.drivers.import.header.countryCode': 'Phone Number Country Code',
  'corporateAccounts.drivers.import.header.email': 'Email',
  'corporateAccounts.drivers.import.header.phoneNumber': 'Phone Number',
  'corporateAccounts.drivers.import.inProgress':
    'Import in progress with {total} drivers ({driversAdded} new, {driversEdited} edited)',
  'corporateAccounts.drivers.import.spaModal.title': 'Important, please read!',
  'corporateAccounts.drivers.import.success':
    '{total} drivers imported successfully! ({driversAdded} new, {driversEdited} edited)',
  'corporateAccounts.drivers.import.warning':
    '{total} drivers imported successfully! ({driversAdded} new, {driversEdited} edited), {driversFailed} drivers could not be imported.',
  'corporateAccounts.drivers.importModal.editDrivers': 'To edit existing drivers',
  'corporateAccounts.drivers.importModal.editDrivers.1':
    'Download this CSV with all the driver information.',
  'corporateAccounts.drivers.importModal.editDrivers.2': 'Edit existing driver information.',
  'corporateAccounts.drivers.importModal.editDrivers.3': 'Upload the updated CSV file',
  'corporateAccounts.drivers.importModal.newDrivers': 'To import new drivers',
  'corporateAccounts.drivers.importModal.newDrivers.1': 'Add new driver information in each row.',
  'corporateAccounts.drivers.importModal.title': 'Import drivers by CSV',
  'corporateAccounts.drivers.infograph.card.description':
    'Ask drivers to download the PayByPhone app and set up an account. Inactive drivers will need to be activated before seeing your payment methods.',
  'corporateAccounts.drivers.infograph.card.title': 'Your drivers will be listed here',
  'corporateAccounts.drivers.infograph.cards.0.body':
    'Ask drivers to download the PayByPhone app and set up an account.',
  'corporateAccounts.drivers.infograph.cards.0.title': 'Notify your drivers',
  'corporateAccounts.drivers.infograph.cards.1.title': 'Upload drivers list',
  'corporateAccounts.drivers.infograph.cards.2.body':
    'Inactive drivers will need to be activated before seeing your payment methods.',
  'corporateAccounts.drivers.infograph.cards.2.title': 'Activate drivers',
  'corporateAccounts.drivers.infograph.subcontent':
    'Driver with a status of ‘Inactive’ will need to be activated using the ‘activate’ button. The driver’s PayByPhone account phone number must be the same as the one on this list.',
  'corporateAccounts.drivers.inviteDriverToolTip':
    'A notification with the invitation link will be sent to a driver. The invitation link will be active for 7 days. You will see a driver status change to “Active”, once they accept the invitation. If the invitation link expires, you can resend it from the “More” dropdown next to a driver.',
  'corporateAccounts.drivers.jobTitle': 'Job Title',
  'corporateAccounts.drivers.lastName': 'Last Name',
  'corporateAccounts.drivers.main.title': 'Drivers',
  'corporateAccounts.drivers.mobilePhoneNumber': 'Mobile Phone Number',
  'corporateAccounts.drivers.resendInviteModal.description': 'The link will expire in 1 week',
  'corporateAccounts.drivers.restrictedAction.add.content':
    'This action is temporarily restricted, however your PaybyPhone account manager will be able to assist you with this process, so that your employees can start parking right away!',
  'corporateAccounts.drivers.restrictedAction.add.title': 'Need to add new drivers?',
  'corporateAccounts.drivers.restrictedAction.edit.content':
    'This action is temporarily restricted, however your PaybyPhone account manager will be able to assist you with this process.',
  'corporateAccounts.drivers.restrictedAction.edit.title': 'Need to edit a driver?',
  'corporateAccounts.drivers.restrictedAction.upload.title': 'Need to upload drivers?',
  'corporateAccounts.drivers.search.placeholder': 'Search drivers',
  'corporateAccounts.drivers.workPhoneNumber': 'Work Phone Number',
  'corporateAccounts.errors.fileUpload':
    'An error has occurred during the upload, please try again.',
  'corporateAccounts.errors.unauthorized.content':
    'You are not assigned to any corporations, please contact support.',
  'corporateAccounts.errors.unauthorized.title': 'Unauthorized Access',
  'corporateAccounts.feedbackModal.button': 'Submit Feedback',
  'corporateAccounts.feedbackModal.feedback.description':
    'Do you have feedback or want to submit a feature request?',
  'corporateAccounts.feedbackModal.feedback.placeholder': 'Enter your feedback here',
  'corporateAccounts.feedbackModal.feedback.subDescription':
    'We’d love to hear from you and your feedback will be reviewed by our product development team.',
  'corporateAccounts.feedbackModal.feedback.success': 'Thank you for your feedback.',
  'corporateAccounts.feedbackModal.support.button': 'Submit a support request',
  'corporateAccounts.feedbackModal.support.description':
    'Do you need technical support or want to report a bug?',
  'corporateAccounts.feedbackModal.title': 'Give Feedback',
  'corporateAccounts.fileUpload.loading': 'This may take few seconds...',
  'corporateAccounts.globalNotification.maintenanceMessage':
    'Maintenance is scheduled on {weekday}, {monthDay} at {startTime} to {endTime}. Access to PayByPhone business will be unavailable during this time period.',
  'corporateAccounts.globalNotification.maintenancePage.restoreTime':
    'The website will be restored at {maintenanceEndHour}. Thank you for your patience.',
  'corporateAccounts.globalNotification.maintenancePage.underGoingScheduledMaintenance':
    'Our Website is currently undergoing scheduled maintenance.',
  'corporateAccounts.globalNotification.maintenancePage.underScheduleMaintenance':
    'Website under schedule maintenance...',
  'corporateAccounts.homePage': 'Go back to the home page',
  'corporateAccounts.import.downloadCsv': 'Download this CSV template',
  'corporateAccounts.import.downloadCsvTemplate': 'Download this CSV template',
  'corporateAccounts.import.driver.success':
    'Importing driver data can take a few hours to complete.',
  'corporateAccounts.import.emailNotify.success':
    'An email notification will be sent to you once the process has completed.',
  'corporateAccounts.import.importData': 'Import data',
  'corporateAccounts.import.maxFileSize.error':
    'Only files less or equal to 1MB are accepted at this time',
  'corporateAccounts.import.singleFile.error': 'Only 1 file per upload is permitted at this time.',
  'corporateAccounts.import.step.1.title': 'Step 1:',
  'corporateAccounts.import.step.2.title': 'Step 2:',
  'corporateAccounts.import.step.3.title': 'Step 3:',
  'corporateAccounts.import.step.5.content': 'Click Import data below.',
  'corporateAccounts.import.title.success': 'File uploaded',
  'corporateAccounts.import.vehicle.success': 'Vehicles have been successfully imported',
  'corporateAccounts.infograph.drivers.cards.0.body':
    "Once you have a payment method and vehicles added, it's time to enter your drivers details into the portal.",
  'corporateAccounts.infograph.drivers.cards.0.title': 'ADD A DRIVER',
  'corporateAccounts.infograph.drivers.cards.1.body':
    'Choose to send your drivers an automatic invitation. View Invitation Process',
  'corporateAccounts.infograph.drivers.cards.1.title': 'SEND INVITATION',
  'corporateAccounts.infograph.drivers.cards.2.body':
    'Once your drivers account is linked, they will be able to pay for business parking with your corporate payment method.',
  'corporateAccounts.infograph.drivers.cards.2.title': 'START PARKING',
  'corporateAccounts.infograph.drivers.note':
    'It is important that your drivers link their corporation account to their personal account. As setting up a separate business account will cause unnecessary time and friction during the parking experience.',
  'corporateAccounts.infograph.payments.cards.0.body':
    'Make it easier to manage parking payments by sharing a masked corporate payment card with your drivers.',
  'corporateAccounts.infograph.payments.cards.0.title': 'ADD A PAYMENT CARD',
  'corporateAccounts.infograph.payments.cards.1.body':
    'Give your payment card a name so that your employees can easily recognize it in the app.',
  'corporateAccounts.infograph.payments.cards.1.title': 'SET CARD NAME',
  'corporateAccounts.infograph.payments.cards.2.body':
    'Once you have saved your card, it will be accessible on your drivers device.',
  'corporateAccounts.infograph.payments.cards.2.title': 'START PARKING',
  'corporateAccounts.infograph.payments.note':
    'Your drivers will only be able to see the name of your card. They will not require any further card information or CVV in order to pay for parking.',
  'corporateAccounts.infograph.vehicles.cards.0.body':
    'Enter vehicles into the portal so that your drivers can park them using their PayByPhone app.',
  'corporateAccounts.infograph.vehicles.cards.0.title': 'ADD A VEHICLE',
  'corporateAccounts.infograph.vehicles.cards.1.body':
    'Give your vehicle a name. This will not be displayed in the app, but will help you when filtering your list.',
  'corporateAccounts.infograph.vehicles.cards.1.title': 'SET VEHICLE NAME',
  'corporateAccounts.infograph.vehicles.cards.2.body':
    'Once you have saved the vehicle, your drivers will be able to search for and add it to their app profile.',
  'corporateAccounts.infograph.vehicles.cards.2.title': 'START PARKING',
  'corporateAccounts.infograph.vehicles.note':
    'Your drivers will be able to only add corporate vehicles that are entered into this list. Vehicles cannot be linked to a specific driver with the portal.',
  'corporateAccounts.invoiceIdValidation.invalidError': 'Not a valid Salesforce ID',
  'corporateAccounts.languages.de': 'German',
  'corporateAccounts.languages.fr': 'French',
  'corporateAccounts.languages.gb': 'English (GB)',
  'corporateAccounts.languages.glb': 'Global',
  'corporateAccounts.languages.it': 'Italian',
  'corporateAccounts.languages.nl': 'Dutch',
  'corporateAccounts.languages.us': 'English',
  'corporateAccounts.menuLeft.account': 'Account',
  'corporateAccounts.menuLeft.accounts': 'Accounts',
  'corporateAccounts.menuLeft.adminUsers': 'Users',
  'corporateAccounts.menuLeft.admins': 'Admins',
  'corporateAccounts.menuLeft.auditLogs': 'Audit logs',
  'corporateAccounts.menuLeft.billing': 'Billing & subscription',
  'corporateAccounts.menuLeft.creditAccount': 'Credit account',
  'corporateAccounts.menuLeft.drivers': 'Drivers',
  'corporateAccounts.menuLeft.monthlyStatements': 'Monthly statements',
  'corporateAccounts.menuLeft.payments': 'Payment methods',
  'corporateAccounts.menuLeft.reports': 'Reports',
  'corporateAccounts.menuLeft.sessions': 'Parking history',
  'corporateAccounts.menuLeft.staffUsers': 'PBP staff users',
  'corporateAccounts.menuLeft.superAdminHome': 'Superadmin home',
  'corporateAccounts.menuLeft.trigger': 'Collapse sidebar',
  'corporateAccounts.menuLeft.vehicles': 'Vehicles',
  'corporateAccounts.month.1': 'January',
  'corporateAccounts.month.10': 'October',
  'corporateAccounts.month.11': 'November',
  'corporateAccounts.month.12': 'December',
  'corporateAccounts.month.2': 'February',
  'corporateAccounts.month.3': 'March',
  'corporateAccounts.month.4': 'April',
  'corporateAccounts.month.5': 'May',
  'corporateAccounts.month.6': 'June',
  'corporateAccounts.month.7': 'July',
  'corporateAccounts.month.8': 'August',
  'corporateAccounts.month.9': 'September',
  'corporateAccounts.monthlyStatements.downloadStatement': 'Download Parking Statement',
  'corporateAccounts.monthlyStatements.generatedOn': 'Generated on',
  'corporateAccounts.monthlyStatements.title': 'Monthly Statements',
  'corporateAccounts.notFound': 'Page not found',
  'corporateAccounts.notification.reportReady.description':
    'Exported monthly reports will be available under ‘Monthly reports’ for 72 hours.',
  'corporateAccounts.notification.reportReady.title': 'Your report is ready',
  'corporateAccounts.notify.error.auditLogs': 'There was an error getting audit logs',
  'corporateAccounts.notify.error.downloadMonthlyStatement':
    'Encountered an error downloading the monthly parking statement',
  'corporateAccounts.notify.error.getMonthlyStatements':
    'Encountered an error loading monthly parking statements',
  'corporateAccounts.onboarding.actions.openPayByPhone': 'Open PayByPhone',
  'corporateAccounts.onboarding.activationFailed': 'Unable to link your account',
  'corporateAccounts.onboarding.activationFailed.message':
    "We couldn't link your account due to a technical issue. Please try again later. If the error persists, contact your manager.",
  'corporateAccounts.onboarding.addDriverStep.description':
    'Add drivers to your corporate account.',
  'corporateAccounts.onboarding.addDriverStep.title': 'Invite drivers',
  'corporateAccounts.onboarding.addVehicleStep.description':
    'Set up a list of authorized business vehicles.',
  'corporateAccounts.onboarding.addVehicleStep.title': 'Add vehicles',
  'corporateAccounts.onboarding.corporationNotFound': 'Corporation does not exist',
  'corporateAccounts.onboarding.corporationNotFound.message':
    'The corporation cannot be found. Please try again later. If the issue persists contact your manager for assistance in resolving the issue.',
  'corporateAccounts.onboarding.driverAlreadyActive': 'Account already linked',
  'corporateAccounts.onboarding.driverAlreadyActive.message':
    'Your account has already been linked to a corporation.',
  'corporateAccounts.onboarding.driverNotFound': 'Could not find the driver!',
  'corporateAccounts.onboarding.driverNotFound.message':
    'You need to have a PayByPhone account to accept the invitation. Please register for PayByPhone and ask your company to invite you again.',
  'corporateAccounts.onboarding.internalError': 'Something went wrong',
  'corporateAccounts.onboarding.internalError.message':
    'We were unable to process your request at this time. Please try again later. If the issue persists contact your manager for assistance in resolving the issue.',
  'corporateAccounts.onboarding.invitationExpired': 'Invitation link expired',
  'corporateAccounts.onboarding.invitationExpired.message':
    'Please ask your company to resend the invitation.',
  'corporateAccounts.onboarding.inviteCodeNotFound': 'Invitation code was not found',
  'corporateAccounts.onboarding.inviteCodeNotFound.message':
    'Please contact your manager to obtain a new link to complete the activation process.',
  'corporateAccounts.onboarding.inviteCodeNotValid': 'Invitation code is not valid',
  'corporateAccounts.onboarding.inviteCodeNotValid.message':
    'Please contact your manager to obtain a new link to complete the activation process.',
  'corporateAccounts.onboarding.logInToStartParking': 'Log in to PayByPhone to start parking!',
  'corporateAccounts.onboarding.progressBar.description':
    'We created a quick checklist to get you started.',
  'corporateAccounts.onboarding.progressBar.title': 'Get Started!',
  'corporateAccounts.onboarding.registration.formDescription':
    '{companyName} has invited you to use PayByPhone App for parking.',
  'corporateAccounts.onboarding.registration.formTitle': 'Complete your registration',
  'corporateAccounts.onboarding.registrationRequired': 'Account not found',
  'corporateAccounts.onboarding.registrationRequired.message':
    'You need to have a PayByPhone account to accept the invitation. Please sign up for PayByPhone and ask your company to invite you again. If the error persists, contact your manager.',
  'corporateAccounts.onboarding.setupAccountStep.description':
    'Company name and address for invoicing',
  'corporateAccounts.onboarding.setupAccountStep.title': 'Set up account',
  'corporateAccounts.onboarding.setupPaymentStep.description':
    'Your drivers will use this payment method to pay for parking.',
  'corporateAccounts.onboarding.setupPaymentStep.title': 'Enter payment details',
  'corporateAccounts.onboarding.socialSignIn.instruction':
    'If you have an existing account through social login and want to use the same account, then sign in with',
  'corporateAccounts.onboarding.success': 'Account successfully linked',
  'corporateAccounts.onboarding.success.carousel1.text':
    'Your business vehicles will be marked as "Business Approved"',
  'corporateAccounts.onboarding.success.carousel1.title': 'Add your business vehicles',
  'corporateAccounts.onboarding.success.carousel2.text':
    "Payment method is already setup, you don't have to add it manually.",
  'corporateAccounts.onboarding.success.carousel2.title': 'Company payment for parking',
  'corporateAccounts.onboarding.success.carousel3.subText':
    '*SMS fees may apply depending on your region.',
  'corporateAccounts.onboarding.success.carousel3.text': 'Keep it active to avoid parking fines.',
  'corporateAccounts.onboarding.success.carousel3.title': 'SMS reminders',
  'corporateAccounts.onboarding.success.message':
    'Your account has been linked to {companyName}. Log in to PayByPhone to start parking.',
  'corporateAccounts.onboarding.success.notification':
    'Account created successfully! Your account is now linked with {companyName}!',
  'corporateAccounts.onboarding.watchProductDemoVideo': 'Watch our product demo video.',
  'corporateAccounts.onboarding.welcomeModal.actions.getStarted': "Let's go",
  'corporateAccounts.onboarding.welcomeModal.description':
    'PayByPhone Business is easy to set up and use. Watch the video to get familiar with the portal.',
  'corporateAccounts.onboarding.welcomeModal.frequentlyAskedQuestions': 'Take a look at our FAQs',
  'corporateAccounts.onboarding.welcomeModal.title': 'Get started with PayByPhone Business',
  'corporateAccounts.pageDeprecated': 'The page is deprecated, deleted, or does not exist at all',
  'corporateAccounts.passwordValidation.defaultError':
    'Password you entered does not meet the requirements. Please try again',
  'corporateAccounts.passwordValidation.lengthError':
    'Password must be between 8 and 51 characters long.',
  'corporateAccounts.passwordValidation.lowerCaseError':
    'Password must contain at least one lowercase letter.',
  'corporateAccounts.passwordValidation.missingNumberError':
    'Password must contain at least one number.',
  'corporateAccounts.passwordValidation.missingSpecialCharacterError':
    'Password must contain at least one special character.',
  'corporateAccounts.passwordValidation.upperCaseError':
    'Password must contain at least one uppercase letter.',
  'corporateAccounts.payByPhone': 'PayByPhone Technologies',
  'corporateAccounts.payment.actions.restrictedEdit.content':
    'This action is temporarily restricted, however your PaybyPhone account manager will be able to assist you with this process so that your drivers can start using a new payment method right away!',
  'corporateAccounts.payment.actions.restrictedEdit.title': 'Payment Method Updates',
  'corporateAccounts.paymentMethod.add.success': 'Payment added',
  'corporateAccounts.paymentMethod.delete.success': 'Payment removed',
  'corporateAccounts.paymentMethod.infograph.cards.0.title': 'Add New Payment Method',
  'corporateAccounts.paymentMethod.infograph.cards.1.body':
    'Optional: Update the name of your payment method via the card edit menu.',
  'corporateAccounts.paymentMethod.infograph.cards.1.title': 'Update Card Name',
  'corporateAccounts.paymentMethod.infograph.cards.2.body':
    'Activated drivers will automatically see these payment methods on their app.',
  'corporateAccounts.paymentMethod.infograph.cards.2.title': 'Automatic Sharing',
  'corporateAccounts.paymentMethod.infograph.subcontent':
    "Payment methods can only be used by drivers to pay for parking with 'Business Approved' vehicles. These are vehicles that have been added to your vehicles list.",
  'corporateAccounts.paymentMethod.update.success': 'Payment updated',
  'corporateAccounts.payments.actions.addAndAgree': 'Add & agree',
  'corporateAccounts.payments.actions.addPayment': 'Add payment',
  'corporateAccounts.payments.actions.editPayment': 'Edit Payment',
  'corporateAccounts.payments.cardCountryCode': 'Card Country Code',
  'corporateAccounts.payments.cardCvc': 'Card CVV',
  'corporateAccounts.payments.cardName': 'Card Name',
  'corporateAccounts.payments.cardNumber': 'Card Number',
  'corporateAccounts.payments.cardType': 'Card Type',
  'corporateAccounts.payments.create.error.cardAlreadyExpired': 'Card has expired.',
  'corporateAccounts.payments.create.error.cardNumberInvalid': 'Invalid card number.',
  'corporateAccounts.payments.create.error.cardNumberInvalidFormat': 'Invalid card number format.',
  'corporateAccounts.payments.create.error.cardNumberRequired': 'Card number is required.',
  'corporateAccounts.payments.create.error.cardTypeNotSupported': 'Card type is not supported.',
  'corporateAccounts.payments.create.error.cardValidationFailed': 'Card validation failed.',
  'corporateAccounts.payments.create.error.countryCodeNotSupported':
    'Country code is not supported.',
  'corporateAccounts.payments.create.error.countryCodeRequired': 'Country code is required.',
  'corporateAccounts.payments.create.error.cvvInvalidFormat': 'Invalid CVV format.',
  'corporateAccounts.payments.create.error.cvvRequired': 'CVV is required.',
  'corporateAccounts.payments.create.error.expiryMonthInvalid': 'Expiration month is invalid.',
  'corporateAccounts.payments.create.error.expiryMonthRequired': 'Expiration month is required.',
  'corporateAccounts.payments.create.error.expiryYearRequired': 'Expiration year is required.',
  'corporateAccounts.payments.create.error.expiryYearTooFarInTheFuture':
    'Expiration year is too far in the future.',
  'corporateAccounts.payments.create.error.limitExceeded':
    'You have reached your payment account limit. To learn more about how to increase your limit, please contact sales.',
  'corporateAccounts.payments.create.error.nameOnCardInvalidFormat': 'Name on card is invalid.',
  'corporateAccounts.payments.create.error.nameOnCardRequired': 'Name on card is required.',
  'corporateAccounts.payments.create.error.paymentAccountAlreadyExists':
    'Payment account already exists.',
  'corporateAccounts.payments.create.error.paymentAccountNotFound':
    'Payment account could not be found.',
  'corporateAccounts.payments.create.error.unhandledError':
    'We were unable to add your payment method.',
  'corporateAccounts.payments.create.error.zipCodeInvalidFormat':
    'Invalid ZIP / postal code format.',
  'corporateAccounts.payments.create.success':
    'Payment method added. New payment methods can take up to a minute before appearing in your payment methods list.',
  'corporateAccounts.payments.creditAccountBanner.button.cta': 'Go to credit account page',
  'corporateAccounts.payments.creditAccountBanner.description':
    'We are excited to launch credit accounts with Allstar Business Solutions Limited, a dedicated credit line for all your parking expenses. We encourage you to set up your credit account as soon as possible to ensure a smooth transition.',
  'corporateAccounts.payments.creditAccountBanner.description.cta': 'Learn more',
  'corporateAccounts.payments.creditAccountBanner.title':
    'Replacing payment cards with credit accounts',
  'corporateAccounts.payments.cvc': 'CVV/CVC',
  'corporateAccounts.payments.description':
    "Activated drivers will automatically see these payment methods in their apps, they don't need to enter card details manually. These payment methods can only be used to pay for parking of",
  'corporateAccounts.payments.description.link': 'business-approved vehicles.',
  'corporateAccounts.payments.emptyState.addCreditCardBtn': 'Add Credit Card',
  'corporateAccounts.payments.emptyState.description':
    'The card will only be used to pay for parking and a separate invoice with subscription fees will be emailed to you at the beginning of each month. Your drivers won’t have access to card details and the card won’t be changed until they start parking.',
  'corporateAccounts.payments.emptyState.title': 'Add a payment method',
  'corporateAccounts.payments.emptyState.videoLink': 'How to add a credit card',
  'corporateAccounts.payments.error.delete.notFound':
    'Payment method could not be found, please refresh your browser.',
  'corporateAccounts.payments.expiry': 'Expiry',
  'corporateAccounts.payments.expiryMonth': 'Expiry Month',
  'corporateAccounts.payments.expiryYear': 'Expiry Year',
  'corporateAccounts.payments.form.cardCountryCodePlaceholder': 'Select a country code',
  'corporateAccounts.payments.form.cardCountryCodeRequired': 'Please select a country code',
  'corporateAccounts.payments.form.cardCvcRequired': 'CVV/CVC is required',
  'corporateAccounts.payments.form.cardCvcValidation': 'CVV/CVC is invalid',
  'corporateAccounts.payments.form.cardExpiryRequired': 'Expiration date is required',
  'corporateAccounts.payments.form.cardExpiryValidation': 'Expiration date is invalid ',
  'corporateAccounts.payments.form.cardNameLength':
    'Card Name cannot be more than {limit} characters',
  'corporateAccounts.payments.form.cardNamePlaceholder': 'Add a card name',
  'corporateAccounts.payments.form.cardNameRequired': 'Please add a card name',
  'corporateAccounts.payments.form.cardNumberPlaceholder': 'Card number',
  'corporateAccounts.payments.form.cardNumberRequired': 'Credit card number is required',
  'corporateAccounts.payments.form.cardNumberValidation': 'Credit Card is invalid',
  'corporateAccounts.payments.form.cardTypePlaceholder': 'Card type',
  'corporateAccounts.payments.form.cardTypeRequired': 'Please choose a card type',
  'corporateAccounts.payments.form.creditCardTypeNotSupported': '{creditCardType} is not supported',
  'corporateAccounts.payments.form.disclaimer': 'Placeholder for updating card disclaimer',
  'corporateAccounts.payments.form.expiryMonthPlaceholder': 'Select expiry month',
  'corporateAccounts.payments.form.expiryYearPlaceholder': 'YYYY',
  'corporateAccounts.payments.form.nameOnCardFormat':
    'Name on card cannot have invalid characters < > \' " or &',
  'corporateAccounts.payments.form.nameOnCardLength':
    'Name on card cannot be less than 2 or more than 70 characters',
  'corporateAccounts.payments.form.nameOnCardPlaceholder': 'Name on card',
  'corporateAccounts.payments.form.nameOnCardRequired': 'Please enter the card name',
  'corporateAccounts.payments.form.primaryCountryOfUse': 'Primary Country of Use',
  'corporateAccounts.payments.form.storedCredentialAgreement': 'Stored Credential Agreement',
  'corporateAccounts.payments.form.termsAgreement':
    'By adding this payment method you are agreeing to our',
  'corporateAccounts.payments.form.update': 'Update Credit Card Information',
  'corporateAccounts.payments.form.zipCodePlaceholder': 'Zip/postal code',
  'corporateAccounts.payments.form.zipCodeRequired': 'Please enter a zip/postal code',
  'corporateAccounts.payments.generic.error': 'There was an issue updating your payment method',
  'corporateAccounts.payments.main.description': 'Business Payment Methods for parking fees.',
  'corporateAccounts.payments.modal.delete.areYouSure':
    'Are you sure you want to delete the following payment method?',
  'corporateAccounts.payments.modal.delete.lastCard':
    'Your drivers will no longer be able charge parking transactions to your company!',
  'corporateAccounts.payments.nameOnCard': 'Name on Card',
  'corporateAccounts.payments.paymentAccountId': 'Payment Account Id',
  'corporateAccounts.payments.spa.challenge.title': 'SCA Challenge flow',
  'corporateAccounts.payments.spa.title': 'Add New Payment Method',
  'corporateAccounts.payments.spa.title.edit': 'Edit Payment Method',
  'corporateAccounts.payments.spa.verification.content':
    'For added security, you will be redirected to your bank before adding this credit card on file.',
  'corporateAccounts.payments.spa.verification.title': 'Verification required',
  'corporateAccounts.payments.title': 'Payment Methods',
  'corporateAccounts.payments.toast.paymentMethodDeleted': 'Payment method deleted',
  'corporateAccounts.payments.update.success': 'Card name updated',
  'corporateAccounts.payments.zipCode': 'Zip/Postal Code',
  'corporateAccounts.reports.actions.clear': 'Clear',
  'corporateAccounts.reports.actions.load': 'Load',
  'corporateAccounts.reports.cardName': 'Card Name',
  'corporateAccounts.reports.cost': 'Total Amount',
  'corporateAccounts.reports.costCenter': 'Cost Center',
  'corporateAccounts.reports.costInfo':
    'Cost of parking is not final until payment has been captured.',
  'corporateAccounts.reports.dateRange': 'Date Range',
  'corporateAccounts.reports.dateRange.endDate': 'End date',
  'corporateAccounts.reports.dateRange.startDate': 'Start date',
  'corporateAccounts.reports.day': 'Day',
  'corporateAccounts.reports.dayOfWeek': 'Day',
  'corporateAccounts.reports.email': 'Email',
  'corporateAccounts.reports.firstName': 'First Name',
  'corporateAccounts.reports.lastName': 'Last Name',
  'corporateAccounts.reports.licensePlate': 'License Plate',
  'corporateAccounts.reports.localEndDateTime': 'Expiry Time',
  'corporateAccounts.reports.localStartDateTime': 'Start Time',
  'corporateAccounts.reports.lotId': 'Parking Lot ID',
  'corporateAccounts.reports.main.title': 'Reports',
  'corporateAccounts.reports.maskedCardNumber': 'Payment Details Masked',
  'corporateAccounts.reports.month': 'Month',
  'corporateAccounts.reports.month.placeholder': 'Please select',
  'corporateAccounts.reports.monthly': 'Monthly',
  'corporateAccounts.reports.monthly.generateReport': 'Generate report',
  'corporateAccounts.reports.monthly.infoNotice':
    'Exported monthly reports will be available here for 72 hours.',
  'corporateAccounts.reports.monthly.last30days': 'Last 30 Days',
  'corporateAccounts.reports.monthly.last7days': 'Last 7 Days',
  'corporateAccounts.reports.monthly.last90days': 'Last 90 Days',
  'corporateAccounts.reports.monthly.reportJob.status.completed': 'Completed',
  'corporateAccounts.reports.monthly.reportJob.status.failed': 'Failed',
  'corporateAccounts.reports.monthly.reportJob.status.pending': 'Pending',
  'corporateAccounts.reports.monthly.reportType': 'Report Type',
  'corporateAccounts.reports.monthly.timeExported': 'Time Exported',
  'corporateAccounts.reports.monthly.toast.generatingReport.content':
    'Report is being generated and will be available in your exports when ready.',
  'corporateAccounts.reports.monthly.toast.generatingReport.title': 'Preparing your report',
  'corporateAccounts.reports.monthlyReports': 'Monthly Reports',
  'corporateAccounts.reports.netTotal': 'Total Amount (net)',
  'corporateAccounts.reports.parkingLotName': 'Parking Lot Name',
  'corporateAccounts.reports.parkingMinutes': 'Parking Minutes',
  'corporateAccounts.reports.parkingRegionName': 'Parking Region Name',
  'corporateAccounts.reports.parkingStatus': 'Parking Status',
  'corporateAccounts.reports.parkingVendorName': 'Parking Vendor Name',
  'corporateAccounts.reports.paymentDate': 'Payment Date',
  'corporateAccounts.reports.paymentStatus': 'Payment Status',
  'corporateAccounts.reports.phone': 'Phone',
  'corporateAccounts.reports.phoneNumber': 'Phone Number',
  'corporateAccounts.reports.searchInput.placeholder':
    'Search by license plate, driver first and last name, parking vendor name',
  'corporateAccounts.reports.tax': 'Tax',
  'corporateAccounts.reports.transactionId': 'Transaction ID',
  'corporateAccounts.reports.transactions': 'Transaction report',
  'corporateAccounts.reports.transactions.chooseDateRange': 'Choose a date range',
  'corporateAccounts.reports.transactions.noResults':
    'No results to display for the selected date range',
  'corporateAccounts.reports.transactions.timeout.modal.content':
    'Try shortening your date range or running a Monthly Report instead.',
  'corporateAccounts.reports.transactions.timeout.modal.title': 'Timed out',
  'corporateAccounts.reports.vehicleCountry': 'Vehicle Country',
  'corporateAccounts.reports.vehicleVin': 'Vehicle VIN',
  'corporateAccounts.search.validation.minimum': 'Search needs 3 characters',
  'corporateAccounts.selfRegistration.actions.signIn': 'Sign in',
  'corporateAccounts.selfRegistration.activation.description':
    'Your drivers can now park with PayByPhone Business, and your billing cycle will start today. If you have any questions, don’t hesitate to contact our support team via the chat button in the bottom right corner.',
  'corporateAccounts.selfRegistration.activation.feedback':
    'How easy was it to set up your account?',
  'corporateAccounts.selfRegistration.activation.feedback.difficult': 'Difficult',
  'corporateAccounts.selfRegistration.activation.feedback.easy': 'Easy',
  'corporateAccounts.selfRegistration.activation.feedback.expected': 'As expected',
  'corporateAccounts.selfRegistration.activation.supportLink': 'Click here to contact support',
  'corporateAccounts.selfRegistration.activation.title':
    'Your PayByPhone Business account is now set up!',
  'corporateAccounts.selfRegistration.emailVerificationInstruction':
    'Check your email and click on the link to activate your account. Check your spam folder if you haven’t received a verification email in 1-2 minutes.',
  'corporateAccounts.selfRegistration.form.agreement':
    'By checking this box, I agree to {subscriptionAgreement} and that I may be charged the aforementioned price per month per driver, and acknowledge the {privacyPolicy}',
  'corporateAccounts.selfRegistration.form.creditCardNote':
    'No credit card required until you are ready to park',
  'corporateAccounts.selfRegistration.form.title': 'Create your PayByPhone Business account',
  'corporateAccounts.selfRegistration.marketing.businessBenefits.one':
    'No more time-consuming expense reports',
  'corporateAccounts.selfRegistration.marketing.businessBenefits.three':
    'Drivers pay for parking in just 30 seconds',
  'corporateAccounts.selfRegistration.marketing.businessBenefits.two':
    'Real-time visibility of who is parking where and when',
  'corporateAccounts.selfRegistration.marketing.businessesTrust':
    'Trusted by businesses large and small',
  'corporateAccounts.selfRegistration.marketing.title':
    'Let PayByPhone Business simplify your fleet parking management.',
  'corporateAccounts.selfRegistration.resendEmail.button': 'Resend email',
  'corporateAccounts.selfRegistration.resendEmail.failed': 'Failed to send verification email',
  'corporateAccounts.selfRegistration.resendEmail.success': 'Verification email sent',
  'corporateAccounts.selfRegistration.success.supportContact': 'Contact support',
  'corporateAccounts.selfRegistration.success.supportMessage':
    'Having trouble? We’re here to help!',
  'corporateAccounts.selfRegistration.success.title': 'Verify your email address',
  'corporateAccounts.selfRegistration.verification.completeCaptcha':
    'Please complete the captcha below',
  'corporateAccounts.selfRegistration.verification.failed': 'Could not be verified',
  'corporateAccounts.selfRegistration.verification.failed.message':
    'The verification request could not be completed at this time. Please try again later.',
  'corporateAccounts.selfRegistration.verification.linkExpired': 'Verification Link expired',
  'corporateAccounts.selfRegistration.verification.linkExpired.message':
    'The verification link is not valid anymore. Please resend a verification email to complete registration.',
  'corporateAccounts.selfRegistration.verification.resendVerification': 'Resend verification email',
  'corporateAccounts.selfRegistration.verification.success': 'Verified',
  'corporateAccounts.selfRegistration.verification.success.message':
    'You have successfully verified your account',
  'corporateAccounts.selfRegistration.verification.success.portalLink':
    'Log in to PayByPhone Business',
  'corporateAccounts.server.domainErrors.RestrictedCorporateClient':
    'Access to the corporate client is restricted due to its current status.',
  'corporateAccounts.sessions.main.description':
    'The data on this page updates every 1 hour and contains the last 72 hours of parking sessions.',
  'corporateAccounts.sessions.title': 'Parking Sessions',
  'corporateAccounts.staff.actions.add': 'Add Staff',
  'corporateAccounts.staff.search': 'Search by name, email, or ID',
  'corporateAccounts.staff.title': 'Staff Management',
  'corporateAccounts.staff.title.add': 'Add staff',
  'corporateAccounts.subscription.cancelAccount': 'Need to cancel your account?',
  'corporateAccounts.subscription.contactCustomerSuccess':
    '{contact} our customer success team if you need to close your account.',
  'corporateAccounts.subscription.contactForPricing':
    'Contact customer support for pricing information.',
  'corporateAccounts.subscription.currentPlanTitle': 'Current Plan',
  'corporateAccounts.subscription.editPlan': 'Edit Plan - Contact Sales',
  'corporateAccounts.subscription.error.contactSupport':
    'Unable to load the pricing information. Contact customer support.',
  'corporateAccounts.subscription.error.tryAgain':
    'Unable to load the pricing information. Please wait a moment and try again.',
  'corporateAccounts.subscription.memberSince': 'Member since',
  'corporateAccounts.subscription.monthlyPlan': 'Monthly plan',
  'corporateAccounts.subscription.nextBillingCycle': 'Next billing cycle',
  'corporateAccounts.subscription.perDriver': 'per driver',
  'corporateAccounts.subscription.terms':
    'PayByPhone Business Subscription {termsAndConditions} and {privacyPolicy}',
  'corporateAccounts.topBar.helpMenu.title': 'Need help?',
  'corporateAccounts.topBar.profileMenu.account': 'Account',
  'corporateAccounts.topBar.profileMenu.changeCorporations': 'Switch Accounts',
  'corporateAccounts.topBar.profileMenu.contact': 'Contact Us',
  'corporateAccounts.topBar.profileMenu.editProfile': 'Edit Profile',
  'corporateAccounts.topBar.profileMenu.email': 'Email',
  'corporateAccounts.topBar.profileMenu.hello': 'Hello',
  'corporateAccounts.topBar.profileMenu.help': 'Visit our FAQ',
  'corporateAccounts.topBar.profileMenu.home': 'Home',
  'corporateAccounts.topBar.profileMenu.phone': 'Phone',
  'corporateAccounts.topBar.profileMenu.role': 'Profile',
  'corporateAccounts.topBar.profileMenu.signOut': 'Sign out',
  'corporateAccounts.topBar.profileMenu.support': 'Support',
  'corporateAccounts.user.activation.error':
    'There was an issue with your activation. Please reach out to your administrator.',
  'corporateAccounts.user.activation.success': 'You have successfully activated your account.',
  'corporateAccounts.user.limitExceeded.error':
    'You have reached your user limit. To learn more about how to increase your limit, please contact sales.',
  'corporateAccounts.user.login.success': 'You have successfully logged in.',
  'corporateAccounts.user.registration.duplicateEmail':
    'User profile with this email already exists.',
  'corporateAccounts.user.registration.internalError':
    'We encountered an issue while processing your request. Please try again later. If the problem persists, please contact PayByPhone sales for assistance.',
  'corporateAccounts.user.registration.userEmailDomainNotValid':
    'Registration from this domain is blocked. Please try again with a different email address.',
  'corporateAccounts.users.role.customer_success': 'Customer Success',
  'corporateAccounts.users.role.customer_support_tier_2': 'Customer Support Tier 2',
  'corporateAccounts.users.role.super_admin': 'Super Admin',
  'corporateAccounts.vehicle.add.duplicate.error':
    'A vehicle with this license plate already exists.',
  'corporateAccounts.vehicle.add.error': 'We were unable to add your vehicle.',
  'corporateAccounts.vehicle.add.invalidCharacters': 'License plate contains invalid characters',
  'corporateAccounts.vehicle.add.success': 'Vehicle added',
  'corporateAccounts.vehicle.delete.error': 'We were unable to delete your vehicle.',
  'corporateAccounts.vehicle.delete.success': 'Vehicle removed',
  'corporateAccounts.vehicle.limitExceeded.error':
    'You have reached your vehicle limit. To learn more about how to increase your limit, please contact sales.',
  'corporateAccounts.vehicle.update.error': 'We were unable to update your vehicle.',
  'corporateAccounts.vehicle.update.success': 'Vehicle updated',
  'corporateAccounts.vehicles.actions.addVehicle': 'Add vehicle',
  'corporateAccounts.vehicles.actions.deleteVehicleMessage': 'Delete this vehicle?',
  'corporateAccounts.vehicles.actions.deleteVehicles': 'Delete Vehicles',
  'corporateAccounts.vehicles.bulkDelete.success':
    '{numOfDeletedVehicles} Vehicles have been deleted',
  'corporateAccounts.vehicles.country': 'Country',
  'corporateAccounts.vehicles.csvDownload.country': 'Country (required)',
  'corporateAccounts.vehicles.csvDownload.jurisdiction': 'State / Province (required)',
  'corporateAccounts.vehicles.csvDownload.licensePlate': 'License Plate Number (required)',
  'corporateAccounts.vehicles.csvDownload.vin': 'VIN [Vehicle Identification Number] (optional)',
  'corporateAccounts.vehicles.emptyState.ImportVehiclesBtn': 'Import vehicles',
  'corporateAccounts.vehicles.emptyState.description':
    'Create a list of business-approved vehicles that can be parked with a business payment method.',
  'corporateAccounts.vehicles.emptyState.videoLink': 'How to add vehicles',
  'corporateAccounts.vehicles.export.error.modal.description':
    'Could not export vehicles list, please try again.',
  'corporateAccounts.vehicles.export.error.modal.title': 'Vehicles list export error',
  'corporateAccounts.vehicles.export.fileName': 'VehicleList',
  'corporateAccounts.vehicles.export.modal.description':
    'Exported data will be generated based on the filters currently applied to your vehicles list.',
  'corporateAccounts.vehicles.export.modal.title': 'Vehicles list export',
  'corporateAccounts.vehicles.export.success.notification.description':
    'Your vehicles list has been exported.',
  'corporateAccounts.vehicles.export.success.notification.title': 'Vehicles list exported',
  'corporateAccounts.vehicles.form.add': 'Add vehicle',
  'corporateAccounts.vehicles.form.countryRequired': 'Please select a country',
  'corporateAccounts.vehicles.form.edit': 'Edit vehicle',
  'corporateAccounts.vehicles.form.licensePlatePlaceholder': 'Add license plate',
  'corporateAccounts.vehicles.form.licensePlateRequired': 'Please enter a license plate number',
  'corporateAccounts.vehicles.form.licensePlateValidation':
    'License plate does not appear to be valid',
  'corporateAccounts.vehicles.form.new': 'Add new vehicle',
  'corporateAccounts.vehicles.form.regionRequired': 'Please select a region',
  'corporateAccounts.vehicles.form.update': 'Update Vehicle',
  'corporateAccounts.vehicles.form.vinValidation': 'Not a valid VIN number',
  'corporateAccounts.vehicles.import.description':
    'Follow the steps below to add multiple vehicles to your account.',
  'corporateAccounts.vehicles.import.fileName': 'VehiclesImport',
  'corporateAccounts.vehicles.import.header.countryISO': 'Country ISO-3166 alpha-2 (required)',
  'corporateAccounts.vehicles.import.header.licensePlate': 'License Plate Number (required)',
  'corporateAccounts.vehicles.import.header.numberPlate': 'Number Plate (required)',
  'corporateAccounts.vehicles.import.header.stateISO':
    'State ISO 3166-2:US Province ISO 3166-2:CA (required)',
  'corporateAccounts.vehicles.import.header.vin': 'VIN [Vehicle Identification Number]',
  'corporateAccounts.vehicles.import.step.2.content': 'Fill out the template and save it.',
  'corporateAccounts.vehicles.import.title': 'Vehicles - Bulk Import',
  'corporateAccounts.vehicles.infograph.cards.0.title': 'Add Vehicles',
  'corporateAccounts.vehicles.infograph.cards.1.body':
    'Ask your drivers to add the corresponding vehicles to their PayByPhone app.',
  'corporateAccounts.vehicles.infograph.cards.1.title': 'Notify your drivers',
  'corporateAccounts.vehicles.infograph.cards.2.body':
    'Active drivers can use your payment methods to park vehicles you have added.',
  'corporateAccounts.vehicles.infograph.cards.2.title': 'Start parking',
  'corporateAccounts.vehicles.infograph.subcontent':
    'Drivers can only use your payment methods to park vehicles on this list. ',
  'corporateAccounts.vehicles.licensePlate': 'License Plate',
  'corporateAccounts.vehicles.modal.content.bulkDelete':
    'Are you sure you would like to delete the selected vehicles?',
  'corporateAccounts.vehicles.modal.title.bulkDelete': 'Delete Vehicles?',
  'corporateAccounts.vehicles.title': 'Vehicles',
  'corporateAccounts.vehicles.vin': 'VIN',
  'corporateAcctounts.vehicles.description':
    'Drivers need to add these vehicles to their PayByPhone app, they will not appear automatically.',
};
