import React from 'react';

const ChangeCorporationIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.99967 1.66659C6.2633 1.66659 5.66634 2.26354 5.66634 2.99992H8.33301C8.33301 2.26354 7.73605 1.66659 6.99967 1.66659ZM6.99967 0.333252C5.52692 0.333252 4.33301 1.52716 4.33301 2.99992H2.33301C1.22844 2.99992 0.333008 3.89535 0.333008 4.99992V11.6666C0.333008 12.7712 1.22844 13.6666 2.33301 13.6666H11.6663C12.7709 13.6666 13.6663 12.7712 13.6663 11.6666V8.33325V4.99992C13.6663 3.89535 12.7709 2.99992 11.6663 2.99992H9.66634C9.66634 1.52716 8.47243 0.333252 6.99967 0.333252ZM12.333 7.66659V4.99992C12.333 4.63173 12.0345 4.33325 11.6663 4.33325H2.33301C1.96482 4.33325 1.66634 4.63173 1.66634 4.99992V7.66659H4.33301V6.99992C4.33301 6.63173 4.63148 6.33325 4.99967 6.33325H8.99967C9.36786 6.33325 9.66634 6.63173 9.66634 6.99992V7.66659H12.333ZM9.66634 8.99992H12.333V11.6666C12.333 12.0348 12.0345 12.3333 11.6663 12.3333H2.33301C1.96482 12.3333 1.66634 12.0348 1.66634 11.6666V8.99992H4.33301V9.66659C4.33301 10.0348 4.63148 10.3333 4.99967 10.3333H8.99967C9.36786 10.3333 9.66634 10.0348 9.66634 9.66659V8.99992ZM8.33301 8.33325V7.66659H5.66634V8.33325V8.99992H8.33301V8.33325Z"
      fill="#4D4D4D"
    />
  </svg>
);

export default ChangeCorporationIcon;
