import React from 'react';
import { Button } from 'antd';
import { CSVLink } from 'react-csv';
import useTranslation from 'utils/hooks/useTranslation';
import styles from './style.module.scss';

const DriverImportModalFooter = ({
  loading,
  importDisabled,
  onClose,
  onConfirm,
  validationErrors,
}) => {
  const { translateText } = useTranslation();

  if (loading) {
    return null;
  }

  return (
    <div className={styles.modalFooter}>
      {validationErrors && (
        <Button type="primary" className={styles.downloadButton}>
          <CSVLink
            data={validationErrors}
            filename={`${translateText('corporateAccounts.drivers.import.error.fileName')}.csv`}
          >
            {translateText('corporateAccounts.actions.downloadLogFile')}
          </CSVLink>
        </Button>
      )}
      <Button data-testid="cancelImport" onClick={onClose}>
        {translateText('corporateAccounts.actions.cancel')}
      </Button>
      <Button
        data-testid="confirmImport"
        type="primary"
        disabled={importDisabled}
        onClick={onConfirm}
      >
        {translateText('corporateAccounts.common.upload')}
      </Button>
    </div>
  );
};

export default DriverImportModalFooter;
