import { store } from 'initStore';
import { datadogLogs } from '@datadog/browser-logs';

export const ddLogWithContext = ({ type, message, context = {} }) => {
  const currentUser = store && store.getState().user;
  const { id, corporateClientId, corporationRole } = currentUser;

  datadogLogs.logger[type](message, {
    user: {
      id,
      corporateClientId,
      corporationRole,
    },
    ...context,
  });
};

export const ddErrorLogwithContext = (errorType = 'Request Error', context = {}) => {
  const currentUser = store && store.getState().user;
  const { id, corporateClientId, corporationRole } = currentUser;

  datadogLogs.logger.error(errorType, {
    user: {
      id,
      corporateClientId,
      corporationRole,
    },
    ...context,
  });
};

const errorLoggerUtil = {
  ddErrorLogwithContext,
};

export default errorLoggerUtil;
