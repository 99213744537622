import React from 'react';
import LanguageSelector from 'components/Layout/TopBar/LanguageSelector/index.js';
import { Logo } from 'components/Shared/Logos';

import styles from './style.module.scss';

const OnboardingPageHeader = () => {
  return (
    <div className={styles.OnboardingPageHeader}>
      <Logo />
      <LanguageSelector />
    </div>
  );
};

export default OnboardingPageHeader;
