import { useState } from 'react';
import { JURISDICTION_REQUIRED_COUNTRIES } from 'regions';

const flattenObject = obj => {
  let result = {};

  const recurse = (current, property = '') => {
    if (typeof current !== 'object' || current === null) {
      result[property] = current;
    } else {
      for (let key in current) {
        recurse(current[key], property === '' ? key : property + '.' + key);
      }
    }
  };

  recurse(obj);
  return result;
};

const useIsFormValid = ({ form }) => {
  const [isFormValid, setIsFormValid] = useState(false);

  const validateForm = () => {
    const allFields = form.getFieldsValue();
    const activeCountry = allFields?.address?.country;
    const optionalFields = ['extensionData.industry', 'address.address2'];

    if (activeCountry && !JURISDICTION_REQUIRED_COUNTRIES.includes(activeCountry))
      optionalFields.push('address.jurisdiction');
    if (activeCountry !== 'FR') optionalFields.push('taxNumber');

    const allFieldsFlattened = flattenObject(allFields);
    const nonOptionalFields = Object.keys(allFieldsFlattened).filter(
      fieldName => !optionalFields.includes(fieldName),
    );

    const isAllFieldsFilled = nonOptionalFields.every(fieldName => !!allFieldsFlattened[fieldName]);
    const isAllFieldsValid = !form.getFieldsError().some(({ errors }) => errors.length > 0);

    setIsFormValid(isAllFieldsFilled && isAllFieldsValid);
  };

  return { isFormValid, validateForm };
};

export default useIsFormValid;
