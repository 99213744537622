import amplitude from 'amplitude-js';
import { AMPLITUDE_KEY } from '../constants';

let amplitudeInitialized = false;

export const initializeAmplitude = () => {
  if (!amplitudeInitialized && AMPLITUDE_KEY) {
    amplitude.getInstance().init(AMPLITUDE_KEY);
    amplitudeInitialized = true;
  } else if (!AMPLITUDE_KEY) {
    console.log('[ERROR] Incorrect or missing Amplitude key');
  }
};

export const deinitializeAmplitude = () => {
  if (amplitudeInitialized) {
    amplitudeInitialized = false;
  }
};

export const setUserProperties = userProperties => {
  if (amplitudeInitialized && AMPLITUDE_KEY) {
    amplitude.getInstance().setUserProperties(userProperties);
  }
};

export const setUserId = userId => {
  if (amplitudeInitialized && AMPLITUDE_KEY) {
    amplitude.getInstance().setUserId(userId);
  }
};

export const sendEvent = (eventType, eventProperties) => {
  if (amplitudeInitialized && AMPLITUDE_KEY) {
    amplitude.getInstance().logEvent(eventType, eventProperties);
  }
};

export const sendEventAsync = (eventType, eventProperties) => {
  if (amplitudeInitialized && AMPLITUDE_KEY) {
    return new Promise((resolve, reject) => {
      amplitude.getInstance().logEvent(eventType, eventProperties, success => {
        if (success) {
          resolve();
        } else {
          console.log('[ERROR] Amplitude encountered an error');
          reject();
        }
      });
    });
  } else {
    return Promise.resolve();
  }
};
