import React, { useMemo } from 'react';
import { Menu, Dropdown } from 'antd';
import { connect } from 'react-redux';
import locales from 'locales';
import useAmplitude from 'utils/hooks/useAmplitude';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import { currentLanguages } from './languages';
import styles from '../style.module.scss';
import useTranslation from 'utils/hooks/useTranslation';

const LanguageSelector = ({ dispatch, settings: { locale }, user: { adminRole }, className }) => {
  const { sendAmplitudeEvent } = useAmplitude();
  const { translateText } = useTranslation();

  const MenuItems = useMemo(() => {
    return currentLanguages.map(loc => {
      if (loc.restricted && !adminRole) return null;

      const messages =
        loc.locale === 'glb-glb' ? locales['en-US'].messages : locales[loc.locale].messages;

      const translatedLanguage =
        messages[`corporateAccounts.languages.${loc.countryCode?.toLowerCase()}`];

      return (
        <Menu.Item key={loc.locale} data-testid={`language:${loc.locale}`}>
          <span
            role="img"
            aria-label={loc.language}
            className="mr-2"
            style={
              loc.locale === 'glb-glb'
                ? { display: 'inline-block' }
                : { display: 'inline-block', verticalAlign: 'sub' }
            }
          >
            {loc.icon}
          </span>
          <span>{translatedLanguage}</span>
        </Menu.Item>
      );
    });
  }, [adminRole]);

  const selectedLanguage = useMemo(() => {
    return currentLanguages.find(lng => {
      return lng.locale === locale;
    });
  }, [locale]);

  const changeLang = ({ key }) => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.languageSelected);
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'locale',
        value: key,
      },
    });
  };

  const langMenu = (
    <Menu
      data-testid="languageSelectorMenu"
      className={styles.menu}
      selectedKeys={[locale]}
      onClick={changeLang}
      alt="Language Selector"
    >
      {MenuItems}
    </Menu>
  );

  return (
    <Dropdown overlay={langMenu} trigger={['click']} className={className}>
      <div className={styles.dropdown} data-testid="languageSelectorTrigger">
        {translateText(
          `corporateAccounts.languages.${selectedLanguage.countryCode?.toLowerCase()}`,
        )}
      </div>
    </Dropdown>
  );
};

export default connect(({ settings, user }) => ({ settings, user }))(LanguageSelector);
