import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Button, Input, Select } from 'antd';
import isEmpty from 'lodash.isempty';
import { COUNTRIES } from 'constants.js';
import { REGION_CODES } from 'regions';
import { injectIntl } from 'react-intl';
import useTranslation from 'utils/hooks/useTranslation';
import useAmplitude from 'utils/hooks/useAmplitude';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import updateRegionLabel from 'utils/region';
import actions from 'redux/vehicles/actions';

const { Option } = Select;

const VehicleForm = ({
  formData,
  initialValues = {},
  formErrors,
  submitting,
  visible,
  isPlateValid,
  isSuccess,
  onCancel,
  onSubmit,
  resetFormErrors,
}) => {
  const { sendAmplitudeEvent } = useAmplitude();
  const { translateText } = useTranslation();
  const [form] = Form.useForm();
  const { getFieldValue, setFieldsValue, validateFields } = form;
  const [activeCountry, setActiveCountry] = useState();
  const [regionLabel, setRegionLabel] = useState(null);
  const [isRegionHidden, setIsRegionHidden] = useState(true);
  const dispatch = useDispatch();

  const resetForm = useCallback(() => {
    form.resetFields();
  }, [form]);

  const handleCancel = () => {
    onCancel();
    resetFormErrors();
  };

  const handleSubmit = async () => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.vehicleSave);
    validateFields()
      .then(values => onSubmit(values))
      .catch(() => {}); // errors handled by form
  };

  const handleCountrySelect = useCallback(
    (value, region = null) => {
      const regionValue = region || (REGION_CODES[value] && REGION_CODES[value][0].value);
      const regionObj = updateRegionLabel(value, setRegionLabel, translateText);

      if (!regionObj.isRegionHidden) setFieldsValue({ jurisdiction: regionValue });
      setActiveCountry(value);
      setIsRegionHidden(regionObj.isRegionHidden);
    },
    [setFieldsValue], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (isEmpty(formData)) {
      updateRegionLabel(null, setRegionLabel, translateText);
      resetForm();
    } else if (formData.id) {
      setFieldsValue(formData);
      if (formData.country) handleCountrySelect(formData.country, formData.jurisdiction);
    }
  }, [formData, handleCountrySelect, resetForm, setFieldsValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!visible) resetForm();
  }, [visible, resetForm]);

  useEffect(() => {
    const country = getFieldValue('country');
    if (country) setActiveCountry(country);
  }, [getFieldValue]);

  useEffect(() => {
    if (!isPlateValid && !submitting) {
      const licensePlateFormatError = {
        name: 'licensePlate',
        errors: [translateText('corporateAccounts.vehicle.add.invalidCharacters')],
      };

      form.setFields([licensePlateFormatError]);
    } else if (isSuccess) {
      form.setFields([]);
      resetForm();
      dispatch({ type: actions.RESET_SUCCESS, payload: null });
    }
  }, [form, isPlateValid, submitting, isSuccess, resetForm, dispatch, translateText]);

  return (
    <>
      <Form form={form} layout="vertical" style={{ paddingBottom: '100px' }}>
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="status" hidden initialValue={initialValues.status}>
          <Input />
        </Form.Item>
        <Form.Item name="corporateClientId" hidden initialValue={initialValues.corporateClientId}>
          <Input />
        </Form.Item>

        <Form.Item
          name="country"
          label={translateText('corporateAccounts.vehicles.country')}
          rules={[
            {
              required: true,
              message: translateText('corporateAccounts.vehicles.form.countryRequired'),
            },
          ]}
        >
          <Select
            onChange={value => handleCountrySelect(value)}
            placeholder={translateText('corporateAccounts.corporateClient.country.placeholder')}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {Object.keys(COUNTRIES).map(key => (
              <Option key={COUNTRIES[key].countryCode} value={COUNTRIES[key].countryCode}>
                {translateText(
                  `corporateAccounts.countries.${COUNTRIES[key].countryCode.toLowerCase()}`,
                  COUNTRIES[key].label,
                )}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {!isRegionHidden && (
          <Form.Item
            name="jurisdiction"
            label={regionLabel}
            rules={[
              {
                required: !isRegionHidden,
                message: translateText('corporateAccounts.vehicles.form.regionRequired'),
              },
            ]}
          >
            <Select
              disabled={!activeCountry}
              placeholder={translateText('corporateAccounts.corporateClient.region.placeholder')}
            >
              {REGION_CODES[activeCountry] &&
                Object.keys(REGION_CODES[activeCountry]).map((_region, index) => (
                  <Option
                    key={REGION_CODES[activeCountry][index].value}
                    value={REGION_CODES[activeCountry][index].value}
                  >
                    {REGION_CODES[activeCountry][index].label}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name="licensePlate"
          label={translateText('corporateAccounts.vehicles.licensePlate')}
          rules={[
            {
              required: true,
              message: translateText('corporateAccounts.vehicles.form.licensePlateRequired'),
            },
            {
              max: 12,
              message: translateText('corporateAccounts.vehicles.form.licensePlateValidation'),
            },
          ]}
        >
          <Input
            size="middle"
            placeholder={translateText('corporateAccounts.vehicles.form.licensePlatePlaceholder')}
          />
        </Form.Item>
        <Form.Item
          name="vin"
          label={translateText('corporateAccounts.vehicles.vin')}
          rules={[
            {
              pattern: /\b[A-HJ-NPR-Z0-9]{17}\b/,
              message: translateText('corporateAccounts.vehicles.form.vinValidation'),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
      <div
        style={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
        }}
      >
        <Button onClick={handleCancel} style={{ marginRight: 8 }} disabled={submitting}>
          {translateText('corporateAccounts.actions.cancel')}
        </Button>
        <Button
          data-testid="addVehicleSubmitBtn"
          onClick={handleSubmit}
          type="primary"
          loading={submitting}
        >
          {formData.id
            ? translateText('corporateAccounts.vehicles.form.update')
            : translateText('corporateAccounts.vehicles.form.add')}
        </Button>
      </div>
    </>
  );
};

export default injectIntl(VehicleForm);
