import React from 'react';
import { Button, Popconfirm } from 'antd';
import tableUtil from 'utils/tableUtil';
import Can from 'config/Can';
import { COUNTRIES } from 'constants.js';
import { REGION_LABEL } from 'regions.js';
import getCountryLabel from 'utils/countryLabelUtil';

const COUNTRIES_WITH_REGIONS = Object.keys(REGION_LABEL);

const tableColumns = (
  showEditForm,
  deleteButtonClick,
  deleteItem,
  corporationCountry,
  translateText,
) => {
  const regionColumn = COUNTRIES_WITH_REGIONS.includes(corporationCountry)
    ? {
        title: translateText('corporateAccounts.corporateClient.region'),
        dataIndex: 'jurisdiction',
        render: regionCode => {
          return tableUtil.emptyValueFallback(regionCode, translateText);
        },
      }
    : {};

  return [
    {
      title: translateText('corporateAccounts.vehicles.licensePlate'),
      dataIndex: 'licensePlate',
      sorter: (a, b) => tableUtil.stringSort(a, b, 'licensePlate'),
    },
    {
      title: translateText('corporateAccounts.vehicles.country'),
      dataIndex: 'country',
      filters: Object.values(COUNTRIES).map(country => {
        return {
          text: translateText(`corporateAccounts.countries.${country.countryCode.toLowerCase()}`),
          value: country.countryCode,
        };
      }),
      render: key => {
        const country = COUNTRIES[key];
        if (!country) return null;
        return getCountryLabel(country, translateText);
      },
    },
    regionColumn,
    {
      title: translateText('corporateAccounts.vehicles.vin'),
      dataIndex: 'vin',
      render: vin => {
        return tableUtil.emptyValueFallback(vin, translateText);
      },
    },
    {
      title: translateText('corporateAccounts.actions'),
      key: 'actions',
      render: (text, record) => (
        <>
          <Can I="edit" a="Vehicle" passThrough>
            {can => (
              <Button
                data-testid={`edit:${record.licensePlate}`}
                disabled={!can}
                size="small"
                style={{ marginRight: '8px' }}
                onClick={() => showEditForm(record.id)}
              >
                {translateText('corporateAccounts.actions.edit')}
              </Button>
            )}
          </Can>
          <Can I="destroy" a="Vehicle" passThrough>
            {can => (
              <Popconfirm
                title={translateText('corporateAccounts.vehicles.actions.deleteVehicleMessage')}
                okText={translateText('corporateAccounts.actions.delete')}
                okType="danger"
                okButtonProps={{ 'data-testid': 'confirmDeleteVehicleBtn' }}
                cancelText={translateText('corporateAccounts.actions.cancel')}
                onConfirm={() => {
                  deleteItem(record.id);
                }}
                disabled={!can}
              >
                <Button
                  data-testid={`delete:${record.licensePlate}`}
                  disabled={!can}
                  danger
                  size="small"
                  onClick={deleteButtonClick}
                >
                  {translateText('corporateAccounts.actions.delete')}
                </Button>
              </Popconfirm>
            )}
          </Can>
        </>
      ),
    },
  ];
};

export default tableColumns;
