const fleetManager = {
  all: ['create', 'destroy', 'read', 'update'],
  vehicle: ['edit', 'upload', 'export'],
  driver: {
    can: ['activate', 'deactivate', 'destroy', 'edit', 'upload', 'export', 'invite'],
    cannot: ['read_all_columns'],
  },
  restrictedDriver: ['create', 'edit', 'upload'],
  vehicleForm: ['read_vehicle_location', 'read_vehicle_name', 'read_vehicle_type'],
  paymentSpa: ['create', 'edit', 'destroy', 'view_form'],
  billing: {
    can: ['edit'],
  },
  RestrictedDriverUpload: {
    can: ['bulkDriverUpload'],
  },
};

export default fleetManager;
