import React from 'react';

const VerificationLinkExpiredIcon = () => (
  <svg
    width="121"
    height="120"
    viewBox="0 0 121 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="121" height="120" fill="#F5F5F5" />
    <g id="Email template">
      <path
        d="M-2284 -1272C-2284 -1280.84 -2276.84 -1288 -2268 -1288H889C897.837 -1288 905 -1280.84 905 -1272V2709C905 2717.84 897.837 2725 889 2725H-2268C-2276.84 2725 -2284 2717.84 -2284 2709V-1272Z"
        fill="white"
      />
      <g id="link expired">
        <rect x="-580.5" y="-160.5" width="1281" height="801" fill="white" />
        <g id="Frame 879">
          <g id="Frame 931">
            <g id="Frame 948">
              <path
                id="Vector"
                d="M60.5 120C93.6371 120 120.5 93.1371 120.5 60C120.5 26.8629 93.6371 0 60.5 0C27.3629 0 0.5 26.8629 0.5 60C0.5 93.1371 27.3629 120 60.5 120Z"
                fill="#F0645E"
              />
              <g id="calendar-clock">
                <path
                  id="Vector_2"
                  d="M83.5 48.75V45C83.5 43.6739 82.9732 42.4021 82.0355 41.4645C81.0979 40.5268 79.8261 40 78.5 40H43.5C42.1739 40 40.9021 40.5268 39.9645 41.4645C39.0268 42.4021 38.5 43.6739 38.5 45V80C38.5 81.3261 39.0268 82.5979 39.9645 83.5355C40.9021 84.4732 42.1739 85 43.5 85H52.25"
                  stroke="white"
                  strokeWidth="5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="Vector_3"
                  d="M71 35V45"
                  stroke="white"
                  strokeWidth="5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="Vector_4"
                  d="M51 35V45"
                  stroke="white"
                  strokeWidth="5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="Vector_5"
                  d="M38.5 55H51"
                  stroke="white"
                  strokeWidth="5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="Vector_6"
                  d="M74.75 73.75L71 70.625V65"
                  stroke="white"
                  strokeWidth="5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="Vector_7"
                  d="M86 70C86 73.9782 84.4196 77.7936 81.6066 80.6066C78.7936 83.4196 74.9782 85 71 85C67.0218 85 63.2064 83.4196 60.3934 80.6066C57.5804 77.7936 56 73.9782 56 70C56 66.0218 57.5804 62.2064 60.3934 59.3934C63.2064 56.5804 67.0218 55 71 55C74.9782 55 78.7936 56.5804 81.6066 59.3934C84.4196 62.2064 86 66.0218 86 70Z"
                  stroke="white"
                  strokeWidth="5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </g>
          </g>
        </g>
        <rect x="-580.5" y="-160.5" width="1281" height="801" stroke="black" />
      </g>
      <path
        d="M-2268 -1287H889V-1289H-2268V-1287ZM904 -1272V2709H906V-1272H904ZM889 2724H-2268V2726H889V2724ZM-2283 2709V-1272H-2285V2709H-2283ZM-2268 2724C-2276.28 2724 -2283 2717.28 -2283 2709H-2285C-2285 2718.39 -2277.39 2726 -2268 2726V2724ZM904 2709C904 2717.28 897.284 2724 889 2724V2726C898.389 2726 906 2718.39 906 2709H904ZM889 -1287C897.284 -1287 904 -1280.28 904 -1272H906C906 -1281.39 898.389 -1289 889 -1289V-1287ZM-2268 -1289C-2277.39 -1289 -2285 -1281.39 -2285 -1272H-2283C-2283 -1280.28 -2276.28 -1287 -2268 -1287V-1289Z"
        fill="black"
        fillOpacity="0.1"
      />
    </g>
  </svg>
);

export default VerificationLinkExpiredIcon;
