import { useState } from 'react';

const useIsFormValid = (form, fieldConfig = null) => {
  const [isFormValid, setIsFormValid] = useState(false);

  const validateForm = () => {
    let allRequiredFieldsTouched;
    if (fieldConfig !== null) {
      const requiredFields = fieldConfig.filter(field => field.required);
      allRequiredFieldsTouched = requiredFields.every(field => form.isFieldTouched(field.name));
    } else {
      allRequiredFieldsTouched = form.isFieldsTouched(true);
    }

    const allFieldsValid = !form.getFieldsError().some(({ errors }) => errors.length > 0);

    setIsFormValid(allRequiredFieldsTouched && allFieldsValid);
  };

  return { isFormValid, validateForm };
};

export default useIsFormValid;
