import React from 'react';
import PageTable from 'components/Shared/Page/Table';
import OffsetPaginationTable from 'components/Shared/OffsetPaginationTable';

const DriversTable = ({
  columns,
  currentPage,
  dataSource,
  loading,
  selectedTableRows,
  totalCount,
  visible,
  onRowSelectChange,
  onPageChange,
  onPageSizeChange,
  onTableChange,
}) => {
  return (
    <div data-testid="driversTable">
      <PageTable
        visible={visible}
        table={
          <OffsetPaginationTable
            loading={loading}
            rowKey="id"
            rowSelection={{
              selectedRowKeys: selectedTableRows.map(row => row.id),
              onChange: onRowSelectChange,
            }}
            columns={columns}
            current={currentPage}
            totalCount={totalCount}
            dataSource={dataSource}
            scroll={{
              x: true,
            }}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onChange={onTableChange}
          />
        }
      />
    </div>
  );
};

export default DriversTable;
