import { all } from 'redux-saga/effects';
import admins from './admins/sagas';
import corporations from './corporations/sagas';
import user from './user/sagas';
import settings from './settings/sagas';
import vehicles from './vehicles/sagas';
import paymentsSpa from './paymentsSpa/sagas';
import sessions from './sessions/sagas';
import reports from './reports/sagas';
import configuration from './configuration/sagas';

export default function* rootSaga() {
  yield all([
    admins(),
    corporations(),
    user(),
    settings(),
    vehicles(),
    paymentsSpa(),
    sessions(),
    reports(),
    configuration(),
  ]);
}
