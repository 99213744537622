import React from 'react';
import tableUtil from 'utils/tableUtil';
import { COUNTRIES, ADMIN_USER_INVITE_STATUS } from 'constants.js';
import {
  CREDIT_ACCOUNT_TYPES,
  CREDIT_ACCOUNT_TYPES_MAPPING,
  CREDIT_ACCOUNT_STATUS,
  CREDIT_ACCOUNT_STATUS_MAPPING,
  CREDIT_ACCOUNT_MIGRATION_STATUS,
  CREDIT_ACCOUNT_MIGRATION_STATUS_MAPPING,
} from 'constants/creditAccount.js';
import {
  CORPORATE_CLIENT_STATUS_TAGS,
  CORPORATE_CLIENT_STATUS_STRING_TAGS,
  TEST_CORPORATION_STATUS_TAGS,
  CORPORATE_CLIENT_REGISTRATION_TYPES,
  CORPORATE_CLIENT_RESTRICTION_TYPES,
} from 'constants/corporateClients';
import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space } from 'antd';
import getCountryLabel from 'utils/countryLabelUtil';
import UtilTag from 'components/Shared/Tag';
import { formatDateTime } from '../../Reports/helper';
import corporationAdminHelper, { toCamelCase } from './helper';

const tableColumns = (menuItems, handleChange, tableFilters, tableData, translateText) => {
  return [
    {
      title: translateText('corporateAccounts.admins.corporationName'),
      sorter: true,
      dataIndex: 'corporateClientName',
      defaultSortOrder: 'ascend',
    },
    {
      title: translateText('corporateAccounts.admins.corporateClientId'),
      sorter: true,
      dataIndex: 'id',
    },
    {
      title: translateText('corporateAccounts.vehicles.country'),
      dataIndex: 'country',
      filters: corporationAdminHelper.buildCountryFilter(translateText),
      filteredValue: tableFilters?.country,
      render: key => {
        const country = COUNTRIES[key];
        if (!country) return null;
        return getCountryLabel(country, translateText);
      },
    },
    {
      title: translateText('corporateAccounts.corporateClient.accountType'),
      dataIndex: 'isTestCorporation',
      filters: Object.values(TEST_CORPORATION_STATUS_TAGS).map(accountType => {
        return {
          text: translateText(accountType.key),
          value: accountType.value,
        };
      }),
      filteredValue: tableFilters?.isTestCorporation,
      render: accountType => {
        const foundAccountType = TEST_CORPORATION_STATUS_TAGS[accountType];
        return foundAccountType ? (
          <UtilTag
            content={translateText(foundAccountType.key)}
            type={foundAccountType.backgroundType}
          />
        ) : null;
      },
    },
    {
      title: translateText('corporateAccounts.corporateClient.registrationType'),
      dataIndex: 'registrationType',
      filters: Object.values(CORPORATE_CLIENT_REGISTRATION_TYPES).map(type => ({
        text: translateText(`corporateAccounts.corporateClient.registrationType.${type}`),
        value: type,
      })),
      filteredValue: tableFilters?.registrationType,
      render: text =>
        translateText(`corporateAccounts.corporateClient.registrationType.${toCamelCase(text)}`),
    },
    {
      title: translateText('corporateAccounts.corporateClient.restrictionType'),
      dataIndex: 'restrictionType',
      filters: Object.values(CORPORATE_CLIENT_RESTRICTION_TYPES).map(type => ({
        text: translateText(`corporateAccounts.corporateClient.restrictionType.${type}`),
        value: type,
      })),
      filteredValue: tableFilters?.restrictionType,
      render: text =>
        translateText(`corporateAccounts.corporateClient.restrictionType.${toCamelCase(text)}`),
    },
    {
      title: translateText('corporateAccounts.corporateClient.clientStatus'),
      dataIndex: 'status',
      filters: Object.values(CORPORATE_CLIENT_STATUS_STRING_TAGS).map(status => {
        return {
          text: translateText(`corporateAccounts.common.status.${status.key}`),
          value: status.value,
        };
      }),
      filteredValue: tableFilters?.status,
      render: text => {
        const status = CORPORATE_CLIENT_STATUS_TAGS[text];
        if (!status) return null;

        const statusLabel = translateText(`corporateAccounts.common.status.${status.key}`);
        return <UtilTag content={statusLabel} type={status.key} />;
      },
    },
    {
      title: translateText('corporateAccounts.corporateClient.accountOwnerStatus'),
      dataIndex: 'accountOwnerStatus',
      filters: ADMIN_USER_INVITE_STATUS.map(status => {
        return {
          text: translateText(`corporateAccounts.common.status.${status.key}`),
          value: status.value,
        };
      }),
      filteredValue: tableFilters?.accountOwnerStatus,
      render: accountStatus => {
        const accountOwnerStatus = ADMIN_USER_INVITE_STATUS[accountStatus];

        return accountOwnerStatus ? (
          <UtilTag
            content={translateText(`corporateAccounts.common.status.${accountOwnerStatus.key}`)}
            type={accountOwnerStatus.key}
          />
        ) : null;
      },
    },
    {
      title: translateText('corporateAccounts.corporateClient.SpaCards'),
      dataIndex: 'spaCount',
      sorter: true,
      render: (spaCount, corporation) => {
        if (spaCount === null || spaCount === undefined)
          return tableUtil.emptyValueFallback(spaCount, translateText);

        return spaCount;
      },
    },
    {
      title: translateText('corporateAccounts.creditAccount.type'),
      dataIndex: 'creditAccountType',
      filters: Object.values(CREDIT_ACCOUNT_TYPES).map(creditAccountType => ({
        text: translateText(
          `corporateAccounts.creditAccount.type.${toCamelCase(creditAccountType)}`,
        ),
        value: creditAccountType,
      })),
      filteredValue: tableFilters?.creditAccountType,
      render: value => {
        const creditAccountType = CREDIT_ACCOUNT_TYPES_MAPPING[toCamelCase(value)];
        return creditAccountType
          ? translateText(`corporateAccounts.creditAccount.type.${toCamelCase(creditAccountType)}`)
          : '-';
      },
    },
    {
      title: translateText('corporateAccounts.creditAccount.status'),
      dataIndex: 'creditAccountStatus',
      filters: Object.values(CREDIT_ACCOUNT_STATUS).map(creditAccountStatus => ({
        text: translateText(`corporateAccounts.common.status.${creditAccountStatus}`),
        value: creditAccountStatus,
      })),
      filteredValue: tableFilters?.creditAccountStatus,
      render: value => {
        const creditAccountStatus = CREDIT_ACCOUNT_STATUS_MAPPING[toCamelCase(value)];
        return creditAccountStatus
          ? translateText(`corporateAccounts.common.status.${toCamelCase(creditAccountStatus)}`)
          : '-';
      },
    },
    {
      title: translateText('corporateAccounts.creditAccount.migrationStatus'),
      dataIndex: 'creditAccountMigrationStatus',
      filters: Object.values(CREDIT_ACCOUNT_MIGRATION_STATUS).map(creditAccountMigrationStatus => ({
        text: translateText(
          `corporateAccounts.creditAccount.migrationStatus.${creditAccountMigrationStatus}`,
        ),
        value: creditAccountMigrationStatus,
      })),
      filteredValue: tableFilters?.creditAccountMigrationStatus,
      render: value => {
        const creditAccountMigrationStatus =
          CREDIT_ACCOUNT_MIGRATION_STATUS_MAPPING[toCamelCase(value)];
        return creditAccountMigrationStatus
          ? translateText(
              `corporateAccounts.creditAccount.migrationStatus.${toCamelCase(
                creditAccountMigrationStatus,
              )}`,
            )
          : '-';
      },
    },
    {
      title: translateText('corporateAccounts.corporateClient.dateCreated'),
      sorter: true,
      dataIndex: 'createdAt',
      render: (value, record, index) => {
        return <span data-testid={`createdAt-${index}`}>{formatDateTime(value)}</span>;
      },
    },

    {
      title: translateText('corporateAccounts.actions'),
      key: 'actions',
      fixed: 'right',
      align: 'center',
      render: (text, corporation) => (
        <Space>
          <Button size="small" onClick={() => handleChange(corporation)}>
            {translateText('corporateAccounts.admin.action.enterAccount')}
          </Button>
          <Dropdown overlay={() => menuItems(corporation)}>
            <Button>
              {translateText('corporateAccounts.admin.action.moreActions')}
              <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];
};

export default tableColumns;
