import React, { useRef, useState } from 'react';
import { Button, Typography } from 'antd';
import ReCAPTCHA from 'react-google-recaptcha-enterprise';
import useTranslation from 'utils/hooks/useTranslation';
import { VerificationLinkExpiredIcon } from 'components/Shared/Icons';
import { RECAPTCHA_SITE_KEY } from 'constants.js';

import styles from './style.module.scss';

const VerificationLinkExpired = ({ onResendEmail }) => {
  const recaptchaRef = useRef(null);
  const { translateText } = useTranslation();

  const [recaptchaToken, setRecaptchaToken] = useState('');

  const generateToken = token => {
    setRecaptchaToken(token);
  };

  const handleResendEmail = () => {
    onResendEmail({ recaptchaToken });
    recaptchaRef.current?.reset();
    setRecaptchaToken('');
  };

  return (
    <div className={styles.verificationPage} data-testid="verificationLinkExpired">
      <div data-testid="verificationLinkExpiredIcon">
        <VerificationLinkExpiredIcon />
      </div>
      <Typography.Title level={2} className={styles.verificationTitle}>
        {translateText('corporateAccounts.selfRegistration.verification.linkExpired')}
      </Typography.Title>
      <Typography className={styles.verificationMessage}>
        <Typography.Text>
          {translateText('corporateAccounts.selfRegistration.verification.linkExpired.message')}
        </Typography.Text>
      </Typography>
      <ReCAPTCHA
        ref={recaptchaRef}
        data-testid="recaptcha"
        id="recaptcha"
        sitekey={RECAPTCHA_SITE_KEY}
        onChange={generateToken}
      />
      <div className={styles.verificationButton}>
        <Button
          type="primary"
          onClick={() => handleResendEmail()}
          disabled={!recaptchaToken}
          data-testid={'resendEmailButton'}
        >
          <p>
            {translateText('corporateAccounts.selfRegistration.verification.resendVerification')}
          </p>
        </Button>
      </div>
    </div>
  );
};

export default VerificationLinkExpired;
