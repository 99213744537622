import React from 'react';
import DrawerForm from 'components/Shared/DrawerForm';
import VehicleForm from './VehicleForm';

const VehicleDrawerForm = ({
  formData,
  initialValues,
  visible,
  submitting,
  title,
  errors,
  isPlateValid,
  isSuccess,
  onClose,
  onCancel,
  onFormReset,
  onSubmit,
}) => {
  return (
    <DrawerForm title={title} width={320} visible={visible} onClose={onClose}>
      <VehicleForm
        formData={formData}
        initialValues={initialValues}
        formErrors={errors}
        submitting={submitting}
        visible={visible}
        isPlateValid={isPlateValid}
        isSuccess={isSuccess}
        onCancel={onCancel}
        onSubmit={onSubmit}
        resetFormErrors={onFormReset}
      />
    </DrawerForm>
  );
};

export default VehicleDrawerForm;
