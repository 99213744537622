import React from 'react';
import { Drawer } from 'antd';

class DrawerForm extends React.Component {
  render() {
    const { children, visible, onClose, title, width = 720 } = this.props;

    return (
      <div>
        <Drawer
          title={title}
          width={width}
          onClose={onClose}
          visible={visible}
          style={{
            overflow: 'auto',
            height: '100%',
            paddingBottom: '108px',
          }}
          destroyOnClose
        >
          {children}
        </Drawer>
      </div>
    );
  }
}

export default DrawerForm;
