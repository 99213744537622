import { CloseMenuIcon, HamburgerMenuIcon } from 'components/Shared/Icons/ResponsiveMenuButton';
import styles from './style.module.scss';

const MobileHeaderMenu = ({ isOpen, setOpen }) => (
  <section className={styles.headerGroup}>
    <span onClick={() => setOpen(!isOpen)} id={styles.drawerButtonContainer}>
      {isOpen ? <CloseMenuIcon /> : <HamburgerMenuIcon />}
    </span>
  </section>
);

export default MobileHeaderMenu;
