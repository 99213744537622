import React from 'react';

const DriversIcon = () => (
  <svg
    width="394"
    height="206"
    viewBox="0 0 394 206"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="driversIcon"
  >
    <g clipPath="url(#clip0_1135_6649)">
      <path
        d="M120.165 124.637C118.411 124.639 116.73 125.336 115.49 126.576C114.25 127.816 113.553 129.497 113.551 131.251V146.724C113.553 148.477 114.25 150.158 115.49 151.398C116.73 152.638 118.411 153.336 120.165 153.338H128.553C130.307 153.336 131.988 152.638 133.228 151.398C134.468 150.158 135.165 148.477 135.167 146.724V131.251C135.165 129.497 134.468 127.816 133.228 126.576C131.988 125.336 130.307 124.639 128.553 124.637H120.165Z"
        fill="black"
      />
      <path
        d="M275.236 153.852H266.847C264.957 153.85 263.145 153.098 261.809 151.762C260.473 150.426 259.721 148.614 259.719 146.724V131.251C259.721 129.361 260.473 127.549 261.809 126.212C263.145 124.876 264.957 124.124 266.847 124.122H275.236C277.126 124.124 278.938 124.876 280.274 126.212C281.611 127.549 282.362 129.361 282.364 131.251V146.724C282.362 148.614 281.611 150.426 280.274 151.762C278.938 153.098 277.126 153.85 275.236 153.852Z"
        fill="black"
      />
      <path
        d="M269.669 141.464H125.216C118.641 141.464 113.293 137.588 113.293 132.825V105.047L120.463 97.4961H275.816L281.591 105.063V132.825C281.591 137.588 276.243 141.464 269.669 141.464Z"
        fill="#86BE56"
      />
      <path
        d="M275.143 111.392H121.288L119.725 87.4174L120.72 84.9641L126.503 70.7325H268.887L276.707 86.8934L275.143 111.392Z"
        fill="#86BE56"
      />
      <path
        d="M276.447 86.9448L275.299 104.958H241.449V91.5766L275.485 84.958L276.447 86.9448Z"
        fill="white"
      />
      <path
        d="M154.468 91.5761V104.958H121.127L119.984 87.4587L120.957 85.0605L154.468 91.5761Z"
        fill="white"
      />
      <path
        d="M268.029 74.8499H127.359L142.363 24.8386C142.975 22.7732 144.235 20.9594 145.957 19.6652C147.679 18.3711 149.772 17.6654 151.926 17.6523L245.087 16.765C247.338 16.7431 249.531 17.4752 251.318 18.8446C253.105 20.214 254.382 22.1419 254.946 24.3212L268.029 74.8499Z"
        fill="#86BE56"
      />
      <path
        d="M257.2 70.121L138.165 70.0051C138.015 70.0033 137.867 69.9662 137.733 69.8968C137.6 69.8274 137.484 69.7277 137.396 69.6056C137.308 69.4835 137.25 69.3424 137.227 69.1938C137.203 69.0452 137.215 68.8931 137.261 68.7497L148.672 31.8509C148.734 31.6568 148.856 31.4875 149.021 31.3674C149.186 31.2473 149.384 31.1826 149.588 31.1826H245.33C245.534 31.1826 245.732 31.2473 245.897 31.3673C246.061 31.4873 246.183 31.6565 246.246 31.8505L258.127 68.8652C258.174 69.0104 258.185 69.1646 258.16 69.315C258.135 69.4654 258.075 69.6079 257.985 69.7305C257.894 69.8532 257.776 69.9525 257.639 70.0204C257.503 70.0883 257.352 70.1228 257.2 70.121Z"
        fill="white"
      />
      <mask
        id="mask0_1135_6649"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="204"
        y="31"
        width="45"
        height="39"
      >
        <rect x="204.125" y="31.0938" width="44.6981" height="38.8679" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1135_6649)">
        <path
          d="M226.185 50.0122C230.081 50.0122 233.24 46.8538 233.24 42.9577C233.24 39.0616 230.081 35.9032 226.185 35.9032C222.289 35.9032 219.131 39.0616 219.131 42.9577C219.131 46.8538 222.289 50.0122 226.185 50.0122Z"
          fill="#BF9E73"
        />
        <path
          d="M215.234 90.9824C222.803 96.9021 231.158 99.886 240.616 98.7487C236.622 84.7234 235.437 70.8042 239.29 57.0755L231.335 53.4765L222.392 52.9082L215.892 54.8199C214.978 55.0891 214.177 55.6514 213.614 56.4203C213.05 57.1891 212.755 58.1218 212.774 59.0748C213.004 70.7828 212.225 81.74 215.234 90.9824Z"
          fill="#E6E6E6"
        />
        <path
          d="M219.433 39.4102C220.112 38.1297 220.974 35.5777 222.344 35.1044C223.105 34.8522 223.891 34.6847 224.688 34.605L227.017 35.5005L225.749 34.515C225.996 34.5003 226.243 34.4882 226.489 34.4789L228.06 35.0831L227.256 34.458C228.721 34.443 230.233 34.5952 231.368 35.4868C232.335 36.2469 233.294 39.1262 233.449 40.3467C233.614 41.5143 233.556 42.7027 233.278 43.8486L232.88 44.2269C232.741 43.9078 232.636 43.5747 232.568 43.2333C232.425 42.5955 232.151 41.9944 231.763 41.4683C231.375 40.9422 230.882 40.5026 230.315 40.1777L230.294 40.1655C229.626 39.7899 228.817 39.8072 228.052 39.8348L224.417 39.9655C223.543 39.997 222.602 40.0523 221.92 40.6008C221.459 40.9712 221.184 41.5179 220.921 42.0474C220.583 42.731 219.921 45.3095 219.921 45.3095C219.921 45.3095 219.546 44.8228 219.367 45.185C219.079 44.2399 218.904 43.2639 218.845 42.2777C218.788 41.287 218.991 40.2985 219.433 39.4102Z"
          fill="#1A1A1A"
        />
        <path
          d="M212.961 94.5807L215.991 91.7394L214.436 76.396L216.236 54.707C212.326 55.9356 208.129 59.2359 207.438 63.2748L204.965 77.722L212.961 94.5807Z"
          fill="#E6E6E6"
        />
        <path
          opacity="0.2"
          d="M214.72 68.1565L212.258 78.0065L215.992 91.9838L213.584 78.7642L214.72 68.1565Z"
          fill="#949494"
        />
        <path
          d="M239.101 99.8849L244.026 100.453L242.873 63.8816C242.821 62.2475 242.3 60.6628 241.372 59.3169C240.444 57.9711 239.148 56.921 237.639 56.2921L235.881 55.5597L236.354 72.7026L239.101 99.8849Z"
          fill="#E6E6E6"
        />
        <circle cx="225.502" cy="76.4396" r="13.6038" stroke="black" strokeWidth="3.88679" />
      </g>
      <path
        d="M127.449 73.8208H106.348V69.1827C106.349 67.6833 106.946 66.2459 108.006 65.1856C109.066 64.1254 110.504 63.529 112.003 63.5273H127.449V73.8208Z"
        fill="#86BE56"
      />
      <path
        d="M289.055 73.8208H267.953V63.5273H283.399C284.899 63.529 286.336 64.1254 287.396 65.1856C288.457 66.2459 289.053 67.6833 289.055 69.1827V73.8208Z"
        fill="#86BE56"
      />
      <path
        d="M103.879 146.122L0.71875 183.181L1.15667 184.4L104.317 147.341L103.879 146.122Z"
        fill="#4D4D4D"
      />
      <path
        d="M290.873 146.125L290.436 147.344L393.599 184.393L394.037 183.174L290.873 146.125Z"
        fill="#4D4D4D"
      />
      <path
        d="M203.433 172.995H197.258V165.02H203.433V172.995ZM203.433 157.044H197.258V149.068H203.433V157.044Z"
        fill="#E6E6E6"
      />
      <path
        d="M203.433 206.032H197.258V198.057H203.433V206.032ZM203.433 190.081H197.258V182.105H203.433V190.081Z"
        fill="#E6E6E6"
      />
    </g>
    <defs>
      <clipPath id="clip0_1135_6649">
        <rect width="392.566" height="206" fill="white" transform="translate(0.716797)" />
      </clipPath>
    </defs>
  </svg>
);

export default DriversIcon;
