import React from 'react';

const OctagonErrorIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="61"
    height="60"
    viewBox="0 0 61 60"
    fill="none"
    data-testid="octagonErrorIcon"
  >
    <path
      d="M20.15 5H40.85L55.5 19.65V40.35L40.85 55H20.15L5.5 40.35V19.65L20.15 5Z"
      stroke="#F0645E"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38 22.5L23 37.5"
      stroke="#F0645E"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23 22.5L38 37.5"
      stroke="#F0645E"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default OctagonErrorIcon;
