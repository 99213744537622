import React, { useState } from 'react';
import { BackTop, Layout } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { ZENDESK_KEY } from 'constants.js';
import useZendeskWidget from 'utils/hooks/useZendeskWidget';
import useCookiesConsent from 'utils/hooks/useCookiesConsent';
import usePlausible from 'utils/hooks/usePlausible';
import TopBar from 'components/Layout/TopBar';
import Menu from 'components/Layout/Menu';
import Footer from 'components/Layout/Footer';
import Settings from 'components/Layout/Settings';
import GlobalNotifications from 'components/Layout/GlobalNotifications';
import SwitchToDesktopModal from 'components/Shared/SwitchToDesktopModal';
import { useSwitchToDesktopModal } from './useSwitchToDesktopModal';
import CreditAccountInfoModal from 'components/Shared/CreditAccountInfoModal/index.js';

import styles from './style.module.scss';

const MainLayout = ({
  children,
  isBorderless,
  isSquaredBorders,
  isFixedWidth,
  isMenuShadow,
  isMenuTop,
  showNav,
  showFooter,
  showMenu,
  padContent,
}) => {
  useZendeskWidget({
    zKey: ZENDESK_KEY,
    settings: {
      messenger: {
        zIndex: 40, // $ztier-4
      },
    },
  });
  const { isVisible } = useSwitchToDesktopModal();
  const [layoutOffset, setLayoutOffset] = useState(0);
  const notificationsRef = React.createRef();

  const handleNotificationClose = e => {
    setTimeout(() => {
      setLayoutOffset(notificationsRef.current?.getBoundingClientRect().height || 0);
    }, 50);
  };

  useCookiesConsent();
  usePlausible();

  return (
    <div>
      <GlobalNotifications ref={notificationsRef} onNotificationClose={handleNotificationClose} />
      <Layout
        className={classNames({
          settings__borderLess: isBorderless,
          settings__squaredBorders: isSquaredBorders,
          settings__fixedWidth: isFixedWidth,
          settings__menuShadow: isMenuShadow,
          settings__menuTop: isMenuTop,
        })}
        style={{
          height: '100%',
          paddingTop: `${layoutOffset}px`,
        }}
      >
        <BackTop className={styles.backTop} />
        {showMenu && <Menu />}
        <Settings />
        <CreditAccountInfoModal />
        <Layout className={classNames(!padContent && styles.layoutWhiteBg)}>
          {showNav && (
            <Layout.Header data-testid="navigationLayout">
              <TopBar showLogo={!showMenu} />
            </Layout.Header>
          )}
          <Layout.Content className={styles.layoutContent} data-testid="contentLayout">
            <div className={classNames({ utils__content: padContent })}>{children}</div>
            {isVisible && <SwitchToDesktopModal isVisible={isVisible} />}
          </Layout.Content>
          {showFooter && (
            <Layout.Footer data-testid="footerLayout">
              <Footer />
            </Layout.Footer>
          )}
        </Layout>
      </Layout>
    </div>
  );
};

const mapStateToProps = ({ settings }) => ({
  isBorderless: settings.isBorderless,
  isSquaredBorders: settings.isSquaredBorders,
  isFixedWidth: settings.isFixedWidth,
  isMenuShadow: settings.isMenuShadow,
  isMenuTop: settings.isMenuTop,
});

MainLayout.defaultProps = {
  showNav: true,
  showFooter: true,
  showMenu: true,
  padContent: true,
};

export default connect(mapStateToProps)(withRouter(MainLayout));
