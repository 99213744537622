import { localizedCurrency } from 'services/localize';
import { formatDateTime } from '../helper';

export const translateReport = (reports, intl) => {
  const translatedReports = reports.map(record => {
    const translatedRecord = Object.assign({}, record);
    const { dayOfWeek } = translatedRecord;

    const timeValues = [
      'localStartDateTime',
      'localEndDateTime',
      'localPaymentDateTime',
      'paymentDate',
      'utcStartDateTime',
      'utcEndDateTime',
    ];

    const moneyKeys = ['cost', 'tax', 'netTotal'];

    timeValues.forEach(value => {
      translatedRecord[value] = formatDateTime(translatedRecord[value]);
    });

    moneyKeys.forEach(key => {
      translatedRecord[key] = localizedCurrency(translatedRecord[key], record.currency);
    });

    translatedRecord.dayOfWeek = intl.formatMessage({
      id: `corporateAccounts.daysOfWeek.${dayOfWeek}`,
      defaultMessage: `${dayOfWeek}`,
    });

    return translatedRecord;
  });
  return translatedReports;
};

export default translateReport;
