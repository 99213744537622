const buildColumnFilter = ({ filter, translateText }) => {
  return Object.values(filter).map(filterItem => ({
    text: translateText(filterItem.label),
    value: filterItem.value,
  }));
};

const getTableItem = ({ item, mappingObject }) => {
  const roleEntry = Object.values(mappingObject).find(object => object.value === item);
  return roleEntry ? roleEntry.label : '-';
};

const getTableItemTranslation = ({ translateText, item, mappingObject }) => {
  const roleEntry = Object.values(mappingObject).find(object => object.value === item);
  return roleEntry ? translateText(roleEntry.label) : null;
};

function isDataFilterActive(filter) {
  for (let key in filter) {
    if (filter[key] !== null) {
      return true;
    }
  }
  return false;
}

const createOrderString = ({ orderBy, sortOrder }) => {
  const SORT_OPTIONS = {
    ascend: 'asc',
    descend: 'desc',
  };

  return orderBy && sortOrder ? `${orderBy.toLowerCase()} ${SORT_OPTIONS[sortOrder]}` : null;
};

export const convertPropertyNamesToLowercase = obj => {
  const newObject = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = key.toLowerCase();
      newObject[newKey] = obj[key];
    }
  }

  return newObject;
};

export const parseAuditLogMessage = entityLog => {
  if (!entityLog) return null;

  try {
    const { Message, Header } = JSON.parse(entityLog?.message);

    return {
      ...entityLog,
      message: {
        message: convertPropertyNamesToLowercase(Message),
        header: convertPropertyNamesToLowercase(Header),
      },
    };
  } catch (error) {
    return null;
  }
};

const auditLogsHelper = {
  getTableItem,
  getTableItemTranslation,
  buildColumnFilter,
  isDataFilterActive,
  createOrderString,
  parseAuditLogMessage,
  convertPropertyNamesToLowercase,
};

export default auditLogsHelper;
