import { useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { sendEvent, sendEventAsync } from '../amplitudeUtil';
import { COUNTRIES } from '../../constants';
import { CORPORATE_CLIENT_ACCOUNT_TYPES } from 'constants/corporateClients.js';

export const getCorporationAccountType = currentCorporation => {
  if (!currentCorporation) return undefined;
  return currentCorporation.isTestCorporation
    ? CORPORATE_CLIENT_ACCOUNT_TYPES.TEST
    : CORPORATE_CLIENT_ACCOUNT_TYPES.STANDARD;
};

const useAmplitude = () => {
  const { currentCorporation } = useSelector(state => state.user);

  const corporationInfoToLog = useMemo(() => {
    return {
      name: currentCorporation?.corporateClientName,
      id: currentCorporation?.id,
      country: COUNTRIES[currentCorporation?.country]?.label,
      status: currentCorporation?.statusName, //'Active or Deleted or Deactivated or Prospect',
      accountType: getCorporationAccountType(currentCorporation), // test or standard
      registrationType: currentCorporation?.registrationType, // Enterprise or SelfRegistered
    };
  }, [currentCorporation]);

  const sendAmplitudeEvent = useCallback(
    (eventType, additionalEventProperties = {}) => {
      sendEvent(eventType, {
        company: corporationInfoToLog,
        ...additionalEventProperties,
      });
    },
    [corporationInfoToLog],
  );

  const sendAmplitudeEventAsync = useCallback(
    async (eventType, additionalEventProperties = {}) => {
      await sendEventAsync(eventType, {
        company: corporationInfoToLog,
        ...additionalEventProperties,
      });
    },
    [corporationInfoToLog],
  );

  return {
    sendAmplitudeEvent,
    sendAmplitudeEventAsync,
  };
};

export default useAmplitude;
