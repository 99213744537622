import React from 'react';

const SuccessIcon = ({ color = '#6CC24A' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="61" height="62" viewBox="0 0 61 62" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.5 61.25C47.2066 61.25 60.75 47.7066 60.75 31C60.75 14.2934 47.2066 0.75 30.5 0.75C13.7934 0.75 0.25 14.2934 0.25 31C0.25 47.7066 13.7934 61.25 30.5 61.25ZM46.1945 24.6945C47.2685 23.6206 47.2685 21.8794 46.1945 20.8055C45.1206 19.7315 43.3794 19.7315 42.3055 20.8055L25.2975 37.8134L18.95 29.35C18.0387 28.135 16.315 27.8887 15.1 28.8C13.885 29.7113 13.6387 31.435 14.55 32.65L22.8 43.65C23.2783 44.2878 24.0099 44.6866 24.8051 44.7431C25.6003 44.7996 26.3808 44.5082 26.9445 43.9445L46.1945 24.6945Z"
      fill={`${color}`}
    />
  </svg>
);

export default SuccessIcon;
