import React from 'react';
import { Row, Col } from 'antd';
import SearchInput from 'components/Shared/Search';
import ButtonGroup from 'antd/lib/button/button-group';

const ButtonSearchRow = ({
  visible,
  searchOptions,
  primaryBtn,
  secondaryBtn,
  bulkActionBtns,
  showLengthRequired = true,
}) => {
  if (!visible) return null;

  return (
    <div className="card-body card-body-padding-bottom-0">
      <Row justify="space-between" gutter={[6, 6]}>
        {/* Search Bar */}
        <Col xs={24} sm={24} md={24} lg={8}>
          {searchOptions && (
            <SearchInput
              onSearchFunc={searchOptions.handleSearch}
              searchValue={searchOptions.searchQuery}
              placeholder={searchOptions.placeholder}
              showLengthRequired={showLengthRequired}
            />
          )}
        </Col>

        <Col xs={24} sm={24} md={24} lg={16}>
          <Row align="middle" gutter={[10, 10]} justify="end">
            {bulkActionBtns && (
              <Col>
                <ButtonGroup>{bulkActionBtns}</ButtonGroup>
              </Col>
            )}

            {secondaryBtn && <Col>{secondaryBtn}</Col>}

            <Col>{primaryBtn}</Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ButtonSearchRow;
