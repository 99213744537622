import React from 'react';

export const stringSort = (a, b, prop) => {
  const stringA = a[prop] && a[prop].toString().toLowerCase();
  const stringB = b[prop] && b[prop].toString().toLowerCase();

  if (stringA === null) return 1;
  if (stringB === null) return -1;
  if (stringA < stringB) return -1;
  if (stringA > stringB) return 1;
  return 0;
};

export const stringNumberSort = (a, b, prop) => {
  const numberA = parseFloat(a[prop]);
  const numberB = parseFloat(b[prop]);

  if (Number.isNaN(numberA)) return 1;
  if (Number.isNaN(numberB)) return -1;
  if (numberA < numberB) return -1;
  if (numberA > numberB) return 1;
  return 0;
};

export const emptyValueFallback = (val, translateText) => {
  if (val) return val;
  const emptyText = translateText('corporateAccounts.common.empty');
  return <span className="empty-watermark"> {emptyText} </span>;
};

const tableUtil = {
  stringSort,
  stringNumberSort,
  emptyValueFallback,
};

export default tableUtil;
