import React from 'react';
import { ADMIN_USER_ROLES } from 'constants.js';
import { CORPORATE_CLIENT_STATUS, CORPORATE_CLIENT_STATUS_TAGS } from 'constants/corporateClients';
import { Button } from 'antd';
import UtilTag from 'components/Shared/Tag';

const tableColumns = (handleChange, translateText) => {
  return [
    {
      title: translateText('corporateAccounts.admins.corporationName'),
      dataIndex: 'corporateClientName',
    },
    {
      title: translateText('corporateAccounts.admins.role'),
      dataIndex: 'userRole',
      render: role => {
        const adminRole = ADMIN_USER_ROLES[role];

        if (!adminRole)
          return (
            <UtilTag
              content={translateText('corporateAccounts.admins.status.reinviteRequired')}
              type="inactive"
            />
          );

        return adminRole ? translateText(`corporateAccounts.admins.role.${adminRole.key}`) : null;
      },
    },
    {
      title: translateText('corporateAccounts.common.status'),
      key: 'status',
      render: (text, record) => {
        const corporationStatus = CORPORATE_CLIENT_STATUS_TAGS[record.status];
        return (
          <UtilTag
            content={
              corporationStatus &&
              translateText(`corporateAccounts.common.status.${corporationStatus.key}`)
            }
            type={corporationStatus.key}
          />
        );
      },
    },
    {
      title: translateText('corporateAccounts.actions'),
      key: 'actions',
      render: (text, record) => {
        return (
          <>
            <Button
              data-testid={`enterCorporateAccount:${record.id}`}
              disabled={
                !record.userRole ||
                (record.status !== CORPORATE_CLIENT_STATUS.ACTIVE &&
                  record.status !== CORPORATE_CLIENT_STATUS.PROSPECT)
              }
              size="small"
              style={{ marginRight: '8px' }}
              onClick={() => handleChange(record)}
            >
              Enter
            </Button>
          </>
        );
      },
    },
  ];
};

export default tableColumns;
