import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import Authorize from 'components/Providers/Authorize';
import PageWrapper from 'components/Shared/Page/Wrapper';
import PageTitle from 'components/Shared/Page/Title';
import withPusher from 'components/Providers/Pusher/withPusher';
import PageBanners from 'components/Layout/PageBanners';
import actions from 'redux/vehicles/actions';
import { ROUTE_VEHICLES_UPLOAD } from 'constants.js';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import useTranslation from 'utils/hooks/useTranslation';
import useAmplitude from 'utils/hooks/useAmplitude';
import VehicleDrawerForm from './VehicleDrawerForm';
import VehiclesEmptyState from './VehiclesEmptyState';
import VehiclesTable from './VehiclesTable';
import useVehiclePaginationTable from './useVehiclePaginationTable';
import useOnboardingProgressBar from 'components/Shared/OnboardingProgressBar/useOnboardingProgressBar';

const Vehicles = ({ history }) => {
  const { sendAmplitudeEvent } = useAmplitude();
  const { translateText } = useTranslation();
  const { onGetOnboardingProgress } = useOnboardingProgressBar();
  const [formVisibility, setFormVisibility] = useState(false);
  const [formTitle, setFormTitle] = useState('');
  const [formData, setFormData] = useState({});
  const [initialValues, setInitialValues] = useState();
  const dispatch = useDispatch();

  const {
    data,
    loading,
    totalCount,
    formErrors,
    submitting,
    limitExceeded,
    isPlateValid,
    isSuccess,
  } = useSelector(state => state.vehicles);
  const corporateClientId = useSelector(state => state.user.corporateClientId);
  const isLoading = loading && totalCount === 0;

  const showAddVehicleForm = amplitudeEvent => {
    const title = translateText('corporateAccounts.vehicles.form.new');
    sendAmplitudeEvent(amplitudeEvent);
    setInitialValues({ corporateClientId, status: 0 });
    setFormTitle(title);
    setFormVisibility(true);
    dispatch({
      type: actions.RESET_ISPLATEVALID,
      payload: null,
    });
  };

  const showEditVehicleForm = id => {
    const title = translateText('corporateAccounts.vehicles.form.edit');
    const tableData = data.find(row => row.id === id);
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.vehicleEdit);
    setFormData(tableData);
    setFormTitle(title);
    setFormVisibility(true);
  };

  const handleFormCancel = () => {
    setFormData({});
    setFormVisibility(false);
    dispatch({
      type: actions.RESET_ISPLATEVALID,
      payload: null,
    });
  };

  const handleFormSubmit = values => {
    // edit
    if (values.id) {
      return dispatch({ type: actions.PUT, payload: values });
    }

    dispatch({ type: actions.POST, payload: values });
  };

  const vehicleTableProps = useVehiclePaginationTable({
    onShowEditForm: showEditVehicleForm,
    onShowAddForm: () => showAddVehicleForm(AMPLITUDE_EVENT_TYPES.vehicleAdd),
  });

  useEffect(() => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.vehiclePage);
  }, [sendAmplitudeEvent]);

  useEffect(() => {
    onGetOnboardingProgress();
  }, [data, onGetOnboardingProgress]);

  useEffect(() => {
    if (limitExceeded) {
      sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.vehicleAddLimitExceededError);
    }
  }, [limitExceeded, sendAmplitudeEvent]);

  return (
    <Authorize>
      <Helmet title="Vehicles" />
      <PageBanners />
      <VehicleDrawerForm
        errors={formErrors}
        formData={formData}
        initialValues={initialValues}
        submitting={submitting}
        title={formTitle}
        visible={formVisibility}
        isPlateValid={isPlateValid}
        isSuccess={isSuccess}
        onClose={handleFormCancel}
        onCancel={handleFormCancel}
        onSubmit={handleFormSubmit}
        onFormReset={() => dispatch({ type: actions.RESET_ERRORS })}
      />

      <PageWrapper loading={isLoading} history={history}>
        <PageTitle
          title={translateText('corporateAccounts.vehicles.title')}
          numRecords={totalCount}
        />

        <VehiclesEmptyState
          visible={!vehicleTableProps.visible && !isLoading}
          handleAddVehicle={() =>
            showAddVehicleForm(AMPLITUDE_EVENT_TYPES.vehiclesEmptyStateAddButtonClicked)
          }
          handleBulkUpload={() => {
            sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.vehiclesEmptyStateImportButtonClicked);
            history.push(ROUTE_VEHICLES_UPLOAD);
          }}
        />

        <VehiclesTable history={history} {...vehicleTableProps} />
      </PageWrapper>
    </Authorize>
  );
};

export default withPusher(withRouter(injectIntl(Vehicles)));
