import React from 'react';
import ability from 'config/ability';
import { Modal } from 'antd';
import utilityModal from 'components/Shared/Modal/info';
import styles from './style.module.scss';

const filterByActivatable = drivers => {
  return drivers
    .filter(row => row.status === 0 || row.status === 5 || row.status === 3)
    .map(row => row.id);
};

const add = ({
  corporateClientId,
  setInitialValues,
  setFormTitle,
  setFormVisibility,
  translateText,
}) => {
  if (ability.can('create', 'RestrictedDriver')) {
    const title = translateText('corporateAccounts.drivers.form.title.new');

    setInitialValues({ corporateClientId, status: 0 });
    setFormTitle(title);
    setFormVisibility(true);
  } else {
    const title = translateText('corporateAccounts.drivers.restrictedAction.add.title');

    const content = translateText('corporateAccounts.drivers.restrictedAction.add.content');

    utilityModal.infoModal({ title, content });
  }
};

const edit = ({ id, data, setFormData, setFormTitle, setFormVisibility, translateText }) => {
  if (ability.can('edit', 'RestrictedDriver')) {
    const tableData = data.find(row => row.id === id);
    const title = translateText('corporateAccounts.drivers.form.title.edit');
    setFormData(tableData);
    setFormTitle(title);
    setFormVisibility(true);
  } else {
    const title = translateText('corporateAccounts.drivers.restrictedAction.edit.title');
    const content = translateText('corporateAccounts.drivers.restrictedAction.edit.content');

    utilityModal.infoModal({ title, content });
  }
};

const deleteSingleDriver = ({ onConfirmDelete, translateText }) => {
  const title = translateText('corporateAccounts.drivers.deleteModal.title');
  const intlContent = translateText('corporateAccounts.drivers.deleteModal.content');
  const deletionContent = (
    <div data-testid="driverDeleteModalContent" className={styles.modalContent}>
      {intlContent}
    </div>
  );

  Modal.confirm({
    content: deletionContent,
    title,
    onOk: onConfirmDelete,
    okText: translateText('corporateAccounts.actions.delete'),
    okType: 'danger',
    cancelText: translateText('corporateAccounts.actions.cancel'),
    onCancel() {},
    icon: null,
    closable: true,
    width: 480,
    bodyStyle: { padding: '24px 24px 24px 24px' },
  });
};

const bulkDelete = ({ onConfirmDelete, translateText }) => {
  const title = translateText('corporateAccounts.drivers.bulkDelete.modal.title');

  const intlContent = translateText('corporateAccounts.drivers.bulkDelete.modal.content');

  const deletionContent = (
    <div data-testid="driverBulkDeleteModalContent" className={styles.modalContent}>
      {intlContent}
    </div>
  );

  Modal.confirm({
    content: deletionContent,
    title,
    onOk: onConfirmDelete,
    okText: translateText('corporateAccounts.actions.delete'),
    okType: 'danger',
    cancelText: translateText('corporateAccounts.actions.cancel'),
    onCancel() {},
    icon: null,
    closable: true,
    width: 480,
    bodyStyle: { padding: '24px 24px 24px 24px' },
  });
};

const driverHelper = {
  add,
  deleteSingleDriver,
  bulkDelete,
  edit,
  filterByActivatable,
};

export default driverHelper;
