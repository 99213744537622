import React from 'react';
import { COUNTRIES } from 'constants.js';

export const currentLanguages = [
  COUNTRIES.US,
  COUNTRIES.GB,
  COUNTRIES.FR,
  COUNTRIES.DE,
  {
    locale: 'glb-glb',
    countryCode: 'GLB',
    icon: <>{String.fromCodePoint(0x1f310)}</>,
    language: 'Global',
    restricted: true,
  },
];

export default currentLanguages;
