export const REPORT_STATUS = {
  REQUESTED: 0,
  STARTED: 1,
  SUCCEEDED: 2,
  FAILED: 3,
};

export const REPORT_STATUS_TAGS = {
  [REPORT_STATUS.REQUESTED]: {
    localizeFallback: 'Pending',
    localizeKey: 'corporateAccounts.reports.monthly.reportJob.status.pending',
    utilTagType: 'pending',
  },
  [REPORT_STATUS.STARTED]: {
    localizeFallback: 'Pending',
    localizeKey: 'corporateAccounts.reports.monthly.reportJob.status.pending',
    utilTagType: 'pending',
  },
  [REPORT_STATUS.SUCCEEDED]: {
    localizeFallback: 'Completed',
    localizeKey: 'corporateAccounts.reports.monthly.reportJob.status.completed',
    utilTagType: 'completed',
  },
  [REPORT_STATUS.FAILED]: {
    localizeFallback: 'Failed',
    localizeKey: 'corporateAccounts.reports.monthly.reportJob.status.failed',
    utilTagType: 'failed',
  },
};

export const REPORT_VALID_DAYS = [1, 2, 3, 4, 5]; // Mon - Fri

export default {
  REPORT_STATUS,
  REPORT_STATUS_TAGS,
  REPORT_VALID_DAYS,
};
