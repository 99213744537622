const refreshCacheAndReload = () => {
  console.log('There is a newer version available - clearing cache and hard reloading...');
  if (caches) {
    caches.keys().then(async names => {
      await Promise.all(names.map(name => caches.delete(name)));
    });
  }
  window.location.reload();
};

export default {
  refreshCacheAndReload,
};
