import { useSelector } from 'react-redux';

export const useSwitchToDesktopModal = () => {
  const isMobile = useSelector(state => state.settings.isMobileView);
  const restrictedCorporateClientProspect = useSelector(
    state => state.user.restrictedCorporateClientProspect,
  );

  const isVisible = isMobile && !restrictedCorporateClientProspect;

  return { isVisible };
};
