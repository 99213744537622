import React, { useState, useRef } from 'react';
import { Typography, Button } from 'antd';
import useTranslation from 'utils/hooks/useTranslation';
import { Carousel } from 'antd';
import {
  DriverOnboardingInfo1,
  DriverOnboardingInfo2,
  DriverOnboardingInfo3,
} from 'components/Shared/Icons';
import OpenAppButton from 'components/Shared/Buttons/OpenAppButton';

import styles from './style.module.scss';

const OnboardingSuccess = () => {
  const { translateText } = useTranslation();
  const carouselRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const buildCarouselSlide = (slideImg, title, text, subText) => {
    return (
      <div className={styles.carouselCard}>
        {slideImg}
        <Typography.Title level={4} className={styles.carouselCardTitle}>
          {title}
        </Typography.Title>
        <Typography>{text}</Typography>
        {subText && <Typography className={styles.carouselCardSubText}>{subText}</Typography>}
      </div>
    );
  };

  const moveToNextSlide = () => {
    carouselRef?.current?.next();
    setCurrentSlide(currentSlide + 1);
  };

  const moveToPrevSlide = () => {
    carouselRef?.current?.prev();
    setCurrentSlide(currentSlide - 1);
  };

  return (
    <div className={styles.carouselContainer} data-testid="onboardingSuccess">
      <Carousel
        afterChange={setCurrentSlide}
        infinite={false}
        className={styles.carousel}
        ref={carouselRef}
      >
        {buildCarouselSlide(
          <DriverOnboardingInfo1 />,
          translateText('corporateAccounts.onboarding.success.carousel1.title'),
          translateText('corporateAccounts.onboarding.success.carousel1.text'),
        )}
        {buildCarouselSlide(
          <DriverOnboardingInfo2 />,
          translateText('corporateAccounts.onboarding.success.carousel2.title'),
          translateText('corporateAccounts.onboarding.success.carousel2.text'),
        )}
        {buildCarouselSlide(
          <DriverOnboardingInfo3 />,
          translateText('corporateAccounts.onboarding.success.carousel3.title'),
          translateText('corporateAccounts.onboarding.success.carousel3.text'),
          translateText('corporateAccounts.onboarding.success.carousel3.subText'),
        )}
      </Carousel>
      <div className={styles.actionBtnscontainer}>
        <div className={styles.actionBtns}>
          {currentSlide > 0 && (
            <Button
              type="secondary"
              className={styles.backBtn}
              onClick={moveToPrevSlide}
              data-testid="backBtn"
            >
              {translateText('corporateAccounts.actions.back')}
            </Button>
          )}
          {currentSlide === 2 ? (
            <OpenAppButton styles={styles} isOnboardingSuccess={true} />
          ) : (
            <Button
              type="primary"
              className={styles.actionBtn}
              onClick={moveToNextSlide}
              data-testid="nextBtn"
            >
              {translateText('corporateAccounts.actions.next')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default OnboardingSuccess;
