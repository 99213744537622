import { useIntl } from 'react-intl';
import enUS from 'locales/en-US';
import { useFetchLocale } from 'services/reactQueryHooks/localeStore';

const keyToIgnore = 'DEFAULT_TRANSLATION_KEY_FOR_OVERRIDE';

const useTranslation = locale => {
  const intl = useIntl();

  const defaultInterpolationOptions = {
    p: chunks => <p>{chunks}</p>,
    strong: chunks => <strong>{chunks}</strong>,
  };

  // This is for a use-case where we need to force a translation to a locale that is different from the provider's current state
  const { data: forceLocaleMessages } = useFetchLocale(locale);

  const translateText = (translationKey, options = {}) => {
    const { defaultMessage, override, ...interpolations } = options;

    if (override) {
      const message = forceLocaleMessages[translationKey];
      return intl.formatMessage(
        {
          id: keyToIgnore,
          defaultMessage: defaultMessage || message || enUS[translationKey] || translationKey,
        },
        { ...interpolations, ...defaultInterpolationOptions },
      );
    }

    return intl.formatMessage(
      {
        id: translationKey,
        defaultMessage: defaultMessage || enUS[translationKey] || translationKey,
      },
      { ...interpolations, ...defaultInterpolationOptions },
    );
  };

  return {
    translateText,
  };
};

export default useTranslation;
