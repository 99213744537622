import useGetOnboardingVideoUrl from 'utils/hooks/useGetOnboardingVideoUrl';
import useYouTubePlayer from 'utils/hooks/useYouTubePlayer';
import ExtendedModal from '../Modal';

import styles from './style.module.scss';

const HowToVideo = ({ topic, title, isVisible, setIsVisible }) => {
  const videoUrl = useGetOnboardingVideoUrl({ topic });
  const howToVideo = useYouTubePlayer({
    title: title,
    videoUrl,
    width: 800,
    height: 450,
  });

  return (
    <ExtendedModal
      visible={isVisible}
      footer={null}
      title={title}
      width={850}
      onCancel={() => setIsVisible(false)}
      destroyOnClose={true}
    >
      <div className={styles.modalContainer} data-testid={'howToModal'}>
        {howToVideo}
      </div>
    </ExtendedModal>
  );
};

export default HowToVideo;
