import React from 'react';

const ChangeIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.58489 0.924079C8.51022 1.21033 9.35739 1.72166 9.9993 2.36541V1.34637C9.9993 0.978747 10.2982 0.680729 10.667 0.680729C11.0357 0.680729 11.3347 0.978747 11.3347 1.34637V4.66884C11.3347 5.03646 11.0357 5.33448 10.667 5.33448H7.33366C6.9649 5.33448 6.66596 5.03646 6.66596 4.66884C6.66596 4.30121 6.9649 4.0032 7.33366 4.0032H9.59118C9.16779 3.2517 8.2874 2.535 7.19002 2.19553C5.99564 1.82604 4.61858 1.92732 3.41175 2.86768C2.70999 3.41449 2.29049 4.27147 2.16368 4.81905C2.08074 5.17719 1.72222 5.40026 1.36292 5.3173C1.00361 5.23434 0.779571 4.87676 0.862514 4.51862C1.04348 3.7372 1.59984 2.58921 2.5889 1.81853C4.18985 0.571082 6.03619 0.444988 7.58489 0.924079ZM4.42069 11.0772C3.49535 10.7909 2.64818 10.2796 2.00628 9.63587V10.6549C2.00628 11.0225 1.70734 11.3206 1.33859 11.3206C0.969833 11.3206 0.670898 11.0225 0.670898 10.6549V7.33244C0.670898 6.96482 0.969834 6.6668 1.33859 6.6668H4.67192C5.04068 6.6668 5.33961 6.96482 5.33961 7.33245C5.33961 7.70007 5.04068 7.99809 4.67192 7.99809L2.41439 7.99809C2.83779 8.74958 3.71818 9.46628 4.81556 9.80575C6.00994 10.1752 7.38699 10.074 8.59383 9.1336C9.29559 8.5868 9.71509 7.72981 9.8419 7.18223C9.92484 6.82409 10.2834 6.60102 10.6427 6.68398C11.002 6.76694 11.226 7.12452 11.1431 7.48266C10.9621 8.26408 10.4057 9.41208 9.41668 10.1827C7.81572 11.4302 5.96939 11.5563 4.42069 11.0772Z"
      fill="#4D4D4D"
    />
  </svg>
);

export default ChangeIcon;
