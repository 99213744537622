import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form, Input, Row, Col, Button, Typography, Select, Tooltip } from 'antd';
import { injectIntl } from 'react-intl';
import { InfoCircleOutlined } from '@ant-design/icons';
import { isPostalCode } from 'validator';
import { getJurisdictionLabel, getTaxNumberLabel } from 'utils/region';
import useTranslation from 'utils/hooks/useTranslation';
import useAmplitude from 'utils/hooks/useAmplitude';
import { MAXIMUM_CHAR_COUNT } from 'utils/formUtils/validateCharCountUtil.js';
import { validateTaxNumber } from 'utils/taxNumberUtil';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import { SUPPORTED_COUNTRIES } from 'constants.js';
import { CORPORATE_CLIENT_INDUSTRY_OPTIONS } from 'constants/corporateClients.js';
import {
  REGION_CODES,
  JURISDICTION_REQUIRED_COUNTRIES,
  TAX_NUMBER_REQUIRED_COUNTRIES,
} from 'regions';
import Can from 'config/Can';
import useIsFormValid from './useIsFormValid';
import { CREDIT_ACCOUNT_STATUS } from 'constants/creditAccount';
import { toCamelCase } from 'pages/CreditAccount/helper';

import styles from './style.module.scss';

const { Title, Text } = Typography;
const { Option } = Select;
const { Item } = Form;

const CorporateClientForm = ({ isMobile, isRegister, isLoading, billingInformation, onSubmit }) => {
  const { sendAmplitudeEvent } = useAmplitude();
  const { translateText } = useTranslation();
  const [form] = Form.useForm();
  const { validateFields, setFieldsValue, getFieldsValue } = form;
  const { isFormValid, validateForm } = useIsFormValid({ form });

  const [activeCountry, setActiveCountry] = useState(billingInformation?.address?.country);
  const isTaxNumberRequired = TAX_NUMBER_REQUIRED_COUNTRIES.includes(activeCountry);
  const taxNumberLabel = getTaxNumberLabel(activeCountry, translateText);

  const characterCountExceededMessage = translateText(
    'corporateAccounts.common.error.characterCountExceeded',
  );
  const formItemSize = 24;
  const responsiveColSpan = isMobile ? formItemSize : 12;
  const layout = {
    labelCol: { span: 24 },
  };

  const creditAccountStatus = useSelector(state =>
    toCamelCase(state.user?.currentCorporation?.creditAccountStatus),
  );

  const isDisabledBasedOnCreditAccountStatus =
    creditAccountStatus === CREDIT_ACCOUNT_STATUS.ACTIVE ||
    creditAccountStatus === CREDIT_ACCOUNT_STATUS.ON_HOLD ||
    creditAccountStatus === CREDIT_ACCOUNT_STATUS.PENDING;

  const handleSubmit = async () => {
    sendAmplitudeEvent(
      isRegister
        ? AMPLITUDE_EVENT_TYPES.selfRegistrationRegisterCorporationButton
        : AMPLITUDE_EVENT_TYPES.billingSaveButton,
    );

    try {
      const formData = await validateFields();
      onSubmit({ formData });
    } catch (error) {}
  };

  const handleCountryChange = selectedCountry => {
    const formData = getFieldsValue();
    setFieldsValue({
      ...formData,
      address: {
        ...formData.address,
        jurisdiction: null,
      },
    });
    validateForm();
    setActiveCountry(selectedCountry);
    isRegister &&
      sendAmplitudeEvent(
        AMPLITUDE_EVENT_TYPES.selfRegistrationCorporateClientRegistrationCountryFieldClicked,
      );
  };

  useEffect(() => {
    setFieldsValue(billingInformation);
    setActiveCountry(billingInformation?.address.country);
  }, [billingInformation, setFieldsValue]);

  const postalCodeValidator = countryCode => (_, value) => {
    if (!value || isPostalCode(value, countryCode)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Invalid postal code'));
  };

  return (
    <div className={styles.form}>
      <Can I="edit" a="Billing" passThrough>
        {can => (
          <Form form={form} {...layout} onFieldsChange={validateForm} requiredMark="optional">
            {isRegister && (
              <Row gutter={formItemSize}>
                <Col span={formItemSize} className={styles.registerHeader}>
                  <Title level={2}>
                    {translateText('corporateAccounts.corporations.register.title')}
                  </Title>
                  <Text>{translateText('corporateAccounts.corporations.register.subtitle')}</Text>
                </Col>
              </Row>
            )}
            <Row gutter={formItemSize}>
              <Col
                span={formItemSize}
                onFocus={() =>
                  isRegister &&
                  sendAmplitudeEvent(
                    AMPLITUDE_EVENT_TYPES.selfRegistrationCorporateClientRegistrationCompanyNameFieldClicked,
                  )
                }
              >
                <Can I="edit_corporate_client_name" a="Billing" passThrough>
                  {canEditCorporateClientName => (
                    <Item
                      name="corporateClientName"
                      label={translateText('corporateAccounts.corporateClient.name')}
                      data-testid="corporateClientName"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: translateText(
                            'corporateAccounts.corporateClient.name.error.presence',
                          ),
                        },
                        MAXIMUM_CHAR_COUNT({ message: characterCountExceededMessage }),
                      ]}
                    >
                      <Input
                        disabled={
                          !can ||
                          (!isRegister && !canEditCorporateClientName) ||
                          isDisabledBasedOnCreditAccountStatus
                        }
                      />
                    </Item>
                  )}
                </Can>
              </Col>
            </Row>
            {isRegister && (
              <Row gutter={formItemSize}>
                <Col
                  span={responsiveColSpan}
                  onFocus={() =>
                    sendAmplitudeEvent(
                      AMPLITUDE_EVENT_TYPES.selfRegistrationCorporateClientRegistrationDriverCountFieldClicked,
                    )
                  }
                >
                  <Item
                    name={['extensionData', 'numberOfDrivers']}
                    label={
                      <Tooltip
                        title={translateText('corporateAccounts.corporateClient.fleetSize.tooltip')}
                      >
                        {translateText('corporateAccounts.corporateClient.fleetSize')}
                        <InfoCircleOutlined
                          className="infoCircle"
                          style={{ fontSize: '16px', color: '#08c' }}
                        />
                      </Tooltip>
                    }
                    data-testid="numberOfDrivers"
                    rules={[
                      {
                        required: isRegister,
                        message: translateText(
                          'corporateAccounts.corporateClient.fleetSize.error.presence',
                        ),
                      },
                      MAXIMUM_CHAR_COUNT({ message: characterCountExceededMessage }),
                    ]}
                  >
                    <Input disabled={!can || isDisabledBasedOnCreditAccountStatus} />
                  </Item>
                </Col>
                <Col span={responsiveColSpan}>
                  <Item
                    name={['extensionData', 'industry']}
                    label={translateText('corporateAccounts.corporateClient.industry')}
                    data-testid="industry"
                  >
                    <Select
                      disabled={!can || isDisabledBasedOnCreditAccountStatus}
                      placeholder={translateText(
                        'corporateAccounts.corporateClient.industry.placeholder',
                      )}
                      onChange={() =>
                        sendAmplitudeEvent(
                          AMPLITUDE_EVENT_TYPES.selfRegistrationCorporateClientRegistrationCompanyIndustryFieldClicked,
                        )
                      }
                    >
                      {CORPORATE_CLIENT_INDUSTRY_OPTIONS.map(({ label, value }) => (
                        <Option key={`industry-${value}`} value={value}>
                          {translateText(label)}
                        </Option>
                      ))}
                    </Select>
                  </Item>
                </Col>
              </Row>
            )}
            {isRegister && (
              <Title level={4} className={styles.addressTitle}>
                {translateText('corporateAccounts.corporateClient.register.addressTitle')}
              </Title>
            )}
            <Row gutter={formItemSize}>
              <Col
                span={formItemSize}
                onFocus={() =>
                  isRegister &&
                  sendAmplitudeEvent(
                    AMPLITUDE_EVENT_TYPES.selfRegistrationCorporateClientRegistrationStreetAddressFieldClicked,
                  )
                }
              >
                <Item
                  name={['address', 'address1']}
                  label={translateText('corporateAccounts.corporateClient.streetAddress')}
                  data-testid="streetAddress"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: translateText(
                        'corporateAccounts.corporateClient.address.error.presence',
                      ),
                    },
                    MAXIMUM_CHAR_COUNT({ message: characterCountExceededMessage }),
                  ]}
                >
                  <Input disabled={!can || isDisabledBasedOnCreditAccountStatus} />
                </Item>
              </Col>
            </Row>
            <Row gutter={formItemSize}>
              <Col
                span={formItemSize}
                onFocus={() =>
                  isRegister &&
                  sendAmplitudeEvent(
                    AMPLITUDE_EVENT_TYPES.selfRegistrationCorporateClientRegistrationOfficeNumFieldClicked,
                  )
                }
              >
                <Item
                  name={['address', 'address2']}
                  label={translateText('corporateAccounts.corporateClient.officeNumber')}
                  data-testid="officeNumberContainer"
                  required={true}
                  rules={[
                    { required: false }, // CA-2752: this is a workaround to make the field optional, but not show the (Optional) mark
                    MAXIMUM_CHAR_COUNT({ message: characterCountExceededMessage }),
                  ]}
                >
                  <Input
                    data-testid="officeNumber"
                    disabled={!can || isDisabledBasedOnCreditAccountStatus}
                  />
                </Item>
              </Col>
            </Row>
            <Row gutter={formItemSize}>
              <Col span={formItemSize}>
                <Item
                  name={['address', 'country']}
                  label={translateText('corporateAccounts.corporateClient.country')}
                  rules={[
                    {
                      required: true,
                      message: translateText(
                        'corporateAccounts.corporateClient.country.error.presence',
                      ),
                    },
                  ]}
                >
                  <Select
                    disabled={!can || !isRegister || isDisabledBasedOnCreditAccountStatus}
                    readOnly={!isRegister}
                    onChange={selectedCountry => handleCountryChange(selectedCountry)}
                    placeholder={translateText(
                      'corporateAccounts.corporateClient.country.placeholder',
                    )}
                    data-testid="corp-client-form-country"
                  >
                    {SUPPORTED_COUNTRIES.map(({ label, countryCode }) => (
                      <Option key={countryCode} value={countryCode}>
                        {translateText(`corporateAccounts.countries.${countryCode.toLowerCase()}`)}
                      </Option>
                    ))}
                  </Select>
                </Item>
              </Col>
            </Row>
            <Row gutter={formItemSize}>
              <Col span={formItemSize}>
                <Item
                  name={['address', 'jurisdiction']}
                  hidden={!JURISDICTION_REQUIRED_COUNTRIES.includes(activeCountry)}
                  label={getJurisdictionLabel(activeCountry, translateText)}
                  data-testid="jurisdiction"
                  rules={[
                    {
                      required: JURISDICTION_REQUIRED_COUNTRIES.includes(activeCountry),
                      message: translateText(
                        'corporateAccounts.corporateClient.region.error.presence',
                      ),
                    },
                  ]}
                >
                  <Select
                    disabled={!can || isDisabledBasedOnCreditAccountStatus}
                    placeholder={translateText(
                      'corporateAccounts.corporateClient.region.placeholder',
                    )}
                    onChange={() =>
                      isRegister &&
                      sendAmplitudeEvent(
                        AMPLITUDE_EVENT_TYPES.selfRegistrationCorporateClientRegistrationRegionFieldClicked,
                      )
                    }
                  >
                    {REGION_CODES[activeCountry] &&
                      Object.keys(REGION_CODES[activeCountry]).map((_region, index) => (
                        <Option
                          key={REGION_CODES[activeCountry][index].value}
                          value={REGION_CODES[activeCountry][index].value}
                        >
                          {REGION_CODES[activeCountry][index].label}
                        </Option>
                      ))}
                  </Select>
                </Item>
              </Col>
            </Row>
            <Row gutter={formItemSize}>
              <Col
                span={responsiveColSpan}
                onFocus={() =>
                  isRegister &&
                  sendAmplitudeEvent(
                    AMPLITUDE_EVENT_TYPES.selfRegistrationCorporateClientRegistrationCityFieldClicked,
                  )
                }
              >
                <Item
                  name={['address', 'city']}
                  label={translateText('corporateAccounts.corporateClient.city')}
                  data-testid="city"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: translateText(
                        'corporateAccounts.corporateClient.city.error.presence',
                      ),
                    },
                    MAXIMUM_CHAR_COUNT({ message: characterCountExceededMessage }),
                  ]}
                >
                  <Input disabled={!can || isDisabledBasedOnCreditAccountStatus} />
                </Item>
              </Col>
              <Col
                span={responsiveColSpan}
                onFocus={() =>
                  isRegister &&
                  sendAmplitudeEvent(
                    AMPLITUDE_EVENT_TYPES.selfRegistrationCorporateClientRegistrationPostalFieldClicked,
                  )
                }
              >
                <Item
                  name={['address', 'postalCode']}
                  label={translateText('corporateAccounts.corporateClient.postalCode')}
                  data-testid="postalCode"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: translateText(
                        'corporateAccounts.corporateClient.postalCode.error.presence',
                      ),
                    },
                    MAXIMUM_CHAR_COUNT({ message: characterCountExceededMessage }),
                    {
                      validator: postalCodeValidator(activeCountry),
                      message: translateText(
                        'corporateAccounts.corporateClient.postalCode.error.validity',
                      ),
                    },
                  ]}
                >
                  <Input
                    disabled={!can || !activeCountry || isDisabledBasedOnCreditAccountStatus}
                    data-testid="postalCodeInput"
                  />
                </Item>
              </Col>
            </Row>
            <Row gutter={formItemSize}>
              <Col
                span={formItemSize}
                onFocus={() =>
                  isRegister &&
                  sendAmplitudeEvent(
                    AMPLITUDE_EVENT_TYPES.selfRegistrationCorporateClientRegistrationTaxFieldClicked,
                  )
                }
              >
                <Item
                  name="taxNumber"
                  label={taxNumberLabel}
                  data-testid="taxNumberContainer"
                  hasFeedback={isTaxNumberRequired}
                  rules={[
                    {
                      required: isTaxNumberRequired,
                      message: translateText(
                        'corporateAccounts.corporateClient.taxNumber.error_presence',
                        {
                          taxNumber: taxNumberLabel,
                        },
                      ),
                    },
                    {
                      validator: (_, value) =>
                        value ? validateTaxNumber(value, translateText) : Promise.resolve(),
                    },
                  ]}
                >
                  <Input
                    data-testid="taxNumber"
                    disabled={!can || isDisabledBasedOnCreditAccountStatus}
                  />
                </Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Text>
                  {!isRegister && translateText('corporateAccounts.corporateClient.billing.Info')}
                </Text>
              </Col>
            </Row>
            {can && (
              <Row gutter={formItemSize}>
                <Col span={formItemSize} className={styles.saveButton}>
                  <Button
                    type="primary"
                    size="large"
                    data-testid="saveButton"
                    onClick={() => handleSubmit()}
                    loading={isLoading}
                    disabled={!isFormValid || isDisabledBasedOnCreditAccountStatus}
                  >
                    {translateText('corporateAccounts.actions.save')}
                  </Button>
                </Col>
              </Row>
            )}
          </Form>
        )}
      </Can>
    </div>
  );
};

export default injectIntl(CorporateClientForm);
