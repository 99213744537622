import React, { forwardRef } from 'react';
import { Input } from 'antd';
import { Number } from 'react-credit-card-primitives';

const CreditCardNumber = ({ isEditMode, onChange, value, valid, ...other }, ref) => {
  if (isEditMode) return <Input value={value} disabled readOnly />;

  return (
    <Number
      onChange={onChange}
      render={({ getInputProps, placeholder }) => (
        <Input ref={ref} placeholder={placeholder} {...getInputProps()} {...other} />
      )}
    />
  );
};

export default forwardRef(CreditCardNumber);
