import React from 'react';
import styles from './style.module.scss';
import { corporateClientMenuItems } from './helper';
import MenuGenerator from './MenuGenerator';

const CorporateClientMenu = ({ onMenuClick, selectedKeys, theme, isAdmin }) => {
  return (
    <div>
      <div
        className={
          theme === 'light' ? styles.corporateClientDividerLight : styles.corporateClientDividerDark
        }
      />
      <MenuGenerator
        testid="corporateClientMenu"
        theme={theme}
        onMenuClick={onMenuClick}
        menuItems={corporateClientMenuItems}
        selectedKeys={selectedKeys}
        isAdmin={isAdmin}
      />
    </div>
  );
};

export default CorporateClientMenu;
