import React from 'react';
import { Button } from 'antd';
import { PAYMENT_STATUS_TAGS } from 'constants/paymentsSpa';
import UtilTag from 'components/Shared/Tag';
import Can from '../../config/Can';

const tableColumns = (editSpa, deleteSpa, translateText) => {
  return [
    {
      title: translateText('corporateAccounts.payments.cardName'),
      dataIndex: 'note',
    },
    {
      title: translateText('corporateAccounts.payments.cardNumber'),
      dataIndex: 'cardNumber',
    },
    {
      title: translateText('corporateAccounts.payments.cardType'),
      dataIndex: 'paymentCardType',
    },
    {
      title: translateText('corporateAccounts.payments.expiryMonth'),
      dataIndex: 'expiryMonth',
    },
    {
      title: translateText('corporateAccounts.payments.expiryYear'),
      dataIndex: 'expiryYear',
    },
    {
      title: translateText('corporateAccounts.common.status'),
      key: 'status',
      align: 'center',
      render: ({ methodStatus }) => {
        const status = PAYMENT_STATUS_TAGS[methodStatus];
        return status ? (
          <UtilTag
            content={translateText(`corporateAccounts.common.status.${status.key}`)}
            type={status.key}
          />
        ) : null;
      },
    },
    {
      title: translateText('corporateAccounts.actions'),
      key: 'actions',
      align: 'center',
      render: (_text, formData) => (
        <>
          <Can I="edit" a="PaymentSpa" passThrough>
            {can => (
              <Button
                disabled={!can}
                size="small"
                style={{ marginRight: '8px' }}
                onClick={() => {
                  editSpa(formData);
                }}
              >
                {translateText('corporateAccounts.actions.edit')}
              </Button>
            )}
          </Can>
          <Can I="destroy" a="PaymentSpa" passThrough>
            {can => (
              <Button disabled={!can} danger size="small" onClick={() => deleteSpa(formData)}>
                {translateText('corporateAccounts.actions.delete')}
              </Button>
            )}
          </Can>
        </>
      ),
    },
  ];
};

export default tableColumns;
