import React from 'react';

const PageTable = ({ visible, table, styles = {} }) => {
  if (!visible) return null;

  return (
    <div className="card-body card-body-table-padding-top">
      <div {...styles}>{table}</div>
    </div>
  );
};

export default PageTable;
