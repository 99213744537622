import React from 'react';
import useTranslation from 'utils/hooks/useTranslation';
import { Typography } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';

import styles from './style.module.scss';

const CreditAccountPendingBanner = () => {
  const { translateText } = useTranslation();

  return (
    <div className={styles.pendingBannerContainer}>
      <section className={styles.pendingBannerSection}>
        <InfoCircleFilled className={styles.pendingBannerIcon} />
        <Typography>
          {translateText('corporateAccounts.creditAccount.pendingBanner.description')}
        </Typography>
      </section>
    </div>
  );
};

export default CreditAccountPendingBanner;
