import React from 'react';

const TransactionReportIcon = ({ active }) => (
  <svg width="17" height="17" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.714 13.0006H2.42829V1.8577C2.42829 1.77913 2.36401 1.71484 2.28544 1.71484H1.28544C1.20686 1.71484 1.14258 1.77913 1.14258 1.8577V14.1434C1.14258 14.222 1.20686 14.2863 1.28544 14.2863H14.714C14.7926 14.2863 14.8569 14.222 14.8569 14.1434V13.1434C14.8569 13.0648 14.7926 13.0006 14.714 13.0006ZM3.99972 11.572H4.99972C5.07829 11.572 5.14258 11.5077 5.14258 11.4291V8.8577C5.14258 8.77913 5.07829 8.71484 4.99972 8.71484H3.99972C3.92115 8.71484 3.85686 8.77913 3.85686 8.8577V11.4291C3.85686 11.5077 3.92115 11.572 3.99972 11.572ZM6.71401 11.572H7.71401C7.79258 11.572 7.85687 11.5077 7.85687 11.4291V5.71484C7.85687 5.63627 7.79258 5.57199 7.71401 5.57199H6.71401C6.63544 5.57199 6.57115 5.63627 6.57115 5.71484V11.4291C6.57115 11.5077 6.63544 11.572 6.71401 11.572ZM9.42829 11.572H10.4283C10.5069 11.572 10.5712 11.5077 10.5712 11.4291V7.1077C10.5712 7.02913 10.5069 6.96484 10.4283 6.96484H9.42829C9.34972 6.96484 9.28544 7.02913 9.28544 7.1077V11.4291C9.28544 11.5077 9.34972 11.572 9.42829 11.572ZM12.1426 11.572H13.1426C13.2212 11.572 13.2854 11.5077 13.2854 11.4291V4.28627C13.2854 4.2077 13.2212 4.14342 13.1426 4.14342H12.1426C12.064 4.14342 11.9997 4.2077 11.9997 4.28627V11.4291C11.9997 11.5077 12.064 11.572 12.1426 11.572Z"
      fill={active ? '#6CC24A' : '#000'}
    />
  </svg>
);

export default TransactionReportIcon;
