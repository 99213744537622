import { alphanumericRegex } from './regex';

const upperCaseToBit = char => (char.match(/[A-Z]/) ? '1' : '0');
const binaryToSymbol = digit =>
  digit <= 25 ? String.fromCharCode(digit + 65) : String.fromCharCode(digit - 26 + 48);

export const isValidSalesforceId = (id, translateText) => {
  const is15Characters = id.length === 15;
  const is18Characters = id.length === 18;
  if (!(is15Characters || is18Characters)) {
    return Promise.reject(
      translateText('corporateAccounts.corporateClient.salesforceId.error.length'),
    );
  }

  const isAlphanumeric = alphanumericRegex.test(id);
  if (!isAlphanumeric) {
    return Promise.reject(
      translateText('corporateAccounts.corporateClient.salesforceId.error.alphanumeric'),
    );
  }

  // If 15 characters, don't need to validate checksum
  if (is15Characters) {
    return Promise.resolve('Salesforce ID is valid');
  }

  const parts = [
    id
      .slice(0, 5)
      .split('')
      .reverse()
      .map(upperCaseToBit)
      .join(''),
    id
      .slice(5, 10)
      .split('')
      .reverse()
      .map(upperCaseToBit)
      .join(''),
    id
      .slice(10, 15)
      .split('')
      .reverse()
      .map(upperCaseToBit)
      .join(''),
  ];

  const check = parts.map(str => binaryToSymbol(parseInt(str, 2))).join('');
  const doesCheckMatchLastThree = check === id.slice(-3);
  if (!doesCheckMatchLastThree) {
    return Promise.reject(
      translateText('corporateAccounts.corporateClient.salesforceId.error.invalid'),
    );
  }

  return Promise.resolve('Salesforce ID is valid');
};
