import helpUrls from 'constants/helpCentre';

const getHelpSupportUrls = country => {
  const helpUrl = helpUrls[country];

  if (!helpUrl) {
    return {
      support: helpUrls.EN.SUPPORT_URL,
      helpCentre: helpUrls.EN.HELP_CENTRE_URL,
    };
  }

  return {
    support: helpUrl.SUPPORT_URL,
    helpCentre: helpUrl.HELP_CENTRE_URL,
  };
};

export default getHelpSupportUrls;
