import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import Icon from '@ant-design/icons';
import useTranslation from 'utils/hooks/useTranslation';
import styles from './style.module.scss';
import { staffUserMenuItems } from './helper';

const StaffUserMenu = ({ onMenuClick, selectedKeys, theme, isSuperAdmin }) => {
  const { translateText } = useTranslation();
  return (
    <div>
      <div className={styles.staffUserDivider} />
      <Menu
        data-testid="staffUserMenu"
        theme={theme}
        onClick={onMenuClick}
        selectedKeys={selectedKeys}
        mode="inline"
        className={styles.navigation}
      >
        {staffUserMenuItems.map(menuItem => {
          if (!isSuperAdmin && menuItem.superAdminViewOnly) {
            return null;
          }
          return (
            <Menu.Item key={menuItem.key} disabled={menuItem.disabled}>
              <Link
                to={menuItem.url}
                data-testid={
                  selectedKeys.includes(menuItem.key) ? `selectedMenuItem-${menuItem.key}` : null
                }
              >
                <Icon component={menuItem.component} className={styles.icon} />
                <span className={styles.title}>
                  {translateText(`corporateAccounts.menuLeft.${menuItem.key}`, menuItem.title)}
                </span>
              </Link>
            </Menu.Item>
          );
        })}
      </Menu>
    </div>
  );
};

export default StaffUserMenu;
