/* eslint-disable */
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import userActions from 'redux/user/actions';
import creditAccount from 'services/api/creditAccounts';
import notify from 'utils/notificationUtils';
import useTranslation from 'utils/hooks/useTranslation';
import creditAccountMigrationConfigManager from 'utils/creditAccountMigrationConfigManager';
import { CREDIT_ACCOUNT_STATUS_MAPPING } from 'constants/creditAccount';

const toCamelCase = string => string?.charAt(0).toLowerCase() + string?.slice(1);

export const useGetCreditAccountDetails = ({ corporateClientId }) => {
  const { translateText } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state);

  const getCreditAccountDetails = async () => {
    const result = await creditAccount.getCreditAccount(corporateClientId);

    return result?.data;
  };

  return useQuery({
    queryKey: [{ corporateClientId }],
    queryFn: getCreditAccountDetails,
    enabled: !!corporateClientId,
    retry: false,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    onSuccess: data => {
      if (!data?.length) return;
      const updatedCorporation = {
        ...user.currentCorporation,
        creditAccountStatus:
          CREDIT_ACCOUNT_STATUS_MAPPING[toCamelCase(data[0]?.creditAccountStatus)],
      };
      const creditAccountMigrationConfig = creditAccountMigrationConfigManager({
        corporation: updatedCorporation,
        override: {
          isCreditAccountInfoModalVisible: false,
        },
      });
      dispatch({
        type: userActions.SET_STATE,
        payload: { ...user, creditAccountMigrationConfig },
      });
    },
    onError: () => {
      notify({
        type: 'error',
        message: translateText('corporateAccounts.creditAccount.internalError'),
      });
    },
  });
};
