import { useMutation, useQuery, useQueryClient } from 'react-query';
import staffUsersApi from 'services/api/staffUsers';
import notify from 'utils/notificationUtils';
import { staffUserQueryKeys } from './queryKeys';

const { getStaffUserQuery } = staffUserQueryKeys;

// Translation for staff user page may be skipped as it is an internal tool
export const useGetStaffUsers = () => {
  const loadStaffUsers = async () => {
    const result = await staffUsersApi.read();
    return result?.data;
  };

  return useQuery({
    queryKey: [getStaffUserQuery],
    queryFn: loadStaffUsers,
    keepPreviousData: false,
    onError: () => {
      notify({
        type: 'error',
        title: 'Error loading staff users',
        message: 'We encountered an error retrieving staff users. Please try again.',
      });
    },
    staleTime: 5000,
  });
};

export const useSendStaffActivation = () => {
  const sendActivation = async ({ userId, email }) => {
    await staffUsersApi.sendInvite(userId);
    return { email };
  };

  return useMutation(sendActivation, {
    onSuccess: ({ email }) => {
      notify({
        type: 'success',
        title: 'Activation invite successful',
        message: `Invite sent to email ${email}`,
      });
    },
    onError: () => {
      notify({
        type: 'error',
        title: 'Error',
        message: 'We were unable to send the activation invite.',
      });
    },
  });
};

export const useDeleteStaffUser = () => {
  const queryClient = useQueryClient();
  const deleteStaffUser = async ({ userId, name }) => {
    await staffUsersApi.destroy(userId);
    return { name, userId };
  };

  return useMutation(deleteStaffUser, {
    onSuccess: ({ name }) => {
      queryClient.invalidateQueries(getStaffUserQuery, { exact: true });
      notify({
        type: 'success',
        title: 'Delete successful',
        message: `Staff user "${name}" has been removed successfully.`,
      });
    },
    onError: () => {
      notify({
        type: 'error',
        title: 'Error',
        message: 'We were unable to complete the delete request.',
      });
    },
  });
};

export const useCreateStaffUser = ({ onSuccess }) => {
  const queryClient = useQueryClient();

  const createStaffUser = async ({ payload }) => {
    await staffUsersApi.create(payload);
  };

  const handleError = error => {
    // todo, convert error.response.data.code to localized message
    notify({
      type: 'error',
      title: 'We were unable to complete the creation request.',
    });
  };

  const handleSuccess = (data, variables, context) => {
    // todo, display localized message
    notify({
      type: 'success',
      title: 'Creation successful',
    });
    queryClient.invalidateQueries(getStaffUserQuery, { exact: true });
    onSuccess();
  };

  return useMutation(createStaffUser, {
    onError: handleError,
    onSuccess: handleSuccess,
  });
};

export const useUpdateStaffUser = ({ onSuccess }) => {
  const queryClient = useQueryClient();

  const updateStaffUser = async ({ payload }) => {
    await staffUsersApi.update(payload);
  };

  const handleError = error => {
    // todo: convert error.response.data.code to localized message
    notify({
      type: 'error',
      title: 'We were unable to complete the update request.',
    });
  };

  const handleSuccess = (data, variables, context) => {
    // todo: display localized message
    notify({
      type: 'success',
      title: 'Update successful',
    });
    queryClient.invalidateQueries(getStaffUserQuery, { exact: true });
    onSuccess();
  };

  return useMutation(updateStaffUser, {
    onError: handleError,
    onSuccess: handleSuccess,
  });
};

export default {
  useGetStaffUsers,
  useCreateStaffUser,
  useUpdateStaffUser,
  useDeleteStaffUser,
  useSendStaffActivation,
};
