import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import useAmplitude from 'utils/hooks/useAmplitude';
import corporateClients from 'services/api/corporateClients';
import { corporationQueryKeys } from '../queryKeys';
import notify, { notifyErrorWithWorkflowId } from 'utils/notificationUtils';
import useTranslation from 'utils/hooks/useTranslation';
import {
  CORPORATE_CLIENT_PRICE_PLAN_ERROR_CODES,
  CREATE_CORPORATE_CLIENT_ERROR_CODES,
  CREDIT_ACCOUNT_MIGRATION_ERROR_CODES,
} from 'constants/corporateClients';
import useOnboardingProgressBar from 'components/Shared/OnboardingProgressBar/useOnboardingProgressBar';

const { getCorporationsQuery, getBillingInformationQuery } = corporationQueryKeys;

const CORPORATIONS_DATA_STALETIME = 30000;

export const useGetCorporations = ({ query, offset, limit, orderBy, filters }) => {
  const { adminRole } = useSelector(state => state.user);
  const loadCorporations = async ({ queryKey, pageParam }) => {
    const [, { query, offset, limit, orderBy, filters }] = queryKey;
    const result = await corporateClients.read({
      query,
      limit,
      offset,
      orderBy,
      ...filters,
      ...pageParam,
    });

    return result?.data;
  };

  return useQuery({
    queryKey: [getCorporationsQuery, { query, offset, limit, orderBy, filters }],
    queryFn: loadCorporations,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    staleTime: CORPORATIONS_DATA_STALETIME,
    enabled: adminRole ? true : false,
  });
};

export const useRegisterCorporation = () => {
  const dispatch = useDispatch();
  const { translateText } = useTranslation();
  const { sendAmplitudeEvent } = useAmplitude();
  const { onGetOnboardingProgress } = useOnboardingProgressBar();
  let registeredCorporation;

  const registerCorporation = async corporation => {
    const result = await corporateClients.register(corporation);
    registeredCorporation = result?.data;
    return result?.data;
  };

  return useMutation(registerCorporation, {
    onSuccess: () => {
      sendAmplitudeEvent(
        AMPLITUDE_EVENT_TYPES.selfRegistrationCorporateClientRegistrationSuccessful,
      );
      dispatch({
        type: 'user/SET_STATE',
        payload: {
          corporations: [registeredCorporation],
          corporateClientId: registeredCorporation?.id,
          currentCorporation: registeredCorporation,
          restrictedCorporateClientProspect: false,
          emailNotVerified: false,
        },
      });
      onGetOnboardingProgress(true);
    },
    onError: ({ errors }) => {
      sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.selfRegistrationCorporateClientRegistrationFailed);
      const domainErrorCode = errors?.domainErrorCode;
      if (
        domainErrorCode === CREATE_CORPORATE_CLIENT_ERROR_CODES.CORPORATE_CLIENT_NAME_ALREADY_EXISTS
      ) {
        notify({
          type: 'error',
          title: translateText(
            'corporateAccounts.corporateClient.register.duplicateNameError.title',
          ),
          message: translateText(
            'corporateAccounts.corporateClient.register.duplicateNameError.message',
          ),
        });
      } else {
        notify({
          type: 'error',
          title: translateText('corporateAccounts.corporateClient.register.internalError.title'),
          message: translateText(
            'corporateAccounts.corporateClient.register.internalError.message',
          ),
        });
      }
    },
  });
};

export const useActivateCorporation = () => {
  const { sendAmplitudeEvent } = useAmplitude();
  const { translateText } = useTranslation();
  const currentCorporation = useSelector(state => state.user.currentCorporation);
  const dispatch = useDispatch();

  const activateCorporation = async () => {
    const result = await corporateClients.activate();
    return result?.data;
  };

  return useMutation(activateCorporation, {
    onSuccess: () => {
      sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.selfRegistrationCorporateClientActivationSuccessful);
      dispatch({
        type: 'user/SET_STATE',
        payload: {
          onboardingProgress: {
            status: 'Active',
          },
          currentCorporation: { ...currentCorporation, status: 1, statusName: 'Active' },
        },
      });
    },
    onError: () => {
      sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.selfRegistrationCorporateClientActivationFailed);
      notify({
        type: 'error',
        message: translateText('corporateAccounts.corporateClient.activate.error.message'),
      });
    },
  });
};

export const useDeactivateCorporation = () => {
  const { translateText } = useTranslation();
  const queryClient = useQueryClient();
  const deactivateCorporation = async corporation => {
    const result = await corporateClients.deactivate(corporation);
    return result?.data;
  };

  return useMutation(deactivateCorporation, {
    onSuccess: deactivatedCorporation => {
      // TODO: add a function to update cached data and remove queryClient.invalidateQueries method
      queryClient.invalidateQueries(getCorporationsQuery, { exact: false });
      notify({
        type: 'success',
        message: translateText('corporateAccounts.admins.deactivate.success', {
          corporateClientName: deactivatedCorporation.corporateClientName,
        }),
      });
    },
    onError: (error, corporationToBeDeactivated) => {
      notify({
        type: 'error',
        title: translateText('corporateAccounts.admins.deactivate.error.title'),
        message: translateText('corporateAccounts.admins.deactivate.error.body', {
          corporateClientName: corporationToBeDeactivated.corporateClientName,
        }),
      });
    },
  });
};

export const useReactivateCorporation = () => {
  const { translateText } = useTranslation();
  const queryClient = useQueryClient();
  const reactivateCorporation = async corporation => {
    const result = await corporateClients.reactivate(corporation);
    return result?.data;
  };

  return useMutation(reactivateCorporation, {
    onSuccess: reactivatedCorporation => {
      // TODO: add a function to update cached data and remove queryClient.invalidateQueries method
      queryClient.invalidateQueries(getCorporationsQuery, { exact: false });
      notify({
        type: 'success',
        message: translateText('corporateAccounts.admins.reactivate.success', {
          corporateClientName: reactivatedCorporation.corporateClientName,
        }),
      });
    },
    onError: (error, corporationToBeReactivated) => {
      notify({
        type: 'error',
        title: translateText('corporateAccounts.admins.reactivate.error.title'),
        message: translateText('corporateAccounts.admins.reactivate.error.body', {
          corporateClientName: corporationToBeReactivated.corporateClientName,
        }),
      });
    },
  });
};

export const useDeleteCorporation = () => {
  const { translateText } = useTranslation();
  const queryClient = useQueryClient();
  const deleteCorporation = async corporation => {
    const result = await corporateClients.delete(corporation);

    return result?.data;
  };

  return useMutation(deleteCorporation, {
    onSuccess: () => {
      queryClient.invalidateQueries(getCorporationsQuery, { exact: false });
      notify({
        type: 'success',
        message: translateText('corporateAccounts.admins.delete.success'),
      });
    },
    onError: (error, corporationToBeDeleted) => {
      notify({
        type: 'error',
        title: translateText('corporateAccounts.admins.delete.error.title'),
        message: translateText('corporateAccounts.admins.delete.error.body', {
          corporateClientName: corporationToBeDeleted.corporateClientName,
        }),
      });
    },
  });
};

export const useGetOnboardingProgress = () => {
  const dispatch = useDispatch();

  const getOnboardingProgress = async () => {
    const result = await corporateClients.onboardingProgress();
    return result?.data;
  };

  return useQuery({
    queryFn: getOnboardingProgress,
    enabled: false,
    onSuccess: data => {
      const {
        status,
        isOnboardingComplete,
        isPaymentSetupComplete,
        isVehicleSetupComplete,
        isDriverSetupComplete,
      } = data;
      dispatch({
        type: 'user/SET_STATE',
        payload: {
          onboardingProgress: {
            status,
            isOnboardingComplete,
            progress: {
              isAccountSetupComplete: true,
              isPaymentSetupComplete,
              isVehicleSetupComplete,
              isDriverSetupComplete,
            },
          },
        },
      });
    },
  });
};

export const useGetBillingInformation = () => {
  const getBillingInformationById = async () => {
    const result = await corporateClients.getBillingInformationById();
    return result?.data;
  };

  return useQuery({
    queryKey: [getBillingInformationQuery],
    queryFn: getBillingInformationById,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    staleTime: 0,
  });
};

export const useEditBillingInformation = () => {
  const { translateText } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const currentCorporation = useSelector(state => state.user.currentCorporation);

  const editBillingInformation = async billingInformation => {
    const result = await corporateClients.updateBillingInformationById(billingInformation);
    return result?.data;
  };

  return useMutation(editBillingInformation, {
    onSuccess: result => {
      queryClient.invalidateQueries(getBillingInformationQuery, { exact: false });
      notify({
        type: 'success',
        message: translateText('corporateAccounts.corporateClient.billing.edit.success'),
      });
      dispatch({
        type: 'user/SET_STATE',
        payload: {
          currentCorporation: {
            ...currentCorporation,
            country: result.address.country,
            corporateClientName: result.corporateClientName,
            address: { city: result.address.city },
          },
        },
      });
    },
    onError: ({ errors }) => {
      const domainErrorCode = errors?.domainErrorCode;
      if (
        domainErrorCode === CREATE_CORPORATE_CLIENT_ERROR_CODES.CORPORATE_CLIENT_NAME_ALREADY_EXISTS
      ) {
        notify({
          type: 'error',
          title: translateText(
            'corporateAccounts.corporateClient.register.duplicateNameError.title',
          ),
          message: translateText(
            'corporateAccounts.corporateClient.register.duplicateNameError.message',
          ),
        });
      } else {
        notify({
          type: 'error',
          title: translateText('corporateAccounts.corporateClient.billing.edit.error.title'),
          message: translateText('corporateAccounts.corporateClient.billing.edit.error.message'),
        });
      }
    },
  });
};

export const useGetPricePlan = () => {
  const [enabled, setEnabled] = useState(false);

  const { translateText } = useTranslation();

  const corporateClientId = useSelector(state => state.user?.currentCorporation?.id);

  const getPricePlan = async () => {
    const result = await corporateClients.getPricePlan();
    return result?.data;
  };

  const query = useQuery({
    queryFn: getPricePlan,
    queryKey: ['getPricePlan', corporateClientId],
    refetchOnWindowFocus: false,
    enabled,
    onError: error => {
      const errorCode = error.errors?.domainErrorCode;

      if (
        errorCode ===
        CORPORATE_CLIENT_PRICE_PLAN_ERROR_CODES.PricePlanNotAvailableForNonSelfRegisteredClients
      ) {
        return;
      }

      // TODO: translations in CA-2707
      if (
        errorCode ===
          CORPORATE_CLIENT_PRICE_PLAN_ERROR_CODES.InvoiceIdNotAvailableForCorporateClient ||
        errorCode === CORPORATE_CLIENT_PRICE_PLAN_ERROR_CODES.PricePlanNotAvailable
      ) {
        const workflowId = error.errors?.workflowId;
        notifyErrorWithWorkflowId({
          errorCodeTitle: translateText('corporateAccounts.common.errorCode'),
          message: translateText('corporateAccounts.subscription.error.contactSupport'),
          workflowId: workflowId,
        });
      } else {
        notify({
          type: 'error',
          message: translateText('corporateAccounts.subscription.error.tryAgain'),
        });
      }
    },
  });

  return {
    ...query,
    fetchPricePlan: () => setEnabled(true),
  };
};

export const useMigrateCorporationToCreditAccount = () => {
  const { translateText } = useTranslation();
  const queryClient = useQueryClient();

  const creditAccountMigration = async payload => {
    const result = await corporateClients.migrateCorporationToCreditAccount(payload);
    return result?.data;
  };

  return useMutation(creditAccountMigration, {
    onSuccess: () => {
      queryClient.invalidateQueries(getCorporationsQuery, { exact: false });
      notify({
        type: 'success',
        message: translateText('corporateAccounts.creditAccountMigration.success'),
      });
    },
    onError: error => {
      const errorCode = error.errors?.domainErrorCode;

      if (errorCode === CREDIT_ACCOUNT_MIGRATION_ERROR_CODES.CorporateClientCountryNotSupported) {
        notify({
          type: 'error',
          message: translateText(
            'corporateAccounts.creditAccountMigration.error.countryNotSupported',
          ),
        });
      } else if (errorCode === CREDIT_ACCOUNT_MIGRATION_ERROR_CODES.CorporateClientNotFound) {
        notify({
          type: 'error',
          message: translateText('corporateAccounts.creditAccountMigration.error.notFound'),
        });
      } else {
        notify({
          type: 'error',
          message: translateText('corporateAccounts.creditAccountMigration.error'),
        });
      }
    },
  });
};

export default {
  useGetCorporations,
  useRegisterCorporation,
  useActivateCorporation,
  useDeleteCorporation,
  useReactivateCorporation,
  useDeactivateCorporation,
  useGetOnboardingProgress,
  useGetBillingInformation,
  useEditBillingInformation,
  useGetPricePlan,
  useMigrateCorporationToCreditAccount,
};
