const getCountryLabel = (country, translateText) => {
  const countryLocalization = translateText(
    `corporateAccounts.countries.${country.countryCode.toLowerCase()}`,
  );

  if (countryLocalization.includes('corporateAccounts.countries')) {
    return countryLocalization;
  }
  return countryLocalization
    .split(' ')
    .slice(0, -1)
    .join(' ');
};

export default getCountryLabel;
