import React from 'react';

const MenuLeftVehiclesIcon = ({ color = '#6C6C6C' }) => (
  <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon /vehicles">
      <path
        id="Vector"
        d="M4.15685 13.6556C3.60784 13.6556 3.14074 13.4633 2.75555 13.0787C2.37036 12.6941 2.17777 12.2272 2.17777 11.6778H1V4.1111C1 3.8111 1.11018 3.55092 1.33055 3.33055C1.55092 3.11018 1.8111 3 2.1111 3H11.6556V5.74445H13.4889L15.6667 8.64445V11.6778H14.4333C14.4333 12.2272 14.2412 12.6941 13.8569 13.0787C13.4726 13.4633 13.0059 13.6556 12.4569 13.6556C11.9078 13.6556 11.4407 13.4633 11.0556 13.0787C10.6704 12.6941 10.4778 12.2272 10.4778 11.6778H6.13333C6.13333 12.2259 5.94117 12.6926 5.55685 13.0778C5.17254 13.463 4.70587 13.6556 4.15685 13.6556ZM4.15555 12.5445C4.39629 12.5445 4.60093 12.4602 4.76945 12.2917C4.93796 12.1231 5.02222 11.9185 5.02222 11.6778C5.02222 11.437 4.93796 11.2324 4.76945 11.0639C4.60093 10.8954 4.39629 10.8111 4.15555 10.8111C3.91481 10.8111 3.71017 10.8954 3.54165 11.0639C3.37314 11.2324 3.28888 11.437 3.28888 11.6778C3.28888 11.9185 3.37314 12.1231 3.54165 12.2917C3.71017 12.4602 3.91481 12.5445 4.15555 12.5445ZM2.1111 10.5667H2.53332C2.72221 10.3 2.95384 10.087 3.22822 9.92778C3.50259 9.76852 3.80816 9.68888 4.1449 9.68888C4.48163 9.68888 4.78889 9.77037 5.06667 9.93333C5.34444 10.0963 5.57778 10.3074 5.76667 10.5667H10.5445V4.1111H2.1111V10.5667ZM12.4556 12.5445C12.6963 12.5445 12.9009 12.4602 13.0695 12.2917C13.238 12.1231 13.3222 11.9185 13.3222 11.6778C13.3222 11.437 13.238 11.2324 13.0695 11.0639C12.9009 10.8954 12.6963 10.8111 12.4556 10.8111C12.2148 10.8111 12.0102 10.8954 11.8417 11.0639C11.6731 11.2324 11.5889 11.437 11.5889 11.6778C11.5889 11.9185 11.6731 12.1231 11.8417 12.2917C12.0102 12.4602 12.2148 12.5445 12.4556 12.5445ZM11.6556 9.16667H14.6667L12.9333 6.85555H11.6556V9.16667Z"
        fill={color}
      />
    </g>
  </svg>
);

export default MenuLeftVehiclesIcon;
