import axios from 'axios';
import mergeWith from 'lodash.mergewith';
import isArray from 'lodash.isarray';
import userManager from 'utils/userManager';
import errorLoggerUtil from 'utils/errorLoggerUtil';
import notify from 'utils/notificationUtils';
import { getDomainErrorCode, setDomainErrorNotification } from 'utils/domainErrorUtil';
import { EMAIL_NOT_VERIFIED } from 'constants.js';
import { v4 as uuidv4 } from 'uuid';
import qs from 'qs';
import localizedMessage from './localize';

// Format errors into an object that can be be provided to Form.setFields
// https://github.com/react-component/form/blob/3b9959b57ab30b41d8890ff30c79a7e7c383cad3/examples/server-validate.js#L74-L79
const getValidationErrors = (data = []) => {
  let errors = {};
  /* eslint-disable consistent-return */
  const customizer = (a, b) => {
    if (isArray(a)) {
      return a.concat(b);
    }
  };
  /* eslint-enable consistent-return */
  if (!Array.isArray(data)) {
    errors = data;
  } else {
    data.forEach(row => {
      const errorCode = Object.prototype.hasOwnProperty.call(row, 'code');
      const PROFILE_CONFLICT_CODE = '10005';
      if (errorCode && row.code === PROFILE_CONFLICT_CODE) {
        // handles ida profile exception
        const idaError = row.detail;
        mergeWith(errors, { issue: idaError });
      } else {
        row.issues?.forEach(issue => {
          const field = {};
          const fieldName =
            issue.propertyName.charAt(0).toLowerCase() + issue.propertyName.slice(1);
          field[fieldName] = {
            errors: [new Error(issue.message)],
          };
          mergeWith(errors, field, customizer);
        });
      }
    });
  }
  return errors;
};

// To be deprecated once we migrate away from Redux Sagas for our server communication
const http = async options => {
  let res;
  let error;
  const workflowId = options.headers['X-Pbp-WorkflowId'] || uuidv4();

  if (!options.headers['X-Pbp-WorkflowId']) {
    options.headers['X-Pbp-WorkflowId'] = uuidv4();
  }

  try {
    options.paramsSerializer = params =>
      qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true });
    res = await axios(options);
  } catch (err) {
    const errorContext = {
      request: {
        method: options.method,
        url: options.url,
      },
      WorkflowId: workflowId,
    };
    error = err;
    res = err.response;

    const domainError = getDomainErrorCode(err.response);
    if (domainError) {
      // This is bit of a hack until the backend and frontend align in DomainErrorCode contracts for most network requests
      error.isDomainError = true;
      setDomainErrorNotification(domainError);
    } else if (err.response) {
      const { status, data } = err.response;
      error.status = status;

      // Server side validation errors will return a status of 400
      if (status === 400) {
        error.errors = getValidationErrors(data);
      }

      if (status === 401) {
        await userManager.signoutRedirect();
      }

      if (status === 403) {
        await userManager.signinSilent().catch(() => {
          notify({
            type: 'error',
            message: localizedMessage('corporateAccounts.errors.unauthorized.title'),
          });
        });
      }
    }

    errorLoggerUtil.ddErrorLogwithContext(`Request Error: \n${error.message}`, errorContext);
  }

  return {
    response: res,
    error,
    workflowId,
  };
};

// This is what we'll use with react-queries. The above http() can be deprecated
// Once we extract / refactor the logic out of redux-sagas and migrate them to React-queries
export const httpWrapper = async options => {
  const workflowId = options.headers['X-Pbp-WorkflowId'] || uuidv4();
  options.headers['X-Pbp-WorkflowId'] = workflowId;

  const requestContext = {
    request: {
      method: options.method,
      url: options.url,
    },
    WorkflowId: workflowId,
  };

  try {
    options.paramsSerializer = params =>
      qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true });
    const response = await axios(options);
    return response;
  } catch (err) {
    const domainErrorCode = err.response?.data?.domainErrorCode;

    if (err.response) {
      const { status } = err.response;

      if (err.response.data && typeof err.response.data === 'object') {
        err.response.data.workflowId = workflowId;
      }

      if (status === 400 || status === 404 || status === 409) {
        err.errors = err.response.data;
      }

      if (status === 401) {
        return userManager.signoutRedirect();
      }

      if (status === 403) {
        if (domainErrorCode === EMAIL_NOT_VERIFIED) {
          const userId = err.response?.data?.errors.UserId[0];

          return { domainErrorCode, userId };
        }

        await userManager.signinSilent().catch(() => {
          notify({
            type: 'error',
            message: localizedMessage('corporateAccounts.errors.unauthorized.title'),
          });
        });
      }
    }

    errorLoggerUtil.ddErrorLogwithContext(`Request Error: \n${err.message}`, requestContext);
    throw err;
  }
};

export const uriComponentEncodedString = data => {
  const encodedData = [];
  Object.keys(data).forEach(key =>
    encodedData.push(`${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`),
  );
  return encodedData.join('&');
};

export default http;
