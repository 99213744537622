const handleAddAdminUser = (
  corporateClientId,
  setInitialValues,
  setFormTitle,
  setFormVisibility,
  translateText,
) => {
  const title = translateText('corporateAccounts.admins.form.title.new');
  setInitialValues({ corporateClientId });
  setFormTitle(title);
  setFormVisibility(true);
};

const handleEditAdminUser = (
  id,
  tableData,
  setFormEdit,
  setFormData,
  setFormTitle,
  setFormVisibility,
  translateText,
) => {
  const title = translateText('corporateAccounts.admins.form.title.edit');
  const data = tableData.find(row => row.id === id);

  setFormEdit(true);
  setFormData(data);
  setFormTitle(title);
  setFormVisibility(true);
};

const handleDeleteAdminUser = (id, deleteAdminUser) => deleteAdminUser(id);

const handleSubmit = (
  addAdminUser,
  currentAccountOwner,
  downgradeAccountOwner,
  editAdminUser,
  values,
) => {
  if (currentAccountOwner) downgradeAccountOwner(currentAccountOwner);
  return values.id ? editAdminUser(values) : addAdminUser(values);
};

const handleCancel = (setFormVisibility, setFormEdit, setFormData, resetFormErrors, visible) => {
  setFormVisibility(visible);
  setFormEdit(false);
  resetFormErrors();
  setFormData();
};

const downgradeCurrentAccount = (
  selectedPrimary,
  currentAccount,
  currentRole,
  existingRole,
  tableData,
) => {
  const ACCOUNT_OWNER = 'primary_fleet_manager';
  const BUSINESS_MANAGER = 'fleet_manager';
  let account = currentAccount;
  if (!account) return false;

  if (selectedPrimary && currentRole !== existingRole) {
    account = tableData.find(user => user.role === ACCOUNT_OWNER);
    account.role = BUSINESS_MANAGER;
  } else {
    account.role = currentRole;
  }

  return account;
};

const adminUtil = {
  downgradeCurrentAccount,
  handleAddAdminUser,
  handleCancel,
  handleDeleteAdminUser,
  handleEditAdminUser,
  handleSubmit,
};

export default adminUtil;
