import React from 'react';
import ScaIframe from './ScaIframe';

const ScaOrderInitiatedIframe = ({ gatewayHtml }) => {
  return (
    <div data-testid="orderInitiatedIframe" style={{ display: 'none' }}>
      <ScaIframe iframeHtml={gatewayHtml} />
    </div>
  );
};

export default React.memo(ScaOrderInitiatedIframe);
