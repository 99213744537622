import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Form, Input, Select, Switch } from 'antd';
import { isPostalCode } from 'validator';
import { MAXIMUM_CHAR_COUNT, SUPPORTED_COUNTRIES, LANGUAGES } from 'constants.js';
import {
  JURISDICTION_REQUIRED_COUNTRIES,
  REGION_CODES,
  TAX_NUMBER_REQUIRED_COUNTRIES,
} from 'regions';
import updateRegionLabel, { getTaxNumberLabel } from 'utils/region';
import useTranslation from 'utils/hooks/useTranslation';
import { isValidSalesforceId } from 'utils/salesforceIdUtil';
import SalesforceIdInfoLabel from 'components/Layout/Forms/CorporationInfo/SalesforceIdInfoLabel';

const { Option } = Select;

const CorporationInfoStep = ({ form }) => {
  const { getFieldValue, setFieldsValue } = form;
  const [regionLabel, setRegionLabel] = useState('');
  const [activeCountry, setActiveCountry] = useState();
  const { translateText } = useTranslation();

  const corporateClient = useSelector(state => state.corporations.corporateClient);

  const taxNumberLabel = getTaxNumberLabel(activeCountry, translateText);

  useEffect(() => {
    setFieldsValue(
      REGION_CODES[activeCountry]
        ? { region: REGION_CODES[activeCountry][0].label }
        : { region: null },
    );
    updateRegionLabel(activeCountry, setRegionLabel, translateText);
  }, [activeCountry, setFieldsValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (corporateClient) setFieldsValue({ id: corporateClient.id });
  }, [corporateClient, setFieldsValue]);

  const isTestCorporation = () => getFieldValue('isTestCorporation');

  const postalCodeValidator = countryCode => (_, value) => {
    if (!value || isPostalCode(value, countryCode)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Invalid postal code'));
  };

  return (
    <>
      <Form.Item
        name="corporateClientName"
        label={translateText('corporateAccounts.corporateClient.name')}
        rules={[
          {
            required: true,
            message: translateText('corporateAccounts.corporateClient.name.error.presence'),
          },
          MAXIMUM_CHAR_COUNT,
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="corporationNumber"
        label={translateText('corporateAccounts.corporateClient.corporationNumber')}
        rules={[
          {
            required: true,
            message: translateText(
              'corporateAccounts.corporateClient.corporationNumber.error.presence',
            ),
          },
          MAXIMUM_CHAR_COUNT,
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="taxNumber"
        label={taxNumberLabel}
        rules={[
          {
            required: TAX_NUMBER_REQUIRED_COUNTRIES.includes(activeCountry),
            message: translateText('corporateAccounts.corporateClient.taxNumber.error_presence', {
              taxNumber: taxNumberLabel,
            }),
          },
          MAXIMUM_CHAR_COUNT,
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="invoiceId"
        label={translateText('corporateAccounts.corporateClient.salesforceId')}
        extra={<SalesforceIdInfoLabel translateText={translateText} />}
        rules={[
          {
            required: true,
            message: translateText('corporateAccounts.corporateClient.salesforceId.error.presence'),
          },
          {
            validator: (rule, value) =>
              isTestCorporation() || !value
                ? Promise.resolve()
                : isValidSalesforceId(getFieldValue('invoiceId'), translateText),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['address', 'companyAddress1']}
        label={translateText('corporateAccounts.corporateClient.companyAddress1')}
        rules={[
          {
            required: true,
            message: translateText('corporateAccounts.corporateClient.address.error.presence'),
          },
          MAXIMUM_CHAR_COUNT,
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['address', 'companyAddress2']}
        label={translateText('corporateAccounts.corporateClient.companyAddress2')}
        rules={[MAXIMUM_CHAR_COUNT]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['address', 'city']}
        label={translateText('corporateAccounts.corporateClient.city')}
        rules={[
          {
            required: true,
            message: translateText('corporateAccounts.corporateClient.city.error.presence'),
          },
          MAXIMUM_CHAR_COUNT,
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['address', 'postalCode']}
        label={translateText('corporateAccounts.corporateClient.postalCode')}
        rules={[
          {
            required: true,
            message: translateText('corporateAccounts.corporateClient.postalCode.error.presence'),
          },
          MAXIMUM_CHAR_COUNT,
          {
            validator: postalCodeValidator(activeCountry),
            message: translateText('corporateAccounts.corporateClient.postalCode.error.validity'),
          },
        ]}
      >
        <Input disabled={!activeCountry} data-testid="postalCodeInput" />
      </Form.Item>
      <Form.Item
        name="country"
        validateTrigger="onBlur"
        label={translateText('corporateAccounts.corporateClient.country')}
        rules={[
          {
            required: true,
            message: translateText('corporateAccounts.corporateClient.country.error.presence'),
          },
        ]}
      >
        <Select
          placeholder={translateText('corporateAccounts.corporateClient.country.placeholder')}
          onChange={value => setActiveCountry(value)}
        >
          {SUPPORTED_COUNTRIES.map(({ label, countryCode }) => (
            <Option key={countryCode} value={countryCode}>
              {translateText(`corporateAccounts.countries.${countryCode.toLowerCase()}`)}
            </Option>
          ))}
        </Select>
      </Form.Item>
      {JURISDICTION_REQUIRED_COUNTRIES.includes(activeCountry) && (
        <Form.Item
          name="region"
          data-testid="regionDropdown"
          label={regionLabel}
          rules={[
            {
              required: JURISDICTION_REQUIRED_COUNTRIES.includes(activeCountry),
              message: translateText('corporateAccounts.corporateClient.region.error.presence'),
            },
          ]}
        >
          <Select
            placeholder={translateText('corporateAccounts.corporateClient.region.placeholder')}
          >
            {REGION_CODES[activeCountry] &&
              Object.keys(REGION_CODES[activeCountry]).map((_region, index) => (
                <Option
                  key={REGION_CODES[activeCountry][index].value}
                  value={REGION_CODES[activeCountry][index].value}
                >
                  {REGION_CODES[activeCountry][index].label}
                </Option>
              ))}
          </Select>
        </Form.Item>
      )}
      <Form.Item
        name="preferredLanguage"
        validateTrigger="onBlur"
        label={translateText('corporateAccounts.corporateClient.preferredLanguage')}
        rules={[
          {
            required: true,
            message: translateText(
              'corporateAccounts.corporateClient.preferredLanguage.error.presence',
            ),
          },
        ]}
      >
        <Select
          placeholder={translateText(
            'corporateAccounts.corporateClient.preferredLanguage.placeholder',
          )}
        >
          {LANGUAGES.map(({ label, value }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="isTestCorporation"
        label={translateText('corporateAccounts.corporateClient.accountType.makeTestAccount')}
      >
        <Switch
          data-testid="makeTestCorpSwitch"
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
        />
      </Form.Item>
    </>
  );
};

export default withRouter(injectIntl(CorporationInfoStep));
