import React from 'react';

const MenuLeftAuditLogsIcon = () => (
  <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon /Audit">
      <path
        id="Vector"
        d="M4.14328 3.31814C4.0647 3.31814 4.00042 3.38243 4.00042 3.461V4.31814C4.00042 4.39671 4.0647 4.461 4.14328 4.461H11.0004C11.079 4.461 11.1433 4.39671 11.1433 4.31814V3.461C11.1433 3.38243 11.079 3.31814 11.0004 3.31814H4.14328ZM7.42899 5.88957H4.14328C4.0647 5.88957 4.00042 5.95386 4.00042 6.03243V6.88957C4.00042 6.96814 4.0647 7.03243 4.14328 7.03243H7.42899C7.50756 7.03243 7.57185 6.96814 7.57185 6.88957V6.03243C7.57185 5.95386 7.50756 5.88957 7.42899 5.88957ZM6.57185 14.0681H2.57185V1.49671H12.5718V7.211C12.5718 7.28957 12.6361 7.35386 12.7147 7.35386H13.7147C13.7933 7.35386 13.8576 7.28957 13.8576 7.211V0.782427C13.8576 0.466356 13.6022 0.210999 13.2861 0.210999H1.85756C1.54149 0.210999 1.28613 0.466356 1.28613 0.782427V14.7824C1.28613 15.0985 1.54149 15.3539 1.85756 15.3539H6.57185C6.65042 15.3539 6.7147 15.2896 6.7147 15.211V14.211C6.7147 14.1324 6.65042 14.0681 6.57185 14.0681ZM14.429 12.4967H11.8576V11.8431C12.6843 11.5967 13.2861 10.8324 13.2861 9.92529C13.2861 8.81993 12.3915 7.92529 11.2861 7.92529C10.1808 7.92529 9.28613 8.81993 9.28613 9.92529C9.28613 10.8306 9.88792 11.5967 10.7147 11.8431V12.4967H8.14328C7.98613 12.4967 7.85756 12.6253 7.85756 12.7824V15.4967C7.85756 15.6539 7.98613 15.7824 8.14328 15.7824H14.429C14.5861 15.7824 14.7147 15.6539 14.7147 15.4967V12.7824C14.7147 12.6253 14.5861 12.4967 14.429 12.4967ZM10.3933 9.92529C10.3933 9.43243 10.7933 9.03243 11.2861 9.03243C11.779 9.03243 12.179 9.43243 12.179 9.92529C12.179 10.4181 11.779 10.8181 11.2861 10.8181C10.7933 10.8181 10.3933 10.4181 10.3933 9.92529ZM13.6076 14.6753H8.9647V13.6039H13.6076V14.6753Z"
        fill="white"
      />
    </g>
  </svg>
);

export default MenuLeftAuditLogsIcon;
