import React from 'react';
import { Typography, Button } from 'antd';
import useTranslation from 'utils/hooks/useTranslation';
import { VerificationSuccessIcon } from 'components/Shared/Icons';
import { URL_BUSINESS_PORTAL } from 'constants.js';

import styles from './style.module.scss';

const VerificationSuccess = () => {
  const { translateText } = useTranslation();

  return (
    <div className={styles.verificationPage} data-testid="verificationSuccess">
      <div data-testid="verificationSuccessIcon">
        <VerificationSuccessIcon />
      </div>
      <Typography.Title level={2} className={styles.verificationTitle}>
        {translateText('corporateAccounts.selfRegistration.verification.success')}
      </Typography.Title>
      <Typography className={styles.verificationMessage}>
        <Typography.Text>
          {translateText('corporateAccounts.selfRegistration.verification.success.message')}
        </Typography.Text>
      </Typography>
      <div className={styles.verificationButton}>
        <Button type="primary" href={URL_BUSINESS_PORTAL}>
          <p>
            {translateText('corporateAccounts.selfRegistration.verification.success.portalLink')}
          </p>
        </Button>
      </div>
    </div>
  );
};

export default VerificationSuccess;
