import React from 'react';
import store from 'store';
import { createIntl, createIntlCache } from 'react-intl';
import locales from 'locales';
import { errorHandler } from 'components/Providers/Localization/index';
import { COUNTRIES, GLOBAL_LOCALE } from '../constants';

const defaultInterpolationOptions = {
  p: chunks => <p>{chunks}</p>,
  strong: chunks => <strong>{chunks}</strong>,
};
const fallbackLocalization = locales[COUNTRIES.US.locale].messages;
export const localizedMessage = (id, defaultMessage, options = {}) => {
  const overrideLocale = locales[options?.locale];
  const currentLocale = store.get('app.settings.locale') || COUNTRIES.US.locale;
  const { messages } = overrideLocale || locales[currentLocale];

  if (!defaultMessage) {
    defaultMessage = fallbackLocalization[id];
  }

  return getLocalizedMessage(currentLocale, messages, id, defaultMessage, {
    ...defaultInterpolationOptions,
    ...options,
  });
};

const getLocalizedMessage = (locale, messages, id, defaultMessage, options) => {
  // Returns internationalized strings for usage outside of React components
  locale = locale === GLOBAL_LOCALE ? COUNTRIES.US.locale : locale;
  const cache = createIntlCache();
  const intl = createIntl({ locale, messages, onError: errorHandler }, cache);
  return intl.formatMessage({ id, defaultMessage }, options);
};

export const localizedCurrency = (value, currency = null) => {
  let currentLocale = store.get('app.settings.locale') || COUNTRIES.US.locale;
  if (currentLocale === GLOBAL_LOCALE) {
    currentLocale = COUNTRIES.US.locale;
  }

  const cache = createIntlCache();
  const intl = createIntl({ locale: currentLocale }, cache);

  return currency
    ? intl.formatNumber(value, { style: 'currency', currency }).replace(/[^0123456789.,]/g, '') // replace removes the currency symbol
    : intl.formatNumber(value, { style: 'decimal', minimumFractionDigits: 2 });
};

export default localizedMessage;
