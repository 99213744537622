import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { GlobalOutlined, UserOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import Authorize from 'components/Providers/Authorize';
import PageWrapper from 'components/Shared/Page/Wrapper';
import PageTitle from 'components/Shared/Page/Title';
import useTranslation from 'utils/hooks/useTranslation';
import { COUNTRIES, ROUTE_CORPORATIONS_ADMIN, ROUTE_DEFAULT } from 'constants.js';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import useAmplitude from 'utils/hooks/useAmplitude';
import PrimaryUserEditForm from './PrimaryUserEditForm';
import CorporationInfoEditForm from './CorporationInfoEditForm';

const { TabPane } = Tabs;

const CorporationsEdit = ({ history }) => {
  const { translateText } = useTranslation();

  const { sendAmplitudeEvent } = useAmplitude();

  const { editableCorporateClient: corporateClient, formErrors, loading } = useSelector(
    state => state.corporations,
  );

  const [activeKey, setActiveKey] = useState('primaryUser');

  const handleOnSave = useCallback(() => {
    const additionalProperties = {
      company: {
        name: corporateClient.corporateClientName,
        id: corporateClient.id,
        country: COUNTRIES[corporateClient.country].label,
      },
    };
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.accountEditSave, additionalProperties);
  }, [sendAmplitudeEvent, corporateClient]);

  const formItemStyle = {
    marginBottom: 0,
  };

  const handleTabChange = nextKey => {
    setActiveKey(nextKey);
  };

  const handleBackToCorporations = () => {
    history.push(ROUTE_CORPORATIONS_ADMIN);
  };

  return (
    <Authorize redirect to={ROUTE_DEFAULT}>
      <Helmet title="Edit Corporation" />
      <PageWrapper loading={loading}>
        <PageTitle title={corporateClient?.corporateClientName} />
        <div className="card-body">
          <Tabs tabPosition="top" onChange={handleTabChange} animated={false} activeKey={activeKey}>
            <TabPane
              tab={
                <span>
                  <UserOutlined />
                  {translateText('corporateAccounts.admins.role.primary_fleet_manager')}
                </span>
              }
              key="primaryUser"
            >
              {corporateClient && ( // Only mount if corporateClient is loaded
                <PrimaryUserEditForm
                  corporation={corporateClient}
                  formErrors={formErrors}
                  formItemStyle={formItemStyle}
                  onSave={handleOnSave}
                  onCancel={handleBackToCorporations}
                />
              )}
            </TabPane>
            <TabPane
              tab={
                <span>
                  <GlobalOutlined />
                  {translateText('corporateAccounts.admin.corporation')}
                </span>
              }
              key="corporation"
            >
              {corporateClient && ( // Only mount if corporateClient is loaded
                <CorporationInfoEditForm
                  corporation={corporateClient}
                  formErrors={formErrors}
                  formItemStyle={formItemStyle}
                  onSave={handleOnSave}
                  onCancel={handleBackToCorporations}
                />
              )}
            </TabPane>
          </Tabs>
        </div>
      </PageWrapper>
    </Authorize>
  );
};

export default withRouter(CorporationsEdit);
