/* eslint-disable */
import React from 'react';
import { Typography } from 'antd';
import userManager from 'utils/userManager';
import { SignoutCallbackComponent } from 'redux-oidc';

const LogoutRedirect = () => {
  const successCallback = () => {
    userManager.signinRedirect();
  };

  const errorCallback = error => {
    console.error(error);
    userManager.removeUser();
  };

  return (
    <SignoutCallbackComponent
      userManager={userManager}
      successCallback={successCallback}
      errorCallback={errorCallback}
    >
      <Typography.Title level={2} style={{ textAlign: 'center', marginTop: 30 }}>
        Thank you for your patience.
      </Typography.Title>
    </SignoutCallbackComponent>
  );
};

export default LogoutRedirect;
