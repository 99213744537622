import React from 'react';
import { Alert } from 'antd';
import moment from 'moment';
import {
  getTimezone,
  parseMaintenanceWindowEnv,
  showMaintenanceNotification,
} from 'utils/maintenanceUtil';
import useTranslation from 'utils/hooks/useTranslation';
import styles from '../style.module.scss';

const MaintenanceNotification = ({ onNotificationClose }) => {
  const { translateText } = useTranslation();
  const parsedDates = parseMaintenanceWindowEnv(process.env.REACT_APP_MAINTENANCE_WINDOW);
  if (!parsedDates) {
    return null;
  }

  const [start, end] = parsedDates;
  const showNotification = showMaintenanceNotification(start);
  if (!showNotification) {
    return null;
  }

  const weekday = moment(start).weekday();
  const timeZone = getTimezone();
  const maintenanceMessage = translateText(
    'corporateAccounts.globalNotification.maintenanceMessage',
    {
      weekday: translateText(`corporateAccounts.daysOfWeek.${weekday}`),
      monthDay: moment(start).format('MMMM D'),
      startTime: moment(start).format('h:mm A'),
      endTime: `${moment(end).format('h:mm A')}${timeZone ? ` ${timeZone}` : ''}`,
    },
  );

  return (
    <Alert
      data-testid="maintenanceNotification"
      className={styles.notification}
      message={maintenanceMessage}
      type="warning"
      afterClose={onNotificationClose}
      showIcon
      closable
      banner
    />
  );
};

export default MaintenanceNotification;
