export const staffUserQueryKeys = { getStaffUserQuery: 'getStaffUser' };
export const monthlyStatementQueryKeys = {
  getMonthlyStatements: 'getMonthlyStatements',
  downloadMonthlyStatement: 'downloadMonthlyStatement',
};
export const driverQueryKeys = {
  getDriversQuery: 'getDriversQuery',
};

export const corporationQueryKeys = {
  getCorporationsQuery: 'getCorporationsQuery',
  getBillingInformationQuery: 'getBillingInformationQuery',
};

export const auditLogsQueryKeys = {
  getAuditLogsQuery: 'getAuditLogsQuery',
};

export default {
  driverQueryKeys,
  staffUserQueryKeys,
  monthlyStatementQueryKeys,
  corporationQueryKeys,
};
