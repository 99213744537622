export const USER_REGISTRATION_ERRORS = {
  InternalError: 'InternalError',
  DuplicateEmail: 'UserAlreadyExists',
  Validation: 'Validation',
  UserEmailDomainNotValid: 'UserEmailDomainNotValid',
};

export const USER_REGISTRATION_ERRORS_TRANSLATION_MAP = {
  [USER_REGISTRATION_ERRORS.InternalError]: 'corporateAccounts.user.registration.internalError',
  [USER_REGISTRATION_ERRORS.DuplicateEmail]: 'corporateAccounts.user.registration.duplicateEmail',
  [USER_REGISTRATION_ERRORS.UserEmailDomainNotValid]:
    'corporateAccounts.user.registration.userEmailDomainNotValid',
};

export const USER_VERIFICATION_ERRORS = {
  InternalError: 'InternalError',
  VerificationCodeExpired: 'VerificationCodeExpired',
  VerificationCodeNotFound: 'VerificationCodeNotFound',
};

export const USER_VERIFICATION_INFO = {
  VerificationCode: 'VerificationCode',
};

export const USER_DOMAIN_ERROR_CODES = {
  RestrictedCorporateClientLimitExceeded: 'RestrictedCorporateClientLimitExceeded',
};

export const USER_ROLES = {
  // Staff roles
  PBP_SUPER_ADMIN: {
    label: 'corporateAccounts.users.role.super_admin',
    value: 'pbp_super_admin',
    description: 'Super Admin',
  },
  PBP_CUSTOMER_SUCCESS: {
    label: 'corporateAccounts.users.role.customer_success',
    value: 'pbp_customer_success',
    description: 'Customer Success',
  },
  PBP_CS_TIER_2: {
    label: 'corporateAccounts.users.role.customer_support_tier_2',
    value: 'pbp_tier_2_customer_support_corpaccts',
    description: 'Customer Support Tier 2',
  },
  // Admin roles
  PRIMARY_FLEET_MANAGER: {
    label: 'corporateAccounts.admins.role.primary_fleet_manager',
    value: 'primary_fleet_manager',
    description: 'Account Owner',
  },
  FLEET_MANAGER: {
    label: 'corporateAccounts.admins.role.fleet_manager',
    value: 'fleet_manager',
    description: 'Business Manager',
  },
  SERVICE_ADMIN: {
    label: 'corporateAccounts.admins.role.service_admin',
    value: 'service_admin',
    description: 'Business Admin',
  },
  REPORTING: {
    label: 'corporateAccounts.admins.role.reporting',
    value: 'reporting',
    description: 'Analyst',
  },
};

export const STAFF_USER_ROLES = [
  USER_ROLES.PBP_SUPER_ADMIN,
  USER_ROLES.PBP_CUSTOMER_SUCCESS,
  USER_ROLES.PBP_CS_TIER_2,
];
export const ADMIN_USER_ROLES = [
  USER_ROLES.PRIMARY_FLEET_MANAGER,
  USER_ROLES.FLEET_MANAGER,
  USER_ROLES.SERVICE_ADMIN,
  USER_ROLES.REPORTING,
];
