import React, { forwardRef } from 'react';
import { Input } from 'antd';
import { Cvc } from 'react-credit-card-primitives';

const CreditCardCvc = ({ isEditMode, onChange, cardType, value, ...other }, ref) => {
  if (isEditMode) return <Input value={value} disabled readOnly />;

  return (
    <Cvc
      cardType={cardType}
      onChange={onChange}
      render={({ getInputProps }) => <Input ref={ref} {...getInputProps()} {...other} />}
    />
  );
};

export default forwardRef(CreditCardCvc);
