const CreditAccountDriverIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="46" height="40" viewBox="0 0 46 40" fill="none">
    <g clipPath="url(#clip0_179_593)">
      <path
        d="M8.00563 23.5193C8.47315 21.6103 10.1843 20.2676 12.1498 20.2676H33.7208C35.6863 20.2676 37.3975 21.6103 37.865 23.5193L42.8219 43.7601C43.151 45.1041 42.1335 46.4009 40.7498 46.4009H5.1208C3.73715 46.4009 2.71957 45.1041 3.0487 43.7601L8.00563 23.5193Z"
        fill="#B8F2B6"
      />
      <rect x="16.0016" y="7.4668" width="14.9333" height="10.6667" rx="4.26667" fill="#B8F2B6" />
      <path
        d="M23.7888 3.42784C19.348 1.77341 19.4714 8.61605 20.0882 12.2442C22.8093 16.8519 28.7739 22.9979 30.8637 10.7204C36.9589 4.62513 27.5984 -0.38167 23.7888 3.42784Z"
        fill="#333333"
      />
      <path
        d="M18.9999 23.8914C21.0897 21.7145 21.8298 16.8891 21.9386 14.7485C24.5872 13.261 29.5359 11.6574 28.1427 17.1431C26.7495 22.6288 28.0701 23.6374 28.9046 23.456L30.4284 25.8506C25.7482 26.1045 16.9101 26.0683 18.9999 23.8914Z"
        fill="#C98D6D"
      />
      <path
        d="M28.0337 17.8971L22.8683 15.4004L22.5916 19.7425C22.5916 19.7425 25.9847 23.2345 27.4803 21.805C27.4803 20.1767 28.0337 17.8971 28.0337 17.8971Z"
        fill="url(#paint0_linear_179_593)"
        fillOpacity="0.78"
      />
      <path
        d="M7.46218 26.5045C10.2486 23.6311 16.605 23.1304 19.435 23.2392C23.7887 26.9835 27.1266 24.7993 28.2513 23.2392C31.2989 23.2392 43.5982 21.4977 45.4486 45.3345H1.36694C2.23769 40.2551 4.67579 29.378 7.46218 26.5045Z"
        fill="#88D98C"
      />
      <path
        d="M31.0818 17.7963C32.3009 12.3977 29.8846 7.76662 28.905 7.67389C27.0076 7.49429 26.0179 8.29775 24.3336 9.05623C22.6493 9.81471 24.007 12.5026 23.1363 12.8984C21.939 13.4426 20.9594 11.1569 20.1975 12.0276C18.8913 14.2916 21.1045 15.4381 22.3743 15.7283C24.7689 18.6671 28.6873 20.0821 31.0818 17.7963Z"
        fill="#C98D6D"
      />
      <path
        d="M10.7274 43.4816L51.4349 8.10742L52.5233 13.6584L16.9315 43.4816H10.7274Z"
        fill="#B8F2B6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.4433 63.9457C34.3619 63.9457 42.4025 55.9051 42.4025 45.9865C42.4025 36.0679 34.3619 28.0273 24.4433 28.0273C14.5247 28.0273 6.48413 36.0679 6.48413 45.9865C6.48413 55.9051 14.5247 63.9457 24.4433 63.9457ZM24.4434 60.8973C32.6788 60.8973 39.355 54.2212 39.355 45.9858C39.355 37.7504 32.6788 31.0742 24.4434 31.0742C16.208 31.0742 9.53185 37.7504 9.53185 45.9858C9.53185 54.2212 16.208 60.8973 24.4434 60.8973Z"
        fill="white"
      />
      <path
        d="M11.0221 31.1062C10.7865 32.4798 9.31264 34.7762 8.76842 35.7558C8.22421 36.7354 6.59155 40.1096 6.59155 40.1096C6.59155 40.1096 7.05296 40.2119 7.35346 40.2184C8.55074 37.0619 10.0745 35.2116 10.0745 35.2116C10.5065 34.309 11.3657 34.0823 11.7093 34.0986C12.0529 34.115 13.1932 36.055 14.3194 35.7558C14.7508 35.6412 14.7548 35.2116 14.9725 35.1028C15.1902 34.9939 15.527 35.1531 15.9521 34.8851C16.1767 34.7435 16.2607 34.4298 16.4963 34.232C16.6844 34.0742 17.1878 33.9096 17.3671 33.7966C17.7709 33.542 17.876 33.6572 18.129 33.2524C18.6732 32.3817 18.0753 31.0178 17.1579 30.076C16.3676 29.2647 14.7393 28.863 14.5073 28.8987C14.2752 28.9343 14.1018 29.2252 13.7752 29.4429C13.4759 29.5835 13.057 29.435 12.6868 29.6606C12.3521 29.8645 12.3801 30.1182 12.0337 30.4225C11.7077 30.7089 11.0911 30.7042 11.0221 31.1062Z"
        fill="#C98D6D"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_179_593"
        x1="26.5579"
        y1="16.4859"
        x2="24.2522"
        y2="19.6206"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.322917" stopColor="#844A2B" />
        <stop offset="1" stopColor="#91664F" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_179_593">
        <rect width="45.3333" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CreditAccountDriverIcon;
