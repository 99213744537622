import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Image, Space, Typography } from 'antd';
import { parseMaintenanceWindowEnv, formatRestoreTimeMessage } from 'utils/maintenanceUtil';
import useTranslation from 'utils/hooks/useTranslation';
import MaintenanceWindowSVG from 'assets/icons/maintenanceWindow.svg';
import styles from './style.module.scss';

const { Text } = Typography;

const MaintenancePage = () => {
  const { translateText } = useTranslation();

  const parsedMaintenanceDates = parseMaintenanceWindowEnv(
    process.env.REACT_APP_MAINTENANCE_WINDOW,
  );

  if (!parsedMaintenanceDates) {
    return null;
  }

  return (
    <div className={styles.maintenanceImage}>
      <Space align="center" direction="vertical" size={40}>
        <Image width="90%" src={MaintenanceWindowSVG} preview={false} />
        <Space align="center" direction="vertical" size="small">
          <Typography.Title level={3} strong={true}>
            {translateText(
              'corporateAccounts.globalNotification.maintenancePage.underScheduleMaintenance',
            )}
          </Typography.Title>
          <Space align="center" direction="vertical" size={1}>
            <Text type="secondary">
              {translateText(
                'corporateAccounts.globalNotification.maintenancePage.underGoingScheduledMaintenance',
              )}
            </Text>
            <Text type="secondary">
              {formatRestoreTimeMessage(parsedMaintenanceDates[1], translateText)}
            </Text>
          </Space>
        </Space>
      </Space>
    </div>
  );
};

export default connect()(withRouter(MaintenancePage));
