import React from 'react';
// import { useSelector } from 'react-redux';
import { Typography } from 'antd';
// import { CORPORATE_CLIENT_PROSPECT_STATUS } from 'constants.js';
// import VideoPlayButtonIcon from 'components/Shared/Icons/VideoPlayButtonIcon';

import styles from './style.module.scss';

const EmptyState = ({ icon, title, description, buttons, videoLink }) => {
  // const corporationStatus = useSelector(state => state.user.currentCorporation.statusName);

  return (
    <div className={styles.emptyState}>
      {icon}
      <Typography.Title level={4}>{title}</Typography.Title>
      <Typography className={styles.description}>{description}</Typography>
      <div className={styles.buttons}>{buttons}</div>
      {/* TODO: Remove the conditional statement below to render this for all clients, not just prospect */}
      {/* TODO: remove comments when product team has video links available */}
      {/* Note that when we enable this feature again we need to remove the skip keyboard from tests in the following files:
       * src/pages/Drivers/__tests__/index.test.js
       * src/pages/PaymentsSpa/__tests__/index.test.js
       * src/pages/Vehicles/__tests/index.test.js  */}
      {/* {corporationStatus === CORPORATE_CLIENT_PROSPECT_STATUS && (
        <Typography className={styles.videoLink}>
          <VideoPlayButtonIcon />
          {videoLink}
        </Typography>
      )} */}
    </div>
  );
};

export default EmptyState;
