import React from 'react';

const HomeIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6667 6.44941V12.6667C12.6667 13.0349 12.3682 13.3333 12 13.3333H10.6667V11.3333C10.6667 9.86057 9.47276 8.66666 8 8.66666C6.52724 8.66666 5.33333 9.86057 5.33333 11.3333V13.3333H4C3.63181 13.3333 3.33333 13.0349 3.33333 12.6667V6.44941L8 2.99262L12.6667 6.44941ZM6.66667 13.3333H9.33333V11.3333C9.33333 10.597 8.73638 10 8 10C7.26362 10 6.66667 10.597 6.66667 11.3333V13.3333ZM2 6.44941C2 6.02694 2.20022 5.62947 2.5397 5.378L7.20636 1.92121C7.67783 1.57198 8.32217 1.57198 8.79364 1.92121L13.4603 5.378C13.7998 5.62947 14 6.02694 14 6.44941V12.6667C14 13.7712 13.1046 14.6667 12 14.6667H4C2.89543 14.6667 2 13.7712 2 12.6667V6.44941Z"
      fill="#4D4D4D"
    />
  </svg>
);

export default HomeIcon;
