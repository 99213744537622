import { DRIVER_STATUS } from 'constants/drivers';

export const updateDriverFromPage = (drivers, updatedDriver) => {
  return drivers.map(driver => (driver.id === updatedDriver.id ? updatedDriver : driver));
};

export const activateDriversFromPage = (drivers, activatedDrivers) => {
  return drivers.map(driver => {
    const activatedDriver = activatedDrivers.find(x => x.id === driver.id);
    if (activatedDriver) {
      return {
        ...driver,
        status: activatedDriver.status,
      };
    }
    return driver;
  });
};

export const updateDriverInvitationStatuses = (
  drivers,
  sentInvitationsDriverIds,
  failedInvitationsIndexes = [],
) => {
  const driverLookupById = new Map();
  drivers.forEach(driver => driverLookupById.set(driver.id, driver));

  const successfulInvitationsDriverIds = sentInvitationsDriverIds.filter(
    (_, index) => !failedInvitationsIndexes.includes(index),
  );

  successfulInvitationsDriverIds.forEach(driverId => {
    const driver = driverLookupById.get(driverId);
    if (driver && driver.status !== DRIVER_STATUS.ACTIVE) {
      driver.status = DRIVER_STATUS.INVITE_SENT;
    }
  });

  failedInvitationsIndexes.forEach(index => {
    const driverId = sentInvitationsDriverIds[index];
    const driver = driverLookupById.get(driverId);
    if (driver) {
      driver.status = DRIVER_STATUS.INVITE_FAILED;
    }
  });

  return drivers;
};
