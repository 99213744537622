import React from 'react';

const MenuLeftDriversIcon = ({ color = '#6C6C6C' }) => (
  <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon /Driver">
      <path
        id="Vertical container"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.125 5.23985C12.125 2.79342 10.1429 0.811279 7.69647 0.811279C5.25004 0.811279 3.2679 2.79342 3.2679 5.23985C3.2679 6.71842 3.9929 8.02914 5.10719 8.83271C5.103 8.8348 5.09943 8.83628 5.09647 8.83751L5.09112 8.83985C4.2929 9.17735 3.57683 9.66128 2.96076 10.2791C2.34825 10.8905 1.8606 11.6153 1.52504 12.4131C1.19489 13.1942 1.01671 14.0312 1.00004 14.8791C0.999568 14.8982 1.00291 14.9172 1.00988 14.9349C1.01684 14.9527 1.02729 14.9688 1.0406 14.9825C1.05392 14.9961 1.06983 15.007 1.08739 15.0144C1.10496 15.0218 1.12384 15.0256 1.1429 15.0256H2.21254C2.28933 15.0256 2.35362 14.9631 2.3554 14.8863C2.39112 13.5077 2.9429 12.2166 3.91969 11.2381C4.92862 10.2256 6.2679 9.66842 7.69647 9.66842C10.1429 9.66842 12.125 7.68628 12.125 5.23985ZM7.69647 8.31128C6.00004 8.31128 4.62504 6.93628 4.62504 5.23985C4.62504 3.54342 6.00004 2.16842 7.69647 2.16842C9.3929 2.16842 10.7679 3.54342 10.7679 5.23985C10.7679 6.93628 9.3929 8.31128 7.69647 8.31128ZM10.8103 11.6863H12.5H14.1897C13.8867 11.0583 13.244 10.625 12.5 10.625C11.756 10.625 11.1133 11.0583 10.8103 11.6863ZM14.324 12.9363H13.125V14.2683C13.7193 14.0583 14.176 13.5572 14.324 12.9363ZM11.875 14.2683V12.9363H10.676C10.824 13.5572 11.2807 14.0583 11.875 14.2683ZM9.375 12.5C9.375 10.7741 10.7741 9.375 12.5 9.375C14.2259 9.375 15.625 10.7741 15.625 12.5C15.625 14.2259 14.2259 15.625 12.5 15.625C10.7741 15.625 9.375 14.2259 9.375 12.5Z"
        fill={color}
      />
    </g>
  </svg>
);

export default MenuLeftDriversIcon;
