import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useRegisterCorporation } from 'services/reactQueryHooks/corporations';
import useAmplitude from 'utils/hooks/useAmplitude';

const useRegisterCorporationModal = () => {
  const { sendAmplitudeEvent } = useAmplitude();

  const { isMobileView: isMobile, locale: preferredLanguage } = useSelector(
    state => state?.settings || {},
  );
  const { restrictedCorporateClientProspect, id: userId } = useSelector(state => state?.user || {});
  const {
    mutate: registerCorporation,
    isLoading: isRegisterCorporationLoading,
    isSuccess: isRegisterCorporationSuccess,
  } = useRegisterCorporation();

  const handleRegistration = useCallback(
    ({ formData }) => {
      sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.selfRegistrationCorporateClientRegistrationSave);

      formData = { ...formData, userAccountId: userId, preferredLanguage: preferredLanguage };
      registerCorporation(formData);
    },
    [preferredLanguage, registerCorporation, sendAmplitudeEvent, userId],
  );

  return {
    isMobile,
    isRegisterCorporationLoading,
    isRegisterCorporationSuccess,
    prospectUser: restrictedCorporateClientProspect,
    onRegister: handleRegistration,
  };
};

export default useRegisterCorporationModal;
