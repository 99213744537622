import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Col, Row, Upload, Typography } from 'antd';
import { uploadProps, handleUpload, buildSteps } from 'utils/uploadUtil';
import { CSVLink } from 'react-csv';
import { getDelimiterByCountry } from 'utils/exportUtil';
import { getCSVHeadersFromImportType } from './helper';
import { getImportFileTranslatedByCountryCorp } from 'utils/importUtil';
import useTranslation from 'utils/hooks/useTranslation';

const BulkUpload = ({
  fileList,
  importEndpoint,
  importFunc,
  onCancel,
  setFileList,
  submitting,
  stepsContent,
  importFrom,
  importFileName,
}) => {
  const { translateText } = useTranslation();
  const currentCorporation = useSelector(state => state.user.currentCorporation);

  return (
    <div className="card-body">
      <Row>
        <Col xs={{ span: 24 }} sm={{ span: 20, offset: 2 }}>
          <Row gutter={16}>
            <Col span={6} style={{ textAlign: 'right' }}>
              <Typography.Paragraph strong>
                {translateText('corporateAccounts.import.step.1.title')}
              </Typography.Paragraph>
            </Col>
            <Col span={3} />
            <Col span={15}>
              <Button type="link">
                <CSVLink
                  data={[]}
                  filename={`${getImportFileTranslatedByCountryCorp(
                    importFileName,
                    currentCorporation?.country,
                    translateText,
                  )}.csv`}
                  headers={getCSVHeadersFromImportType(
                    importFrom,
                    currentCorporation?.country,
                    translateText,
                  )}
                  separator={getDelimiterByCountry(
                    currentCorporation && currentCorporation?.country,
                  )}
                >
                  {translateText(`corporateAccounts.import.downloadCsv`)}
                </CSVLink>
              </Button>
            </Col>
          </Row>

          {buildSteps(stepsContent)}

          <Row gutter={[16, 16]}>
            <Col span={9} />
            <Col xs={8}>
              <Upload {...uploadProps(fileList, setFileList, translateText)} maxCount={1}>
                <Button size="large" type="primary" disabled={submitting}>
                  {translateText('corporateAccounts.actions.browse')}
                </Button>
              </Upload>
            </Col>
          </Row>

          <Row gutter={[16, 16]} style={{ marginBottom: 50, textAlign: 'right' }}>
            <Col xs={8} offset={10}>
              <Button onClick={onCancel} style={{ marginTop: 16, marginRight: 7 }}>
                {translateText('corporateAccounts.actions.cancel')}
              </Button>
              <Button
                type="primary"
                onClick={() =>
                  handleUpload(fileList, importFunc, currentCorporation.id, importEndpoint)
                }
                disabled={fileList.length === 0 || submitting}
                style={{ marginTop: 16 }}
                data-testid="import"
              >
                {submitting
                  ? translateText('corporateAccounts.actions.importing')
                  : translateText('corporateAccounts.import.importData')}
                <UploadOutlined />
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(BulkUpload);
