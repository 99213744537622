import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ConnectedRouter } from 'connected-react-router';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// OIDC Provider
import userManager from 'utils/userManager';
import { initializeRaygun } from './utils/raygunUtil';
import { initializeDatadog } from './utils/datadogUtil';
import { OidcProvider, loadUser } from 'redux-oidc';
import { PersistGate } from 'redux-persist/integration/react';
import Router from 'router';
import Localization from 'components/Providers/Localization';
import { FeatureFlagProvider } from 'components/Providers/FeatureFlag';
import featureFlagConfig from 'config/featureFlags';
import { store, persistor, history } from './initStore';
import * as serviceWorker from './serviceWorker';

// app styles
import './global.scss';

initializeRaygun();
initializeDatadog();

loadUser(store, userManager);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <OidcProvider userManager={userManager} store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Localization>
            <ConnectedRouter history={history}>
              <FeatureFlagProvider defaultFeatures={featureFlagConfig}>
                <Router history={history} />
              </FeatureFlagProvider>
            </ConnectedRouter>
          </Localization>
        </PersistGate>
      </OidcProvider>
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root'),
);

// Unregister Service Workers during initial development
serviceWorker.unregister();
