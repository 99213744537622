import React from 'react';
import useTranslation from 'utils/hooks/useTranslation';
import { Button, Typography } from 'antd';
import { ROUTE_CREDIT_ACCOUNT } from 'constants.js';
import { useHistory } from 'react-router-dom';

import styles from './style.module.scss';

const ReplacePaymentsBanner = () => {
  const { translateText } = useTranslation();
  const history = useHistory();

  const handlePageChange = () => {
    history.push(ROUTE_CREDIT_ACCOUNT);
  };

  return (
    <div className={styles.replacePaymentsBannerContainer}>
      <section className={styles.replacePaymentsBannerSection}>
        <Typography className={styles.replacePaymentsBannerTitle}>
          {translateText('corporateAccounts.payments.creditAccountBanner.title')}
        </Typography>
        <Typography>
          {translateText('corporateAccounts.payments.creditAccountBanner.description')}{' '}
          {/* TODO: change this to the correct link in a future ticket */}
          <a href="https://www.google.com/" target="_blank" rel="noreferrer">
            {translateText('corporateAccounts.payments.creditAccountBanner.description.cta')}
          </a>
        </Typography>
        <Button
          type="primary"
          className={styles.replacePaymentsBannerButton}
          onClick={handlePageChange}
        >
          {translateText('corporateAccounts.payments.creditAccountBanner.button.cta')}
        </Button>
      </section>
    </div>
  );
};

export default ReplacePaymentsBanner;
