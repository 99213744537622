import React from 'react';
import { Modal, Typography, Spin } from 'antd';
import useTranslation from 'utils/hooks/useTranslation';
import AuditDetailsModalContent from './AuditDetailsModalContent';

import styles from '../style.module.scss';

const AuditDetailsModal = ({ visible, onClose, data, loading }) => {
  const { translateText } = useTranslation();

  return (
    <Modal
      title={
        <Typography>{translateText('corporateAccounts.auditLogs.auditDetails.title')}</Typography>
      }
      width="auto"
      visible={visible}
      centered
      closable
      onCancel={() => onClose()}
      footer={null}
      className={styles.auditDetailsModal}
    >
      {loading ? (
        <div className={styles.loadingContainer}>
          <Spin />
        </div>
      ) : (
        <AuditDetailsModalContent data={data} />
      )}
    </Modal>
  );
};

export default AuditDetailsModal;
