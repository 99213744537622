import { parsePhoneNumber, ParseError } from 'libphonenumber-js';

export const isValidPhoneNumber = value => {
  try {
    const phoneNumber = parsePhoneNumber(value);

    return phoneNumber.isValid() ? Promise.resolve() : Promise.reject();
  } catch (error) {
    if (error instanceof ParseError) {
      // Not a phone number, non-existent country, etc.
      console.log(error.message);
    }
    return Promise.reject();
  }
};

export default isValidPhoneNumber;
