import React from 'react';

const MenuLeftReportsIcon = () => (
  <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon /FileSearch">
      <path
        id="Vector"
        d="M11.1423 4.42528V3.56814C11.1423 3.48957 11.078 3.42528 10.9994 3.42528H4.1423C4.06373 3.42528 3.99944 3.48957 3.99944 3.56814V4.42528C3.99944 4.50386 4.06373 4.56814 4.1423 4.56814H10.9994C11.078 4.56814 11.1423 4.50386 11.1423 4.42528ZM4.1423 5.99671C4.06373 5.99671 3.99944 6.061 3.99944 6.13957V6.99671C3.99944 7.07529 4.06373 7.13957 4.1423 7.13957H7.42801C7.50658 7.13957 7.57087 7.07529 7.57087 6.99671V6.13957C7.57087 6.061 7.50658 5.99671 7.42801 5.99671H4.1423ZM6.71373 14.0681H2.57087V1.49671H12.5709V7.63957C12.5709 7.71814 12.6352 7.78243 12.7137 7.78243H13.7137C13.7923 7.78243 13.8566 7.71814 13.8566 7.63957V0.782427C13.8566 0.466356 13.6012 0.210999 13.2852 0.210999H1.85658C1.54051 0.210999 1.28516 0.466356 1.28516 0.782427V14.7824C1.28516 15.0985 1.54051 15.3539 1.85658 15.3539H6.71373C6.7923 15.3539 6.85658 15.2896 6.85658 15.211V14.211C6.85658 14.1324 6.7923 14.0681 6.71373 14.0681ZM14.6727 14.9878L13.0066 13.3217C13.4048 12.7949 13.6423 12.1378 13.6423 11.4253C13.6423 9.68957 12.2352 8.28243 10.4994 8.28243C8.76373 8.28243 7.35658 9.68957 7.35658 11.4253C7.35658 13.161 8.76373 14.5681 10.4994 14.5681C11.1387 14.5681 11.7316 14.3771 12.228 14.0503L13.9191 15.7414C13.9477 15.7699 13.9834 15.7824 14.0191 15.7824C14.0548 15.7824 14.0923 15.7681 14.1191 15.7414L14.6727 15.1878C14.6858 15.1747 14.6963 15.1591 14.7034 15.1419C14.7105 15.1248 14.7142 15.1064 14.7142 15.0878C14.7142 15.0692 14.7105 15.0508 14.7034 15.0336C14.6963 15.0165 14.6858 15.0009 14.6727 14.9878ZM10.4994 13.4253C9.39408 13.4253 8.49944 12.5306 8.49944 11.4253C8.49944 10.3199 9.39408 9.42529 10.4994 9.42529C11.6048 9.42529 12.4994 10.3199 12.4994 11.4253C12.4994 12.5306 11.6048 13.4253 10.4994 13.4253Z"
        fill="white"
      />
    </g>
  </svg>
);

export default MenuLeftReportsIcon;
