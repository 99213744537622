import React from 'react';
import { Typography } from 'antd';
import useTranslation from 'utils/hooks/useTranslation';
import PageTable from 'components/Shared/Page/Table';
import OffsetPaginationTable from 'components/Shared/OffsetPaginationTable';
import VehiclesTableActions from './VehiclesTableActions';
import styles from './style.module.scss';

const VehiclesTable = ({
  history,
  columns,
  currentPage,
  dataSource,
  loading,
  searchQuery,
  selectedTableRows,
  totalCount,
  visible,
  onRowSelectChange,
  onBulkDelete,
  onExport,
  onPageChange,
  onPageSizeChange,
  onShowAddForm,
  onSearch,
  onTableChange,
}) => {
  const { translateText } = useTranslation();

  return (
    <div>
      <VehiclesTableActions
        history={history}
        visible={visible}
        selectedRows={selectedTableRows}
        searchQuery={searchQuery}
        onExport={onExport}
        onBulkDelete={onBulkDelete}
        onSearch={onSearch}
        onShowAddForm={onShowAddForm}
      />
      {visible && (
        <Typography.Text className={styles.vehiclesDescription}>
          {translateText('corporateAcctounts.vehicles.description')}
        </Typography.Text>
      )}
      <PageTable
        visible={visible}
        table={
          <OffsetPaginationTable
            loading={loading}
            rowKey="id"
            rowSelection={{
              selectedRowKeys: selectedTableRows.map(row => row.id),
              onChange: onRowSelectChange,
            }}
            columns={columns}
            current={currentPage}
            totalCount={totalCount}
            dataSource={dataSource}
            scroll={{
              x: true,
            }}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onChange={onTableChange}
          />
        }
      />
    </div>
  );
};

export default VehiclesTable;
