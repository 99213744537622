const actions = {
  SET_STATE: 'admin/SET_STATE',
  GET: 'admin/GET',
  POST: 'admin/POST',
  POST_SUCCESS: 'admin/POST_SUCCESS',
  POST_ERROR: 'admin/POST_ERROR',
  PUT: 'admin/PUT',
  PUT_SUCCESS: 'admin/PUT_SUCCESS',
  PUT_ERROR: 'admin/PUT_ERROR',
  DELETE: 'admin/DELETE',
  DELETE_SUCCESS: 'admin/DELETE_SUCCESS',
  DOWNGRADE_ACCOUNT_OWNER: 'admin/DOWNGRADE_ACCOUNT_OWNER',
  INVITE: 'admin/INVITE',
  INVITE_SUCCESS: 'admin/INVITE_SUCCESS',
  RESET_ERRORS: 'admin/RESET_ERRORS',
};

export default actions;
