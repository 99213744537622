import React from 'react';

const MenuLeftAccountIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="menuLeftAccountIcon"
  >
    <g id="Group 6">
      <mask
        id="mask0_3626_1018"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="40"
        height="40"
      >
        <circle id="Ellipse 6" cx="20" cy="20" r="20" fill="#7DCEDC" />
      </mask>
      <g mask="url(#mask0_3626_1018)">
        <circle id="Ellipse 7" cx="20" cy="20" r="20" fill="#ABC9FF" />
        <g id="Group 3">
          <path
            id="Vector 1"
            d="M15.5234 9.9393H11.8593H-2.64453V28.8324H13.1952H34.3402C34.3402 28.8324 35.377 28.2747 35.8288 27.7256C36.3142 27.1355 36.5322 26.1336 36.5539 25.5882C36.5787 24.9651 36.0959 24.9011 36.0959 24.9011C36.0959 24.9011 35.7316 21.1797 34.9995 20C34.6638 19.4589 29.4163 18.9088 29.073 18.6416C27.4845 17.405 27.461 17.008 25.4089 15.0156C23.5008 13.1631 21.233 10.9695 18.9967 10.2828C17.4647 9.81238 15.5234 9.9393 15.5234 9.9393Z"
            fill="#3E6EEC"
          />
          <path
            id="Intersect"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M34.305 19.6871L32.7711 20.8375C32.4169 21.1031 32.6048 21.6666 33.0475 21.6666H35.3865H35.6594C35.592 21.3622 35.5155 21.0728 35.4292 20.8181H35.3865L35.3169 20.5213C35.2216 20.2974 35.1159 20.1169 34.999 20C34.8908 19.8917 34.6422 19.7872 34.305 19.6871Z"
            fill="white"
          />
          <path
            id="Vector 3"
            d="M36.0957 24.9011H32.0117L32.5079 28.8324H34.34C34.34 28.8324 35.3767 28.2747 35.8285 27.7255C36.314 27.1355 36.532 26.1336 36.5537 25.5881C36.5785 24.9651 36.0957 24.9011 36.0957 24.9011Z"
            fill="black"
          />
          <g id="Group 4">
            <mask
              id="mask1_3626_1018"
              style={{ maskType: 'alpha' }}
              maskUnits="userSpaceOnUse"
              x="17"
              y="12"
              width="12"
              height="8"
            >
              <path
                id="Vector 2"
                d="M18.1561 16.9629L17.7143 13.3243C17.6859 13.0906 17.8739 12.8871 18.1091 12.8969L21.1259 13.0226C21.8419 13.0525 22.5199 13.3534 23.0223 13.8644L28.7957 19.7362C28.8473 19.7887 28.802 19.8766 28.7293 19.8651L19.5617 18.4193C18.8204 18.3024 18.2466 17.7078 18.1561 16.9629Z"
                fill="#474F59"
              />
            </mask>
            <g mask="url(#mask1_3626_1018)">
              <path
                id="Vector 3_2"
                d="M18.1573 16.9726L17.7143 13.3243C17.6859 13.0906 17.8739 12.8871 18.1091 12.8969L21.1259 13.0226C21.8419 13.0525 22.5199 13.3534 23.0223 13.8644L28.6081 19.5454C28.6694 19.6077 28.5948 19.7074 28.5177 19.6663L26.6196 18.6534C26.4475 18.5616 26.2362 18.5887 26.093 18.7211L25.3975 19.364C25.3802 19.38 25.3565 19.3871 25.3332 19.3833L19.5505 18.427C18.8147 18.3054 18.2472 17.713 18.1573 16.9726Z"
                fill="black"
              />
              <rect
                id="Rectangle 4"
                x="22.9199"
                y="13.2823"
                width="0.813361"
                height="7.38571"
                transform="rotate(41.5307 22.9199 13.2823)"
                fill="#606770"
              />
              <rect
                id="Rectangle 5"
                x="24.3018"
                y="13.5116"
                width="1.40092"
                height="7.0229"
                transform="rotate(41.5307 24.3018 13.5116)"
                fill="#606770"
              />
            </g>
          </g>
          <path
            id="Rectangle 8"
            d="M-1.97266 13.6641C-1.97266 13.2847 -1.66507 12.9771 -1.28563 12.9771H16.1953C16.5747 12.9771 16.8823 13.2847 16.8823 13.6641V17.5573C16.8823 17.9367 16.5747 18.2443 16.1953 18.2443H-1.28563C-1.66507 18.2443 -1.97266 17.9367 -1.97266 17.5573V13.6641Z"
            fill="black"
          />
          <rect
            id="Rectangle 2"
            x="11.0195"
            y="25.1683"
            width="24.5038"
            height="1.37405"
            fill="black"
          />
          <path
            id="Ellipse 8"
            d="M32.7332 27.6506C32.7332 29.3042 31.3927 30.6446 29.7392 30.6446C28.0856 30.6446 26.7451 29.3042 26.7451 27.6506C26.7451 25.997 28.0856 24.6566 29.7392 24.6566C31.3927 24.6566 32.7332 25.997 32.7332 27.6506Z"
            fill="black"
          />
          <path
            id="Ellipse 8 (Stroke)"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.7392 25.2816C28.4308 25.2816 27.3701 26.3422 27.3701 27.6506C27.3701 28.959 28.4308 30.0196 29.7392 30.0196C31.0476 30.0196 32.1082 28.959 32.1082 27.6506C32.1082 26.3422 31.0476 25.2816 29.7392 25.2816ZM26.1201 27.6506C26.1201 25.6519 27.7404 24.0316 29.7392 24.0316C31.7379 24.0316 33.3582 25.6519 33.3582 27.6506C33.3582 29.6493 31.7379 31.2696 29.7392 31.2696C27.7404 31.2696 26.1201 29.6493 26.1201 27.6506Z"
            fill="black"
          />
        </g>
        <g id="Group 7">
          <g id="Group 6_2">
            <mask
              id="mask2_3626_1018"
              style={{ maskType: 'alpha' }}
              maskUnits="userSpaceOnUse"
              x="-17"
              y="14"
              width="47"
              height="16"
            >
              <path
                id="Vector 4"
                d="M-11.5016 19.8771H-13.9487C-14.5145 19.8771 -15.0323 20.1953 -15.25 20.7175C-15.6437 21.6619 -16.2081 23.2433 -16.2444 24.62C-16.3692 29.3628 -12.1257 29.6124 -12.1257 29.6124L27.9186 29.3718C28.7582 29.3668 29.4362 28.6847 29.4362 27.8451V23.4954C29.4362 22.239 28.5044 21.1784 27.2597 21.0077C24.5423 20.635 20.1003 19.9877 18.8674 19.5801C17.5405 19.1415 12.5849 16.7897 10.0225 15.5598C8.99504 15.0667 7.8757 14.8091 6.73607 14.8091H-6.31877C-7.31725 14.8091 -11.5016 19.8771 -11.5016 19.8771Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask2_3626_1018)">
              <path
                id="Vector 9"
                d="M-11.5016 19.8771H-13.9487C-14.5145 19.8771 -15.0323 20.1953 -15.25 20.7175C-15.6437 21.6619 -16.2081 23.2433 -16.2444 24.62C-16.3692 29.3628 -12.1257 29.6124 -12.1257 29.6124L27.9186 29.3718C28.7582 29.3668 29.4362 28.6847 29.4362 27.8451V23.4954C29.4362 22.239 28.5044 21.1784 27.2597 21.0077C24.5423 20.635 20.1003 19.9877 18.8674 19.5801C17.5405 19.1415 12.5849 16.7897 10.0225 15.5598C8.99504 15.0667 7.8757 14.8091 6.73607 14.8091H-6.31877C-7.31725 14.8091 -11.5016 19.8771 -11.5016 19.8771Z"
                fill="white"
              />
              <g id="Group 246">
                <mask
                  id="mask3_3626_1018"
                  style={{ maskType: 'alpha' }}
                  maskUnits="userSpaceOnUse"
                  x="-16"
                  y="15"
                  width="34"
                  height="9"
                >
                  <g id="Vector 5">
                    <path
                      d="M-12.1773 21.107L-15.3711 21.0005L-15.9951 23.1223H-14.4722C-13.8512 23.1223 -13.2579 22.8652 -12.8332 22.4122L-12.0035 21.5272C-11.8567 21.3706 -11.9628 21.1141 -12.1773 21.107Z"
                      fill="black"
                    />
                    <path
                      d="M11.569 20.6261H-4.58049C-5.17632 20.6261 -5.74775 20.3894 -6.16907 19.9681L-7.05752 19.0796C-7.24148 18.8956 -7.25334 18.6012 -7.08477 18.4031L-5.63624 16.7004C-5.01968 15.9756 -4.1161 15.558 -3.16456 15.558H7.01244C7.80561 15.558 8.5882 15.74 9.29997 16.09L16.9627 19.8579C17.058 19.9047 17.0903 20.0247 17.0314 20.113C16.9495 20.2359 16.7959 20.2896 16.6552 20.2445L14.5728 19.5769C14.4183 19.5274 14.2508 19.5369 14.1029 19.6037L12.0824 20.5156C11.921 20.5884 11.746 20.6261 11.569 20.6261Z"
                      fill="black"
                    />
                  </g>
                </mask>
                <g mask="url(#mask3_3626_1018)">
                  <g id="Vector 10">
                    <path
                      d="M-12.1773 21.107L-15.3711 21.0005L-15.9951 23.1223H-14.4722C-13.8512 23.1223 -13.2579 22.8652 -12.8332 22.4122L-12.0035 21.5272C-11.8567 21.3706 -11.9628 21.1141 -12.1773 21.107Z"
                      fill="black"
                    />
                    <path
                      d="M11.3937 20.6261H-4.58049C-5.17632 20.6261 -5.74775 20.3894 -6.16907 19.9681L-7.05752 19.0796C-7.24148 18.8956 -7.25334 18.6012 -7.08477 18.4031L-5.63624 16.7004C-5.01968 15.9756 -4.1161 15.558 -3.16456 15.558H7.01244C7.80561 15.558 8.5882 15.74 9.29997 16.09L16.9674 19.8601C17.0602 19.9058 17.0916 20.0227 17.0343 20.1087C16.9514 20.233 16.7941 20.284 16.6541 20.2321L13.8565 19.1943C13.5397 19.0768 13.1833 19.1625 12.9546 19.4112L12.138 20.2993C11.9465 20.5076 11.6766 20.6261 11.3937 20.6261Z"
                      fill="black"
                    />
                  </g>
                  <rect
                    id="Rectangle 4_2"
                    x="5.47852"
                    y="14.5244"
                    width="0.813361"
                    height="7.76151"
                    transform="rotate(41.5307 5.47852 14.5244)"
                    fill="#606770"
                  />
                  <rect
                    id="Rectangle 5_2"
                    x="7.52832"
                    y="13.9999"
                    width="1.40092"
                    height="8.40546"
                    transform="rotate(41.5307 7.52832 13.9999)"
                    fill="#606770"
                  />
                </g>
              </g>
              <path
                id="Vector 6"
                d="M28.2011 26.7417H29.9352L29.8104 29.4875H29.0616L27.9739 27.0946C27.8988 26.9293 28.0196 26.7417 28.2011 26.7417Z"
                fill="#474F59"
              />
              <path
                id="Vector 7"
                d="M29.8865 22.3718H26.7798C26.3905 22.3718 26.151 22.7974 26.353 23.1301L27.1875 24.5045C27.2781 24.6536 27.4398 24.7447 27.6143 24.7447H29.8865H31.2594V22.3718H29.8865Z"
                fill="black"
              />
            </g>
          </g>
          <path
            id="Rectangle 7"
            d="M-8.69043 29.3892H27.6454C27.6454 29.3892 25.0811 29.9235 23.4203 29.9235C10.8658 29.9235 -6.03465 29.9235 -6.03465 29.9235L-8.69043 29.3892Z"
            fill="black"
          />
          <path
            id="Ellipse 8_2"
            d="M24.2342 27.6546C24.2342 29.3082 22.8937 30.6487 21.2401 30.6487C19.5866 30.6487 18.2461 29.3082 18.2461 27.6546C18.2461 26.0011 19.5866 24.6606 21.2401 24.6606C22.8937 24.6606 24.2342 26.0011 24.2342 27.6546Z"
            fill="black"
          />
          <path
            id="Ellipse 8 (Stroke)_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.2401 25.2856C19.9318 25.2856 18.8711 26.3462 18.8711 27.6546C18.8711 28.963 19.9318 30.0237 21.2401 30.0237C22.5485 30.0237 23.6092 28.963 23.6092 27.6546C23.6092 26.3462 22.5485 25.2856 21.2401 25.2856ZM17.6211 27.6546C17.6211 25.6559 19.2414 24.0356 21.2401 24.0356C23.2389 24.0356 24.8592 25.6559 24.8592 27.6546C24.8592 29.6534 23.2389 31.2737 21.2401 31.2737C19.2414 31.2737 17.6211 29.6534 17.6211 27.6546Z"
            fill="black"
          />
        </g>
        <g id="Group 11">
          <g id="Group 12">
            <mask
              id="mask4_3626_1018"
              style={{ maskType: 'alpha' }}
              maskUnits="userSpaceOnUse"
              x="-30"
              y="15"
              width="51"
              height="15"
            >
              <path
                id="Vector 8"
                d="M-16.4248 15.7251C-17.3958 15.7251 -23.9628 17.112 -27.1929 17.8071C-28.2716 18.0392 -29.1044 18.9107 -29.2318 20.0067C-29.5291 22.5655 -29.4767 24.3312 -29.8618 28.2269C-29.931 28.9274 -29.3844 29.5419 -28.6805 29.5419H20.6771L20.8515 28.3301C21.0516 26.9407 20.3373 25.5827 19.0694 24.9803C15.9178 23.4828 9.96011 20.7316 6.18258 19.4829C-5.09829 15.7539 -4.29319 15.7251 -16.4248 15.7251Z"
                fill="#B2E7EB"
              />
            </mask>
            <g mask="url(#mask4_3626_1018)">
              <path
                id="Vector 10_2"
                d="M-16.4248 15.7251C-17.3958 15.7251 -23.9628 17.112 -27.1929 17.8071C-28.2716 18.0392 -29.1044 18.9107 -29.2318 20.0067C-29.5291 22.5655 -29.4767 24.3312 -29.8618 28.2269C-29.931 28.9274 -29.3844 29.5419 -28.6805 29.5419H20.1386C20.4463 29.5419 20.706 29.3129 20.7445 29.0076L20.8845 27.8976C21.064 26.4752 20.2909 25.1062 18.9731 24.5417C15.7978 23.1815 9.922 20.7191 6.18258 19.4829C-5.09829 15.7539 -4.29319 15.7251 -16.4248 15.7251Z"
                fill="#B2E7EB"
              />
              <g id="Group 10">
                <path
                  id="Vector 7_2"
                  d="M20.242 24.3144H17.6343C17.4285 24.3144 17.2617 24.4812 17.2617 24.6869V24.9663C17.2617 25.1721 17.4285 25.3389 17.6343 25.3389H20.242H21.2665V24.3144H20.242Z"
                  fill="black"
                />
              </g>
              <path
                id="Vector 5_2"
                d="M5.30781 22.3588H-12.4467C-13.3301 22.3588 -14.1659 21.9582 -14.7193 21.2696L-15.6351 20.1299C-15.9288 19.7644 -15.7513 19.222 -15.2953 19.113C-13.0087 18.5667 -7.16288 17.2976 -3.32083 17.5953C1.58098 17.9751 8.23848 21.1202 9.54921 21.7597C9.67342 21.8203 9.71097 21.9742 9.63431 22.0892C9.52773 22.249 9.32861 22.3197 9.14509 22.2627L7.03168 21.6069C6.81568 21.5399 6.58053 21.583 6.40243 21.7224L5.81109 22.1853C5.66743 22.2977 5.49025 22.3588 5.30781 22.3588Z"
                fill="black"
              />
            </g>
          </g>
          <g id="Group 9">
            <path
              id="Ellipse 8_3"
              d="M15.0252 27.64C15.0252 29.2987 13.6806 30.6434 12.0219 30.6434C10.3632 30.6434 9.01855 29.2987 9.01855 27.64C9.01855 25.9814 10.3632 24.6367 12.0219 24.6367C13.6806 24.6367 15.0252 25.9814 15.0252 27.64Z"
              fill="black"
            />
            <path
              id="Ellipse 8 (Stroke)_3"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.0219 25.2617C10.7084 25.2617 9.64355 26.3265 9.64355 27.64C9.64355 28.9535 10.7084 30.0183 12.0219 30.0183C13.3354 30.0183 14.4002 28.9535 14.4002 27.64C14.4002 26.3265 13.3354 25.2617 12.0219 25.2617ZM8.39355 27.64C8.39355 25.6361 10.018 24.0117 12.0219 24.0117C14.0257 24.0117 15.6502 25.6361 15.6502 27.64C15.6502 29.6438 14.0257 31.2683 12.0219 31.2683C10.018 31.2683 8.39355 29.6438 8.39355 27.64Z"
              fill="black"
            />
          </g>
        </g>
        <rect id="Rectangle 6" y="31.2213" width="47.5573" height="12.0611" fill="#474F59" />
      </g>
    </g>
  </svg>
);

export default MenuLeftAccountIcon;
