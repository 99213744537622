import { Table } from 'antd';
import React from 'react';
import { DEFAULT_PAGE_SIZE } from 'constants/tables';

const OffsetPaginationTable = ({
  current,
  loading,
  totalCount,
  dataSource,
  paginationTestId,
  onPageChange,
  onPageSizeChange,
  onFilterChange,
  onSortChange,
  ...tableProps
}) => {
  return (
    <Table
      loading={loading}
      dataSource={dataSource}
      pagination={{
        'data-testid': paginationTestId,
        current,
        total: totalCount,
        onChange: pageNumber => {
          onPageChange(pageNumber);
        },
        onShowSizeChange: onPageSizeChange,
        responsive: true,
        pageSizeOptions: [DEFAULT_PAGE_SIZE, 50, 100, 200],
        defaultPageSize: DEFAULT_PAGE_SIZE,
        showSizeChanger: true,
      }}
      {...tableProps}
    />
  );
};

OffsetPaginationTable.defaultProps = {
  onPageChange: () => {},
};

export default OffsetPaginationTable;
