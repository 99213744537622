const actions = {
  SET_STATE: 'paymentsSpa/SET_STATE',
  GET: 'paymentsSpa/GET',
  GET_SUCCESS: 'paymentsSpa/GET_SUCCESS',
  GET_ERROR: 'paymentsSpa/GET_ERROR',
  PUT: 'paymentsSpa/PUT',
  PUT_SUCCESS: 'paymentsSpa/PUT_SUCCESS',
  PUT_ERROR: 'paymentsSpa/PUT_ERROR',
  DELETE: 'paymentsSpa/DELETE',
  DELETE_SUCCESS: 'paymentsSpa/DELETE_SUCCESS',
  DELETE_ERROR: 'paymentsSpa/DELETE_ERROR',
  RESET_FORM_ERRORS: 'paymentsSpa/RESET_FORM_ERRORS',
  RESET_SUCCESS: 'paymentsSpa/RESET_SUCCESS',
  SET_FORM_DATA: 'paymentsSpa/SET_FORM_DATA',
};

export default actions;
