import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Tabs } from 'antd';
import { injectIntl } from 'react-intl';
import Authorize from 'components/Providers/Authorize';
import PageWrapper from 'components/Shared/Page/Wrapper';
import PageTitle from 'components/Shared/Page/Title';
import withPusher from 'components/Providers/Pusher/withPusher';
import PageBanners from 'components/Layout/PageBanners';
import useTranslation from 'utils/hooks/useTranslation';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import BillingAddress from './BillingAddress';
import SubscriptionPage from './SubscriptionPage';
import useAmplitude from '../../utils/hooks/useAmplitude';
import useBillingPage from './useBillingPage';

const { TabPane } = Tabs;

const Billing = ({ history }) => {
  const { sendAmplitudeEvent } = useAmplitude();
  const { translateText } = useTranslation();

  const isMobile = useSelector(state => state.settings.isMobile);

  const {
    isGetBillingiformationLoading,
    isEditBillingInformationLoading,
    billingInformation,
    onSubmit,
  } = useBillingPage();

  useEffect(() => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.billingPage);
  }, [sendAmplitudeEvent]);

  return (
    <>
      <Authorize>
        <Helmet title="Billing and subscription" />
        <PageBanners />
        <PageWrapper history={history} loading={isGetBillingiformationLoading}>
          <PageTitle title={translateText('corporateAccounts.menuLeft.billing')} />
          <div className="card-body">
            <Tabs defaultActiveKey="1">
              <TabPane
                tab={<span>{translateText('corporateAccounts.billing.billingAddress')}</span>}
                key="1"
              >
                <BillingAddress
                  isMobile={isMobile}
                  isLoading={isEditBillingInformationLoading}
                  billingInformation={billingInformation}
                  onSubmit={onSubmit}
                />
              </TabPane>
              <TabPane
                data-testid={'subscriptionBtn'}
                tab={<span>{translateText('corporateAccounts.billing.subscription')}</span>}
                key="2"
              >
                <SubscriptionPage />
              </TabPane>
            </Tabs>
          </div>
        </PageWrapper>
      </Authorize>
    </>
  );
};

export default withPusher(injectIntl(Billing));
