import { REHYDRATE } from 'redux-persist/lib/constants';
import { all, takeEvery, put, call, take } from 'redux-saga/effects';
import notify from 'utils/notificationUtils';
import { paymentsSpa } from 'services/api';
import { localizedMessage } from 'services/localize';
import { isSuccess, isBadRequest, isNotFound, isInteralServerError } from 'utils/sagasUtil';
// import eventPollerActions from '../eventPoller/actions';
import actions from './actions';

export function* GET() {
  const { response } = yield call(paymentsSpa.read);

  if (isSuccess(response)) {
    yield put({
      type: actions.GET_SUCCESS,
      payload: response.data,
    });
  } else {
    yield put({
      type: actions.GET_ERROR,
    });
  }
}

export function* PUT({ payload }) {
  const { response, error } = yield call(paymentsSpa.update, payload);

  if (isSuccess(response)) {
    yield put({
      type: actions.PUT_SUCCESS,
      payload: response.data,
    });

    notify({
      type: 'success',
      message: localizedMessage('corporateAccounts.payments.update.success'),
    });
  } else if (isBadRequest(error)) {
    notify({
      type: 'error',
      message: localizedMessage('corporateAccounts.payments.generic.error'),
    });
    yield put({
      type: actions.PUT_ERROR,
      payload: error.errors,
    });
  } else {
    yield put({
      type: actions.PUT_ERROR,
    });
  }
}

export function* DELETE({ payload }) {
  const { response, error } = yield call(paymentsSpa.destroy, payload.id);

  if (isSuccess(response)) {
    yield put({
      type: actions.DELETE_SUCCESS,
      payload,
    });
    notify({
      type: 'success',
      message: localizedMessage('corporateAccounts.payments.toast.paymentMethodDeleted'),
    });
  } else {
    if (isNotFound(error.response)) {
      notify({
        type: 'error',
        message: localizedMessage('corporateAccounts.payments.error.delete.notFound'),
      });
    }

    if (isInteralServerError(error.response)) {
      notify({
        type: 'error',
        message: localizedMessage('corporateAccounts.common.error.somethingWentWrong'),
      });
    }

    yield put({
      type: actions.DELETE_ERROR,
    });
  }
}

export default function* rootSaga() {
  // Wait for redux-persist to rehydrate to prevent sagas running against empty store
  yield take(REHYDRATE);
  yield all([takeEvery(actions.GET, GET)]);
  yield all([takeEvery(actions.PUT, PUT)]);
  yield all([takeEvery(actions.DELETE, DELETE)]);
}
