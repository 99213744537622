import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Header } from 'antd/lib/layout/layout';
import { Logo } from 'components/Shared/Logos';
import HeaderMenu from './HeaderMenu';
import HeaderDrawer from './HeaderDrawer';
import MobileHeaderMenu from './MobileHeaderMenu';
import styles from './style.module.scss';

const ResponsiveHeader = () => {
  const isMobile = useSelector(state => state.settings.isMobileView);
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  return (
    <>
      <Header id={styles.header}>
        <section className={styles.headerGroup}>
          <Logo />
        </section>
        {isMobile ? (
          <MobileHeaderMenu isOpen={isDrawerOpen} setOpen={setDrawerOpen} />
        ) : (
          <HeaderMenu />
        )}
      </Header>
      <HeaderDrawer isOpen={isDrawerOpen} />
    </>
  );
};

export default ResponsiveHeader;
