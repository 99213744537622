import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import useTranslation from 'utils/hooks/useTranslation';

const NotFoundPage = () => {
  const { translateText } = useTranslation();

  return (
    <div
      style={{
        minHeight: 'calc(100vh - 500px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Helmet title="Not Found" />
      <div
        style={{
          maxWidth: '560px',
          backgroundColor: '#fff',
          padding: '80px 30px',
          margin: '100px auto',
          borderRadius: '10px',
          flex: '1',
        }}
      >
        <div
          style={{
            maxWidth: '400px',
            margin: '0 auto',
          }}
        >
          <Typography.Title>{translateText('corporateAccounts.notFound')}</Typography.Title>
          <Typography.Paragraph>
            {translateText('corporateAccounts.pageDeprecated')}
          </Typography.Paragraph>
          <Typography.Title style={{ fontSize: '80px' }}>404 —</Typography.Title>
          <Link to="/" className="btn">
            &larr;&nbsp;<span>{translateText('corporateAccounts.homePage')}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
