import React, { useEffect, useState, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Input, Form, Row, Col, Select, Typography } from 'antd';
import ReCAPTCHA from 'react-google-recaptcha-enterprise';
import CreditCardNumber from 'components/Shared/CardUtils/CreditCardNumber';
import CreditCardCvc from 'components/Shared/CardUtils/CreditCardCvc';
import paymentHelper from 'pages/PaymentsSpa/helper';
import { COUNTRIES, RECAPTCHA_SITE_KEY, SUPPORTED_COUNTRIES } from 'constants.js';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import { UNSUPPORTED_CREDIT_CARDS } from 'constants/paymentsSpa';
import useAmplitude from 'utils/hooks/useAmplitude';
import useTranslation from 'utils/hooks/useTranslation';
import useIsFormValid from 'utils/hooks/useIsFormValid';
import formHelper from './helper';

import styles from './style.module.scss';

const { Option } = Select;

const PaymentSpaForm = ({
  corporateClientId,
  formData,
  formErrors,
  onCancel,
  onSubmit,
  submitting,
  language,
}) => {
  const { translateText } = useTranslation();
  const { sendAmplitudeEvent } = useAmplitude();
  const [form] = Form.useForm();
  const [creditCardType, setCreditCardType] = useState('');
  const [isCvcValid, setCvcValidation] = useState(false);
  const [isCreditCardValid, setCreditCardValidation] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isNameOnCardRequired, setIsNameOnCardRequired] = useState(true);
  const [isZipCodeRequired, setIsZipCodeOnCardRequired] = useState(true);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [isRecaptchaResetRequired, setIsRecaptchaResetRequired] = useState(true);

  const { setFieldsValue, setFields, getFieldValue, validateFields } = form;
  const fieldValidationConfig = [
    { name: 'cardNumber', required: true },
    { name: 'paymentCardType', required: true },
    { name: 'nameOnCard', required: isNameOnCardRequired },
    { name: 'paymentCardType', required: true },
    { name: 'expiryMonth', required: true },
    { name: 'expiryYear', required: true },
    { name: 'cvv', required: true },
    { name: ['billingAddress', 'zipCode'], required: isZipCodeRequired },
    { name: 'countryCode', required: true },
  ];
  const { isFormValid, validateForm } = useIsFormValid(form, fieldValidationConfig);

  const recaptchaRef = useRef(null);

  const generateToken = token => {
    setRecaptchaToken(token);
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleCreditCardChange = ({ valid, type }) => {
    setCreditCardValidation(valid);
    setCreditCardType(type);
    setFieldsValue({ paymentCardType: type });
  };

  const handleCvcChange = ({ valid }) => setCvcValidation(valid);

  const handleExpiryChange = expiryDates => {
    return paymentHelper.validateExpiryDate(expiryDates);
  };

  const handleEdit = () => {
    validateFields(['id', 'note'])
      .then(values => {
        onSubmit({ ...values, corporateClientId });
        onCancel();
      })
      .catch(() => {});
  };

  const handleSubmit = () => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.paymentSave);

    validateFields()
      .then(values => {
        values.cardNumber = values.cardNumber.value.replace(/\s/g, '');
        values.cvv = values.cvv.value;
        values.recaptchaToken = recaptchaToken;
        values.corporateClientId = corporateClientId;
        if (values.billingAddress.zipCode && values.billingAddress.zipCode.replace(/\s/g, '')) {
          values.billingAddress.zipCode = values.billingAddress.zipCode.replace(/\s/g, '');
        } else {
          values.billingAddress = null;
        }
        onSubmit(values);
        setRecaptchaToken(null);
      })
      .catch(() => {});
  };

  const handleCountryChange = countryCode => {
    if (countryCode === COUNTRIES.CA.countryCode || countryCode === COUNTRIES.US.countryCode) {
      setIsZipCodeOnCardRequired(true);
    } else {
      setIsZipCodeOnCardRequired(false);
    }
    const isRequired = countryCode !== COUNTRIES.FR.countryCode;
    return isRequired ? setIsNameOnCardRequired(true) : setIsNameOnCardRequired(false);
  };

  const expiryMonths = paymentHelper.buildExpiryMonths();

  const displayInitialValue = field => {
    return isEditMode && formData ? formData[field] : undefined;
  };

  const renderSubmitLabel = () => {
    if (isEditMode) {
      return translateText('corporateAccounts.actions.save');
    }

    return translateText('corporateAccounts.payments.actions.addAndAgree');
  };

  useEffect(() => {
    if (submitting) {
      setIsRecaptchaResetRequired(true);
    }
    if (!submitting && isRecaptchaResetRequired) {
      recaptchaRef.current?.reset();
      setIsRecaptchaResetRequired(false);
    }
  }, [isRecaptchaResetRequired, submitting]);

  useEffect(() => {
    const currentErrors = formHelper.buildFieldErrors(formErrors, translateText);
    setFields(currentErrors);
  }, [formErrors, setFields, translateText]);

  useEffect(() => {
    if (formData) {
      setIsEditMode(true);
      setFieldsValue({
        cvv: '***',
        countryCode: '--',
        billingAddress: { zipCode: formData.zipCode },
        ...formData,
      });
    }
  }, [formData, setFieldsValue]);

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        style={{ paddingBottom: '100px' }}
        onFieldsChange={validateForm}
      >
        {isEditMode && (
          <Row gutter={[48, 48]}>
            <Col span={8}>
              <Form.Item name="id" hidden initialValue={formData?.id}>
                <Input />
              </Form.Item>
              <Form.Item
                name="note"
                initialValue={displayInitialValue('note')}
                label={translateText('corporateAccounts.payments.cardName')}
                rules={[
                  {
                    required: true,
                    message: translateText('corporateAccounts.payments.form.cardNameRequired'),
                  },
                  {
                    max: 50,
                    message: translateText('corporateAccounts.payments.form.cardNameLength', {
                      limit: '50',
                    }),
                  },
                ]}
              >
                <Input placeholder={translateText('corporateAccounts.payments.cardName')} />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row gutter={[48, 48]}>
          <Col span={8}>
            <Form.Item
              name="cardNumber"
              initialValue={displayInitialValue('cardNumber')}
              label={translateText('corporateAccounts.payments.cardNumber')}
              rules={[
                {
                  required: true,
                  message: translateText('corporateAccounts.payments.form.cardNumberRequired'),
                },
                {
                  validator: () =>
                    UNSUPPORTED_CREDIT_CARDS.find(card => card.type === creditCardType)
                      ? Promise.reject()
                      : Promise.resolve(),
                  message: translateText(
                    'corporateAccounts.payments.form.creditCardTypeNotSupported',
                    {
                      creditCardType,
                    },
                  ),
                },
                {
                  validator: (rule, value) =>
                    isCreditCardValid ? Promise.resolve() : Promise.reject(),
                  message: translateText('corporateAccounts.payments.form.cardNumberValidation'),
                },
              ]}
            >
              <CreditCardNumber
                isEditMode={isEditMode}
                placeholder={translateText('corporateAccounts.payments.form.cardNumberPlaceholder')}
                onChange={handleCreditCardChange}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="paymentCardType"
              initialValue={displayInitialValue('cardType')}
              label={translateText('corporateAccounts.payments.cardType')}
              rules={[
                {
                  required: true,
                  message: translateText('corporateAccounts.payments.form.cardTypeRequired'),
                },
              ]}
            >
              <Input
                placeholder={translateText('corporateAccounts.payments.form.cardTypePlaceholder')}
                disabled
                readOnly
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="nameOnCard"
              initialValue={displayInitialValue('nameOnCard')}
              label={translateText('corporateAccounts.payments.nameOnCard')}
              rules={[
                {
                  required: isNameOnCardRequired,
                  message: translateText('corporateAccounts.payments.form.nameOnCardRequired'),
                },
                {
                  pattern: new RegExp('^[^<>"\'&]+$'),
                  message: translateText('corporateAccounts.payments.form.nameOnCardFormat'),
                },
                {
                  max: 70,
                  message: translateText('corporateAccounts.payments.form.nameOnCardLength'),
                },
                {
                  min: 2,
                  message: translateText('corporateAccounts.payments.form.nameOnCardLength'),
                },
              ]}
            >
              <Input
                placeholder={translateText('corporateAccounts.payments.form.nameOnCardPlaceholder')}
                disabled={isEditMode}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[48, 48]}>
          <Col span={4}>
            <Form.Item
              name="expiryMonth"
              validateTrigger="onBlur"
              initialValue={displayInitialValue('expiryMonth')}
              label={translateText('corporateAccounts.payments.expiryMonth')}
              rules={[
                {
                  required: true,
                  message: translateText(
                    'corporateAccounts.corporateClient.payment.expiryMonth.error.presence',
                  ),
                },
                {
                  validator: (rule, value) =>
                    handleExpiryChange({
                      month: value,
                      year: getFieldValue('expiryYear'),
                    })
                      ? Promise.resolve()
                      : Promise.reject(),
                  message: translateText('corporateAccounts.payments.form.cardExpiryValidation'),
                },
              ]}
            >
              <Select
                placeholder={translateText(
                  'corporateAccounts.payments.form.expiryMonthPlaceholder',
                )}
                disabled={isEditMode}
              >
                {expiryMonths &&
                  expiryMonths.map(month => (
                    <Option
                      data-testid={`selectExpiryMonth-${month.label}`}
                      key={month.value}
                      value={month.value}
                    >
                      {month.label}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="expiryYear"
              initialValue={displayInitialValue('expiryYear')}
              label={translateText('corporateAccounts.payments.expiryYear')}
              rules={[
                {
                  max: 4,
                  message: translateText('corporateAccounts.payments.form.cardExpiryValidation'),
                },
                {
                  required: true,
                  message: translateText(
                    'corporateAccounts.corporateClient.payment.expiryYear.error.presence',
                  ),
                },
                {
                  validator: (rule, value) =>
                    handleExpiryChange({
                      month: getFieldValue('expiryMonth'),
                      year: value,
                    })
                      ? Promise.resolve()
                      : Promise.reject(),
                  message: translateText('corporateAccounts.payments.form.cardExpiryValidation'),
                },
              ]}
            >
              <Input
                placeholder={translateText('corporateAccounts.payments.form.expiryYearPlaceholder')}
                onChange={handleExpiryChange}
                disabled={isEditMode}
              />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item
              name="cvv"
              initialValue={displayInitialValue('cvv')}
              label={translateText('corporateAccounts.payments.cardCvc')}
              rules={[
                {
                  required: true,
                  message: translateText('corporateAccounts.payments.form.cardCvcRequired'),
                },
                {
                  validator: (rule, value) => (isCvcValid ? Promise.resolve() : Promise.reject()),
                  message: translateText('corporateAccounts.payments.form.cardCvcValidation'),
                },
              ]}
            >
              <CreditCardCvc
                isEditMode={isEditMode}
                onChange={handleCvcChange}
                cardType={creditCardType}
                placeholder={translateText('corporateAccounts.payments.cvc')}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name={['billingAddress', 'zipCode']}
              initialValue={isEditMode ? formData?.billingAddress?.zipCode : null}
              label={translateText('corporateAccounts.payments.zipCode')}
              rules={[
                {
                  required: isZipCodeRequired,
                  message: translateText('corporateAccounts.payments.form.zipCodeRequired'),
                },
              ]}
            >
              <Input
                placeholder={translateText('corporateAccounts.payments.form.zipCodePlaceholder')}
                disabled={isEditMode}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="countryCode"
              validateTrigger="onBlur"
              initialValue={displayInitialValue('country')}
              label={translateText('corporateAccounts.payments.form.primaryCountryOfUse')}
              rules={[
                {
                  required: true,
                  message: translateText('corporateAccounts.payments.form.cardCountryCodeRequired'),
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder={translateText(
                  'corporateAccounts.payments.form.cardCountryCodePlaceholder',
                )}
                disabled={isEditMode}
                onChange={handleCountryChange}
              >
                {Object.keys(SUPPORTED_COUNTRIES).map(key => (
                  <Option
                    key={SUPPORTED_COUNTRIES[key].countryCode}
                    value={SUPPORTED_COUNTRIES[key].countryCode}
                    data-testid={`selectCountry-${SUPPORTED_COUNTRIES[key].countryCode}`}
                  >
                    {translateText(
                      `corporateAccounts.countries.${SUPPORTED_COUNTRIES[
                        key
                      ].countryCode.toLowerCase()}`,
                      {
                        defaultMessage: SUPPORTED_COUNTRIES[key].label,
                      },
                    )}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row className={!isEditMode ? styles.addPaymentsfooter : styles.editPaymentsfooter}>
          {!isEditMode && (
            <>
              <Col span={18}>
                {translateText('corporateAccounts.payments.form.termsAgreement')}
                &nbsp;
                <Typography.Link
                  href={paymentHelper.getStoredCredentialAgreementUrl(language)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {translateText('corporateAccounts.payments.form.storedCredentialAgreement')}
                </Typography.Link>
                .
              </Col>
              <Col span={24}>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  data-testid="recaptcha"
                  id="recaptcha"
                  sitekey={RECAPTCHA_SITE_KEY}
                  onChange={generateToken}
                  onExpired={() => setRecaptchaToken(null)}
                />
              </Col>
            </>
          )}

          <Col span={6} offset={isEditMode ? 18 : 0}>
            <Button onClick={handleCancel}>
              {translateText('corporateAccounts.actions.cancel')}
            </Button>
            <Button
              data-testid="submitSpaPaymentForm"
              loading={submitting}
              onClick={isEditMode ? handleEdit : handleSubmit}
              type="primary"
              disabled={!isEditMode && (!isFormValid || !recaptchaToken)}
            >
              {renderSubmitLabel()}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default injectIntl(PaymentSpaForm);
