import { Modal } from 'antd';

const bulkDeleteModal = ({ onDeleteConfirm }, translateText) => {
  Modal.confirm({
    onOk: onDeleteConfirm,
    onCancel: () => {},
    title: translateText('corporateAccounts.vehicles.modal.title.bulkDelete'),
    content: translateText('corporateAccounts.vehicles.modal.content.bulkDelete'),
    cancelText: translateText('corporateAccounts.actions.cancel', 'Cancel'),
    okText: translateText('corporateAccounts.vehicles.actions.deleteVehicles'),
    okType: 'danger',
    icon: false,
  });
};

export default bulkDeleteModal;
