import React, { Component } from 'react';
import Loader from 'components/Shared/Loader';
import cacheUtil from 'utils/cacheUtil';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error Boundary Caught:', error, errorInfo);
  }

  render() {
    const { error, hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      if (error.name === 'ChunkLoadError') cacheUtil.refreshCacheAndReload();
      return <Loader spinning />;
    }

    return children;
  }
}
