import { URL_BUSINESS_PORTAL } from 'constants.js';

const useYouTubePlayer = ({ title, videoUrl, width = 500, height = 281, iframeRef }) => {
  return (
    <iframe
      title={title}
      src={`${videoUrl}?origin=${URL_BUSINESS_PORTAL}&enablejsapi=1`}
      style={{ border: 'none' }}
      allowFullScreen
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      width={width}
      height={height}
      ref={iframeRef}
      className="player"
    />
  );
};

export default useYouTubePlayer;
