// UNCOMMENT LINES WHEN WE HAVE THE VIDEO LINK

import React, { useState } from 'react';
import { Button } from 'antd';
import Can from 'config/Can';
import { ONBOARDING_VIDEO_TOPICS } from 'constants/onboarding';
// import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import useTranslation from 'utils/hooks/useTranslation';
// import useAmplitude from 'utils/hooks/useAmplitude';
import PaymentMethodsIcon from 'components/Shared/Icons/PaymentMethodsIcon';
import EmptyState from 'components/Layout/EmptyState';
import HowToVideo from 'components/Shared/HowToVideo';

// import styles from './style.module.scss';

const PaymentsEmptyState = ({ handleAddPayment, visible }) => {
  const { translateText } = useTranslation();
  // const { sendAmplitudeEvent } = useAmplitude();

  const [isVideoVisible, setIsVideoVisible] = useState(false);

  // const openHowToVideo = () => {
  //   setIsVideoVisible(true);
  //   sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.howToAddPaymentMethodVideoOpened);
  // };

  if (!visible) return null;

  return (
    <>
      <EmptyState
        icon={<PaymentMethodsIcon />}
        title={translateText('corporateAccounts.payments.emptyState.title')}
        description={translateText('corporateAccounts.payments.emptyState.description')}
        buttons={
          <Can I="create" a="PaymentSpa" passThrough>
            {can => (
              <Button
                type="primary"
                size="large"
                onClick={handleAddPayment}
                disabled={!can}
                data-testid="addPaymentButton"
              >
                {translateText('corporateAccounts.payments.emptyState.addCreditCardBtn')}
              </Button>
            )}
          </Can>
        }
        // videoLink={
        //   <span className={styles.buttonAsLink} onClick={openHowToVideo}>
        //     {translateText('corporateAccounts.payments.emptyState.videoLink')}
        //   </span>
        // }
      />
      <HowToVideo
        topic={ONBOARDING_VIDEO_TOPICS.HOW_TO_PAYMENTS}
        title={translateText('corporateAccounts.payments.emptyState.videoLink')}
        isVisible={isVideoVisible}
        setIsVisible={setIsVideoVisible}
      />
    </>
  );
};

export default PaymentsEmptyState;
