import {
  ROUTE_SPA_PAYMENT_CREATE,
  ROUTE_SPA_PAYMENT_EDIT,
  TERMS_AND_CONDITIONS_URL,
  PRIVACY_URL,
  BUSINESS_SUBSCRIPTION_AGREEMENT_URL,
  STORED_CREDENTIAL_AGREEMENT_URL,
} from 'constants.js';

const handleNavigateToAddPayment = history => {
  history.push(ROUTE_SPA_PAYMENT_CREATE);
};

const handleNavigateToEditPayment = history => {
  history.push(ROUTE_SPA_PAYMENT_EDIT);
};

const buildExpiryMonths = () => {
  const months = [...Array(13).keys()].slice(1); // range 1 to 12

  const expiryMonths = months.map(month => {
    return {
      label: month,
      value: month,
    };
  });

  return expiryMonths;
};

const validateExpiryDate = ({ month, year }) => {
  if (month && year) {
    const currentDate = new Date();
    const inputDate = new Date();

    inputDate.setFullYear(Number(year), month, inputDate.getDate());

    return inputDate > currentDate;
  }

  return true;
};

const getLocalizedUrl = (urls, language) => {
  const shortLanguage = language.substring(0, 2);
  /* eslint-disable no-prototype-builtins */
  return urls.hasOwnProperty(shortLanguage) ? urls[shortLanguage] : urls.en;
};

const getTermsAndConditionsUrl = language => {
  return getLocalizedUrl(TERMS_AND_CONDITIONS_URL, language);
};

const getPrivacyUrl = language => {
  return getLocalizedUrl(PRIVACY_URL, language);
};

const getBusinessSubscriptionAgreementUrl = language => {
  return getLocalizedUrl(BUSINESS_SUBSCRIPTION_AGREEMENT_URL, language);
};

const getStoredCredentialAgreementUrl = language => {
  return getLocalizedUrl(STORED_CREDENTIAL_AGREEMENT_URL, language);
};

const paymentHelper = {
  buildExpiryMonths,
  handleNavigateToAddPayment,
  handleNavigateToEditPayment,
  validateExpiryDate,
  getTermsAndConditionsUrl,
  getPrivacyUrl,
  getBusinessSubscriptionAgreementUrl,
  getStoredCredentialAgreementUrl,
};

export default paymentHelper;
