import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Table } from 'antd';
import { injectIntl } from 'react-intl';
import Authorize from 'components/Providers/Authorize';
import PageWrapper from 'components/Shared/Page/Wrapper';
import PageTitle from 'components/Shared/Page/Title';
import withPusher from 'components/Providers/Pusher/withPusher';
import PageBanners from 'components/Layout/PageBanners';
import actions from 'redux/sessions/actions';
import useTranslation from 'utils/hooks/useTranslation';
import useAmplitude from 'utils/hooks/useAmplitude';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import tableColumns from './tableColumns';

const filterData = (data, filters) => data.filter(row => includeRow(row, filters));

const includeRow = (row, filters) => {
  const results = Object.keys(filters).map(
    key => filters[key].length === 0 || columnFilters[key](filters[key], row[key]),
  );
  return results.reduce((accumulator, result) => accumulator && result, true);
};

const columnFilters = {
  unknownVehicle: (filterValues, rowValue) => filterValues.indexOf(rowValue) !== -1,
  unknownTime: (filterValues, rowValue) => filterValues.indexOf(rowValue) !== -1,
  startDateTime: (filterValues, rowValue) => {
    const day = new Date(rowValue).getUTCDay();
    return filterValues.indexOf(day) !== -1;
  },
};

const Sessions = ({ corporateClientId, getSessions, sessions, loading, history }) => {
  const { sendAmplitudeEvent } = useAmplitude();
  const [tableData, setTableData] = useState(sessions);
  const { translateText } = useTranslation();
  const locale = useSelector(state => state.settings.locale);

  const handleTableChange = (pagination, filters) => {
    const filteredData = filterData(tableData, filters);
    setTableData(filteredData);
  };

  useEffect(() => {
    if (corporateClientId) getSessions();
  }, [corporateClientId, getSessions]);

  useEffect(() => {
    if (tableData !== sessions) setTableData(sessions);
  }, [sessions, tableData]);

  useEffect(() => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.sessionPage);
  }, [sendAmplitudeEvent]);

  return (
    <Authorize>
      <Helmet title="Parking Sessions" />
      <PageBanners />

      <PageWrapper loading={loading} history={history}>
        <PageTitle
          title={translateText('corporateAccounts.menuLeft.sessions')}
          numRecords={tableData.length}
          description={translateText('corporateAccounts.sessions.main.description')}
        />
        {!loading && (
          <div className="card-body">
            <Table
              loading={loading}
              scroll={{ x: true }}
              className="utils__scrollTable"
              columns={tableColumns(translateText, locale)}
              rowKey={record => record.transactionId}
              dataSource={tableData}
              onChange={handleTableChange}
              bordered
            />
          </div>
        )}
      </PageWrapper>
    </Authorize>
  );
};

export default withPusher(
  connect(
    ({ sessions, user }) => ({
      corporateClientId: user.corporateClientId,
      sessions: sessions.data,
      loading: sessions.loading,
    }),
    dispatch => ({
      getSessions: () => dispatch({ type: actions.GET }),
    }),
  )(injectIntl(Sessions)),
);
