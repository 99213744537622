import React from 'react';

const CheckCircleGreenIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="checkCircleGreenIcon"
  >
    <g id="check-circle-2">
      <path
        id="Vector"
        d="M7.99999 15.1666C11.682 15.1666 14.6667 12.1819 14.6667 8.49992C14.6667 4.81792 11.682 1.83325 7.99999 1.83325C4.31799 1.83325 1.33333 4.81792 1.33333 8.49992C1.33333 12.1819 4.31799 15.1666 7.99999 15.1666Z"
        fill="#6CC24A"
      />
      <path
        id="Vector_2"
        d="M6 8.49984L7.33333 9.83317L10 7.1665"
        stroke="white"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default CheckCircleGreenIcon;
