import React from 'react';

const VerifyEmailIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
  >
    <path
      d="M60 120C93.1371 120 120 93.1371 120 60C120 26.8629 93.1371 0 60 0C26.8629 0 0 26.8629 0 60C0 93.1371 26.8629 120 60 120Z"
      fill="#6CC24A"
    />
    <path
      d="M89.2498 40.9535V79.0466C89.2499 79.4039 89.1795 79.7578 89.0428 80.0879C88.9061 80.4181 88.7057 80.7181 88.4531 80.9708C88.2004 81.2234 87.9004 81.4239 87.5702 81.5606C87.2401 81.6973 86.8862 81.7676 86.5288 81.7675H33.4707C33.1134 81.7676 32.7595 81.6973 32.4293 81.5606C32.0992 81.4239 31.7992 81.2234 31.5465 80.9708C31.2938 80.7181 31.0934 80.4181 30.9567 80.0879C30.82 79.7578 30.7497 79.4039 30.7498 79.0466V40.9535C30.7481 40.6423 30.8034 40.3333 30.9132 40.042C31.0758 39.5772 31.3629 39.1658 31.7431 38.8528C32.1232 38.5397 32.582 38.337 33.0694 38.2666C33.2021 38.2449 33.3363 38.2335 33.4707 38.2326H86.5288C86.6633 38.2335 86.7974 38.2449 86.9301 38.2666C87.4175 38.337 87.8763 38.5397 88.2565 38.8527C88.6367 39.1658 88.9237 39.5771 89.0863 40.042C89.1961 40.3333 89.2515 40.6423 89.2498 40.9535Z"
      fill="white"
    />
    <path
      d="M89.0865 40.0419L60.8093 61.0883C60.5764 61.2648 60.2922 61.3604 59.9999 61.3604C59.7076 61.3604 59.4234 61.2648 59.1904 61.0883L30.9133 40.0419C31.076 39.577 31.363 39.1657 31.7432 38.8527C32.1234 38.5396 32.5822 38.3369 33.0696 38.2665L60 58.3061L86.9302 38.2665C87.4177 38.3368 87.8765 38.5395 88.2567 38.8526C88.6369 39.1656 88.9239 39.577 89.0865 40.0419Z"
      fill="#6CC24A"
    />
  </svg>
);

export default VerifyEmailIcon;
