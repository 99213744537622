import Loader from 'components/Shared/Loader';
import { USER_VERIFICATION_ERRORS } from 'constants/users';
import VerificationSuccess from './VerificationSuccess';
import VerificationFailed from './VerificationFailed';
import VerificationLinkExpired from './VerificationLinkExpired';

import styles from './style.module.scss';

const VerificationStatus = ({ verificationError, isVerificationSuccessful, onResendEmail }) => {
  return (
    <section className={styles.verificationContent} data-testid="main">
      {isVerificationSuccessful ? (
        <VerificationSuccess />
      ) : verificationError === USER_VERIFICATION_ERRORS.VerificationCodeExpired ? (
        <VerificationLinkExpired onResendEmail={onResendEmail} />
      ) : verificationError === USER_VERIFICATION_ERRORS.VerificationCodeNotFound ||
        verificationError === USER_VERIFICATION_ERRORS.InternalError ? (
        <VerificationFailed />
      ) : (
        <div className="card-body">
          <Loader spinning />
        </div>
      )}
    </section>
  );
};

export default VerificationStatus;
