import React from 'react';

const AllstarIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="40" viewBox="0 0 64 40" fill="none">
    <path
      d="M2.94434 35.8352C2.94434 32.7983 5.05569 32.0753 9.82792 32.0753H10.2618C10.2618 31.0919 10.0015 30.6002 8.15041 30.6002C6.29936 30.6002 5.69199 31.063 5.05569 31.4679L3.58063 29.2698C4.33262 28.7492 6.24152 27.9683 8.49748 27.9683C12.3731 27.9683 13.154 29.2119 13.154 32.2488V35.5171C13.154 36.3847 13.2119 36.5004 14.0795 36.5004V38.9299C13.8771 38.9878 13.5011 39.0167 12.8359 39.0167C11.7079 39.0167 11.2162 38.7853 10.7824 37.9466C10.2328 38.6986 9.04701 39.1324 7.02243 39.1324C3.69632 39.1324 3.00218 37.6863 3.00218 35.8063M10.2328 34.5048V34.0999H9.97254C6.18367 34.0999 5.72091 34.418 5.72091 35.5749C5.72091 36.7318 6.15475 36.7607 7.51411 36.7607C8.87348 36.7607 10.2039 36.0955 10.2039 34.5048"
      fill="black"
      style={{ fill: 'black', fillOpacity: 1 }}
    />
    <path
      d="M14.8315 35.7195V24.2083H17.7817V35.3435C17.7817 36.2111 17.9552 36.4714 18.8518 36.4714H19.1989V38.9299C18.9386 38.9588 18.3601 39.0166 17.7527 39.0166C15.8149 39.0166 14.8315 38.2647 14.8315 35.7195Z"
      fill="black"
      style={{ fill: 'black', fillOpacity: 1 }}
    />
    <path
      d="M19.8638 35.7195V24.2083H22.8139V35.3435C22.8139 36.2111 22.9874 36.4714 23.884 36.4714H24.2311V38.9299C23.9708 38.9588 23.3923 39.0166 22.785 39.0166C20.8471 39.0166 19.8638 38.2647 19.8638 35.7195Z"
      fill="black"
      style={{ fill: 'black', fillOpacity: 1 }}
    />
    <path
      d="M24.8096 37.802L26.3135 35.6038C27.4415 36.3269 28.8877 36.7318 30.1024 36.7318C31.3172 36.7318 32.0402 36.5872 32.0402 35.8352C32.0402 35.0832 31.6064 35.0832 30.1603 34.8808C27.4994 34.5337 25.4748 34.1288 25.4748 31.3233C25.4748 28.5178 26.7763 27.9683 30.1603 27.9683C33.5442 27.9683 33.5442 28.3153 34.6722 28.9516L33.255 31.2654C32.503 30.8605 31.3172 30.5713 30.247 30.5713C29.1769 30.5713 28.4249 30.8316 28.4249 31.3522C28.4249 31.8728 28.8009 32.1042 30.1892 32.2777C32.9079 32.6537 34.9325 32.9719 34.9325 35.6617C34.9325 38.3515 33.602 39.1324 29.9289 39.1324C26.2557 39.1324 26.2557 38.7564 24.8096 37.773"
      fill="black"
      style={{ fill: 'black', fillOpacity: 1 }}
    />
    <path
      d="M35.2798 28.2285H37.1598L37.6804 25.5966L39.9942 25.1338V28.2285H42.5394V30.7737H39.9942V35.1989C39.9942 36.0376 40.1099 36.5004 41.3825 36.5004H42.5394V38.9877C42.1055 39.0167 41.2957 39.0167 40.7751 39.0167C37.8828 39.0167 37.0441 38.0333 37.0441 35.6327V30.7737H35.2798V28.2285Z"
      fill="black"
      style={{ fill: 'black', fillOpacity: 1 }}
    />
    <path
      d="M43.2046 35.8352C43.2046 32.7983 45.3159 32.0753 50.0882 32.0753H50.522C50.522 31.0919 50.2617 30.6002 48.4107 30.6002C46.5596 30.6002 45.9522 31.063 45.3159 31.4679L43.8409 29.2698C44.5929 28.7492 46.5018 27.9683 48.7577 27.9683C52.6334 27.9683 53.4143 29.2119 53.4143 32.2488V35.5171C53.4143 36.3847 53.4721 36.5004 54.3398 36.5004V38.9299C54.1373 38.9878 53.7614 39.0167 53.0961 39.0167C51.9682 39.0167 51.4765 38.7853 51.0426 37.9466C50.4931 38.6986 49.3073 39.1324 47.2827 39.1324C43.9566 39.1324 43.2624 37.6863 43.2624 35.8063M50.4931 34.5048V34.0999H50.2328C46.4439 34.0999 45.9812 34.418 45.9812 35.5749C45.9812 36.7318 46.415 36.7607 47.7744 36.7607C49.1337 36.7607 50.4642 36.0955 50.4642 34.5048"
      fill="black"
      style={{ fill: 'black', fillOpacity: 1 }}
    />
    <path
      d="M55.1495 28.402L57.4633 28.2285L57.7526 29.4143C58.2153 28.7202 59.5168 28.1706 61.1654 28.1128V30.9761C58.6492 30.9761 58.0996 31.9884 58.0996 33.6659V38.9877H55.1206V28.402H55.1495Z"
      fill="black"
      style={{ fill: 'black', fillOpacity: 1 }}
    />
    <path
      d="M46.4151 0.838867L52.055 8.6769L61.1945 5.69787L55.5257 13.507L61.1656 21.345L52.026 18.3371L46.3572 26.1172L46.3861 16.486L37.2466 13.4781L46.3861 10.499L46.4151 0.838867Z"
      fill="#F1D300"
      style={{ fill: '#F1D300', color: 'color(display-p3 0.9451 0.8275 0.0000)', fillOpacity: 1 }}
    />
    <path
      d="M40.0257 18.8394L38.5888 22.3185C38.3959 22.7908 38.0766 23.3695 37.2251 23.3695C36.8593 23.3695 36.6331 23.2498 36.5466 23.1832L36.8194 22.5313C36.8859 22.5646 37.0189 22.6045 37.1387 22.6045C37.4048 22.6045 37.5378 22.4914 37.6442 22.2985C37.6775 22.2386 37.744 22.079 37.7773 22.0058L36.4136 18.8394H37.4048L38.2363 20.9614L39.0678 18.8394H40.0257Z"
      fill="#F1D300"
      style={{ fill: '#F1D300', color: 'color(display-p3 0.9451 0.8275 0.0000)', fillOpacity: 1 }}
    />
    <path
      d="M36.2967 20.4425C36.2967 21.274 35.7645 22.1321 34.7201 22.1321C34.015 22.1321 33.689 21.713 33.6557 21.6665L33.6291 22.039H32.771V17.5288H33.7023V19.1985C33.7489 19.1453 34.0948 18.7196 34.8132 18.7196C35.4784 18.7196 36.2967 19.1985 36.2967 20.4425ZM35.3387 20.4425C35.3387 19.877 35.0327 19.4713 34.5139 19.4713C34.015 19.4713 33.6823 19.8837 33.6823 20.4425C33.6823 20.9946 34.0083 21.3937 34.5139 21.3937C35.0261 21.3937 35.3387 20.988 35.3387 20.4425Z"
      fill="#F1D300"
      style={{ fill: '#F1D300', color: 'color(display-p3 0.9451 0.8275 0.0000)', fillOpacity: 1 }}
    />
    <path
      d="M30.2386 22.039H29.3738L29.3538 21.6332C29.3272 21.6731 28.9813 22.1321 28.2096 22.1321C27.5577 22.1321 26.7129 21.713 26.7129 20.4159C26.7129 19.5976 27.2318 18.7196 28.2828 18.7196C28.9214 18.7196 29.2341 19.0788 29.3073 19.1653V17.5288H30.2386V22.039ZM29.3272 20.4225C29.3272 19.8637 29.0012 19.4713 28.4957 19.4713C27.9768 19.4713 27.6708 19.8903 27.6708 20.4225C27.6708 21.0079 27.9835 21.3937 28.489 21.3937C28.9879 21.3937 29.3272 21.0013 29.3272 20.4225Z"
      fill="#F1D300"
      style={{ fill: '#F1D300', color: 'color(display-p3 0.9451 0.8275 0.0000)', fillOpacity: 1 }}
    />
    <path
      d="M26.2836 20.7151H23.942C23.942 21.0012 24.1416 21.4269 24.7602 21.4269C25.3057 21.4269 25.6183 21.1076 25.6915 21.0345L26.1904 21.5134C25.8844 21.8593 25.4188 22.1387 24.7269 22.1387C23.5029 22.1387 22.9907 21.3138 22.9907 20.4424C22.9907 19.5976 23.5162 18.7195 24.707 18.7195C24.9997 18.7195 25.4387 18.7727 25.798 19.1319C26.237 19.571 26.2836 20.1231 26.2836 20.7151ZM25.3789 20.0898C25.3789 19.9435 25.2791 19.398 24.6737 19.398C24.0817 19.398 23.9486 19.9568 23.9486 20.0898H25.3789Z"
      fill="#F1D300"
      style={{ fill: '#F1D300', color: 'color(display-p3 0.9451 0.8275 0.0000)', fillOpacity: 1 }}
    />
    <path
      d="M22.8698 19.6242C22.8166 19.6175 22.7434 19.6109 22.6503 19.6109C22.4307 19.6109 22.158 19.6574 21.9651 19.8237C21.6524 20.0965 21.6325 20.4224 21.6325 20.7484V22.0389H20.7012V18.8392H21.5793L21.5926 19.3914C21.8187 19.0122 22.291 18.7195 22.7434 18.7195C22.7833 18.7195 22.8299 18.7195 22.8698 18.7261V19.6242Z"
      fill="#F1D300"
      style={{ fill: '#F1D300', color: 'color(display-p3 0.9451 0.8275 0.0000)', fillOpacity: 1 }}
    />
    <path
      d="M20.1117 20.7151H17.7701C17.7701 21.0012 17.9697 21.4269 18.5883 21.4269C19.1338 21.4269 19.4465 21.1076 19.5196 21.0345L20.0186 21.5134C19.7126 21.8593 19.2469 22.1387 18.5551 22.1387C17.3311 22.1387 16.8188 21.3138 16.8188 20.4424C16.8188 19.5976 17.3444 18.7195 18.5351 18.7195C18.8278 18.7195 19.2669 18.7727 19.6261 19.1319C20.0651 19.571 20.1117 20.1231 20.1117 20.7151ZM19.207 20.0898C19.207 19.9435 19.1072 19.398 18.5019 19.398C17.9098 19.398 17.7768 19.9568 17.7768 20.0898H19.207Z"
      fill="#F1D300"
      style={{ fill: '#F1D300', color: 'color(display-p3 0.9451 0.8275 0.0000)', fillOpacity: 1 }}
    />
    <path
      d="M16.5189 18.8394L15.4279 22.0391H14.5232L13.8381 20.0234L13.1529 22.0391H12.2548L11.1572 18.8394H12.1284L12.7671 20.8882L13.4722 18.8394H14.2172L14.9357 20.8882L15.5876 18.8394H16.5189Z"
      fill="#F1D300"
      style={{ fill: '#F1D300', color: 'color(display-p3 0.9451 0.8275 0.0000)', fillOpacity: 1 }}
    />
    <path
      d="M10.8679 20.4291C10.8679 21.2407 10.3424 22.1387 9.07851 22.1387C7.81459 22.1387 7.28906 21.2407 7.28906 20.4291C7.28906 19.6242 7.81459 18.7195 9.07851 18.7195C10.3424 18.7195 10.8679 19.6242 10.8679 20.4291ZM9.91003 20.4224C9.91003 19.8703 9.62399 19.4645 9.07851 19.4645C8.81907 19.4645 8.61285 19.5577 8.47981 19.7173C8.33346 19.8903 8.24698 20.1364 8.24698 20.4224C8.24698 20.7151 8.33346 20.9613 8.48646 21.1342C8.62616 21.2939 8.81907 21.387 9.07851 21.387C9.33794 21.387 9.53751 21.2939 9.6772 21.1342C9.8302 20.9613 9.91003 20.7151 9.91003 20.4224Z"
      fill="#F1D300"
      style={{ fill: '#F1D300', color: 'color(display-p3 0.9451 0.8275 0.0000)', fillOpacity: 1 }}
    />
    <path
      d="M7.06869 18.9524C7.06869 19.3449 6.94895 19.6775 6.71612 19.917C6.36355 20.2895 5.81142 20.4292 5.18611 20.4292H4.38785V22.039H3.40332V17.5288H4.96659C5.61185 17.5288 6.13072 17.5421 6.53651 17.8614C6.91568 18.1541 7.06869 18.5666 7.06869 18.9524ZM6.08416 18.9657C6.08416 18.367 5.51207 18.3204 5.0065 18.3204H4.38785V19.6243H5.08633C5.63181 19.6243 6.08416 19.5112 6.08416 18.9657Z"
      fill="#F1D300"
      style={{ fill: '#F1D300', color: 'color(display-p3 0.9451 0.8275 0.0000)', fillOpacity: 1 }}
    />
  </svg>
);

export default AllstarIcon;
