import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import Authorize from 'components/Providers/Authorize';
import PageWrapper from 'components/Shared/Page/Wrapper';
import PageTitle from 'components/Shared/Page/Title';
import withPusher from 'components/Providers/Pusher/withPusher';
import PageBanners from 'components/Layout/PageBanners';
import useTranslation from 'utils/hooks/useTranslation';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import useAmplitude from '../../utils/hooks/useAmplitude';
import AuditLogsEmptyState from './AuditLogsEmptyState';
import tableColumns from './tableColumns';
import AuditLogsTable from './AuditLogsTable';
import useAuditLogsPage from './useAuditLogsPage';
import TableControl from './TableControl';
import AuditDetailsModal from './AuditDetailsModal/AuditDetailsModal';

const AuditLogs = ({ history }) => {
  const { sendAmplitudeEvent } = useAmplitude();
  const { translateText } = useTranslation();

  const {
    auditLogId,
    auditLogs,
    auditLogChanges,
    tableFilters,
    isAuditLogsLoading,
    isAuditLogChangesLoading,
    totalCount,
    isPageLoading,
    isTableVisible,
    currentPage,
    onTableChange,
    setAuditLogId,
    onTableControlChange,
  } = useAuditLogsPage();

  useEffect(() => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.auditLogsPage);
  }, [sendAmplitudeEvent]);

  return (
    <>
      {
        <Authorize>
          <Helmet title="Audit Logs" />
          <PageBanners />
          <PageWrapper history={history} loading={isPageLoading}>
            <PageTitle title={translateText('corporateAccounts.menuLeft.auditLogs')} />
            <AuditLogsEmptyState
              visible={!isAuditLogsLoading && !isPageLoading && !isTableVisible}
            />
            <TableControl
              visible={!isPageLoading && isTableVisible}
              onTableControlChange={onTableControlChange}
            />
            <AuditLogsTable
              columns={tableColumns({
                tableFilters,
                setAuditLogId,
                translateText,
              })}
              currentPage={currentPage}
              dataSource={auditLogs}
              loading={isAuditLogsLoading}
              totalCount={totalCount}
              visible={!isPageLoading && isTableVisible}
              onTableChange={onTableChange}
              onPageChange={() => {}}
              onPageSizeChange={() => {}}
            />
            <AuditDetailsModal
              visible={auditLogId}
              loading={isAuditLogChangesLoading}
              onClose={() => setAuditLogId(false)}
              data={auditLogChanges}
            />
          </PageWrapper>
        </Authorize>
      }
    </>
  );
};

export default withPusher(injectIntl(AuditLogs));
