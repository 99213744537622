import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import helpUrls from 'constants/helpCentre';

const useGetFaqLink = () => {
  const [faqLink, setFaqLink] = useState(null);

  const currentCorporation = useSelector(state => state.user.currentCorporation);

  useEffect(() => {
    const helpUrl = helpUrls[currentCorporation?.country];
    setFaqLink(helpUrl?.HELP_CENTRE_URL || helpUrls.EN.HELP_CENTRE_URL);
  }, [currentCorporation?.country]);

  return faqLink;
};

export default useGetFaqLink;
