import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { useGetCreditAccountDetails } from 'services/reactQueryHooks/creditAccounts';
import { flattenCreditAccountDetails, formatCreditAccountDetails } from './helper';

const useCreditAccountPage = () => {
  const corporateClientId = useSelector(state => state.user.corporateClientId);

  const {
    data: creditAccountDetails,
    isLoading: isCreditAccountLoading,
    isSuccess: isCreditAccountLoadingSuccessful,
    isError: isCreditAccountLoadingError,
    error: creditAccountLoadingServerErrors,
  } = useGetCreditAccountDetails({
    corporateClientId,
  });

  const formattedCreditAccountDetails = useMemo(() => {
    return formatCreditAccountDetails(flattenCreditAccountDetails(creditAccountDetails));
  }, [creditAccountDetails]);

  return {
    creditAccountDetails: formattedCreditAccountDetails,
    isCreditAccountLoading,
    isCreditAccountLoadingSuccessful,
    isCreditAccountLoadingError,
    creditAccountLoadingServerErrors,
  };
};

export default useCreditAccountPage;
