import React, { useState, useEffect } from 'react';
import { Menu, Dropdown, Typography } from 'antd';
import Icon, { QuestionCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { HelpIcon, ContactIcon } from 'components/Shared/Icons';
import helpUrls from 'constants/helpCentre';
import useAmplitude from 'utils/hooks/useAmplitude';
import useTranslation from 'utils/hooks/useTranslation';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import getHelpSupportUrls from './helper';
import styles from '../style.module.scss';

const HelpMenu = () => {
  const { sendAmplitudeEvent } = useAmplitude();
  const { translateText } = useTranslation();
  const currentCorporation = useSelector(state => state.user.currentCorporation);

  const [supportUrl, setSupportUrl] = useState(helpUrls.EN.SUPPORT_URL);
  const [helpCentreUrl, setHelpCentreUrl] = useState(helpUrls.EN.HELP_CENTRE_URL);

  useEffect(() => {
    if (currentCorporation) {
      const { country } = currentCorporation;
      const { support, helpCentre } = getHelpSupportUrls(country);
      setSupportUrl(support || helpUrls.EN.SUPPORT_URL);
      setHelpCentreUrl(helpCentre || helpUrls.EN.HELP_CENTRE_URL);
    }
  }, [currentCorporation]);

  const menuItemLookup = {
    contact: {
      icon: ContactIcon,
      url: supportUrl,
      amplitudeEvent: AMPLITUDE_EVENT_TYPES.support,
    },
    help: {
      icon: HelpIcon,
      url: helpCentreUrl,
      amplitudeEvent: AMPLITUDE_EVENT_TYPES.helpCentre,
    },
  };

  const dropdownItems = Object.keys(menuItemLookup).map(menuKey => {
    if (currentCorporation?.country === 'DE' && menuKey === 'help') return null;
    const config = menuItemLookup[menuKey];

    return {
      key: menuKey,
      icon: <Icon component={config.icon} />,
      label: (
        <a
          href={config.url}
          target="_blank"
          rel="noopener noreferrer"
          data-testid={`${menuKey}-href`}
        >
          <Typography.Text>
            {translateText(`corporateAccounts.topBar.profileMenu.${menuKey}`)}
          </Typography.Text>
        </a>
      ),
      onClick: () => sendAmplitudeEvent(config.amplitudeEvent),
    };
  });

  const menu = <Menu selectable={false} items={dropdownItems} />;

  return (
    <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
      <div className={styles.dropdown}>
        <div className={styles.helpIcon}>
          <QuestionCircleOutlined style={{ fontSize: '20px' }} data-testid="helpMenu" />
          {translateText('corporateAccounts.topBar.profileMenu.support')}
        </div>
      </div>
    </Dropdown>
  );
};

export default HelpMenu;
