import React, { useEffect, useRef } from 'react';
import { Button, Typography } from 'antd';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import { ONBOARDING_VIDEO_TOPICS } from 'constants/onboarding';
import useTranslation from 'utils/hooks/useTranslation';
import useYouTubePlayer from 'utils/hooks/useYouTubePlayer.js';
import useGetFaqLink from 'utils/hooks/useGetFaqLink.js';
import useGetOnboardingVideoUrl from 'utils/hooks/useGetOnboardingVideoUrl.js';
import useAmplitude from 'utils/hooks/useAmplitude';
import ExtendedModal from 'components/Shared/Modal/index.js';

import styles from './style.module.scss';

const WelcomeModal = ({ isVisible, setIsVisible }) => {
  const { Title, Text } = Typography;
  const iframeRef = useRef(null);
  const { sendAmplitudeEvent } = useAmplitude();
  const { translateText } = useTranslation();
  const videoUrl = useGetOnboardingVideoUrl({ topic: ONBOARDING_VIDEO_TOPICS.ONBOARDING });
  const welcomeVideo = useYouTubePlayer({ title: 'welcomeVideo', videoUrl, iframeRef });
  const faqLink = useGetFaqLink();

  const closeModal = () => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.selfRegistrationWelcomeModalCloseButton);
    setIsVisible(false);
  };

  const openFaqLink = () => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.selfRegistrationFaqLink);
  };

  useEffect(() => {
    if (!isVisible && iframeRef.current) {
      const players = document.getElementsByClassName('player');
      for (const player of players) {
        const video = player.contentWindow;
        video.postMessage(JSON.stringify({ event: 'command', func: 'pauseVideo' }), '*');
      }
    }
  }, [isVisible]);

  return (
    <ExtendedModal
      visible={isVisible}
      closable={true}
      footer={null}
      width={580}
      title={null}
      onCancel={closeModal}
    >
      <div className={styles.welcomeModalContainer} data-testid={'welcomeModal'}>
        <Title level={3} className={styles.welcomModalTitle}>
          {translateText('corporateAccounts.onboarding.welcomeModal.title')}
        </Title>
        <Text className={styles.welcomeModalDescription}>
          {translateText('corporateAccounts.onboarding.welcomeModal.description')}
        </Text>
        <div className={styles.video}>{welcomeVideo}</div>
        <Text onClick={openFaqLink} className={`${styles.buttonAsLink}`}>
          <a href={faqLink} target="_blank" rel="noreferrer">
            {translateText('corporateAccounts.onboarding.welcomeModal.frequentlyAskedQuestions')}
          </a>
        </Text>
        <Button
          className={styles.welcomeModalButton}
          style={{ border: 'none', outline: 'none !important', height: '40px' }}
          type="primary"
          onClick={closeModal}
        >
          {translateText('corporateAccounts.onboarding.welcomeModal.actions.getStarted')}
        </Button>
      </div>
    </ExtendedModal>
  );
};

export default WelcomeModal;
