const pbpCustomerSuccessTierTwo = {
  all: [
    'activate',
    'create',
    'deactivate',
    'destroy',
    'edit',
    'edit_corporate_client_name',
    'reactivate',
    'read',
    'update',
    'upload',
    'view',
    'export',
    'invite',
    'bulkDriverUpload',
  ],
  corporation: {
    cannot: ['set_test'],
  },
  driver: {
    cannot: ['read_all_columns'],
  },
  vehicleForm: ['read_vehicle_location', 'read_vehicle_name', 'read_vehicle_type'],
  paymentSpa: {
    cannot: ['create', 'edit', 'destroy'],
  },
};

export default pbpCustomerSuccessTierTwo;
