import React, { useCallback, useEffect, useState } from 'react';
import { Form, Input, Col, Row, Button } from 'antd';
import { useDispatch } from 'react-redux';
import corporationActions from 'redux/corporations/actions';
import useTranslation from 'utils/hooks/useTranslation';
import isEmpty from 'lodash.isempty';
import PhoneInput from 'components/Shared/PhoneInput';
import isValidPhoneNumber from 'utils/phoneUtils';
import notify from 'utils/notificationUtils';
import { CORP_EDIT_FIELDS } from 'constants/corporateClients';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 20 },
};

const PrimaryUserEditForm = ({ corporation, formErrors, formItemStyle, onSave, onCancel }) => {
  const { translateText } = useTranslation();

  const dispatch = useDispatch();

  const [formData, setFormData] = useState(corporation);
  const [form] = Form.useForm();
  const { setFieldsValue, setFields, getFieldValue, validateFields } = form;

  const onInputChange = (fieldName, value) => {
    if (fieldName === 'primaryFleetManager.phoneNumber') {
      setFieldsValue({ primaryFleetManager: { phoneNumber: value } });
    } else {
      setFieldsValue({ [fieldName]: value });
    }
  };

  const handleFormSubmit = useCallback(() => {
    validateFields(CORP_EDIT_FIELDS.primaryUser)
      .then(values => {
        const updatedFields = { ...formData, ...values };
        if (!updatedFields.invoiceId) {
          notify({
            type: 'error',
            message: translateText('corporateAccounts.corporateClient.salesforceId.error.presence'),
          });

          return;
        }
        dispatch({ type: corporationActions.PUT, payload: updatedFields });
        onSave();
      })
      .catch(() => {});
  }, [formData, dispatch, validateFields, onSave, translateText]);

  const handleFormCancel = useCallback(() => {
    dispatch({ type: corporationActions.RESET_EDIT_CORPORATION });
    onCancel();
  }, [dispatch, onCancel]);

  useEffect(() => {
    if (formData && formData.id) setFieldsValue(formData);
  }, [formData, setFieldsValue]);

  useEffect(() => {
    if (!isEmpty(formErrors)) {
      Object.keys(formErrors).forEach(key => {
        const fieldValue = getFieldValue(key);
        if (key) {
          formErrors[key].value = fieldValue;
        } else {
          // Field might not exist, show notification with error
          notify({
            type: 'error',
            title: translateText('corporateAccounts.common.error'),
            message: formErrors[key].errors.join(', '),
          });
          delete formErrors[key];
        }
      });
      setFields(formErrors);
    }
  }, [formErrors, getFieldValue, setFields, translateText]);

  useEffect(() => {
    setFormData(corporation);
  }, [corporation, setFormData]);

  return (
    <Form
      form={form}
      layout="horizontal"
      colon={false}
      {...layout}
      data-testid="primaryUserEditForm"
    >
      <Row gutter={[16, 48]}>
        <Col xs={24} sm={8}>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>

          <Form.Item
            name={['primaryFleetManager', 'firstName']}
            style={formItemStyle}
            label={translateText('corporateAccounts.admins.firstName')}
            labelAlign="left"
            rules={[
              {
                required: true,
                message: translateText(
                  'corporateAccounts.corporateClient.owner.firstName.error.presence',
                ),
              },
              {
                max: 50,
                message: translateText('corporateAccounts.drivers.form.firstNameValidation'),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            name={['primaryFleetManager', 'lastName']}
            style={formItemStyle}
            label={translateText('corporateAccounts.admins.lastName')}
            labelAlign="left"
            rules={[
              {
                required: true,
                message: translateText('corporateAccounts.admins.form.lastNameRequired'),
              },
              {
                max: 50,
                message: translateText('corporateAccounts.drivers.form.lastNameValidation'),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            name={['primaryFleetManager', 'phoneNumber']}
            validateTrigger="onBlur"
            style={formItemStyle}
            label={translateText('corporateAccounts.admins.phoneNumber')}
            labelAlign="left"
            rules={[
              {
                required: true,
                message: translateText('corporateAccounts.drivers.form.phoneRequired'),
              },
              {
                validator: (rule, value) =>
                  isValidPhoneNumber(getFieldValue(['primaryFleetManager', 'phoneNumber'])),
                message: translateText('corporateAccounts.drivers.form.phoneValidation'),
              },
            ]}
          >
            <PhoneInput
              code={formData && formData.country}
              fieldValue="primaryFleetManager.phoneNumber"
              onInputChange={onInputChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 48]}>
        <Col xs={24} sm={8}>
          <Form.Item
            name={['primaryFleetManager', 'email']}
            label={translateText('corporateAccounts.corporateClient.email')}
            labelAlign="left"
            rules={[
              {
                required: true,
                message: translateText('corporateAccounts.drivers.form.emailValidation'),
              },
              {
                type: 'email',
                message: translateText('corporateAccounts.drivers.form.emailValidation'),
              },
            ]}
          >
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ float: 'right' }}>
        <Col xs={24}>
          <Button
            data-testid="cancelButton"
            onClick={handleFormCancel}
            type="default"
            loading={false}
            style={{
              width: '130px',
              marginRight: 10,
            }}
          >
            {translateText('corporateAccounts.actions.cancel')}
          </Button>
          <Button
            data-testid="primaryUserSaveButton"
            onClick={handleFormSubmit}
            type="primary"
            loading={false}
            style={{
              width: '130px',
              marginRight: 10,
            }}
          >
            {translateText('corporateAccounts.actions.save')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default PrimaryUserEditForm;
