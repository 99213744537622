import React from 'react';

const LogoutIcon = () => (
  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.66634 1.66666C8.03453 1.66666 8.33301 1.96514 8.33301 2.33333V2.99999C8.33301 3.36818 8.63148 3.66666 8.99967 3.66666C9.36786 3.66666 9.66634 3.36818 9.66634 2.99999V2.33333C9.66634 1.22876 8.77091 0.333328 7.66634 0.333328H2.99968C1.52692 0.333328 0.333008 1.52724 0.333008 2.99999V11C0.333008 12.4728 1.52691 13.6667 2.99967 13.6667H7.66634C8.77091 13.6667 9.66634 12.7712 9.66634 11.6667V11C9.66634 10.6318 9.36786 10.3333 8.99967 10.3333C8.63148 10.3333 8.33301 10.6318 8.33301 11V11.6667C8.33301 12.0349 8.03453 12.3333 7.66634 12.3333H2.99967C2.26329 12.3333 1.66634 11.7364 1.66634 11L1.66634 2.99999C1.66634 2.26362 2.2633 1.66666 2.99968 1.66666L7.66634 1.66666ZM2.99967 7C2.99967 6.63181 3.29815 6.33333 3.66634 6.33333L10.7235 6.33333L9.8616 5.4714C9.60125 5.21105 9.60125 4.78894 9.8616 4.52859C10.122 4.26824 10.5441 4.26824 10.8044 4.52859L12.8034 6.52762L12.8116 6.53593C12.9276 6.65551 12.9991 6.8184 12.9997 6.998L12.9997 6.99999L12.9997 7.002C12.9994 7.09132 12.9816 7.17652 12.9495 7.2543C12.9169 7.33326 12.8686 7.40724 12.8044 7.4714L10.8044 9.4714C10.5441 9.73175 10.122 9.73175 9.8616 9.4714C9.60125 9.21105 9.60125 8.78894 9.8616 8.52859L10.7235 7.66666L3.66634 7.66666C3.29815 7.66666 2.99967 7.36819 2.99967 7Z"
      fill="#4D4D4D"
    />
  </svg>
);

export default LogoutIcon;
