import { Button } from 'antd';
import useTranslation from 'utils/hooks/useTranslation';
import useAmplitude from 'utils/hooks/useAmplitude';
import { ROUTE_AUTH_REDIRECT } from 'constants.js';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import styles from './style.module.scss';

const SignInButton = () => {
  const { translateText } = useTranslation();
  const { sendAmplitudeEvent } = useAmplitude();

  const signIn = () => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.selfRegistrationSignInButton);
    window.location.href = ROUTE_AUTH_REDIRECT;
  };

  return (
    <span className={styles.signInButtonWrapper}>
      <Button onClick={() => signIn()}>
        {translateText('corporateAccounts.selfRegistration.actions.signIn')}
      </Button>
    </span>
  );
};

export default SignInButton;
