import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Table } from 'antd';
import useTranslation from 'utils/hooks/useTranslation';
import Authorize from 'components/Providers/Authorize';
import PageTitle from 'components/Shared/Page/Title';
import PageTable from 'components/Shared/Page/Table';
import PageWrapper from 'components/Shared/Page/Wrapper';
import MonthlyStatementActionRow from './MonthlyStatementActionRow';
import useMonthlyStatementPage from './useMonthlyStatementPage';

const MonthlyStatements = ({ history }) => {
  const { translateText } = useTranslation();
  const {
    statements,
    loading,
    columns,
    filterYear,
    availableYears,
    onFilterYearChange,
  } = useMonthlyStatementPage();

  return (
    <Authorize>
      <Helmet title="Monthly Statements" />
      <PageWrapper history={history}>
        <PageTitle
          title={translateText('corporateAccounts.menuLeft.monthlyStatements')}
          numRecords={statements.length}
        />
        <MonthlyStatementActionRow
          filterYear={filterYear}
          filterYearOptions={availableYears}
          onFilterYearChange={onFilterYearChange}
        />
        <PageTable
          visible
          table={
            <Table
              columns={columns}
              dataSource={statements}
              loading={loading}
              rowKey="id"
              scroll={{ x: 'max-content', y: 500 }}
              pagination={false}
            />
          }
        />
      </PageWrapper>
    </Authorize>
  );
};

export default React.memo(withRouter(MonthlyStatements));
