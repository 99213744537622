import { ROUTES } from 'constants.js';
import {
  MenuLeftDriversIcon,
  MenuLeftPaymentMethodsIcon,
  MenuLeftUsersIcon,
  MenuLeftVehiclesIcon,
} from 'components/Shared/Icons';
import { AMPLITUDE_EVENT_TYPES } from './amplitude';

export const ONBOARDING_STATUS = {
  activation: 'activation',
  registration: 'registration',
};

export default {
  ONBOARDING_STATUS,
};

export const DRIVER_ONBOARDING_INFO = {
  InvitationCode: 'invitationCode',
  Password: 'password',
};

export const SOCIAL_AUTH_INFO = {
  SubjectIssuer: 'subjectIssuer',
  SubjectTokenType: 'subjectTokenType',
  SubjectToken: 'subjectToken',
};

export const FACEBOOK_SOCIAL_AUTH = {
  Issuer: 'Facebook',
  SubjectTokenType: 'AccessToken',
};

export const APPLE_SOCIAL_AUTH = {
  Issuer: 'Apple',
  SubjectTokenType: 'IdToken',
};

export const onboardingSteps = {
  accountSetup: {
    title: 'corporateAccounts.onboarding.setupAccountStep.title',
    description: 'corporateAccounts.onboarding.setupAccountStep.description',
    statusName: 'isAccountSetupComplete',
    url: '',
    icon: MenuLeftUsersIcon,
  },
  driverSetup: {
    title: 'corporateAccounts.onboarding.addDriverStep.title',
    description: 'corporateAccounts.onboarding.addDriverStep.description',
    statusName: 'isDriverSetupComplete',
    url: ROUTES.DRIVERS,
    amplitudeEvent: AMPLITUDE_EVENT_TYPES.selfRegistrationOnboardingDriversLink,
    icon: MenuLeftDriversIcon,
  },
  vehicleSetup: {
    title: 'corporateAccounts.onboarding.addVehicleStep.title',
    description: 'corporateAccounts.onboarding.addVehicleStep.description',
    statusName: 'isVehicleSetupComplete',
    url: ROUTES.VEHICLES,
    amplitudeEvent: AMPLITUDE_EVENT_TYPES.selfRegistrationOnboardingVehiclesLink,
    icon: MenuLeftVehiclesIcon,
  },
  paymentSetup: {
    title: 'corporateAccounts.onboarding.setupPaymentStep.title',
    description: 'corporateAccounts.onboarding.setupPaymentStep.description',
    statusName: 'isPaymentSetupComplete',
    url: ROUTES.PAYMENTS,
    amplitudeEvent: AMPLITUDE_EVENT_TYPES.selfRegistrationOnboardingPaymentsLink,
    icon: MenuLeftPaymentMethodsIcon,
  },
};

export const ONBOARDING_VIDEO_TOPICS = {
  ONBOARDING: 'onboarding',
  HOW_TO_PAYMENTS: 'howToPayments',
  HOW_TO_VEHICLES: 'howToVehicles',
  HOW_TO_DRIVERS: 'howToDrivers',
};

export const onboardingVideoUrls = {
  [ONBOARDING_VIDEO_TOPICS.ONBOARDING]: {
    'en-US': 'https://www.youtube.com/embed/jlirJ7CFxZY?si=V1QejtQpOjiCMxCF',
    'fr-FR': 'https://www.youtube.com/embed/fjNsSj_FFSc?si=jStG4A-bWcH0X-OK',
    'de-DE': 'https://www.youtube.com/embed/jlirJ7CFxZY?si=KUK-6azIgtUTsI5K',
  },
  [ONBOARDING_VIDEO_TOPICS.HOW_TO_PAYMENTS]: {
    'en-US': 'https://player.vimeo.com/video/161801298?h=9c8f9f79d4&title=0&byline=0&portrait=0',
    'fr-FR': 'https://player.vimeo.com/video/213027972?h=5718ad5ae9&title=0&byline=0&portrait=0',
    'de-DE': 'https://player.vimeo.com/video/213027972?h=17232256db&title=0&byline=0&portrait=0',
  },
  [ONBOARDING_VIDEO_TOPICS.HOW_TO_VEHICLES]: {
    'en-US': 'https://player.vimeo.com/video/334963376?h=9c8f9f79d4&title=0&byline=0&portrait=0',
    'fr-FR': 'https://player.vimeo.com/video/224545290?h=5718ad5ae9&title=0&byline=0&portrait=0',
    'de-DE': 'https://player.vimeo.com/video/224545290?h=17232256db&title=0&byline=0&portrait=0',
  },
  [ONBOARDING_VIDEO_TOPICS.HOW_TO_DRIVERS]: {
    'en-US': 'https://player.vimeo.com/video/244199235?h=9c8f9f79d4&title=0&byline=0&portrait=0',
    'fr-FR': 'https://player.vimeo.com/video/240838087?h=5718ad5ae9&title=0&byline=0&portrait=0',
    'de-DE': 'https://player.vimeo.com/video/240838087?h=17232256db&title=0&byline=0&portrait=0',
  },
};
