export const CORP_CREATE_STEPS = {
  PROFILE: {
    TABKEY: 'profile',
    STEPINDEX: 0,
  },
  INFO: {
    TABKEY: 'info',
    STEPINDEX: 1,
  },
};

export const CORP_CREATE_FIELDS = {
  profile: [
    'id',
    ['primaryFleetManager', 'email'],
    ['primaryFleetManager', 'firstName'],
    ['primaryFleetManager', 'lastName'],
    ['primaryFleetManager', 'phoneNumber'],
  ],
  info: [
    'id',
    ['address', 'city'],
    ['address', 'companyAddress1'],
    ['address', 'companyAddress2'],
    ['address', 'postalCode'],
    'corporationNumber',
    'region',
    'taxNumber',
    'invoiceId',
    'country',
    'preferredLanguage',
    'corporateClientName',
  ],
};

export const CORP_EDIT_FIELDS = {
  primaryUser: [
    ['primaryFleetManager', 'firstName'],
    ['primaryFleetManager', 'lastName'],
    ['primaryFleetManager', 'phoneNumber'],
    ['primaryFleetManager', 'email'],
  ],
  corporation: [
    'corporateClientName',
    'corporationNumber',
    'invoiceId',
    'isTestCorporation',
    'taxNumber',
    ['address', 'companyAddress1'],
    ['address', 'companyAddress2'],
    ['address', 'city'],
    'region',
    ['address', 'postalCode'],
    'country',
    'preferredLanguage',
  ],
};

// Use this variable to represent the status of corporate clients as strings, in line with the backend's move away from integer enums.
export const CORPORATE_CLIENT_STATUS_STRING = {
  ACTIVE: 'active',
  DELETED: 'deleted',
  DEACTIVATED: 'deactivated',
  PROSPECT: 'prospect',
};

export const CORPORATE_CLIENT_STATUS_STRING_TAGS = {
  [CORPORATE_CLIENT_STATUS_STRING.ACTIVE]: {
    key: 'active',
    value: CORPORATE_CLIENT_STATUS_STRING.ACTIVE,
  },
  [CORPORATE_CLIENT_STATUS_STRING.DELETED]: {
    key: 'deleted',
    value: CORPORATE_CLIENT_STATUS_STRING.DELETED,
  },
  [CORPORATE_CLIENT_STATUS_STRING.DEACTIVATED]: {
    key: 'deactivated',
    value: CORPORATE_CLIENT_STATUS_STRING.DEACTIVATED,
  },
  [CORPORATE_CLIENT_STATUS_STRING.PROSPECT]: {
    key: 'prospect',
    value: CORPORATE_CLIENT_STATUS_STRING.PROSPECT,
  },
};

export const CORPORATE_CLIENT_STATUS = {
  ACTIVE: 1,
  DELETED: 2,
  DEACTIVATED: 3,
  PROSPECT: 4,
};

export const CORPORATE_CLIENT_STATUS_TAGS = {
  [CORPORATE_CLIENT_STATUS.ACTIVE]: {
    key: 'active',
    value: CORPORATE_CLIENT_STATUS.ACTIVE,
  },
  [CORPORATE_CLIENT_STATUS.DELETED]: {
    key: 'deleted',
    value: CORPORATE_CLIENT_STATUS.DELETED,
  },
  [CORPORATE_CLIENT_STATUS.DEACTIVATED]: {
    key: 'deactivated',
    value: CORPORATE_CLIENT_STATUS.DEACTIVATED,
  },
  [CORPORATE_CLIENT_STATUS.PROSPECT]: {
    key: 'prospect',
    value: CORPORATE_CLIENT_STATUS.PROSPECT,
  },
};

export const TEST_CORPORATION_STATUS = {
  TEST: true,
  STANDARD: false,
};

export const TEST_CORPORATION_STATUS_TAGS = {
  [TEST_CORPORATION_STATUS.TEST]: {
    value: TEST_CORPORATION_STATUS.TEST,
    key: 'corporateAccounts.corporateClient.accountType.test',
    backgroundType: 'inactive',
  },
  [TEST_CORPORATION_STATUS.STANDARD]: {
    value: TEST_CORPORATION_STATUS.STANDARD,
    key: 'corporateAccounts.corporateClient.accountType.standard',
    backgroundType: 'active',
  },
};

export const CORPORATE_CLIENT_COUNTRIES = {
  US: 'US',
  CA: 'CA',
  FR: 'FR',
  GB: 'GB',
  DE: 'DE',
  CH: 'CH',
};

export const CORPORATE_CLIENT_COUNTRIES_WITH_GLB = {
  ...CORPORATE_CLIENT_COUNTRIES,
  glb: 'glb',
};

export const CORPORATE_CLIENT_COUNTRY_LOCALE = {
  [CORPORATE_CLIENT_COUNTRIES_WITH_GLB.CA]: 'en-US',
  [CORPORATE_CLIENT_COUNTRIES_WITH_GLB.US]: 'en-US',
  [CORPORATE_CLIENT_COUNTRIES_WITH_GLB.GB]: 'en-GB',
  [CORPORATE_CLIENT_COUNTRIES_WITH_GLB.FR]: 'fr-FR',
  [CORPORATE_CLIENT_COUNTRIES_WITH_GLB.DE]: 'de-DE',
  [CORPORATE_CLIENT_COUNTRIES_WITH_GLB.CH]: 'de-DE',
  glb: 'glb-glb',
  default: 'en-US',
};

export const CORPORATE_CLIENT_COUNTRY_CSV_DELIMITOR = {
  [CORPORATE_CLIENT_COUNTRIES_WITH_GLB.CA]: ',',
  [CORPORATE_CLIENT_COUNTRIES_WITH_GLB.US]: ',',
  [CORPORATE_CLIENT_COUNTRIES_WITH_GLB.GB]: ',',
  [CORPORATE_CLIENT_COUNTRIES_WITH_GLB.FR]: ';',
  [CORPORATE_CLIENT_COUNTRIES_WITH_GLB.DE]: ';',
  [CORPORATE_CLIENT_COUNTRIES_WITH_GLB.CH]: ';',
  default: ',',
};

export const CORPORATE_CLIENT_INDUSTRY_OPTIONS = [
  { value: 'Car Rental', label: 'corporateAccounts.corporateClient.industryOption.carRental' },
  { value: 'Cleaning', label: 'corporateAccounts.corporateClient.industryOption.cleaning' },
  { value: 'Construction', label: 'corporateAccounts.corporateClient.industryOption.construction' },
  { value: 'Delivery', label: 'corporateAccounts.corporateClient.industryOption.delivery' },
  {
    value: 'Electric Vehicles',
    label: 'corporateAccounts.corporateClient.industryOption.electricVehicles',
  },
  {
    value: 'Food & Service',
    label: 'corporateAccounts.corporateClient.industryOption.foodService',
  },
  { value: 'Government', label: 'corporateAccounts.corporateClient.industryOption.government' },
  {
    value: 'Higher Education',
    label: 'corporateAccounts.corporateClient.industryOption.higherEducation',
  },
  { value: 'Hospitals', label: 'corporateAccounts.corporateClient.industryOption.hospitals' },
  { value: 'Hotel / Chain', label: 'corporateAccounts.corporateClient.industryOption.hotelChain' },
  { value: 'Insurance', label: 'corporateAccounts.corporateClient.industryOption.insurance' },
  {
    value: 'Mobile health',
    label: 'corporateAccounts.corporateClient.industryOption.mobileHealth',
  },
  {
    value: 'Mobile Sales Team',
    label: 'corporateAccounts.corporateClient.industryOption.mobileSalesTeam',
  },
  { value: 'Municipal', label: 'corporateAccounts.corporateClient.industryOption.municipal' },
  {
    value: 'Office parking',
    label: 'corporateAccounts.corporateClient.industryOption.officeParking',
  },
  { value: 'Other', label: 'corporateAccounts.corporateClient.industryOption.other' },
  {
    value: 'Passenger Terminal',
    label: 'corporateAccounts.corporateClient.industryOption.passengerTerminal',
  },
  { value: 'Real Estate', label: 'corporateAccounts.corporateClient.industryOption.realEstate' },
  { value: 'Retail', label: 'corporateAccounts.corporateClient.industryOption.retail' },
  {
    value: 'Telecommunications',
    label: 'corporateAccounts.corporateClient.industryOption.telecommunications',
  },
  { value: 'Tourism', label: 'corporateAccounts.corporateClient.industryOption.tourism' },
  { value: 'Utilities', label: 'corporateAccounts.corporateClient.industryOption.utilities' },
];

export const CREATE_CORPORATE_CLIENT_ERROR_CODES = {
  CORPORATE_CLIENT_NAME_ALREADY_EXISTS: 'CorporateClientWithTheSameNameAlreadyExists',
};

export const CORPORATE_CLIENT_REGISTRATION_TYPES = {
  ENTERPRISE: 'enterprise',
  SELF_REGISTERED: 'selfRegistered',
};

export const CORPORATE_CLIENT_RESTRICTION_TYPES = {
  UNRESTRICTED: 'unrestricted',
  RESTRICTED: 'restricted',
};

export const CORPORATE_CLIENT_ACCOUNT_TYPES = {
  STANDARD: 'Standard',
  TEST: 'Test',
};

export const CORPORATE_CLIENT_PRICE_PLAN_ERROR_CODES = {
  PricePlanNotAvailable: 'PricePlanNotAvailable',
  PricePlanNotAvailableForNonSelfRegisteredClients:
    'PricePlanNotAvailableForNonSelfRegisteredClients',
  InvoiceIdNotAvailableForCorporateClient: 'InvoiceIdNotAvailableForCorporateClient',
};

export const CREDIT_ACCOUNT_MIGRATION_ERROR_CODES = {
  CorporateClientCountryNotSupported: 'CorporateClientCountryNotSupported',
  CorporateClientNotFound: 'CorporateClientNotFound',
};

export default {
  CORP_CREATE_STEPS,
  CORP_CREATE_FIELDS,
  CORPORATE_CLIENT_STATUS,
  CORPORATE_CLIENT_STATUS_TAGS,
  CORPORATE_CLIENT_STATUS_STRING_TAGS,
  CORPORATE_CLIENT_COUNTRIES_WITH_GLB,
  CORPORATE_CLIENT_COUNTRY_LOCALE,
  CORPORATE_CLIENT_COUNTRY_CSV_DELIMITOR,
  TEST_CORPORATION_STATUS,
  TEST_CORPORATION_STATUS_TAGS,
  CORPORATE_CLIENT_INDUSTRY_OPTIONS,
  CORPORATE_CLIENT_REGISTRATION_TYPES,
  CORPORATE_CLIENT_RESTRICTION_TYPES,
  CORPORATE_CLIENT_PRICE_PLAN_ERROR_CODES,
};
