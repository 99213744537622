import { Button, Typography } from 'antd';
import ReCAPTCHA from 'react-google-recaptcha-enterprise';
import { RECAPTCHA_SITE_KEY } from 'constants.js';

import styles from './style.module.scss';

const ResendVerificationComponent = ({
  recaptchaRef,
  recaptchaToken,
  generateToken,
  handleResendEmail,
  translateText,
}) => (
  <>
    <Typography.Title level={2} className={styles.resendVerificationTitle}>
      {translateText('corporateAccounts.selfRegistration.verification.resendVerification')}
    </Typography.Title>
    <Typography className={styles.resendVerificationInstruction}>
      <p>{translateText('corporateAccounts.selfRegistration.verification.completeCaptcha')}</p>
    </Typography>
    <ReCAPTCHA
      ref={recaptchaRef}
      data-testid="recaptcha"
      id="recaptcha"
      sitekey={RECAPTCHA_SITE_KEY}
      onChange={generateToken}
      style={{ marginTop: '32px' }}
    />
    <div className={styles.resendEmailButton}>
      <Button
        type="primary"
        onClick={() => handleResendEmail()}
        disabled={!recaptchaToken}
        data-testid={'resendEmailButton'}
      >
        <p>{translateText('corporateAccounts.selfRegistration.resendEmail.button')}</p>
      </Button>
    </div>
  </>
);

export default ResendVerificationComponent;
