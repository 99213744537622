import React from 'react';

const MenuLeftPaymentMethodsIcon = ({ color = '#6C6C6C' }) => (
  <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon /CreditCard">
      <path
        id="Vector"
        d="M15.4286 1.71106H0.571429C0.255357 1.71106 0 1.96642 0 2.28249V13.7111C0 14.0271 0.255357 14.2825 0.571429 14.2825H15.4286C15.7446 14.2825 16 14.0271 16 13.7111V2.28249C16 1.96642 15.7446 1.71106 15.4286 1.71106ZM1.28571 2.99677H14.7143V5.13963H1.28571V2.99677ZM14.7143 12.9968H1.28571V6.71106H14.7143V12.9968ZM10.4821 11.8539H13.4286C13.5071 11.8539 13.5714 11.7896 13.5714 11.7111V10.4253C13.5714 10.3468 13.5071 10.2825 13.4286 10.2825H10.4821C10.4036 10.2825 10.3393 10.3468 10.3393 10.4253V11.7111C10.3393 11.7896 10.4036 11.8539 10.4821 11.8539Z"
        fill={color}
      />
    </g>
  </svg>
);

export default MenuLeftPaymentMethodsIcon;
