import React from 'react';
import { Table, Typography } from 'antd';
import { tableRows, columnHeader } from './AuditDetailsTableLayout';
import useTranslation from 'utils/hooks/useTranslation';

import styles from '../style.module.scss';

const AuditDetailsTable = ({ updatedEntityLog, originalEntityLog }) => {
  const { translateText } = useTranslation();
  const entityType = updatedEntityLog.entityType;
  const camelCasedEntityType = entityType.charAt(0).toLowerCase() + entityType.slice(1);

  const getTableColumns = () => {
    const tableHeaderColumn = {
      title: '',
      dataIndex: 'key',
      onCell: _ => ({
        className: styles.auditLogsTableHeader,
      }),
    };

    const sharedTableColumn = (title, dataIndex) => {
      return { title, dataIndex, render: text => <Typography>{text}</Typography> };
    };

    if (originalEntityLog) {
      return [
        tableHeaderColumn,
        {
          title: translateText('corporateAccounts.common.status.previous'),
          dataIndex: 'value',
          render: (text, record) => {
            return (
              <Typography
                className={record.value !== record.newValue ? styles.auditLogsDiffRed : null}
              >
                {text}
              </Typography>
            );
          },
        },
        {
          title: translateText('corporateAccounts.common.status.modified'),
          dataIndex: 'newValue',
          render: (text, record) => (
            <Typography
              className={record.value !== record.newValue ? styles.auditLogsDiffGreen : null}
            >
              {text}
            </Typography>
          ),
        },
      ];
    }

    return [
      tableHeaderColumn,
      sharedTableColumn(translateText(columnHeader[camelCasedEntityType]), 'newValue'),
    ];
  };

  const getTableRows = () =>
    Object.keys(tableRows[camelCasedEntityType]).map(rowName => {
      const label = translateText(tableRows[camelCasedEntityType][rowName]);

      return {
        key: translateText(label),
        value: originalEntityLog?.message.message[rowName.toLowerCase()] || '-',
        newValue: updatedEntityLog?.message.message[rowName.toLowerCase()] || '-',
      };
    });

  return (
    <Table
      columns={getTableColumns()}
      dataSource={getTableRows()}
      bordered
      pagination={false}
      data-testid="audtLogsTable"
    />
  );
};

export default AuditDetailsTable;
