import {
  PBP_SUPER_ADMIN,
  ROUTE_CORPORATIONS,
  ROUTE_CORPORATIONS_ADMIN,
  ROUTE_CORPORATIONS_CREATE,
  ROUTE_CORPORATIONS_EDIT,
  ROUTE_STAFF_USERS,
  ROUTE_DEFAULT,
} from 'constants.js';
import { CORPORATE_CLIENT_STATUS } from 'constants/corporateClients';

const adminRoutes = {
  [ROUTE_CORPORATIONS_ADMIN]: true,
  [ROUTE_CORPORATIONS_CREATE]: true,
  [ROUTE_CORPORATIONS_EDIT]: true,
  [ROUTE_STAFF_USERS]: true,
};

const isDeactivatedCorp = corporation => {
  return corporation?.status === CORPORATE_CLIENT_STATUS.DEACTIVATED;
};

const viewCorpSelectionPage = ({ corporation, path }) => {
  return (!corporation || isDeactivatedCorp(corporation)) && path !== ROUTE_CORPORATIONS;
};

const viewCorpClientPage = ({ corporation, corporationList, path }) => {
  return (
    corporation &&
    !isDeactivatedCorp(corporation) &&
    corporationList.length === 1 &&
    path === ROUTE_CORPORATIONS
  );
};

export const checkNonAdminRedirect = ({ corporation, corporationList, path }) => {
  if (adminRoutes[path] || viewCorpSelectionPage({ corporation, path })) {
    return ROUTE_CORPORATIONS;
  }

  if (viewCorpClientPage({ corporation, corporationList, path })) {
    return ROUTE_DEFAULT;
  }

  return null;
};

export const checkAdminRedirect = ({ corporation, role, path }) => {
  const isSuperAdmin = role === PBP_SUPER_ADMIN;
  if (path === ROUTE_STAFF_USERS) {
    if (!isSuperAdmin) {
      return ROUTE_CORPORATIONS_ADMIN;
    }
  }

  if (!adminRoutes[path] && !corporation) {
    return ROUTE_CORPORATIONS_ADMIN;
  }

  return null;
};

const authorizeRedirect = ({ corporation, corporationList, path, adminRole }) => {
  return adminRole
    ? checkAdminRedirect({ corporation, path, role: adminRole })
    : checkNonAdminRedirect({ corporation, corporationList, path });
};

export default authorizeRedirect;
