import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import { MailOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Table, Row, Col, Drawer } from 'antd';
import Authorize from 'components/Providers/Authorize';
import PageTitle from 'components/Shared/Page/Title';
import actions from 'redux/admins/actions';
import PageWrapper from 'components/Shared/Page/Wrapper';
import DrawerForm from 'components/Shared/DrawerForm';
import withPusher from 'components/Providers/Pusher/withPusher';
import PageBanners from 'components/Layout/PageBanners';
import AdminUserForm from './AdminUserForm';
import useTranslation from 'utils/hooks/useTranslation';
import useAmplitude from 'utils/hooks/useAmplitude';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import adminUtil from 'utils/adminUtil';
import Roles from './roles';
import tableColumns from './tableColumns';
import Can from '../../config/Can';

const ButtonGroup = Button.Group;

const Admins = ({
  addAdminUser,
  adminUsers,
  corporateClientId,
  currentCorporation,
  deleteAdminUser,
  downgradeAccountOwner,
  editAdminUser,
  formErrors,
  getAdminUsers,
  loading,
  sendInvite,
  submitting,
  resetFormErrors,
  intl,
  history,
}) => {
  const { sendAmplitudeEvent } = useAmplitude();
  const { translateText } = useTranslation();
  const [tableData, setTableData] = useState(adminUsers);
  const [formVisibility, setFormVisibility] = useState(false);
  const [formData, setFormData] = useState();
  const [formTitle, setFormTitle] = useState('');
  const [initialValues, setInitialValues] = useState();
  const [isEditing, setFormEdit] = useState(false);
  const [selectedTableRows, setSelectedTableRows] = useState([]);
  const [isSecondaryDrawerOpen, setIsSecondaryDrawerOpen] = useState(false);
  const ACTIVE_USER = 2;

  const handleSubmit = (values, currentAccountOwner) => {
    if (currentAccountOwner) downgradeAccountOwner(currentAccountOwner);
    return values.id ? editAdminUser(values) : addAdminUser(values);
  };

  const handleCancel = visible => {
    adminUtil.handleCancel(setFormVisibility, setFormEdit, setFormData, resetFormErrors, visible);
  };

  const renderInvitationButton = () => {
    const button = (
      <Button
        data-testid="sendUserInviteBtn"
        disabled={selectedTableRows.length === 0}
        type="secondary"
        onClick={() => sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.userInvite)}
      >
        <MailOutlined />
        {translateText('corporateAccounts.actions.sendInvite')}
      </Button>
    );

    const confirmButton = (
      <Popconfirm
        title={translateText('corporateAccounts.admins.actions.sendInviteMessage', {
          hours: 72,
        })}
        okText="OK"
        okButtonProps={{ 'data-testid': 'confirmInviteUserBtn' }}
        cancelText={translateText('corporateAccounts.actions.cancel')}
        onConfirm={sendInviteToAdmins}
      >
        {button}
      </Popconfirm>
    );
    return selectedTableRows.length > 0 ? confirmButton : button;
  };

  const handleRowSelection = {
    selectedRowKeys: selectedTableRows.map(row => `user:${row.email}`),
    onChange: (selectedRowKeys, selectedRows) => setSelectedTableRows(selectedRows),
  };

  const sendInviteToAdmins = () => {
    const adminIds = selectedTableRows
      .filter(row => row.status !== ACTIVE_USER)
      .map(row => ({ adminId: row.id }));
    sendInvite(adminIds);
    setSelectedTableRows([]);
  };

  const handleSecondaryDrawer = val => {
    setIsSecondaryDrawerOpen(val);
  };

  const addUserClick = translateText => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.userAdd);

    adminUtil.handleAddAdminUser(
      corporateClientId,
      setInitialValues,
      setFormTitle,
      setFormVisibility,
      translateText,
    );
  };

  const editUserClick = (recordId, translateText) => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.userEdit);

    adminUtil.handleEditAdminUser(
      recordId,
      tableData,
      setFormEdit,
      setFormData,
      setFormTitle,
      setFormVisibility,
      translateText,
    );
  };

  const deleteUserClick = recordId => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.userDelete);
    adminUtil.handleDeleteAdminUser(recordId, deleteAdminUser);
  };

  useEffect(() => {
    if (corporateClientId) getAdminUsers();
  }, [corporateClientId, getAdminUsers]);

  useEffect(() => {
    if (tableData !== adminUsers) setTableData(adminUsers);
  }, [adminUsers, tableData]);

  useEffect(() => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.userPage);
  }, [sendAmplitudeEvent]);

  return (
    <Authorize>
      <Helmet title="Users" />
      <PageBanners />

      <PageWrapper loading={loading} history={history}>
        <PageTitle
          title={translateText('corporateAccounts.menuLeft.admins')}
          numRecords={tableData.length}
        />

        <div className="card-body text-right">
          <Row gutter={[6, 6]}>
            {!loading && tableData.length > 0 && (
              <Col xs={24} sm={8} md={20}>
                <ButtonGroup style={{ marginTop: 4 }}>{renderInvitationButton()}</ButtonGroup>
              </Col>
            )}

            <Col xs={24} sm={12} md={4}>
              {!loading && (
                <Can I="create" a="AdminUser" passThrough>
                  {can => (
                    <Button
                      data-testid="addUserBtn"
                      type="primary"
                      size="large"
                      onClick={() => addUserClick(translateText)}
                      disabled={!can}
                    >
                      <PlusOutlined />
                      {translateText('corporateAccounts.admins.actions.addUser')}
                    </Button>
                  )}
                </Can>
              )}
            </Col>
          </Row>
        </div>

        <div className="card-body">
          <Table
            columns={tableColumns(editUserClick, deleteUserClick, translateText)}
            dataSource={tableData}
            loading={loading}
            pagination={false}
            rowKey={record => `user:${record.email}`}
            rowSelection={handleRowSelection}
            scroll={{
              x: true,
            }}
          />
          <DrawerForm
            title={formTitle}
            width={320}
            visible={formVisibility}
            onClose={() => handleCancel(false)}
          >
            <AdminUserForm
              currentCorporation={currentCorporation}
              formData={formData}
              formErrors={formErrors}
              initialValues={initialValues}
              onCancel={() => handleCancel(false)}
              onSubmit={handleSubmit}
              onOpenSecondaryDrawer={handleSecondaryDrawer}
              isSubmitting={submitting}
              isEditing={isEditing}
              tableData={tableData}
            />
            <Drawer
              title={translateText('corporateAccounts.admins.role.faq.title')}
              closable
              onClose={() => handleSecondaryDrawer(false)}
              visible={isSecondaryDrawerOpen}
              data-testid="addUserFaq"
            >
              <Roles translateText={translateText} />
            </Drawer>
          </DrawerForm>
        </div>
      </PageWrapper>
    </Authorize>
  );
};

export default withPusher(
  connect(
    ({ admins, user }) => ({
      currentCorporation: user.currentCorporation,
      corporateClientId: user.corporateClientId,
      adminUsers: admins.data,
      submitting: false,
      loading: admins.loading,
      formErrors: admins.formErrors,
    }),
    dispatch => ({
      addAdminUser: payload => dispatch({ type: actions.POST, payload }),
      deleteAdminUser: id => dispatch({ type: actions.DELETE, payload: { id } }),
      downgradeAccountOwner: payload =>
        dispatch({ type: actions.DOWNGRADE_ACCOUNT_OWNER, payload }),
      editAdminUser: payload => dispatch({ type: actions.PUT, payload }),
      getAdminUsers: () => dispatch({ type: actions.GET }),
      resetFormErrors: () => dispatch({ type: actions.RESET_ERRORS }),
      sendInvite: adminIds => dispatch({ type: actions.INVITE, payload: adminIds }),
    }),
  )(injectIntl(Admins)),
);
