import React from 'react';
import { localizedCurrency } from 'services/localize';
import tableUtil from 'utils/tableUtil';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { formatDateTimeMonthFirst } from '../Reports/helper';

const tableColumns = (translateText, locale) => [
  {
    title: translateText('corporateAccounts.reports.localStartDateTime'),
    dataIndex: 'startDateTime',
    sorter: (a, b) => tableUtil.stringSort(a, b, 'startDateTime'),
    render: value => formatDateTimeMonthFirst(value, locale),
  },
  {
    title: translateText('corporateAccounts.reports.firstName'),
    dataIndex: 'parkerFirstName',
    sorter: (a, b) => tableUtil.stringSort(a, b, 'parkerFirstName'),
    render: firstName => {
      return tableUtil.emptyValueFallback(firstName, translateText);
    },
  },
  {
    title: translateText('corporateAccounts.reports.lastName'),
    dataIndex: 'parkerLastName',
    sorter: (a, b) => tableUtil.stringSort(a, b, 'parkerLastName'),
    render: lastName => {
      return tableUtil.emptyValueFallback(lastName, translateText);
    },
  },
  {
    title: translateText('corporateAccounts.reports.parkingVendorName'),
    dataIndex: 'parkingVendorName',
  },
  {
    title: translateText('corporateAccounts.reports.licensePlate'),
    dataIndex: 'licensePlate',
    sorter: (a, b) => tableUtil.stringSort(a, b, 'licensePlate'),
  },
  {
    title: translateText('corporateAccounts.drivers.costCenter'),
    dataIndex: 'costCenter',
  },
  {
    title: () => {
      return (
        <Tooltip title={translateText('corporateAccounts.reports.costInfo')}>
          {translateText('corporateAccounts.reports.cost')}
          <InfoCircleOutlined className="infoCircle" />
        </Tooltip>
      );
    },
    dataIndex: 'totalFee',
    render: value => localizedCurrency(value),
  },
  {
    title: translateText('corporateAccounts.reports.parkingStatus'),
    dataIndex: 'parkingStatus',
    sorter: (a, b) => tableUtil.stringSort(a, b, 'parkingStatus'),
  },
];

export default tableColumns;
