import React from 'react';
import { Button, Tooltip, Dropdown, Space, Menu, Typography } from 'antd';
import { InfoCircleOutlined, DownOutlined } from '@ant-design/icons';
import { DRIVER_STATUS, DRIVER_STATUS_TAGS, DRIVER_STATUS_FILTERS } from 'constants/drivers';
import ability from 'config/ability';
import UtilTag from 'components/Shared/Tag';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import tableUtil from 'utils/tableUtil';
import Can from 'config/Can';
import styles from './style.module.scss';

const tableColumns = ({
  translateText,
  handleEditItem,
  handleInviteDriver,
  handleDeleteDriver,
  statusFilters,
}) => {
  return [
    {
      title: translateText('corporateAccounts.drivers.firstName'),
      dataIndex: 'firstName',
      key: 'name',
      sorter: true,
      defaultSortOrder: 'ascend',
      render: firstName => {
        return tableUtil.emptyValueFallback(firstName, translateText);
      },
    },
    {
      title: translateText('corporateAccounts.drivers.lastName'),
      dataIndex: 'lastName',
      sorter: true,
      render: lastName => {
        return tableUtil.emptyValueFallback(lastName, translateText);
      },
    },
    {
      title: translateText('corporateAccounts.drivers.mobilePhoneNumber'),
      dataIndex: 'mobilePhoneNumber',
      sorter: true,
      render: text => <a href={`tel:${text}`}>{formatPhoneNumber(text)}</a>,
    },
    {
      title: translateText('corporateAccounts.drivers.email'),
      dataIndex: 'email',
      sorter: true,
      render: text => <a href={`mailto:${text}`}>{text}</a>,
    },
    {
      title: () => {
        return (
          <Tooltip title={translateText('corporateAccounts.drivers.costCenterInfo')}>
            {translateText('corporateAccounts.drivers.costCenter')}
            <InfoCircleOutlined className="infoCircle" />
          </Tooltip>
        );
      },
      dataIndex: 'costCenter',
      sorter: true,
      render: costCenter => {
        return tableUtil.emptyValueFallback(costCenter, translateText);
      },
    },
    {
      title: translateText('corporateAccounts.common.status'),
      dataIndex: 'status',
      filteredValue: statusFilters,
      filters: DRIVER_STATUS_FILTERS.map(status => {
        return {
          text: translateText(`corporateAccounts.common.status.${status.key}`),
          value: status.value,
        };
      }),
      render: text => {
        const status = DRIVER_STATUS_TAGS[text];
        return status ? (
          <UtilTag
            content={translateText(`corporateAccounts.common.status.${status.key}`)}
            type={status.key}
          />
        ) : null;
      },
    },
    {
      title: translateText('corporateAccounts.actions'),
      key: 'actions',
      render: (text, record) => {
        const actionItems = [
          <Menu.Item key={`edit:${record.mobilePhoneNumber}`} className={styles.menuItem}>
            <Can I="edit" a="Driver" passThrough>
              {can => (
                <Button
                  type="text"
                  block={true}
                  className={styles.menuItemButton}
                  data-testid={`edit:${record.mobilePhoneNumber}`}
                  onClick={() => handleEditItem(record.id)}
                  disabled={!can}
                >
                  <Typography>
                    <p>{translateText('corporateAccounts.actions.edit')}</p>
                  </Typography>
                </Button>
              )}
            </Can>
          </Menu.Item>,
          <Menu.Item
            key={`invite:${record.mobilePhoneNumber}`}
            className={styles.menuItem}
            hidden={
              record.status === DRIVER_STATUS.ACTIVE || record.status === DRIVER_STATUS.DELETED
            }
          >
            <Can I="invite" a="Driver" passThrough>
              {can => (
                <Button
                  type="text"
                  block={true}
                  className={styles.menuItemButton}
                  data-testid={`invite:${record.mobilePhoneNumber}`}
                  onClick={() => handleInviteDriver(record.id)}
                  disabled={!can}
                >
                  <Typography>
                    <p>{translateText('corporateAccounts.actions.resendInvite')}</p>
                  </Typography>
                </Button>
              )}
            </Can>
          </Menu.Item>,
          <Menu.Item key={`delete:${record.mobilePhoneNumber}`} className={styles.menuItem_delete}>
            <Can I="destroy" a="Driver" passThrough>
              {can => (
                <Button
                  type="text"
                  block={true}
                  className={styles.menuItemButton}
                  data-testid={`delete:${record.mobilePhoneNumber}`}
                  disabled={record.status === DRIVER_STATUS.DEACTIVATED || !can}
                  onClick={() => {
                    handleDeleteDriver(record.id);
                  }}
                >
                  <Typography>
                    <p>{translateText('corporateAccounts.actions.delete')}</p>
                  </Typography>
                </Button>
              )}
            </Can>
          </Menu.Item>,
        ];

        return (
          <>
            <Space>
              <Dropdown
                overlay={
                  <Menu data-testid={`actionItems:${record.mobilePhoneNumber}`}>{actionItems}</Menu>
                }
                trigger={'click'}
              >
                <Button data-testid={`moreActions:${record.mobilePhoneNumber}`}>
                  {translateText('corporateAccounts.admin.action.more')}
                  <DownOutlined />
                </Button>
              </Dropdown>
            </Space>
          </>
        );
      },
    },
  ];
};

const filteredTableColumns = (editItem, deleteButtonClick, deactivateItem, statusFilter) => {
  const columns = tableColumns(editItem, deleteButtonClick, deactivateItem, statusFilter);

  if (ability.cannot('read_all_columns', 'Driver')) {
    return columns.filter(col => {
      const validColumn = [
        'firstName',
        'lastName',
        'mobilePhoneNumber',
        'email',
        'costCenter',
        'status',
        'actions',
      ].includes(col.dataIndex);
      const actionColumn = col.key === 'actions';
      return validColumn || actionColumn;
    });
  }

  return columns;
};

export default filteredTableColumns;
