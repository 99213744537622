import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Col, Row, Table, Select, Button, Typography } from 'antd';
import { injectIntl } from 'react-intl';
import useTranslation from 'utils/hooks/useTranslation';
import useAmplitude from 'utils/hooks/useAmplitude';
import { convertCorporateClientNameToShortName } from 'utils/corporateClientUtil';
import actions from 'redux/reports/actions';
import InfoSvg from 'assets/icons/info.svg';
import infographStyles from 'components/Layout/Infograph/style.module.scss';
import { reports as reportsService } from 'services/api';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import monthlyReportsHelper from './helper';
import tableColumns from './tableColumns';
import style from './style.module.scss';

const { Option } = Select;

const MonthlyReport = () => {
  const { sendAmplitudeEvent } = useAmplitude();
  const { translateText } = useTranslation();
  const [availableRanges, setAvailableRanges] = useState([]);
  const [selectedRange, setSelectedRange] = useState(null);

  const dispatch = useDispatch();
  const currentCorporation = useSelector(state => state.user.currentCorporation);
  const data = useSelector(state => state.reports.monthlyReports);
  const loading = useSelector(state => state.reports.monthlyLoading);
  const language = useSelector(state => state.settings.locale);

  const handleGenerate = () => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.generateReport, {
      paymentDate: availableRanges[selectedRange]?.label,
    });

    const reportToGenerate = monthlyReportsHelper.getReportConfigs(
      convertCorporateClientNameToShortName(currentCorporation.corporateClientName),
      availableRanges,
      selectedRange,
      translateText,
    );
    dispatch({ type: actions.GENERATE_REPORT, payload: { ...reportToGenerate } });
    setSelectedRange(null);
  };

  const handleDownloadReport = async ({ jobId }) => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.downloadReport);

    const { response } = await reportsService.downloadReport(jobId);
    if (response.data) window.open(response.data);
  };

  useEffect(() => {
    dispatch({ type: actions.GET_MONTHLY_REPORTS });
  }, [dispatch]);

  useEffect(() => {
    const generatedRanges = monthlyReportsHelper.buildAvailableReportDates(
      currentCorporation,
      translateText,
    );
    setAvailableRanges(generatedRanges);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [language, currentCorporation]);

  return (
    <>
      <div>
        <Typography.Title level={5}>
          {translateText('corporateAccounts.reports.month')}
        </Typography.Title>
        <Row gutter={[10, 10]} justify="start">
          <Col>
            <Select
              className={style.dateRangeSelector}
              placeholder={translateText('corporateAccounts.reports.month.placeholder')}
              onChange={setSelectedRange}
              value={selectedRange}
            >
              {availableRanges &&
                availableRanges.map((range, index) => (
                  <Option key={range.label} value={index}>
                    {range.label}
                  </Option>
                ))}
            </Select>
          </Col>

          <Col>
            <Button type="primary" onClick={handleGenerate} disabled={selectedRange === null}>
              {translateText('corporateAccounts.reports.monthly.generateReport')}
            </Button>
          </Col>
        </Row>
      </div>
      <Table
        className="card-body-table-padding-top"
        columns={tableColumns({ handleDownloadReport, translateText })}
        dataSource={data}
        rowKey="id"
        loading={loading}
        scroll={{
          x: true,
        }}
      />

      {data.length > 0 && (
        <Row justify="center">
          <Col span={24}>
            <Alert
              type="info"
              icon={<img src={InfoSvg} alt="" className={infographStyles.info} />}
              showIcon
              message={translateText('corporateAccounts.reports.monthly.infoNotice')}
              className={infographStyles.subtext}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default injectIntl(MonthlyReport);
