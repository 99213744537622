import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import useTranslation from 'utils/hooks/useTranslation';
import LanguageSelector from 'components/Layout/TopBar/LanguageSelector';

import styles from './style.module.scss';

const RegisterCorporationFormHeader = () => {
  const dispatch = useDispatch();
  const { translateText } = useTranslation();

  const logout = () => {
    dispatch({ type: 'user/LOGOUT' });
  };

  return (
    <section className={styles.registerCorporationFormHeader}>
      <div className={styles.languageSelectorContainer}>
        <LanguageSelector className={styles.languageSelector} />
      </div>
      <div className={styles.logoutButtonContainer}>
        <Button
          className={styles.logoutButton}
          style={{ border: 'none', outline: 'none !important' }}
          onClick={logout}
        >
          {translateText('corporateAccounts.topBar.profileMenu.signOut')}
        </Button>
      </div>
    </section>
  );
};

export default RegisterCorporationFormHeader;
