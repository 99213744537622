import React from 'react';

const VerificationSuccessIcon = () => (
  <svg
    width="121"
    height="120"
    viewBox="0 0 121 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="60.5" cy="60" r="55" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.5 115C90.8757 115 115.5 90.3757 115.5 60C115.5 29.6243 90.8757 5 60.5 5C30.1243 5 5.5 29.6243 5.5 60C5.5 90.3757 30.1243 115 60.5 115ZM89.0355 48.5355C90.9882 46.5829 90.9882 43.4171 89.0355 41.4645C87.0829 39.5118 83.9171 39.5118 81.9645 41.4645L51.041 72.388L39.5 57C37.8431 54.7909 34.7091 54.3431 32.5 56C30.2909 57.6569 29.8431 60.7909 31.5 63L46.5 83C47.3697 84.1596 48.6997 84.8847 50.1456 84.9874C51.5914 85.0902 53.0106 84.5605 54.0355 83.5355L89.0355 48.5355Z"
      fill="#6CC24A"
    />
  </svg>
);

export default VerificationSuccessIcon;
