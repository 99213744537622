const pbpSuperAdmin = {
  all: [
    'activate',
    'create',
    'deactivate',
    'destroy',
    'edit',
    'edit_corporate_client_name',
    'reactivate',
    'read',
    'read_all_columns',
    'update',
    'upload',
    'view',
    'view_form',
    'migrate',
    'export',
    'set_test',
    'invite',
    'bulkDriverUpload',
  ],
  vehicleForm: ['read_vehicle_location', 'read_vehicle_name', 'read_vehicle_type'],
};

export default pbpSuperAdmin;
