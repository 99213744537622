import React from 'react';
import OnboardingSuccess from './OnboardingSuccess';
import OnboardingFailure from './OnboardingFailure';

import styles from './style.module.scss';

const DriverActivation = ({ error }) => {
  return (
    <div className={styles.activation} data-testid="driverActivation">
      {!error && <OnboardingSuccess />}
      {error && <OnboardingFailure error={error} />}
    </div>
  );
};

export default DriverActivation;
