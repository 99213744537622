/* eslint-disable  */
import React from 'react';
import { Row, Col, Typography } from 'antd';
import useTranslation from 'utils/hooks/useTranslation';
import { BUILD_VERSION, RELEASE_VERSION } from 'constants.js';
import styles from './style.module.scss';

const Footer = () => {
  const { translateText } = useTranslation();

  return (
    <div className={styles.footer}>
      <div className={styles.inner}>
        <Row>
          <Col span={24} className={styles.footerContent}>
            <Typography.Text data-testid="footer">
              &copy; {`${new Date().getFullYear()} `}
              <span>{translateText('corporateAccounts.payByPhone')}</span>
              {'. '}
              <span>{translateText('corporateAccounts.copyright.allRightsReserved')}</span>
              {'. '}
              <span>{translateText('corporateAccounts.corporateClient.version')}</span>:{' '}
              {RELEASE_VERSION}.{BUILD_VERSION}
            </Typography.Text>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Footer;
