import React from 'react';

const EmptyIcon = () => (
  <svg width="68" height="40" viewBox="0 0 68 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="34" cy="32.5" rx="34" ry="7.5" fill="#F5F5F5" />
    <path
      d="M57 31V13H47.5C45.8431 13 44.5 14.3431 44.5 16V16.5C44.5 18.1569 43.1569 19.5 41.5 19.5H27.5C25.8431 19.5 24.5 18.1569 24.5 16.5V16C24.5 14.3431 23.1569 13 21.5 13H12V31C12 33.2091 13.7909 35 16 35H53C55.2091 35 57 33.2091 57 31Z"
      fill="#FAFAFA"
    />
    <path
      d="M57 13L45.7887 2.1284C45.0424 1.40471 44.0436 1 43.0041 1H24.7596C23.6384 1 22.5686 1.47059 21.811 2.2971L12 13M57 13V31C57 33.2091 55.2091 35 53 35H16C13.7909 35 12 33.2091 12 31V13M57 13H47.5C45.8431 13 44.5 14.3431 44.5 16V16.5C44.5 18.1569 43.1569 19.5 41.5 19.5H27.5C25.8431 19.5 24.5 18.1569 24.5 16.5V16C24.5 14.3431 23.1569 13 21.5 13H12"
      stroke="#D9D9D9"
    />
  </svg>
);

export default EmptyIcon;
