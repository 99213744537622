import find from 'lodash.find';
import { corporateClients, adminUsers } from 'services/api';
import userManager from 'utils/userManager';
import { CORPORATE_CLIENT_STATUS } from 'constants/corporateClients';
import { updateAbilities } from 'config/ability';
import { splitRole } from 'config/users/permissions';
import {
  ADMIN_USER_ROLES,
  PBP_SUPER_ADMIN,
  PBP_CUSTOMER_SUCCESS,
  PBP_CS_TIER_2,
  RESTRICTED_CORPORATE_CLIENT_PROSPECT,
  EMAIL_NOT_VERIFIED,
  PLACEHOLDER_CORPORATE_CLIENT_PROSPECT_LIST,
} from 'constants.js';
import jwtService from 'services/jwt';
import isEmpty from 'lodash.isempty';
import { store } from 'initStore';

const loadCorporations = async () => {
  const user = userService.getUserFromState();
  const {
    corporations,
    currentCorporation,
    corporateClientId,
    restrictedCorporateClientProspect,
    emailNotVerified,
    userId,
  } = await getUserCorporations(user);
  return {
    corporations: await setCorporationRole(corporations),
    currentCorporation,
    corporateClientId,
    restrictedCorporateClientProspect,
    emailNotVerified,
    userId,
  };
};

const getUserCorporations = async user => {
  const corporations = await userService.fetchCorporations(user.corporations);

  if (corporations.restrictedCorporateClientProspect) {
    const corporation = corporations.items[0];
    return {
      corporations: corporations.items,
      corporateClientId: corporation.id,
      currentCorporation: corporation,
      restrictedCorporateClientProspect: true,
      emailNotVerified: false,
    };
  }

  if (corporations.emailNotVerified) {
    return {
      corporations: [],
      corporateClientId: EMAIL_NOT_VERIFIED,
      currentCorporation: { id: 'EmailNotVerified' },
      emailNotVerified: true,
      restrictedCorporateClientProspect: false,
      userId: corporations.userId,
    };
  }
  const selectedCorporateClientId = user?.corporateClientId;
  const selectedCurrentCorporation = user?.currentCorporation;
  if (selectedCorporateClientId || selectedCurrentCorporation) {
    if (selectedCorporateClientId === RESTRICTED_CORPORATE_CLIENT_PROSPECT) {
      return {
        corporations,
        corporateClientId: selectedCorporateClientId,
        currentCorporation: selectedCurrentCorporation,
        restrictedCorporateClientProspect: true,
      };
    } else if (selectedCorporateClientId === EMAIL_NOT_VERIFIED) {
      return {
        corporations,
        corporateClientId: selectedCorporateClientId,
        currentCorporation: selectedCurrentCorporation,
        emailNotVerified: true,
      };
    } else {
      return {
        corporations,
        corporateClientId: selectedCorporateClientId,
        currentCorporation: selectedCurrentCorporation,
        restrictedCorporateClientProspect: false,
        emailNotVerified: false,
      };
    }
  }

  const activeOrProspectCorporations = corporations.filter(
    corp =>
      corp.status === CORPORATE_CLIENT_STATUS.ACTIVE ||
      corp.status === CORPORATE_CLIENT_STATUS.PROSPECT,
  );
  if (activeOrProspectCorporations.length === 1) {
    const corporation = corporations[0];
    return {
      corporations,
      corporateClientId: corporation.id,
      currentCorporation: corporation,
      restrictedCorporateClientProspect: false,
      emailNotVerified: false,
    };
  }

  return {
    corporations,
    corporateClientId: null,
    currentCorporation: null,
    restrictedCorporateClientProspect: false,
    emailNotVerified: false,
  };
};

const setCorporationRole = async corporations => {
  const permissions = await userService.getPermissions();

  if (corporations && permissions) {
    corporations = corporations.map(corporation => {
      const role = find(permissions, r => r.split(':')[2] === corporation.id);
      if (role) {
        corporation.userRole = splitRole(role, false);
      }
      return corporation;
    });
  }

  return corporations;
};

const fetchCorporations = async corporations => {
  const permissions = await userService.getPermissions();
  const hasActivation = await userService.checkActivation();

  if (!isEmpty(permissions) && (hasActivation || isEmpty(corporations))) {
    const response = await corporateClients.read({
      offset: 0,
      limit: 200, // Assuming the number of corporations attached to the user will never exceed 200.
    });
    const isCorporateClientProspect = response.data?.items.length === 0;
    if (response?.status === 200 && response.data?.items.length > 0) {
      return response.data.items;
    }

    if (response?.status === 200 && isCorporateClientProspect) {
      return {
        items: PLACEHOLDER_CORPORATE_CLIENT_PROSPECT_LIST,
        restrictedCorporateClientProspect: true,
      };
    }

    if (response.domainErrorCode === EMAIL_NOT_VERIFIED) {
      return {
        emailNotVerified: true,
        userId: response.userId,
      };
    }
  }

  return corporations;
};

const checkTokenExpiration = async () => {
  const auth = await userService.getAuth();
  return auth ? jwtService.isTokenExpired(auth.access_token) : null;
};

const checkActivation = async () => {
  const payload = await getPayload();
  const activationAttributes = payload
    ? payload['https://identity.paybyphone.com/claims/activationid']
    : null;

  return activationAttributes;
};

const currentAccount = async () => {
  const auth = await getAuth();
  const isExpired = auth ? jwtService.isTokenExpired(auth.access_token) : null;

  if (!auth || isExpired) return false;

  const payload = auth.profile;
  const user = await userService.getUserFromState();

  if (user.isActivating) {
    return (
      payload && {
        userId: payload.sub,
        permissions: await userService.getPermissions(),
        corporations: [],
        corporateClientId: null,
        firstName: user.firstName,
      }
    );
  }

  const {
    corporations,
    corporateClientId,
    currentCorporation,
    restrictedCorporateClientProspect,
    emailNotVerified,
    userId,
  } = await loadCorporations();
  return payload
    ? {
        userId: emailNotVerified ? userId : payload.sub,
        permissions: await userService.getPermissions(),
        corporateClientId,
        corporations,
        currentCorporation,
        restrictedCorporateClientProspect,
        emailNotVerified,
      }
    : null;
};

const currentProfile = async () => {
  const user = await userService.getUserFromState();
  const { firstName, lastName, corporationRole: role, corporateClientId } = user;

  const isUserAdmin = await getAdminRole();

  if (!isUserAdmin && !corporateClientId) return null;

  if (!isUserAdmin && (!firstName || !role)) return getUserProfile();

  if (!isUserAdmin) return { firstName, lastName, role };

  const currentAdminRoles = await userService.getPermissions();

  const adminRole = await getAdminRole(currentAdminRoles); // we should make sure we are finding the correct role

  return {
    firstName: adminRole,
    lastName,
    role: adminRole,
  };
};

const updateCurrentAbilities = async () => {
  const user = userService.getUserFromState();
  const { corporations, corporateClientId } = user;
  const isUserAdmin = await getAdminRole();
  let currentRole;
  if (isUserAdmin) {
    currentRole = await userService.getAdminRole();
  } else {
    currentRole = await userService.getPermissions();
  }

  updateAbilities(currentRole, corporations, corporateClientId, isUserAdmin);
};

const getAuth = async () => {
  const auth = await userManager.getUser();
  return auth;
};

const getPayload = async () => {
  const auth = await getAuth();
  return auth && auth.access_token ? jwtService.decodeToken(auth.access_token) : null;
};

const getPermissions = async () => {
  const payload = await getPayload();
  const permissionAttributes = payload
    ? payload['https://identity.paybyphone.com/claims/attributes']
    : null;
  if (!permissionAttributes) return [];

  const permissions =
    typeof permissionAttributes === 'string' ? [permissionAttributes] : permissionAttributes;

  return permissions;
};

const getUserFromState = () => {
  const state = store && store.getState();
  const user = state && state.user;
  return user;
};

const getUserProfile = async () => {
  let profile;
  const user = userService.getUserFromState();
  const { response } = await adminUsers.profiles(user.id);

  if (response && response.status === 200 && response.data) {
    profile = response.data;
  }
  return profile;
};

const getAuthorizationHeaders = async () => {
  await verifyAuth();
  const auth = await getAuth();

  return (await hasAccessTokenParts())
    ? { Authorization: `${auth.token_type} ${auth.access_token}` }
    : {};
};

const hasAccessTokenParts = async () => {
  const auth = await getAuth();
  return auth && auth.token_type && auth.access_token;
};

const getCorporateClientId = () => {
  const user = userService.getUserFromState();
  const id = user && user.corporateClientId ? user.corporateClientId : 0;
  return id;
};

const getAdminRole = async () => {
  const userRoles = await userService.getPermissions();
  let adminRoles = userRoles.map(role => {
    let adminRole;
    const splitRoleArr = role.split(':');
    const adminRoleRole = splitRoleArr.includes('global');

    if (adminRoleRole) {
      adminRole = splitRoleArr[splitRoleArr.length - 1];
    }
    return adminRole;
  });

  adminRoles.sort((a, b) => ADMIN_USER_ROLES[a].weight - ADMIN_USER_ROLES[b].weight);
  adminRoles = adminRoles.filter(role => role !== undefined); // remove undefined values

  if ([PBP_SUPER_ADMIN, PBP_CUSTOMER_SUCCESS, PBP_CS_TIER_2].includes(adminRoles[0])) {
    return adminRoles[0];
  }

  return null;
};

const verifyAuth = async () => {
  const tokenExpired = await userService.checkTokenExpiration();
  const sessionExpired = await userService.verifySessionValid();
  if (tokenExpired && !sessionExpired) {
    await userManager.signinSilent();
  }
};

const verifySessionValid = async () => {
  const auth = await userService.getAuth();
  if (!auth) return false;
  const sessionExpired = jwtService.isSessionExpired(auth.access_token);
  return sessionExpired;
};

const getRestrictedStatus = async () => {
  const user = userService.getUserFromState();
  return {
    restrictedCorporateClientProspect: user.restrictedCorporateClientProspect,
    emailNotVerified: user.emailNotVerified,
  };
};

const userService = {
  checkActivation,
  checkTokenExpiration,
  currentAccount,
  currentProfile,
  fetchCorporations,
  getAdminRole,
  getAuth,
  getAuthorizationHeaders,
  getCorporateClientId,
  getPermissions,
  getUserFromState,
  hasAccessTokenParts,
  loadCorporations,
  setCorporationRole,
  updateCurrentAbilities,
  verifyAuth,
  verifySessionValid,
  getRestrictedStatus,
};

export default userService;
