import http from 'services/http';
import userService from 'services/user';
import { ENDPOINT_PARKING_SESSIONS } from 'constants.js';
import { endpoint } from '.';

const read = async () => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'GET',
    url: endpoint(ENDPOINT_PARKING_SESSIONS),
    headers: {
      ...authHeaders,
    },
  };

  const response = http(requestOptions);
  return response;
};

export default { read };
