import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'redux/settings/actions';
import LanguageSelector from './LanguageSelector';
import ProfileMenu from './ProfileMenu';
import HelpMenu from './HelpMenu';
import styles from './style.module.scss';

const TopBar = ({ showLogo }) => {
  const dispatch = useDispatch();

  const { corporateClientId, adminRole } = useSelector(state => state.user);
  const isMobileView = useSelector(state => state.settings.isMobileView);

  const renderLogo = () => {
    return (
      <div data-testid="topBarLogo" style={{ display: isMobileView ? 'none' : 'inline' }}>
        <img src="/resources/images/logo-pbp.svg" alt="" />
      </div>
    );
  };

  const handleOpenMenu = () => {
    dispatch({
      type: actions.CHANGE_SETTING,
      payload: {
        setting: 'isMobileMenuOpen',
        value: true,
      },
    });
  };

  return (
    <div className={styles.topbar}>
      <div className="mr-auto">
        {isMobileView && (
          <button
            className={styles.menuTrigger}
            type="button"
            onClick={handleOpenMenu}
            title="Open Menu"
          >
            <span />
            <span />
            <span />
          </button>
        )}

        {showLogo ? renderLogo() : !corporateClientId && !adminRole && renderLogo()}
      </div>
      <div className="mr-4">
        <LanguageSelector />
      </div>
      <div className="mr-4">
        <HelpMenu />
      </div>
      <ProfileMenu />
    </div>
  );
};

export default TopBar;
