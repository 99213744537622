import { Typography } from 'antd';
import { VerifyEmailIcon } from 'components/Shared/Icons';

import styles from './style.module.scss';

const ResendVerificationLanding = ({ setResendVerificationComponentOpen, translateText }) => (
  <>
    <div className={styles.verifyEmailIcon} data-testid="successIcon">
      <VerifyEmailIcon />
    </div>
    <Typography.Title level={2} className={styles.resendVerificationTitle}>
      {translateText('corporateAccounts.selfRegistration.success.title')}
    </Typography.Title>
    <Typography className={styles.resendVerificationInstruction}>
      <p>{translateText('corporateAccounts.selfRegistration.emailVerificationInstruction')}</p>
    </Typography>
    <Typography.Title
      level={5}
      className={styles.resendVerificationText}
      onClick={() => setResendVerificationComponentOpen(true)}
    >
      {translateText('corporateAccounts.selfRegistration.verification.resendVerification')}
    </Typography.Title>
  </>
);

export default ResendVerificationLanding;
