import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import withPusher from 'components/Providers/Pusher/withPusher';
import useCookiesConsent from 'utils/hooks/useCookiesConsent';
import useSelfRegistrationPage from './useSelfRegistrationPage';
import useVerificationStatus from './VerificationStatus/useVerificationStatus';
import useVerificationPage from '../VerificationPage/useVerificationPage';
import VerificationStatus from './VerificationStatus';
import { getQueryParameters } from './helper';
import RegistrationPage from './RegistrationPage';

const SelfRegistration = () => {
  const {
    onRegister,
    onRegistrationPageLoad,
    isRegistrationLoading,
    isRegistrationSuccessful,
    registrationError,
  } = useSelfRegistrationPage();
  const isMobile = useSelector(state => state.settings.isMobileView);
  const {
    verificationCode,
    verificationError,
    isVerificationSuccessful,
    onVerificationPageLoad,
  } = useVerificationStatus();
  const { onResendEmail } = useVerificationPage();

  useEffect(() => {
    const queryParams = getQueryParameters(window.location.search);
    onRegistrationPageLoad({ queryParams });
    onVerificationPageLoad({ queryParams });
  }, [onRegistrationPageLoad, onVerificationPageLoad]);

  useCookiesConsent();

  return (
    <>
      {verificationCode ? (
        <VerificationStatus
          verificationError={verificationError}
          isVerificationSuccessful={isVerificationSuccessful}
          onResendEmail={onResendEmail}
        />
      ) : (
        <RegistrationPage
          isMobile={isMobile}
          onRegister={onRegister}
          registrationError={registrationError}
          isRegistrationLoading={isRegistrationLoading}
          isRegistrationSuccessful={isRegistrationSuccessful}
        />
      )}
    </>
  );
};

export default withPusher(withRouter(injectIntl(SelfRegistration)));
