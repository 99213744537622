import { useRef, useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useUpdateEffect from 'utils/hooks/useUpdateEffect';
import useAmplitude from 'utils/hooks/useAmplitude';
import { ZENDESK_SUPPORTED_COUNTRIES } from 'constants.js';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
/* eslint-disable */
const zendeskSupportedCountries = ZENDESK_SUPPORTED_COUNTRIES?.split(',') || [];

/**
 * Decided not to test the zendesk API script at a unit test level. We don't want to pull in a 3rd party script during a test.
 * useZendeskWidget will be mocked out for all global tests to prevent this. On a unit test level, since the hook is reliant on
 * proper zendesk script APIs to be loaded, we cannot reliably test the functionality. This particular area of our code needs to be
 * smoke tested.
 */
const useZendeskWidget = ({ zKey, settings }) => {
  const { sendAmplitudeEvent } = useAmplitude();
  const { currentCorporation, firstName, lastName } = useSelector(state => state.user);
  const [mounted, setMounted] = useState(false);
  const script = useRef(document.createElement('script'));

  const mountWidget = useCallback(
    script => {
      script.id = 'ze-snippet';
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zKey}`;
      script.addEventListener('load', () => {
        window.zE('messenger:on', 'open', function() {
          sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.zendesk);
        });
        setMounted(true);
      });

      // https://developer.zendesk.com/api-reference/widget/settings/
      window.zESettings = settings || {};
      document.body.appendChild(script);
    },
    [settings, zKey, setMounted, sendAmplitudeEvent],
  );

  const showWidget = useCallback(() => {
    if (mounted) {
      zE('messenger:set', 'zIndex', 40); // $ztier-4
    }
  }, [mounted]);

  const hideWidget = useCallback(() => {
    if (mounted) {
      zE('messenger:set', 'zIndex', -10);
    }
  }, [mounted]);

  useEffect(() => {
    if (!mounted) {
      mountWidget(script.current);
    }
  }, [mountWidget]);

  useEffect(() => {
    if (
      currentCorporation?.country &&
      zendeskSupportedCountries.includes(currentCorporation.country)
    ) {
      showWidget();
    } else {
      hideWidget();
    }
  }, [currentCorporation, showWidget, hideWidget]);

  useUpdateEffect(() => {
    if (mounted && currentCorporation?.country) {
      zE('messenger:set', 'locale', currentCorporation.country);
    }
  }, [currentCorporation, mounted]);
};

export default useZendeskWidget;
