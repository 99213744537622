import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { deinitializeAmplitude, initializeAmplitude } from 'utils/amplitudeUtil';
import { deinitializeHotjar, initializeHotjar } from 'utils/hotjarUtil';
import { initializePusher } from 'components/Providers/Pusher/withPusher/pusher';

const useInitializeTrackingServices = () => {
  const authorized = useSelector(state => state.user?.authorized);
  const hasAcceptedFunctionalCookies = useSelector(
    state => state.settings?.hasAcceptedFunctionalCookies,
  );

  useEffect(() => {
    if (authorized) {
      initializePusher();
    }
  }, [authorized]);

  useEffect(() => {
    if (hasAcceptedFunctionalCookies) {
      initializeAmplitude();
      initializeHotjar();
    } else {
      deinitializeAmplitude();
      deinitializeHotjar();
    }
  }, [hasAcceptedFunctionalCookies]);
};

export default useInitializeTrackingServices;
