import { useMutation, useQueryClient, useQuery } from 'react-query';
import moment from 'moment';
import { datadogLogs } from '@datadog/browser-logs';
import driversApi from 'services/api/drivers';
import { calculateOffset } from 'utils/paginationUtil';
import useTranslation from 'utils/hooks/useTranslation';
import notify from 'utils/notificationUtils';
import useAmplitude from 'utils/hooks/useAmplitude';
import { generateAndDownloadCsv, getDriverExportHeaders } from 'utils/exportUtil';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import {
  DRIVER_IMPORT_ERRORS,
  IMPORT_ERRORS_TRANSLATION_MAP,
  DRIVER_AMPLITUDE_EVENTS,
} from 'constants/drivers';
import useOnboardingProgressBar from 'components/Shared/OnboardingProgressBar/useOnboardingProgressBar';
import { updateDriverFromPage, updateDriverInvitationStatuses } from './helpers';
import mappings from './mappings';
import { driverQueryKeys } from '../queryKeys';

const { getDriversQuery } = driverQueryKeys;

const PAGE_SIZE_EXPORT = 1000;
const DRIVERS_DATA_STALETIME = 30000;

export const useGetDrivers = ({ query, offset, limit, orderBy, status, enabled }) => {
  const { translateText } = useTranslation();

  const loadDrivers = async ({ queryKey, pageParam }) => {
    const [, { query, offset, limit, orderBy, status, enabled }] = queryKey;
    const result = await driversApi.read({
      query,
      limit,
      offset,
      orderBy,
      status,
      enabled,
      ...pageParam,
    });

    return result?.data;
  };

  return useQuery({
    queryKey: [getDriversQuery, { query, offset, limit, orderBy, status, enabled }],
    queryFn: loadDrivers,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    staleTime: DRIVERS_DATA_STALETIME,
    enabled,
    onError: () => {
      notify({
        type: 'error',
        message: translateText('corporateAccounts.driver.get.error'),
      });
    },
  });
};

export const useDeleteDriver = () => {
  const { translateText } = useTranslation();

  const queryClient = useQueryClient();
  const { sendAmplitudeEvent } = useAmplitude();
  const deleteDriver = async driverId => {
    const result = await driversApi.destroy(driverId);
    return result?.data;
  };
  const { onGetOnboardingProgress } = useOnboardingProgressBar();

  return useMutation(deleteDriver, {
    onSuccess: () => {
      sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.driverDeleteSuccessful);
      queryClient.invalidateQueries(getDriversQuery, { exact: false });
      notify({
        type: 'success',
        message: translateText('corporateAccounts.driver.delete.success'),
      });
      onGetOnboardingProgress();
    },
    onError: () => {
      notify({
        type: 'error',
        message: translateText('corporateAccounts.driver.delete.error'),
      });
      sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.driverDeleteFailed);
    },
  });
};

export const useBulkDeleteDrivers = () => {
  const { translateText } = useTranslation();

  const queryClient = useQueryClient();
  const { sendAmplitudeEvent } = useAmplitude();
  const deleteDrivers = async driverIds => {
    const result = await driversApi.batchDelete({ parkerIds: driverIds });
    return result?.data;
  };
  const { onGetOnboardingProgress } = useOnboardingProgressBar();

  return useMutation(deleteDrivers, {
    onSuccess: deletedDrivers => {
      sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.driverBulkDeleteSuccessful);
      queryClient.invalidateQueries(getDriversQuery, { exact: false });
      notify({
        type: 'success',
        message: translateText('corporateAccounts.driver.bulkDelete.success', {
          numOfDeletedDrivers: deletedDrivers.length,
        }),
      });
      onGetOnboardingProgress();
    },
    onError: () => {
      sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.driverBulkDeleteFailed);
      notify({
        type: 'error',
        message: translateText('corporateAccounts.driver.delete.error'),
      });
    },
  });
};

export const useInviteDriver = () => {
  const queryClient = useQueryClient();
  const { translateText } = useTranslation();
  const { sendAmplitudeEvent } = useAmplitude();
  let driversToUpdate;
  let failedInvitationsIndexes;
  let isBulkDriverInvite;

  const inviteDriver = async driverData => {
    driversToUpdate = driverData.DriverIds;
    isBulkDriverInvite = driversToUpdate.length > 1;
    const result = await driversApi.invitation(driverData);
    failedInvitationsIndexes = result?.data?.errors?.InternalError;
    return result?.data;
  };

  const updateCachedDriverData = () => {
    queryClient.setQueriesData({ queryKey: getDriversQuery, exact: false }, queryData => {
      const newItems = updateDriverInvitationStatuses(
        queryData.items,
        driversToUpdate,
        failedInvitationsIndexes,
      );
      return {
        ...queryData,
        items: newItems,
      };
    });
  };

  return useMutation(inviteDriver, {
    onSuccess: () => {
      isBulkDriverInvite
        ? sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.driverBulkInvitationSuccessful)
        : sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.driverInvitationSuccessful);
      updateCachedDriverData();
      notify({
        type: 'success',
        message: translateText('corporateAccounts.actions.resendInvite.success'),
      });
    },
    onError: () => {
      isBulkDriverInvite
        ? sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.driverBulkInvitationFailed)
        : sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.driverInvitationFailed);
      // If there's an error, then that means all invitations failed
      // so we set the error array to include all indexes,
      // and then we rethrow the error
      failedInvitationsIndexes = driversToUpdate.map((_, i) => i);
      updateCachedDriverData();
      notify({
        type: 'error',
        message: translateText('corporateAccounts.actions.resendInvite.error'),
      });
    },
  });
};

export const useAddDriver = () => {
  const queryClient = useQueryClient();
  const { sendAmplitudeEvent } = useAmplitude();
  const { translateText } = useTranslation();
  const { onGetOnboardingProgress } = useOnboardingProgressBar();
  const addDriver = async driver => {
    const result = await driversApi.create(driver);
    return result?.data;
  };

  return useMutation(addDriver, {
    onSuccess: () => {
      sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.driverAddSucessful);
      queryClient.invalidateQueries(getDriversQuery, { exact: false });
      notify({
        type: 'success',
        message: translateText('corporateAccounts.actions.resendInvite.success'),
      });
      onGetOnboardingProgress();
    },
    onError: ({ errors }) => {
      const errorMessage = Object.keys(DRIVER_IMPORT_ERRORS).find(
        err =>
          DRIVER_IMPORT_ERRORS[err] === errors ||
          DRIVER_IMPORT_ERRORS[err] === errors?.domainErrorCode,
      );

      if (errorMessage) {
        sendAmplitudeEvent(DRIVER_AMPLITUDE_EVENTS[errorMessage]);
        notify({
          type: 'error',
          message: translateText(IMPORT_ERRORS_TRANSLATION_MAP[DRIVER_IMPORT_ERRORS[errorMessage]]),
        });
      } else {
        sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.driverAddFailed);
        notify({
          type: 'error',
          message: translateText('corporateAccounts.driver.add.error'),
        });
      }
    },
  });
};

export const useActivateDriver = () => {
  const { translateText } = useTranslation();
  const { sendAmplitudeEvent } = useAmplitude();
  const activateDriver = async invitationCode => {
    const result = await driversApi.activateV2(invitationCode);
    return result?.data;
  };

  return useMutation(activateDriver, {
    onSuccess: activatedDriver => {
      sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.onboardingDriverSuccessful);
      notify({
        type: 'success',
        message: translateText('corporateAccounts.onboarding.success.notification', {
          companyName: activatedDriver?.corporateClientName,
        }),
      });
    },
  });
};

export const useRegisterDriver = companyName => {
  const { translateText } = useTranslation();
  const { sendAmplitudeEvent } = useAmplitude();
  const registerDriver = async query => {
    const result = await driversApi.register(query);
    return result?.data;
  };

  return useMutation(registerDriver, {
    onSuccess: () => {
      sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.onboardingDriverSuccessful);
      notify({
        type: 'success',
        message: translateText('corporateAccounts.onboarding.success.notification', {
          companyName,
        }),
      });
    },
  });
};

export const useGetDriverByRegistrationCode = ({ query }) => {
  const getDriverByRegistrationToken = async ({ queryKey }) => {
    const [{ query }] = queryKey;
    const result = await driversApi.find(query);
    return result?.data;
  };

  return useQuery({
    queryKey: [{ query }],
    queryFn: getDriverByRegistrationToken,
    enabled: !!query,
    retry: false, // This is intended solely for development purposes and must be removed.
    keepPreviousData: false,
    refetchOnWindowFocus: false,
  });
};

export const useEditDriver = () => {
  const { translateText } = useTranslation();

  const queryClient = useQueryClient();
  const { sendAmplitudeEvent } = useAmplitude();
  const editDriver = async driver => {
    const result = await driversApi.update(driver);
    return result?.data;
  };

  return useMutation(editDriver, {
    onSuccess: updatedDriver => {
      sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.driverEditSuccessful);
      queryClient.setQueriesData({ queryKey: getDriversQuery, exact: false }, queryData => {
        const newItems = updateDriverFromPage(queryData.items, updatedDriver);
        return {
          ...queryData,
          items: newItems,
        };
      });
      notify({
        type: 'success',
        message: translateText('corporateAccounts.driver.update.success'),
      });
    },
    onError: () => {
      notify({
        type: 'error',
        message: translateText('corporateAccounts.driver.update.error'),
      });
      sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.driverEditFailed);
    },
  });
};

export const useExportDrivers = currentCorporation => {
  const { translateText } = useTranslation();

  const loadExportDrivers = async ({ query, status, orderBy }) => {
    const startTime = moment().valueOf();
    const drivers = [];
    let pageNumber = 1;
    let getMoreDrivers = false;

    do {
      const result = await driversApi.read({
        query,
        limit: PAGE_SIZE_EXPORT,
        offset: calculateOffset(pageNumber, PAGE_SIZE_EXPORT),
        orderBy,
        status,
      });

      if (result.data?.items.length > 0) {
        drivers.push(...result.data?.items);
      }

      pageNumber += 1;
      getMoreDrivers = result.data?.items.length === PAGE_SIZE_EXPORT;
    } while (getMoreDrivers);

    return { drivers, startTime };
  };

  return useMutation(loadExportDrivers, {
    onSuccess: data => {
      const mappedDrivers = mappings.mapExportedDrivers(data.drivers);
      generateAndDownloadCsv(
        mappedDrivers,
        getDriverExportHeaders(currentCorporation?.country, translateText),
        'DriverList',
      );

      const endTime = moment().valueOf();
      datadogLogs.logger.info(`Driver export finished. Duration: ${endTime - data.startTime}ms`, {
        duration: endTime - data.startTime,
      });

      notify({
        type: 'success',
        message: translateText('corporateAccounts.drivers.export.success.notification.description'),
      });
    },
    onError: () => {
      notify({
        type: 'error',
        message: translateText('corporateAccounts.drivers.export.error.modal.description'),
      });
    },
  });
};

export const useImportDrivers = () => {
  const { translateText } = useTranslation();
  const { sendAmplitudeEvent } = useAmplitude();
  const uploadDrivers = async data => {
    const response = await driversApi.upload(data);
    return response?.data;
  };

  return useMutation(uploadDrivers, {
    onSuccess: data => {
      sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.driverBulkImportSuccessful);
      notify({
        type: 'info',
        message: translateText('corporateAccounts.drivers.import.inProgress', {
          total: data.totalNumberOfDrivers,
          driversAdded: data.numberOfDriversToAdd || 0,
          driversEdited: data.numberOfDriversToEdit || 0,
        }),
      });
    },
    onError: error => {
      const validationErrors = error?.errors?.errors ?? null;
      const isLimitExceededError =
        error?.errors?.domainErrorCode ===
        DRIVER_IMPORT_ERRORS.RestrictedCorporateClientLimitExceeded;

      if (isLimitExceededError) {
        sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.driverUploadLimitExceededError);
      } else if (validationErrors) {
        sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.driverBulkImportCSVFileError);
      } else {
        sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.driverBulkImportFailed);
      }

      notify({
        type: 'error',
        message: translateText('corporateAccounts.errors.fileUpload'),
      });
    },
  });
};

export default {
  useGetDrivers,
  useAddDriver,
  useEditDriver,
  useDeleteDriver,
  useBulkDeleteDrivers,
  useInviteDriver,
  useActivateDriver,
  useRegisterDriver,
  useGetDriverByRegistrationCode,
  useExportDrivers,
  useImportDrivers,
};
