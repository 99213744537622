import notify from 'utils/notificationUtils.js';
import useTranslation from 'utils/hooks/useTranslation';
import useFormatValidationErrors from 'pages/Drivers/DriverImportModal/useFormatValidationErrors';

const useDriverImportCompletionNotification = () => {
  const { translateText } = useTranslation();
  const { formatImportErrors } = useFormatValidationErrors();

  const onDriverImportPushNotification = ({
    DriversProcessed,
    DriversAdded,
    DriversEdited,
    DriversFailed,
    Errors,
  }) => {
    if (Object.keys(Errors).length === 0 && !DriversFailed) {
      notify({
        type: 'success',
        message: translateText('corporateAccounts.drivers.import.success', {
          total: DriversAdded + DriversEdited,
          driversAdded: DriversAdded,
          driversEdited: DriversEdited,
        }),
      });
    } else if (DriversFailed < DriversProcessed) {
      const driverImportErrors = Errors ? formatImportErrors(Errors) : null;

      notify({
        type: 'warning',
        csvFileInformation: {
          title: translateText('corporateAccounts.actions.downloadLogFile'),
          fileName: `${translateText('corporateAccounts.drivers.import.error.fileName')}.csv`,
          data: driverImportErrors,
        },
        message: translateText('corporateAccounts.drivers.import.warning', {
          total: DriversAdded + DriversEdited,
          driversAdded: DriversAdded,
          driversEdited: DriversEdited,
          driversFailed: DriversFailed,
        }),
      });
    } else {
      const driverImportErrors = Errors ? formatImportErrors(Errors) : null;

      notify({
        type: 'error',
        csvFileInformation: {
          title: translateText('corporateAccounts.actions.downloadLogFile'),
          fileName: `${translateText('corporateAccounts.drivers.import.error.fileName')}.csv`,
          data: driverImportErrors,
        },
        message: translateText('corporateAccounts.drivers.import.error.failed'),
      });
    }
  };

  return { onDriverImportPushNotification };
};

export default useDriverImportCompletionNotification;
