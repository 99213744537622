import status from 'http-status';

export const isSuccess = response => {
  return (
    response &&
    (response.status === status.OK ||
      response.status === status.CREATED ||
      response.status === status.NO_CONTENT)
  );
};

export const isBadRequest = error => {
  return error && error.status === status.BAD_REQUEST;
};

export const isNotFound = error => {
  return error && error.status === status.NOT_FOUND;
};

export const isUnprocessableEntity = error => {
  return error && error.status === status.UNPROCESSABLE_ENTITY;
};

export const isInteralServerError = error => {
  return error && error.status === status.INTERNAL_SERVER_ERROR;
};
