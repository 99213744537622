import React from 'react';

const AuditLogsEmptyStateIcon = () => (
  <svg
    width="84"
    height="90"
    viewBox="0 0 84 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="auditLogsEmptyStateIcon"
  >
    <g id="Group 2">
      <path
        id="Vector"
        d="M56.8253 55.8202H11.9265C11.0149 55.8192 10.141 55.4566 9.49644 54.812C8.85188 54.1675 8.48931 53.2936 8.48828 52.382V3.4382C8.48931 2.52664 8.85188 1.65272 9.49644 1.00815C10.141 0.36359 11.0149 0.00102522 11.9265 0H56.8253C57.7369 0.00101734 58.6108 0.36358 59.2554 1.00815C59.9 1.65271 60.2625 2.52664 60.2635 3.4382V52.382C60.2625 53.2936 59.9 54.1675 59.2554 54.8121C58.6108 55.4566 57.7369 55.8192 56.8253 55.8202Z"
        fill="#E6E6E6"
      />
      <path
        id="Vector_2"
        d="M39.0276 11.3259H16.7804C16.1904 11.3259 15.6245 11.0915 15.2073 10.6743C14.79 10.2571 14.5557 9.69122 14.5557 9.10119C14.5557 8.51116 14.79 7.94529 15.2073 7.52807C15.6245 7.11086 16.1904 6.87646 16.7804 6.87646H39.0276C39.6176 6.87646 40.1835 7.11086 40.6007 7.52807C41.0179 7.94529 41.2523 8.51116 41.2523 9.10119C41.2523 9.69122 41.0179 10.2571 40.6007 10.6743C40.1835 11.0915 39.6176 11.3259 39.0276 11.3259Z"
        fill="white"
      />
      <path
        id="Vector_3"
        d="M51.3647 22.6518H16.9827C16.6637 22.6522 16.3477 22.5898 16.0529 22.468C15.758 22.3463 15.49 22.1676 15.2643 21.9422C15.0386 21.7168 14.8595 21.4491 14.7373 21.1544C14.6151 20.8597 14.5522 20.5438 14.5522 20.2248C14.5522 19.9058 14.6151 19.5899 14.7373 19.2953C14.8595 19.0006 15.0386 18.7329 15.2643 18.5075C15.49 18.282 15.758 18.1034 16.0529 17.9816C16.3477 17.8598 16.6637 17.7974 16.9827 17.7979H51.3647C52.0078 17.7988 52.6242 18.0549 53.0786 18.5099C53.5329 18.965 53.7882 19.5817 53.7882 20.2248C53.7882 20.8679 53.5329 21.4847 53.0786 21.9397C52.6242 22.3948 52.0078 22.6509 51.3647 22.6518Z"
        fill="white"
      />
      <path
        id="Vector_4"
        d="M51.3646 31.1459H16.9826C16.339 31.1459 15.7216 30.8902 15.2665 30.4351C14.8114 29.9799 14.5557 29.3626 14.5557 28.719C14.5557 28.0753 14.8114 27.458 15.2665 27.0028C15.7216 26.5477 16.339 26.292 16.9826 26.292H51.3646C52.0083 26.292 52.6256 26.5477 53.0808 27.0028C53.5359 27.458 53.7916 28.0753 53.7916 28.719C53.7916 29.3626 53.5359 29.9799 53.0808 30.4351C52.6256 30.8902 52.0083 31.1459 51.3646 31.1459Z"
        fill="white"
      />
      <path
        id="Vector_5"
        d="M51.3646 39.6405H16.9826C16.339 39.6405 15.7216 39.3849 15.2665 38.9297C14.8114 38.4746 14.5557 37.8573 14.5557 37.2136C14.5557 36.5699 14.8114 35.9526 15.2665 35.4975C15.7216 35.0423 16.339 34.7866 16.9826 34.7866H51.3646C52.0083 34.7866 52.6256 35.0423 53.0808 35.4975C53.5359 35.9526 53.7916 36.5699 53.7916 37.2136C53.7916 37.8573 53.5359 38.4746 53.0808 38.9297C52.6256 39.3849 52.0083 39.6405 51.3646 39.6405Z"
        fill="white"
      />
      <path
        id="Vector_6"
        d="M60.9161 71.3759L19.0903 55.0507C18.2415 54.7183 17.5592 54.0628 17.1931 53.228C16.827 52.3932 16.807 51.4472 17.1375 50.5977L34.9334 5.00383C35.2658 4.15504 35.9213 3.47276 36.7561 3.10668C37.5909 2.74059 38.5369 2.72059 39.3864 3.05108L81.2122 19.3762C82.061 19.7086 82.7433 20.3641 83.1094 21.199C83.4755 22.0338 83.4954 22.9797 83.1649 23.8293L65.369 69.4231C65.0367 70.2719 64.3812 70.9542 63.5463 71.3203C62.7115 71.6864 61.7656 71.7064 60.9161 71.3759Z"
        fill="#E6E6E6"
      />
      <path
        id="Vector_7"
        d="M60.5142 23.4556L39.7897 15.3666C39.2404 15.1518 38.7988 14.7277 38.5621 14.1875C38.3253 13.6472 38.3128 13.0351 38.5273 12.4856C38.7417 11.9362 39.1656 11.4944 39.7057 11.2573C40.2459 11.0203 40.858 11.0075 41.4076 11.2217L62.132 19.3107C62.6817 19.5253 63.1236 19.9494 63.3606 20.4897C63.5975 21.0301 63.6101 21.6424 63.3956 22.1921C63.1811 22.7417 62.757 23.1837 62.2166 23.4206C61.6763 23.6576 61.0639 23.6702 60.5142 23.4556Z"
        fill="white"
      />
      <path
        id="Vector_8"
        d="M67.8889 38.4921L35.8601 25.9908C35.261 25.7564 34.7794 25.2938 34.5213 24.7045C34.2632 24.1152 34.2496 23.4475 34.4835 22.8482C34.7174 22.2489 35.1797 21.767 35.7688 21.5084C36.3579 21.2498 37.0255 21.2357 37.625 21.4691L69.6538 33.9704C69.9511 34.086 70.2227 34.259 70.4531 34.4797C70.6834 34.7003 70.868 34.9642 70.9963 35.2563C71.1246 35.5483 71.1941 35.8628 71.2008 36.1817C71.2075 36.5006 71.1512 36.8177 71.0352 37.1149C70.9192 37.412 70.7458 37.6834 70.5248 37.9135C70.3039 38.1435 70.0397 38.3278 69.7475 38.4557C69.4553 38.5836 69.1408 38.6527 68.8218 38.6589C68.5029 38.6652 68.1859 38.6085 67.8889 38.4921Z"
        fill="white"
      />
      <path
        id="Vector_9"
        d="M64.8008 46.4049L32.7721 33.9036C32.4752 33.7878 32.204 33.6145 31.9741 33.3938C31.7441 33.1732 31.5599 32.9093 31.4319 32.6175C31.3039 32.3256 31.2346 32.0113 31.2281 31.6927C31.2215 31.3741 31.2778 31.0572 31.3937 30.7603C31.5096 30.4634 31.6828 30.1923 31.9034 29.9623C32.1241 29.7324 32.3879 29.5481 32.6798 29.4201C32.9717 29.2921 33.2859 29.2229 33.6046 29.2163C33.9232 29.2098 34.24 29.266 34.5369 29.3819L66.5657 41.8832C67.1653 42.1172 67.6474 42.5799 67.9059 43.1694C68.1644 43.7588 68.1781 44.4269 67.9441 45.0265C67.7101 45.6261 67.2474 46.1082 66.6579 46.3667C66.0685 46.6252 65.4004 46.6389 64.8008 46.4049Z"
        fill="white"
      />
      <path
        id="Vector_10"
        d="M61.7121 54.3183L29.6834 41.817C29.0842 41.5826 28.6027 41.1199 28.3446 40.5307C28.0864 39.9414 28.0728 39.2737 28.3067 38.6744C28.5407 38.0751 29.0029 37.5932 29.592 37.3346C30.1811 37.076 30.8487 37.0619 31.4482 37.2953L63.477 49.7966C64.0766 50.0306 64.5587 50.4932 64.8172 51.0827C65.0757 51.6722 65.0895 52.3402 64.8554 52.9398C64.6214 53.5395 64.1587 54.0215 63.5692 54.2801C62.9797 54.5386 62.3117 54.5523 61.7121 54.3183Z"
        fill="white"
      />
      <path
        id="Vector_11"
        d="M74.2185 39.4383H25.8295L18.1365 27.1994C17.8451 26.7359 17.441 26.3539 16.9619 26.0892C16.4827 25.8244 15.9442 25.6855 15.3968 25.6855H3.83654C3.41159 25.6855 2.99082 25.7692 2.59821 25.9319C2.20561 26.0945 1.84887 26.3328 1.54838 26.6333C1.2479 26.9338 1.00952 27.2905 0.846898 27.6831C0.684278 28.0758 0.60058 28.4965 0.600586 28.9215V86.7642C0.60058 87.1891 0.684278 87.6099 0.846898 88.0025C1.00952 88.3951 1.2479 88.7519 1.54838 89.0524C1.84887 89.3528 2.20561 89.5912 2.59821 89.7538C2.99082 89.9164 3.41159 90.0001 3.83654 90.0001H74.2185C74.6435 90.0001 75.0643 89.9164 75.4569 89.7538C75.8495 89.5912 76.2062 89.3528 76.5067 89.0524C76.8072 88.7519 77.0456 88.3951 77.2082 88.0025C77.3708 87.6099 77.4545 87.1891 77.4545 86.7642V42.6743C77.4545 42.2494 77.3708 41.8286 77.2082 41.4359C77.0456 41.0433 76.8072 40.6866 76.5067 40.3861C76.2062 40.0856 75.8495 39.8473 75.4569 39.6847C75.0643 39.522 74.6435 39.4383 74.2185 39.4383Z"
        fill="#CCCCCC"
      />
    </g>
  </svg>
);

export default AuditLogsEmptyStateIcon;
