import React, { useRef, useState } from 'react';
import { DatePicker, Button } from 'antd';
import SearchInput from 'components/Shared/Search';
import useTranslation from 'utils/hooks/useTranslation';

import styles from './style.module.scss';

const { RangePicker } = DatePicker;

const TableControl = ({ visible, onTableControlChange }) => {
  const { translateText } = useTranslation();
  const searchInputRef = useRef(null);

  const [dateRange, setDateRange] = useState([null, null]);
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = () => {
    onTableControlChange({ searchQuery, dateRange });
  };

  return (
    visible && (
      <div className={`card-body ${styles.tableControlContainer}`}>
        <SearchInput
          ref={searchInputRef}
          onSearchFunc={handleSearch}
          searchValue={''}
          placeholder={translateText('corporateAccounts.auditLogs.searchInput.placeholder')}
          showLengthRequired={true}
          onChange={value => setSearchQuery(value)}
        />
        <RangePicker
          onChange={setDateRange}
          placeholder={[
            translateText('corporateAccounts.reports.dateRange.startDate'),
            translateText('corporateAccounts.reports.dateRange.endDate'),
          ]}
        />
        <Button type="primary" size="large" onClick={handleSearch}>
          {translateText('corporateAccounts.common.search')}
        </Button>
      </div>
    )
  );
};

export default TableControl;
