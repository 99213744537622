import React, { useEffect, useState } from 'react';
import store from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { Layout } from 'antd';
import { PBP_SUPER_ADMIN } from 'constants.js';
import actions from 'redux/settings/actions';
import styles from './style.module.scss';
import CorporateClientMenu from './CorporateClientMenu';
import StaffOnlyMenu from './StaffOnlyMenu';
import StaffUserMenu from './StaffUserMenu';
import CurrentCorporationMenu from './CurrentCorporationMenu';
import MenuTrigger from './MenuTrigger';
import { getMenuItemByLocation } from './helper';
import { Logo, LogoDark, LogoMobile } from 'components/Shared/Logos';

const { Sider } = Layout;

const MenuLeft = () => {
  const [selectedKeys, setSelectedKeys] = useState(store.get('app.menu.selectedKeys') || []);
  const dispatch = useDispatch();
  const location = useLocation();

  const { corporateClientId, adminRole, currentCorporation } = useSelector(state => state.user);
  const { isMenuCollapsed, isMobileView } = useSelector(state => state.settings);
  const isLightTheme = !adminRole;

  const onCollapse = (value, type) => {
    if (type === 'responsive' && isMenuCollapsed) {
      return;
    }
    dispatch({
      type: actions.CHANGE_SETTING,
      payload: {
        setting: 'isMenuCollapsed',
        value,
      },
    });
  };

  const handleClick = e => {
    store.set('app.menu.selectedKeys', [e.key]);
    setSelectedKeys([e.key]);
  };

  const logo = () => {
    if (isMenuCollapsed) return <LogoMobile />;
    else if (isLightTheme) return <Logo />;
    else return <LogoDark />;
  };

  const menuSettings = isMobileView
    ? {
        width: 280,
        collapsible: false,
        collapsed: false,
        onCollapse,
      }
    : {
        width: 280,
        collapsible: true,
        collapsed: isMenuCollapsed,
        onCollapse,
        breakpoint: 'lg',
      };

  useEffect(() => {
    const menuItem = getMenuItemByLocation(adminRole, location);
    if (!menuItem) {
      setSelectedKeys([]);
    } else {
      store.set('app.menu.selectedKeys', [menuItem.key]);
      setSelectedKeys([menuItem.key]);
    }
  }, [adminRole, location, setSelectedKeys]);

  return (
    <Sider
      data-testid="menuLeftLayout"
      {...menuSettings}
      className={isLightTheme ? `${styles.menu} ${styles.light}` : `${styles.menu}`}
      theme={isLightTheme ? 'light' : 'dark'}
      // NEEDS TO BE A CONDITIONAL DISPLAY
      style={!corporateClientId && !adminRole ? { display: 'none' } : null}
      trigger={<MenuTrigger isMenuCollapsed={isMenuCollapsed} isLightTheme={isLightTheme} />}
    >
      <div className={isMenuCollapsed ? `${styles.logo} ${styles.logoMobile}` : styles.logo}>
        {logo()}
      </div>
      {corporateClientId && (
        <CurrentCorporationMenu
          currentCorporation={currentCorporation}
          isMenuCollapsed={isMenuCollapsed}
          isLightTheme={isLightTheme}
        />
      )}
      <Scrollbars
        className={isMobileView ? styles.scrollbarMobile : styles.scrollbarDesktop}
        renderThumbVertical={({ style, ...props }) => (
          <div
            {...props}
            style={{
              ...style,
              width: '4px',
              borderRadius: 'inherit',
              backgroundColor: '#c5cdd2',
              left: '1px',
            }}
          />
        )}
        autoHide
      >
        {corporateClientId && (
          <CorporateClientMenu
            onMenuClick={handleClick}
            selectedKeys={selectedKeys}
            theme={!isLightTheme ? 'dark' : 'light'}
            isAdmin={adminRole}
          />
        )}
        {corporateClientId && adminRole && (
          <StaffOnlyMenu
            onMenuClick={handleClick}
            selectedKeys={selectedKeys}
            theme={!isLightTheme ? 'dark' : 'light'}
            isAdmin={adminRole}
          />
        )}
        {!corporateClientId && adminRole && (
          <StaffUserMenu
            onMenuClick={handleClick}
            selectedKeys={selectedKeys}
            theme="dark"
            isSuperAdmin={adminRole === PBP_SUPER_ADMIN}
          />
        )}
      </Scrollbars>
    </Sider>
  );
};

export default withRouter(MenuLeft);
