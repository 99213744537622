import { useSelector } from 'react-redux';
import LanguageSelector from 'components/Layout/TopBar/LanguageSelector';
import SignInButton from './SignInButton';
import styles from './style.module.scss';

const HeaderMenu = () => {
  const showSignInButton = useSelector(state => state.settings.showSignInButton);
  const showLanguageSelector = useSelector(state => state.settings.showLanguageSelector);

  return (
    <section className={styles.headerGroup}>
      {showLanguageSelector && <LanguageSelector className={styles.languageSelector} />}
      {showSignInButton && <SignInButton />}
    </section>
  );
};

export default HeaderMenu;
