import actions from './actions';
import corporationActions from '../corporations/actions';
import userActions from '../user/actions';

export const INITIAL_STATE = {
  loading: false,
  data: [],
};

export default function sessionsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actions.SET_STATE:
    case actions.GET:
      return { ...state, ...action.payload };
    case corporationActions.GET:
    case userActions.LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
