import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from 'constants/tables';
import { useGetAuditLogs, useGetAuditLogChanges } from 'services/reactQueryHooks/auditLogs';
import { calculateOffset } from 'utils/paginationUtil';
import auditLogsHelper from './helper';
import searchUtil from 'utils/searchUtil';

const useAuditLogsPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [tableFilters, setTableFilters] = useState(null);
  const [orderBy, setOrderBy] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [auditLogId, setAuditLogId] = useState(null);

  const { corporateClientId } = useSelector(state => state.user);

  const { data: auditLogsData, isLoading: isAuditLogsLoading } = useGetAuditLogs({
    corporateClientId,
    pageSize,
    offset: calculateOffset(currentPage, pageSize || DEFAULT_PAGE_SIZE),
    query: searchQuery,
    orderBy,
    filters: tableFilters,
    startDate: dateRange ? dateRange[0]?.startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZZ') : null,
    endDate: dateRange ? dateRange[1]?.endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZZ') : null,
  });

  const { data: auditLogChangesData, isLoading: isAuditLogChangesLoading } = useGetAuditLogChanges({
    corporateClientId,
    auditLogId,
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
    setTableFilters(filters);
    setOrderBy(
      auditLogsHelper.createOrderString({ orderBy: sorter.field, sortOrder: sorter.order }),
    );
  };

  const handleTableControlChange = ({ searchQuery, dateRange }) => {
    setSearchQuery(searchUtil.trimValue(searchQuery));
    setDateRange(dateRange);
    setCurrentPage(1);
  };

  const isTableVisible = () => {
    const hasData = auditLogsData?.totalCount !== 0;
    const isFilterActive = auditLogsHelper.isDataFilterActive(tableFilters);
    const hasSearchQuery = searchQuery;
    const hasDateRange = dateRange?.some(date => date !== null);

    return hasData || isFilterActive || hasSearchQuery || hasDateRange;
  };

  const formatAudiLogChanges = auditLogChangesData => {
    if (!auditLogChangesData) return null;

    const [updatedEntityLog, originalEntityLog] = auditLogChangesData;
    return {
      updatedEntityLog: auditLogsHelper.parseAuditLogMessage(updatedEntityLog),
      originalEntityLog: auditLogsHelper.parseAuditLogMessage(originalEntityLog),
    };
  };

  useEffect(() => {
    if (auditLogsData?.items && !pageLoaded) {
      setPageLoaded(true);
    }
  }, [auditLogsData, pageLoaded, setPageLoaded]);

  return {
    auditLogId,
    auditLogs: auditLogsData?.items,
    auditLogChanges: formatAudiLogChanges(auditLogChangesData),
    tableFilters,
    currentPage,
    isPageLoading: !pageLoaded,
    isAuditLogsLoading,
    isAuditLogChangesLoading,
    totalCount: auditLogsData?.totalCount,
    isTableVisible: isTableVisible(),
    setAuditLogId,
    onTableChange: handleTableChange,
    onTableControlChange: handleTableControlChange,
  };
};

export default useAuditLogsPage;
