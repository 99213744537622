import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import MaintenanceNotification from './MaintenanceNotification';
import styles from './style.module.scss';

// Sunday, July 5 at 9 AM to 5 PM EST -> Example date range
const GlobalNotifications = React.forwardRef(({ onNotificationClose }, ref) => {
  useEffect(() => {
    onNotificationClose();
  }, [onNotificationClose]);

  return (
    <div
      ref={ref}
      data-testid="globalNotificationContainer"
      className={styles.globalNotificationContainer}
    >
      <MaintenanceNotification onNotificationClose={onNotificationClose} />
    </div>
  );
});

export default connect(null, null, null, { forwardRef: true })(GlobalNotifications);
