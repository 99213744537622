import React from 'react';
import { Button, Popconfirm } from 'antd';
import { ADMIN_USER_INVITE_STATUS, ADMIN_USER_ROLES } from 'constants.js';
import UtilTag from 'components/Shared/Tag';
import Can from '../../config/Can';

const tableColumns = (handleEditUser, handleDeleteUser, translateText) => {
  return [
    {
      title: translateText('corporateAccounts.admins.firstName'),
      dataIndex: 'firstName',
    },
    {
      title: translateText('corporateAccounts.admins.lastName'),
      dataIndex: 'lastName',
    },
    {
      title: translateText('corporateAccounts.admins.role'),
      dataIndex: 'role',
      render: role => {
        const adminRole = ADMIN_USER_ROLES[role];
        return adminRole ? translateText(`corporateAccounts.admins.role.${adminRole.key}`) : null;
      },
    },
    {
      title: translateText('corporateAccounts.admins.phone'),
      dataIndex: 'phone',
    },
    {
      title: translateText('corporateAccounts.admins.email'),
      dataIndex: 'email',
    },
    {
      title: translateText('corporateAccounts.common.status'),
      dataIndex: 'status',
      render: status => {
        const inviteStatus = ADMIN_USER_INVITE_STATUS[status];
        return inviteStatus ? (
          <UtilTag
            content={translateText(`corporateAccounts.common.status.${inviteStatus.key}`)}
            type={inviteStatus.key}
          />
        ) : null;
      },
    },
    {
      title: translateText('corporateAccounts.actions'),
      key: 'actions',
      render: (text, record) => (
        <>
          <Can I="update" a="AdminUser" passThrough>
            {can => (
              <Button
                data-testid={`edit:${record.email}`}
                size="small"
                style={{ marginRight: '8px' }}
                onClick={() => handleEditUser(record.id, translateText)}
                disabled={!can}
              >
                {translateText('corporateAccounts.actions.edit')}
              </Button>
            )}
          </Can>

          <Can I="destroy" a="AdminUser" passThrough>
            {can => (
              <Popconfirm
                title={translateText('corporateAccounts.admins.actions.deleteUser')}
                okText={translateText('corporateAccounts.actions.delete')}
                okType="danger"
                okButtonProps={{ 'data-testid': 'confirmDeleteUserBtn' }}
                cancelText="Cancel"
                onConfirm={() => handleDeleteUser(record.id)}
                disabled={!can}
              >
                <Button data-testid={`delete:${record.email}`} danger size="small" disabled={!can}>
                  {translateText('corporateAccounts.actions.delete')}
                </Button>
              </Popconfirm>
            )}
          </Can>
        </>
      ),
    },
  ];
};

export default tableColumns;
