const serviceAdmin = {
  adminUsers: 'read',
  vehicle: ['create', 'destroy', 'edit', 'read', 'upload', 'export'],
  driver: {
    can: ['activate', 'create', 'deactivate', 'destroy', 'edit', 'upload', 'export'],
    cannot: ['read_all_columns'],
  },
  restrictedDriver: ['create', 'edit', 'upload'],
  vehicleForm: ['read_vehicle_location', 'read_vehicle_name', 'read_vehicle_type'],
  paymentSpa: {
    cannot: ['create', 'edit', 'destroy'],
  },
  billing: {
    cannot: ['edit'],
  },
  RestrictedDriverUpload: {
    can: ['bulkDriverUpload'],
  },
};

export default serviceAdmin;
