import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Typography } from 'antd';
import useAmplitude from 'utils/hooks/useAmplitude';
import { SuccessIcon } from 'components/Shared/Icons';
import styles from './style.module.scss';
import { COUNTRIES } from 'constants.js';

const { Title, Text } = Typography;

const ProgressStepCard = ({
  title,
  description,
  url,
  amplitudeEvent,
  icon,
  status,
  linkText,
  isComplete,
  isActive,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const history = useHistory();
  const { sendAmplitudeEvent } = useAmplitude();
  const locale = useSelector(state => state.settings.locale);

  const TEXT_COMPLETED_STATE_COLOR = '#4D4D4D';
  const TEXT_INCOMPLETE_STATE_COLOR = '#9B9B9B';
  const TEXT_HOVER_STATE_COLOR = '#6cc24a';

  const BACKGROUND_COMPLETED_STATE_COLOR = '#f5f5f5'; //$sand
  const BACKGROUND_INCOMPLETE_STATE_COLOR = '#fff'; //$white
  const BACKGROUND_ACTIVE_STATE_COLOR = '#f9fdf8';

  const BORDER_COMPLETED_STATE_COLOR = '#c4c4c4'; //$silver
  const BORDER_INCOMPLETE_STATE_COLOR = '#c4c4c4'; //$silver
  const BORDER_ACTIVE_STATE_COLOR = '#6cc24a';

  const CardIcon = ({ Icon, Color }) => {
    return Icon ? <Icon color={Color} /> : null;
  };

  const handleClick = () => {
    if (isComplete) {
      return;
    }

    sendAmplitudeEvent(amplitudeEvent);
    history.push(url);
  };

  const handleMouseOver = () => {
    if (!isActive && !isComplete) {
      setIsHovered(true);
    }
  };

  const handleMouseOut = () => {
    if (!isActive && !isComplete) {
      setIsHovered(false);
    }
  };

  const CardContent = () => (
    <div
      style={{
        borderBottom: isComplete
          ? `1px solid ${BORDER_COMPLETED_STATE_COLOR}`
          : isActive
          ? `2px solid ${BORDER_ACTIVE_STATE_COLOR}`
          : `2px solid ${BORDER_INCOMPLETE_STATE_COLOR}`,
      }}
      className={styles.stepCardContent}
    >
      <div className={styles.stepCardIcon}>
        <CardIcon
          Icon={icon}
          Color={`${isComplete ? TEXT_INCOMPLETE_STATE_COLOR : TEXT_COMPLETED_STATE_COLOR}`}
        />
      </div>
      <div
        className={
          locale === COUNTRIES.US.locale || locale === COUNTRIES.GB.locale
            ? styles.stepCardInfoEnglish
            : styles.stepCardInfo
        }
      >
        <Title
          level={5}
          style={{
            textAlign: 'left',
            color: `${isComplete ? TEXT_INCOMPLETE_STATE_COLOR : TEXT_COMPLETED_STATE_COLOR}`,
          }}
        >
          {title}
        </Title>
        <Text
          style={{
            color: `${isComplete ? TEXT_INCOMPLETE_STATE_COLOR : TEXT_COMPLETED_STATE_COLOR}`,
          }}
        >
          <p>{description}</p>
        </Text>
      </div>
    </div>
  );

  const CardStatus = () => (
    <div
      className={styles.stepCardStatus}
      style={
        isHovered && !isComplete && !isActive
          ? {
              marginLeft: '0px',
              borderLeft: `8px solid ${BORDER_ACTIVE_STATE_COLOR}`,
              borderRadius: '0 0 0 5px',
            }
          : null
      }
    >
      <div className={styles.stepCardStatusIcon}>
        {!isHovered && (
          <SuccessIcon color={`${isComplete ? '#6CC24A' : TEXT_INCOMPLETE_STATE_COLOR}`} />
        )}
      </div>
      <div className={styles.stepCardStatus}>
        <Text>
          <p
            style={{
              color: isComplete
                ? TEXT_COMPLETED_STATE_COLOR
                : isHovered
                ? TEXT_HOVER_STATE_COLOR
                : TEXT_INCOMPLETE_STATE_COLOR,
            }}
          >
            {isHovered && !isComplete && !isActive ? linkText : status}
          </p>
        </Text>
      </div>
    </div>
  );

  return (
    <div
      data-testid={url}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={handleClick}
      className={styles.stepCardContainer}
      style={{
        cursor: isHovered ? 'pointer' : 'default',
        backgroundColor: isComplete
          ? BACKGROUND_COMPLETED_STATE_COLOR
          : isActive
          ? BACKGROUND_ACTIVE_STATE_COLOR
          : BACKGROUND_INCOMPLETE_STATE_COLOR,
        border: isComplete
          ? `1px solid ${BORDER_COMPLETED_STATE_COLOR}`
          : isActive
          ? `2px solid ${BORDER_ACTIVE_STATE_COLOR}`
          : `2px solid ${BORDER_INCOMPLETE_STATE_COLOR}`,
      }}
    >
      <CardContent />
      <CardStatus />
    </div>
  );
};

export default ProgressStepCard;
