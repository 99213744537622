import React from 'react';

const VehiclesIcon = () => (
  <svg
    width="394"
    height="206"
    viewBox="0 0 394 206"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="vehiclesIcon"
  >
    <g clipPath="url(#clip0_1137_10943)">
      <path
        d="M120.165 124.637C118.411 124.639 116.73 125.336 115.49 126.576C114.25 127.816 113.553 129.497 113.551 131.251V146.724C113.553 148.477 114.25 150.158 115.49 151.398C116.73 152.638 118.411 153.336 120.165 153.338H128.553C130.307 153.336 131.988 152.638 133.228 151.398C134.468 150.158 135.165 148.477 135.167 146.724V131.251C135.165 129.497 134.468 127.816 133.228 126.576C131.988 125.336 130.307 124.639 128.553 124.637H120.165Z"
        fill="black"
      />
      <path
        d="M275.236 153.852H266.847C264.957 153.85 263.145 153.098 261.809 151.762C260.473 150.426 259.721 148.614 259.719 146.724V131.251C259.721 129.361 260.473 127.549 261.809 126.212C263.145 124.876 264.957 124.124 266.847 124.122H275.236C277.126 124.124 278.938 124.876 280.274 126.212C281.611 127.549 282.362 129.361 282.364 131.251V146.724C282.362 148.614 281.611 150.426 280.274 151.762C278.938 153.098 277.126 153.85 275.236 153.852Z"
        fill="black"
      />
      <path
        d="M269.669 141.464H125.216C118.641 141.464 113.293 137.588 113.293 132.825V105.047L120.463 97.4961H275.816L281.591 105.063V132.825C281.591 137.588 276.243 141.464 269.669 141.464Z"
        fill="#86BE56"
      />
      <path
        d="M275.143 111.392H121.288L119.725 87.4174L120.72 84.9641L126.503 70.7325H268.887L276.707 86.8934L275.143 111.392Z"
        fill="#86BE56"
      />
      <path
        d="M276.447 86.9448L275.299 104.958H241.449V91.5766L275.485 84.958L276.447 86.9448Z"
        fill="white"
      />
      <path
        d="M154.468 91.5761V104.958H121.127L119.984 87.4587L120.957 85.0605L154.468 91.5761Z"
        fill="white"
      />
      <path
        d="M268.029 74.8499H127.359L142.363 24.8386C142.975 22.7732 144.235 20.9594 145.957 19.6652C147.679 18.3711 149.772 17.6654 151.926 17.6523L245.087 16.765C247.338 16.7431 249.531 17.4752 251.318 18.8446C253.105 20.214 254.382 22.1419 254.946 24.3212L268.029 74.8499Z"
        fill="#86BE56"
      />
      <path
        d="M257.2 70.121L138.165 70.0051C138.015 70.0033 137.867 69.9662 137.733 69.8968C137.6 69.8274 137.484 69.7277 137.396 69.6056C137.308 69.4835 137.25 69.3424 137.227 69.1938C137.203 69.0452 137.215 68.8931 137.261 68.7497L148.672 31.8509C148.734 31.6568 148.856 31.4875 149.021 31.3674C149.186 31.2473 149.384 31.1826 149.588 31.1826H245.33C245.534 31.1826 245.732 31.2473 245.897 31.3673C246.061 31.4873 246.183 31.6565 246.246 31.8505L258.127 68.8652C258.174 69.0104 258.185 69.1646 258.16 69.315C258.135 69.4654 258.075 69.6079 257.985 69.7305C257.894 69.8532 257.776 69.9525 257.639 70.0204C257.503 70.0883 257.352 70.1228 257.2 70.121Z"
        fill="white"
      />
      <mask
        id="mask0_1137_10943"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="204"
        y="31"
        width="45"
        height="39"
      >
        <rect x="204.125" y="31.0938" width="44.6981" height="38.8679" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1137_10943)">
        <path
          opacity="0.2"
          d="M214.72 68.1565L212.258 78.0065L215.992 91.9838L213.584 78.7642L214.72 68.1565Z"
          fill="#949494"
        />
        <circle cx="225.502" cy="76.4396" r="13.6038" stroke="black" strokeWidth="3.88679" />
      </g>
      <path
        d="M127.449 73.8208H106.348V69.1827C106.349 67.6833 106.946 66.2459 108.006 65.1856C109.066 64.1254 110.504 63.529 112.003 63.5273H127.449V73.8208Z"
        fill="#86BE56"
      />
      <path
        d="M289.055 73.8208H267.953V63.5273H283.399C284.899 63.529 286.336 64.1254 287.396 65.1856C288.457 66.2459 289.053 67.6833 289.055 69.1827V73.8208Z"
        fill="#86BE56"
      />
      <path
        d="M103.879 146.122L0.71875 183.181L1.15667 184.4L104.317 147.341L103.879 146.122Z"
        fill="#4D4D4D"
      />
      <path
        d="M290.873 146.125L290.436 147.344L393.599 184.393L394.037 183.174L290.873 146.125Z"
        fill="#4D4D4D"
      />
      <path
        d="M203.433 172.995H197.258V165.02H203.433V172.995ZM203.433 157.044H197.258V149.068H203.433V157.044Z"
        fill="#E6E6E6"
      />
      <path
        d="M203.433 206.032H197.258V198.057H203.433V206.032ZM203.433 190.081H197.258V182.105H203.433V190.081Z"
        fill="#E6E6E6"
      />
    </g>
    <defs>
      <clipPath id="clip0_1137_10943">
        <rect width="392.566" height="206" fill="white" transform="translate(0.716797)" />
      </clipPath>
    </defs>
  </svg>
);

export default VehiclesIcon;
