import { useEffect, useMemo, useState, useCallback } from 'react';
import {
  useGetDriverByRegistrationCode,
  useActivateDriver,
  useRegisterDriver,
} from 'services/reactQueryHooks/drivers';
import { getOnboardingErrorMessage } from './helper';
import useAppleSignIn from 'utils/hooks/useAppleSignIn.js';
import useAmplitude from 'utils/hooks/useAmplitude';
import {
  DRIVER_ONBOARDING_ERRORS,
  DRIVER_ONBOARDING_ERRORS_AMPLITUDE_MAP,
} from 'constants/drivers';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import { COUNTRIES } from 'constants.js';
import {
  ONBOARDING_STATUS,
  DRIVER_ONBOARDING_INFO,
  SOCIAL_AUTH_INFO,
  APPLE_SOCIAL_AUTH,
  FACEBOOK_SOCIAL_AUTH,
} from 'constants/onboarding';

const useOnboardingPage = () => {
  const [onBoardingStatus, setOnboardingStatus] = useState(null);
  const [registrationCode, setRegistrationCode] = useState(null);
  const [facebookSubjectToken, setFacebookSubjectToken] = useState(null);

  const { sendAmplitudeEvent } = useAmplitude();

  const defaultErrorMessage = 'InternalError';
  const activationDefaultErrorMessage = 'ActivationFailed';

  const { isAppleSignInLoading, appleSubjectToken, renderAppleSignInButton } = useAppleSignIn();

  const {
    data: driverInfo,
    isLoading: isDriverLoading,
    isSuccess: isDriverLoadingSuccessful,
    isError: isDriverLoadingError,
    error: driverLoadingServerErrors,
  } = useGetDriverByRegistrationCode({
    query: registrationCode,
  });

  const {
    mutate: activateDriver,
    data: activatedDriverCorporation,
    isLoading: isActivationLoading,
    isSuccess: isActivationSuccessful,
    isError: isActivationFailed,
    error: activationServerError,
  } = useActivateDriver();

  const {
    mutate: registerDriver,
    isLoading: isRegistrationLoading,
    isSuccess: isRegistrationSuccessful,
    isError: isRegistrationFailed,
    error: registrationServerError,
  } = useRegisterDriver(driverInfo?.corporateClientName);

  const enableFacebookSignIn = driverInfo?.country === COUNTRIES.CA.countryCode ? false : true;

  const activationError = useMemo(() => {
    if (!isActivationFailed) return null;
    const errorMessage =
      getOnboardingErrorMessage(activationServerError?.errors) ?? activationDefaultErrorMessage;

    sendAmplitudeEvent(
      DRIVER_ONBOARDING_ERRORS_AMPLITUDE_MAP[DRIVER_ONBOARDING_ERRORS[errorMessage]],
    );

    return errorMessage;
  }, [activationServerError, isActivationFailed, sendAmplitudeEvent]);

  const registrationError = useMemo(() => {
    if (!isRegistrationFailed) return null;
    const errorMessage =
      getOnboardingErrorMessage(registrationServerError?.errors) ?? defaultErrorMessage;

    sendAmplitudeEvent(
      DRIVER_ONBOARDING_ERRORS_AMPLITUDE_MAP[DRIVER_ONBOARDING_ERRORS[errorMessage]],
    );

    return errorMessage;
  }, [isRegistrationFailed, registrationServerError, sendAmplitudeEvent]);

  const driverLoadingErrors = useMemo(() => {
    if (!isDriverLoadingError) return null;
    const errorMessage =
      getOnboardingErrorMessage(driverLoadingServerErrors?.errors) ?? defaultErrorMessage;

    sendAmplitudeEvent(
      DRIVER_ONBOARDING_ERRORS_AMPLITUDE_MAP[DRIVER_ONBOARDING_ERRORS[errorMessage]],
    );

    return errorMessage;
  }, [driverLoadingServerErrors, isDriverLoadingError, sendAmplitudeEvent]);

  const driverAlreadyActive = useMemo(() => {
    if (!isDriverLoadingError) return false;

    const errorMessage = getOnboardingErrorMessage(driverLoadingServerErrors?.errors);
    return errorMessage === DRIVER_ONBOARDING_ERRORS.DriverAlreadyActive;
  }, [driverLoadingServerErrors, isDriverLoadingError]);

  const isPageLoading = useMemo(() => {
    isAppleSignInLoading &&
      sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.onboardingDriverAppleSignInButton);

    return isActivationLoading || isDriverLoading || isRegistrationLoading || isAppleSignInLoading
      ? true
      : false;
  }, [
    isActivationLoading,
    isAppleSignInLoading,
    isDriverLoading,
    isRegistrationLoading,
    sendAmplitudeEvent,
  ]);

  const handleRegistration = useCallback(
    ({
      password = null,
      subjectIssuer = null,
      subjectTokenType = null,
      subjectToken = null,
      recaptchaToken,
    }) => {
      registerDriver({
        [DRIVER_ONBOARDING_INFO.InvitationCode]: registrationCode,
        [DRIVER_ONBOARDING_INFO.Password]: password,
        [SOCIAL_AUTH_INFO.SubjectIssuer]: subjectIssuer,
        [SOCIAL_AUTH_INFO.SubjectTokenType]: subjectTokenType,
        [SOCIAL_AUTH_INFO.SubjectToken]: subjectToken,
        recaptchaToken,
      });
    },
    [registerDriver, registrationCode],
  );

  const handleInitialLoading = useCallback(
    ({ queryParams, hashParams }) => {
      const activationCodeFromUrl = queryParams?.a;
      const facebookAccessTokenFromUrl = hashParams?.access_token;
      const registrationCodeFromUrl = hashParams?.state || queryParams?.r;

      if (facebookAccessTokenFromUrl) {
        setOnboardingStatus(ONBOARDING_STATUS.registration);
        setRegistrationCode(registrationCodeFromUrl);
        setFacebookSubjectToken(facebookAccessTokenFromUrl);
      } else if (activationCodeFromUrl) {
        setOnboardingStatus(ONBOARDING_STATUS.activation);
        activateDriver({ [DRIVER_ONBOARDING_INFO.InvitationCode]: activationCodeFromUrl });
      } else if (registrationCodeFromUrl) {
        setOnboardingStatus(ONBOARDING_STATUS.registration);
        setRegistrationCode(registrationCodeFromUrl);
      }
    },
    [activateDriver, setRegistrationCode],
  );

  useEffect(() => {
    appleSubjectToken &&
      handleRegistration({
        subjectIssuer: APPLE_SOCIAL_AUTH.Issuer,
        subjectTokenType: APPLE_SOCIAL_AUTH.SubjectTokenType,
        subjectToken: appleSubjectToken,
      });

    if (facebookSubjectToken) {
      handleRegistration({
        subjectIssuer: FACEBOOK_SOCIAL_AUTH.Issuer,
        subjectTokenType: FACEBOOK_SOCIAL_AUTH.SubjectTokenType,
        subjectToken: facebookSubjectToken,
      });

      setFacebookSubjectToken(null);
    }

    if (driverInfo?.invitationType === 'Activation') {
      if (registrationCode) {
        setOnboardingStatus(ONBOARDING_STATUS.activation);
        activateDriver({ [DRIVER_ONBOARDING_INFO.InvitationCode]: registrationCode });
      }
    }
  }, [
    appleSubjectToken,
    facebookSubjectToken,
    handleRegistration,
    driverInfo,
    registrationCode,
    activateDriver,
  ]);

  return {
    isActivationSuccessful,
    isRegistrationSuccessful,
    isDriverLoadingSuccessful,
    isActivationFailed,
    isDriverLoading,
    isPageLoading,
    onBoardingStatus,
    activationError,
    registrationError,
    driverLoadingErrors,
    driverAlreadyActive,
    activatedDriverCorporation,
    driverInfo,
    enableFacebookSignIn,
    registrationCode,
    renderAppleSignInButton,
    onRegister: handleRegistration,
    onPageLoad: handleInitialLoading,
  };
};

export default useOnboardingPage;
