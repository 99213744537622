import { Modal } from 'antd';
import React, { useState } from 'react';
import { useCreateStaffUser, useUpdateStaffUser } from 'services/reactQueryHooks/staffUsers';
import { STAFF_USER_ROLES } from 'constants/staffUsers';

const useStaffUserForm = () => {
  const [formState, setFormState] = useState({ formVisibility: false, staffUserBeingEdited: null });

  const showCreateStaffUserForm = () => {
    setFormState({ formVisibility: true, staffUserBeingEdited: null });
  };
  const showEditStaffUserForm = staffUser => {
    setFormState({ formVisibility: true, staffUserBeingEdited: staffUser });
  };
  const closeStaffUserForm = () => {
    setFormState({ ...formState, formVisibility: false });
  };
  const { mutate: createStaffUser, isLoading: isCreatingStaffUser } = useCreateStaffUser({
    onSuccess: closeStaffUserForm,
  });
  const { mutate: updateStaffUser, isLoading: isUpdatingStaffUser } = useUpdateStaffUser({
    onSuccess: closeStaffUserForm,
  });

  const submit = staffUser => {
    const name = `${staffUser.firstName} ${staffUser.lastName}`;
    const { staffUserBeingEdited } = formState;

    if (!staffUserBeingEdited) {
      createStaffUser({ payload: staffUser });
      return;
    }

    if (staffUserBeingEdited.role !== staffUser.role) {
      const oldRole = STAFF_USER_ROLES[staffUserBeingEdited.role].label;
      const newRole = STAFF_USER_ROLES[staffUser.role].label;

      Modal.warning({
        title: 'Update Role?',
        content: (
          <a>
            Are you sure you want to update the role of <b>{name}</b> from <b>{oldRole}</b> to{' '}
            <b>{newRole}</b>?
          </a>
        ),
        okText: 'Update',
        okButtonProps: {
          'data-testid': `confirmUpdate:${staffUser.id}`,
          type: 'danger',
        },
        cancelText: 'Cancel',
        okCancel: true,
        autoFocusButton: null,
        onOk: () => {
          updateStaffUser({ payload: staffUser });
        },
      });
    } else {
      updateStaffUser({ payload: staffUser });
    }
  };

  return {
    onShowCreate: showCreateStaffUserForm,
    onShowEdit: showEditStaffUserForm,
    onClose: closeStaffUserForm,
    onSubmit: submit,
    isLoading: isCreatingStaffUser || isUpdatingStaffUser,
    ...formState,
  };
};

export default useStaffUserForm;
