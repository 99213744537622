import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Icon, { UserOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Avatar, Typography } from 'antd';
import {
  ADMIN_USER_ROLES,
  ROUTE_CORPORATIONS,
  ROUTE_CORPORATIONS_ADMIN,
  ROUTE_DEFAULT,
} from 'constants.js';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import { HomeIcon, ChangeIcon, ChangeCorporationIcon, LogoutIcon } from 'components/Shared/Icons';
import useTranslation from 'utils/hooks/useTranslation';
import useAmplitude from 'utils/hooks/useAmplitude';
import { convertCorporateClientNameToShortName } from 'utils/corporateClientUtil';
import styles from './style.module.scss';
import topBarStyles from '../style.module.scss';

const ProfileMenu = ({ user, logout }) => {
  const { sendAmplitudeEvent, sendAmplitudeEventAsync } = useAmplitude();
  const { translateText } = useTranslation();

  const renderHome = () => {
    const { currentCorporation } = user;
    const home = (
      <Menu.Item key="home">
        <Link to={currentCorporation ? ROUTE_DEFAULT : ROUTE_CORPORATIONS_ADMIN}>
          <Icon component={HomeIcon} className={topBarStyles.menuIcon} />
          <Typography.Text>
            {translateText('corporateAccounts.topBar.profileMenu.home')}
          </Typography.Text>
        </Link>
      </Menu.Item>
    );
    if (currentCorporation) return home;
    return null;
  };

  const renderSwitchAccounts = () => {
    const { adminRole, corporations } = user;
    const hasMultipleCorporations = corporations && corporations.length > 1;
    const adminSwitchAccounts = (
      <Menu.Item key="switchAccounts">
        <Link to={ROUTE_CORPORATIONS_ADMIN}>
          <Icon component={ChangeIcon} className={topBarStyles.menuIcon} />
          {translateText('corporateAccounts.topBar.profileMenu.changeCorporations')}
        </Link>
      </Menu.Item>
    );

    const switchAccounts = (
      <Menu.Item key="switchAccounts">
        <Link to={ROUTE_CORPORATIONS}>
          <Icon component={ChangeCorporationIcon} className={topBarStyles.menuIcon} />
          {translateText('corporateAccounts.topBar.profileMenu.changeCorporations')}
        </Link>
      </Menu.Item>
    );

    if (adminRole && hasMultipleCorporations) return adminSwitchAccounts;
    if (hasMultipleCorporations) return switchAccounts;
    return null;
  };

  const renderFirstName = () => {
    const { adminRole, corporationRole, firstName } = user;
    const getfirstName =
      adminRole && corporationRole ? ADMIN_USER_ROLES[corporationRole].label : firstName;
    return (
      <Typography.Text strong>
        {translateText('corporateAccounts.topBar.profileMenu.hello')}, {getfirstName}
      </Typography.Text>
    );
  };

  const renderCorporationName = () => {
    const { currentCorporation } = user;
    const currentCorporationName =
      currentCorporation &&
      convertCorporateClientNameToShortName(currentCorporation.corporateClientName);

    return (
      <Menu.Item key="corporation">
        <Typography.Text strong>
          {translateText('corporateAccounts.topBar.profileMenu.account')}:{' '}
        </Typography.Text>
        {currentCorporationName || 'N/A'}
      </Menu.Item>
    );
  };

  const handleClick = async ({ key }) => {
    if (key === 'logout') {
      await sendAmplitudeEventAsync(AMPLITUDE_EVENT_TYPES.logout);
      logout();
    } else if (key === 'switchAccounts') {
      sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.switchAccounts);
    }
  };

  const buildMenu = (
    <Menu selectable={false} onClick={handleClick}>
      {/* User First Name */}
      <Menu.Item key="firstName">{renderFirstName()}</Menu.Item>

      {/* Corporation Short Name */}
      {renderCorporationName()}

      {/* User Role */}
      <Menu.Item key="role">
        <Typography.Text strong>
          {translateText('corporateAccounts.topBar.profileMenu.role')}:{' '}
        </Typography.Text>
        {(user.corporationRole && ADMIN_USER_ROLES[user.corporationRole].label) || 'N/A'}
      </Menu.Item>
      <Menu.Divider />

      {/* Home */}
      {renderHome()}
      {user.currentCorporation && <Menu.Divider />}

      {/* Switch Accounts */}
      {renderSwitchAccounts()}
      {(user.adminRole || user.corporations.length > 1) && <Menu.Divider />}

      <Menu.Item key="logout">
        <Icon component={LogoutIcon} className={topBarStyles.menuIcon} />
        <Typography.Text>
          {translateText('corporateAccounts.topBar.profileMenu.signOut')}
        </Typography.Text>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={buildMenu} trigger={['click']}>
      <div className={topBarStyles.dropdown} data-testid="profileMenu">
        <Avatar className={styles.avatar} shape="square" size="large" icon={<UserOutlined />} />
      </div>
    </Dropdown>
  );
};

export default connect(
  ({ user }) => ({ user }),
  dispatch => ({
    logout: () => dispatch({ type: 'user/LOGOUT' }),
  }),
)(withRouter(ProfileMenu));
