import React from 'react';
import { Divider, Typography } from 'antd';

const Roles = ({ translateText }) => {
  const roleDescriptions = [
    {
      name: translateText('corporateAccounts.admins.role.primary_fleet_manager'),
      description: translateText('corporateAccounts.admins.role.primary_fleet_manager.description'),
    },
    {
      name: translateText('corporateAccounts.admins.role.fleet_manager'),
      description: translateText('corporateAccounts.admins.role.fleet_manager.description'),
    },
    {
      name: translateText('corporateAccounts.admins.role.service_admin'),
      description: translateText('corporateAccounts.admins.role.service_admin.description'),
    },
    {
      name: translateText('corporateAccounts.admins.role.reporting'),
      description: translateText('corporateAccounts.admins.role.reporting.description'),
    },
  ];

  const buildRolesDescriptions = () => {
    const renderDivider = i => (roleDescriptions[i + 1] ? <Divider /> : null);
    const descriptions = roleDescriptions.map((role, i) => {
      return (
        <div key={Math.random()}>
          <Typography.Paragraph strong>{role.name} </Typography.Paragraph>
          <Typography.Paragraph>{role.description} </Typography.Paragraph>
          {renderDivider(i)}
        </div>
      );
    });

    return descriptions;
  };

  return <>{buildRolesDescriptions()}</>;
};

export default Roles;
