import React from 'react';

const VerificationFailedIcon = () => (
  <svg
    width="121"
    height="120"
    viewBox="0 0 121 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M60.5 120C93.6371 120 120.5 93.1371 120.5 60C120.5 26.8629 93.6371 0 60.5 0C27.3629 0 0.5 26.8629 0.5 60C0.5 93.1371 27.3629 120 60.5 120Z"
      fill="#F0645E"
    />
    <path
      d="M92.9336 73.9999L66.2669 27.3333C65.6855 26.3073 64.8423 25.4539 63.8233 24.8602C62.8044 24.2664 61.6462 23.9536 60.4669 23.9536C59.2876 23.9536 58.1294 24.2664 57.1105 24.8602C56.0916 25.4539 55.2484 26.3073 54.6669 27.3333L28.0003 73.9999C27.4125 75.0178 27.1043 76.1729 27.1069 77.3483C27.1096 78.5237 27.4228 79.6774 28.0151 80.6927C28.6073 81.7079 29.4574 82.5486 30.4792 83.1294C31.5011 83.7102 32.6583 84.0105 33.8336 83.9999H87.1669C88.3366 83.9987 89.4853 83.6898 90.4979 83.1042C91.5104 82.5186 92.351 81.6769 92.9353 80.6636C93.5196 79.6504 93.8271 78.5012 93.8268 77.3316C93.8264 76.1619 93.5184 75.0129 92.9336 73.9999Z"
      stroke="white"
      strokeWidth="6.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.5 44V57.3333"
      stroke="white"
      strokeWidth="6.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.5 70.6666H60.5333"
      stroke="white"
      strokeWidth="6.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default VerificationFailedIcon;
