import React from 'react';

const DriverOnboardingInfo2 = () => (
  <svg
    width="244"
    height="304"
    viewBox="0 0 244 304"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="driverOnboardingInfo2"
  >
    <path
      d="M242 303H243V302V66.4V66.3515C243 55.0025 243 46.4263 242.434 39.6065C241.865 32.7649 240.722 27.6013 238.384 23.1C234.494 15.6117 228.388 9.50595 220.9 5.61605C216.399 3.27781 211.235 2.13452 204.394 1.56636C197.574 0.999998 188.997 0.999999 177.648 1H177.6H66.4H66.3518C55.0027 0.999999 46.4264 0.999998 39.6065 1.56636C32.7649 2.13452 27.6013 3.27781 23.1 5.61605C15.6117 9.50595 9.50595 15.6117 5.61605 23.1C3.27781 27.6013 2.13452 32.7649 1.56636 39.6065C0.999998 46.4264 0.999999 55.0027 1 66.3518V66.4V302V303H2H242Z"
      fill="#F5F5F5"
    />
    <path
      d="M242 303H243V302V66.4V66.3515C243 55.0025 243 46.4263 242.434 39.6065C241.865 32.7649 240.722 27.6013 238.384 23.1C234.494 15.6117 228.388 9.50595 220.9 5.61605C216.399 3.27781 211.235 2.13452 204.394 1.56636C197.574 0.999998 188.997 0.999999 177.648 1H177.6H66.4H66.3518C55.0027 0.999999 46.4264 0.999998 39.6065 1.56636C32.7649 2.13452 27.6013 3.27781 23.1 5.61605C15.6117 9.50595 9.50595 15.6117 5.61605 23.1C3.27781 27.6013 2.13452 32.7649 1.56636 39.6065C0.999998 46.4264 0.999999 55.0027 1 66.3518V66.4V302V303H2H242Z"
      stroke="#E9E9E9"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 65.5H68.35V77.525H74.7461L81.9999 88.25V98H78.0833C77.833 101.632 74.8077 104.5 71.1125 104.5C67.4173 104.5 64.392 101.632 64.1417 98H48.8333C48.583 101.632 45.5577 104.5 41.8625 104.5C38.1673 104.5 35.142 101.632 34.8917 98H30V65.5ZM64.125 93.775H47.7675C46.5285 91.8216 44.3469 90.525 41.8625 90.525C39.3781 90.525 37.1965 91.8216 35.9575 93.775H34.225V69.725H64.125V93.775ZM76.1564 88.25L72.3539 81.75H68.35V88.25H76.1564ZM41.8625 94.75C40.3368 94.75 39.1 95.9868 39.1 97.5125C39.1 99.0382 40.3368 100.275 41.8625 100.275C43.3882 100.275 44.625 99.0382 44.625 97.5125C44.625 95.9868 43.3882 94.75 41.8625 94.75ZM68.35 97.5125C68.35 95.9868 69.5868 94.75 71.1125 94.75C72.6382 94.75 73.875 95.9868 73.875 97.5125C73.875 99.0382 72.6382 100.275 71.1125 100.275C69.5868 100.275 68.35 99.0382 68.35 97.5125Z"
      fill="#4D4D4D"
    />
    <rect x="52" y="44" width="30" height="30" rx="15" fill="white" />
    <path
      d="M67 45.875C59.752 45.875 53.875 51.752 53.875 59C53.875 66.248 59.752 72.125 67 72.125C74.248 72.125 80.125 66.248 80.125 59C80.125 51.752 74.248 45.875 67 45.875ZM72.6689 54.7139L66.499 63.2686C66.4128 63.3889 66.2991 63.487 66.1674 63.5546C66.0357 63.6223 65.8898 63.6576 65.7417 63.6576C65.5936 63.6576 65.4477 63.6223 65.316 63.5546C65.1843 63.487 65.0706 63.3889 64.9844 63.2686L61.3311 58.2061C61.2197 58.0508 61.3311 57.834 61.5215 57.834H62.8955C63.1943 57.834 63.4785 57.9775 63.6543 58.2236L65.7402 61.1182L70.3457 54.7314C70.5215 54.4883 70.8027 54.3418 71.1045 54.3418H72.4785C72.6689 54.3418 72.7803 54.5586 72.6689 54.7139Z"
      fill="#6CC24A"
    />
    <path
      d="M83.4221 91H168C182.359 91 194 102.641 194 117V134V135C194 149.359 182.359 161 168 161H80C65.6406 161 54 172.641 54 187V205C54 219.359 65.6406 231 80 231H156.19"
      stroke="#4D4D4D"
      strokeWidth="5"
      strokeDasharray="7 7"
    />
    <rect width="32" height="32" transform="translate(115 145)" fill="#F5F5F5" />
    <g clipPath="url(#clip0_9978_116244)">
      <circle cx="131" cy="161" r="14" stroke="#4D4D4D" strokeWidth="4" />
      <path
        d="M126.578 168V153.455H132.586C133.675 153.455 134.615 153.668 135.406 154.094C136.201 154.515 136.814 155.105 137.245 155.862C137.676 156.615 137.892 157.491 137.892 158.49C137.892 159.494 137.671 160.372 137.231 161.125C136.795 161.873 136.173 162.453 135.363 162.865C134.553 163.277 133.592 163.483 132.48 163.483H128.772V160.713H131.826C132.357 160.713 132.799 160.621 133.154 160.436C133.514 160.251 133.786 159.993 133.971 159.662C134.156 159.326 134.248 158.935 134.248 158.49C134.248 158.04 134.156 157.652 133.971 157.325C133.786 156.994 133.514 156.738 133.154 156.558C132.794 156.378 132.352 156.288 131.826 156.288H130.093V168H126.578Z"
        fill="#4D4D4D"
      />
    </g>
    <g clipPath="url(#clip1_9978_116244)">
      <path
        d="M215.643 210.561H167.357C166.33 210.561 165.5 211.391 165.5 212.418V249.561C165.5 250.588 166.33 251.418 167.357 251.418H215.643C216.67 251.418 217.5 250.588 217.5 249.561V212.418C217.5 211.391 216.67 210.561 215.643 210.561ZM169.679 214.739H213.321V221.704H169.679V214.739ZM213.321 247.239H169.679V226.811H213.321V247.239ZM199.567 243.525H209.143C209.398 243.525 209.607 243.316 209.607 243.061V238.882C209.607 238.627 209.398 238.418 209.143 238.418H199.567C199.312 238.418 199.103 238.627 199.103 238.882V243.061C199.103 243.316 199.312 243.525 199.567 243.525Z"
        fill="#4D4D4D"
      />
    </g>
    <path
      d="M215.5 190.875C208.252 190.875 202.375 196.752 202.375 204C202.375 211.248 208.252 217.125 215.5 217.125C222.748 217.125 228.625 211.248 228.625 204C228.625 196.752 222.748 190.875 215.5 190.875ZM221.169 199.714L214.999 208.269C214.913 208.389 214.799 208.487 214.667 208.555C214.536 208.622 214.39 208.658 214.242 208.658C214.094 208.658 213.948 208.622 213.816 208.555C213.684 208.487 213.571 208.389 213.484 208.269L209.831 203.206C209.72 203.051 209.831 202.834 210.021 202.834H211.396C211.694 202.834 211.979 202.978 212.154 203.224L214.24 206.118L218.846 199.731C219.021 199.488 219.303 199.342 219.604 199.342H220.979C221.169 199.342 221.28 199.559 221.169 199.714Z"
      fill="#6CC24A"
    />
    <rect x="102" y="10" width="40" height="16" rx="8" fill="#9B9B9B" />
    <defs>
      <clipPath id="clip0_9978_116244">
        <rect width="32" height="32" fill="white" transform="translate(115 145)" />
      </clipPath>
      <clipPath id="clip1_9978_116244">
        <rect width="52" height="52" fill="white" transform="translate(165.5 205)" />
      </clipPath>
    </defs>
  </svg>
);

export default DriverOnboardingInfo2;
