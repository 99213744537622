import React from 'react';
import { Typography } from 'antd';
import { OctagonErrorIcon } from 'components/Shared/Icons';
import useTranslation from 'utils/hooks/useTranslation';
import {
  DRIVER_ONBOARDING_ERRORS,
  DRIVER_ONBOARDING_ERRORS_TRANSLATION_MAP,
} from 'constants/drivers';
import styles from './style.module.scss';

const OnboardingFailure = ({ error }) => {
  const { translateText } = useTranslation();

  const errorTitle = translateText(
    DRIVER_ONBOARDING_ERRORS_TRANSLATION_MAP[DRIVER_ONBOARDING_ERRORS[error]],
  );
  const errorMessage = translateText(
    `corporateAccounts.onboarding.${DRIVER_ONBOARDING_ERRORS[error].charAt(0).toLowerCase() +
      DRIVER_ONBOARDING_ERRORS[error].slice(1)}.message`,
  );

  return (
    <div className={styles.notificationPage}>
      <div className={styles.notificationIcon}>
        <OctagonErrorIcon />
      </div>
      <Typography.Title level={4} className={styles.notificationErrorTitle}>
        {errorTitle}
      </Typography.Title>
      <div className={styles.activationMessage}>
        <Typography.Paragraph>
          <p>{errorMessage}</p>
        </Typography.Paragraph>
      </div>
    </div>
  );
};

export default OnboardingFailure;
