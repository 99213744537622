import {
  IMPORT_VALIDATION_ERRORS_TRANSLATION_MAP,
  IMPORT_ERRORS_TRANSLATION_MAP,
} from 'constants/drivers';
import useTranslation from 'utils/hooks/useTranslation';

const useFormatValidationErrors = () => {
  const { translateText } = useTranslation();

  const translateImportErrors = validationErrors => {
    return Object.keys(validationErrors).map(key => {
      const errorMessage = IMPORT_ERRORS_TRANSLATION_MAP[key] || key;

      return {
        type: translateText(errorMessage),
        rows: validationErrors[key],
      };
    });
  };

  const translateValidationErrors = validationErrors => {
    return Object.keys(validationErrors).map(key => {
      const errorMessage = IMPORT_VALIDATION_ERRORS_TRANSLATION_MAP[key] || key;
      const rowsWithValidationError =
        validationErrors[key][0] === 'There are no valid drivers to be imported.'
          ? ['']
          : validationErrors[key];

      return {
        type: translateText(errorMessage),
        rows: rowsWithValidationError,
      };
    });
  };

  const collectErrorsPerRow = validationErrors => {
    const errorLogs = new Map();
    validationErrors.forEach(error => {
      error.rows.forEach(row => {
        const rowErrors = errorLogs.get(row);

        if (!rowErrors) errorLogs.set(row, [error.type]);
        else {
          rowErrors.push(error.type);
        }
      });
    });

    return [...errorLogs].sort((a, b) => a[0] - b[0]);
  };

  const convertRowErrorsToString = validationErrors => {
    return validationErrors.map(row => {
      return [row[0], row[1].join(', ')];
    });
  };

  const addColumnHeader = validationErrors => {
    validationErrors.unshift([
      translateText('corporateAccounts.common.rowNumber'),
      translateText('corporateAccounts.common.error'),
    ]);
    return validationErrors;
  };

  const formatImportErrors = validationErrors =>
    addColumnHeader(
      convertRowErrorsToString(collectErrorsPerRow(translateImportErrors(validationErrors))),
    );

  const formatValidationErrors = validationErrors =>
    addColumnHeader(
      convertRowErrorsToString(collectErrorsPerRow(translateValidationErrors(validationErrors))),
    );

  return { formatValidationErrors, formatImportErrors };
};

export default useFormatValidationErrors;
