import http from 'services/http';
import userService from 'services/user';
import { ENDPOINT_VEHICLES } from 'constants.js';
import { endpoint } from '.';

const create = async data => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'POST',
    url: endpoint(ENDPOINT_VEHICLES),
    headers: {
      ...authHeaders,
    },
    data,
  };

  const response = http(requestOptions);
  return response;
};

const read = async query => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'GET',
    url: endpoint(ENDPOINT_VEHICLES, true),
    headers: {
      ...authHeaders,
    },
    params: query,
  };

  const response = http(requestOptions);
  return response;
};

const update = async data => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'PUT',
    url: `${endpoint(ENDPOINT_VEHICLES)}/${data.id}`,
    headers: {
      ...authHeaders,
    },
    data,
  };

  const response = http(requestOptions);
  return response;
};

const destroy = async id => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'DELETE',
    url: `${endpoint(ENDPOINT_VEHICLES)}/${id}`,
    headers: {
      ...authHeaders,
    },
  };

  const response = http(requestOptions);
  return response;
};

const batchDelete = async data => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'POST',
    url: `${endpoint(ENDPOINT_VEHICLES, true)}:batchDelete`,
    headers: {
      ...authHeaders,
    },
    data,
  };

  const response = http(requestOptions);
  return response;
};

export default {
  read,
  create,
  update,
  destroy,
  batchDelete,
};
