import { useEffect } from 'react';
import { processSilentRenew } from 'redux-oidc';

const SilentRenew = () => {
  useEffect(() => {
    processSilentRenew();
  }, []);

  return null;
};

export default SilentRenew;
