import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Typography } from 'antd';
import useTranslation from 'utils/hooks/useTranslation';
import useAmplitude from 'utils/hooks/useAmplitude';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import ExtendedModal from '../Modal';
import { LaptopIcon } from '../Icons';

import styles from './style.module.scss';

const { Title, Text } = Typography;

const SwitchToDesktopModal = ({ isVisible }) => {
  const dispatch = useDispatch();
  const { translateText } = useTranslation();
  const { sendAmplitudeEvent } = useAmplitude();

  const logout = () => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.switchToDesktopModalLogOutButtonClicked);
    dispatch({ type: 'user/LOGOUT' });
  };

  useEffect(() => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.switchToDesktopModalViewed);
  }, [sendAmplitudeEvent]);

  return (
    <ExtendedModal
      visible={isVisible}
      closable={false}
      footer={null}
      wrapClassName={'modalWrapper'}
    >
      <div className={styles.modalContainer}>
        <Title level={3}>{translateText('corporateAccounts.common.switchToDesktop')}</Title>
        <LaptopIcon />
        <Text>{translateText('corporateAccounts.common.switchToDesktop.description')}</Text>
        <Button type="primary" size="large" className={styles.logoutButton} onClick={logout}>
          {translateText('corporateAccounts.topBar.profileMenu.signOut')}
        </Button>
      </div>
    </ExtendedModal>
  );
};

export default SwitchToDesktopModal;
