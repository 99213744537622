const helpUrls = {
  FR: {
    HELP_CENTRE_URL: 'https://pbpbusiness.zendesk.com/hc/fr-fr',
    SUPPORT_URL: 'https://pbpbusiness.zendesk.com/hc/fr-fr/requests/new',
  },
  DE: {
    SUPPORT_URL: 'https://pbpbusiness.zendesk.com/hc/de-de/requests/new',
  },
  CH: {
    HELP_CENTRE_URL: 'https://pbpbusiness.zendesk.com/hc/fr-fr',
    SUPPORT_URL: 'https://pbpbusiness.zendesk.com/hc/fr-fr/requests/new',
  },
  EN: {
    HELP_CENTRE_URL: 'https://pbpbusiness.zendesk.com/hc/en-gb',
    SUPPORT_URL: 'https://pbpbusiness.zendesk.com/hc/en-gb/requests/new',
  },
};

export default helpUrls;
