import React from 'react';
import { CSVLink } from 'react-csv';
import { Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { CopyToClipboardIcon } from 'components/Shared/Icons';

import styles from './style.module.scss';

const downloadCsvFile = csvFileInformation => {
  if (csvFileInformation?.fileName && csvFileInformation?.title && csvFileInformation?.data) {
    return (
      <>
        <Typography.Text>&nbsp;</Typography.Text>
        <Typography.Link type="link" data-testid="csvLink">
          <CSVLink data={csvFileInformation.data} filename={`${csvFileInformation.fileName}.csv`}>
            {csvFileInformation.title}
          </CSVLink>
        </Typography.Link>
      </>
    );
  } else return null;
};

const NotificationContent = (message, title = null, onClose, csvFileInformation) => (
  <div className={styles.notificationContent}>
    <div className={styles.notificationDescription}>
      {title && (
        <>
          <Typography.Text strong={true}>{title}</Typography.Text>
          <Typography.Text strong={true}>:&nbsp;</Typography.Text>
        </>
      )}
      <Typography.Text>{message}</Typography.Text>
      {downloadCsvFile(csvFileInformation)}
    </div>
    <div>
      <CloseOutlined onClick={onClose} style={{ fontSize: '13px' }} data-testid="closeButton" />
    </div>
  </div>
);

export const ErrorNotificationWithWorkflowId = ({
  errorCodeTitle,
  message,
  workflowId,
  onClose,
}) => {
  return (
    <div className={styles.notificationContent}>
      <div className={styles.notificationDescription}>
        <div className={styles.notificationErrorCode}>
          <Typography.Text>
            {errorCodeTitle}: {workflowId}
          </Typography.Text>
          <a
            onClick={() => {
              navigator.clipboard.writeText(workflowId);
            }}
          >
            <CopyToClipboardIcon color="black" />
          </a>
        </div>
        <Typography.Text>{message}</Typography.Text>
      </div>
      <div>
        <CloseOutlined onClick={onClose} style={{ fontSize: '13px' }} data-testid="closeButton" />
      </div>
    </div>
  );
};

export default NotificationContent;
