export const exportHeaders = intl => {
  return [
    {
      id: intl.formatMessage({
        id: 'corporateAccounts.reports.paymentDate',
        defaultMessage: 'Payment Date',
      }),
      key: 'paymentDate',
    },
    {
      id: intl.formatMessage({
        id: 'corporateAccounts.reports.localStartDateTime',
        defaultMessage: 'Start Time',
      }),
      key: 'localStartDateTime',
    },
    {
      id: intl.formatMessage({
        id: 'corporateAccounts.reports.localEndDateTime',
        defaultMessage: 'Expiry Time',
      }),
      key: 'localEndDateTime',
    },
    {
      id: intl.formatMessage({
        id: 'corporateAccounts.reports.parkingMinutes',
        defaultMessage: 'Parking Minutes',
      }),
      key: 'duration',
    },
    {
      id: intl.formatMessage({
        id: 'corporateAccounts.reports.dayOfWeek',
        defaultMessage: 'Day',
      }),
      key: 'dayOfWeek',
    },
    {
      id: intl.formatMessage({
        id: 'corporateAccounts.reports.licensePlate',
        defaultMessage: 'License Plate',
      }),
      key: 'licensePlate',
    },
    {
      id: intl.formatMessage({
        id: 'corporateAccounts.reports.firstName',
        defaultMessage: 'First Name',
      }),
      key: 'firstName',
    },
    {
      id: intl.formatMessage({
        id: 'corporateAccounts.reports.lastName',
        defaultMessage: 'Last Name',
      }),
      key: 'lastName',
    },
    {
      id: intl.formatMessage({
        id: 'corporateAccounts.reports.phoneNumber',
        defaultMessage: 'Phone Number',
      }),
      key: 'phoneNumber',
    },
    {
      id: intl.formatMessage({
        id: 'corporateAccounts.reports.netTotal',
        defaultMessage: 'Total Amount (net)',
      }),
      key: 'netTotal',
    },
    {
      id: intl.formatMessage({
        id: 'corporateAccounts.reports.tax',
        defaultMessage: 'Tax',
      }),
      key: 'tax',
    },
    {
      id: intl.formatMessage({
        id: 'corporateAccounts.reports.cost',
        defaultMessage: 'Total Amount',
      }),
      key: 'cost',
    },
    {
      id: intl.formatMessage({
        id: 'corporateAccounts.reports.parkingVendorName',
        defaultMessage: 'Vendor',
      }),
      key: 'parkingVendorName',
    },
    {
      id: intl.formatMessage({
        id: 'corporateAccounts.reports.parkingLotName',
        defaultMessage: 'Parking Lot Name',
      }),
      key: 'parkingLotName',
    },
    {
      id: intl.formatMessage({
        id: 'corporateAccounts.reports.lotId',
        defaultMessage: 'Parking Lot Id',
      }),
      key: 'lotId',
    },
    {
      id: intl.formatMessage({
        id: 'corporateAccounts.reports.transactionId',
        defaultMessage: 'Transaction ID',
      }),
      key: 'transactionId',
    },
    {
      id: intl.formatMessage({
        id: 'corporateAccounts.reports.email',
        defaultMessage: 'Email',
      }),
      key: 'email',
    },
    {
      id: intl.formatMessage({
        id: 'corporateAccounts.drivers.costCenter',
        defaultMessage: 'Cost Center',
      }),
      key: 'costCenter',
    },
    {
      id: intl.formatMessage({
        id: 'corporateAccounts.reports.paymentStatus',
        defaultMessage: 'Payment Status',
      }),
      key: 'paymentStatus',
    },
    {
      id: intl.formatMessage({
        id: 'corporateAccounts.reports.maskedCardNumber',
        defaultMessage: 'Payment Details Masked',
      }),
      key: 'maskedCardNumber',
    },
  ];
};

export default exportHeaders;
