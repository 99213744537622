import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from 'antd';
import { onboardingSteps } from 'constants/onboarding';
import useTranslation from 'utils/hooks/useTranslation';
import { CORPORATE_CLIENT_PROSPECT_STATUS } from 'constants.js';
import { isCardActive, calculatePercentage, getLinkText } from './helper';
import ProgressStepCard from './ProgressStepCard';
import ProgressCircle from 'components/Shared/ProgressCircle/index.js';
import AccountActivationModal from './AccountActivationModal';
import WelcomeModal from '../WelcomeModal';
import useProspectAccountActivation from './useProspectAccountActivation';

import styles from './style.module.scss';

const { Title, Text } = Typography;

const ProgressBarTitle = () => {
  const { translateText } = useTranslation();

  const [isWelcomeModalVisible, setIsWelcomeModalVisible] = useState(false);

  return (
    <>
      <div className={styles.headerTitle}>
        <Title level={3}>{translateText('corporateAccounts.onboarding.progressBar.title')}</Title>
        <Text>
          {translateText('corporateAccounts.onboarding.progressBar.description')}{' '}
          <span className={styles.buttonAsLink} onClick={() => setIsWelcomeModalVisible(true)}>
            {translateText('corporateAccounts.onboarding.watchProductDemoVideo')}
          </span>
        </Text>
      </div>
      <WelcomeModal isVisible={isWelcomeModalVisible} setIsVisible={setIsWelcomeModalVisible} />
    </>
  );
};

const StepCards = ({ currentUrl, onboardingStatus }) => {
  const { translateText } = useTranslation();

  return (
    <div className={styles.stepCards}>
      {Object.values(onboardingSteps)
        .sort(
          (onboardingStep1, onboardingStep2) =>
            onboardingStatus[onboardingStep2.statusName] -
            onboardingStatus[onboardingStep1.statusName],
        )
        .map(onboardingStep => {
          const cardStatus = onboardingStatus[onboardingStep.statusName];
          return (
            <ProgressStepCard
              key={onboardingStep.title}
              title={translateText(onboardingStep.title)}
              description={translateText(onboardingStep.description)}
              url={onboardingStep.url}
              amplitudeEvent={onboardingStep.amplitudeEvent}
              icon={onboardingStep.icon}
              linkText={getLinkText({ translateText, pageUrl: onboardingStep.url })}
              status={
                cardStatus
                  ? translateText('corporateAccounts.reports.monthly.reportJob.status.completed')
                  : translateText('corporateAccounts.common.status.notCompleted')
              }
              isComplete={cardStatus}
              isActive={isCardActive(currentUrl, onboardingStep.url)}
            />
          );
        })}
    </div>
  );
};

const OnboardingProgressBar = ({ history }) => {
  const {
    isActivateCorporationSuccess,
    isActivateCorporationError,
    isActivateCorporationLoading,
    onAccountActivation,
  } = useProspectAccountActivation();

  const corporationStatus = useSelector(state => state?.user?.currentCorporation?.statusName);
  const progress = useSelector(state => state?.user?.onboardingProgress?.progress);
  const isOnboardingComplete = useSelector(
    state => state?.user?.onboardingProgress?.isOnboardingComplete,
  );

  const progressPercentage = calculatePercentage(progress);
  const currentUrl = history?.location?.pathname || window?.location?.pathname;

  return (
    <div>
      {corporationStatus === CORPORATE_CLIENT_PROSPECT_STATUS &&
        progress !== undefined &&
        progressPercentage <= 100 && (
          <div className="card">
            <div className="card-body">
              <div className={styles.container}>
                <div className={styles.headerContainer}>
                  <ProgressBarTitle />
                  <ProgressCircle progressPercentage={progressPercentage} />
                </div>
                <StepCards currentUrl={currentUrl} onboardingStatus={progress} />
              </div>
            </div>
            <AccountActivationModal
              visible={isOnboardingComplete && !isActivateCorporationSuccess}
              onAccountActivation={onAccountActivation}
              isActivateCorporationError={isActivateCorporationError}
              isActivateCorporationLoading={isActivateCorporationLoading}
            />
          </div>
        )}
    </div>
  );
};

export default OnboardingProgressBar;
