const actions = {
  GET: 'vehicles/GET',
  GET_SUCCESS: 'vehicles/GET_SUCCESS',
  GET_ERROR: 'vehicles/GET_ERROR',
  POST: 'vehicles/POST',
  POST_SUCCESS: 'vehicles/POST_SUCCESS',
  RESET_SUCCESS: 'vehicles/RESET_SUCCESS',
  POST_LIMIT_ERROR: 'vehicles/POST_LIMIT_ERROR',
  POST_ERROR: 'vehicles/POST_ERROR',
  POST_INVALID_PLATE_ERROR: 'vehicles/POST_INVALID_PLATE_ERROR',
  RESET_ISPLATEVALID: 'vehicles/RESET_ISPLATEVALID',
  PUT: 'vehicles/PUT',
  PUT_SUCCESS: 'vehicles/PUT_SUCCESS',
  PUT_ERROR: 'vehicles/PUT_ERROR',
  BATCH_DELETE: 'vehicles/BATCH_DELETE',
  BATCH_DELETE_SUCCESS: 'vehicles/BATCH_DELETE_SUCCESS',
  BATCH_DELETE_ERROR: 'vehicles/BATCH_DELETE_ERROR',
  DELETE: 'vehicles/DELETE',
  DELETE_SUCCESS: 'vehicles/DELETE_SUCCESS',
  DELETE_ERROR: 'vehicles/DELETE_ERROR',
  IMPORT: 'vehicles/IMPORT',
  IMPORT_CANCEL: 'vehicles/IMPORT_CANCEL',
  IMPORT_SUCCESS: 'vehicles/IMPORT_SUCCESS',
  IMPORT_ERROR: 'vehicles/IMPORT_ERROR',
  IMPORT_LIMIT_ERROR: 'vehicles/IMPORT_LIMIT_ERROR',
  EXPORT_VEHICLES: 'vehicles/EXPORT_VEHICLES',
  EXPORT_VEHICLES_SUCCESS: 'vehicles/EXPORT_VEHICLES_SUCCESS',
  EXPORT_VEHICLES_ERROR: 'vehicles/EXPORT_VEHICLES_ERROR',
  RESET_ERRORS: 'vehicles/RESET_ERRORS',
  RESET_LIST: 'vehicles/RESET_LIST',
};

export default actions;
