import moment from 'moment';

export const formatDateTime = value => {
  return moment(value).format('YYYY-MM-DD HH:mm');
};

export const formatDateTimeMonthFirst = (value, locale) => {
  moment.locale(locale);
  return moment(value).format('MMM Do, hh:mm A');
};

export default { formatDateTime, formatDateTimeMonthFirst };
