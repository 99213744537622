import { store } from 'initStore';
import { localizedMessage } from 'services/localize';
import {
  CORPORATE_CLIENT_COUNTRIES_WITH_GLB,
  CORPORATE_CLIENT_COUNTRY_LOCALE,
  CORPORATE_CLIENT_COUNTRY_CSV_DELIMITOR,
} from 'constants/corporateClients';
import { DRIVER_EXPORT_CSV_HEADER_KEYS } from 'constants/drivers';
import {
  VEHICLE_EXPORT_CSV_HEADER_KEYS,
  VEHICLE_EXPORT_CSV_HEADER_KEYS_EU,
} from 'constants/vehicles';

const isEu = country => {
  if (country === CORPORATE_CLIENT_COUNTRIES_WITH_GLB.glb) {
    return false;
  }

  return [CORPORATE_CLIENT_COUNTRIES_WITH_GLB.FR, CORPORATE_CLIENT_COUNTRIES_WITH_GLB.GB].includes(
    country,
  );
};

export const getDelimiterByCountry = country => {
  return (
    CORPORATE_CLIENT_COUNTRY_CSV_DELIMITOR[country] ||
    CORPORATE_CLIENT_COUNTRY_CSV_DELIMITOR.default
  );
};

export const getVehicleExportHeaders = country => {
  const localeOptions = {
    locale: CORPORATE_CLIENT_COUNTRY_LOCALE[country] || CORPORATE_CLIENT_COUNTRY_LOCALE.default,
  };

  const headers = isEu(country)
    ? VEHICLE_EXPORT_CSV_HEADER_KEYS_EU
    : VEHICLE_EXPORT_CSV_HEADER_KEYS;

  return headers.map(key => ({
    key,
    label: localizedMessage(`corporateAccounts.vehicles.csvDownload.${key}`, null, localeOptions),
  }));
};

export const getDriverExportHeaders = (country, translateText) => {
  const localeOptions = {
    locale: CORPORATE_CLIENT_COUNTRY_LOCALE[country] || CORPORATE_CLIENT_COUNTRY_LOCALE.default,
  };
  return DRIVER_EXPORT_CSV_HEADER_KEYS.map((key, i) => {
    if (i < 2) {
      return {
        key,
        label: translateText(`corporateAccounts.admins.${key}`, localeOptions),
      };
    } else {
      return {
        key,
        label: translateText(`corporateAccounts.drivers.csvDownload.${key}`, localeOptions),
      };
    }
  });
};

const generateCsv = (data, headerMapping, delimiter) => {
  // \uFEFF is the BOM character to support accented letters
  let csvContent = '\uFEFF';
  const lastColumnIndex = headerMapping.length - 1;

  headerMapping.forEach((header, index) => {
    csvContent += `"${header.label}"${index !== lastColumnIndex ? delimiter : ''}`;
  });
  csvContent += '\n';

  data.forEach(row => {
    headerMapping.forEach((header, index) => {
      csvContent += `${row[header.key] || ''}${index !== lastColumnIndex ? delimiter : ''}`;
    });
    csvContent += '\n';
  });

  return new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
};

/**
 * This function will take an array of DTOs, package them into a CSV and trigger an auto-download.
 * @param {array} data - the DTOs to output as CSV
 * @param {array} headerMapping - an array of objects with 'label' and 'key' properties where
 *      'key' will be used for mapping the DTO
 *      'label' will be the CSV header label
 *    NOTE: headerMapping array should be presented in the desired output order
 * @param {*} filename - the CSV output filename; will be suffixed with '_semi' if using a ';' delimiter
 */
export const generateAndDownloadCsv = (data, headerMapping, filename) => {
  const currentCorpCountry = store && store.getState().user.currentCorporation?.country;
  const delimiter = getDelimiterByCountry(currentCorpCountry);
  const filenameWithDelimiter = delimiter === ';' ? `${filename}_semi.csv` : `${filename}.csv`;

  const csvFile = generateCsv(data, headerMapping, delimiter);
  const link = document.createElement('a');

  if (link.download !== undefined) {
    const url = URL.createObjectURL(csvFile);
    link.setAttribute('href', url);
    link.setAttribute('download', filenameWithDelimiter);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    window.open(encodeURI(csvFile));
  }
};

const exportUtil = {
  getDelimiterByCountry,
  generateAndDownloadCsv,
};

export default exportUtil;
