import React from 'react';
import { Form } from 'antd';
import PhoneInput from 'components/Shared/PhoneInput';
import isValidPhoneNumber from 'utils/phoneUtils';
import CheckMarkIcon from 'components/Shared/Icons/CheckMarkIcon';
import { FORM_FIELD_NAMES } from 'constants/forms';

import styles from './style.module.scss';

const { Item } = Form;

const PhoneInputWithCheckmarkValidation = ({
  translateText,
  isValid,
  onChange,
  form,
  validateForm,
  onlyCountries,
}) => {
  const { getFieldValue, validateFields, setFieldsValue } = form;

  const handleFieldChange = (fieldName, setValidationState) => {
    validateFields([fieldName])
      .then(() => {
        setValidationState(true);
      })
      .catch(() => {
        setValidationState(false);
      });
  };

  return (
    <Item
      name={FORM_FIELD_NAMES.mobilePhoneNumber}
      label={translateText('corporateAccounts.drivers.mobilePhoneNumber')}
      className={styles.formLabelOverride}
      validateFirst
      data-testid={FORM_FIELD_NAMES.mobilePhoneNumber}
      rules={[
        {
          required: true,
          message: translateText('corporateAccounts.drivers.form.phoneRequired'),
        },
        {
          validator: (rule, value) =>
            value
              ? isValidPhoneNumber(getFieldValue(FORM_FIELD_NAMES.mobilePhoneNumber))
              : Promise.resolve(),
          message: translateText('corporateAccounts.drivers.form.phoneValidation'),
        },
      ]}
    >
      <div className={styles.mobilePhoneNumberInputBox}>
        <PhoneInput
          fieldValue={FORM_FIELD_NAMES.mobilePhoneNumber}
          onInputChange={(fieldName, value) => {
            setFieldsValue({ [fieldName]: value });
            validateFields([fieldName]);
            validateForm();
            handleFieldChange(FORM_FIELD_NAMES.mobilePhoneNumber, onChange);
          }}
          onlyCountries={onlyCountries}
        />
        <div className={styles.mobilePhoneNumberCheckMark}>
          {isValid ? <CheckMarkIcon /> : <span />}
        </div>
      </div>
    </Item>
  );
};

export default PhoneInputWithCheckmarkValidation;
