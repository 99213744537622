import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { ONETRUST_DATA_DOMAIN_SCRIPT } from 'constants.js';
import actions from 'redux/settings/actions';

const reloadBannerIfNoCookiesValueInStore = hasAcceptedFunctionalCookiesInStore => {
  if (
    hasAcceptedFunctionalCookiesInStore === null ||
    hasAcceptedFunctionalCookiesInStore === undefined
  ) {
    Cookies.remove('OptanonAlertBoxClosed');
  }
};

const attachOneTrustScriptToHead = (oneTrustScriptUrl, head) => {
  const cdnScript = document.createElement('script');
  cdnScript.setAttribute('src', oneTrustScriptUrl);
  cdnScript.setAttribute('type', 'text/javascript');
  cdnScript.setAttribute('charset', 'UTF-8');
  cdnScript.setAttribute('data-domain-script', ONETRUST_DATA_DOMAIN_SCRIPT);

  head.prepend(cdnScript);

  return cdnScript;
};

const findOneTrust = async () => {
  const intervalDelayInMs = 100;
  const maxAttempts = 10;

  const delayPromise = () => new Promise(resolve => setTimeout(resolve, intervalDelayInMs));

  for (let i = 0; i < maxAttempts; i++) {
    await delayPromise();

    if (window.OneTrust) {
      return Promise.resolve(window.OneTrust);
    }
  }

  return Promise.reject();
};

const addCookieConsentChangeListener = async dispatch => {
  try {
    const OneTrust = await findOneTrust();

    OneTrust.OnConsentChanged(consentEvent => {
      const acceptedCookieCategories = consentEvent?.detail;
      const hasAcceptedFunctionalCookies = acceptedCookieCategories?.includes('C0003');

      dispatch({
        type: actions.CHANGE_SETTING,
        payload: {
          setting: 'hasAcceptedFunctionalCookies',
          value: hasAcceptedFunctionalCookies,
        },
      });
    });
  } catch (_) {
    console.log("Couldn't find OneTrust");
  }
};

const useCookiesConsent = (
  oneTrustScriptUrl = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
) => {
  const dispatch = useDispatch();
  const hasAcceptedFunctionalCookies = useSelector(
    state => state.settings?.hasAcceptedFunctionalCookies,
  );

  useEffect(() => {
    reloadBannerIfNoCookiesValueInStore(hasAcceptedFunctionalCookies);

    const head = document.querySelector('head');
    const cdnScript = attachOneTrustScriptToHead(oneTrustScriptUrl, head);

    addCookieConsentChangeListener(dispatch);

    return () => {
      head.removeChild(cdnScript);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useCookiesConsent;
