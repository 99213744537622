import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import ResponsiveHeader from 'components/Shared/ResponsiveHeader';
import styles from './style.module.scss';
import usePlausible from 'utils/hooks/usePlausible';

const RegistrationLayout = ({ children }) => {
  usePlausible();

  return (
    <Layout>
      <ResponsiveHeader />
      <Content id={styles.content}>{children}</Content>
    </Layout>
  );
};

export default connect()(withRouter(RegistrationLayout));
