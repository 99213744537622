import React from 'react';
import { Typography } from 'antd';
import useTranslation from 'utils/hooks/useTranslation';
import { VerificationFailedIcon } from 'components/Shared/Icons';

import styles from './style.module.scss';

const VerificationFailed = () => {
  const { translateText } = useTranslation();

  return (
    <div className={styles.verificationPage} data-testid="verificationFailed">
      <div data-testid="verificationFailedIcon">
        <VerificationFailedIcon />
      </div>
      <Typography.Title level={2} className={styles.verificationTitle}>
        {translateText('corporateAccounts.selfRegistration.verification.failed')}
      </Typography.Title>
      <Typography className={styles.verificationMessage}>
        <Typography.Text>
          {translateText('corporateAccounts.selfRegistration.verification.failed.message')}
        </Typography.Text>
      </Typography>
    </div>
  );
};

export default VerificationFailed;
