import React from 'react';
import { Layout, Spin, Typography } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import styles from './style.module.scss';

const AuthRedirectLayout = ({ children }) => (
  <Layout>
    <Layout.Content>
      <div>
        <div className={styles.spinner}>
          <Spin tip="Redirecting..." size="large" />
        </div>
        <div className={styles.header}>
          <div className={styles.logo}>
            <Link to="/">
              <img src="/resources/images/logo-pbp.svg" alt="logo" />
            </Link>
          </div>
        </div>
        <div className={styles.content}>{children}</div>
        <div className={`${styles.footer} text-center`}>
          <Typography.Text>
            &copy; 2021 PayByPhone Technologies. All rights reserved.
          </Typography.Text>
        </div>
      </div>
    </Layout.Content>
  </Layout>
);

export default withRouter(AuthRedirectLayout);
