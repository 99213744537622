import axios from 'axios';
import { LOCALE_STORE_URL } from '../../constants';

export const loadLocaleTranslations = async locale => {
  const { data } = await axios.get(`${LOCALE_STORE_URL}/${locale}.json`);
  return data;
};

export default {
  loadLocaleTranslations,
};
