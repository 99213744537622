import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useGetPricePlan } from 'services/reactQueryHooks/corporations';
import {
  CORPORATE_CLIENT_REGISTRATION_TYPES,
  CORPORATE_CLIENT_STATUS,
} from 'constants/corporateClients';

const useSubscriptionPage = () => {
  const {
    data: pricePlan,
    isLoading: isPricePlanLoading,
    isError: isPricePlanError,
    fetchPricePlan,
  } = useGetPricePlan();

  const isSelfRegistered = useSelector(
    state =>
      state.user?.currentCorporation?.registrationType?.toLowerCase() ===
      CORPORATE_CLIENT_REGISTRATION_TYPES.SELF_REGISTERED.toLowerCase(),
  );

  const isActive = useSelector(
    state => state.user?.currentCorporation?.status === CORPORATE_CLIENT_STATUS.ACTIVE,
  );

  const isPricePlanFetchAvailable = false; // This is temporary disabled

  const shouldFetch = isSelfRegistered && isActive && isPricePlanFetchAvailable;

  useEffect(() => {
    if (shouldFetch) {
      fetchPricePlan();
    }
  }, [shouldFetch, fetchPricePlan]);

  return {
    pricePlan,
    isPricePlanLoading,
    isPricePlanError,
    isSelfRegistered,
  };
};

export default useSubscriptionPage;
