import qs from 'qs';
import userManager from 'utils/userManager';
import { invitations } from 'services/api';

const triggerActivationRedirect = async history => {
  const query = await qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const { activation_id, activation_code } = query;
  if (activation_id && activation_code) {
    try {
      const currentSession = await userManager.querySessionStatus();

      if (currentSession) {
        await userManager.signoutRedirect();
        return userManager.signinRedirect({
          prompt: 'activate',
          extraQueryParams: { activation_id, activation_code },
        });
      }
    } catch (e) {
      return userManager.signinRedirect({
        prompt: 'activate',
        extraQueryParams: { activation_id, activation_code },
      });
    }
  }

  return userManager.signinRedirect();
};

const triggerActivation = async (n = 3) => {
  try {
    const response = await invitations.admin();
    return response;
  } catch (err) {
    if (n === 1) {
      console.error(err);
      return userManager.signoutRedirect();
    }
    return triggerActivation(n - 1);
  }
};

const authUtil = {
  triggerActivationRedirect,
  triggerActivation,
};

export default authUtil;
