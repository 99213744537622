const { LoadingOutlined } = require('@ant-design/icons');
const { Spin } = require('antd');

const Spinner = ({ size = 24 }) => {
  return (
    <Spin
      indicator={<LoadingOutlined style={{ fontSize: size }} spin />}
      data-testid="loadingIndicator"
    />
  );
};

export default Spinner;
