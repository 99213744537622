import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import PageTitle from 'components/Shared/Page/Title';
import Authorize from 'components/Providers/Authorize';
import useTranslation from 'utils/hooks/useTranslation';
import { ROUTE_CORPORATIONS_ADMIN } from 'constants.js';
import CorporationForm from './CorporationForm';

const CorporationProfile = ({ history }) => {
  const { translateText } = useTranslation();

  const [formData, setFormData] = useState({});

  const dispatch = useDispatch();

  const corporateClient = useSelector(state => state.corporations.corporateClient);

  const handleFormDataChange = values => {
    setFormData(prevState => {
      return { ...prevState, ...values };
    });
  };

  const handleBackToCorporations = () => {
    history.push(ROUTE_CORPORATIONS_ADMIN);
  };

  const handleCorporationSave = values => {
    if (!corporateClient)
      dispatch({ type: 'corporations/POST', payload: { ...formData, ...values } });
  };

  return (
    <Authorize>
      <Helmet title="Create a Corporation" />

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <PageTitle title={translateText('corporateAccounts.admin.newCorporations')} />
            <div className="card-body">
              <CorporationForm
                formData={formData}
                onCancel={handleBackToCorporations}
                onFormDataChange={handleFormDataChange}
                onSave={handleCorporationSave}
              />
            </div>
          </div>
        </div>
      </div>
    </Authorize>
  );
};

export default withRouter(injectIntl(CorporationProfile));
