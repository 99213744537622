import { REGION_LABEL, TAX_NUMBER_LABEL } from 'regions';

const updateRegionLabel = (value, setValueFn, translateText) => {
  const currentRegion = REGION_LABEL[value];
  const currentLabel = currentRegion || 'region';
  const isRegionHidden = currentLabel === 'region';

  setValueFn(translateText(`corporateAccounts.corporateClient.${currentLabel}`));
  return {
    currentLabel,
    isRegionHidden,
  };
};

export const getJurisdictionLabel = (country, translateText) => {
  const label = REGION_LABEL[country] || 'region';
  return translateText(`corporateAccounts.corporateClient.${label}`);
};

export const getTaxNumberLabel = (country, translateText) => {
  const label = TAX_NUMBER_LABEL[country] || 'taxNumber';
  return translateText(`corporateAccounts.corporateClient.${label}`);
};

export default updateRegionLabel;
