import React from 'react';
import moment from 'moment';
import UtilTag from 'components/Shared/Tag';
import { Button } from 'antd';
import { REPORT_STATUS, REPORT_STATUS_TAGS } from 'constants/reports';

const tableColumns = ({ handleDownloadReport, translateText }) => [
  {
    title: translateText('corporateAccounts.reports.monthly.reportType'),
    dataIndex: 'reportName',
  },
  {
    title: translateText('corporateAccounts.reports.monthly.timeExported'),
    dataIndex: 'createdAt',
    render: time => {
      return moment(time)
        .local()
        .format('YYYY-MM-DD HH:mm');
    },
  },
  {
    title: translateText('corporateAccounts.common.status'),
    dataIndex: 'status',
    render: status => {
      const reportStatus = REPORT_STATUS_TAGS[status];
      if (reportStatus) {
        const content = translateText(reportStatus.localizeKey);
        return <UtilTag content={content} type={reportStatus.utilTagType} />;
      }
      return null;
    },
  },
  {
    title: translateText('corporateAccounts.actions'),
    key: 'actions',
    render: (_text, record) => {
      return (
        <Button
          type="secondary"
          onClick={() => handleDownloadReport({ jobId: record.id })}
          disabled={record.status !== REPORT_STATUS.SUCCEEDED}
        >
          {translateText('corporateAccounts.actions.download')}
        </Button>
      );
    },
  },
];

export default tableColumns;
