import { REHYDRATE } from 'redux-persist/lib/constants';
import { all, takeEvery, put, call, take } from 'redux-saga/effects';
import { reports } from 'services/api';
import { isSuccess, isUnprocessableEntity } from 'utils/sagasUtil';
import notify from 'utils/notificationUtils';
import localizedMessage from 'services/localize';
import actions from './actions';

export function* QUERY({ payload }) {
  const { response } = yield call(reports.query, payload);

  if (isSuccess(response)) {
    yield put({
      type: actions.QUERY_SUCCESS,
      payload: response.data,
    });
    return;
  }

  let error = null;
  if (isUnprocessableEntity(response)) {
    error = {
      type: 'Unprocessable',
    };
  } else {
    notify({
      type: 'error',
      title: localizedMessage('corporateAccounts.common.unexpectedError'),
      message: localizedMessage('corporateAccounts.common.pleaseTryAgain'),
    });
  }

  yield put({
    type: actions.QUERY_ERROR,
    payload: {
      error,
    },
  });
}

export function* GET_MONTHLY_REPORTS() {
  const { response } = yield call(reports.readMonthlyReports);
  if (isSuccess(response)) {
    yield put({
      type: actions.GET_MONTHLY_REPORTS_SUCCESS,
      payload: response.data,
    });
  } else {
    yield put({ type: actions.GET_MONTHLY_REPORTS_ERROR });
  }
}

export function* GENERATE_REPORT({ payload }) {
  const { response } = yield call(reports.createMonthlyReport, payload);

  if (isSuccess(response)) {
    notify({
      type: 'info',
      title: localizedMessage('corporateAccounts.reports.monthly.toast.generatingReport.title'),
      message: localizedMessage('corporateAccounts.reports.monthly.toast.generatingReport.content'),
    });
    yield put({ type: actions.GENERATE_REPORT_SUCCESS, payload: response.data });
  } else {
    yield put({ type: actions.GENERATE_REPORT_ERROR });
  }
}

export default function* rootSaga() {
  // Wait for redux-persist to rehydrate to prevent sagas running against empty store
  yield take(REHYDRATE);
  yield all([takeEvery(actions.QUERY, QUERY)]);
  yield all([takeEvery(actions.GET_MONTHLY_REPORTS, GET_MONTHLY_REPORTS)]);
  yield all([takeEvery(actions.GENERATE_REPORT, GENERATE_REPORT)]);
}
