const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOAD_CURRENT_PROFILE: 'user/LOAD_CURRENT_PROFILE',
  LOGOUT: 'user/LOGOUT',
  SET_ADMIN_TABLE_PAGE_SIZE: 'user/SET_ADMIN_TABLE_PAGE_SIZE',
  VERIFY_AUTH: 'user/VERIFY_AUTH',
  UPDATE_CURRENT_CORPORATION: 'user/UPDATE_CURRENT_CORPORATION',
};

export default actions;
