import React from 'react';
import PageTable from 'components/Shared/Page/Table';
import OffsetPaginationTable from 'components/Shared/OffsetPaginationTable';
import { useFeatureFlag } from 'components/Providers/FeatureFlag';

const CorporationsTable = ({
  columns,
  currentPage,
  dataSource,
  loading,
  totalCount,
  visible,
  onPageChange,
  onPageSizeChange,
  onTableChange,
  onRowSelectChange,
  selectedTableRows,
}) => {
  const { showFeature } = useFeatureFlag();

  return (
    <div data-testid="corporationsTable">
      <PageTable
        visible={visible}
        table={
          <OffsetPaginationTable
            loading={loading}
            rowKey="id"
            columns={columns}
            current={currentPage}
            totalCount={totalCount}
            dataSource={dataSource}
            scroll={{
              x: true,
            }}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onChange={onTableChange}
            rowSelection={
              showFeature('creditAccount')
                ? {
                    selectedRowKeys: selectedTableRows.map(row => row.id),
                    onChange: onRowSelectChange,
                  }
                : null
            }
          />
        }
      />
    </div>
  );
};

export default CorporationsTable;
