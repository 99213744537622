import http from 'services/http';
import userService from 'services/user';
import { API_URL_CA } from 'constants.js';

const upload = async data => {
  const { files } = data;
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'POST',
    url: `${API_URL_CA}/${data.id}${data.endpoint}`,
    headers: {
      ...authHeaders,
    },
    data: files,
  };
  const response = http(requestOptions);
  return response;
};

export default {
  upload,
};
