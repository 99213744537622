import React from 'react';
import moment from 'moment';
import MonthlyStatementDownloadButton from './MonthlyStatementDownloadButton';

const tableColumns = (translateText, locale) => {
  moment.locale(locale);

  return [
    {
      title: translateText('corporateAccounts.common.month'),
      dataIndex: 'statementDate',
      render: date => moment.utc(date).format('MMMM, YYYY'),
    },
    {
      title: translateText('corporateAccounts.monthlyStatements.generatedOn'),
      dataIndex: 'creationDateTime',
      render: date => `${moment.utc(date).format('MMMM DD, YYYY, HH:mm')} (UTC)`,
    },
    {
      title: translateText('corporateAccounts.common.download'),
      render: (text, statement) => {
        return (
          <MonthlyStatementDownloadButton
            statementId={statement.id}
            statementDate={moment.utc(statement.statementDate).format('MMMM, YYYY')}
          />
        );
      },
    },
  ];
};

export default tableColumns;
