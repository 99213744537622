import { Divider, Typography, Alert } from 'antd';
import { DRIVER_IMPORT_ERRORS, IMPORT_VALIDATION_ERRORS_TRANSLATION_MAP } from 'constants/drivers';
import React from 'react';
import useTranslation from 'utils/hooks/useTranslation';
import styles from './style.module.scss';

const DriverImportModalFailed = ({ error }) => {
  const validationErrors = error?.errors?.errors ?? null;
  const isLimitExceededError =
    error?.errors?.domainErrorCode === DRIVER_IMPORT_ERRORS.RestrictedCorporateClientLimitExceeded;

  const { translateText } = useTranslation();

  return (
    <div className={styles.importFailedContainer}>
      {isLimitExceededError ? (
        <Alert
          className={styles.serverErrorALert}
          message={translateText('corporateAccounts.driver.limitExceeded.error')}
          type="error"
        />
      ) : validationErrors ? (
        <>
          <Typography.Paragraph
            data-testid="errorTitle"
            type="danger"
            strong
            className={styles.errorTitle}
          >
            {translateText('corporateAccounts.drivers.import.failed')}
          </Typography.Paragraph>
          {Object.keys(validationErrors).map((key, i) => {
            if (!IMPORT_VALIDATION_ERRORS_TRANSLATION_MAP[key]) {
              return null;
            }

            return (
              <div className={styles.importFailedDetail} key={i} data-testid={key}>
                <Typography.Paragraph>
                  {translateText(IMPORT_VALIDATION_ERRORS_TRANSLATION_MAP[key])}
                </Typography.Paragraph>
                <Typography.Paragraph>{validationErrors[key].length}</Typography.Paragraph>
              </div>
            );
          })}

          <Divider className={styles.divider} />
          <div className={styles.importFailedDetail} data-testid="validationErrorTotalCount">
            <Typography.Paragraph>
              {translateText('corporateAccounts.drivers.import.error.invalidEntries')}
            </Typography.Paragraph>
            <Typography.Paragraph>
              {Object.keys(validationErrors).reduce((prev, current) => {
                return prev + (validationErrors[current].length || 0);
              }, 0)}
            </Typography.Paragraph>
          </div>
          <Typography.Paragraph type="danger" className={styles.genericError}>
            {translateText('corporateAccounts.drivers.import.error')}
          </Typography.Paragraph>
        </>
      ) : (
        <Alert
          className={styles.serverErrorALert}
          message={translateText('corporateAccounts.drivers.import.error.uploadFile')}
          type="error"
        />
      )}
    </div>
  );
};

export default DriverImportModalFailed;
