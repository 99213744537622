import { useState, useEffect } from 'react';
import { DEFAULT_PAGE_SIZE } from 'constants/tables';
import {
  useGetCorporations,
  useDeleteCorporation,
  useReactivateCorporation,
  useDeactivateCorporation,
} from 'services/reactQueryHooks/corporations/index';
import { calculateOffset } from 'utils/paginationUtil';
import searchUtil from 'utils/searchUtil';
import useTranslation from 'utils/hooks/useTranslation';
import { resetFiltersOnAllSelect } from './helper';

const createOrderString = (orderBy, sortOrder) => {
  const SORT_OPTIONS = {
    ascend: 'asc',
    descend: 'desc',
  };
  return orderBy && sortOrder ? `${orderBy.toLowerCase()} ${SORT_OPTIONS[sortOrder]}` : null;
};

const useCorporationsAdmin = () => {
  const [corporations, setCorporations] = useState([]);
  const [searchQuery, setSearchQuery] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(DEFAULT_PAGE_SIZE);
  const [orderBy, setOrderBy] = useState(null);
  const [tableFilters, setTableFilters] = useState(null);
  const [totalSize, setTotalSize] = useState(0);
  const [selectedTableRows, setSelectedTableRows] = useState([]);

  const { translateText } = useTranslation();

  const { mutate: deleteCorporation, isLoading: isDeleteLoading } = useDeleteCorporation();
  const {
    mutate: deactivateCorporation,
    isLoading: isDeactivateLoading,
  } = useDeactivateCorporation();
  const {
    mutate: reactivateCorporation,
    isLoading: isReactivateLoading,
  } = useReactivateCorporation();
  const { data, isLoading: isCorporationsLoading } = useGetCorporations({
    query: searchQuery,
    offset: calculateOffset(currentPage, limit || DEFAULT_PAGE_SIZE),
    limit,
    orderBy,
    filters: tableFilters,
  });

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const handlePageSizeChange = (i, pageSize) => {
    setLimit(pageSize);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableFilters(resetFiltersOnAllSelect(filters, translateText));
    setOrderBy(createOrderString(sorter.field, sorter.order));
  };

  const handleSearch = value => {
    setSearchQuery(searchUtil.trimValue(value));
  };

  const handleDeleteCorporation = corporation => {
    deleteCorporation(corporation);
  };

  const handleDeactivate = corporation => {
    deactivateCorporation(corporation);
  };

  const handleReactivate = corporation => {
    reactivateCorporation(corporation);
  };

  const handleSelectionChange = (_selectedRowKeys, selectedRows) => {
    setSelectedTableRows(selectedRows);
  };

  useEffect(() => {
    if (!isCorporationsLoading) {
      const offset = calculateOffset(currentPage, limit || DEFAULT_PAGE_SIZE);
      if (offset !== 0 && data?.items.length === 0) setCurrentPage(currentPage - 1);
      else {
        setCorporations(data?.items);
        setTotalSize(data?.totalCount);
      }
    }
  }, [data, isCorporationsLoading, currentPage, limit]);

  return {
    corporations,
    totalSize,
    currentPage,
    tableFilters,
    selectedTableRows,
    isCorporationsLoading,
    isDeleteLoading,
    isDeactivateLoading,
    isReactivateLoading,
    onPageSizeChange: handlePageSizeChange,
    onPageChange: handlePageChange,
    onSearch: handleSearch,
    onRowSelectChange: handleSelectionChange,
    onTableChange: handleTableChange,
    onDeactivate: handleDeactivate,
    onReactivate: handleReactivate,
    onDeleteCorporation: handleDeleteCorporation,
  };
};

export default useCorporationsAdmin;
