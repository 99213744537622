import React from 'react';
import tableUtil from 'utils/tableUtil';
import { Button, Dropdown, Space, Menu, Modal } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { STAFF_USER_ROLES, STAFF_USER_STATUS } from 'constants/staffUsers';
import UtilTag from 'components/Shared/Tag';

const staffUserRowActions = ({
  user,
  loading,
  onEdit,
  onConfirmDelete,
  onActivate,
  translateText,
}) => {
  const showInviteAction = user.status !== STAFF_USER_STATUS.Active.value;
  const name = `${user.firstName} ${user.lastName}`;
  const handleDeleteAction = () =>
    Modal.warning({
      content: 'Deleting this user will revoke their access. Are you sure you want to delete?',
      title: `Delete ${name}?`,
      okText: 'Delete user',
      okButtonProps: {
        'data-testid': `confirmDelete:${user.id}`,
        type: 'danger',
      },
      cancelText: 'Cancel',
      okCancel: true,
      autoFocusButton: null,
      onOk: () => onConfirmDelete({ userId: user.id, name }),
    });

  return [
    <Menu.Item
      key={`edit:${user.id}`}
      data-testid={`edit:${user.id}`}
      onClick={() => onEdit(user.id)}
      disabled={loading}
    >
      {translateText('corporateAccounts.actions.edit')}
    </Menu.Item>,
    showInviteAction && (
      <Menu.Item
        key={`activate:${user.id}`}
        data-testid={`sendActivation:${user.id}`}
        onClick={() => onActivate({ userId: user.id, email: user.email })}
        disabled={loading}
      >
        {translateText('corporateAccounts.actions.sendInvite')}
      </Menu.Item>
    ),
    <Menu.Item
      key={`delete:${user.id}`}
      data-testid={`delete:${user.id}`}
      onClick={handleDeleteAction}
      disabled={loading}
      danger
    >
      {translateText('corporateAccounts.actions.delete')}
    </Menu.Item>,
  ];
};

const tableColumns = ({
  currentUserAccountId,
  filterInput,
  isActionLoading,
  onSendActivation,
  onConfirmDelete,
  onShowEdit,
  translateText,
}) => {
  return [
    {
      title: 'Id',
      dataIndex: 'id',
    },
    {
      title: translateText('corporateAccounts.admins.firstName'),
      dataIndex: 'firstName',
    },
    {
      title: translateText('corporateAccounts.admins.lastName'),
      dataIndex: 'lastName',
    },
    {
      title: translateText('corporateAccounts.admins.email'),
      sorter: (a, b) => tableUtil.stringSort(a, b, 'email'),
      dataIndex: 'email',
      defaultSortOrder: 'ascend',
    },
    {
      title: translateText('corporateAccounts.admins.role'),
      dataIndex: 'role',
      key: 'role',
      filters: Object.values(STAFF_USER_ROLES).map(role => {
        return {
          text: role.label,
          value: role.key,
        };
      }),
      filterInput: filterInput?.role || null,
      render: role => {
        const staffRole = STAFF_USER_ROLES[role];
        // This is for internal use only so does not need to be translated
        return staffRole ? staffRole.label : 'Unknown';
      },
    },
    {
      title: translateText('corporateAccounts.common.status'),
      dataIndex: 'status',
      key: 'status',
      filters: Object.values(STAFF_USER_STATUS).map(status => {
        return {
          text: status.label,
          value: status.label,
        };
      }),
      filterInput: filterInput?.status || null,
      render: status => {
        const staffStatus = STAFF_USER_STATUS[status];
        return staffStatus ? (
          <UtilTag
            content={translateText(`corporateAccounts.common.status.${staffStatus.key}`)}
            type={staffStatus.key}
          />
        ) : (
          'Unknown'
        );
      },
    },
    {
      title: translateText('corporateAccounts.actions'),
      key: 'actions',
      render: (text, user) => {
        const rowActions = staffUserRowActions({
          user,
          loading: isActionLoading,
          onEdit: () => {
            onShowEdit(user);
          },
          onConfirmDelete,
          onActivate: onSendActivation,
          translateText,
        });
        if (currentUserAccountId === user.userAccountId) {
          return <span data-testid="currentStaffUserRowAction">-</span>;
        }

        return (
          <Space>
            <Dropdown overlay={<Menu>{rowActions}</Menu>}>
              <Button data-testid="staffUserMoreActions">
                {translateText('corporateAccounts.admin.action.moreActions')}
                <DownOutlined />
              </Button>
            </Dropdown>
          </Space>
        );
      },
    },
  ];
};

export default tableColumns;
