import Pusher from 'pusher-js';

let pusher = null;

export function initializePusher() {
  if (!pusher) {
    pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    });
  }
}

export function getPusher() {
  return pusher;
}
