import React from 'react';

const HelpIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5 15H12.5"
      stroke="#4D4D4D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.33301 17.333H12.6663"
      stroke="#4D4D4D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.575 12.6665C12.725 11.8498 13.1167 11.2165 13.75 10.5832C14.1524 10.2128 14.4718 9.76136 14.6872 9.25866C14.9027 8.75596 15.0093 8.21335 15 7.6665C15 6.34042 14.4732 5.06865 13.5355 4.13097C12.5979 3.19329 11.3261 2.6665 10 2.6665C8.67392 2.6665 7.40215 3.19329 6.46447 4.13097C5.52678 5.06865 5 6.34042 5 7.6665C5 8.49984 5.19167 9.52484 6.25 10.5832C6.85338 11.1349 7.265 11.8647 7.425 12.6665"
      stroke="#4D4D4D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HelpIcon;
