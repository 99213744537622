import moment from 'moment';
import { getLocalizedMoment } from 'utils/momentUtil';

export const REPORT_TYPE = {
  LAST_SEVEN: 0,
  LAST_THIRTY: 1,
  LAST_NINETY: 2,
  MONTHLY: 3,
};

const buildAvailableReportDates = (currentCorporation, translateText) => {
  const localizedMoment = getLocalizedMoment();
  if (!currentCorporation) return [];

  const MAXIMUM_OPTIONS = 38;
  const dateStart = localizedMoment().date(0); // start of current month minus 1 day
  const dateEnd = localizedMoment(currentCorporation.createdAt).startOf('month'); // corporation creation date plus one month in its start date
  const interim = dateStart.clone();
  const monthValues = [];

  const lastSevenDays = {
    label: translateText('corporateAccounts.reports.monthly.last7days'),
  };
  const lastThirtyDays = {
    label: translateText('corporateAccounts.reports.monthly.last30days'),
  };
  const lastNinetyDays = {
    label: translateText('corporateAccounts.reports.monthly.last90days'),
  };

  monthValues.push(lastSevenDays, lastThirtyDays, lastNinetyDays);

  // build by available month, up to maximum of 36 months + the 7 days and 30 days options
  while (
    (monthValues.length < MAXIMUM_OPTIONS && dateEnd < interim) ||
    interim.format('M') === dateEnd.format('M')
  ) {
    monthValues.push({ label: interim.format('MMMM YYYY') });
    interim.subtract(1, 'month');
  }
  return monthValues;
};

const getLastDaysReport = (label, numDays) => {
  const report = {
    startDate: moment()
      .startOf('day')
      .subtract(numDays, 'days')
      .utc()
      .format(),
    endDate: moment()
      .endOf('day')
      .utc()
      .format(),
  };
  report.reportName = `${label} - ${moment(report.startDate).format('YYYY-MM-DD')} - ${moment(
    report.endDate,
  ).format('YYYY-MM-DD')}`;

  return report;
};

const getMonthlyReport = (baseLabel, monthYear, translateText) => {
  const localizedMoment = getLocalizedMoment();

  // Help moment figure out how to parse the date by specifying the date format.
  // Using the 4 M's since it's what we display to the users in the drop down.
  const parsedDate = localizedMoment(monthYear, 'MMMM YYYY');
  const localEndDate = parsedDate.endOf('month').format();
  const monthLabel = monthYear.split(' ')[0];
  const yearLabel = monthYear.split(' ')[1];

  const monthlyReport = {
    startDate: parsedDate
      .startOf('month')
      .utc()
      .format(),
    endDate: moment(localEndDate)
      .endOf('day')
      .utc()
      .format(),
  };
  monthlyReport.reportName = `${baseLabel} - ${translateText(
    'corporateAccounts.reports.monthly',
  )} - ${monthLabel} (${yearLabel})`;
  return monthlyReport;
};

const getReportConfigs = (baseLabel, availableDates, index, translateText) => {
  const requestedReport = availableDates[index];
  switch (index) {
    case REPORT_TYPE.LAST_SEVEN:
      return getLastDaysReport(`${baseLabel} - ${requestedReport.label}`, 7);
    case REPORT_TYPE.LAST_THIRTY:
      return getLastDaysReport(`${baseLabel} - ${requestedReport.label}`, 30);
    case REPORT_TYPE.LAST_NINETY:
      return getLastDaysReport(`${baseLabel} - ${requestedReport.label}`, 90);
    default:
      return getMonthlyReport(baseLabel, requestedReport.label, translateText);
  }
};

const helper = {
  buildAvailableReportDates,
  getReportConfigs,
};

export default helper;
