import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useResendEmail } from 'services/reactQueryHooks/adminUsers.js';

const useVerificationPage = () => {
  const { mutate: resendEmail, isSuccess: isResendEmailSuccessful } = useResendEmail();

  const userId = useSelector(state => state.user.userId);
  const preferredLanguage = useSelector(state => state.settings.locale);

  const handleResendEmail = useCallback(
    ({ recaptchaToken }) => {
      resendEmail({ userId, preferredLanguage, recaptchaToken });
    },
    [resendEmail, userId, preferredLanguage],
  );

  return {
    isResendEmailSuccessful,
    onResendEmail: handleResendEmail,
  };
};

export default useVerificationPage;
