import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Button } from 'antd';
import useAmplitude from 'utils/hooks/useAmplitude';
import useTranslation from 'utils/hooks/useTranslation';
import useGetSupportLink from 'utils/hooks/useGetFaqLink.js';
import ExtendedModal from 'components/Shared/Modal/index.js';
import AccountActivationIcon from 'components/Shared/Icons/AccountActivationIcon';
import Spinner from 'components/Shared/Spinner';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';

import styles from './style.module.scss';

const AccountActivationModal = ({
  visible,
  onAccountActivation,
  isActivateCorporationError,
  isActivateCorporationLoading,
}) => {
  const [activationError, setActivationError] = useState(false);

  const { sendAmplitudeEvent } = useAmplitude();
  const { translateText } = useTranslation();
  const dispatch = useDispatch();
  const supportLink = useGetSupportLink();

  const logout = () => {
    dispatch({ type: 'user/LOGOUT' });
  };

  const userFeedback = feedback => {
    onAccountActivation();
    sendAmplitudeEvent(feedback);
  };

  useEffect(() => {
    isActivateCorporationError && setActivationError(true);
  }, [isActivateCorporationError]);

  const modalWidth = window.innerWidth <= 580 ? '90%' : 580;

  return (
    <ExtendedModal
      width={modalWidth}
      visible={visible}
      closable={false}
      footer={null}
      data-testid="accountActivationModal"
    >
      <div className={styles.activateAccountContainer}>
        {activationError && (
          <div className={styles.logoutButtonContainer}>
            <Button
              className={styles.logoutButton}
              style={{ border: 'none', outline: 'none !important' }}
              onClick={logout}
            >
              {translateText('corporateAccounts.topBar.profileMenu.signOut')}
            </Button>
          </div>
        )}
        <Typography.Title level={3} className={styles.activateAccountTitle}>
          {translateText('corporateAccounts.selfRegistration.activation.title')}
        </Typography.Title>
        <div className={styles.activateAccountIcon} data-testid="accountActivationIcon">
          <AccountActivationIcon />
        </div>
        <div className={styles.activateAccountDescription}>
          <Typography.Text>
            {translateText('corporateAccounts.selfRegistration.activation.description')}
          </Typography.Text>
        </div>

        {isActivateCorporationLoading ? (
          <Spinner />
        ) : (
          <>
            <div className={styles.activateAccountfeedback}>
              <Typography.Text>
                {translateText('corporateAccounts.selfRegistration.activation.feedback')}
              </Typography.Text>
            </div>
            <div className={styles.activateAccountfeedbackButtons}>
              <Button
                type="secodary"
                onClick={() => userFeedback(AMPLITUDE_EVENT_TYPES.accountActivationFeedbackEasy)}
                className={styles.activateAccountfeedbackBtn}
                data-testid="accountActivationFeedbackEasy"
              >
                {translateText('corporateAccounts.selfRegistration.activation.feedback.easy')}
              </Button>
              <Button
                type="secodary"
                onClick={() =>
                  userFeedback(AMPLITUDE_EVENT_TYPES.accountActivationFeedbackExpected)
                }
                className={styles.activateAccountfeedbackBtn}
                data-testid="accountActivationFeedbackExpected"
              >
                {translateText('corporateAccounts.selfRegistration.activation.feedback.expected')}
              </Button>
              <Button
                type="secodary"
                onClick={() =>
                  userFeedback(AMPLITUDE_EVENT_TYPES.accountActivationFeedbackDifficult)
                }
                className={styles.activateAccountfeedbackBtn}
                data-testid="accountActivationFeedbackDifficult"
              >
                {translateText('corporateAccounts.selfRegistration.activation.feedback.difficult')}
              </Button>
            </div>
          </>
        )}

        {activationError && (
          <Typography.Text
            onClick={() => {}}
            className={`${styles.buttonAsLink} ${styles.activationSupportLink}`}
          >
            <a href={supportLink} target="_blank" rel="noreferrer">
              {translateText('corporateAccounts.selfRegistration.activation.supportLink')}
            </a>
          </Typography.Text>
        )}
      </div>
    </ExtendedModal>
  );
};

export default AccountActivationModal;
