import { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { notification } from 'antd';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import useAmplitude from 'utils/hooks/useAmplitude';
import useTranslation from 'utils/hooks/useTranslation';
import { useGetMonthlyStatements } from 'services/reactQueryHooks/monthlyStatements';
import tableColumns from './tableColumns';

const getUniqueYears = statements => {
  const yearMap = {};

  statements.forEach(({ statementDate }) => {
    const year = moment(statementDate).year();
    if (!yearMap[year]) {
      yearMap[year] = true;
    }
  });

  return Object.keys(yearMap).sort((a, b) => {
    return Number(a) - Number(b);
  });
};

const useMonthlyStatementPage = () => {
  const { translateText } = useTranslation();
  const [filterYear, setFilterYear] = useState('All');
  const { data, isLoading } = useGetMonthlyStatements();
  const { sendAmplitudeEvent } = useAmplitude();
  const locale = useSelector(state => state.settings.locale);

  const availableYears = useMemo(() => {
    return getUniqueYears(data || []);
  }, [data]);

  const filterStatements = useMemo(() => {
    if (!data) {
      return [];
    }

    if (filterYear === 'All') {
      return data;
    }

    return data.filter(({ statementDate }) => {
      const year = moment(statementDate)
        .year()
        .toString();
      return year === filterYear;
    });
  }, [data, filterYear]);

  const handleFilterYear = year => {
    setFilterYear(year);
  };

  useEffect(() => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.monthlyStatementsPageView);
    return () => {
      notification.destroy();
    };
  }, [sendAmplitudeEvent]);

  return {
    statements: filterStatements,
    loading: isLoading,
    filterYear,
    availableYears,
    columns: tableColumns(translateText, locale),
    onFilterYearChange: handleFilterYear,
  };
};

export default useMonthlyStatementPage;
