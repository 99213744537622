import { USER_REGISTRATION_ERRORS, USER_VERIFICATION_ERRORS } from 'constants/users';

export const getUserRegistrationError = serverErrors => {
  const defaultUserRegistrationError = 'InternalError';

  const domainErrorCode = serverErrors?.domainErrorCode;
  if (!domainErrorCode) return null;

  const errorMessage =
    Object.keys(USER_REGISTRATION_ERRORS).find(
      err => USER_REGISTRATION_ERRORS[err] === domainErrorCode,
    ) ?? defaultUserRegistrationError;

  return errorMessage;
};

export const getUserVerificationError = serverErrors => {
  const defaultUserVerificationError = 'InternalError';

  const domainErrorCode = serverErrors?.domainErrorCode;
  if (!domainErrorCode) return null;

  const errorMessage =
    Object.keys(USER_VERIFICATION_ERRORS).find(
      err => USER_VERIFICATION_ERRORS[err] === domainErrorCode,
    ) ?? defaultUserVerificationError;

  return errorMessage;
};

export const getQueryParameters = queryString => {
  const regex = /\b([vp])=([^&]*)/g;
  let match;
  let result = {};
  while ((match = regex.exec(queryString)) !== null) {
    result[match[1]] = match[2];
  }

  return result;
};
