import { message as antdMessage } from 'antd';
import { v4 as uuidV4 } from 'uuid';
import { InfoIcon } from 'components/Shared/Icons';
import NotificationContent, {
  ErrorNotificationWithWorkflowId,
} from '../components/Layout/NotificationContent';

import styles from '../components/Layout/NotificationContent/style.module.scss';

const DEFAULT_TYPE = 'info';
const INFINITE_TIME = 0;

const messageTypes = {
  info: 'info',
  success: 'success',
  warning: 'warning',
  error: 'error',
};

const messageDuration = {
  info: 4,
  success: 4,
  warning: 4,
  error: 4,
};

const notificationStyles = {
  info: styles.info,
  success: styles.success,
  error: styles.error,
  warning: styles.warning,
};

export const messageActions = {
  closeMessage: 'closeMessage',
};

const notify = ({
  type,
  message,
  title = null,
  requiredAction = null,
  csvFileInformation = null,
  icon = null,
}) => {
  const messageType = messageTypes[type] ?? messageTypes[DEFAULT_TYPE];
  const messageKey = uuidV4();
  const userAction = messageActions[requiredAction] || null;
  const onClose = () => {
    antdMessage.destroy(messageKey);
  };

  const messageConfig = {
    key: messageKey,
    duration: userAction ? INFINITE_TIME : messageDuration[messageType],
    content: NotificationContent(message, title, onClose, csvFileInformation),
    className: notificationStyles[messageType],
    icon: icon ? <span className={styles.icon}>{icon}</span> : null,
  };

  antdMessage[messageType](messageConfig);
};

export const notifyErrorWithWorkflowId = ({ errorCodeTitle, message, workflowId }) => {
  const messageType = 'error';
  const messageKey = uuidV4();

  const onClose = () => {
    antdMessage.destroy(messageKey);
  };

  const messageConfig = {
    key: messageKey,
    duration: INFINITE_TIME,
    content: ErrorNotificationWithWorkflowId({ errorCodeTitle, message, workflowId, onClose }),
    className: notificationStyles[messageType],
    icon: <span className={styles.icon}>{<InfoIcon color="#CD3D37" />}</span>,
  };

  antdMessage[messageType](messageConfig);
};

export default notify;
