import { DRIVER_ONBOARDING_ERRORS } from 'constants/drivers';

const getQueryParameters = queryString => {
  const regex = /([ra])=([^&]*)/g;
  let match;
  let result = {};
  while ((match = regex.exec(queryString)) !== null) {
    result[match[1]] = match[2];
  }
  return result;
};

const getHashParameters = hashString => {
  const params = {};

  const hashParameters = new URLSearchParams(hashString.substring(1));
  hashParameters.forEach((value, key) => {
    params[key] = value;
  });

  return params;
};

export const getOnboardingErrorMessage = serverErrors => {
  const domainErrorCode = serverErrors?.domainErrorCode;
  if (!domainErrorCode) return null;

  let errorMessage = null;

  if (domainErrorCode === 'Validation') {
    errorMessage = Object.keys(DRIVER_ONBOARDING_ERRORS).find(err =>
      Object.keys(serverErrors.errors).find(
        serverError => DRIVER_ONBOARDING_ERRORS[err] === serverError,
      ),
    );
  } else {
    errorMessage = Object.keys(DRIVER_ONBOARDING_ERRORS).find(
      err => DRIVER_ONBOARDING_ERRORS[err] === serverErrors.domainErrorCode,
    );
  }

  return errorMessage ?? null;
};

const onboardingHelper = {
  getQueryParameters,
  getHashParameters,
  getOnboardingErrorMessage,
};

export default onboardingHelper;
