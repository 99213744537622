import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import PageTitle from 'components/Shared/Page/Title';
import BulkUpload from 'components/Shared/BulkUpload';
import Authorize from 'components/Providers/Authorize';
import withPusher from 'components/Providers/Pusher/withPusher';
import useTranslation from 'utils/hooks/useTranslation';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import useAmplitude from 'utils/hooks/useAmplitude';
import useOnboardingProgressBar from 'components/Shared/OnboardingProgressBar/useOnboardingProgressBar';
import {
  ENDPOINT_VEHICLES_IMPORT,
  ROUTE_VEHICLES,
  ROUTE_DEFAULT,
  VEHICLES_IMPORT,
} from 'constants.js';
import actions from 'redux/vehicles/actions';
import PageWrapper from 'components/Shared/Page/Wrapper';

const VehiclesUpload = ({ history, importSuccess, submitting, limitExceeded }) => {
  const { sendAmplitudeEvent } = useAmplitude();
  const { translateText } = useTranslation();
  const dispatch = useDispatch();
  const { onGetOnboardingProgress } = useOnboardingProgressBar();
  const [fileList, setFileList] = useState([]);

  const handleBackToVehicles = () => {
    dispatch({ type: actions.IMPORT_CANCEL });
    history.push(ROUTE_VEHICLES);
  };

  const importVehicles = (files, id, endpoint) => {
    sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.vehicleFinalImport);
    dispatch({ type: actions.IMPORT, payload: { files, id, endpoint } });
    onGetOnboardingProgress();
  };

  useEffect(() => {
    if (importSuccess) {
      setFileList([]);
    }
  }, [importSuccess]);

  useEffect(() => {
    if (limitExceeded) {
      sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.vehicleAddLimitExceededError);
    }
  }, [limitExceeded, sendAmplitudeEvent]);

  const stepsContent = [
    {
      title: translateText('corporateAccounts.import.step.2.title'),
      content: translateText('corporateAccounts.vehicles.import.step.2.content'),
      key: '2',
    },
    {
      title: translateText('corporateAccounts.import.step.3.title'),
      content: translateText('corporateAccounts.import.step.5.content'),
      key: '3',
    },
  ];

  return (
    <Authorize redirect to={ROUTE_DEFAULT}>
      <Helmet title="Vehicles Upload" />

      <PageWrapper history={history}>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <PageTitle
                title={translateText('corporateAccounts.vehicles.import.title')}
                description={translateText('corporateAccounts.vehicles.import.description')}
              />
              <div className="card-body">
                <BulkUpload
                  stepsContent={stepsContent}
                  fileList={fileList}
                  importEndpoint={ENDPOINT_VEHICLES_IMPORT}
                  importFunc={importVehicles}
                  onCancel={handleBackToVehicles}
                  setFileList={setFileList}
                  submitting={submitting}
                  importFrom={VEHICLES_IMPORT}
                  importFileName="corporateAccounts.vehicles.import.fileName"
                />
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </Authorize>
  );
};

export default withPusher(
  connect(({ vehicles, user }) => ({
    importSuccess: vehicles.importSuccess,
    submitting: vehicles.submitting,
    currentCorporation: user.currentCorporation,
    limitExceeded: vehicles.limitExceeded,
  }))(withRouter(injectIntl(VehiclesUpload))),
);
