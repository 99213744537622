import { datadogLogs } from '@datadog/browser-logs';
import { DATADOG_CLIENT_TOKEN, PORTAL_ENV } from '../constants';

const duplicateErrorLogsToIgnore = [
  'console error: Error: Request failed with status code 400',
  'console error: Error: Request failed with status code 409',
  'console error: Error: Network Error',
];

export const initializeDatadog = () => {
  datadogLogs.init({
    clientToken: DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    isCollectingError: true,
    sampleRate: 100,
    forwardErrorsToLogs: true,
    beforeSend: log => {
      if (duplicateErrorLogsToIgnore.includes(log.message)) {
        return false;
      }

      //mask FB sign in access_token
      if (log?.view?.url) {
        log.view.url = log.view.url
          .replace(/access_token=[^&]*/, 'access_token=REDACTED')
          .replace(/code=[^&]*/, 'code=REDACTED')
          .replace(/long_lived_token=[^&]*/, 'long_lived_token=REDACTED');
      }

      if (log?.view?.url_details?.hash) {
        delete log.view.url_details.hash;
      }

      if (log?.user) {
        log.user.access_token = 'REDACTED';
        log.user.id_token = 'REDACTED';
      }
    },
  });

  datadogLogs.addLoggerGlobalContext('service', 'corporateAccountsWebAdminPortal');
  datadogLogs.addLoggerGlobalContext('host', PORTAL_ENV);
  datadogLogs.addLoggerGlobalContext('env', PORTAL_ENV);
};
