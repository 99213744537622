import React from 'react';
import styles from './style.module.scss';
import { staffOnlyMenuItems } from './helper';
import MenuGenerator from './MenuGenerator';

const StaffOnlyMenu = ({ onMenuClick, selectedKeys, theme, isAdmin }) => {
  return (
    <div>
      <div className={styles.staffOnlyDivider} />
      <MenuGenerator
        testid="staffOnlyMenu"
        theme={theme}
        onMenuClick={onMenuClick}
        menuItems={staffOnlyMenuItems}
        selectedKeys={selectedKeys}
        isAdmin={isAdmin}
      />
    </div>
  );
};

export default StaffOnlyMenu;
