import { createUserManager } from 'redux-oidc';
import { WebStorageStateStore } from 'oidc-client';

const userManagerConfig = {
  client_id: 'paybyphone_corporate_admin',
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }/auth/login_redirect`,
  response_type: 'code',
  scope: 'paybyphone openid',
  authority: `${process.env.REACT_APP_API_URL_IDA_AUTH}`,
  silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }/auth/silent_renew`,
  post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }/auth/logout_redirect`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  revokeAccessTokenOnSignout: true,
  includeIdTokenInSilentRenew: true,
  monitorSession: true,
  checkSessionInterval: 1000,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};

const userManager = createUserManager(userManagerConfig);

userManager.events.addAccessTokenExpiring(() => {
  console.log('token expiring...');
});

userManager.events.addUserSignedOut(() => {
  userManager.signinRedirect();
});

export default userManager;
