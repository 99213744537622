import http from 'services/http';
import userService from 'services/user';
import { ENDPOINT_REPORTS, API_URL_REPORTS_V1, ENDPOINT_REPORT_JOB } from 'constants.js';
import { endpoint } from '.';

const query = async data => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'POST',
    url: `${endpoint(ENDPOINT_REPORTS, false)}/${userService.getCorporateClientId()}`,
    headers: {
      ...authHeaders,
    },
    data,
  };

  const response = http(requestOptions);
  return response;
};

const readMonthlyReports = async () => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'GET',
    url: `${endpoint(ENDPOINT_REPORTS, true, API_URL_REPORTS_V1)}`,
    headers: {
      ...authHeaders,
    },
  };

  const response = http(requestOptions);
  return response;
};

const createMonthlyReport = async reportConfigs => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'POST',
    url: `${endpoint(ENDPOINT_REPORT_JOB, false, API_URL_REPORTS_V1)}`,
    headers: {
      ...authHeaders,
    },
    data: {
      corporateClientId: userService.getCorporateClientId(),
      userAccountId: userService.getUserFromState().id,
      ...reportConfigs,
    },
  };

  const response = http(requestOptions);
  return response;
};

const downloadReport = async jobId => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'GET',
    url: `${endpoint(ENDPOINT_REPORT_JOB, false, API_URL_REPORTS_V1)}/${jobId}`,
    headers: {
      ...authHeaders,
    },
  };

  const response = http(requestOptions);
  return response;
};

export default {
  query,
  readMonthlyReports,
  createMonthlyReport,
  downloadReport,
};
