import { useCallback, useEffect, useMemo, useState } from 'react';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import { MAX_UPLOAD_SIZE_BYTES } from 'constants.js';
import useAmplitude from 'utils/hooks/useAmplitude';
import notify from 'utils/notificationUtils.js';
import useTranslation from 'utils/hooks/useTranslation';
import { useImportDrivers } from 'services/reactQueryHooks/drivers';
import useFormatValidationErrors from './useFormatValidationErrors';

const useDriverImportModal = () => {
  const { sendAmplitudeEvent } = useAmplitude();
  const { translateText } = useTranslation();
  const { formatValidationErrors } = useFormatValidationErrors();
  const { mutate: importDrivers, reset, error, isError, isSuccess, isLoading } = useImportDrivers();

  const [fileList, setFileList] = useState([]);
  const [invitationMethodEmail, setInvitationMethodEmail] = useState(false);
  const [invitationMethodSms, setInvitationMethodSms] = useState(false);

  const handleImport = useCallback(
    recaptchaToken => {
      sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.driverFinalImport);
      const formData = new FormData();
      formData.append('File', fileList[0]);
      formData.append('InvitationByEmail', invitationMethodEmail);
      formData.append('InvitationBySms', invitationMethodSms);
      importDrivers({ formData, recaptchaToken });
    },
    [fileList, invitationMethodSms, invitationMethodEmail, importDrivers, sendAmplitudeEvent],
  );

  const validationErrors = useMemo(() => {
    const validations = error?.errors?.errors ?? null;
    if (!validations) return null;
    return formatValidationErrors(validations);
  }, [error, formatValidationErrors]);

  const resetAll = useCallback(() => {
    setFileList([]);
    setInvitationMethodSms(false);
    setInvitationMethodEmail(false);
    reset();
  }, [setFileList, setInvitationMethodSms, setInvitationMethodEmail, reset]);

  const handleBeforeFileUpload = useCallback(
    file => {
      if (file.size > MAX_UPLOAD_SIZE_BYTES) {
        notify({
          type: 'error',
          message: translateText('corporateAccounts.import.maxFileSize.error'),
        });
        return false;
      }

      setFileList([file]);
      reset();
      return false;
    },
    [setFileList, translateText, reset],
  );

  useEffect(() => {
    if (isSuccess) {
      resetAll();
    }
  }, [isSuccess, resetAll]);

  return {
    loading: isLoading,
    fileList,
    error,
    isError,
    isSuccess,
    validationErrors,
    onFileUpload: handleBeforeFileUpload,
    onImport: handleImport,
    onReset: resetAll,
    invitationMethodEmail,
    invitationMethodSms,
    setInvitationMethodSms,
    setInvitationMethodEmail,
  };
};

export default useDriverImportModal;
