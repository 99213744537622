import React, { useCallback, useEffect } from 'react';
import { Form, Button, Input, Radio } from 'antd';
import DrawerForm from 'components/Shared/DrawerForm';
import PhoneInput from 'components/Shared/PhoneInput';
import isValidPhoneNumber from 'utils/phoneUtils';
import useTranslation from 'utils/hooks/useTranslation';
import { STAFF_USER_ROLES } from 'constants/staffUsers';

const formFirstName = (firstName, translateText) => (
  <Form.Item
    name="firstName"
    initialValue={firstName ?? ''}
    validateFirst
    validateTrigger="onBlur"
    label={translateText('corporateAccounts.admins.firstName')}
    rules={[
      {
        max: 50,
        message: translateText('corporateAccounts.drivers.form.firstNameValidation'),
      },
      {
        required: true,
        message: translateText('corporateAccounts.admins.form.firstNameRequired'),
      },
    ]}
  >
    <Input
      name="firstName"
      data-testid="firstNameInput"
      placeholder={translateText('corporateAccounts.common.empty')}
    />
  </Form.Item>
);

const formLastName = (lastName, translateText) => (
  <Form.Item
    name="lastName"
    initialValue={lastName ?? ''}
    validateFirst
    validateTrigger="onBlur"
    label={translateText('corporateAccounts.admins.lastName')}
    rules={[
      {
        max: 50,
        message: translateText('corporateAccounts.drivers.form.lastNameValidation'),
      },
      {
        required: true,
        message: translateText('corporateAccounts.admins.form.lastNameRequired'),
      },
    ]}
  >
    <Input
      name="lastName"
      data-testid="lastNameInput"
      placeholder={translateText('corporateAccounts.common.empty')}
    />
  </Form.Item>
);

const formEmail = (email, translateText) => (
  <Form.Item
    name="email"
    initialValue={email ?? ''}
    validateFirst
    validateTrigger="onBlur"
    label={translateText('corporateAccounts.admins.email')}
    rules={[
      {
        required: true,
        message: translateText('corporateAccounts.admins.form.emailRequired'),
      },
      {
        type: 'email',
        message: translateText('corporateAccounts.drivers.form.emailValidation'),
      },
    ]}
  >
    <Input name="email" data-testid="emailInput" disabled={!!email} />
  </Form.Item>
);

const formPhoneNumber = (phoneNumber, handleInputChange, translateText) => {
  return (
    <Form.Item
      name="phoneNumber"
      initialValue={phoneNumber}
      validateFirst
      validateTrigger="onBlur"
      label={translateText('corporateAccounts.admins.phone')}
      rules={[
        {
          required: true,
          message: translateText('corporateAccounts.admins.form.phoneRequired'),
        },
        {
          validator: (rule, value) => (value ? isValidPhoneNumber(value) : Promise.resolve()),
          message: translateText('corporateAccounts.drivers.form.phoneValidation'),
        },
      ]}
    >
      <PhoneInput
        name="phoneNumber"
        dataTestId="phoneNumberInput"
        code="CA"
        disabled={!!phoneNumber}
        fieldValue="phoneNumber"
        onInputChange={handleInputChange}
      />
    </Form.Item>
  );
};

const formRole = (role, translateText) => {
  return (
    <Form.Item
      name="role"
      label={translateText('corporateAccounts.admins.role')}
      initialValue={role ?? STAFF_USER_ROLES.pbp_customer_success.key}
      rules={[
        {
          required: true,
          message: translateText('corporateAccounts.admins.form.roleRequired'),
        },
      ]}
    >
      <Radio.Group name="roleRadioGroup">
        {Object.keys(STAFF_USER_ROLES).map(key => (
          <Radio
            key={key}
            value={key}
            data-testid={`radio-${key}`}
            disabled={STAFF_USER_ROLES[key].disabled ?? false}
          >
            {STAFF_USER_ROLES[key].label}
          </Radio>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};

const StaffUserForm = ({ onClose, onSubmit, staffUserBeingEdited, formVisibility }) => {
  const { translateText } = useTranslation();

  const createStaffUserTitle = translateText('corporateAccounts.staff.title.add');
  const editStaffUserTitle = translateText('corporateAccounts.breadcrumbs.edit');

  const [form] = Form.useForm();
  const { setFieldsValue } = form;

  const resetForm = useCallback(() => {
    form.resetFields();
  }, [form]);

  useEffect(() => {
    resetForm();
  }, [formVisibility, resetForm]);

  const handleInputChange = useCallback(
    (fieldName, value) => {
      setFieldsValue({ [fieldName]: value });
    },
    [setFieldsValue],
  );

  return (
    <DrawerForm
      title={staffUserBeingEdited ? editStaffUserTitle : createStaffUserTitle}
      width={320}
      visible={formVisibility}
      onClose={() => {
        onClose();
      }}
    >
      <Form
        data-testid={staffUserBeingEdited ? 'editStaffUserForm' : 'createStaffUserForm'}
        form={form}
        onFinish={onSubmit}
        layout="vertical"
        style={{ paddingBottom: '100px' }}
      >
        <Form.Item name="id" initialValue={staffUserBeingEdited?.id} hidden>
          <Input name="id" />
        </Form.Item>

        {formFirstName(staffUserBeingEdited?.firstName, translateText)}
        {formLastName(staffUserBeingEdited?.lastName, translateText)}
        {formEmail(staffUserBeingEdited?.email, translateText)}
        {formPhoneNumber(staffUserBeingEdited?.phoneNumber, handleInputChange, translateText)}
        {formRole(staffUserBeingEdited?.role, translateText)}

        <Button onClick={onClose} style={{ marginRight: 8 }} disabled={false}>
          {translateText('corporateAccounts.actions.cancel')}
        </Button>
        <Button data-testid="staffUserSubmitBtn" type="primary" htmlType="submit">
          {staffUserBeingEdited
            ? translateText('corporateAccounts.actions.save')
            : translateText('corporateAccounts.breadcrumbs.create')}
        </Button>
      </Form>
    </DrawerForm>
  );
};

export default StaffUserForm;
