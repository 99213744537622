import actions from './actions';
import corporationActions from '../corporations/actions';
import userActions from '../user/actions';
import mappings from './mappings';

export const INITIAL_STATE = {
  data: [],
  formData: null,
  formErrors: [],
  loading: false,
  newPaymentAccountId: null,
  paymentCreateFailed: false,
  paymentCreateSuccess: false,
  submitting: false,
  scaOrderChallengeRequired: false,
  scaOrderChallengeHtml: null,
  scaOrderChallengeSuccess: null,
  scaOrderInitiatedGatewayHtml: null,
};

export default function paymentsSpaReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actions.GET:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        formErrors: [],
        data: action.payload,
      };
    case actions.GET_ERROR:
      return {
        ...state,
        loading: false,
        formErrors: [],
      };
    case actions.PUT:
      return {
        ...state,
        submitting: true,
      };
    case actions.PUT_SUCCESS:
      return {
        ...state,
        submitting: false,
        ...mappings.mapEdittedSpaPayment(state, action.payload),
      };
    case actions.PUT_ERROR:
      return {
        ...state,
        submitting: false,
        formErrors: action.payload || state.formErrors,
      };
    case actions.DELETE:
      return {
        ...state,
        submitting: true,
      };
    case actions.DELETE_SUCCESS:
      return {
        ...state,
        submitting: false,
        data: mappings.mapDeletedPayment(state, action.payload),
      };
    case actions.DELETE_ERROR:
      return {
        ...state,
        submitting: false,
      };
    case actions.RESET_FORM_ERRORS:
      return {
        ...state,
        formErrors: [],
        paymentCreateFailed: false,
      };
    case actions.RESET_SUCCESS:
      return {
        ...state,
        paymentCreateSuccess: false,
      };
    case actions.SET_FORM_DATA:
      return {
        ...state,
        formData: action.payload,
      };
    case corporationActions.GET:
    case userActions.LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
