import http from 'services/http';
import userService from 'services/user';
import { ENDPOINT_ADMIN_API_CONFIGURATION } from 'constants.js';
import { endpoint } from '.';

const read = async () => {
  const authHeaders = await userService.getAuthorizationHeaders();
  const requestOptions = {
    method: 'GET',
    url: endpoint(ENDPOINT_ADMIN_API_CONFIGURATION, false),
    headers: {
      ...authHeaders,
    },
  };

  const response = http(requestOptions);
  return response;
};

export default {
  read,
};
