export const USER_ACTIONS = {
  // Driver
  ADD_DRIVER: {
    label: 'Add Driver',
    value: 'AddDriver',
  },
  EDIT_DRIVER: {
    label: 'Edit Driver',
    value: 'EditDriver',
  },
  DELETE_DRIVER: {
    label: 'Remove Driver',
    value: 'RemoveDriver',
  },
  INVITE_DRIVERS: {
    label: 'Invite Drivers',
    value: 'InviteDrivers',
  },
  ACTIVATE_DRIVER: {
    label: 'Activate Driver',
    value: 'ActivateDriver',
  },
  REGISTER_DRIVER: {
    label: 'Register Driver',
    value: 'RegisterDriver',
  },
  EXPIRE_DRIVER_INVITATIONS: {
    label: 'Expire Driver Invitations',
    value: 'ExpireDriverInvitations',
  },
  BULK_IMPORT_DRIVERS: {
    label: 'Bulk Import Drivers',
    value: 'ImportDrivers',
  },
  BULK_DELETE_DRIVERS: {
    label: 'Bulk Remove Drivers',
    value: 'RemoveDrivers',
  },
  // Vehicle
  ADD_VEHICLE: {
    label: 'Add Vehicle',
    value: 'AddVehicle',
  },
  EDIT_VEHICLE: {
    label: 'Edit Vehicle',
    value: 'EditVehicle',
  },
  DELETE_VEHICLE: {
    label: 'Remove Vehicle',
    value: 'RemoveVehicle',
  },
  BULK_IMPORT_VEHICLES: {
    label: 'Bulk Import Vehicles',
    value: 'ImportVehicles',
  },
  BULK_DELETE_VEHICLES: {
    label: 'Bulk Remove Vehicles',
    value: 'RemoveVehicles',
  },
  // payment
  ADD_PAYMENT_ACCOUNT: {
    label: 'Add Payment Account',
    value: 'LinkPaymentAccount',
  },
  EDIT_PAYMENT_ACCOUNT: {
    label: 'Edit Payment Account',
    value: 'EditPaymentAccount',
  },
  DELETE_PAYMENT_ACCOUNT: {
    label: 'Delete Payment Account',
    value: 'RemovePaymentAccount',
  },
  // credit account
  APPROVE_CREDIT_ACCOUNT: {
    label: 'Approve Credit Account',
    value: 'ApproveCreditAccount',
  },
  CREATE_CREDIT_ACCOUNT: {
    label: 'Create Credit Account',
    value: 'CreateCreditAccount',
  },
  // User
};

export const ACTION_TYPES = {
  PAYMENTMETHOD: {
    label: 'corporateAccounts.admin.payment',
    value: 'PaymentMethod',
    description: 'Payment',
  },
  DRIVER: {
    label: 'corporateAccounts.common.driver',
    value: 'Driver',
    description: 'Driver',
  },
  VEHICLE: {
    label: 'corporateAccounts.common.vehicle',
    value: 'Vehicle',
    description: 'Vehicle',
  },
  USER: {
    label: 'corporateAccounts.common.user',
    value: 'User',
    description: 'User',
  },
  CREDIT_ACCOUNT: {
    label: 'corporateAccounts.creditAccount.pageTitle',
    value: 'CreditAccount',
    description: 'Credit Account',
  },
};
