import React from 'react';
import styles from '../style.module.scss';

const getBlobURL = code => {
  const blob = new Blob([code], { type: 'text/html' });
  return URL.createObjectURL(blob);
};

const ScaIframe = ({ iframeHtml }) => {
  return (
    <div className={styles.scaChallengeContainer}>
      <iframe id="sca-challenge" src={getBlobURL(iframeHtml)} title="sca-challenge" />
    </div>
  );
};

export default React.memo(ScaIframe);
