import React, { useState } from 'react';
import userManager from 'utils/userManager';
import errorLoggerUtil from 'utils/errorLoggerUtil';
import { CallbackComponent } from 'redux-oidc';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ROUTE_ACTIVATE_REDIRECT } from 'constants.js';
import userService from 'services/user';

const LoginRedirect = ({ setCurrentUser, history, logout }) => {
  const [redirectMessage, setRedirectMessage] = useState('Thank you for your patience.');

  const setUser = async user => {
    const isActivated = await userService.checkActivation();

    if (isActivated) {
      return history.push(ROUTE_ACTIVATE_REDIRECT);
    }

    await setCurrentUser(user);
    return history.push('/');
  };

  const successCallback = async user => {
    await setUser(user);
  };

  const errorCallback = async error => {
    if (error && error.error_description === 'Code Invalid Or Expired') {
      setRedirectMessage(
        'Your invitation has expired. Please reach out to your administrator for a new invitation.',
      );

      errorLoggerUtil.ddErrorLogwithContext(
        'Admin Activation Error - Invalid or Expired Invitation',
      );

      return setTimeout(() => userManager.signinRedirect(), 3000);
    }

    return userManager.signinRedirect();
  };

  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={successCallback}
      errorCallback={errorCallback}
    >
      <h2 style={{ textAlign: 'center', marginTop: 30 }}>{redirectMessage}</h2>
    </CallbackComponent>
  );
};

export default connect(
  () => ({}),
  dispatch => ({
    setCurrentUser: currentUser => {
      dispatch({ type: 'user/LOGIN', payload: currentUser });
      return Promise.resolve();
    },
    logout: () => dispatch({ type: 'user/LOGOUT' }),
  }),
)(withRouter(LoginRedirect));
