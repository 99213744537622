import isEmpty from 'lodash.isempty';
import notify from 'utils/notificationUtils';

const buildFieldErrors = (formErrors, translateText) => {
  const currentErrors = [];
  if (!isEmpty(formErrors)) {
    Object.keys(formErrors['invalid-params']).forEach(key => {
      if (key) {
        currentErrors.push({
          name: key,
          errors: [new Error(formErrors['invalid-params'][key])],
        });
      } else {
        // Field might not exist, show notification with error
        notify({
          type: 'error',
          title: translateText('corporateAccounts.common.error'),
          message: formErrors[key].errors.join(', '),
        });
        delete formErrors[key];
      }
    });
  }

  return currentErrors;
};

const formHelper = {
  buildFieldErrors,
};

export default formHelper;
