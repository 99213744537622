import React, { forwardRef } from 'react';
import IntlTelInput from 'react-intl-tel-input';
import { PREFERRED_COUNTRIES } from 'constants.js';
import 'react-intl-tel-input/dist/main.css';
import {
  getBrowserCountryBasedOnLanguage,
  getBrowserDefaultLanguage,
} from 'utils/browserLanguageCountryUtil';

const PhoneInput = (
  {
    value = '',
    onBlur,
    dataTestId,
    disabled = false,
    code,
    onInputChange,
    fieldValue,
    onlyCountries,
  },
  ref,
) => {
  const sanitizeNonNumericals = phoneNumber => {
    return phoneNumber.replace(/[^+\d]+/g, '');
  };

  const triggerChange = (valid, natlPhone, country, intlPhone, y) => {
    const formattedNumber = sanitizeNonNumericals(intlPhone);
    onInputChange(fieldValue, formattedNumber);
  };

  const countryCode = () => code?.toLowerCase();

  const defaultCountry =
    countryCode() ?? getBrowserCountryBasedOnLanguage(getBrowserDefaultLanguage());

  return (
    <IntlTelInput
      style={{ width: '100%' }}
      fieldId={fieldValue}
      containerClassName="intl-tel-input"
      inputClassName="form-control ant-input"
      format
      defaultValue={value}
      disabled={disabled}
      onPhoneNumberChange={triggerChange}
      onPhoneNumberBlur={triggerChange}
      data-testid={dataTestId}
      defaultCountry={defaultCountry}
      preferredCountries={onlyCountries ? [defaultCountry] : PREFERRED_COUNTRIES}
      onlyCountries={onlyCountries}
    />
  );
};

export default forwardRef(PhoneInput);
