import React from 'react';
import { Typography } from 'antd';
import useTranslation from 'utils/hooks/useTranslation';
import { CopyToClipboardIcon } from 'components/Shared/Icons';
import { formatDateTime } from '../../Reports/helper';
import { USER_ACTIONS } from 'constants/auditLogs.js';
import AuditDetailsTable from './AuditDetailsTable';

import styles from '../style.module.scss';

const AuditDetailsModalContent = ({ data }) => {
  const { translateText } = useTranslation();

  if (!data) return null;
  const { updatedEntityLog, originalEntityLog } = data;
  if (!updatedEntityLog && !updatedEntityLog?.entityType) return null;

  const getUserAction = action => {
    const foundAction = Object.values(USER_ACTIONS).find(item => item.value === action);
    return foundAction ? foundAction.label : '-';
  };

  const user =
    updatedEntityLog.performedByUserFirstName && updatedEntityLog.performedByUserLastName
      ? `${updatedEntityLog.performedByUserFirstName} ${updatedEntityLog.performedByUserLastName}`
      : updatedEntityLog.message?.message?.firstname && updatedEntityLog.message?.message?.lastname
      ? `${updatedEntityLog.message.message.firstname} ${updatedEntityLog.message.message.lastname}`
      : '-';

  const auditLogDescription = translateText(
    'corporateAccounts.auditLogs.auditDetails.description',
    {
      auditLogUserAction: getUserAction(updatedEntityLog.performedAction),
      auditLogDate: formatDateTime(updatedEntityLog.performedActionDate),
      user,
    },
  );

  const truncateDetails = details => {
    const maxLength = 30;
    if (details?.length > maxLength) {
      return details?.substring(0, maxLength) + '...';
    }
    return details;
  };

  return (
    <div data-testid="auditDetailModalContent">
      <Typography className={styles.auditDetailsTitle}>
        <span>{updatedEntityLog.entityType}</span>
        {` ${truncateDetails(updatedEntityLog.details)}`}
      </Typography>
      <div className={styles.auditDetailsDescription}>
        <Typography>{auditLogDescription}</Typography>
        <div className={styles.auditDetailsId}>
          <Typography>
            {translateText('corporateAccounts.auditLogs.auditDetails.id')}
            <span>:</span>
          </Typography>
          {updatedEntityLog.entityId}
          <a
            className={styles.auditDetailsIcon}
            onClick={() => {
              navigator.clipboard.writeText(updatedEntityLog.entityId);
            }}
          >
            <CopyToClipboardIcon />
          </a>
        </div>
        <div className={styles.auditDetailsId}>
          <Typography>
            {translateText('corporateAccounts.auditLogs.auditDetails.workflowId')}
            <span>:</span>
          </Typography>
          {updatedEntityLog.workflowId}
          <a
            className={styles.auditDetailsIcon}
            onClick={() => {
              navigator.clipboard.writeText(updatedEntityLog.workflowId);
            }}
          >
            <CopyToClipboardIcon />
          </a>
        </div>
      </div>
      <AuditDetailsTable
        updatedEntityLog={updatedEntityLog}
        originalEntityLog={originalEntityLog}
      />
    </div>
  );
};

export default AuditDetailsModalContent;
