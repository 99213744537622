import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import { Form, Button, Col, Row, Steps, Input, Tabs } from 'antd';
import useAmplitude from 'utils/hooks/useAmplitude';
import useTranslation from 'utils/hooks/useTranslation';
import { CORP_CREATE_STEPS } from 'constants/corporateClients';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import ProfileStep from './ProfileStep';
import CorporationInfoStep from './CorporationInfoStep';

import util from './util';

const { Step } = Steps;
const { TabPane } = Tabs;

const CorporationForm = ({ formData, onCancel, onFormDataChange, onSave }) => {
  const { sendAmplitudeEvent } = useAmplitude();
  const { translateText } = useTranslation();

  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const [areAllStepsComplete, setAreAllStepsComplete] = useState(false);
  const [currentStep, setCurrentStep] = useState(CORP_CREATE_STEPS.PROFILE.STEPINDEX);
  const [activeKey, setActiveKey] = useState(CORP_CREATE_STEPS.PROFILE.TABKEY);

  const dispatch = useDispatch();

  const corporationsSelector = useSelector(state => state.corporations);
  const { submitting, success } = corporationsSelector;

  // Helper to set both Step and Tab current values.
  const setActivePage = tabKey => {
    if (tabKey === CORP_CREATE_STEPS.PROFILE.TABKEY) {
      setActiveKey(CORP_CREATE_STEPS.PROFILE.TABKEY);
      setCurrentStep(CORP_CREATE_STEPS.PROFILE.STEPINDEX);
    } else {
      setActiveKey(CORP_CREATE_STEPS.INFO.TABKEY);
      setCurrentStep(CORP_CREATE_STEPS.INFO.STEPINDEX);
    }
  };

  const handleStepChange = async step => {
    if (activeKey === CORP_CREATE_STEPS.INFO.TABKEY) {
      sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.accountCreate);
    }

    // No need to validate if moving to the prev step.
    if (step === CORP_CREATE_STEPS.PROFILE.STEPINDEX) {
      setActivePage(CORP_CREATE_STEPS.PROFILE.TABKEY);
      return;
    }

    await util.handleNext({
      form,
      onFormDataChange,
      onSave,
      setAreAllStepsComplete,
      activeKey,
      setActivePage,
    });
  };

  const resetCorporateClient = useCallback(() => {
    dispatch({ type: 'corporations/RESET' });
    onCancel();
  }, [dispatch, onCancel]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: {
        span: 6,
        offset: 2,
      },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: {
        span: 12,
      },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: { span: 24 },
      sm: {
        span: 6,
        offset: 16,
      },
    },
  };

  const getPrimaryButtonText = () => {
    if (activeKey === CORP_CREATE_STEPS.PROFILE.TABKEY)
      return translateText('corporateAccounts.actions.continue');

    return translateText('corporateAccounts.corporateClient.createAccount');
  };

  useEffect(() => {
    if (!isEmpty(formData)) {
      setFieldsValue(formData);
    }
  }, [formData, setFieldsValue]);

  useEffect(() => {
    if (areAllStepsComplete && success) resetCorporateClient();
  }, [areAllStepsComplete, success, resetCorporateClient]);

  const renderSteps = () => {
    return (
      <Row style={{ marginBottom: '15px' }}>
        <Col span={6} offset={10}>
          <Steps responsive current={currentStep} onChange={current => handleStepChange(current)}>
            <Step title={translateText('corporateAccounts.admins.role.primary_fleet_manager')} />
            <Step title={translateText('corporateAccounts.admin.corporation')} />
          </Steps>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Form form={form} {...formItemLayout} style={{ marginTop: '10.5px' }}>
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>

        {/* STEP 1 - Profile */}
        <Tabs renderTabBar={renderSteps} activeKey={activeKey}>
          <TabPane key={CORP_CREATE_STEPS.PROFILE.TABKEY}>
            <ProfileStep form={form} />
          </TabPane>

          {/* STEP 2 - CORPORATION */}
          <TabPane key={CORP_CREATE_STEPS.INFO.TABKEY}>
            <CorporationInfoStep form={form} />
          </TabPane>
        </Tabs>

        <Form.Item {...tailFormItemLayout}>
          <Button onClick={resetCorporateClient} style={{ marginRight: 10 }}>
            {translateText('corporateAccounts.actions.cancel')}
          </Button>

          <Button onClick={handleStepChange} type="primary" loading={submitting}>
            {getPrimaryButtonText(currentStep)}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default withRouter(injectIntl(CorporationForm));
