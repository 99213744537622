export const DOMAIN_ERRORS = {
  RestrictedCorporateClient: {
    type: 'error',
    code: 'RestrictedCorporateClient',
  },
};

export default {
  DOMAIN_ERRORS,
};
