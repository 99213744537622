import React from 'react';
import { Button } from 'antd';
import FacebookSvg from 'assets/icons/facebookLogo.svg';
import { SOCIAL_SIGN_IN_REDIRECT_URL, FACEBOOK_SIGN_IN_APP_ID } from 'constants.js';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import useAmplitude from 'utils/hooks/useAmplitude';

import styles from './style.module.scss';

const FacebookSignInButton = ({ invitationCode, enableFacebookSignIn }) => {
  const { sendAmplitudeEvent } = useAmplitude();

  const facebookSignInUrl = `https://www.facebook.com/v16.0/dialog/oauth?client_id=${FACEBOOK_SIGN_IN_APP_ID}&redirect_uri=${SOCIAL_SIGN_IN_REDIRECT_URL}&response_type=code%20token,granted_scopes&locale=en_US&scope=email&auth_type=rerequest&state=${invitationCode}`;

  return (
    <div>
      {enableFacebookSignIn && (
        <Button
          className={styles.facebookSignInButton}
          data-testid="facebook-sign-in"
          href={facebookSignInUrl}
          target="_self"
          onClick={() =>
            sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.onboardingDriverFacebookSignInButton)
          }
        >
          <img className={styles.facebookSignImg} src={FacebookSvg} alt="" />
        </Button>
      )}
    </div>
  );
};

export default FacebookSignInButton;
