import React from 'react';
import Loader from 'components/Shared/Loader';
import OnboardingProgressBar from 'components/Shared/OnboardingProgressBar/index.js';

const PageWrapper = ({ children, loading, history }) => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <OnboardingProgressBar history={history} />
        <div className="card">
          {children}
          {loading && (
            <div className="card-body">
              <Loader spinning />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageWrapper;
