import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Loader from 'components/Shared/Loader';
import cacheUtil from 'utils/cacheUtil';

global.appVersion = `${process.env.REACT_APP_BUILD_VERSION}-${process.env.REACT_APP_JOB_VERSION}`;

const CacheBuster = ({ children, isUserAuthorized }) => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function checkCache() {
      try {
        if (isUserAuthorized) {
          const res = await axios({
            method: 'GET',
            url: '/meta.json',
            headers: { 'Cache-Control': 'no-store, max-age=0' },
          });
          const latestVersion = res.data.version;
          const currentVersion = global.appVersion;

          if (latestVersion !== currentVersion && process.env.NODE_ENV !== 'development') {
            cacheUtil.refreshCacheAndReload();
          } else {
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
      }
    }

    checkCache();
  }, [isUserAuthorized, location]);

  if (loading) return <Loader spinning />;
  return children;
};

export default CacheBuster;
