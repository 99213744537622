import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from 'antd';
import useTranslation from 'utils/hooks/useTranslation';
import { OPERATING_SYSTEM } from 'constants/operatingSystem';
import FacebookSignInButton from './FacebookSignInButton';

import styles from './style.module.scss';

const SocialSignIn = ({ renderAppleSignInButton, enableFacebookSignIn, registrationCode }) => {
  const { operatingSystem } = useSelector(state => state.settings);
  const { translateText } = useTranslation();

  useEffect(() => {
    renderAppleSignInButton && renderAppleSignInButton();
  });

  return (
    <div className={styles.socialSignIn}>
      <Typography.Paragraph>
        <p>{translateText('corporateAccounts.onboarding.socialSignIn.instruction')}</p>
      </Typography.Paragraph>
      <div className={styles.socialSignInButtons}>
        {operatingSystem !== OPERATING_SYSTEM.Android && (
          <div
            id="appleid-signin"
            data-mode="logo-only"
            data-type="sign-in"
            data-border-radius="50"
            className={styles.appleSignInButton}
          ></div>
        )}
        {enableFacebookSignIn && (
          <FacebookSignInButton
            invitationCode={registrationCode}
            enableFacebookSignIn={enableFacebookSignIn}
          />
        )}
      </div>
    </div>
  );
};

export default SocialSignIn;
