import userService from 'services/user';
import { API_URL_CA, ENDPOINT_CORPORATE_CLIENTS } from 'constants.js';

/* eslint-disable import/no-cycle */
export { default as corporateClients } from './corporateClients';
export { default as staffUser } from './staffUsers';
export { default as adminUsers } from './adminUsers';
export { default as vehicles } from './vehicles';
export { default as drivers } from './drivers';
export { default as eventsProxy } from './eventsProxy';
export { default as paymentsSpa } from './paymentsSpa';
export { default as sessions } from './sessions';
export { default as reports } from './reports';
export { default as imports } from './imports';
export { default as invitations } from './invitations';
export { default as configuration } from './configuration';
/* eslint-disable import/no-cycle */

export const endpoint = (path, appendClientId = true, root = API_URL_CA) =>
  `${root}${appendClientId ? `/${userService.getCorporateClientId()}` : ''}${path}`;

export const endpointCorporateClient = (path, appendClientId = true, root = API_URL_CA) =>
  `${root}${ENDPOINT_CORPORATE_CLIENTS}${
    appendClientId ? `/${userService.getCorporateClientId()}` : ''
  }${path}`;
