import React from 'react';
import { Modal, Typography, Button } from 'antd';
import useTranslation from 'utils/hooks/useTranslation';
import { useDispatch, useSelector } from 'react-redux';
import { ALLSTAR_CREDIT_ACCOUNT_URL } from 'constants.js';

import styles from './style.module.scss';

const CreditAccountInfoModal = () => {
  const { translateText } = useTranslation();
  const { creditAccountMigrationConfig, corporateClientId } = useSelector(state => state.user);
  const dispatch = useDispatch();

  const handleVisibility = () => {
    creditAccountMigrationConfig &&
      dispatch({
        type: 'user/SET_STATE',
        payload: {
          creditAccountMigrationConfig: {
            ...creditAccountMigrationConfig,
            isCreditAccountInfoModalVisible: false,
          },
        },
      });
  };

  return (
    <Modal
      title={
        <Typography component="h2" className={styles.modalTitle}>
          {translateText('corporateAccounts.creditAccounts.modal.title')}
        </Typography>
      }
      width={600}
      visible={creditAccountMigrationConfig?.isCreditAccountInfoModalVisible}
      className={styles.modalContainer}
      centered
      closable={false}
      data-testid="creditAccountInfoModal"
      footer={[
        <div key="footer" className={styles.footer}>
          <a href={`${ALLSTAR_CREDIT_ACCOUNT_URL}/ete/pbp?pbpClientId=${corporateClientId}`}>
            <Button
              key="ok"
              type="primary"
              onClick={handleVisibility}
              className={`${styles.sharedButton}`}
              data-testid="creditAccountBtn"
            >
              {translateText('corporateAccounts.creditAccounts.modal.cta')}
            </Button>
          </a>
          <Button
            key="cancel"
            onClick={handleVisibility}
            className={`${styles.sharedButton} ${styles.cancelButton}`}
          >
            {translateText('corporateAccounts.common.status.close')}
          </Button>
        </div>,
      ]}
    >
      <div className={styles.content}>
        <Typography>
          {translateText('corporateAccounts.creditAccounts.modal.description1')}
        </Typography>
      </div>
      <img
        src="/resources/images/credit_account_info_modal.png"
        alt="Credit account preview"
        className={styles.image}
      />
      <div className={styles.content}>
        <Typography>
          {translateText('corporateAccounts.creditAccounts.modal.description2')}
        </Typography>
        <Typography>
          <span className={styles.bulletPoints}> &bull; </span>
          {translateText('corporateAccounts.creditAccounts.modal.description3')}
        </Typography>
        <Typography>
          <span className={styles.bulletPoints}> &bull; </span>
          {translateText('corporateAccounts.creditAccounts.modal.description4')}
        </Typography>
        <Typography className={styles.link}>
          <a href="https://www.google.com" rel="noreferrer" target="_blank" className={styles.link}>
            {translateText('corporateAccounts.payments.creditAccountBanner.description.cta')}
          </a>
        </Typography>
      </div>
    </Modal>
  );
};

export default CreditAccountInfoModal;
