const actions = {
  GET: 'corporations/GET',
  GET_BY_ID: 'corporations/GET_BY_ID',
  GET_BY_ID_SUCCESS: 'corporations/GET_BY_ID_SUCCESS',
  GET_BY_ID_ERROR: 'corporations/GET_BY_ID_ERROR',
  POST: 'corporations/POST',
  POST_SUCCESS: 'corporations/POST_SUCCESS',
  POST_ERROR: 'corporations/POST_ERROR',
  PUT: 'corporations/PUT',
  PUT_SUCCESS: 'corporations/PUT_SUCCESS',
  PUT_ERROR: 'corporations/PUT_ERROR',
  RESET: 'corporations/RESET',
  RESET_EDIT_CORPORATION: 'corporations/RESET_EDIT_CORPORATION',
  RESET_ERRORS: 'corporations/RESET_ERRORS',
  DELETE_ERROR: 'corporations/DELETE_ERROR',
};

export default actions;
