import { Modal, notification } from 'antd';
import { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import antdUtil from 'utils/antdUtil';
import useTranslation from 'utils/hooks/useTranslation';
import userActions from 'redux/user/actions';
import {
  useGetStaffUsers,
  useDeleteStaffUser,
  useSendStaffActivation,
} from 'services/reactQueryHooks/staffUsers';

import tableColumns from './tableColumns';

const INITIAL_STATE = {
  fullStaffUsers: [],
  staffUsers: [],
  filters: {},
  search: '',
};

const filterUserBySearch = (user, search) => {
  const searchRegex = new RegExp(search, 'gi');
  const firstNameMatch = searchRegex.test(user.firstName);
  const lastNameMatch = searchRegex.test(user.lastName);
  const staffUserIdMatch = searchRegex.test(user.id);
  const emailMatch = searchRegex.test(user.email);
  return firstNameMatch || lastNameMatch || staffUserIdMatch || emailMatch;
};

const filterStaffUsersBySearchCriteria = (staffUsers, { search, filters }) => {
  const filteredUsers = antdUtil.filterTableData(staffUsers, filters);
  if (search) {
    return filteredUsers.filter(user => filterUserBySearch(user, search));
  }

  return filteredUsers;
};

const staffManagementReducer = (state, { type, payload }) => {
  switch (type) {
    case 'load':
      state.fullStaffUsers = payload?.data || [];
      break;
    case 'filters':
      state.filters = payload.filters;
      break;
    case 'search':
      state.search = payload.search;
      break;
    default:
      throw new Error('Unknown action');
  }

  state.staffUsers = filterStaffUsersBySearchCriteria(state.fullStaffUsers, {
    filters: state.filters,
    search: state.search,
  });
  return { ...state };
};

const useStaffUserPage = ({ onShowEdit }) => {
  const { translateText } = useTranslation();

  const dispatch = useDispatch();

  const { id: userAccountId } = useSelector(state => state.user);
  const { mutate: sendActivation, isLoading: isSendActivationLoading } = useSendStaffActivation();
  const { mutate: deleteStaffUser, isLoading: isDeleteLoading } = useDeleteStaffUser();
  const { data, isLoading } = useGetStaffUsers();

  const [{ staffUsers, filters }, staffDispatch] = useReducer(
    staffManagementReducer,
    INITIAL_STATE,
  );

  useEffect(() => {
    dispatch({
      type: userActions.SET_STATE,
      payload: {
        corporateClientId: null,
        currentCorporation: null,
      },
    });
    return () => {
      Modal.destroyAll();
      notification.destroy();
    };
  }, [dispatch]);

  useEffect(() => {
    staffDispatch({ type: 'load', payload: { data } });
  }, [data, staffDispatch]);

  return {
    staffUsers,
    columns: tableColumns({
      currentUserAccountId: userAccountId,
      isActionLoading: isSendActivationLoading || isDeleteLoading,
      onSendActivation: sendActivation,
      filterInput: filters,
      onConfirmDelete: deleteStaffUser,
      onShowEdit,
      translateText,
    }),
    loading: isLoading,
    onSearch: search => {
      staffDispatch({
        type: 'search',
        payload: {
          search,
        },
      });
    },
    onTableChange: tableFilters => {
      staffDispatch({
        type: 'filters',
        payload: {
          filters: tableFilters,
        },
      });
    },
  };
};

export default useStaffUserPage;
