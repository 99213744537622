import React from 'react';
import { Typography } from 'antd';
import { SuccessIcon } from 'components/Shared/Icons';
import useTranslation from 'utils/hooks/useTranslation';

import styles from './style.module.scss';
import OpenAppButton from 'components/Shared/Buttons/OpenAppButton';

const DriverAlreadyActive = () => {
  const { translateText } = useTranslation();

  return (
    <div className={styles.notificationPage} data-testid="driverAlreadyActive">
      <div className={styles.notificationIcon} data-testid="successIcon">
        <SuccessIcon />
      </div>
      <Typography.Title level={4} className={styles.notificationSuccessTitle}>
        {translateText('corporateAccounts.onboarding.driverAlreadyActive')}
      </Typography.Title>
      <Typography.Paragraph>
        <p> {translateText('corporateAccounts.onboarding.driverAlreadyActive.message')}</p>
      </Typography.Paragraph>
      <div className={styles.activationMessage}>
        <Typography.Paragraph>
          {translateText('corporateAccounts.onboarding.logInToStartParking')}
        </Typography.Paragraph>
      </div>
      <OpenAppButton styles={styles} />
    </div>
  );
};

export default DriverAlreadyActive;
