import { Typography } from 'antd';
import useTranslation from 'utils/hooks/useTranslation';
import {
  CORPORATE_CLIENT_PROSPECT_STATUS,
  CORPORATE_CLIENT_ACTIVE_STATUS,
  CORPORATE_CLIENT_DEACTIVATED_STATUS,
} from 'constants.js';
import styles from './style.module.scss';

const { Title } = Typography;

const StatusIndicator = ({ corporationStatusName }) => {
  const { translateText } = useTranslation();

  const renderStatus = statusName => {
    switch (statusName) {
      case CORPORATE_CLIENT_PROSPECT_STATUS:
        return translateText('corporateAccounts.common.status.onboarding');
      case CORPORATE_CLIENT_ACTIVE_STATUS:
        return translateText('corporateAccounts.common.status.active');
      case CORPORATE_CLIENT_DEACTIVATED_STATUS:
        return translateText('corporateAccounts.common.status.deactivated');
      default:
        break;
    }
  };

  const getStatusCircleClass = statusName => {
    switch (statusName) {
      case CORPORATE_CLIENT_PROSPECT_STATUS:
        return styles.statusCircleProspect;
      case CORPORATE_CLIENT_ACTIVE_STATUS:
        return styles.statusCircleActive;
      case CORPORATE_CLIENT_DEACTIVATED_STATUS:
        return styles.statusCircleDeactivated;
      default:
        break;
    }
  };

  return (
    <div className={styles.statusIndicator}>
      <div className={styles.statusCircleContainer}>
        {
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <circle
              cx="8"
              cy="8"
              r="8"
              className={getStatusCircleClass(corporationStatusName)}
              data-testid="statusIndicatorCircle"
            />
          </svg>
        }
      </div>
      <Title level={3}>{renderStatus(corporationStatusName)}</Title>
    </div>
  );
};

export default StatusIndicator;
