import React from 'react';
import { USER_ROLES } from 'constants/users.js';
import { USER_ACTIONS, ACTION_TYPES } from 'constants/auditLogs.js';
import { CopyToClipboardIcon } from 'components/Shared/Icons';
import { formatDateTime } from '../Reports/helper';
import auditLogsHelper from './helper';

import styles from './style.module.scss';

const isActionPerformedByDriver = performedAction =>
  performedAction === USER_ACTIONS.REGISTER_DRIVER.value ||
  performedAction === USER_ACTIONS.ACTIVATE_DRIVER.value;

const processUserColumn = ({ firstName, lastName, record, translateText }) => {
  const user = isActionPerformedByDriver(record.performedAction)
    ? translateText('corporateAccounts.common.driver')
    : firstName && lastName
    ? `${firstName} ${lastName}`
    : '-';

  return user;
};

const processRoleColumn = ({ value, record, translateText }) => {
  const role = isActionPerformedByDriver(record.performedAction)
    ? translateText('corporateAccounts.common.driver')
    : value
    ? auditLogsHelper.getTableItemTranslation({
        translateText,
        item: value,
        mappingObject: USER_ROLES,
      })
    : '-';

  return role;
};

const tableColumns = ({ tableFilters, setAuditLogId, translateText }) => {
  return [
    {
      title: translateText('corporateAccounts.common.date'),
      sorter: true,
      dataIndex: 'performedActionDate',
      render: (value, _, index) => {
        return <span data-testid={`performedActionDate-${index}`}>{formatDateTime(value)}</span>;
      },
    },
    {
      title: translateText('corporateAccounts.common.user'),
      render: (value, record, index) => {
        return (
          <span data-testid={`user-${index}`}>
            {processUserColumn({
              firstName: record.performedByUserFirstName,
              lastName: record.performedByUserLastName,
              record,
              translateText,
            })}
          </span>
        );
      },
    },
    {
      title: translateText('corporateAccounts.auditLogs.tableColumn.userRole'),
      dataIndex: 'performedByUserRole',
      filters: auditLogsHelper.buildColumnFilter({ filter: USER_ROLES, translateText }),
      filteredValue: tableFilters?.performedByUserRole,
      render: (value, record) => processRoleColumn({ value, record, translateText }),
    },
    {
      title: translateText('corporateAccounts.auditLogs.tableColumn.userAction'),
      dataIndex: 'performedAction',
      filters: auditLogsHelper.buildColumnFilter({ filter: USER_ACTIONS, translateText }),
      filteredValue: tableFilters?.performedAction,
      render: (value, _, index) => {
        return (
          <span data-testid={`performedAction-${index}`}>
            {auditLogsHelper.getTableItem({ item: value, mappingObject: USER_ACTIONS })}
          </span>
        );
      },
    },
    {
      title: translateText('corporateAccounts.common.type'),
      dataIndex: 'entityType',
      filters: auditLogsHelper.buildColumnFilter({
        filter: ACTION_TYPES,
        translateText,
      }),
      filteredValue: tableFilters?.entityType,
      render: key =>
        auditLogsHelper.getTableItemTranslation({
          translateText,
          item: key,
          mappingObject: ACTION_TYPES,
        }),
    },
    {
      title: translateText('corporateAccounts.common.details'),
      dataIndex: 'details',
    },
    {
      title: translateText('corporateAccounts.auditLogs.auditDetails.id'),
      dataIndex: 'entityId',

      render: value => {
        const shortenedId = `${value.substring(0, 8)}...`;

        return (
          <div>
            <span>{shortenedId}</span>
            <a
              className={styles.auditDetailsIcon}
              onClick={() => {
                navigator.clipboard.writeText(value);
              }}
            >
              <CopyToClipboardIcon />
            </a>
          </div>
        );
      },
    },
    {
      dataIndex: 'moreDetails',
      render: (_, record) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          className={styles.buttonAsLink}
          onClick={() => setAuditLogId(record.id)}
        >
          {`${translateText('corporateAccounts.auditLogs.table.clickForDetails')}`}
        </a>
      ),
    },
  ];
};

export default tableColumns;
