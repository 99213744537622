// UNCOMMENT LINES WHEN WE HAVE THE VIDEO LINK

import React, { useState } from 'react';
import { Button } from 'antd';
import Can from 'config/Can';
import useTranslation from 'utils/hooks/useTranslation';
// import useAmplitude from 'utils/hooks/useAmplitude';
import { ONBOARDING_VIDEO_TOPICS } from 'constants/onboarding';
// import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitude';
import DriversIcon from 'components/Shared/Icons/DriversIcon';
import EmptyState from 'components/Layout/EmptyState';
import HowToVideo from 'components/Shared/HowToVideo';

import styles from './style.module.scss';

const DriversEmptyState = ({ handleAddDriver, visible, onImportClick }) => {
  const { translateText } = useTranslation();
  // const { sendAmplitudeEvent } = useAmplitude();

  const [isVideoVisible, setIsVideoVisible] = useState(false);

  // const openHowToVideo = () => {
  //   setIsVideoVisible(true);
  //   sendAmplitudeEvent(AMPLITUDE_EVENT_TYPES.howToAddDriverVideoOpened);
  // };

  if (!visible) return null;

  return (
    <>
      <EmptyState
        icon={<DriversIcon />}
        title={translateText('corporateAccounts.onboarding.addDriverStep.title')}
        description={translateText('corporateAccounts.drivers.emptyState.description')}
        buttons={
          <>
            <Can I="create" a="Driver" passThrough>
              {can => (
                <Button
                  data-testid="addDriverBtn"
                  type="primary"
                  size="large"
                  onClick={handleAddDriver}
                  className={[styles.button]}
                  disabled={!can}
                >
                  {translateText('corporateAccounts.drivers.actions.addParker')}
                </Button>
              )}
            </Can>
            <Can I="bulkDriverUpload" a="RestrictedDriverUpload" passThrough>
              {can =>
                can && (
                  <Button
                    data-testid="uploadDriverBtn"
                    type="secondary"
                    size="large"
                    onClick={onImportClick}
                    className={styles.button}
                    disabled={!can}
                  >
                    {translateText('corporateAccounts.drivers.emptyState.importDriversBtn')}
                  </Button>
                )
              }
            </Can>
          </>
        }
        // videoLink={
        //   <span className={styles.buttonAsLink} onClick={openHowToVideo}>
        //     {translateText('corporateAccounts.drivers.emptyState.videoLink')}
        //   </span>
        // }
      />
      <HowToVideo
        topic={ONBOARDING_VIDEO_TOPICS.HOW_TO_DRIVERS}
        title={translateText('corporateAccounts.drivers.emptyState.videoLink')}
        isVisible={isVideoVisible}
        setIsVisible={setIsVideoVisible}
      />
    </>
  );
};

export default DriversEmptyState;
