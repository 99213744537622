import { REHYDRATE } from 'redux-persist/lib/constants';
import { all, take, takeEvery, put, call, select } from 'redux-saga/effects';
import moment from 'moment';
import { datadogLogs } from '@datadog/browser-logs';
import uploadHelper from 'components/Shared/BulkUpload/helper';
import utilityModal from 'components/Shared/Modal/info';
import { localizedMessage } from 'services/localize';
import { vehicles, imports } from 'services/api';
import { isSuccess, isBadRequest } from 'utils/sagasUtil';
import notify from 'utils/notificationUtils';
import { generateAndDownloadCsv, getVehicleExportHeaders } from 'utils/exportUtil';
import { DEFAULT_EXPORT_PAGE_SIZE } from 'constants/tables';
import { VEHICLE_ADD_ERRORS } from 'constants/vehicles';
import actions from './actions';
import mappings from './mappings';

export function* GET({ payload }) {
  const { response } = yield call(vehicles.read, payload);
  if (isSuccess(response)) {
    yield put({
      type: actions.GET_SUCCESS,
      payload: response.data,
    });
  } else {
    yield put({
      type: actions.GET_ERROR,
    });
  }
}

export function* POST({ payload }) {
  const { response, error } = yield call(vehicles.create, payload);

  if (isSuccess(response)) {
    yield put({
      type: actions.POST_SUCCESS,
      payload: response.data,
    });
    notify({
      type: 'success',
      message: localizedMessage('corporateAccounts.vehicle.add.success'),
    });
  } else if (isBadRequest(error)) {
    if (
      error?.errors?.domainErrorCode === VEHICLE_ADD_ERRORS.RestrictedCorporateClientLimitExceeded
    ) {
      notify({
        type: 'error',
        message: localizedMessage('corporateAccounts.vehicle.limitExceeded.error'),
      });
      yield put({
        type: actions.POST_LIMIT_ERROR,
        payload: null,
      });
    } else if (
      error?.errors?.licensePlate?.errors[0]?.message.includes('contains invalid characters')
    ) {
      yield put({
        type: actions.POST_INVALID_PLATE_ERROR,
        payload: null,
      });
    } else if (
      error?.errors?.licensePlate?.errors[0]?.message.includes(
        "There's already a vehicle with this license plate",
      )
    ) {
      notify({
        type: 'error',
        message: localizedMessage('corporateAccounts.vehicle.add.duplicate.error'),
      });
      yield put({
        type: actions.POST_ERROR,
        payload: null,
      });
    } else {
      notify({
        type: 'error',
        message: localizedMessage('corporateAccounts.vehicle.add.error'),
      });
      yield put({
        type: actions.POST_ERROR,
        payload: error.errors,
      });
    }
  } else {
    yield put({
      type: actions.POST_ERROR,
    });
  }
}

export function* PUT({ payload }) {
  const { response, error } = yield call(vehicles.update, payload);

  if (isSuccess(response)) {
    yield put({
      type: actions.PUT_SUCCESS,
      payload: response.data,
    });
    notify({
      type: 'success',
      message: localizedMessage('corporateAccounts.vehicle.update.success'),
    });
  } else if (isBadRequest(error)) {
    if (error?.errors?.licensePlate?.errors[0]?.message.includes('contains invalid characters')) {
      yield put({
        type: actions.POST_INVALID_PLATE_ERROR,
        payload: null,
      });
    } else {
      notify({
        type: 'error',
        message: localizedMessage('corporateAccounts.vehicle.update.error'),
      });

      yield put({
        type: actions.PUT_ERROR,
        payload: error.errors,
      });
    }
  } else {
    yield put({
      type: actions.PUT_ERROR,
    });
  }
}

export function* DELETE({ payload }) {
  const { response } = yield call(vehicles.destroy, payload.id);

  if (isSuccess(response)) {
    yield put({
      type: actions.DELETE_SUCCESS,
      payload,
    });
    notify({
      type: 'success',
      message: localizedMessage('corporateAccounts.vehicle.delete.success'),
    });
  } else {
    notify({
      type: 'error',
      message: localizedMessage('corporateAccounts.vehicle.delete.error'),
    });
    yield put({
      type: actions.DELETE_ERROR,
    });
  }
}

export function* IMPORT({ payload }) {
  const { response, error } = yield call(imports.upload, payload);

  if (isSuccess(response)) {
    yield put({
      type: actions.IMPORT_SUCCESS,
    });

    uploadHelper.setSuccessModal('vehicle');
  } else if (isBadRequest(error)) {
    if (
      error?.errors?.domainErrorCode === VEHICLE_ADD_ERRORS.RestrictedCorporateClientLimitExceeded
    ) {
      yield put({
        type: actions.IMPORT_LIMIT_ERROR,
        payload: null,
      });

      uploadHelper.setErrorModal({
        content: localizedMessage('corporateAccounts.vehicle.limitExceeded.error'),
      });
    } else {
      yield put({
        type: actions.IMPORT_ERROR,
        payload: error.errors,
      });

      uploadHelper.setErrorModal();
    }
  } else {
    yield put({
      type: actions.IMPORT_ERROR,
      payload: error.errors,
    });

    uploadHelper.setErrorModal();
  }
}

export function* EXPORT_VEHICLES({ payload }) {
  const startTime = moment().valueOf();
  const vehiclesToExport = [];
  let isOperationFailed = false;
  let offset = 0;
  let totalCount = null;
  do {
    const { response } = yield call(vehicles.read, {
      ...payload,
      limit: DEFAULT_EXPORT_PAGE_SIZE,
      offset,
    });

    if (isSuccess(response)) {
      const vehiclesResponse = response.data;
      vehiclesToExport.push(...vehiclesResponse.items);

      if (totalCount === null) {
        totalCount = vehiclesResponse.totalCount;
      }
      offset += DEFAULT_EXPORT_PAGE_SIZE;
    } else {
      isOperationFailed = true;
      break;
    }
  } while (offset <= totalCount);

  if (!isOperationFailed) {
    const currentCorpCountry = yield select(state => state.user.currentCorporation?.country);
    const csvHeader = getVehicleExportHeaders(currentCorpCountry);
    const mappedVehicles = mappings.mapExportedVehicles(vehiclesToExport);
    generateAndDownloadCsv(
      mappedVehicles,
      csvHeader,
      localizedMessage('corporateAccounts.vehicles.export.fileName'),
    );

    const endTime = moment().valueOf();
    const duration = endTime - startTime;
    datadogLogs.logger.info(`Vehicle export finished. Duration: ${duration}ms`, {
      duration,
    });

    notify({
      type: 'success',
      title: localizedMessage('corporateAccounts.vehicles.export.success.notification.title'),
      message: localizedMessage(
        'corporateAccounts.vehicles.export.success.notification.description',
      ),
    });

    yield put({
      type: actions.EXPORT_VEHICLES_SUCCESS,
    });
  } else {
    utilityModal.errorModal({
      title: localizedMessage('corporateAccounts.vehicles.export.error.modal.title'),
      content: localizedMessage('corporateAccounts.vehicles.export.error.modal.description'),
    });

    yield put({
      type: actions.EXPORT_VEHICLES_ERROR,
    });
  }
}

export function* BATCH_DELETE({ payload }) {
  const { response } = yield call(vehicles.batchDelete, payload);

  if (isSuccess(response)) {
    yield put({
      type: actions.BATCH_DELETE_SUCCESS,
      payload: { ...payload, loading: false },
    });

    const numOfDeletedVehicles = response.data.length;
    yield notify({
      type: 'success',
      message: localizedMessage('corporateAccounts.vehicles.bulkDelete.success', null, {
        numOfDeletedVehicles: `${numOfDeletedVehicles}`,
      }),
    });
  } else {
    yield put({
      type: actions.BATCH_DELETE_ERROR,
    });
  }
}

export default function* rootSaga() {
  // Wait for redux-persist to rehydrate to prevent sagas running against empty store
  yield take(REHYDRATE);
  yield all([
    takeEvery(actions.GET, GET),
    takeEvery(actions.POST, POST),
    takeEvery(actions.PUT, PUT),
    takeEvery(actions.BATCH_DELETE, BATCH_DELETE),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.IMPORT, IMPORT),
    takeEvery(actions.EXPORT_VEHICLES, EXPORT_VEHICLES),
  ]);
}
