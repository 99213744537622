import actions from './actions';
import corporationActions from '../corporations/actions';
import userActions from '../user/actions';

export const INITIAL_STATE = {
  loading: false,
  submitting: false,
  data: [],
  formErrors: [],
};

export default function adminsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actions.SET_STATE:
    case actions.POST_ERROR:
    case actions.PUT_ERROR:
      return { ...state, ...action.payload };
    case actions.POST_SUCCESS:
      return {
        submitting: false,
        data: [...state.data, action.payload],
      };
    case actions.PUT_SUCCESS:
      return {
        submitting: false,
        data: [...state.data.map(row => (row.id === action.payload.id ? action.payload : row))],
      };
    case actions.DELETE_SUCCESS:
      return {
        submitting: false,
        data: [...state.data.filter(row => row.id !== action.payload.id)],
      };
    case actions.INVITE_SUCCESS:
      return {
        submitting: false,
        data: [
          ...state.data.map(row => {
            action.payload.forEach(adminUser => {
              if (row.id === adminUser.adminId) {
                row.status = adminUser.status;
              }
              return row;
            });
            return row;
          }),
        ],
      };
    case actions.RESET_ERRORS:
      return {
        formErrors: [],
        data: state.data,
      };
    case corporationActions.GET:
    case userActions.LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
