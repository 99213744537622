import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ROUTE_CORPORATIONS } from 'constants.js';
import errorLoggerUtil from 'utils/errorLoggerUtil';
import userManager from 'utils/userManager';
import notify from 'utils/notificationUtils';
import { localizedMessage } from 'services/localize';
import { invitations } from 'services/api';
import userService from 'services/user';
import { datadogLogs } from '@datadog/browser-logs';

const ActivationRedirect = ({
  changeCorporateClient,
  history,
  logout,
  setCurrentUser,
  user,
  setActivationSuccess,
  setUserActivationId,
}) => {
  const acceptInvitation = async () => {
    const { response } = await invitations.admin();

    return response && response.status === 200;
  };

  const checkUserActivation = async () => {
    return userService.checkActivation();
  };

  const refetchActivatedUser = async () => {
    const currentUser = await userManager.getUser();
    let signedInUser;
    datadogLogs.logger.info('User Activation - Fetching Current User', { user: currentUser });

    if (currentUser) {
      signedInUser = await userManager.signinSilent();
      datadogLogs.logger.info('User Activation - Signed In User', { user: signedInUser });
    }
    return signedInUser;
  };

  const setCurrentUserProfile = useCallback(async () => {
    const hasActivation = await checkUserActivation();

    if (hasActivation) {
      const activationSuccess = await acceptInvitation();
      if (activationSuccess) {
        await setUserActivationId(hasActivation);

        const refetchedUser = await refetchActivatedUser();
        await setCurrentUser(refetchedUser, true);
        await setActivationSuccess(true);

        if (user.currentCorporation) {
          await changeCorporateClient(null);
        }

        history.push(ROUTE_CORPORATIONS);
      } else {
        const activationFailedTitle = localizedMessage(
          'corporateAccounts.admins.activate.error.title',
        );

        const activationFailedMessage = localizedMessage(
          'corporateAccounts.admins.activate.error.message',
        );

        notify({
          type: 'error',
          title: activationFailedTitle,
          message: activationFailedMessage,
        });

        errorLoggerUtil.ddErrorLogwithContext('Admin Activation Error');

        setTimeout(async () => logout(), 3000);
      }
    }
  }, [
    changeCorporateClient,
    history,
    logout,
    setActivationSuccess,
    setCurrentUser,
    setUserActivationId,
    user.currentCorporation,
  ]);

  useEffect(() => {
    setCurrentUserProfile();
  }, [setCurrentUserProfile]);

  return (
    <h2 style={{ textAlign: 'center', marginTop: 30 }}>
      {localizedMessage('corporateAccounts.admins.status.activating')}
    </h2>
  );
};

export default connect(
  ({ user, corporations }) => ({ user, corporations }),
  dispatch => ({
    setCurrentUser: (currentUser, force) => {
      dispatch({ type: 'user/LOGIN', payload: { currentUser, force } });
      return Promise.resolve();
    },
    logout: () => {
      dispatch({ type: 'user/LOGOUT' });
      return Promise.resolve();
    },
    changeCorporateClient: (corporateClientId, currentCorporation, corporationRole) => {
      return dispatch({
        type: 'user/SET_STATE',
        payload: {
          corporateClientId,
          currentCorporation,
          corporationRole,
        },
      });
    },
    setActivationSuccess: activationSuccess => {
      return dispatch({
        type: 'user/SET_STATE',
        payload: { activationSuccess },
      });
    },
    setUserActivationId: activationId => {
      return dispatch({
        type: 'user/SET_STATE',
        payload: { activationId },
      });
    },
  }),
)(withRouter(ActivationRedirect));
