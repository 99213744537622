import store from 'store';
import { useQuery } from 'react-query';
import { loadLocaleTranslations } from 'services/api/localeStore';
import enUS from 'locales/en-US';

const LOCALE_STORAGE_PREFIX = 'pbpb_';
const TTL_HOURS = 3;
export const buildLocalStoreKey = locale => `${LOCALE_STORAGE_PREFIX}${locale}`;

const setTtl = () => {
  return new Date().getTime() + TTL_HOURS * 60 * 60 * 1000;
};

const isTranslationAssetValid = translations => {
  const now = new Date().getTime();
  return translations && translations.ttl && now < translations.ttl;
};

export const fetchAndSaveLocale = async locale => {
  const storageKey = buildLocalStoreKey(locale);
  const localStorageAsset = store.get(storageKey);
  if (isTranslationAssetValid(localStorageAsset)) {
    return localStorageAsset;
  }

  const data = await loadLocaleTranslations(locale);
  data.ttl = setTtl();
  await store.set(storageKey, data);
  return data;
};

export const useFetchLocale = (locale, loadOnMount = true) => {
  const loadLocaleFile = async ({ queryKey }) => {
    const [, localeKey] = queryKey;
    if (!localeKey) {
      return {};
    }
    const data = await fetchAndSaveLocale(localeKey);
    return data || {};
  };

  return useQuery({
    queryKey: ['fetchLocale', locale],
    queryFn: loadLocaleFile,
    initialData: enUS,
    keepPreviousData: true,
    retry: false,
    enabled: loadOnMount,
    onError: error => {
      console.log(error);
    },
  });
};

export default {
  fetchAndSaveLocale,
  useFetchLocale,
};
