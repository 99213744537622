import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const FeatureContext = React.createContext({});

/** Feature flag data model:
 * override = If `true`, it will always display the feature.
 * status = If `FEATURE_STATUS.Development`, the feature is only visible if the user overrides. If `FEATURE_STATUS.Internal`, all staff users can see the feature. If `FEATURE_STATUS.All` the feature may be visible to end users depending on the rules. Otherwise, the feature will be hidden by default unless overriden or otherwise specified by configured rules.
 * rules = An object of options to configure. Defaults to `null` if not provided,
 * rules options = {
 *   excludeCountries = List of country codes that will exclude this feature from end users. Defaults to null
 * }
 */

export const FEATURE_STATUS = {
  Development: 'Development',
  Internal: 'Internal',
  All: 'All',
};

export const createFeature = ({ status, rules }) => {
  return {
    status,
    override: false,
    rules: rules
      ? {
          excludeCountries: rules.excludeCountries || null,
        }
      : null,
  };
};

const FeatureFlagProvider = ({ children, defaultFeatures }) => {
  const { search } = useLocation();
  const [flags, setFlags] = useState(defaultFeatures || {});

  useEffect(() => {
    const featureFlag = new URLSearchParams(search).get('feature');
    if (featureFlag) {
      setFlags(prev => {
        const copyFlags = { ...prev };
        const existingFlag = copyFlags[featureFlag];
        if (!existingFlag) {
          copyFlags[featureFlag] = {
            override: true,
            status: FEATURE_STATUS.Development,
            rules: null,
          };
        } else {
          copyFlags[featureFlag].override = true;
        }

        return copyFlags;
      });
    }

    if (featureFlag === 'null') {
      setFlags(defaultFeatures || {});
    }
  }, [search, defaultFeatures]);

  return <FeatureContext.Provider value={flags}>{children}</FeatureContext.Provider>;
};

export default FeatureFlagProvider;
