import Subscription from './Subscription';
import useSubscriptionPage from './useSubscriptionPage';

const SubscriptionPage = () => {
  const {
    pricePlan,
    isPricePlanLoading,
    isPricePlanError,
    isSelfRegistered,
  } = useSubscriptionPage();

  return (
    <Subscription
      pricePlan={pricePlan}
      isPricePlanLoading={isPricePlanLoading}
      isPricePlanError={isPricePlanError}
      isSelfRegistered={isSelfRegistered}
    />
  );
};

export default SubscriptionPage;
