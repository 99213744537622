import React from 'react';
import { Alert } from 'antd';
import { useSelector } from 'react-redux';
import useTranslation from 'utils/hooks/useTranslation';
import { CORPORATE_CLIENT_STATUS } from 'constants/corporateClients';

const PageBanners = () => {
  const { translateText } = useTranslation();
  const { currentCorporation } = useSelector(state => state.user);
  const isDeactivated = currentCorporation?.status === CORPORATE_CLIENT_STATUS.DEACTIVATED;

  return (
    <div>
      {isDeactivated && (
        <Alert
          data-testid="deactivatedBanner"
          style={{ marginBottom: 30 }}
          message={translateText('corporateAccounts.admins.deactivate.banner.message')}
          type="error"
          showIcon
        />
      )}
    </div>
  );
};

export default PageBanners;
